import React from 'react';
import { makeStyles, Theme, createStyles, Fab } from '@material-ui/core';
import classNames from 'classnames';
import { darken, rgba } from 'polished';
import { flatten, includes, pipe, pluck, remove } from 'ramda';
import { useFormContext } from 'react-hook-form';

import { DAYS } from "./constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: `0 ${theme.spacing(2)}`,
      gap: theme.spacing(3.5),

      '& .day': {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.primary.main}`,
        color: rgba(theme.palette.primary.main, 0.5),
        fontSize: '14px',
        fontWeight: 'bold',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 200ms linear',
        width: "60px",
        textTransform: "unset",
        boxShadow: "unset",

        '&:hover': {
          background: rgba(theme.palette.primary.main, 0.25),
        },

        '&.selected': {
          background: theme.palette.primary.main,
          color: theme.palette.background.paper,

          '&:hover': {
            background: darken(0.2, theme.palette.primary.main),
          },
        },

        '&.disabled': {
          opacity: 0.6,
          cursor: 'not-allowed'
        }
      },
    },
  })
);

type MultipleDaysSelectProps = {
  daysList: number[];
  handleDayClick: (dayIndex: number, hourIndex: number) => void;
  currentHourIndex: number;
}

const MultipleDaysSelect = ({daysList, currentHourIndex, handleDayClick}: MultipleDaysSelectProps) => {
  const classes = useStyles();

  const { watch } = useFormContext();

  const normalHours = watch('normalHours');

  return (
    <div className={classes.root}>
      {DAYS.map((data: string, index: number) => {
        return (
          <Fab
            className={classNames({
              day: true,
              selected: daysList.includes(index)
            })}
            key={`day-${data}`}
            onClick={() => handleDayClick(index, currentHourIndex)}
          >
            {data}
          </Fab>
        );
      })}
    </div>
  );
};

export default MultipleDaysSelect;
