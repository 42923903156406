import React, { useState } from "react";
import { Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { isEmptyOrNil } from "bos_common/src";
import { MultiFabContainer } from "bos_common/src/components/FabContainers";

import { useAppSelector } from "../redux/hooks";
import RenderMerchandises from "./RenderMerchandise";
import MerchandiseEditDialog from "./MerchandiseEditDialog";
import MerchantPreviewButton from "./MerchantPreviewButton";
import { getMerchant } from "../redux/slice/merchant/merchantSelector";
import { Merchandise, MerchandiseApiResponseType } from "../services/models";
import { getMerchandiseRaw } from "../redux/slice/merchandise/merchandiseSelector";

type ServiceMerchandiseListProps = {
  merchandises?: Merchandise[];
}

const ServiceMerchandiseList = (props: ServiceMerchandiseListProps) => {
  const { merchandises } = props;

  const { t } = useTranslation();

  const [dlgOpen, setDlgOpen] = useState<boolean>(false);
  const [editMerchandise, setEditMerchandise] = useState<Merchandise>();

  const merchant = useAppSelector(getMerchant);
  const merchandiseData = useAppSelector(getMerchandiseRaw);

  const handleAddMerchandise = () => {
    setEditMerchandise(undefined);
    setDlgOpen(true);
  };

  const onEditMerchandise = (merchandise: Merchandise) => {
    setEditMerchandise(merchandise);
    setDlgOpen(true);
  };

  if(isEmptyOrNil(merchant)) return null;

  return (
    <div>
      <RenderMerchandises
        merchandiseData={merchandiseData || {} as MerchandiseApiResponseType}
        merchandises={merchandises || {} as Merchandise[]}
        merchantId={merchant?.id}
        onEditMerchandise={onEditMerchandise}
        narrow={true}
      />

      <MultiFabContainer buttonCount={2}>
        <MerchantPreviewButton merchant={merchant!} />

        <Fab variant="extended" color="primary" component="span" onClick={handleAddMerchandise}>
          <Add />
          {t('Single_Item')}
        </Fab>
      </MultiFabContainer>

      {merchant && (
        <MerchandiseEditDialog
          merchandise={editMerchandise}
          merchant={merchant}
          open={dlgOpen}
          onClose={() => setDlgOpen(false)}
        />
      )}
    </div>
  )
}

export default ServiceMerchandiseList;