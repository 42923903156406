import { configure } from 'axios-hooks';
import Axios from 'axios';
import LRU from 'lru-cache';


let backendBaseURL = 'http://localhost:7777';
// let backendBaseURL = 'https://staging-wh.api.1m.app' // wh_test
if (process.env.NODE_ENV === 'production') {
  backendBaseURL = 'https://api.1m.app';
  if (process.env.REACT_APP_IS_STAGING === '1') {
    backendBaseURL = 'https://staging.api.1m.app';
  }
  if (process.env.REACT_APP_IS_WH_STAGING === '1') {
    backendBaseURL = 'https://staging-wh.api.1m.app';
  }
}
const apiBaseURL = `${backendBaseURL}/api`

const axios = Axios.create({
  baseURL: apiBaseURL,
});

const i18Config = {
  enUS: 'en-US',
  zhCN: 'zh-CN',
  esEs: 'es-ES',
}

type I18nConfig = keyof typeof i18Config;

axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('bos_locale')) {
      (config.headers as any).common['accept-language'] = `${i18Config[localStorage.getItem('bos_locale') as I18nConfig]}`
    }
    return config;
  },
);

const cache = new LRU({ max: 10, maxAge: 120 * 1000 });
configure({ axios, cache });

export default axios;
export const backendURL = backendBaseURL
export const backendAPIURL = apiBaseURL
