import React from 'react';
import { pathOr, head, propOr } from 'ramda';
import { Typography } from '@material-ui/core';
import { If } from 'react-if';

import { Order, OrderStatus } from '../../services/models';
import { useTranslation } from 'react-i18next';

type OrderRefundedByUserInfoProps = {
  order?: Order
}

const getCancelAdminDisplayName = (order: Order): string => {
  let userDisplayName = 'Unknown';
  const cancellationInfo = propOr([], 'cancellationInfo', order);

  if (order.cancellationInfo && order.cancellationInfo.length > 0) {
    userDisplayName = pathOr('', ['user', 'displayName'], head(cancellationInfo));
  }

  return userDisplayName;
}

const getVoidOrSkipPaymentAdminDisplayName = (order: Order): string => {
  let userDisplayName = 'Unknown';
  if (order.voidOrSkipPaymentInfo) {
    userDisplayName = order.voidOrSkipPaymentInfo.user?.displayName || userDisplayName;
  }

  return userDisplayName;
}

const OrderAdminOperationInfo = ({ order }: OrderRefundedByUserInfoProps) => {
  if (!order) return null;
  if (!order.cancellationInfo && !order.voidOrSkipPaymentInfo) return null;
  const { t } = useTranslation();

  return (
    <>
    <If condition={Boolean(order.cancellationInfo && order.cancellationInfo.length > 0)}>
      <Typography variant="subtitle1" color="red" component="div" align="left" className='order-status'>
        {
          Number(order.refundedAmount) === 0 ?
          t("Order_CancelledBy", {name: getCancelAdminDisplayName(order)}) :
          t("Order_RefundedBy", {name: getCancelAdminDisplayName(order)})
        }
        {/* {Number(order.refundedAmount) === 0 ? 'Cancelled' : 'Refunded'} by {getCancelAdminDisplayName(order)} */}
      </Typography>
    </If>
    <If condition={order.status === OrderStatus.VOIDED}>
      <Typography variant="subtitle1" color="red" component="div" align="left" className='order-status'>
        { t("OrderHistory_VoidedBy", { name: getVoidOrSkipPaymentAdminDisplayName(order)}) }
        {/* Voided by {getVoidOrSkipPaymentAdminDisplayName(order)} */}
      </Typography>
    </If>
    <If condition={order.status === OrderStatus.PAID_EXTERNALLY}>
      <Typography variant="subtitle1" color="red" component="div" align="left" className='order-status'>
        { t("OrderHistory_MarkedAsPaidBy", {name: getVoidOrSkipPaymentAdminDisplayName(order) }) }
        {/* Marked as paid by {getVoidOrSkipPaymentAdminDisplayName(order)} */}
      </Typography>
    </If>
    </>
  )
}

export default OrderAdminOperationInfo