export enum StatsType {
  DAILY = 'daily',
  HOURLY = 'hourly',
}

export interface ItemSale {
  id: string;
  name: string;
  quantity: number;
  sale: number;
  transactionCost: number;
  pointsRedeemed: number;
  categoryId?: number;
}

export interface ItemSales { [key: string]: ItemSale }
export interface UsedCoupon {
  id: number; // the coupon id
  code: string;
  usageCount: number;
  totalRedemption: number;
}

export interface UsedCouponDictionary {
  [key: string]: UsedCoupon
}

export interface MerchantSalesStats {
  id: number,
  merchantId: string;
  type: StatsType;
  timePoint: Date;
  totalRevenue: number;
  totalOrders: number;
  totalTips: number;
  totalTax: number;
  totalRefund: number;
  totalTransactionCost: number;
  totalPointsRedeemed: number;
  totalPointsAwarded: number;
  totalDiscount: number;      // derived on client
  totalGrossSales: number;    // derived on client, based on merchandises sold, or sum(price * quantity)
  totalTotalGrossSales: number;    // derived on client, totalGrossSales + total tips + total tax
  totalNetSales: number;      // derived on client
  totalNetTotal: number;      // derived on client
  itemsSold: number;          // derived on client
  salePerMerchandise?: ItemSales;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  usedCouponPerCode: UsedCouponDictionary;
}
