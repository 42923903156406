import React, { useContext, useEffect, useState } from 'react';
import { Chip, IconButton, MenuItem, Select } from '@material-ui/core';
import classNames from 'classnames';
import { AddCircleOutline, DeleteOutline } from '@material-ui/icons';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { remove as ramdaRemove } from "ramda"

import { getHourRanges, HourOption } from '../../utils';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import { NotificationSeverity } from '../../types/NotificationSlice';

type NormalHoursShiftsProps = {
  normalHourIndex: number;
};

const NormalHoursShifts = ({ normalHourIndex }: NormalHoursShiftsProps) => {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const { triggerNotification } = useContext(AppContext);

  const [hoursOptions, setHoursOptions] = useState<Array<HourOption>>([]);

  const normalHours = watch('normalHours');

  const { t } = useTranslation();

  const {
    remove: removeNormalHour,
  } = useFieldArray({
    name: `normalHours`,
  });

  const {
    fields: shiftsData,
    update,
    append,
    remove,
  } = useFieldArray({
    name: `normalHours[${normalHourIndex}].shifts`,
  });

  useEffect(() => {
    setHoursOptions(getHourRanges());
  }, []);

  const handleDeleteShiftClick = (shiftIndex: number) => {
    if (shiftsData.length <= 1) {
      setValue(`normalHours`, ramdaRemove(normalHourIndex, 1, normalHours))
      return;
    }
    remove(shiftIndex);
  };

  const isHourValueInvalid = (currentDateOffset: number, currentShiftIndex: number) => {
    const hours = shiftsData.filter(
      (item: any, index: number) =>
        currentDateOffset >= item.startTimeOffset &&
        currentDateOffset <= item.endTimeOffset &&
        currentShiftIndex !== index
    );
    if (hours.length > 0) return true;
    return false;
  };

  const handleAddShiftClick = () => {
    append({ enabled: true, endTimeOffset: undefined, startTimeOffset: undefined });
  };

  const handleShiftTimeChange = (event: React.ChangeEvent<HTMLSelectElement>, shiftIndex: number, shiftType: string) => {
    const currentShiftItem = shiftsData[shiftIndex];
    if (shiftType === 'startTimeOffset') {
      update(shiftIndex, { ...currentShiftItem, startTimeOffset: event.target.value });
    } else {
      update(shiftIndex, { ...currentShiftItem, endTimeOffset: event.target.value });
    }
  };

  return (
    <>
      {shiftsData.map((data: any, shiftIndex: number) => {
        return (
          <div
            className={classNames('hoursWrapper', {
              disabled: !data.enabled,
            })}
            key={data.id}
          >
            <Select
              onChange={(e: any) => handleShiftTimeChange(e, shiftIndex, "startTimeOffset")}
              variant="outlined"
              size="small"
              sx={{ maxWidth: '265px', width: '100%' }}
              defaultValue={data.startTimeOffset}
              error={!Boolean(errors)}
              required
            >
              {hoursOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  value={option.minuteOffset}
                  disabled={
                    option.minuteOffset >= data.endTimeOffset ||
                    isHourValueInvalid(option.minuteOffset, shiftIndex)
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            -
            <Select
              onChange={(e: any) => handleShiftTimeChange(e, shiftIndex, "endTimeOffset")}
              required
              defaultValue={data.endTimeOffset}
              variant="outlined"
              size="small"
              sx={{ maxWidth: '265px', width: '100%' }}
              disabled={
                data.startTimeOffset === undefined
              }
            >
              {hoursOptions.map((option) => (
                <MenuItem
                  key={option.label}
                  value={option.minuteOffset}
                  disabled={
                    option.minuteOffset <= data.startTimeOffset ||
                    isHourValueInvalid(option.minuteOffset, shiftIndex)
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              color="default"
              component="span"
              size="small"
              onClick={() => handleDeleteShiftClick(shiftIndex)}
            >
              <DeleteOutline color="error" />
            </IconButton>
          </div>
        );
      })}

      <div className="divider" />

      <div className="actionsWrapper">
        <Chip
          className="roundedButton"
          icon={<AddCircleOutline sx={{ width: 21, height: 21 }} />}
          label={t("AddNewShift")}
          onClick={handleAddShiftClick}
        />
      </div>
    </>
  );
};

export default NormalHoursShifts;
