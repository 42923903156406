import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, createStyles, makeStyles, Popover, TabsActions, Theme, withStyles } from '@material-ui/core';
import { CalendarTodaySharp } from '@material-ui/icons';
import { Calendar, DateInputType, DateRange, OnDateRangeChangeProps, Range } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { addDays, subDays } from 'date-fns';

// src
import { calculateStatsDateRange } from "../../utils/merchantStatsUtils";
import { MerchantStatsContext } from '../../context/MerchantStats/MerchantStatsContext';
import { MerchantStatsPeriod } from './types';
import { StyledTab, StyledTabs, StyledTabPanel } from '../StyledTab';
import { a11yTabProps } from '../../utils';
// styles for the date picker
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateRangeSelector: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .datePickerButton': {
        paddingLeft: 0,
        paddingRight: 0,
        width: 36,
        height: 35,
        minWidth: 30
      }
    },
    paper: {
      marginTop: theme.spacing(1)
    }
  })
)

interface StyledCalendarContainerProps {
  classes: ClassNameMap,
  children: ReactNode
}


const StyledCalendarContainer = withStyles((theme: Theme) => createStyles({
  root: {
    '& .rdrInRange, & .rdrStartEdge, & .rdrEndEdge, & .rdrSelected': {
      backgroundColor: `${theme.palette.primary.main} !important`
    },
    "& .rdrDayStartPreview , & .rdrDayInPreview, & .rdrDayEndPreview, & .rdrDayHovered, & .rdrStartEdge, & .rdrEndEdge": {
      color: `${theme.palette.primary.main} !important`
    },
    "& .rdrDayToday .rdrDayNumber span:after": {
      background: theme.palette.primary.main
    },
    "& .rdrDateDisplayItemActive": {
      borderColor: theme.palette.primary.main
    }
  }
}))(({ classes, children }: StyledCalendarContainerProps) => <Box className={classes.root}>{children}</Box>);


const DateSelectionTabs = () => {
  const { statsTimePeriod, statsDateRange, setStatsDateRange, setStatsTimePeriod } = useContext(MerchantStatsContext);
  const { t } = useTranslation()

  // Fix Active Tab Indicator position of component mount
  const actionRef = useRef<TabsActions>();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (actionRef.current)
        actionRef.current.updateIndicator();
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const [selectedDate, setSelectedDate] = useState(statsTimePeriod === MerchantStatsPeriod.Day ? statsDateRange?.fromDate : statsDateRange?.toDate);
  const [selectedTab, setSelectedTab] = useState(statsTimePeriod);

  const handleSelect = (dateSelected: Date, timePeriod: MerchantStatsPeriod) => {
    let fromDate;
    let toDate = dateSelected
    if (timePeriod === MerchantStatsPeriod.Week) {
      const weekDay = dateSelected.getDay()
      if (weekDay > 0) {
        fromDate = subDays(dateSelected, weekDay - 1)
        toDate = addDays(fromDate, 6)
      } else {
        fromDate = subDays(toDate, 6)
      }
    }
    if (selectedDate?.getTime() !== toDate.getTime()) {
      setSelectedDate(toDate);
      setStatsTimePeriod(timePeriod)
      setStatsDateRange(calculateStatsDateRange(toDate, timePeriod, fromDate))
    }
  }

  const handleRangeSelect = (ranges: OnDateRangeChangeProps) => {
    const selectedRange = ranges.selection
    if (selectedRange.endDate) {
      setStatsTimePeriod(MerchantStatsPeriod.Custom)
      setStatsDateRange(calculateStatsDateRange(selectedRange.endDate, statsTimePeriod, selectedRange.startDate))
    }
  }

  const selectedDateRange: Range = useMemo(() => ({
    startDate: statsDateRange?.fromDate || new Date(),
    endDate: statsDateRange?.toDate || new Date(),
    key: 'selection',
  }), [statsDateRange])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: MerchantStatsPeriod) => {
    setSelectedTab(newValue);
  };

  return (
    <StyledCalendarContainer>
      <div>
        <StyledTabs
          value={selectedTab}
          onChange={handleTabChange}
          action={actionRef}
          aria-label="date range picker tabs"
        >
          <StyledTab label={t("Day")} value={MerchantStatsPeriod.Day} {...a11yTabProps(MerchantStatsPeriod.Day)} />
          <StyledTab label={t("Week")} value={MerchantStatsPeriod.Week} {...a11yTabProps(MerchantStatsPeriod.Week)} />
          <StyledTab label={t("Custom")} value={MerchantStatsPeriod.Custom} {...a11yTabProps(MerchantStatsPeriod.Custom)} />
        </StyledTabs>
      </div>
      <StyledTabPanel value={selectedTab} tabKey={MerchantStatsPeriod.Day} >
        <Calendar
          key='daily-range-picker'
          // @ts-ignore - used because the type declaration doesn't expose the prop weekStartsOn
          weekStartsOn={1}
          date={selectedDate as DateInputType}
          onChange={(v: Date) => handleSelect(v, MerchantStatsPeriod.Day)}
          maxDate={new Date()}
        />
      </StyledTabPanel>
      <StyledTabPanel value={selectedTab} tabKey={MerchantStatsPeriod.Week}>
        <Calendar
          // @ts-ignore - used because the type declaration doesn't expose the prop weekStartsOn
          weekStartsOn={1}
          key='weekly-range-picker'
          onChange={(v: Date) => handleSelect(v, MerchantStatsPeriod.Week)}
          maxDate={new Date()}
          displayMode="dateRange"
          ranges={[selectedDateRange]}

        />
      </StyledTabPanel>
      <StyledTabPanel value={selectedTab} tabKey={MerchantStatsPeriod.Custom}>
        <DateRange
          weekStartsOn={1}
          key='custom-range-picker'
          ranges={[selectedDateRange]}
          onChange={handleRangeSelect}
          maxDate={new Date()}
          editableDateInputs
          moveRangeOnFirstSelection={false}
        />
      </StyledTabPanel>
    </StyledCalendarContainer>
  )
}

const StatsDateRangePicker = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDateRangePicker = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'dateRangePicker' : undefined;

  return (
    <div className={classes.dateRangeSelector}>
      <Button aria-describedby={id} variant="contained" className='datePickerButton' onClick={toggleDateRangePicker}>
        <CalendarTodaySharp fontSize='small' />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={24}
        classes={{
          paper: classes.paper
        }}
      >
        {open && <DateSelectionTabs />}
      </Popover>
    </div>
  )
}

export default StatsDateRangePicker