import React from 'react'
import { SvgIcon } from "@material-ui/core";

function QRCodeIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 20, height: 21 }} viewBox="0 0 20 21" {...props} >

        <path d="M15.9375 13.625H13.4375C13.2649 13.625 13.125 13.7649 13.125 13.9375V16.4375C13.125 16.6101 13.2649 16.75 13.4375 16.75H15.9375C16.1101 16.75 16.25 16.6101 16.25 16.4375V13.9375C16.25 13.7649 16.1101 13.625 15.9375 13.625Z" fill="#1AAA5D" />
        <path d="M12.8125 11.125H10.9375C10.7649 11.125 10.625 11.2649 10.625 11.4375V13.3125C10.625 13.4851 10.7649 13.625 10.9375 13.625H12.8125C12.9851 13.625 13.125 13.4851 13.125 13.3125V11.4375C13.125 11.2649 12.9851 11.125 12.8125 11.125Z" fill="#1AAA5D" />
        <path d="M18.4375 16.75H16.5625C16.3899 16.75 16.25 16.8899 16.25 17.0625V18.9375C16.25 19.1101 16.3899 19.25 16.5625 19.25H18.4375C18.6101 19.25 18.75 19.1101 18.75 18.9375V17.0625C18.75 16.8899 18.6101 16.75 18.4375 16.75Z" fill="#1AAA5D" />
        <path d="M18.4375 11.125H17.1875C17.0149 11.125 16.875 11.2649 16.875 11.4375V12.6875C16.875 12.8601 17.0149 13 17.1875 13H18.4375C18.6101 13 18.75 12.8601 18.75 12.6875V11.4375C18.75 11.2649 18.6101 11.125 18.4375 11.125Z" fill="#1AAA5D" />
        <path d="M12.1875 17.375H10.9375C10.7649 17.375 10.625 17.5149 10.625 17.6875V18.9375C10.625 19.1101 10.7649 19.25 10.9375 19.25H12.1875C12.3601 19.25 12.5 19.1101 12.5 18.9375V17.6875C12.5 17.5149 12.3601 17.375 12.1875 17.375Z" fill="#1AAA5D" />
        <path d="M17.5 1.75H11.875C11.5435 1.75 11.2255 1.8817 10.9911 2.11612C10.7567 2.35054 10.625 2.66848 10.625 3V8.625C10.625 8.95652 10.7567 9.27446 10.9911 9.50888C11.2255 9.7433 11.5435 9.875 11.875 9.875H17.5C17.8315 9.875 18.1495 9.7433 18.3839 9.50888C18.6183 9.27446 18.75 8.95652 18.75 8.625V3C18.75 2.66848 18.6183 2.35054 18.3839 2.11612C18.1495 1.8817 17.8315 1.75 17.5 1.75ZM16.25 7.0625C16.25 7.14538 16.2171 7.22487 16.1585 7.28347C16.0999 7.34208 16.0204 7.375 15.9375 7.375H13.4375C13.3546 7.375 13.2751 7.34208 13.2165 7.28347C13.1579 7.22487 13.125 7.14538 13.125 7.0625V4.5625C13.125 4.47962 13.1579 4.40013 13.2165 4.34153C13.2751 4.28292 13.3546 4.25 13.4375 4.25H15.9375C16.0204 4.25 16.0999 4.28292 16.1585 4.34153C16.2171 4.40013 16.25 4.47962 16.25 4.5625V7.0625Z" fill="#1AAA5D" />
        <path d="M8.125 1.75H2.5C2.16848 1.75 1.85054 1.8817 1.61612 2.11612C1.3817 2.35054 1.25 2.66848 1.25 3V8.625C1.25 8.95652 1.3817 9.27446 1.61612 9.50888C1.85054 9.7433 2.16848 9.875 2.5 9.875H8.125C8.45652 9.875 8.77446 9.7433 9.00888 9.50888C9.2433 9.27446 9.375 8.95652 9.375 8.625V3C9.375 2.66848 9.2433 2.35054 9.00888 2.11612C8.77446 1.8817 8.45652 1.75 8.125 1.75ZM6.875 7.0625C6.875 7.14538 6.84208 7.22487 6.78347 7.28347C6.72487 7.34208 6.64538 7.375 6.5625 7.375H4.0625C3.97962 7.375 3.90013 7.34208 3.84153 7.28347C3.78292 7.22487 3.75 7.14538 3.75 7.0625V4.5625C3.75 4.47962 3.78292 4.40013 3.84153 4.34153C3.90013 4.28292 3.97962 4.25 4.0625 4.25H6.5625C6.64538 4.25 6.72487 4.28292 6.78347 4.34153C6.84208 4.40013 6.875 4.47962 6.875 4.5625V7.0625Z" fill="#1AAA5D" />
        <path d="M8.125 11.125H2.5C2.16848 11.125 1.85054 11.2567 1.61612 11.4911C1.3817 11.7255 1.25 12.0435 1.25 12.375V18C1.25 18.3315 1.3817 18.6495 1.61612 18.8839C1.85054 19.1183 2.16848 19.25 2.5 19.25H8.125C8.45652 19.25 8.77446 19.1183 9.00888 18.8839C9.2433 18.6495 9.375 18.3315 9.375 18V12.375C9.375 12.0435 9.2433 11.7255 9.00888 11.4911C8.77446 11.2567 8.45652 11.125 8.125 11.125ZM6.875 16.4375C6.875 16.5204 6.84208 16.5999 6.78347 16.6585C6.72487 16.7171 6.64538 16.75 6.5625 16.75H4.0625C3.97962 16.75 3.90013 16.7171 3.84153 16.6585C3.78292 16.5999 3.75 16.5204 3.75 16.4375V13.9375C3.75 13.8546 3.78292 13.7751 3.84153 13.7165C3.90013 13.6579 3.97962 13.625 4.0625 13.625H6.5625C6.64538 13.625 6.72487 13.6579 6.78347 13.7165C6.84208 13.7751 6.875 13.8546 6.875 13.9375V16.4375Z" fill="#1AAA5D" />


    </SvgIcon>
  );
}

export default QRCodeIcon