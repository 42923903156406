import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// src
import { AppContext } from '../context/AppContext';
import MerchantProfile from '../components/MerchantProfile';
import useFetchMerchant from '../services/useFetchMerchant';
import { ColoredPaper } from '../bos_common/src';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';

interface IMerchantProfileParams {
  merchantUsername: string;
}

const MerchantProfilePage = () => {
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantProfileParams>();
  const { merchant, setMerchant } = useContext(AppContext);
  const { t } = useTranslation();

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    history.push(`/biz/create`);
  }

  const loading = useFetchMerchant({ merchantUsername })

  return (
    <div className="container">
      <SimpleLoader loading={loading} />
      {!loading && (
        <ColoredPaper>
          <MerchantProfile merchant={merchant} onUpdatedMerchant={setMerchant} />
        </ColoredPaper>
      )}
    </div>
  );
};

export default MerchantProfilePage;
