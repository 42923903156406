import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getMerchandises } from "../../../apiServices/merchandiseService";
import { Merchandise, MerchandiseCategory } from "../../../bos_common/src/types/MerchandiseType";
import { Merchant } from "../../../bos_common/src/types/MerchantType";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";
import { getMerchant } from "../merchant/merchantSelector";

type SwapCategoriesType = {
  oldCategoryId: number;
  newCategoryId: number;
}

export const merchandiseFetching = createAction('merchandise/setLoadingAuth');

export const merchandiseFetchSucceed = createAction<Merchandise[]>('merchandise/updateMerchandise');

export const merchandiseFetchCategoriesSucceed = createAction<MerchandiseCategory>('merchandise/updateMerchandiseCategories');

export const deleteMerchandiseCategorySucceed = createAction<number>('merchandise/deleteCategory');

export const deleteMerchandiseItemSucceed = createAction<string>('merchandise/deleteMerchandise');

export const swapMerchandiseCategoriesSucceeded = createAction<SwapCategoriesType>('merchandise/swapMerchandiseCategory');

export const updateMerchandiseCategoriesSucceed = createAction<Merchandise>('merchandise/updateMerchandiseCategory');

export const updatedCategoriesList = createAction<MerchandiseCategory[]>('merchandise/updatedCategoriesList');

export const addFeaturedCategoriesSucceed = createAction<Merchandise>('merchandise/submitFeaturedCategory');

export const merchandiseFetchFailed = createAction('merchandise/stopLoadingMerchandise');

export const createMerchandiseSucceeded = createAction<Merchandise>('merchandise/addMerchandise');

export const fetchMerchandises = createAsyncThunk(
  'merchandise/fetchMerchandises',
  async (merchantUserName: string|undefined, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token: string = getToken(state);
    const merchant: Merchant | null = getMerchant(state);

    if (!merchant?.username && !merchantUserName) {
      throw new Error('merchant is null');
    }

    const response = await getMerchandises(merchantUserName || merchant?.username || '', { headers: getAuthHeaders(token) });
    return response.data;
  }
);
