import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { dissoc, values } from 'ramda';
import {
  MerchantPackage,
} from 'bos_common/src/types/crm/MerchantPackageType';

import { MerchantServicePackagesSliceState } from '../../../types/MerchantServicePackageSlice';
import { fetchPackages } from './merchantServicePackagesActions';

type MerchantServicePackagesSliceCaseReducer = SliceCaseReducers<MerchantServicePackagesSliceState>;

export const merchantServicePackagesSlice = createSlice<
  MerchantServicePackagesSliceState,
  MerchantServicePackagesSliceCaseReducer,
  string
>({
  name: 'merchantServicePackage',
  initialState: {
    loading: false,
    entities: {
      ids: null,
      byId: {},
    },
  },
  reducers: {
    setLoading: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    stopLoading: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    add: (state, action) => {
      const { payload } = action;
      const ids = [...(state.entities.ids || [])];
      const byId = { ...state.entities.byId };
      const updatedMerchandises = [...values(byId), payload].reduce(
        (acc: string[], item: MerchantPackage) => {
          return {
            ...acc,
            [item.id]: item,
          };
        },
        {}
      );

      if (ids.indexOf(payload.id) === -1) {
        ids.push(payload.id);
      }

      return {
        ...state,
        entities: {
          ids,
          byId: updatedMerchandises,
        }
      };
    },

    remove: (state, action) => {
      const { payload } = action;
      const updatedMerchandises = dissoc(payload, { ...state.entities.byId });

      return {
        ...state,
        entities: {
          ids: [...(state.entities.ids || [])],
          byId: updatedMerchandises,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        const { payload } = action;
        const ids = [...(state.entities.ids || [])];
        const byId = { ...state.entities.byId };
        const updatedMerchandises = [...values(byId), ...payload].reduce(
          (acc: string[], item: MerchantPackage) => {
            return {
              ...acc,
              [item.id]: item,
            };
          },
          {}
        );

        payload.forEach((item) => {
          if (ids.indexOf(item.id) === -1) {
            ids.push(item.id);
          }
        });

        return {
          ...state,
          loading: false,
          entities: {
            ids,
            byId: updatedMerchandises,
          }
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  stopLoading,
  add,
  remove
} = merchantServicePackagesSlice.actions;

export default merchantServicePackagesSlice.reducer;
