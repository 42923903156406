import React, { useContext } from 'react';
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { flatten, includes, pathOr, pipe, pluck, remove, without } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteOutline, InfoOutlined } from '@material-ui/icons';

import CardWrapper from '../CardWrapper';
import MultipleDaysSelect from './MultipleDaysSelect';
import NormalHoursShifts from './NormalHoursShifts';
import { NormalHours as NormalHoursDataProps } from '../../bos_common/src/types/MerchantConfigType';
import { AppContext } from '../../context/AppContext';
import { NotificationSeverity } from '../../types/NotificationSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .message': {
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        margin: 0,
        marginLeft: theme.spacing(1),
        display: "flex",
        gridGap: theme.spacing(1),
        color: theme.palette.text.secondary,
      },

      '& .actionsWrapper': {
        display: 'flex',
        gap: theme.spacing(4.2),
        padding: `0 ${theme.spacing(3.1)} ${theme.spacing(2)}`,
      },

      '& .divider': {
        margin: `${theme.spacing(2)} 0`,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      },

      '& .hoursWrapper': {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        padding: `0 ${theme.spacing(2)}`,
        marginBottom: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
          flexDirection: "column",
          gap: 0,
          height: "105px",
          flexWrap: "wrap",
          width: "79%",
          justifyContent: "center",
          marginBottom: theme.spacing(3),
        },

        '&.disabled': {
          opacity: 0.5,
        },
      },
    },
  })
);

const NormalHours = () => {

  const classes = useStyles();

  const { triggerNotification } = useContext(AppContext);

  const { t } = useTranslation();

  const {
    watch,
    setValue,
    register,
  } = useFormContext();

  const {
    remove: removeNormalHour,
  } = useFieldArray({
    name: `normalHours`,
  });

  const normalHoursData = watch('normalHours');

  const handleHourDelete = (currentHourIndex: number) => {
    removeNormalHour(currentHourIndex)
  };

  const handleDayClick = (dayIndex: number, currentHourIndex: number) => {
    const isDayAvailable = pipe(
      pluck(['days']),
      remove(currentHourIndex, 1),
      flatten(),
      includes(dayIndex)
    )(normalHoursData)

    if(isDayAvailable) {
      triggerNotification(true, t("BusinessHours_DayAlreadySelectedMessage"), NotificationSeverity.INFO, true);
      return;
    }

    const daysList = pathOr([], [currentHourIndex, 'days'], normalHoursData);

    register(`normalHours[${currentHourIndex}].days`)

    if (!daysList.includes(dayIndex)) {
      setValue(`normalHours[${currentHourIndex}].days`, [...daysList, dayIndex]);
    } else {
      setValue(`normalHours[${currentHourIndex}].days`, without([dayIndex], daysList));
    }
  };

  return (
    <>
      {normalHoursData?.map((data: NormalHoursDataProps, index: number) => {
        const { days } = data;
        return (
          <div className={classes.root} key={`normalHour-${index}`}>
            <CardWrapper
              heading={`Hours ${index + 1}`}
              headerItems={
                <IconButton
                  color="primary"
                  component="span"
                  size="small"
                  onClick={() => handleHourDelete(index)}>
                  <DeleteOutline color="error" />
                </IconButton>
              }
            >
              <MultipleDaysSelect
                daysList={days || []}
                handleDayClick={handleDayClick}
                currentHourIndex={index}
              />

              <div className="divider" />

              <NormalHoursShifts normalHourIndex={index} />

              <p className="message"><InfoOutlined /> {t("BusinessHours_UnassignedDaysMessage")}</p>
            </CardWrapper>
          </div>
        );
      })}
    </>
  );
};

export default NormalHours;
