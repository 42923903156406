import { utcToZonedTime } from 'date-fns-tz';
import { repeat } from 'ramda';
import { renderToStaticMarkup } from 'react-dom/server';
import { formatDate, getClientTimezone, pluralString } from '.';
import { getOrderTotalItems } from '../bos_common/src/services/orderPricingUtils';
import {
  displayOrderShortCode,
  displayPickUpTypeLabel,
  isPickupASAP,
} from '../bos_common/src/services/orderUtils';
import { Merchant, MerchantQRCode } from '../bos_common/src/types/MerchantType';
import { Order, OrderType } from '../bos_common/src/types/OrderTypes'; 

type Alignment = 'left' | 'center' | 'right';

type PrintCommand = Partial<{
  fontSize: number;
  bold: boolean;
  text: string;
  alignment: Alignment;
}>;

export type PrintLabelParams = {
  order: Order;
  merchant: Merchant;
  reprint?: boolean;
};

// export class PrintHtmlBuilder {
//   public body: ReactNode[];

//   public style: Record<string, string>;

//   constructor() {
//     this.body = [];
//     this.style = {
//       root: `
//       html,
//       body {
//         font-size: 32px;
//       }`,
//     };
//   }

//   addClass = (key: string, value: string) => {
//     const item = this.style[key];
//     if (!item) this.style[key] = `.${key} ${value}`;
//   };

//   addTextLine = (text: ReactNode | string, alignment?: Alignment, className?: string) => {
//     let value = text;
//     if (typeof text === 'string') {
//       value = (
//         <div
//           className={className}
//           style={{ textAlign: alignment !== 'left' ? alignment : undefined }}
//         >
//           {value}
//         </div>
//       );
//     }
//     this.body.push(value);
//   };

//   addLargerTextLine = (text: string, alignment?: Alignment) => {
//     const className = 'text-larger-line';
//     this.addClass(
//       className,
//       `{
//         font-size: 64px;
//         font-weight: 'bolder';
//       }`
//     );
//     this.addTextLine(text, alignment, className);
//   };

//   addDivider = () => {
//     const className = 'divider';
//     this.addClass(
//       className,
//       `{
//         height: 2px;
//         margin: 5px 0;
//         background-image: linear-gradient(to right, black 0%, black 80%, transparent 50%);
//         background-size: 20px 4px;
//         background-repeat: x-repeat;
//       }`
//     );
//     this.body.push(<div className={className} />);
//   };

//   addEmptyLine = () => {
//     this.body.push(<div>&nbsp;</div>);
//   };

//   // TODO The current version is not used, but may be required in later versions
//   // addQRCode = (_link: string) => {
//   //   const toCanvasString = (
//   //     size: number,
//   //     border: number = 20,
//   //     lightColor: string = '#FFF',
//   //     darkColor: string = '#000'
//   //   ) => {
//   //     const canvas = document.createElement('canvas');
//   //     // @ts-ignore
//   //     const qr: any = qrcodegen.QrCode.encodeText(link, qrcodegen.QrCode.Ecc.HIGH);
//   //     const scale = Math.ceil((size - border) / qr.size);
//   //     // eslint-disable-next-line no-param-reassign,no-multi-assign
//   //     canvas.height = canvas.width = qr.size * scale + border * 2;
//   //     const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
//   //     // eslint-disable-next-line no-plusplus
//   //     for (let y = -border; y < qr.size + border; y++) {
//   //       // eslint-disable-next-line no-plusplus
//   //       for (let x = -border; x < qr.size + border; x++) {
//   //         ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor;
//   //         ctx.fillRect(x * scale + border, y * scale + border, scale, scale);
//   //       }
//   //     }

//   //     return canvas.toDataURL('image/webp', 100);
//   //   };

//   //   //   const toSvgString = (qr: any, border: number, lightColor: string, darkColor: string) => {
//   //   //     if (border < 0) throw new RangeError('Border must be non-negative');
//   //   //     const parts: string[] = [];
//   //   //     // eslint-disable-next-line no-plusplus
//   //   //     for (let y = 0; y < qr.size; y++) {
//   //   //       // eslint-disable-next-line no-plusplus
//   //   //       for (let x = 0; x < qr.size; x++) {
//   //   //         if (qr.getModule(x, y)) parts.push(`M${x + border},${y + border}h1v1h-1z`);
//   //   //       }
//   //   //     }
//   //   //     return `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 ${
//   //   //       qr.size + border * 2
//   //   //     } ${qr.size + border * 2}" stroke="none">
//   //   //   <rect width="100%" height="100%" fill="${lightColor}"/>
//   //   //   <path d="${parts.join(' ')}" fill="${darkColor}"/>
//   //   // </svg>
//   //   // `;
//   //   //   };

//   //   const className = 'qrcode';
//   //   this.addClass(
//   //     className,
//   //     `{
//   //       width: 50%;
//   //       text-align: center;
//   //     }`
//   //   );

//   //   this.body.push(
//   //     this.formatLeftRight(
//   //       <div className="qrcode">
//   //         <img src={toCanvasString(240)} alt="" />
//   //       </div>,
//   //       <div style={{ width: '50%' }}>
//   //         <div>Scan QR code to</div>
//   //         <div>order ahead</div>
//   //       </div>
//   //     )
//   //   );
//   // };

//   addLogo = () => {};

//   formatLeftRight = (
//     left: string | ReactNode | null = '',
//     right: string | ReactNode = '',
//     identation: number = 0,
//     width?: string | number
//   ) => {
//     const className = 'format-left-right';
//     this.addClass(
//       className,
//       `{
//       display: flex;
//       justify-content: space-between;
//     }`
//     );
//     return (
//       <div
//         className={className}
//         style={{
//           paddingLeft: identation ? identation * 16 : undefined,
//           width,
//         }}
//       >
//         {typeof right === 'string' ? <span>{left}</span> : left}
//         {typeof right === 'string' ? <span>{right}</span> : right}
//       </div>
//     );
//   };

//   formatPrintOrderItem = (foodName: string, quantity: string, price: string) => {
//     return this.formatLeftRight(foodName, this.formatLeftRight(quantity, price, 0, '50%'));
//   };
// }

export class PrintBuilder {
  public commands: PrintCommand[];

  public currentStyle: Partial<Omit<PrintCommand, 'text'>>;

  constructor() {
    this.commands = [];
    this.currentStyle = {
      fontSize: 1,
      alignment: 'Left' as Alignment,
      bold: false,
    };
  }

  addCommand = ({ text, fontSize = 1, bold = false, alignment = 'left' }: PrintCommand) => { 
    if (this.currentStyle.alignment?.toLowerCase() !== alignment) {
      const a = `${alignment![0].toUpperCase()}${alignment!.substring(1)}` as Alignment;
      this.commands.push({ alignment: a });
      this.currentStyle.alignment = a;
    }
    if (this.currentStyle.fontSize !== fontSize) {
      this.commands.push({ fontSize });
      this.currentStyle.fontSize = fontSize;
    }
    if (this.currentStyle.bold !== bold) {
      this.commands.push({ bold });
      this.currentStyle.bold = bold;
    }
    this.commands.push({ text });
  };

  addTextLine = (text: string, alignment?: Alignment, bold?: boolean, fontSize?: number) => {
    this.addCommand({
      fontSize,
      alignment,
      bold,
      text: `${text}\n`,
    });
  };

  addLargerTextLine = (text: string, alignment?: Alignment, bold?: boolean) => {
    this.addTextLine(text, alignment, bold, 2);
  };

  addDivider = () => {
    this.addCommand({
      text: `${repeat('-', 44).join('')}\n`,
    });
  };

  addEmptyLine = () => {
    this.addCommand({
      text: '\n',
    });
  };

  addLogo = () => {};

  formatLeftRight = (left: string, right: string) => {
    this.addCommand({
      text: left,
      alignment: 'left',
    });
    this.addCommand({
      text: `${right}\n`,
      alignment: 'right',
    });
  };
}

export const buildKitchenTicket = (params: PrintLabelParams): PrintCommand[] => {
  const { order, merchant, reprint = false } = params;

  const toLocalTime = (date: Date, timeZone?: string) =>
    utcToZonedTime(date, timeZone || getClientTimezone());

  const toPrintItems = order.lineItems;
  const totalItems = getOrderTotalItems(toPrintItems);

  const addAsterisk = reprint ? '*' : '';
  const orderShortCode = displayOrderShortCode(order);
  const builder = new PrintBuilder();

  let orderStatusLine = '';
  let eatHereOrTogo = '';

  const now = new Date();
  const displayTime = formatDate(toLocalTime(now, merchant.timeZone), 'Ppp');
  const scheduledPickupTime = new Date(order.toPickupTime);
  let pickupTime = formatDate(toLocalTime(scheduledPickupTime, merchant.timeZone), 'p');

  switch (order.type) {
    case OrderType.DINEIN:
      {
        const table = merchant.qrCodes?.find(
          (qrCode: MerchantQRCode) => qrCode.id === order.tableId
        );
        orderStatusLine = table ? `Dine In: ${table.identifier}` : 'Dine In';
      }
      break;
    case OrderType.PICKUP:
    default:
      eatHereOrTogo = displayPickUpTypeLabel(order);

      if (isPickupASAP(order)) {
        orderStatusLine = 'Pick up ASAP';
      } else {
        if (scheduledPickupTime.getDate() != now.getDate()) {
          pickupTime = formatDate(
            toLocalTime(scheduledPickupTime, merchant.timeZone),
            'EEE, MMM d, p'
          );
        }
        orderStatusLine = `Pick up: ${pickupTime}`;
      }
      break;
  }

  // builder.addEmptyLine();
  builder.addLogo();
  builder.addLargerTextLine(merchant.officialName, 'center');

  builder.addEmptyLine();
  builder.addDivider();

  builder.addTextLine(displayTime);

  builder.addDivider();
  builder.addEmptyLine();

  builder.addLargerTextLine(order.userDisplayName || '');
  if (eatHereOrTogo) builder.addLargerTextLine(eatHereOrTogo);

  builder.addTextLine(`#${orderShortCode}${addAsterisk}`);
  builder.addTextLine(orderStatusLine);
  builder.addTextLine(`${pluralString(totalItems, 'item')}`, 'left');

  builder.addEmptyLine();
  builder.addDivider();
  builder.addEmptyLine();

  toPrintItems.forEach(async (item, _index: number) => {
    const quantity = item.quantity ?? 1;
    builder.addTextLine(`${quantity}x  ${item.name}`, 'left');
    const hasNote = item.note && item.note !== '';
    if (hasNote) {
      builder.addTextLine(`NOTE: ${item.note}`, 'left');
    }

    const modifiersByDisplayOrder =
      item.customizationDisplayOrder ?? Object.keys(item.customization);
    modifiersByDisplayOrder.forEach((name: string) => {
      builder.addTextLine(` - ${name}`, 'left');
    });
    builder.addEmptyLine();

    return null;
  });

  builder.addDivider();
  builder.addEmptyLine();

  return builder.commands;
};

export const starPassPRNT = (nodes: React.ReactNode[], styles: string[]) => {
  const htmlText = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <meta name="format-detection" content="telephone=no">
          <title>print</title>
          <style>${styles
            .join('')
            .replace(/\s+|\n/g, ' ')
            .replace(/\s*{\s*/g, '{')
            .replace(/\s*;\s*}\s*/g, '}')
            .replace(/\s*:\s*/g, ':')
            .replace(/\s*;\s*/g, ';')
            .replace(/\s*,\s*/g, ',')}</style>
        </head>
        <body>${renderToStaticMarkup(<>{nodes}</>)}</body>
      </html>`;

  let changeHref = 'starpassprnt://v1/print/nopreview?';

  // back
  changeHref += `&back=${encodeURIComponent(`${window.location.origin}/print-back.html`)}`;

  // popup
  changeHref += `&popup=disable`;

  // size ( default: 3 )
  // TSP700II ( dot/mm )
  // 2    406/50.8
  // 2w1  416/52
  // 2w2  420/52.5
  // 3    576/72
  // 3w   640/80
  // changeHref += `&size=3w`;

  // html
  changeHref += `&html=${encodeURIComponent(htmlText)}`;

  window.open(changeHref);
};

// export const localService = (type: 'print' | 'discovery', data: any) => {
//   // axios(`${SERVICE_URL}/print`, {
//   //   method: 'POST',
//   //   withCredentials: true,
//   //   timeout: 3000,
//   //   data: commands,
//   // });
//   return fetch(`${LOCAL_SERVICE_URL}/${type}`, {
//     method: 'POST',
//     mode: 'cors',
//     body: JSON.stringify(data),
//   })
// };
