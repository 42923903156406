
import React, { useState } from 'react';
import { CardHeader } from '@material-ui/core';
import { If, Then, Else } from 'react-if';

import { MerchandiseCategory } from '../../types/MerchandiseType';

import MerchandiseCategoriesHeader from './MerchandiseCategoriesHeader';

type MerchandiseListingProps = {
  categories: Array<MerchandiseCategory>,
  menuItemsAvailable: boolean
  children: React.ReactElement | Array<React.ReactElement>
  loading?: boolean,
  isToolbar?: boolean,
}
const MerchandiseListing = (props: MerchandiseListingProps): React.ReactElement => {
  const { categories, loading = false, children, menuItemsAvailable = false, isToolbar = false } = props;

  const initSelectedCategory = () => categories.length > 0 ? categories[0].id : -1
  const [selectedCategory, setSelectedCategory] = useState<number>(initSelectedCategory());

  return (
    <If condition={!menuItemsAvailable && !loading}>
      <Then>
        <CardHeader subheader="No menu items available." />
      </Then>
      <Else>
        <>
          <MerchandiseCategoriesHeader
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={(id: number) => setSelectedCategory(id)}
            loading={loading}
            isToolbar={isToolbar}
          />
          {children}
        </>
      </Else>
    </If>
  )
}

export default MerchandiseListing;