
import React from 'react'
import { SvgIcon } from "@material-ui/core";

function OperationsIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 18, height: 19 }} viewBox="0 0 18 19" {...props} >
      <path d="M17.4727 7.56641H16.6716C16.4839 6.81716 16.1892 6.1058 15.7918 5.44272L16.3583 4.87618C16.5643 4.67023 16.5643 4.33636 16.3583 4.13041L14.3696 2.14166C14.1636 1.93571 13.8298 1.93571 13.6238 2.14166L13.0573 2.7082C12.3943 2.31079 11.6828 2.01608 10.9336 1.82838V1.02734C10.9336 0.736109 10.6975 0.5 10.4062 0.5H7.59375C7.30252 0.5 7.06641 0.736109 7.06641 1.02734V1.82838C6.31719 2.01604 5.60573 2.31079 4.94272 2.7082L4.37618 2.14166C4.17023 1.93571 3.83636 1.93571 3.63041 2.14166L1.64169 4.13041C1.43575 4.33636 1.43575 4.67023 1.64169 4.87618L2.2082 5.44272C1.81079 6.10577 1.51608 6.81712 1.32838 7.56641H0.527344C0.236109 7.56641 0 7.80252 0 8.09375V10.9062C0 11.1975 0.236109 11.4336 0.527344 11.4336H1.32838C1.51608 12.1829 1.81079 12.8942 2.2082 13.5573L1.64166 14.1238C1.43571 14.3298 1.43571 14.6636 1.64166 14.8696L3.63037 16.8583C3.83632 17.0643 4.1702 17.0643 4.37614 16.8583L4.94269 16.2918C5.60573 16.6891 6.31719 16.9839 7.06637 17.1716V17.9727C7.06637 18.2639 7.30248 18.5 7.59371 18.5H10.4062C10.6974 18.5 10.9336 18.2639 10.9336 17.9727V17.1716C11.6827 16.9839 12.3942 16.6892 13.0572 16.2918L13.6238 16.8583C13.8297 17.0643 14.1636 17.0643 14.3696 16.8583L16.3583 14.8696C16.5643 14.6636 16.5643 14.3298 16.3583 14.1238L15.7918 13.5573C16.1892 12.8942 16.4839 12.1828 16.6716 11.4336H17.4727C17.7639 11.4336 18 11.1975 18 10.9062V8.09375C18 7.80252 17.7639 7.56641 17.4727 7.56641ZM9 14.9492C5.9953 14.9492 3.55078 12.5047 3.55078 9.5C3.55078 6.4953 5.9953 4.05078 9 4.05078C12.0047 4.05078 14.4492 6.4953 14.4492 9.5C14.4492 12.5047 12.0047 14.9492 9 14.9492Z" fill="#1AAA5D" />
      <path d="M9 5.10547C6.57686 5.10547 4.60547 7.07686 4.60547 9.5C4.60547 11.9231 6.57686 13.8945 9 13.8945C11.4231 13.8945 13.3945 11.9231 13.3945 9.5C13.3945 7.07686 11.4231 5.10547 9 5.10547ZM11.6102 8.38133L8.6271 11.3644C8.52413 11.4674 8.38916 11.5189 8.25423 11.5189C8.1193 11.5189 7.9843 11.4674 7.88136 11.3644L6.38982 9.8729C6.18388 9.66696 6.18388 9.33308 6.38982 9.12713C6.59573 8.92119 6.92965 8.92119 7.13559 9.12713L8.25427 10.2458L10.8645 7.63556C11.0704 7.42961 11.4043 7.42965 11.6103 7.63556C11.8162 7.8415 11.8162 8.17542 11.6102 8.38133Z" fill="#1AAA5D" />
    </SvgIcon>
  );
}

export default OperationsIcon