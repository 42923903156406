import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, CircularProgress, createStyles, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import useAxios from 'axios-hooks';
import InfiniteScroll from "react-infinite-scroller";
import { pathOr, uniq } from 'ramda';

// src
import { useAppSelector } from '../redux/hooks';
import { getMerchant } from '../redux/slice/merchant/merchantSelector';

import MerchantPageHeader from '../components/MerchantPageHeader';
import { isEmptyOrNil } from '../utils';

import { FullscreenPaper } from '../bos_common/src';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { Review } from '../bos_common/src/types/ReviewType';
import UserReviewCard from '../bos_common/src/components/UserReviewCard';
import InputSearchBox from '../bos_common/src/components/InputSearchBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .reviewScrollContainer': {
        display: 'grid',
        gridGap: theme.spacing(2),
        gridTemplateColumns: '1fr 1fr',
        padding: theme.spacing(2),

        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '1fr',
        },

        [theme.breakpoints.up('md')]: {
          '& .loadMore': {
            gridColumn: '1/3',
          },
        },
      },

      '& .reviewItem': {
        margin: `${theme.spacing(1)} 0`
      }
    },
    searchType: {
      color: 'rgba(0,0,0,0.6)',

      "&:hover": {
        "&:not(.Mui-disabled):before": {
          borderBottom: 0
        }
      },
      "&:before": {
        borderBottom: 0
      },
      "&:after": {
        borderBottom: 0
      },

      "& .MuiSelect-select:focus": {
        background: "transparent"
      }
    },
    emptyMenu: {
      textAlign: 'center',
      margin: theme.spacing(2),
      height: '100%',

      '& .fabButton': {
        margin: theme.spacing(1),
      },
    },
  })
);

export enum ReviewSearchBarFilters {
  searchByMerchName = 'merchandiseName', // search order by merchandise name
  searchByName = 'userName', // search order by user name
  searchByPhone = 'phoneNumber', // search order by phone number
  searchByEmail = 'email', // search by unique order number
}

interface IMerchantProfileParams {
  merchantUsername: string;
}

type MerchantReviewsApiResponseType = {
  reviews: Review[],
  totalReviewCount: number
}

const ReviewsPerPage = 10;

const ReviewsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantProfileParams>();

  const [reviewsList, setReviewList] = useState<Review[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [searchType, setSearchType] = useState(ReviewSearchBarFilters.searchByMerchName)

  const merchant = useAppSelector(getMerchant);
  const { t } = useTranslation();

  const [{ data: reviewsData, loading }, fetchMerchantReviews] = useAxios<MerchantReviewsApiResponseType>({
    url: `/merchants/${merchant?.id}/reviews`,
    method: "GET",
    params: {
      skip: 0,
      limit: ReviewsPerPage,
      [searchType]: searchQuery
    }
  }, { manual: true })

  const { totalReviewCount } = reviewsData || {}

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    history.push(`/create`);
  }

  useEffect(() => {
    fetchMerchantReviews({
      params: {
        skip: 0,
        limit: ReviewsPerPage,
        [searchType]: searchQuery
      }
    }).then(response => {
      setReviewList(uniq([...pathOr([], ['data', 'reviews'], response)]))
    })
  }, [merchantUsername, searchType, searchQuery]);

  const fetchItems = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      const skip = reviewsList.length
      const response = await fetchMerchantReviews({
        params: {
          skip,
          limit: ReviewsPerPage,
          [searchType]: searchQuery
        }
      })
      setReviewList(state => uniq([...state, ...pathOr([], ['data', 'reviews'], response)]))
    } catch (err) {
      console.log(err)
    }
  }, [loading, reviewsList, searchType]);

  const handleSelectChange = (e: any) => {
    setSearchType(e.target.value);
  }

  const SearchTypeSelect = (
    <Select
      defaultValue={ReviewSearchBarFilters.searchByMerchName}
      id="grouped-select"
      label="Search Filter"
      className={classes.searchType}
      onChange={handleSelectChange}
    >
      <MenuItem value={ReviewSearchBarFilters.searchByMerchName}>{t("ByMerchandiseName")}</MenuItem>
      <MenuItem value={ReviewSearchBarFilters.searchByName}>{t("ByUserName")}</MenuItem>
      {/* <MenuItem value={ReviewSearchBarFilters.searchByPhone}>{t("ByPhone")}#</MenuItem>
      <MenuItem value={ReviewSearchBarFilters.searchByEmail}>{t("ByEmail")}</MenuItem> */}
    </Select >
  )

  return (
    <div className={classes.root}>
      <MerchantPageHeader onBack={onBack} title={`${t('Reviews')}${totalReviewCount ? `(${totalReviewCount})` : ''}`} elevation={0} />
      <AppBar position="sticky" color="inherit">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <InputSearchBox
            onChangeQuery={(s) => setSearchQuery(s)}
            placeholder="Search Reviews..."
            rightChild={SearchTypeSelect} />
        </Box>
      </AppBar>
      <SimpleLoader loading={loading} />
      <FullscreenPaper>
        <If condition={isEmptyOrNil(reviewsList) && !loading}>
          <Then>
            <div className={classes.emptyMenu}>
              <Typography variant="subtitle1" color="textSecondary" component="div">
                {t('Reviews_NoReviewText')}
              </Typography>
            </div>
          </Then>
          <Else>
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchItems}
              hasMore={reviewsList.length < (totalReviewCount ?? 0)}
              loader={<Box className='loadMore' textAlign="center" p={2} key={`loader`}><CircularProgress /></Box>}
              className="reviewScrollContainer"
            >
              {reviewsList.map((review, index) => (
                <div className='reviewItem' key={`${review.id}-${index}`}>
                  <UserReviewCard review={review} merchant={merchant} showMerchandiseDetails isMerchant/>
                </div>
              ))}
            </InfiniteScroll>
          </Else>
        </If>
      </FullscreenPaper>
    </div >
  );
};

export default ReviewsPage;