import React, { useState } from "react"
import { Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { isEmptyOrNil } from "bos_common/src";
import MerchantPackage from "bos_common/src/types/crm/MerchantPackageType";
import { MultiFabContainer } from "bos_common/src/components/FabContainers";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { useAppSelector } from "../../../redux/hooks";
import { getMerchant } from "../../../redux/slice/merchant/merchantSelector";

import RenderPackages from "./RenderPackages";
import { ServiceFormType } from "../../../constants";
import MerchantPreviewButton from "../../MerchantPreviewButton";
import PackageMembershipFormDialog from "../../PackageMembershipFormDialog";

type ServicePackagesListProps = {
  packages: MerchantPackage[];
}

const ServicePackagesList = (props: ServicePackagesListProps) => {
  const { packages } = props;

  const [dlgOpen, setDlgOpen] = useState<boolean>(false);
  const [editPackage, setEditPackage] = useState<MerchantPackage | MerchantMembership>();

  const merchant = useAppSelector(getMerchant);

  const { t } = useTranslation();

  const handleAddPackage = () => {
    setEditPackage(undefined);
    setDlgOpen(true);
  };

  const onEditPackage = (item: MerchantPackage | MerchantMembership) => {
    setEditPackage(item);
    setDlgOpen(true);
  };

  return (
    <div>
      <RenderPackages
        packages={packages || {} as MerchantPackage[]}
        merchantId={merchant?.id}
        onEditMerchandise={onEditPackage}
      />

      <MultiFabContainer buttonCount={2}>
        <MerchantPreviewButton merchant={merchant!} />

        <Fab variant="extended" color="primary" component="span" onClick={handleAddPackage}>
          <Add />
          {t('Services_AddPackage')}
        </Fab>
      </MultiFabContainer>

      {merchant && (
        <PackageMembershipFormDialog
          title={isEmptyOrNil(editPackage) ? t('Services_AddPackage') : t('Services_EditPackage')}
          merchantPackage={editPackage}
          open={dlgOpen}
          onClose={() => setDlgOpen(false)}
          type={ServiceFormType.package}
        />
      )}
    </div>
  )
}

export default ServicePackagesList;
