import { LinearProgress } from '@material-ui/core';
import { History } from 'history';
import React, { useContext } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { AppType } from './bos_common/src';
import ErrorBoundary, { ErrorReporterParams } from './bos_common/src/components/ErrorBoundary';
import { UserContext } from './bos_common/src/context/UserContext';
import CallbackPage from './bos_common/src/pages/CallbackPage';
import CopyrightPolicyPage from './bos_common/src/pages/CopyrightPolicyPage';
import PrivacyPolicyPage from './bos_common/src/pages/PrivacyPolicyPage';
import TermsOfServicePage from './bos_common/src/pages/TermsOfServicePage';
import AppErrorPage from './bos_common/src/pages/AppErrorPage';

import { ProtectedRoute } from './components/ProtectedRoute';

import BizSettingsPage from './pages/BizSettingsPage';
import InventoryPage from './pages/InventoryPage';
import LoginPage from './pages/LoginPage';
import MerchantCreatePage from './pages/MerchantCreatePage';
import MerchantDashboardPage from './pages/MerchantDashboardPage';
import MerchantOrdersPage from './pages/MerchantOrders/MerchantOrdersPage';
import OperatorOrdersHubPage from './pages/MerchantOrders/OperatorOrdersHubPage';
import MerchantPrintsPage from './pages/MerchantPrintsPage';
import MerchantReportsPage from './pages/MerchantReportsPage';
import MerchantStaffLoginPage from './pages/MerchantStaffLoginPage';
import MerchantStaffsPage from './pages/MerchantStaffsPage';
import NoneExistPage from './pages/NoneExistPage';
import NotificationPage from './pages/NotificationPage';
import ReviewsPage from './pages/ReviewsPage';
import UserPage from './pages/UserPage';
import MerchantCustomersPage from './pages/MerchantCustomersPage';
import MerchandiseMenuPage from './pages/MenuPage';

import logger from './services/logger';
import useAuthDevice from './services/useAuthDevice';
import MUIThemeProvider from './theme/MUIThemeProvider';

import './App.css';
import MerchantCalendarPage from './pages/MerchantCalendarPage';
import CallboardContextProvider from './context/CallboardContext/CallboardContextProvider';
import OrdersCallboardPage from './pages/OrdersCallboardPage';
import MonthlyBillingStatementPage from './pages/MonthlyBillingStatementPage';
import MerchantStoryEditPage from './pages/MerchantStories/MerchantStoryEditPage';
import MerchantStoriesPage from './pages/MerchantStories/MerchantStoriesPage';
import PlatformChatPage from './pages/PlatformChatPage';
import ShortURLPage from './pages/ShortURLPage';

function App({ history }: { history: History }): React.ReactElement {
  // store device auth token when admin logs in
  useAuthDevice();

  const { authenticating } = useContext(UserContext);

  if (authenticating) {
    return (
      <div className="App">
        <div className="spinner">
          <LinearProgress />
          <h1>Loading</h1>
        </div>
      </div>
    );
  }

  const logErrorToSentry = (params: ErrorReporterParams) => {
    const { source, error, errorInfo } = params
    logger.error(error, { source, errorInfo })
  }

  return (
    <MUIThemeProvider>
      <ErrorBoundary
        source={AppType.MERCHANT}
        reportError={logErrorToSentry}
        errorFallbackComponent={<AppErrorPage />}
      >
        <div className="App">
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/:merchantUsername/stafflogin" component={MerchantStaffLoginPage} />
              <Route exact path="/landing" component={LoginPage} />
              <Route exact path="/notfound" component={NoneExistPage} />
              <Route exact path="/help/privacy-policy" component={PrivacyPolicyPage} />
              <Route exact path="/help/terms-of-use" component={TermsOfServicePage} />
              <Route exact path="/help/copyright-policy" component={CopyrightPolicyPage} />
              <Route exact path="/callback" component={CallbackPage} />
              <Route exact path="/to/:identifier" component={ShortURLPage} />
              <ProtectedRoute exact path="/">
                <MerchantDashboardPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/user">
                <UserPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/create">
                <MerchantCreatePage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/orders-hub/:type?">
                <OperatorOrdersHubPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername">
                <MerchantDashboardPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/menu">
                <MerchandiseMenuPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername/customers">
                <MerchantCustomersPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/reports">
                <MerchantReportsPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/bizsettings">
                <BizSettingsPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/monthly-statement">
                <MonthlyBillingStatementPage />
              </ProtectedRoute>
              <ProtectedRoute path='/:merchantUsername/callboard'>
                <CallboardContextProvider>
                  <OrdersCallboardPage />
                </CallboardContextProvider>
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/stories/:storyId">
                <MerchantStoryEditPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/stories">
                <MerchantStoriesPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername/staffs">
                <MerchantStaffsPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername/calendar">
                <MerchantCalendarPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername/orders/:type?">
                <MerchantOrdersPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername/prints">
                <MerchantPrintsPage />
              </ProtectedRoute>
              <ProtectedRoute exact path="/:merchantUsername/inventory">
                <InventoryPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/reviews">
                <ReviewsPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/engagements">
                <PlatformChatPage />
              </ProtectedRoute>
              <ProtectedRoute path="/:merchantUsername/chat">
                <PlatformChatPage />
              </ProtectedRoute>
            </Switch>
            <NotificationPage />
          </Router>

          <div>
            {/* Temporary hosted to the 3rd party website #252 */}
            {/* // TODO: cache this to a blob  */}
            <audio id="app_notif_audio">
              <source src="https://1m-assets.s3.us-west-1.amazonaws.com/audio/new-order.mp3" type="audio/mpeg" />
              <track />
            </audio>
          </div>
        </div>
      </ErrorBoundary>
    </MUIThemeProvider>
  );
}

export default App;
