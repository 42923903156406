import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ColoredPaper } from '../bos_common/src';

import MerchantCalendar from '../components/Calendar/Calendar';
import MerchantPageHeader from '../components/MerchantPageHeader';
import { AppContext } from '../context/AppContext';
import CalendarContextProvider from '../components/Calendar/context/CalendarContextProvider';
import useFetchMerchant from '../services/useFetchMerchant';
import MerchantSelectorCTA from '../components/MerchantSelectorCTA';

interface IMerchantCalendarPageParams {
  merchantUsername: string;
}

const MerchantCalendarPage = () => {
  const { merchantUsername } = useParams<IMerchantCalendarPageParams>();
  const { merchant } = useContext(AppContext);
  const { t } = useTranslation();

  const loading = useFetchMerchant({ merchantUsername });

  return (
    <CalendarContextProvider>
      <div className="container">
        <MerchantPageHeader
          leftChild={<MerchantSelectorCTA />}
          title={t('MerchantService_Calendar')}
        />
        <ColoredPaper>
          {!loading && merchant && (
            <MerchantCalendar />
          )}
        </ColoredPaper>
      </div>
    </CalendarContextProvider>
  );
};

export default MerchantCalendarPage;
