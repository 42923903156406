import React from 'react';
import { append, without } from 'ramda';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CustomizedChip from 'bos_common/src/components/CustomizedChip';
import TypographyEllipsis from 'bos_common/src/components/TypographyEllipsis';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';

export type AudienceGroupsCount = {
  groupName: string;
  count: number;
};

interface AudienceGroupsListProps {
  selectedGroup: string;
  setSelectedGroup: (_: string) => void;
  audienceGroupCount: AudienceGroupsCount[];
  isLoading?: boolean;
  setCurrentRowPage: (_: number) => void;
}

const AudienceGroupsList = ({
  selectedGroup,
  setSelectedGroup,
  isLoading = false,
  setCurrentRowPage,
  audienceGroupCount,
}: AudienceGroupsListProps) => {
  const { t } = useTranslation();

  const handleGroupSelect = (group: string) => {
    setSelectedGroup(group);
    setCurrentRowPage(0);
  };

  return (
    <List>
      <SimpleLoader loading={isLoading} />
      <ListItem>
        <ListItemText>
          <Typography variant="body1" fontWeight={600}>
            {t('AudienceGroup')}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />

      {audienceGroupCount.map(({ groupName, count }) => {
        return (
          <React.Fragment key={groupName}>
            <Divider />
            <ListItem>
              <ListItemText>
                <FormGroup>
                  <FormControlLabel
                    label={
                      <TypographyEllipsis sx={{ textTransform: 'capitalize' }}>
                        {t(groupName)}
                      </TypographyEllipsis>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={selectedGroup === groupName}
                        onChange={() => handleGroupSelect(groupName)}
                      />
                    }
                  />
                </FormGroup>
              </ListItemText>
              <ListItemSecondaryAction>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CustomizedChip
                    label="Auto"
                    chipCustomClass="customized-chip"
                    variant="filled"
                    size="small"
                  />
                  <Typography component="span" variant="body1" fontWeight={600} color="primary">
                    {count}
                  </Typography>
                </Stack>
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default AudienceGroupsList;
