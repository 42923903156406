import React, { useContext, useState } from 'react'
import { If, Then, Else } from 'react-if'
import { CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from '@material-ui/core'
import { Check, Close, Edit } from '@material-ui/icons'

// src
import renderPrice from 'bos_common/src/components/Price'
import { isEmptyOrNil } from '../../utils'
import { CalendarContext } from './context/CalendarContext'

interface EditableTextFieldProps {
  defaultValue?: number,
}

const EventPaymentField = ({
  defaultValue = 0,
}: EditableTextFieldProps) => {
  const [editableInput, setEditableInput] = useState(false)
  const [error, setFieldError] = useState("")
  const [amount, setPaymentAmount] = useState(defaultValue)
  const [processing, setProcessing] = useState(false)

  const { updateEventPaymentAmount } = useContext(CalendarContext)

  const onUpdatePayment = async () => {
    if (amount !== defaultValue) {
      setProcessing(true)
      if (amount < 0) {
        setFieldError('Amount should be larger than 0')
        return;
      }

      const response: number = await updateEventPaymentAmount(amount)
      setProcessing(false)
      setPaymentAmount(response)
    }
    setEditableInput(false)
  }

  const cancelPayment = () => {
    setPaymentAmount(defaultValue);
    setEditableInput(false)
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <If condition={editableInput}>
        <Then>
          <div>
            <TextField
              autoFocus
              value={amount}
              variant="standard"
              type="number"
              size='small'
              disabled={processing}
              inputProps={{ step: '0.01', min: '0' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                sx: { fontSize: 16 }
              }}
              sx={{ width: 120 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPaymentAmount(Number(event.target.value))}
            />
            <If condition={!isEmptyOrNil(error)}>
              <Typography color="error">{error}</Typography>
            </If>
          </div>
          <div>
            <If condition={processing}>
              <Then>
                <CircularProgress size={20} />
              </Then>
              <Else>
                <IconButton size="small" color='primary' onClick={onUpdatePayment}><Check fontSize='small' /></IconButton>
                <IconButton size="small" onClick={cancelPayment}><Close fontSize='small' /></IconButton>
              </Else>
            </If>
          </div>
        </Then>
        <Else>
          <Typography variant='body2'>{renderPrice(amount)}</Typography>
          <IconButton size="small" color='primary' onClick={() => setEditableInput(true)}><Edit fontSize='small' /></IconButton>
        </Else>
      </If>
    </Stack>
  )
}

export default EventPaymentField