import React from "react";
import { rgba } from "polished";
import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 100,
      background: rgba(theme.palette.background.paper, 0.5),
    },
  })
);

type FullScreenLoaderProps = {
  loading: boolean;
}

const FullScreenLoader = (props: FullScreenLoaderProps) => {
  const { loading } = props;

  const classes = useStyles();

  if(!loading) return null;

  return (
    <div className={classes.root}>
      <CircularProgress disableShrink />
    </div>
  )
}

export default FullScreenLoader;
