import React from "react";
import { Fab } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { getMarketPlaceMerchantURL } from "../bos_common/src/services/urls";
import { Merchant } from "../services/models";
import { useTranslation } from "react-i18next";

type MerchantPreviewButtonProps = {
  merchant?: Merchant
}
const MerchantPreviewButton = (props: MerchantPreviewButtonProps) => {
  const { merchant } = props
  if (!merchant) return null;
  const { t } = useTranslation();

  return (
    <Fab variant="extended" color="secondary">
      <Visibility />
      <a target='_blank' rel="noreferrer" href={getMarketPlaceMerchantURL(merchant)}>{t("Preview")}</a>
    </Fab>
  )
}

export default MerchantPreviewButton;