import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defaultTo, pathOr, remove } from 'ramda';
import { Select, MenuItem, IconButton, Chip } from '@material-ui/core';
import { AddCircleOutline, DeleteOutline } from '@material-ui/icons';

import { Shifts } from './types';
import { getHourRanges, HourOption } from '../../utils';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity } from '../../types/NotificationSlice';
import { AppContext } from '../../context/AppContext';

type SpecialHoursShiftsProps = {
  currentHourIndex: number;
};

const SpecialHoursShifts = (props: SpecialHoursShiftsProps) => {
  const { currentHourIndex } = props;

  const [hoursOptions, setHoursOptions] = useState<Array<HourOption>>([]);

  const { t } = useTranslation();

  const { setValue, control, watch, register } = useFormContext();

  const specialHoursList = watch('specialHours', []);
  const SpecialHoursShiftsList: Shifts[] = defaultTo([], watch(`specialHours[${currentHourIndex}].data.shifts`))

  useEffect(() => {
    setHoursOptions(getHourRanges());
  }, []);

  const handleAddShiftClick = () => {
    setValue(
      `specialHours[${currentHourIndex}].data.shifts`,
      [...SpecialHoursShiftsList, { enabled: true, endTimeOffset: undefined, startTimeOffset: undefined }]
    )
  };

  const handleDeleteShiftClick = (shiftIndex: number) => {
    if(SpecialHoursShiftsList.length <= 1) {
      setValue('specialHours', remove(currentHourIndex, 1, specialHoursList))
      return;
    }
    const updatedShifts = remove(shiftIndex, 1, SpecialHoursShiftsList)

    register(`specialHours[${currentHourIndex}].data.shifts`)
    setValue(
      `specialHours[${currentHourIndex}].data.shifts`,
      [...updatedShifts],
      { shouldValidate: true, shouldTouch: true }
    )
  };

  const isHourValueInvalid = (
    currentHourIndex: number,
    currentDateOffset: number,
    currentShiftIndex: number
  ) => {
    const hours = pathOr([], [currentHourIndex, 'data', 'shifts'], specialHoursList);

    const filteredHours = hours.filter(
      (item: Shifts, index: number) =>
        currentDateOffset >= (item?.startTimeOffset || 0) &&
        currentDateOffset <= (item?.endTimeOffset || 0) &&
        currentShiftIndex !== index
    )

    if (filteredHours.length > 0) return true;
    return false;
  };

  const handleTimeChange = (
    timeValue: number,
    timeIndex: number,
    timeType: 'startTimeOffset' | 'endTimeOffset'
  ) => {
    setValue(`specialHours[${currentHourIndex}].data.shifts[${timeIndex}].${timeType}`, timeValue);
  };

  return (
    <>
      {SpecialHoursShiftsList.map(
        (shift: Shifts, shiftIndex: number) => {
          return (
            <div className="dateRangeContent" key={`shift-${currentHourIndex}-${shiftIndex}`}>
              <Select
                defaultValue={shift.startTimeOffset}
                variant="outlined"
                required
                size="small"
                fullWidth
                sx={{ maxWidth: '265px' }}
                onChange={(e) => handleTimeChange(e.target.value, shiftIndex, 'startTimeOffset')}
              >
                {hoursOptions?.map((option) => (
                  <MenuItem
                    key={option.label}
                    value={option.minuteOffset}
                    disabled={isHourValueInvalid(currentHourIndex, option.minuteOffset, shiftIndex)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              -
              <Select
                defaultValue={shift.endTimeOffset}
                variant="outlined"
                required
                size="small"
                fullWidth
                sx={{ maxWidth: '265px' }}
                disabled={shift.startTimeOffset === undefined}
                onChange={(e) => handleTimeChange(e.target.value, shiftIndex, 'endTimeOffset')}
              >
                {hoursOptions?.map((option) => (
                  <MenuItem
                    key={option.label}
                    value={option.minuteOffset}
                    disabled={
                      option.minuteOffset <= (shift?.startTimeOffset || 0) ||
                      isHourValueInvalid(currentHourIndex, option.minuteOffset, shiftIndex)
                    }
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              <IconButton
                color="primary"
                component="span"
                size="small"
                onClick={() => handleDeleteShiftClick(shiftIndex)}
              >
                <DeleteOutline color="error" />
              </IconButton>
            </div>
          );
        }
      )}

      <div className="divider" />

      <div className="actionsWrapper">
        <Chip
          className="roundedButton"
          icon={<AddCircleOutline sx={{ width: 21, height: 21 }} />}
          label={t("AddNewShift")}
          onClick={() => handleAddShiftClick()}
        />
      </div>
    </>
  );
};

export default SpecialHoursShifts;
