/* eslint-disable import/no-cycle */
import { BaseIncrement } from '../BaseIncrement';
import { Merchandise } from '../MerchandiseType';
import { Merchant } from '../MerchantType';
import CalendarEvent from './CalendarEventType';
import EnrolledCustomer from './EnrolledCustomerType';
import merchantPackage from './MerchantPackageType';

export enum EventRepetitionOptions {
  once = 'once',
  daily = 'daily',
  weekly = 'weekly',
  // monthly = 'monthly',
  weekdays = 'weekdays'
}

// timestamp and array of customerIds enrolled customer
export interface CalendarSchedule extends BaseIncrement {
  merchantPackage?: merchantPackage;
  merchantPackageId: number;

  merchandise?: Merchandise;
  merchandiseId?: string;

  merchantId: string;
  merchant?: Merchant;

  repeat: EventRepetitionOptions;

  startDate: Date;
  endDate: Date;
  startTimeOffset: number;
  endTimeOffset: number;

  // days when the service is scheduled [0-7] [Sunday-Saturday]
  daysOfWeek: number[];
  occurrences: number;

  calendarEvent?: CalendarEvent[];
}