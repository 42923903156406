import {
  CardContent,
  createStyles,
  Divider,
  Fab,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import {
  Alarm,
  Restore,
  ChevronRight,
  Print,
  Textsms,
  MoneyOff,
  DoNotDisturb,
} from '@material-ui/icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { PRIORITIZED_ORDERS_LIST } from '../../../../constants/localStorageKeys';
import { AppContext } from '../../../../context/AppContext';
import { MerchantOrdersContext } from '../../../../context/MerchantOrders/MerchantOrdersContext';
import { Order, OrderStatus, OrderType } from '../../../../services/models';
import { NotificationSeverity } from '../../../../types/NotificationSlice';
import { LocalStorageUtils } from '../../../../utils/LocalStorageUtils';
import { isOrderReminderLimitExceeded } from '../../../../utils/orderUtils';
import OrderInfo from '../../OrderInfo';
import { OrderActionsContext } from '../context/OrderActionsContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .order-header-container': {
        marginBottom: theme.spacing(2),
      },

      '& .order-actions-container': {
        display: 'flex',
        flexDirection: 'column',

        '& .order-action-fab': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60px',
          width: '100%',
          margin: `${theme.spacing(2)} 0`,
          borderRadius: '5px',

          '& .label-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',

            '& .MuiSvgIcon-root': {
              marginRight: theme.spacing(1),
            },
          },
        },
      },
    },
  })
);

type OrderActionFabProps = {
  onClick: (e: any) => void;
  label: string;
  icon: React.ReactElement;
  color: 'primary' | 'secondary';
  showChevron?: boolean;
  disabled?: boolean;
};
const OrderActionFab = ({
  onClick,
  label,
  color,
  icon,
  showChevron = true,
  disabled = false,
}: OrderActionFabProps) => {
  return (
    <Fab
      variant="extended"
      color={color}
      className="order-action-fab"
      component="button"
      onClick={onClick}
      disabled={disabled}
    >
      <div className="label-container">
        {icon}
        <span>{label}</span>
      </div>

      {showChevron && <ChevronRight />}
    </Fab>
  );
};

type OrderActionSelectorProps = {
  order: Order;
  closeDrawer?: () => void;
};

const OrderActionSelector = (props: OrderActionSelectorProps) => {
  const { order, closeDrawer = () => { } } = props;
  const classes = useStyles();
  const { setOrderAction, handleSetOrderStatus } = useContext(OrderActionsContext);
  const { merchant, localPrinter, triggerNotification } = useContext(AppContext);
  const { isUserOneMOperator, merchants } = useContext(MerchantOrdersContext);
  const { t } = useTranslation();

  if (!order) return null;

  const RenderOrderHeader = () => {
    return (
      <div className="order-header-container">
        <Typography gutterBottom variant="h6" component="h6">
          {t('Order_Actions')}
        </Typography>
        <OrderInfo order={order} merchant={merchants[order.merchantId] || merchant} />
      </div>
    );
  };

  const prioritizeOrder = () => {
    const lsUtils = new LocalStorageUtils(PRIORITIZED_ORDERS_LIST);
    const prioritizedOrderKey = `${order.merchantId}-${order.id}`;
    const onSuccess = () => {
      triggerNotification(
        true,
        `Order for ${order.userDisplayName} is prioritized`,
        NotificationSeverity.SUCCESS
      );
    };

    if (!lsUtils.findItemInList((key: string) => key === prioritizedOrderKey))
      lsUtils.appendToList(prioritizedOrderKey);

    handleSetOrderStatus(order.id, order.status, onSuccess);
    closeDrawer();
  };

  const recallOrder = () => {
    const onSuccess = () =>
      triggerNotification(
        true,
        `Order for ${order.userDisplayName} is recalled successfully`,
        NotificationSeverity.SUCCESS
      );
    if (order.status !== OrderStatus.PAID) {
      handleSetOrderStatus(order.id, OrderStatus.PAID, onSuccess);
    }
    closeDrawer();
  };

  const markAsUnpaid = () => {
    const onSuccess = () =>
      triggerNotification(
        true,
        `Order for ${order.userDisplayName} is marked as unpaid`,
        NotificationSeverity.SUCCESS
      );
    if (order.status === OrderStatus.PAID_EXTERNALLY || order.status === OrderStatus.VOIDED) {
      handleSetOrderStatus(
        order.id,
        order.voidOrSkipPaymentInfo?.priorStatus || OrderStatus.OPEN_CHECK,
        onSuccess
      );
    }
    closeDrawer();
  };

  const hasKitchenPrinter =
    localPrinter || merchant?.device?.labelPrinterID || merchant?.device?.kitchenPrinterID;
  const kitchenPrint = merchant?.device?.labelPrinterID ? t('KitchenLabels') : t('KitchenTickets');
  const showReminderAction =
    order.type === OrderType.PICKUP && order.status === OrderStatus.FULFILLED;
  const isOpenCheckOrder = order.status === OrderStatus.OPEN_CHECK;
  const isOrderVoidedOrMarkAsPaid =
    order.status === OrderStatus.VOIDED || order.status === OrderStatus.PAID_EXTERNALLY;

  let primaryAction: any = null;
  if (!isUserOneMOperator) {
    switch (order.status) {
      case OrderStatus.PAID:
      case OrderStatus.CONFIRMED:
      case OrderStatus.OPEN_CHECK:
        primaryAction = {
          label: t('Order_Button_Priortize'),
          action: prioritizeOrder,
          icon: <Alarm />,
        };
        break;
      case OrderStatus.FULFILLED:
        primaryAction = {
          label: t('Recall'),
          action: recallOrder,
          icon: <Restore />,
        };
        break;
      case OrderStatus.PAID_EXTERNALLY:
      case OrderStatus.VOIDED:
        primaryAction = {
          label: t('MarkAsUnpaid'),
          action: markAsUnpaid,
          icon: <Restore />,
        };
        break;
      default:
        break;
    }
  }

  /* FOR MERCHANT ADMIN / STAFF ACTIONS */
  const OrderActionSelectionTemplate = () => {
    return (
      <div className="order-actions-container">
        {primaryAction && (
          <>
            <OrderActionFab
              label={primaryAction.label}
              icon={primaryAction.icon}
              onClick={() => {
                primaryAction.action();
              }}
              color="primary"
              showChevron={false}
            />
            <Divider />
          </>
        )}

        <If condition={hasKitchenPrinter}>
          <OrderActionFab
            label={kitchenPrint}
            icon={<Print />}
            onClick={() => setOrderAction('kitchenPrint')}
            color="secondary"
          />
        </If>

        <If condition={merchant?.device?.receiptPrinterID}>
          <OrderActionFab
            label={t('UserReceipt')}
            icon={<Print />}
            onClick={() => setOrderAction('printReceipt')}
            color="secondary"
          />
        </If>

        <If condition={isOpenCheckOrder}>
          <>
            <OrderActionFab
              label={t('Order_Button_Void')}
              icon={<MoneyOff />}
              onClick={() => setOrderAction('voided')}
              color="secondary"
            />
          </>
        </If>

        <OrderActionFab
          label={isOpenCheckOrder || isOrderVoidedOrMarkAsPaid ? t('Cancel') : t('Order_Refund')}
          icon={<DoNotDisturb />}
          onClick={() => setOrderAction('refund')}
          color="secondary"
        />

        <If condition={showReminderAction}>
          <OrderActionFab
            label={
              !isOrderReminderLimitExceeded(order)
                ? t('RemindCustomer')
                : t('Order_Button_ReminderAlreadySent')
            }
            icon={<Textsms />}
            onClick={() => setOrderAction('reminder')}
            color="secondary"
            disabled={isOrderReminderLimitExceeded(order)}
          />
        </If>
      </div>
    );
  };

  /* FOR OPERATOR ACTIONS */
  const OperatorOrderActionSelectionTemplate = () => {
    return (
      <div className="order-actions-container">
        <OrderActionFab
          label="Refund"
          icon={<DoNotDisturb />}
          onClick={() => setOrderAction('refund')}
          color="secondary"
        />
      </div>
    );
  };

  return (
    <CardContent className={classes.root}>
      <RenderOrderHeader />
      <If condition={isUserOneMOperator}>
        <Then>
          <OperatorOrderActionSelectionTemplate />
        </Then>
        <Else>
          <OrderActionSelectionTemplate />
        </Else>
      </If>
    </CardContent>
  );
};

export default OrderActionSelector;