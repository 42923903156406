import React, { useState, useEffect, useRef } from 'react';

type WINDOW_STORAGE_TYPES = 'localStorage' | 'sessionStorage'

// Hook
export function useWindowStorage<T>(
  key: string,
  initialValue: T,
  storageType: WINDOW_STORAGE_TYPES = 'localStorage'
): [T, (value: T | ((val: T) => T)) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window[storageType].getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      // adding timeout 0 to make sure state updates correctly
      // otherwise state does not update.
      // TODO: look into this update issue
      setTimeout(() => {
        setStoredValue(valueToStore);
      },0)
      // Save to local storage
      window[storageType].setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

// Hook to execute a Async function with specific Interval
export const useFetchApiOnInterval = (fetcherFinction: any, interval: number, exponentialBackoff?: boolean) => {
  let timer: NodeJS.Timeout;
  let newInterval: number = interval;
  const executeFunc = () => {
    fetcherFinction().then((res: any) => {
      if (res.status === 200) {
        timer = setTimeout(executeFunc, newInterval)
        newInterval = (exponentialBackoff === true) ? newInterval * 2 : newInterval;
      } else {
        clearTimeout(timer)
      }
    }).catch((err: any) => {
      console.error(err);
      clearTimeout(timer)
    })
  }

  useEffect(() => {
    timer = setTimeout(executeFunc, newInterval)
    return () => {
      clearTimeout(timer)
    }
  }, [fetcherFinction])
}

// Hook to execute a Async function with specific Interval
export const useFunctionOnInterval = (func: any, interval: number, immediate?: boolean, exponentialBackoff?: boolean): React.MutableRefObject<NodeJS.Timeout | undefined> => {
  const timerRef = useRef<NodeJS.Timeout>();
  let newInterval: number = interval;
  const executeFunc = () => {
    try {
      func();
      newInterval = (exponentialBackoff === true) ? newInterval * 2 : newInterval;
      timerRef.current = setTimeout(executeFunc, newInterval)
    } catch (err) {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }

  useEffect(() => {
    if (immediate === true) {
      try {
        func();
      } catch (err) {
        if (timerRef.current) clearTimeout(timerRef.current)
      }
    }

    timerRef.current = setTimeout(executeFunc, newInterval)
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [func])

  return timerRef;
}

/**
 * Use setInterval with Hooks in a declarative way.
 *
 * @see https://stackoverflow.com/a/59274004/3723993
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(
  callback: React.EffectCallback,
  delay: number | null,
): React.MutableRefObject<number | null> {
  const intervalRef = useRef<number | null>(null);
  const callbackRef = useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be reset.

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval:

  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current || 0);
    }
  }, [delay]);

  // In case you want to manually clear the interval from the consuming component...:
  return intervalRef;
}