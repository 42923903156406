import React from "react";
import { createStyles, makeStyles, Stack, Theme } from '@material-ui/core';

import { FullscreenPaper } from "bos_common/src";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .pageContent': {
        padding: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
        gridGap: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'unset',
        },
        [theme.breakpoints.between('sm', 'md')]: {
          gridTemplateColumns: '200px 1fr',
        },
      },

      '& .side-menu': {
        '& .menuListContainer': {
          [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: '2%',
          },
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: '95vw',
          paddingTop: theme.spacing(4),
        },
      },
    },
  })
);

type TwoColumnLayoutProps = {
  children: React.ReactElement;
  leftSide: React.ReactElement;
}

const TwoColumnLayout = (props: TwoColumnLayoutProps) => {
  const { leftSide, children } = props;

  const classes = useStyles();

  return (
    <FullscreenPaper className={classes.root}>
      <Stack direction="row" className="pageContent">
        <div className="side-menu">
          <div className="menuListContainer">
            {leftSide}
          </div>
        </div>

        {children}
      </Stack>
    </FullscreenPaper>
  )
}

export default TwoColumnLayout;
