import React from "react"
import {
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Else, If, Then } from "react-if";

import { ChangeAppliedTo } from "bos_common/src/types/crm/CustomerScheduleEntryType";
import { isEmptyOrNil } from "bos_common/src";

import CustomDialog from "../common/CustomDialog";
import { RecurringOptionsType } from "./CalendarEventForm";

export type RecurringEventDialogProps = {
  title: string;
  open: boolean;
  setOpen: (_: boolean) => void;
  handleFormSubmit: any;
  isSubmitButtonEnabled: boolean;
  changesAppliedTo: ChangeAppliedTo;
  setChangesAppliedTo: (_: ChangeAppliedTo) => void;
  recurringDialogValues?: RecurringOptionsType[];
}

const RecurringEventDialog = (props: RecurringEventDialogProps): JSX.Element | null => {
  const { title, open, setOpen, handleFormSubmit, isSubmitButtonEnabled, setChangesAppliedTo, recurringDialogValues } = props;
  if (!open) return null;

  const { t } = useTranslation()

  const toggleDialog = () => {
    setOpen(!open);
  }

  const handleSaveClick = async () => {
    handleFormSubmit();
  }

  return (
    <CustomDialog
      title={title}
      open={open}
      setOpen={setOpen}
      onClose={toggleDialog}
      maxWidth="sm"
      fullWidth
      footerActions={
        <DialogActions sx={{ justifyContent: 'center', width: "100%" }}>
          <Fab
            onClick={handleSaveClick}
            disabled={!isSubmitButtonEnabled}
            variant="extended"
            color="primary"
            sx={{ textTransform: "unset", pl: 7, pr: 7 }}
          >
            {t("Save")}
          </Fab>
        </DialogActions>
      }
    >
      <DialogContent>
        <FormControl>
          <RadioGroup defaultValue={ChangeAppliedTo.currentEvent} onChange={(event: any) => setChangesAppliedTo(event.target.value)}>
            <If condition={!isEmptyOrNil(recurringDialogValues)}>
              <Then>
                {
                  recurringDialogValues?.map((data: RecurringOptionsType) => (
                      <FormControlLabel key={data.value} value={data.value} control={<Radio />} label={data.title} />
                  ))
                }
              </Then>
              <Else>
                <FormControlLabel value={ChangeAppliedTo.currentEvent} control={<Radio />} label={t('Calendar_RecurringCurrentEvent')} />
                <FormControlLabel value={ChangeAppliedTo.futureEvent} control={<Radio />} label={t('Calendar_RecurringCurrentAndFollowing')} />
                <FormControlLabel value={ChangeAppliedTo.allEvents} control={<Radio />} label={t('Calendar_RecurringAllEvents')} />
              </Else>
            </If>
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </CustomDialog>
  )
}

export default RecurringEventDialog;