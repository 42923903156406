import React, { useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  useTheme,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { append, propOr } from 'ramda';

import { getAuthHeaders } from '../../utils';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { AppContext, MerchantWithMerchandises } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';

interface FormValues {
  identifier: string;
  slogan: string
}

type NewQRCodeDialogProps = {
  isOpen: boolean,
  onClose: () => void
}
const NewQRCodeDialog = (props: NewQRCodeDialogProps) => {
  const { token } = useContext(UserContext);
  const { merchant, setMerchant } = useContext(AppContext);
  const { isOpen, onClose } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const [{ loading }, executePost] = useAxios(
    {
      url: `/merchants/${merchant?.id}/qrcode`,
      method: 'POST',
      headers: getAuthHeaders(token)
    },
    { manual: true }
  );

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
      isValid
    },
    reset
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      slogan: "Scan to Order, Get Rewards"
    }
  });

  const registerTextField = (name: keyof FormValues, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  const handleClose = () => {
    reset()
    onClose();
  }

  const addNewQRCode = async (formValues: FormValues) => {
    const data = { ...formValues, type: 'table' }
    executePost({ data })
      .then((response) => {
        if (response.status === 200) {
          const updatedMerchant = {
            ...merchant,
            qrCodes: append({ id: response?.data?.id, ...data }, propOr([], 'qrCodes', merchant))
          } as MerchantWithMerchandises
          setMerchant(updatedMerchant);
        }
      })
    handleClose()
  }

  const isDuplicateIdentifierName = (value: string) => {
    if (!value) return;
    return !Boolean(merchant?.qrCodes?.find((item) => item.identifier === value)) || "Please enter a unique identifier"
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(addNewQRCode)}>
        <DialogTitle>{t("QRCode_NewTableQRCode")}</DialogTitle>

        <DialogContent>
          <Grid container justifyContent="space-evenly" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label={t("QRCode_NewTableQRCode_TableIdentifier")}
                helperText={errors.identifier?.message || t("QRCode_NewTableQRCode_TableIdentifier_Tips")}
                error={!!errors.identifier}
                {...registerTextField('identifier', {
                  required: t("QRCode_NewTableQRCode_TableIdentifier_Error"),
                  validate: { isDuplicateIdentifierName }
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("QRCode_NewTableQRCode_Slogan")}
                helperText={errors.slogan?.message || t("QRCode_NewTableQRCode_Slogan_Tips")}
                error={!!errors.slogan}
                {...registerTextField('slogan', {
                  validate: {
                    lessThan30Chars: (value: string) => value && (value.length <= 30 || t("QRCode_NewTableQRCode_Slogan_ValidateText")),
                  }
                })}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-evenly', paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) }}>
          <Fab
            variant='extended'
            size="medium"
            color="secondary"
            onClick={handleClose}
          >
            {t("Cancel")}
          </Fab>
          <Fab
            color='primary'
            variant='extended'
            size="medium"
            disabled={isSubmitting || !isValid || loading}
            type="submit"
          >
            {t("QRCode_NewTableQRCode_AddTable")}
          </Fab>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default NewQRCodeDialog;