import {
  Alert,
  Box, Button, createStyles, Divider, FormHelperText, Grid, GridSize, IconButton, InputLabel, ListSubheader,
  makeStyles, MenuItem, Popover, Select, Stack, TextField, Theme, Typography, useMediaQuery
} from "@material-ui/core";
import { Cancel, Delete, Edit, Save } from "@material-ui/icons";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { addDays, differenceInMinutes, isEqual, isFuture } from "date-fns";
import { omit, pathOr, pluck, propOr } from "ramda";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Else, If, Then } from "react-if";

import { isEmptyOrNil } from "bos_common/src";
import axios from "bos_common/src/services/backendAxios";
import { getMinOffset, getRoundedMinutesOffset } from "bos_common/src/services/hoursUtils";
import { EventRepetitionOptions } from "bos_common/src/types/crm/CalendarScheduleType";
import CustomerScheduleEntry, { ChangeAppliedTo } from "bos_common/src/types/crm/CustomerScheduleEntryType";
import renderPrice from "bos_common/src/components/Price";
import { MerchantType } from "bos_common/src/types/MerchantType";
import { UserContext } from "bos_common/src/context/UserContext";

import { AppContext } from "../../context/AppContext";
import { getHourRanges, userIsAdmin } from "../../utils";
import { CalendarContext } from "./context/CalendarContext";
import Accordion from "./Accordion";
import AttendeesSection from "./AttendeesSection";
import RecurringEventDialog from "./RecurringEventDialog";
import { BigCalendarEvent, CalenderEventPayload } from "./types";
import { getDateTimeString, isEventOlderThanOneWeek } from "./utils";
import EventPaymentField from "./EventPaymentField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .drawerHeaderEdit": {
        [theme.breakpoints.down('sm')]: {
          flexDirection: "column-reverse",
          alignItems: "flex-start",
          gridGap: theme.spacing(1),
        },
      },

      "& .drawerCTAs": {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          alignSelf: "flex-start"
        }
      },

      "& .drawerCTAsEdit": {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          alignSelf: "flex-end"
        }
      },

      "& .MuiAccordionSummary-content": {
        margin: `${theme.spacing(1)} 0`
      },

      "& .MuiAccordionSummary-root ": {
        minHeight: 'unset',
      },
    },
  })
);

interface FormValues extends Omit<CalenderEventPayload, 'merchandiseId' | 'attendees' | 'merchantPackageId'> {
  service: `{
      type: 'merchandise' | 'package',
      value: string | number
    }`,
  attendees?: CustomerScheduleEntry[],
  changeAppliedTo?: ChangeAppliedTo,
  startTimeOffset: number,
  endTimeOffset: number
}

type CalendarEventFormProps = {
  eventData: Partial<BigCalendarEvent>;
  closeDrawer: () => void;
}

export type ShowDrawerStateType = {
  isOpen: boolean;
  type: "edit" | "delete";
}

export type RecurringOptionsType = {
  title: string;
  value: ChangeAppliedTo;
}

const hoursList = getHourRanges();
const getNameFromList = (list: any[], value: string | number, key: string = 'id', nameLabelProp: string = 'name') => {
  const item = list.find((i: any) => i[key] === value)
  return propOr('', nameLabelProp, item)
}

const getFormDefaultValues = (eventData: Partial<BigCalendarEvent>, isServicesMerchant: boolean) => ({
  title: eventData?.title,
  date: eventData.start,
  repeat: eventData.calendarSchedule?.repeat,
  repeatsUntilDate: eventData.calendarSchedule?.endDate,
  notes: eventData.notes ?? "",
  attendees: eventData.customerEntries ?? [],
  startTimeOffset: getMinOffset(eventData?.start),
  endTimeOffset: getMinOffset(eventData?.end),
  ...(isServicesMerchant && {
    serviceTips: {
      amount: eventData.serviceTips?.amount ?? 0,
    }
  })
})

type FormFieldWrapperTypes = {
  children: React.ReactChild | React.ReactChild[],
  label: string,
  id?: string,
  required?: boolean,
  fieldError?: FieldError,
  gridOptions?: {
    xs?: GridSize | boolean,
    sm?: GridSize | boolean,
    md?: GridSize | boolean,
    lg?: GridSize | boolean,
  }
}

const FormFieldWrapper = ({
  required = false,
  id = '',
  label = '',
  gridOptions = { xs: 12, sm: 6 },
  fieldError,
  children
}: FormFieldWrapperTypes) => {
  return (
    <Grid item {...gridOptions}>
      <InputLabel required={required} htmlFor={id}>
        <Typography variant="body2" fontWeight={600} component="span" gutterBottom>{label}</Typography>
      </InputLabel>
      {children}
      <If condition={Boolean(fieldError)}>
        <FormHelperText error>{fieldError?.message}</FormHelperText>
      </If>
    </Grid>
  )
}

const CalendarEventForm = ({ eventData, closeDrawer }: CalendarEventFormProps) => {
  const { t } = useTranslation();
  const { packages = [], services = [], staffList, addCalendarEvent, updateCalendarEvent, deleteCalendarEvent, selectedEvent } = useContext(CalendarContext);
  const { merchant } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const isEditMode = !isEmptyOrNil(selectedEvent)
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isServicesMerchant = merchant?.type === MerchantType.services

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [readOnlyMode, setReadOnlyMode] = useState(isEditMode);
  const [showEditRecurringEventDialog, setShowEditRecurringEventDialog] = useState<boolean>(false);
  const [showDeleteRecurringEventDialog, setShowDeleteRecurringEventDialog] = useState<boolean>(false);
  const [changesAppliedTo, setChangesAppliedTo] = useState<ChangeAppliedTo>(ChangeAppliedTo.currentEvent);
  const [recurringDialogValues, setRecurringDialogValues] = useState<RecurringOptionsType[]>([
    {
      title: t('Calendar_RecurringCurrentEvent'),
      value: ChangeAppliedTo.currentEvent,
    },
    {
      title: t('Calendar_RecurringCurrentAndFollowing'),
      value: ChangeAppliedTo.futureEvent,
    },
    {
      title: t('Calendar_RecurringAllEvents'),
      value: ChangeAppliedTo.allEvents,
    },
  ]);

  const open = Boolean(anchorEl);

  const methods = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: getFormDefaultValues(eventData, isServicesMerchant)
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { isValid, errors }
  } = methods;

  // @ts-ignore
  const watchStartTimeOffset = watch('startTimeOffset');
  const watchService = watch('service');
  const watchEndTimeOffset = watch('endTimeOffset');
  const watchEventDate = watch('date');
  const watchRepeatsUntilDate = watch('repeatsUntilDate');
  const watchRepeat = watch('repeat');

  const availableStaffList = useMemo(() => {
    if (!user) return []
    if (userIsAdmin(user)) return staffList.filter(staff => staff.id !== -1)
    return staffList.filter(staff => staff.userId === user.id);
  }, [user, staffList])


  const isMerchandiseService = !isEmptyOrNil(watchService) && JSON.parse(watchService).type === "merchandise";

  const endTime = getMinOffset(eventData?.end!);
  useEffect(() => {
    if (endTime > 0) return;
    // @ts-ignore
    if (endTime === 0) setValue('endTimeOffset', 60)
  }, [])

  useEffect(() => {
    trigger('repeatsUntilDate')
  }, [watchEventDate, watchRepeatsUntilDate, watchRepeat])

  // useEffect(() => {
  //   trigger('date')
  // }, [watchEventDate])

  useEffect(() => {
    trigger('endTimeOffset')
  }, [watchStartTimeOffset])

  useEffect(() => {
    if (!isMerchandiseService) return;

    setValue('repeatsUntilDate', watchEventDate)
  }, [watchRepeat])

  useEffect(() => {
    if (isEmptyOrNil(watchService)) return;

    const service = JSON.parse(watchService);
    if (
      isEmptyOrNil(service.value)
      || service.type !== "package"
      || watchRepeat === EventRepetitionOptions.once
    ) return;

    setSubmitButtonEnabled(false);
    const searchParams = new URLSearchParams()
    searchParams.append("eventDate", watchEventDate.toISOString())
    searchParams.append("repeat", watchRepeat)

    axios
      .get(`merchants/${merchant?.id}/package/${service.value}/end-date?${searchParams.toString()}`)
      .then((response) => {
        setValue("repeatsUntilDate", response.data.endDate)
        setSubmitButtonEnabled(true);
      })
  }, [watchService, watchRepeat, watchEventDate])

  const validateWeekday = (value: Date | undefined) => {
    if (!value) return
    const dateToCheck = new Date(value)
    if (watchRepeat === EventRepetitionOptions.weekdays && (dateToCheck.getDay() === 0 || dateToCheck.getDay() === 6)) {
      return "Invalid selection for this recurrence"
    }
  }

  const validateEndDate = (value: Date | undefined) => {
    if (watchRepeat === EventRepetitionOptions.once) return true;

    const diff = differenceInMinutes(new Date(value ?? new Date()), new Date(watchEventDate));

    if (diff < 0) {
      return "End Date cannot be before Start Date"
    }
  }

  const validateEndTime = (value: number) => {
    const isTimeInvalid = value <= watchStartTimeOffset;

    if (isTimeInvalid) return "End time must be greater than start time";
  }

  const validateService = (value: string) => {
    const parsedValue = !isEmptyOrNil(value) ? JSON.parse(value).value : '';

    if (isEmptyOrNil(parsedValue)) return "This field is required";
  }

  const registerTextField = (name: any, options?: any) => {
    // @ts-ignore
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDiscard = () => {
    setAnchorEl(null);
    reset();
    closeDrawer();
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isEmptyOrNil(eventData.calendarScheduleId) && eventData.calendarSchedule?.repeat !== EventRepetitionOptions.once) {
      setShowDeleteRecurringEventDialog(true);
      return;
    }

    // show warning before deleting any event where recurring dialog is not applicable
    if (isEmptyOrNil(anchorEl)) {
      setAnchorEl(event.currentTarget);
      return;
    }

    if (!isEmptyOrNil(eventData.calendarScheduleId)) {
      deleteCalendarEvent(eventData.id!, ChangeAppliedTo.currentEvent)
        .then((succeeded: boolean) => {
          if (succeeded) {
            handleDiscard();
          }
        })
    }
  }

  const eventFormSubmit = async (formValues: FormValues) => {
    const curDate = new Date();
    /*
      to determine repetition options for the updated event we need to check the following
      1/ has the start date changed or start time has changed
      2/ has the end date changed or end time has changed

      TODO: look into calculating date with time offset
    */
    const oldStartOffset = getRoundedMinutesOffset(eventData?.start ?? curDate)
    const oldEndOffset = getRoundedMinutesOffset(eventData?.calendarSchedule?.endDate ?? curDate)

    const isStartDateChanged = !isEqual(eventData?.start ?? curDate, formValues.date)
    const isStartTimeOffsetChanged = oldStartOffset !== formValues.startTimeOffset;
    const isEndDateChanged = !isEqual(eventData?.calendarSchedule?.endDate ?? curDate, formValues.repeatsUntilDate || curDate)
    const isEndTimeOffsetChanged = oldEndOffset !== formValues.endTimeOffset;

    const isStartTimeChanged = isStartDateChanged || isStartTimeOffsetChanged
    const isEndTimeChanged = isEndDateChanged || isEndTimeOffsetChanged

    const showDialog = isEditMode && !showEditRecurringEventDialog && eventData.calendarSchedule?.repeat !== EventRepetitionOptions.once

    if (showDialog) {
      let recurringOptions: RecurringOptionsType[] = []

      if (isStartTimeChanged && !isEndTimeChanged) {
        recurringOptions = [
          {
            title: t('Calendar_RecurringCurrentEvent'),
            value: ChangeAppliedTo.currentEvent,
          },
          {
            title: t('Calendar_RecurringCurrentAndFollowing'),
            value: ChangeAppliedTo.futureEvent,
          },
        ]
      } else if (isEndTimeChanged && !isStartTimeChanged) {
        recurringOptions = [
          {
            title: t('Calendar_RecurringCurrentAndFollowing'),
            value: ChangeAppliedTo.futureEvent,
          },
          {
            title: t('Calendar_RecurringAllEvents'),
            value: ChangeAppliedTo.allEvents,
          },
        ]
      } else if (!isStartTimeChanged && !isEndTimeChanged) {
        recurringOptions = [
          {
            title: t('Calendar_RecurringCurrentEvent'),
            value: ChangeAppliedTo.currentEvent,
          },
          {
            title: t('Calendar_RecurringCurrentAndFollowing'),
            value: ChangeAppliedTo.futureEvent,
          },
          {
            title: t('Calendar_RecurringAllEvents'),
            value: ChangeAppliedTo.allEvents,
          },
        ]
      }

      setRecurringDialogValues(recurringOptions);
      setShowEditRecurringEventDialog(true);
      return
    }

    setSubmitButtonEnabled(false);

    const service = JSON.parse(formValues.service)

    const attendees = !isEmptyOrNil(formValues.attendees) && { attendees: pluck('customerId', formValues.attendees) }

    const selectedChangeAppliedTo = eventData.calendarSchedule?.repeat === EventRepetitionOptions.once && formValues.repeat !== EventRepetitionOptions.once
      ? { changeAppliedTo: ChangeAppliedTo.allEvents }
      : { changeAppliedTo: changesAppliedTo, }

    const merchandiseOrPackageId = service.type === 'merchandise'
      ? { merchandiseId: service.value }
      : { merchantPackageId: Number(service.value) }

    const startTime = formValues.startTimeOffset * 60000;
    let eventDate = getDateTimeString(formValues.date, startTime);

    if (isEditMode) {
      switch (selectedChangeAppliedTo.changeAppliedTo) {
        case ChangeAppliedTo.allEvents:
        case ChangeAppliedTo.futureEvent:
          eventDate = isStartTimeChanged
            ? eventDate
            : pathOr(new Date(), ['calendarSchedule', 'startDate'], eventData)
          break;
        default:
          break;
      }
    }

    const startDate = getDateTimeString(formValues.date);
    const endDate = !isEmptyOrNil(formValues.repeatsUntilDate) ?
      getDateTimeString(formValues.repeatsUntilDate!, startTime) :
      getDateTimeString(formValues.date)

    const data: CalenderEventPayload = {
      ...omit(['service', 'startTimeOffset', 'endTimeOffset', 'repeatsUntilDate'], formValues),

      ...attendees,
      ...merchandiseOrPackageId,
      ...selectedChangeAppliedTo,

      // schedule start date
      startDate: eventDate,
      // schedule end date
      endDate,
      // event date
      date: isEditMode ? getDateTimeString(startDate, startTime) : startDate,

      duration: formValues.endTimeOffset - formValues.startTimeOffset,
      notes: formValues.notes,
    }

    const submitted: boolean = isEditMode
      ? await updateCalendarEvent(data)
      : await addCalendarEvent(data);

    if (submitted) {
      handleDiscard();
      setShowEditRecurringEventDialog(false);
    }
    // always enable the button again
    setSubmitButtonEnabled(true);
  }

  const handleFormSubmit = handleSubmit(eventFormSubmit);

  const handleDeleteEvent = async () => {
    const succeeded = await deleteCalendarEvent(eventData.id!, changesAppliedTo)
    if (succeeded) {
      handleDiscard();
      setShowDeleteRecurringEventDialog(false);
    }
  }

  const getServiceName = () => {
    const serviceData = !isEmptyOrNil(eventData.merchandiseId)
      ? services.find(i => i.id === eventData.merchandiseId)
      : packages.find(i => i.id === eventData.merchantPackageId)

    return propOr('', 'name', serviceData)
  }

  const handleConfirmCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isEmptyOrNil(eventData.calendarScheduleId)) {
      handleDiscard();
      return;
    }

    if (isEditMode && !readOnlyMode) {
      handleDiscard();
    }
    else {
      handleDelete(e);
    }
  }

  const isEditDisabled = isEventOlderThanOneWeek(selectedEvent);

  return (
    <FormProvider {...methods} >
      <Popover
        id={`${anchorEl}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1" gutterBottom>{readOnlyMode ? t('DeleteEventWarning') : t('DiscardWarning')}</Typography>

          <Stack direction="row" alignItems="center">
            <Button
              variant='contained'
              color="primary"
              size="small"
              onClick={(e) => handleConfirmCancel(e)}>
              {t('Yes')}
            </Button>
            <Button variant='text' size="small" onClick={() => setAnchorEl(null)}>{t('No')}</Button>
          </Stack>
        </Box>
      </Popover>

      <If condition={isEditDisabled}>
        <Alert severity="info" sx={{ mb: 2 }}>
          You cannot edit events older than one week.
        </Alert>
      </If>

      <form onSubmit={handleFormSubmit} className={classes.root}>
        {eventData?.id && <input type="hidden" {...register('id')} value={eventData.id} />}
        {/* // TODO: refactor this component */}
        <If condition={readOnlyMode && !isEmptyOrNil(eventData)}>
          <Then>
            <Stack className="drawerHeader" direction="row" alignItems="center" spacing={1} justifyContent="space-between" mb={1}>
              <Typography variant="h6" lineHeight={1.2}>{eventData.title}</Typography>
              <If condition={!isEditDisabled}>
                <div className="drawerCTAs">
                  <IconButton onClick={() => setReadOnlyMode(false)}><Edit /></IconButton>
                  <IconButton onClick={handleDelete}><Delete color="error" /></IconButton>
                </div>
              </If>
            </Stack>

            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" fontWeight={600} gutterBottom>{t("Service")}</Typography>
            <Typography variant="body2" >{getServiceName()}</Typography>

            <If condition={isServicesMerchant && !isFuture(addDays(eventData.date!, 1))}>
              <Then>
                <Typography variant="body2" fontWeight={600} gutterBottom sx={{ mt: 1 }}>{t("BillingStatements_Tips")}</Typography>
                <If condition={isEditDisabled}>
                  <Then>
                    <Typography variant="body2" >{renderPrice(selectedEvent?.serviceTips?.amount ?? 0)}</Typography>
                  </Then>
                  <Else>
                    <EventPaymentField defaultValue={selectedEvent?.serviceTips?.amount ?? 0} />
                  </Else>
                </If>
              </Then>
            </If>

            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" fontWeight={600} gutterBottom>{t("MerchantService_EventDate")}</Typography>
            <Typography variant="body2" >
              {getNameFromList(hoursList, watchStartTimeOffset, 'minuteOffset', 'label')} -
              {getNameFromList(hoursList, watchEndTimeOffset, 'minuteOffset', 'label')}
            </Typography>
            <If condition={!isEmptyOrNil(eventData.calendarSchedule?.repeat)}>
              <Typography variant="body2" >Repeats {eventData.calendarSchedule?.repeat}</Typography>
            </If>

            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" fontWeight={600} gutterBottom>{t("Staffs")}</Typography>
            <Typography variant="body2" >
              {eventData.staffId
                ? getNameFromList(staffList, eventData.staffId, 'id', 'displayName')
                : '-'}
            </Typography>

            <Divider sx={{ my: 1 }} />
            <Typography variant="body2" fontWeight={600} gutterBottom>{t("Customers_Note")}</Typography>
            <Typography variant="body2" >{isEmptyOrNil(eventData.notes) ? "-" : eventData.notes}</Typography>
          </Then>
          <Else>
            <Stack className="drawerHeaderEdit" direction="row" alignItems="center" spacing={2} justifyContent="space-between" mb={3}>
              <TextField
                label={t("MerchantService_EventName")}
                required
                variant="standard"
                {...registerTextField('title', { required: "The field is required" })}
                fullWidth
                sx={{ ...(!isMobile && { maxWidth: "50%" }), width: "100%" }}
              />
              <div className="drawerCTAsEdit">
                <Button
                  startIcon={<Cancel />}
                  variant="text"
                  sx={{ mr: 1 }}
                  // @ts-ignore
                  color="error"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                >
                  {t("Cancel")}
                </Button>
                <Button startIcon={<Save />} disabled={!isValid || !isSubmitButtonEnabled} type="submit" variant="contained">
                  {t("Save")}
                </Button>
              </div>
            </Stack>

            <Grid container spacing={1}>
              <FormFieldWrapper
                required
                id='grouped-select'
                label={t("Services")}
              >
                <Select
                  size="small"
                  fullWidth
                  required
                  disabled={isEditMode}
                  variant="outlined"
                  defaultValue={JSON.stringify(eventData.merchandiseId
                    ? { type: 'merchandise', value: eventData.merchandiseId }
                    : { type: 'package', value: eventData.merchantPackageId }
                  )}
                  {...registerTextField('service', { validate: validateService })}
                >
                  <ListSubheader>{t("services")}</ListSubheader>
                  {
                    services.map((data) => {
                      return (
                        <MenuItem value={JSON.stringify({ type: 'merchandise', value: data.id })} key={data.id}>{data.name}</MenuItem>
                      )
                    })
                  }

                  <ListSubheader>Packages</ListSubheader>
                  {
                    packages.map((data) => {
                      return (
                        <MenuItem value={JSON.stringify({ type: 'package', value: data.id })} key={data.id}>{data.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormFieldWrapper>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Accordion title={t("MerchantService_DateSettings")} expanded={!isEditMode || readOnlyMode}>
              <>
                <Grid container spacing={1} mb={2}>
                  <FormFieldWrapper
                    required
                    id='date-select'
                    label={t("MerchantService_Date")}
                    gridOptions={{ xs: 12, lg: 4 }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            error={Boolean(errors.date)}
                            helperText={Boolean(errors.date) ? errors.date?.message : 'mm/dd/yyyy'}
                          />
                        )}
                        {...registerTextField('date', {
                          validate: {
                            isWeekday: validateWeekday
                          }
                        })}
                        value={watchEventDate}
                        onChange={(newValue) => {
                          setValue('date', newValue || new Date());
                        }}
                      />
                    </LocalizationProvider>
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    required
                    id='start-time-select'
                    label={t("MerchantService_Start")}
                    gridOptions={{ xs: 6, lg: 4 }}
                  >
                    <Select
                      fullWidth
                      size="small"
                      required
                      id="start-time-select"
                      variant="outlined"
                      defaultValue={eventData?.start!.getHours() * 60 + eventData?.start!.getMinutes()}
                      {...registerTextField('startTimeOffset')}
                      value={watchStartTimeOffset}
                      onChange={(event) => {
                        setValue('startTimeOffset', event.target.value);

                        if (event.target.value >= watchEndTimeOffset) {
                          setValue('endTimeOffset', event.target.value + 30)
                        }
                      }}
                    >
                      {
                        hoursList.map((data) => (
                          <MenuItem value={data.minuteOffset} key={data.minuteOffset}>{data.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    required
                    id='end-time-select'
                    label={t("MerchantService_End")}
                    gridOptions={{ xs: 6, lg: 4 }}
                    fieldError={errors.endTimeOffset}
                  >
                    <Select
                      fullWidth
                      size="small"
                      required
                      id="end-time-select"
                      variant="outlined"
                      error={Boolean(errors.endTimeOffset)}
                      defaultValue={endTime}
                      {...registerTextField('endTimeOffset', {
                        validate: validateEndTime
                      })}
                      value={watchEndTimeOffset}
                      onChange={(event) => {
                        setValue('endTimeOffset', event?.target.value);
                      }}
                    >
                      {
                        hoursList.map((data) => (
                          <MenuItem value={data.minuteOffset} key={data.minuteOffset}>{data.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormFieldWrapper>
                </Grid>

                <Grid container spacing={1}>
                  <FormFieldWrapper
                    required
                    id='repeat-schedule-select'
                    label={t("MerchantService_Repeat")}
                    gridOptions={{ xs: 12, md: 6 }}
                  >
                    <Select
                      fullWidth
                      size="small"
                      required
                      id="repeat-schedule-select"
                      defaultValue={eventData.calendarSchedule?.repeat || EventRepetitionOptions.once}
                      variant="outlined"
                      {...registerTextField('repeat', { required: "The field is required" })}
                    >
                      {
                        Object
                          .keys(EventRepetitionOptions)
                          .map((option) => <MenuItem value={option} key={option}>{option === EventRepetitionOptions.once ? t("MerchantService_DoesntRepeat")  : t(option)}</MenuItem>)
                      }
                    </Select>
                  </FormFieldWrapper>

                  <If condition={watchRepeat !== EventRepetitionOptions.once}>
                    <FormFieldWrapper
                      id='repeatsUntilDate-select'
                      label="Repeats Until"
                      gridOptions={{ xs: 12, md: 6 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={!isEmptyOrNil(watchService) && JSON.parse(watchService).type === "package"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              error={Boolean(errors.repeatsUntilDate)}
                              helperText={Boolean(errors.repeatsUntilDate) ? errors.repeatsUntilDate?.message : 'mm/dd/yyyy'}
                            />
                          )}
                          {...registerTextField('repeatsUntilDate', {
                            validate: {
                              greaterThanStartDate: validateEndDate,
                              isWeekDay: validateWeekday
                            }
                          })}
                          value={watchRepeatsUntilDate}
                          onChange={(newValue) => {
                            setValue('repeatsUntilDate', newValue || new Date());
                          }}
                        />
                      </LocalizationProvider>
                    </FormFieldWrapper>
                  </If>
                </Grid>
              </>
            </Accordion>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Accordion title={t("Staff")} expanded={!isEditMode || readOnlyMode}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Select
                    fullWidth
                    size="small"
                    defaultValue={eventData.resourceId || availableStaffList[0].id}
                    id="staff-select"
                    variant="outlined"
                    {...registerTextField('staffId')}
                  >
                    {
                      availableStaffList
                        .map((data) => {
                          return (
                            <MenuItem value={data.id} key={data.id}>{data.displayName}</MenuItem>
                          )
                        })
                    }
                  </Select>
                </Grid>
              </Grid>
            </Accordion>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Accordion title={t("Customers_Notes")} expanded={!isEditMode || readOnlyMode}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...registerTextField('notes')}
                    placeholder={t("MerchantService_AdditionalInfo")}
                  />
                </Grid>
              </Grid>
            </Accordion>
          </Else>
        </If>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <AttendeesSection isEditMode={isEditMode} />

        <If condition={showEditRecurringEventDialog}>
          <RecurringEventDialog
            title={t('Calendar_EditRecurringEvent')}
            open={showEditRecurringEventDialog}
            setOpen={setShowEditRecurringEventDialog}
            isSubmitButtonEnabled={isSubmitButtonEnabled}
            handleFormSubmit={handleFormSubmit}
            changesAppliedTo={changesAppliedTo}
            setChangesAppliedTo={setChangesAppliedTo}
            recurringDialogValues={recurringDialogValues}
          />
        </If>

        <If condition={showDeleteRecurringEventDialog}>
          <RecurringEventDialog
            title={t('Calendar_DeleteRecurringEvent')}
            open={showDeleteRecurringEventDialog}
            setOpen={setShowDeleteRecurringEventDialog}
            isSubmitButtonEnabled={isSubmitButtonEnabled}
            handleFormSubmit={handleDeleteEvent}
            changesAppliedTo={changesAppliedTo}
            setChangesAppliedTo={setChangesAppliedTo}
          />
        </If>
      </form>
    </FormProvider>
  )
}

export default CalendarEventForm;
