import React, { useContext, useMemo, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { Redirect, useParams, useHistory } from 'react-router';
import { append, pluck, without } from 'ramda';
import { If } from 'react-if';

// src
import { groupMerchandiseDataByCategories } from '../bos_common/src/services/merchandiseUtils';

import { MerchandiseApiResponseType } from '../services/models';
import useFetchMerchant from '../services/useFetchMerchant';

import MerchantPageHeader from '../components/MerchantPageHeader';
import { AppContext } from '../context/AppContext';
import MerchandiseInventory from '../components/Inventory/MerchandiseInventory';
import MerchandiseModifierInventory from '../components/Inventory/MerchandiseModifierInventory';
import { ColoredPaper } from '../bos_common/src';
import { isEmptyOrNil } from '../utils';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { useTranslation } from 'react-i18next';

interface IMerchantInventoryParams {
  merchantUsername: string;
}
const InventoryPage = () => {
  const history = useHistory()
  const { merchantUsername } = useParams<IMerchantInventoryParams>();
  const { merchant, setMerchant } = useContext(AppContext);
  const { t } = useTranslation();

  const onBack = () => {
    history.goBack();
  }

  if (!merchantUsername && !merchant) {
    return <Redirect to="/" />
  }

  const loadingMerchant = useFetchMerchant({ merchantUsername })

  const [{ loading: loadingMerchandise }, refetchMerchandisesData] = useAxios<MerchandiseApiResponseType>(
    { url: '/merchants/merchandises', params: { merchantUsername, } },
    { manual : true }
  );

  const refetchMerchandises = (): void => {
    refetchMerchandisesData().then((response) => {
      if (merchant && response.status == 200) {
          setMerchant({ ...merchant, merchantData: response.data });
        }
      }
    )
  }

  useEffect(() => {
    refetchMerchandises();
  }, []);

  const loading = loadingMerchant || loadingMerchandise;

  const groupedMerchandiseData = useMemo(() => groupMerchandiseDataByCategories({
    categories: merchant?.merchantData?.mercCategories,
    merchandises: merchant?.merchantData?.merchandises,
    merchant
  }), [merchant])

  const modifiersCategory = {
    id: -10,
    displayOrder: 9999,
    merchantId: merchant?.id || '',
    name: t('Modifiers'),
    description: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    version: 0,
  }
  const showMerchandiseModifiers = !isEmptyOrNil(merchant?.merchantData?.modifiers)

  const categories = showMerchandiseModifiers
    ? append(modifiersCategory, without([null], pluck('category', groupedMerchandiseData)))
    : without([null], pluck('category', groupedMerchandiseData))

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={t("Inventory")} hideOnScroll={false} elevation={0} />
      <SimpleLoader loading={loading} />
      <ColoredPaper elevation={3}>
        <MerchandiseInventory
          categories={categories}
          loading={loading}
          merchandiseData={merchant?.merchantData}
          groupedMerchandiseData={groupedMerchandiseData}
          refetchMerchandises={refetchMerchandises}
          isToolbar
        />
        <If condition={showMerchandiseModifiers}>
          <MerchandiseModifierInventory
            modifiers={merchant?.merchantData?.modifiers}
            refetchMerchandises={refetchMerchandises}
          />
        </If>
      </ColoredPaper>
    </div>
  );
};

export default InventoryPage;