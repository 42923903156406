import React, { useMemo, useState } from "react"
import { Search } from "@material-ui/icons";
import { Stack } from "@material-ui/core";

import { isEmptyOrNil } from "bos_common/src";
import InputSearchBox from "bos_common/src/components/InputSearchBox";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { useMerchantMenuContext } from "../../../context/MenuContext/MerchantMenuContext";
import ServiceMembershipsList from "../../../components/MerchantCustomers/components/ServiceMembershipsList";

const MembershipsMenu = () => {
  const [searchQuery, setSearchQuery] = useState<string>();

  const { state } = useMerchantMenuContext();

  // Filter List based on search query
  const filteredMembershipsList: MerchantMembership[] | undefined = useMemo(() => {
    if (isEmptyOrNil(state.memberships)) return [];

    if (!searchQuery || isEmptyOrNil(searchQuery)) {
      // sort selected classes at the top of list
      return state.memberships;
    }

    return state.memberships.filter((merchandise: MerchantMembership) => {
      return merchandise.name?.toLowerCase().includes(searchQuery.toLowerCase())
    })
  }, [state.memberships, searchQuery])

  return (
    <Stack direction="column" gap={2}>
      <InputSearchBox
        placeholder="Search memberships"
        onChangeQuery={(s) => setSearchQuery(s)}
        leftChild={<Search />}
      />

      <ServiceMembershipsList memberships={filteredMembershipsList} />
    </Stack>
  )
}

export default MembershipsMenu;
