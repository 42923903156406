import React, { useContext } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ColoredPaper } from '../bos_common/src';

import { AppContext } from '../context/AppContext';
import MerchantPageHeader from '../components/MerchantPageHeader';
import useFetchMerchant from '../services/useFetchMerchant';
import MerchantCustomers from '../components/MerchantCustomers/MerchantCustomers';

interface IMerchantOrderingConfigProps {
  merchantUsername: string;
}

const MerchantCustomersPage = () => {
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantOrderingConfigProps>();
  const { merchant, setMerchant } = useContext(AppContext);
  const { t } = useTranslation();

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    history.push(`/biz/create`);
  }

  const loading = useFetchMerchant({ merchantUsername })

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={t("OrderHistory_List_Customer")} />
      {loading && <LinearProgress />}
      {merchant && (
        <ColoredPaper>
          <MerchantCustomers merchant={merchant} />
        </ColoredPaper>
      )}
    </div>
  );
};

export default MerchantCustomersPage;
