import { useState } from 'react';
import { addDays } from 'date-fns';

import { DateOptions, MerchantStatsPeriod } from '../../components/Stats/types';
import { MerchantStatsContext, MerchantStatsContextState } from './MerchantStatsContext';

const MerchantStatsContextProvider = (props: { children: React.ReactNode }): React.ReactElement => {
  const { children } = props;
  const [statsTimePeriod, setStatsTimePeriod] = useState<MerchantStatsPeriod>(
    MerchantStatsPeriod.Day
  );
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [statsDateRange, setStatsDateRange] = useState<DateOptions>({
    fromDate: today,
    toDate: addDays(today, 1),
  });
  const [report, setReport] = useState<MerchantStatsContextState['report']>({});

  return (
    <MerchantStatsContext.Provider
      value={{
        statsTimePeriod,
        setStatsTimePeriod,
        statsDateRange,
        setStatsDateRange,
        report,
        setReport,
      }}
    >
      {children}
    </MerchantStatsContext.Provider>
  );
};

export default MerchantStatsContextProvider;
