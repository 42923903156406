import { BaseIncrement } from "../BaseIncrement";
import CalendarEvent from "./CalendarEventType";
import { MerchantCustomer } from "./MerchantCustomerType";

export enum AttendeeStatus {
  CREATED = "created",
  PRESENT = "present",
  ABSENT = "absent",
  RESCHEDULED = "rescheduled",
}

export enum ChangeAppliedTo {
  currentEvent = 'current-event',
  futureEvent = 'future-events',
  allEvents = 'all-events',
}

export interface CustomerScheduleEntry extends BaseIncrement {
  customerId: number;
  customer?: MerchantCustomer;

  event?: CalendarEvent;
  eventId?: number;

  status: AttendeeStatus;
  statusUpdateTime?: Date;

  // this is the date the attendee was present in the session. This should only update when the status is AttendeeStatus.PRESENT
  note?: string;
  orderId?: string;
}

export default CustomerScheduleEntry;
