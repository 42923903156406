import React from "react";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core";

const MUIThemeProvider = (props: {
  children: React.ReactNode,
}): React.ReactElement => {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1AAA5D',
        light: `#DDF2E7`,
        dark: '#126e3c'
      },
      secondary: {
        main: '#555c58',
      },
      warning: {
        main: "#FF8E25"
      },
      error: {
        main: "#E34D28",
      },
      background: {
        paper: '#fff',
        default: '#E6E6E6',
      },
      divider: '#DDF2E7',
      action: {
        disabledBackground: '#e4e4e4',
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      "fontFamily": `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    },
    components: {
      MuiFab: {
        variants: [
          {
            props: { variant: "extended", color: "secondary" },
            style: {
              backgroundColor: "white",
              color: "#1AAA5D",
              boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 20%)",

              "a": {
                color: "#1AAA5D",
                textDecoration: "none"
              },
              "&.deleteFab": {
                color: "red"
              },
              "&:hover": {
                color: "white",

                "a": {
                  color: "white"
                }
              }
            },

          }]
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
            '& .MuiPaper-elevation0': {
              boxShadow: 'none',
            }
          }
        }
      },
    },
  })

  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  )
}

export default MUIThemeProvider