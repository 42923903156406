import { isProduction } from "../../utils"
import { Logger } from "./loggerTypes"

/**
 * Logging module
 * This file provides the public API and logs to
 * the correct place based on the current environment
 */
const logger: Logger = isProduction() ? require("./sentry").default : require("./console").default

export default logger
