import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { rgba } from "polished";

import LoginIllustration from '../../assets/images/login-illustration.svg'
import LoginIllustrationMobile from '../../assets/images/login-illustration-mobile.svg'

const useLoginPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      "& .layout-container": {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: '100%',

        [theme.breakpoints.down('sm')]: {
          gridGap: theme.spacing(3),
        },

        '&.merchant-login': {
          [theme.breakpoints.down('sm')]: {
            gridTemplateRows: 'auto 1fr',
          },

          '& .login-illustration': {
            [theme.breakpoints.down('sm')]: {
              alignItems: 'center'
            }
          }
        },

        '& .language-selector': {
          color: "#939393",
          fontSize: 18,

          [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(2),
          },

          '& .content-container': {
            padding: theme.spacing(2, 2),

            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(2),
            },
          }
        },

        '& .header-container': {
          padding: theme.spacing(2),
        },

        '& .staff-login-header': {
          display: 'flex',
          alignItems: 'center',
        },

        '& .login-illustration': {
          display: 'flex',
          alignItems: 'flex-end',

          [theme.breakpoints.up('sm')]: {
            background: `url(${LoginIllustration}) no-repeat`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          },

          '& .phoneInput': {
            height: theme.spacing(6),
          },

          '& .merchant-logo-container': {
            [theme.breakpoints.down('sm')]: {
              minHeight: "unset",
              marginBottom: theme.spacing(1),
            },
          },

          '& .login-people': {
            [theme.breakpoints.down('sm')]: {
              display: "none",
            }
          }
        },

        '& .content': {
          [theme.breakpoints.down(600)]: {
            height: theme.spacing(28),
            background: `url(${LoginIllustrationMobile}) no-repeat`,
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
          },

          [theme.breakpoints.down(500)]: {
            height: theme.spacing(20),
          },

          '& .merchant-logo-container': {
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          },
        },

        '& .login-form-container': {
          maxWidth: '400px',
          width: '100%',
          marginBottom: theme.spacing(-14),

          [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
          },

          '& .signUpActionRow': {
            marginBottom: theme.spacing(1),
          },

          '& .react-code-input': {
            marginBottom: theme.spacing(1),

            '& input': {
              [theme.breakpoints.down('sm')]: {
                width: '15vw !important', //to override inline style
                height: '15vw !important', //to override inline style
                textAlign: 'center',
                paddingLeft: "0 !important", //to override inline style
              }
            },

            '& .MuiAlert-standard passcode-error': {
              marginTop: theme.spacing(1),
            }
          },

          '& .content-container': {
            padding: theme.spacing(3),

            "& .MuiButton-contained, .MuiButtonBase-root": {
              borderRadius: theme.spacing(1),
              minHeight: theme.spacing(6),
            },
          },

          '& .divider-container': {
            position: 'relative',

            '& .login-form-divider': {
              borderColor: rgba(0, 0, 0, 0.15),
            },

            '& .or-span': {
              position: 'absolute',
              left: '50%',
              top: '-10px',
              backgroundColor: theme.palette.common.white,
              color: 'rgba(0, 0, 0, 0.6)',
              marginLeft: '-12px'
            }
          },
        },
      },

    }
  })
);

export default useLoginPageStyles;