import React, { SetStateAction } from 'react';

import { MerchandiseApiResponseType } from 'bos_common/src/types/MerchandiseType';
import { Merchant } from 'bos_common/src/types/MerchantType';
import { Order } from 'bos_common/src/types/OrderTypes';
import { FeatureFlags } from 'bos_common/src/types/FeatureFlagTypes';

import { SupportedLocales } from '../constants/locale';
import { NotificationSeverity } from '../types/NotificationSlice';

export interface MerchantWithMerchandises extends Merchant {
  merchantData?: MerchandiseApiResponseType;
}

export interface AppContextState {
  merchant?: MerchantWithMerchandises;
  setMerchant: (m: MerchantWithMerchandises) => void;

  updatedOrder?: Order;
  setUpdatedOrder: (order: Order) => void;
  triggerNotification: (
    open: boolean,
    v?: string,
    s?: NotificationSeverity,
    hideAction?: boolean
  ) => void;

  featureFlags?: FeatureFlags;

  locale?: SupportedLocales;
  setLocale: (locale: SupportedLocales) => void;

  localPrinter: string | null;
  setLocalPrinter: (_: string | null) => void;
}

export const AppContext = React.createContext<AppContextState>({
  setMerchant: (_: SetStateAction<MerchantWithMerchandises>) => { },
  setUpdatedOrder: (_: SetStateAction<Order>) => { },
  triggerNotification: (_: boolean, __?: string, ___?: NotificationSeverity) => { },
  setLocale: (_: SupportedLocales) => { },
  localPrinter: null,
  setLocalPrinter: () => { },
});
