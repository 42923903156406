import React, { useContext, useMemo, useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  CardContent,
  createStyles,
  Grid,
  useTheme,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { If, Then } from 'react-if';
import { Check, HourglassEmpty } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { OrderStatus, Merchant } from '../../../services/models';
import { FullscreenPaper, ColoredPaper } from '../../../bos_common/src/components/Papers';
import SimpleLoader from '../../../bos_common/src/components/SimpleLoader';

import OrdersHeader from '../../../components/Order/OrdersHeader';
import RenderMerchantOrders from './RenderMerchantOrders';
import { MerchantOrdersContext } from '../../../context/MerchantOrders/MerchantOrdersContext';
import { OrderTabs } from '../../../constants/orders';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomNav: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderRadius: 0,
      textAlign: 'center'
    },
    orders: {
      marginBottom: 50,

      '& .orders-container': {

        '& > div': {
          marginBottom: theme.spacing(2)
        }
      }
    },
    ordersBlankslate: {
      textAlign: 'center'
    }
  })
);

type Props = {
  merchant?: Merchant;
  loading?: boolean;
};

const MerchantOrdersPageCompact = ({ merchant, loading }: Props) => {
  const classes = useStyles();
  const { selectedTab, setSelectedTab, ordersList, getFilteredOrdersList, isUserOneMOperator } = useContext(MerchantOrdersContext)
  const theme = useTheme();
  const { t } = useTranslation();

  const StaffColumns = [
    {
      title: t('New'),
      status: OrderStatus.PAID,
      value: OrderTabs.new,
      icon: <HourglassEmpty />,
      count: getFilteredOrdersList(OrderTabs.new).length,
    },
    {
      title: t('Completed'),
      status: OrderStatus.FULFILLED,
      value: OrderTabs.completed,
      icon: <Check />,
      count: getFilteredOrdersList(OrderTabs.completed).length
    },
  ];

  const OperatorColumns = [
    {
      title: t('New'),
      status: OrderStatus.PAID,
      value: OrderTabs.new,
      icon: <HourglassEmpty />,
      count: getFilteredOrdersList(OrderTabs.new).length,
    },
    {
      title: t('Confirmed'),
      status: OrderStatus.FULFILLED,
      value: OrderTabs.completed,
      icon: <Check />,
      count: getFilteredOrdersList(OrderTabs.completed).length
    },
  ];

  const columns = isUserOneMOperator ? OperatorColumns : StaffColumns

  return (
    <div className="container">
      <OrdersHeader merchant={merchant} />
      <FullscreenPaper>
        <SimpleLoader loading={!!loading} backgroundColor={theme.palette.background.default} />
        <ColoredPaper className={classes.orders} id="order-tabs">
          <If condition={!loading && ordersList.length === 0}>
            <Then>
              <CardContent>
                <Typography variant="subtitle1" component="div" className={classes.ordersBlankslate}>
                  {t("Order_NoOrderQueue")}
                </Typography>
              </CardContent>
            </Then>
          </If>
          <If condition={ordersList.length > 0}>
            <Then>
              <Grid container>
                <Grid item xs={12} className="orders-container">
                  <RenderMerchantOrders />
                </Grid>
              </Grid>
            </Then>
          </If>
        </ColoredPaper>

        <BottomNavigation
          value={selectedTab}
          onChange={(_event, newValue) => {
            setSelectedTab(newValue);
          }}
          className={classes.bottomNav}
          component={Paper}
          showLabels>
          {columns.map((val, index) =>
            <BottomNavigationAction
              key={val.status}
              value={val.value}
              label={`${val.title} (${val.count})`}
              icon={val.icon}
              component={Link}
            />
          )}
        </BottomNavigation>
      </FullscreenPaper>
    </div >
  );
};

export default MerchantOrdersPageCompact;
