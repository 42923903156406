

import React, { PropsWithChildren, useContext } from 'react';
import { rgba } from 'polished';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Else, If, Then } from 'react-if';
import { useHistory } from 'react-router';
import { last, pathOr, propOr } from 'ramda';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { Ballot, Person, Chat as ChatIcon, DateRange, FiberManualRecord } from '@material-ui/icons';
import { Attachment, ChannelPreviewUIComponentProps, Message, useChannelStateContext, useMessageContext } from 'stream-chat-react';
import { Avatar, Typography, Button, Stack, Box, Chip, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';

import { handleBookingRedirect, isChatUserOnline, isEmptyOrNil, randomID } from '../../utils';
import { PlatformChatContext } from './context/PlatformChatContext';
import { Merchant } from '../../types/MerchantType';

const useStyle = makeStyles((theme: Theme) => createStyles({
  channelPreview: {
    cursor: "pointer",
    justifyContent: 'space-between',

    '& .str-chat__channel-preview-messenger--right': {
      flex: '1 1 auto',
    }
  },

  customMessage: {
    display: "inline-block",
    background: theme.palette.background.paper,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
    fontSize: theme.spacing(1.6),
    maxWidth: "49%",
    borderRadius: theme.spacing(1),
    wordBreak: "break-word",

    [theme.breakpoints.down('sm')]: {
      maxWidth: "70%",
    },

    "&.ownerMessage": {
      marginLeft: "auto",
      background: rgba(theme.palette.primary.main, 0.15),

      '& .str-chat__message-attachment-card': {
        borderRadius: 0,
        borderColor: theme.palette.background.paper,
        marginTop: 0,
      }
    }
  }
}))

export const RenderChannelPreview = (
  props: ChannelPreviewUIComponentProps<DefaultStreamChatGenerics> & { isChatPage?: boolean }
) => {
  const { channel, setActiveChannel, active, isChatPage = true } = props;
  const { setChatChannel, merchant, chatClient } = useContext(PlatformChatContext);
  const history = useHistory();
  const classes = useStyle();

  if (!channel) return null;
  
  const { messages = [] } = channel.state;
  
  const isCurrentUser = last(messages)?.user?.name === chatClient?.user?.name;
  const isDeletedMessage = last(messages)?.type === 'deleted';

  const message = propOr('Entered the lobby', 'text', last(messages)).slice(0, 30);
  const messagePreview = isDeletedMessage ? 'Deleted message' : `${isCurrentUser ? "You: " : ""}${message}`;

  const userName = pathOr('Unnamed Channel', ['data', 'created_by', 'name'], channel);
  const channelId = pathOr(randomID(), ['id'], channel);
  const unreadCount = pathOr(0, ['state', 'unreadCount'], channel);
  const lastMessageTime = pathOr('', ['state', 'last_message_at'], channel);

  const renderedTime = !isEmptyOrNil(lastMessageTime) ? `${format(lastMessageTime, 'p')}` : '';

  const classesList = classNames(classes.channelPreview, {
    'str-chat__channel-preview-messenger--active': active && isChatPage,
    'str-chat__channel-preview-messenger': true,
  });

  const handleChannelClick = () => {
    if (isEmptyOrNil(channel) || isEmptyOrNil(setActiveChannel)) return;
    setActiveChannel!(channel!);
    setChatChannel(channel!);

    if (!isChatPage) {
      history.push(`/${merchant?.username}/chat`);
    }
  };

  return (
    <Box className={classesList} key={channelId} onClick={handleChannelClick} component="button">
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar
          sx={{
            mr: 2,
            background: (theme) => theme.palette.warning.main,
            color: (theme) => theme.palette.background.paper,
          }}
        >
          <Person />
        </Avatar>
      </div>
      <div className="str-chat__channel-preview-messenger--right">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          className="str-chat__channel-preview-messenger--name"
        >
          <span>{userName}</span>
          <If condition={isChatUserOnline(channel)}>
            <Then>
              <FiberManualRecord
                sx={{ width: 14, height: 14, fill: (theme) => theme.palette.primary.main }}
              />
            </Then>
          </If>
        </Stack>
        <Stack
          className="str-chat__channel-preview-messenger--last-message"
          direction="row"
          gap={2}
          maxWidth="unset"
          justifyContent="space-between"
        >
          <p>{isChatUserOnline(channel) ? messagePreview : 'The user has left the lobby'}</p> {renderedTime}
        </Stack>
      </div>
      {unreadCount > 0 && (
        <div className="str-chat__channel-preview-messenger--end">
          <Chip label={unreadCount} size="small" />
        </div>
      )}
    </Box>
  );
};

type RenderBookServiceButtonProps = {
  buttonText: string; 
  merchant?: Merchant;
  handleClick: (_: string) => void;
}
export const RenderBookServiceButton = ({buttonText, merchant, handleClick}: RenderBookServiceButtonProps) => {
  if (isEmptyOrNil(merchant) || !['dance_vita_ballroom', 'kp_therapy_llc', 'no_hawaiki_nui'].includes(propOr('', 'username', merchant))) return null;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  
  let text = buttonText;
  let redirectLink = '';

  switch (merchant?.username) {
    case 'dance_vita_ballroom': {
      redirectLink = "http://www.dancevita.com/schedule";
      text = 'Book Class'
    }
      break;

    case 'no_hawaiki_nui': {
      redirectLink = "mailto:nohawaikinui@gmail.com";
      text = 'Book Class';
    }
      break;

    case 'kp_therapy_llc': {
      redirectLink = "https://ikneadkat.as.me/schedule.php";
      text = 'Book Appointment';
    }
      break;

    default:
      console.log('unknown merchant')
      return null;
  }

  return (
    <Button
      onClick={() => handleClick(redirectLink)}
      startIcon={<DateRange />}
      variant='contained'
      sx={{ borderRadius: 50, px: isMobile ? 1.5 : 2, mr: isMobile ? 0 : 2 }}
    >
      {text}
    </Button>
  )
}

type RenderMessageProps = {
  handleReadMoreEvent: () => void;
  handleBookClassEvent: () => void;
}

export const RenderMessage = (props: RenderMessageProps) => {
  const { handleReadMoreEvent, handleBookClassEvent } = props;
  
  const { message } = useMessageContext();
  const { chatClient, merchant } = useContext(PlatformChatContext);

  const classes = useStyle();

  const isCurrentUser = message?.user?.name === chatClient?.user?.name;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const messageCardClasses = classNames(classes.customMessage, {
    'ownerMessage': isCurrentUser
  })

  if (!message || message.type === 'deleted') return (
    <Typography variant='body1' fontSize="16px" className={messageCardClasses}>
      This message was deleted...
    </Typography>
  )

  if (!isEmptyOrNil(message.attachments)) {
    const attachments = propOr([], 'attachments', message);
    const url = pathOr("", ['attachments', 0, 'og_scrape_url'], message);
    
    const handleReadMore = (url: string) => {
      window?.open(url, '_blank')?.focus();
      handleReadMoreEvent();
    }

    const handleBookClassClick = (url: string) => {
      handleBookingRedirect(url);
      handleBookClassEvent();
    }

    return (
      <Stack className={messageCardClasses}>
        <Attachment attachments={attachments} />
        <If condition={!isEmptyOrNil(message?.text) && isEmptyOrNil(attachments)}>
          <Typography variant="body2" ml={1}>
            {message?.text}
          </Typography>
        </If>
        <If condition={!isEmptyOrNil(attachments[0].og_scrape_url)}>
          <Then>
            <Stack direction={ isMobile ? "column" : "row"} justifyContent="center" gap={2} mt={-1} p={2} sx={{ background: (theme) => theme.palette.background.paper }}>
              <If condition={url.includes('1m.app') && url.includes('story')}>
                <Then>
                  <RenderBookServiceButton 
                    handleClick={handleBookClassClick} 
                    buttonText='Book' 
                    merchant={merchant} 
                  />
                  <Button
                    startIcon={<Ballot />}
                    variant="contained"
                    onClick={() => handleReadMore(url)}
                    color="inherit"
                    sx={{ borderRadius: 50, px: isMobile ? 1.5 : 2, }}
                  >
                    Read more
                  </Button>
                </Then>
                <Else>
                  <Button
                    startIcon={<Ballot />}
                    variant="contained"
                    onClick={() => handleReadMore(url)}
                    color="inherit"
                    sx={{ borderRadius: 50, px: isMobile ? 1.5 : 2, }}
                  >
                    Read more
                  </Button>
                </Else>
              </If>
            </Stack>
          </Then>
        </If>
      </Stack>
    )
  }

  return (
    <Message message={message} messageActions={['react']} />
  );
};

export const MessageListWrapper = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const { messages } = useChannelStateContext();

  if (isEmptyOrNil(messages)) return (
    <Stack className="str-chat__list" alignItems="center" justifyContent="center">
      <ChatIcon sx={{ fontSize: 50, opacity: 0.3 }} />
      <Typography sx={{opacity: 0.3}}>
        No messages yet
      </Typography>
    </Stack>
  )

  return (
    children
  )
}
