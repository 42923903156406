import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { format } from 'date-fns';
import { ChevronRight } from '@material-ui/icons';
import {
  createStyles, Theme, makeStyles, TableRow, Paper, TableContainer,
  Table, TableHead, TableCell, Typography, TableBody, IconButton
} from '@material-ui/core';
import { If } from 'react-if';
import { useTranslation } from 'react-i18next';

import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { ColoredPaper } from '../bos_common/src';
import useFetchMerchant from '../services/useFetchMerchant';
import { AppContext } from '../context/AppContext';
import { Merchant } from '../services/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    }
  })
)

type BillingMonthItem = {
  fromDate: number,
  toDate: number,
  label: string,
}
const getBillingMonthsList = (merchant: Merchant): BillingMonthItem[] => {
  const list: BillingMonthItem[] = []

  const today = new Date()
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth()

  const startDate = new Date(merchant.createdAt)
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();

  let atYear = startYear;
  let atMonth = startMonth;
  while (atYear < currentYear) {
    for (let i = atMonth; i < 12; i++) {
      list.push({
        fromDate: new Date(atYear, i, 1).valueOf(),
        toDate: new Date(atYear, i + 1, 0).valueOf(),
        label: format(new Date(atYear, i), 'MMM, Y')
      })
    }
    atYear += 1;
    atMonth = 0;
  }

  if (atYear === currentYear) {
    for (let i = atMonth; i < currentMonth; i++) {
      list.push({
        fromDate: new Date(atYear, i, 1).valueOf(),
        toDate: new Date(atYear, i + 1, 0).valueOf(),
        label: format(new Date(atYear, i), 'MMM, Y')
      })
    }
    atYear += 1;
    atMonth = 0;
  }

  return list;
}

interface IMerchantOfflinePresenceParams {
  merchantUsername: string;
}

const MerchantBillingStatementsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { merchant } = useContext(AppContext);
  const { merchantUsername } = useParams<IMerchantOfflinePresenceParams>();
  const [billingMonths, setBillingMonths] = useState<Array<BillingMonthItem>>();
  const { t } = useTranslation()

  const onBack = () => {
    history.goBack();
  };

  const loading = useFetchMerchant({merchantUsername})

  useEffect(() => {
    if (merchant) {
      setBillingMonths(getBillingMonthsList(merchant));
    }
  }, [merchant]);

  return (
    <div className="container">
      <ColoredPaper>
        <SimpleLoader loading={loading} />
        <If condition={!!merchant}>
          <div className={classes.root}>
            <TableContainer component={Paper}>
              <Table aria-label="stats table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <Typography><b>{t("BillingStatements_BillingMonth")}</b></Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingMonths?.map((item: BillingMonthItem) => (
                    <TableRow
                      key={`${item.fromDate}-${item.toDate}`}
                      onClick={() => history.push(`/${merchantUsername}/monthly-statement?to=${item.toDate}&from=${item.fromDate}`)}>
                      <TableCell component="td" scope="row">
                        {item.label}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary">
                          <ChevronRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </If>
      </ColoredPaper>
    </div>
  );
};

export default MerchantBillingStatementsPage;
