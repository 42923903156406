import { AxiosRequestConfig } from 'axios';
import { LOCAL_SERVICE_URL } from '../constants/localAndroidService';

export type Printer = {
  name: string;
  type: string;
  device: string;
  address: string;
};

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

/**
 * discovery printer
 */
export const localDiscovery: AxiosRequestConfig = {
  baseURL: LOCAL_SERVICE_URL,
  url: '/discovery',
  method: 'GET', 
};

/**
 *  print
 */
export const localPrint: AxiosRequestConfig = {
  baseURL: LOCAL_SERVICE_URL,
  url: '/print',
  headers,
  method: 'POST',
};

/**
 * set default printer
 */
export const defaultPrinter: AxiosRequestConfig = {
  baseURL: LOCAL_SERVICE_URL,
  url: '/default-printer',
  headers,
  method: 'POST',
};

/**
 * set default websocket
 */
export const localWebsocket: AxiosRequestConfig = {
  baseURL: LOCAL_SERVICE_URL,
  url: '/websocket',
  headers,
  method: 'POST',
};
