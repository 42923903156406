import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { rgba } from "polished";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)}`,
      height: '100%',

      '& .callboard-container': {
        minHeight: "70vh",
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        '& .grey-text': {
          color: '#9CA5A8',
          lineHeight: '1.2',
        },

        '& .header-container': {
          '& >div': {
            padding: `${theme.spacing(2.1)} ${theme.spacing(1)}`,
          },

          '& .wait-time-container': {
            backgroundColor: rgba(0, 0, 0, 0.03),
            alignItems: 'center',

            '& .progress-bar-wrapper': {
              width: '100%',

              '& .progress-root': {
                display: 'block',
                width: '100%',
                maxWidth: '360px',
                height: 20,
                position: 'relative',
                borderRadius: 20,
                overflow: 'visible',
                background: '#E5E5E5',

                '& .progress-bar-indicator': {
                  height: '100%',
                  borderRadius: 20,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  mask: 'linear-gradient(#fff 0 0)',

                  '&::before': {
                    content: '""',
                    borderRadius: 20,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: 'linear-gradient(90deg, #00FF8A 0%, #FFF800 50.32%, #EA5514 100%)',
                  }
                }
              }
            }
          },

        },

        '& .content-container': {
          padding: `${theme.spacing(2.1)} ${theme.spacing(1)}`,

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',

          '& .order-numbers-container': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridColumnGap: theme.spacing(2),
            gridRowGap: theme.spacing(2),

            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: '1fr 1fr 1fr',
            },

            '& .order-number-chip': {
              border: `2px solid ${rgba(0, 0, 0, 0.1)}`,
              borderRadius: '10px',
              backgroundColor: theme.palette.common.white,
              padding: "10px 20px",
              textAlign: 'center',

              color: theme.palette.common.black,
              fontWeight: 'bold',
              fontSize: '1.4rem'
            },
          },

          '& .pagination-container': {
            paddingTop: theme.spacing(2),

            [theme.breakpoints.down('sm')]: {
              justifyContent: 'space-evenly',

            },

            '& .pagination-dots-container': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              '& .pagination-dot': {
                height: '1rem',
                width: '5rem',

                backgroundColor: rgba(0, 0, 0, 0.07),
                borderRadius: 20,

                "&:not(:last-of-type)": {
                  marginRight: '5px'
                },

                '&.active': {
                  backgroundColor: theme.palette.common.black,
                },
              },
            }
          }
        },

        '&.completed-callboard': {
          backgroundColor: rgba(34, 172, 56, 0.07),

          '& .main-header': {
            backgroundColor: rgba(34, 172, 56, 1),
            color: theme.palette.common.white
          },

          '& .content-container': {
            '& .order-numbers-container': {
              '& .order-number-chip': {
                borderColor: rgba(34, 172, 56, 1),
                color: rgba(34, 172, 56, 1),
              }
            },

            '& .pagination-container': {
              '& .pagination-label': {
                color: rgba(34, 172, 56, 1)
              },

              '& .pagination-dots-container': {
                '& .pagination-dot': {
                  backgroundColor: rgba(34, 172, 56, 0.07),

                  '&.active': {
                    backgroundColor: rgba(34, 172, 56, 1),
                  }
                }
              }
            }
          }
        }
      },
    },
    slider: {
      backgroundColor: '#E34D28',
      userSelect: 'none',

      '& .slide-container': {
        position: 'relative',
        minHeight: 'calc(100vh - 64px)',
        display: "flex",
        alignItems: 'center',

        '&.centered': {
          justifyContent: 'center',
        },
        '& .circle-illustration': {
          position: 'absolute',
          height: '100%',
          width: '75%',
          objectFit: 'cover',
          objectPosition: 'right top',
          top: 0,
          right: 0,
        },

        '& .slide-illustration': {
          position: 'absolute',
          height: '100%',
          width: '50%',
          top: 0,
          right: 0,

          '& img': {
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            objectFit: 'cover',
            objectPosition: 'right bottom',
          }
        },

        '& .content': {
          padding: theme.spacing(5),

          '& .QRCode-wrapper': {
            backgroundColor: 'white',
            padding: theme.spacing(1),
            borderRadius: theme.spacing(2),
            width: 256,
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
          },

          '& .scan-to-order': {
            background: 'white',
            color: '#E34D28',
            padding: `${theme.spacing(1)} 0`,
            borderRadius: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: `calc(256px + ${theme.spacing(2)})`,
            fontWeight: '500',
            fontSize: '20px'
          }
        }
      },
    }
  })
)

export default useStyles;