import React from 'react';
import { styled, TooltipProps, Tooltip, tooltipClasses } from "@material-ui/core";


const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    borderRadius: 2,
  },
}));

export default InfoTooltip;
