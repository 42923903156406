import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Refresh } from '@material-ui/icons';
import { addDays, differenceInDays, subDays } from 'date-fns/esm';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { If, Then} from 'react-if';

// src
import { MerchantStatsContext } from '../../context/MerchantStats/MerchantStatsContext';
import { calculateStatsDateRange, getStatsTimePeriodLabel } from '../../utils/merchantStatsUtils';
import { getStatsDateRangeLabel } from './chartUtils';
import StatsDateRangePicker from './StatsDateRangePicker';
import { MerchantStatsPeriod } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .timePeriodLabel': {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },

      '& .dateRange': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '1rem',
        },
      },

      '& .iconOnlyButton': {
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.primary.light,
        width: 36,
        height: 36,
      '& .MuiIconButton-label': {
          width: 36,
          height: 36,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
  })
);

const StatsRangeSelector = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { statsTimePeriod, statsDateRange, setStatsDateRange, setStatsTimePeriod } =
    useContext(MerchantStatsContext);
  const isCustomStatsPeriod = statsTimePeriod === MerchantStatsPeriod.Custom;
  const isWeeklyStats = statsTimePeriod === MerchantStatsPeriod.Week;

  const toDate = statsDateRange?.toDate ?? new Date();
  const fromDate = statsDateRange?.fromDate ?? new Date();
  const dateFormat = isMobile || isWeeklyStats ? 'MM/dd' : 'MM/dd/yyyy';

  const difference = isWeeklyStats ? 7 : 1;

  const handlePreviousClick = () => {
    setStatsDateRange({
      fromDate: subDays(fromDate, difference),
      toDate: subDays(fromDate, isWeeklyStats ? 1 : 0),
    });
  };

  const handleNextClick = () => {
    setStatsDateRange({
      fromDate: addDays(toDate, isWeeklyStats ? 1 : 0),
      toDate: addDays(toDate, difference),
    });
  };

  const handleRefresh = () => {
    setStatsDateRange({
      fromDate,
      toDate,
    });
  }

  const toggleTimePeriod = () => {
    let updatedStatsTimePeriod;
    let startDate;
    let endDate;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (statsTimePeriod === MerchantStatsPeriod.Day) {
      updatedStatsTimePeriod = MerchantStatsPeriod.Week;
      endDate = statsDateRange?.fromDate ?? today;
    } else {
      updatedStatsTimePeriod = MerchantStatsPeriod.Day;
      endDate =
        statsDateRange?.toDate && statsDateRange.toDate <= today ? statsDateRange.toDate : today;
    }
    endDate.setHours(0, 0, 0, 0);

    // set current week stats range
    if (updatedStatsTimePeriod === MerchantStatsPeriod.Week) {
      const weekDay = endDate.getDay();
      if (weekDay > 0) {
        startDate = subDays(endDate, weekDay - 1);
        endDate = addDays(startDate, 6);
      } else {
        startDate = subDays(endDate, 6);
      }
    } else {
      startDate = subDays(endDate, 1);
    }
    setStatsTimePeriod(updatedStatsTimePeriod);
    setStatsDateRange(calculateStatsDateRange(endDate, updatedStatsTimePeriod, startDate));
  };

  const isNextBtnDisabled =
    isCustomStatsPeriod ||
    differenceInDays(new Date(), isWeeklyStats ? toDate : fromDate) < difference;
  const isPrevBtnDisabled = isCustomStatsPeriod;

  return (
    <Stack className={classes.root} spacing={1} direction="row" alignItems="center">
      <If condition={!isCustomStatsPeriod}>
        <Then>
          <IconButton
            color="primary"
            className="iconOnlyButton"
            onClick={handleRefresh}
          >
            <Refresh />
          </IconButton>
        </Then>
      </If>
      <IconButton
        color="primary"
        className="iconOnlyButton"
        disabled={isPrevBtnDisabled}
        onClick={handlePreviousClick}
      >
        <ChevronLeft />
      </IconButton>
      <Typography variant="h6" className="dateRange" color="primary">
        {getStatsDateRangeLabel(statsTimePeriod, statsDateRange, dateFormat)}
      </Typography>
      <IconButton
        color="primary"
        className="iconOnlyButton"
        disabled={isNextBtnDisabled}
        onClick={handleNextClick}
      >
        <ChevronRight />
      </IconButton>
      <Button variant="outlined" color="primary" onClick={toggleTimePeriod}>
        {t(getStatsTimePeriodLabel(statsTimePeriod))}
      </Button>
      <StatsDateRangePicker />
    </Stack>
  );
};

export default StatsRangeSelector;
