import React, { useContext, useEffect, useState } from 'react';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';

import DialogWrapper from '../common/DialogWrapper';
import CategoriesList from './CategoriesList';
import axios from '../../bos_common/src/services/backendAxios';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { getAuthHeaders } from '../../bos_common/src';
import { AppContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMerchandiseCategories, getMerchandises } from '../../redux/slice/merchandise/merchandiseSelector';
import { NotificationSeverity } from '../../types/NotificationSlice';
import { deleteMerchandiseCategorySucceed, swapMerchandiseCategoriesSucceeded } from '../../redux/slice/merchandise/merchandiseActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeCategories: {
      paddingBottom: '20px',

      '& .footerButtonWrapper': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  })
);

export interface RemoveCategoryDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentCategoryId: number;
}

const RemoveCategoryDialog = ({ open, setOpen, currentCategoryId }: RemoveCategoryDialogProps) => {
  const classes = useStyles();

  const { merchant, triggerNotification } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { t } = useTranslation();

  const categoriesList = useAppSelector(getMerchandiseCategories);
  const merchandisesList = useAppSelector(getMerchandises);
  const reduxDispatch = useAppDispatch();


  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);

  const currentCategory = categoriesList.find((item) => item.id === currentCategoryId);
  const categoryHasMerchandises = Boolean(
    merchandisesList?.find((item) => item.categoryId === currentCategoryId)
  );

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCategory = async () => {
    await axios
      .delete(`/merchandises/categories/${currentCategoryId}`, {
        headers: getAuthHeaders(token),
      })
      .then(() => {
        reduxDispatch(deleteMerchandiseCategorySucceed(currentCategoryId));
        triggerNotification(
          true,
          `Category deleted successfully`,
          NotificationSeverity.SUCCESS,
          true
        );
        setOpen(false);
      })
      .catch((error) =>
        triggerNotification(true, `Error while submitting data`, NotificationSeverity.ERROR, true)
      );
  };

  const handleContinueButtonClick = () => {
    if (categoryHasMerchandises) {
      const merchandises = merchandisesList?.map((data) => {
        if (data.categoryId === currentCategoryId) {
          return {
            ...data,
            categoryId: selectedCategoryId === -1 ? null : selectedCategoryId
          }
        }
        return { ...data }
      })

      axios
        .put(`/merchants/merchandises?merchantId=${merchant?.id}`, merchandises, {
          headers: getAuthHeaders(token),
        })
        .then((response) => {
          setOpen(false)
          deleteCategory()
          reduxDispatch(
            swapMerchandiseCategoriesSucceeded({
              oldCategoryId: currentCategoryId,
              newCategoryId: selectedCategoryId,
            })
          )
        })
        .catch((error) =>
          triggerNotification(true, `Error while submitting data`, NotificationSeverity.ERROR, true)
        );
    } else {
      deleteCategory();
    }
  };

  return (
    <DialogWrapper
      title={t("NewCategory_Remove", { name: currentCategory?.name })}
      subTitle={
        categoryHasMerchandises
          ? t("Menu_Categories_Remove_NoTitle")
          : t("Menu_Categories_Remove_HasTitle", { name: currentCategory?.name })
      }
      handleClose={handleClose}
      open={open}
    >
      <div className={classes.removeCategories}>
        {categoryHasMerchandises && (
          <CategoriesList
            merchandiseCategoryId={currentCategoryId}
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
          />
        )}

        <div className="footerButtonWrapper">
          <Fab
            className="actionButton"
            variant="extended"
            color="primary"
            component="span"
            size="medium"
            onClick={handleContinueButtonClick}
          >
            {t("Continue")}
          </Fab>
        </div>
      </div>
    </DialogWrapper>
  );
};

export default RemoveCategoryDialog;
