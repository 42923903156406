import React, { useContext, useEffect } from 'react';
import {
  Chip,
  createStyles,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { AddCircleOutlineOutlined, Close, DeleteOutline } from '@material-ui/icons';
import { equals, flatten, length, pipe, pluck, propOr, remove, without } from 'ramda';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';

import axios from '../../bos_common/src/services/backendAxios';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { MerchantConfig } from '../../bos_common/src/types/MerchantConfigType';
import SimpleLoader from '../../bos_common/src/components/SimpleLoader';

import { AppContext } from '../../context/AppContext';

import { NotificationSeverity } from '../../types/NotificationSlice';
import CardWrapper from "../CardWrapper"

import { getAuthHeaders, isEmptyOrNil } from '../../utils';
import { NORMAL_BUSINESS_HOURS, SPECIAL_BUSINESS_HOURS } from "../../constants/businessHours"
import SpecialHoursTabs from './SpecialHoursTabs';
import NormalHours from './NormalHours';
import { Shifts } from './types';
import { updateMerchantSucceed } from '../../redux/slice/merchant/merchantActions';
import CustomDialog from '../common/CustomDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .businessHoursForm': {
        maxHeight: "90vh",
        overflow: "auto"
      },

      '& .MuiDialog-paperScrollPaper': {
        maxWidth: 680,
        width: '100%',
        borderRadius: '20px',
      },

      '& .divider': {
        margin: `${theme.spacing(2)} 0`,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      },

      '& .saveButtonWrapper': {
        position: "sticky",
        bottom: 0,
        width: "100%",
        background: theme.palette.background.paper,
        textAlign: "center",
      },


      '& .headerButtonsWrapper': {
        padding: theme.spacing(0.5, 2, 2),
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        gap: theme.spacing(2),
        position: 'sticky',
        top: 0,
        background: theme.palette.background.paper,
        zIndex: 1,
      },

      '& .dialogContentWrapper': {
        padding: theme.spacing(2),
        display: 'grid',
        gap: theme.spacing(2),
      },

      '& .roundedButton': {
        padding: theme.spacing(1),
        height: 'unset',
        borderRadius: '50px',
        color: theme.palette.primary.main,
        fontSize: '16px',
        background: theme.palette.background.paper,
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',
        cursor: 'pointer',

        '& .icon, svg': {
          fill: theme.palette.primary.main,
        },
      },
    },
  })
);

const BusinessHoursForm = ({ handleClose }: { handleClose: () => void }) => {
  const { merchant, setMerchant, triggerNotification } = useContext(AppContext);
  const { token, loading, setLoading } = useContext(UserContext);

  const { t } = useTranslation();

  const methods = useFormContext();

  const reduxDispatch = useAppDispatch();

  const {
  } = useFieldArray({
    name: `normalHours`,
    control: methods.control,
  });
  const {
    fields: specialHours,
    append: appendSpecialHour,
  } = useFieldArray({
    name: `specialHours`,
    control: methods.control,
  });

  const normalHours = methods.watch('normalHours')

  useEffect(() => {
    if (!isEmptyOrNil(merchant?.hours?.defaultFromMinOffset) || !isEmptyOrNil(merchant?.hours?.defaultToMinOffset)) {
      methods.setValue('normalHours', [{
        days: [0, 1, 2, 3, 4, 5, 6],
        shifts: [{
          startTimeOffset: merchant?.hours?.defaultFromMinOffset || 0,
          endTimeOffset: merchant?.hours?.defaultToMinOffset || 1440,
          enabled: true,
        }]
      }])
    }

    if (!isEmptyOrNil(merchant?.normalHours)) {
      methods.register('normalHours')
      merchant?.normalHours.map((_, hourIndex) => {
        methods.register(`normalHours.${hourIndex}.days`)
        methods.register(`normalHours.${hourIndex}.shifts`)
      })
      methods.setValue('normalHours', [...merchant?.normalHours!])
    }

    if (!isEmptyOrNil(merchant?.specialHours)) {
      methods.setValue('specialHours', [...merchant?.specialHours!])
    }
  }, [merchant, merchant?.normalHours, merchant?.specialHours]);

  const handleAddHoursClick = (hourType: string) => {
    if (hourType === 'normalHours') {
      const isButtonDisabled = pipe(
        pluck('days'),
        flatten,
        without([undefined, null, '']),
        length,
        equals(7)
      )(normalHours || [])

      if (isButtonDisabled) {
        triggerNotification(true, t('BusinessHours_DaysAssociatedShiftsMessage'), NotificationSeverity.INFO, true);
        return;
      }

      methods.setValue('normalHours', [...normalHours, NORMAL_BUSINESS_HOURS[0]]);
    } else {
      appendSpecialHour(SPECIAL_BUSINESS_HOURS[0]);
    }
  };

  const handleSaveButtonClick = (event: MerchantConfig) => {
    setLoading(true);

    axios
      .put(
        `merchants/`,
        {
          id: merchant?.id,
          normalHours: event.normalHours,
          specialHours: event.specialHours
        },
        { headers: getAuthHeaders(token) }
      )
      .then((response) => {
        setLoading(false);
        triggerNotification(true, `Business hours update successfully`, NotificationSeverity.SUCCESS, true);
        reduxDispatch(updateMerchantSucceed(response.data));
        setMerchant(response.data)
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        triggerNotification(true, `Failed to add Business hours`, NotificationSeverity.ERROR, true)
      });
  };

  const onSubmitHandler = (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    return methods.handleSubmit(handleSaveButtonClick)(e);
  }

  const handleSpecialHourDelete = (currentHourIndex: number) => {
    methods.setValue('specialHours', remove(currentHourIndex, 1, specialHours))
  };

  return (
    <form onSubmit={onSubmitHandler} className="businessHoursForm">
      <SimpleLoader loading={loading} />
      <div className="headerButtonsWrapper">
        <Chip
          className="roundedButton"
          onClick={() => handleAddHoursClick('normalHours')}
          icon={<AddCircleOutlineOutlined className="icon" />}
          label="Hours"
        />
        <Chip
          className="roundedButton"
          onClick={() => handleAddHoursClick('specialHours')}
          icon={<AddCircleOutlineOutlined className="icon" />}
          label={t('BusinessHours_SpecialHours')}
        />
      </div>

      <div className="dialogContentWrapper">
        {specialHours.map((_: Record<'id', string>, index: number) => {
          return (
            <CardWrapper
              heading={t('BusinessHours_SpecialHoursOverridng')}
              headerItems={
                <IconButton
                  component="span"
                  size="small"
                  onClick={() => handleSpecialHourDelete(index)}>
                  <DeleteOutline color="error" />
                </IconButton>
              }>
              <SpecialHoursTabs currentHourIndex={index} />
            </CardWrapper>
          );
        })}

        <NormalHours />

        <div className="saveButtonWrapper">
          <Fab
            type="submit"
            disabled={!methods.formState.isValid || loading}
            variant="extended"
            color="primary"
            sx={{ width: "100%", maxWidth: 245, textTransform: "unset" }}
          >
            {t("Save")}
          </Fab>
        </div>
      </div>
    </form>
  )
}

type BusinessHoursDialogProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
}

export type BusinessHoursFormValuesType = {
  normalHours: Array<{
    days?: number[],
    shifts?: Shifts[],
    enabled?: boolean,
  }>,
  specialHours: any;
}

const BusinessHoursDialog = ({ open, setOpen }: BusinessHoursDialogProps) => {
  if (!open) {
    return null;
  }

  const { merchant } = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const methods = useForm<BusinessHoursFormValuesType>({
    mode: "onBlur",
    defaultValues: {
      normalHours: NORMAL_BUSINESS_HOURS,
      specialHours: SPECIAL_BUSINESS_HOURS,
      ...(merchant?.normalHours && {
        normalHours: [...merchant?.normalHours ?? []]
      }),
      ...(merchant?.specialHours && {
        specialHours: [...merchant?.specialHours ?? []]
      }),
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomDialog title={t("EditBusinessHours")} className={classes.root} setOpen={setOpen} keepMounted={false} open={open}>
      <FormProvider {...methods} >
        <BusinessHoursForm handleClose={handleClose} />
      </FormProvider>
    </CustomDialog>
  );
};

export default BusinessHoursDialog;
