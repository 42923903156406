import React, { useContext, useState } from 'react';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';

import SubmitCategoryDialog from './SubmitCategoryDialog';
import RemoveCategoryDialog from './RemoveCategoryDialog';
import DialogWrapper from '../common/DialogWrapper';
import DraggableCategoriesList from './DraggableCategoriesList';
import { Else, If, Then } from 'react-if';
import { AppContext } from '../../context/AppContext';
import axios from '../../bos_common/src/services/backendAxios';
import { getAuthHeaders } from '../../bos_common/src';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { MerchandiseCategory } from '../../services/models';
import { t } from 'i18next';
import { useAppDispatch } from '../../redux/hooks';
import { updatedCategoriesList } from '../../redux/slice/merchandise/merchandiseActions';
import { NotificationSeverity } from '../../types/NotificationSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addCategory: {
      '& .categoriesList': {
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: '10px',
        padding: '5px 10px',
        justifyContent: 'center',
      },

      '& .categoryInfo': {
        textAlign: 'center',
        color: '#777777',
      },

      '& .dialogFooterButtons': {
        display: 'flex',
        justifyContent: 'center',
        gridGap: theme.spacing(4),
        padding: theme.spacing(2),
      },
    },
    categoryChip: {
      background: '#eaebec',
      color: '#8f9598',
      border: '1px solid #91999b',
      borderRadius: '4px',
      fontSize: '16px',
    },
  })
);

export interface SimpleDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const AddCategoryDialog = (props: SimpleDialogProps) => {
  const { open, setOpen } = props;

  const [isAddCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false);
  const [isRenameCategoryDialogOpen, setRenameCategoryDialogOpen] = useState(false);
  const [isRemoveCategoryDialogOpen, setRemoveCategoryDialogOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(-1);
  const [categoriesList, setCategoriesList] = useState<MerchandiseCategory[]>([]);
  const [areCategoriesChanged, setAreCategoriesChanged] = useState(false);

  const classes = useStyles();
  const { merchant, triggerNotification } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const reduxDispatch = useAppDispatch();

  const handleClose = () => {
    setCategoriesList(categoriesList);
    setOpen(false);
  };

  const handleDeleteCategory = (id: number) => {
    setCurrentCategoryId(id);
    setRemoveCategoryDialogOpen(true);
  };

  const handleAddCategoryDialogOpen = () => {
    setAddCategoryDialogOpen(true);
  };

  const handleRenameCategoryDialogOpen = (id: number) => {
    setCurrentCategoryId(id);
    setRenameCategoryDialogOpen(true);
  };

  const handleSaveCategory = () => {
    if (areCategoriesChanged) {
      axios
        .put(`/merchandises/categories?merchantId=${merchant?.id}`, categoriesList, {
          headers: getAuthHeaders(token),
        })
        .then((response) => {
          reduxDispatch(updatedCategoriesList(categoriesList));
          triggerNotification(
            true,
            `Categories updated successfully`,
            NotificationSeverity.SUCCESS,
            true
          );
        })
        .catch(() =>
          triggerNotification(true, `Categories update failed`, NotificationSeverity.ERROR, true)
        );
    }
    setOpen(false);
  };

  return (
    <DialogWrapper title={t("Menu_Categories")} handleClose={handleClose} open={open}>
      <div className={classes.addCategory}>
        <DraggableCategoriesList
          handleDeleteCategory={handleDeleteCategory}
          handleRenameCategoryDialog={handleRenameCategoryDialogOpen}
          categoriesList={categoriesList}
          setCategoriesList={setCategoriesList}
          setAreCategoriesChanged={setAreCategoriesChanged}
        />

        <If condition={categoriesList.length > 0}>
          <Then>
            <p className="categoryInfo">{t("Menu_Categories_TapOneText")}</p>
          </Then>
          <Else>
            <p className="categoryInfo">{t("Menu_Categories_CreateText")}</p>
          </Else>
        </If>

        <div className="dialogFooterButtons">
          <Fab
            variant="extended"
            color="secondary"
            component="span"
            size="medium"
            sx={{pl: 4, pr: 4}}
            onClick={handleAddCategoryDialogOpen}
          >
            {t("New")}
          </Fab>

          <Fab
            variant="extended"
            color="primary"
            component="span"
            size="medium"
            sx={{pl: 4, pr: 4}}
            onClick={handleSaveCategory}
            disabled={!areCategoriesChanged}
          >
            {t("Save")}
          </Fab>
        </div>

        <SubmitCategoryDialog
          title={t("Menu_Categories_New")}
          open={isAddCategoryDialogOpen}
          setOpen={setAddCategoryDialogOpen}
          setAreCategoriesChanged={setAreCategoriesChanged}
        />
        <SubmitCategoryDialog
          title={t("Menu_Categories_Rename")}
          open={isRenameCategoryDialogOpen}
          setOpen={setRenameCategoryDialogOpen}
          categoryId={currentCategoryId}
          setCurrentCategoryId={setCurrentCategoryId}
          setAreCategoriesChanged={setAreCategoriesChanged}
        />
        <RemoveCategoryDialog
          open={isRemoveCategoryDialogOpen}
          setOpen={setRemoveCategoryDialogOpen}
          currentCategoryId={currentCategoryId}
        />
      </div>
    </DialogWrapper>
  );
};

export default AddCategoryDialog;
