import React from 'react';
import { Typography } from '@material-ui/core';
import { getBuildTimestamp } from '..';

const VersionNumber = () => {
  const versionNumber = getBuildTimestamp()
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant='subtitle2' component='div' style={{ color: "#BBB" }}>
        {versionNumber}
      </Typography>
    </div>
  )
}

export default VersionNumber