import { IconButton, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useContext } from "react";
import { If } from 'react-if';

import { isEmptyOrNil } from "../../../../utils";
import { OrderActionsContext } from "../context/OrderActionsContext";

type ActionHeaderProps = {
  title: string,
  subtitle?: string
}

const ActionHeader = (props: ActionHeaderProps) => {
  const { title, subtitle } = props
  const { setOrderAction } = useContext(OrderActionsContext);

  return (
    <div className='header-container'>
      <Typography variant='h6' component='h6'>
        {title}
      </Typography>
      <If condition={!isEmptyOrNil(subtitle)}>
        <Typography variant='subtitle2' component='div'>
          {subtitle}
        </Typography>
      </If>
    </div>
  )
}

export default ActionHeader;