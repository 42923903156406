import React, { ComponentType } from 'react'
import assert from 'assert';
import { Channel } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { format, differenceInMilliseconds, fromUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import preval from 'preval.macro';
import { path, anyPass, isEmpty, isNil } from 'ramda';

import backendAxios from "./services/backendAxios";
import { Merchandise } from './types/MerchandiseType';

// https://gist.github.com/gordonbrander/2230317
const ID = () => Math.random().toString(36).substr(2, 9);

export const merchantID = () => `b_${ID()}`;

export const merchandiseID = () => `m_${ID()}`;

export const userID = () => `u_${ID()}`;

export const photoID = () => `p_${ID()}`;

export const orderID = () => `o_${ID()}`;

export const randomID = () => `ran_${ID()}`;

export const dateString = (d: Date) => {
  const mm = d.getMonth() + 1; // getMonth() is zero-based
  const dd = d.getDate();

  return [d.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('');
};

export const formatDate = (date: Date | string | undefined, dateFormat = 'M/d/yyyy') => date ? format(new Date(date), dateFormat) : "";

export const isEmptyOrNil = anyPass([isEmpty, isNil])

export const getUTCDate = (date: Date): Date => {
  const d = new Date(date);
  return new Date(Date.UTC(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  ));
};

export const lapsedTime = function (timeStampDiff: number): string {
  const secondsDiff = timeStampDiff / 1000;
  const seconds = Math.floor(secondsDiff % 60)
  const minuteDiff = Math.floor(secondsDiff / 60)
  const minutes = Math.floor(minuteDiff % 60)
  const hourDiff = Math.floor(minuteDiff / 60)
  const hours = hourDiff % 24
  const dayDiff = Math.floor(hourDiff / 24);
  const days = dayDiff

  if (days > 0) {
    return `${days} days ago`
  } else if (hours > 0) {
    return `${hours} hours ago`
  } else if (minutes > 0) {
    return `${minutes} minutes ago`
  } else if (seconds > 0) {
    return `${seconds} seconds ago`
  } else {
    return "just now"
  }
}

export const displayTime = (t: Date, long?: boolean, timeZone?: string): string => {
  const utcDate = getUTCDate(t);
  const now = getUTCDate(new Date());
  const timeStampDiff = Math.abs(differenceInMilliseconds(now, utcDate));
  const sixHourDiff = Math.floor(timeStampDiff / (6 * 60 * 60 * 1000));

  let finalDate = utcDate;
  if (timeZone) {
    finalDate = utcToZonedTime(utcDate, timeZone);
  }

  if (sixHourDiff > 0) {
    if (long) {
      return format(finalDate, 'EEE, MMM d, p');
    } else {
      return format(finalDate, 'MMM d, p');
    }
  }
  return format(finalDate, 'p');
};

export const displayDateTime = (t: Date): string => {
  const utcDate = getUTCDate(t);
  return format(utcDate, 'MMM d, p');
}

export const displayHourMinutes = (t: Date): string => {
  const utcDate = getUTCDate(t);
  return format(utcDate, 'p');
}

export const displayDate = (t: Date): string => {
  const utcDate = getUTCDate(t);
  const now = getUTCDate(new Date());
  const timeStampDiff = Math.abs(differenceInMilliseconds(now, utcDate));
  const daysDiff = Math.floor(timeStampDiff / (24 * 60 * 60 * 1000));

  if (daysDiff > 365) {
    return format(utcDate, 'PP');
  } else if (daysDiff > 2) {
    return `${format(utcDate, 'MMM dd')} at ${format(utcDate, 'HH:mm')}`;
  }
  return lapsedTime(timeStampDiff)
}

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const repeatAudio = (audio: HTMLAudioElement, times: number): boolean => {
  assert(times < 6 && times > 0 && audio)
  try {
    let played = 0;
    const repeat = () => {
      played++;
      if (played < times) {
        audio.play();
      } else {
        audio.removeEventListener("ended", repeat)
      }
    }
    audio.addEventListener("ended", repeat, false);
    audio.play();
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

export const getAuthHeaders = (token: string) => ({ 'Authorization': `JWT ${token}` });

export const pluralString = (count: number, singular: string, plural?: string) => {
  const pluarlFinal = plural ?? `${singular}s`;
  const itemStr = count === 1 ? singular : pluarlFinal;
  return `${count} ${itemStr}`;
}

export const getAPIErrorMessage = (err: any, defaultErrMessage?: string): string => {
  // Show external error first, because those are meant to be customer facing
  // internal errors are meant to be engineers facing
  if (err?.response?.data?.errors?.bosError?.external) {
    return err?.response?.data?.errors?.bosError?.external;
  }
  if (err?.response?.data?.errors?.bosError?.internal) {
    return err?.response?.data?.errors?.bosError?.internal;
  }
  if (err?.response?.data?.errors?.bosError?.raw?.message) {
    return err.response.data.errors.bosError.raw.message;
  }
  if (err?.response?.data?.errors?.external) {
    return err?.response?.data?.errors?.external;
  }
  if (err?.response?.data?.errors?.internal) {
    return err?.response?.data?.errors?.internal;
  }

  if (err?.message) return err.message

  return defaultErrMessage || 'Error ocurred';
}

export const toFixed2 = (num: number): number => {
  return Math.round(num * 1e2) / 1e2;
}

export const toFixed1 = (num: number): number => {
  return Math.round(num * 1e1) / 1e1;
}

export function withFallbackPath<T>(Component: ComponentType<T>, fallbackUrl: string | null | undefined) {
  const NewComponent = (props: T) => {
    const handleImageError = (e: any) => {
      e.target.onerror = null;
      e.target.src = fallbackUrl
    }

    return (
      <Component {...props} onError={handleImageError} />
    )
  }

  return NewComponent
}

const resizeFile = async (file: any, width: number, height: number) =>
  new Promise(resolve => {
    readerFile(file, data => {
      const img = new Image();
      img.onload = () => {
        const w = img.width;
        const h = img.height;
        let x1 = 0;
        let y1 = 0;
        let side = w;
        if (w != h) {
          if (w > h) {
            x1 = Math.round((w - h) / 2);
            side = h;
          } else {
            y1 = Math.round((h - w) / 2);
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, x1, y1, side, side, 0, 0, width, height);
        canvas.toBlob(
          blob => {
            resolve(blob);
          },
          'image/jpeg',
          0.95,
        );
      };
      img.src = data;
    });
  });

const uploadImgByUrl = async (data: any, url: string) =>
  backendAxios.put(
    url,
    data,
    { headers: { "Content-Type": "image/jpeg", } },
  )

export const uploadThumbnail = async (url: string, data: any) => {
  const small = url + "_small";
  const medium = url + "_medium";
  const buf: any = await resizeFile(data, 256, 256);
  const response = await uploadImgByUrl(buf, small);
  if (response.status === 200) {
    console.log(
      "create and upload thumbnail_Small:Source Size:" +
      data?.length +
      "(b)  Small Size:" +
      buf?.size +
      "(b) \n    url:" +
      small
    );
  }

  const buf1: any = await resizeFile(data, 512, 512);
  const response1 = await uploadImgByUrl(buf1, medium);

  if (response1.status === 200) {
    console.log(
      "create and upload thumbnail_Medium:Source Size:" +
      data.length +
      "(b)  Medium Size:" +
      buf1.size +
      "(b) \n    url:" +
      medium
    );
  }

  return {
    smallThumbnailUrl: response.config.url,
    mediumThumbnailUrl: response1.config.url,
  }
}

export const getBuildTimestamp = () => {
  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const lastUpdateTime = fromUnixTime(buildTimestamp / 1000)
  const versionNumber = `v${format(lastUpdateTime, 'MM.dd.yyyy-HH:mm:ss a')}`

  return versionNumber
}

export const getMerchandiseOverallRatings = (merchandise: Merchandise | null): string | undefined => {
  const rating = path(['rating', 'rating', 'overall'], merchandise);

  if (!rating) return;
  return parseFloat(rating).toFixed(2);
}

export const getAbbreviatedNumber = (num: number): string => {
  const abbreviatedNum = new Intl.NumberFormat('en-US', {
    notation: "compact",
    compactDisplay: "short",
    style: "decimal",
    maximumFractionDigits: 1
  }).format(num);

  return abbreviatedNum;
}

export const readerFile = (file: File | Blob, callBack: (dataURL: string) => void) => {
  const reader = new FileReader();
  reader.onload = e => {
    callBack((e.currentTarget as any).result);
  };
  reader.readAsDataURL(file);
};

export const isEmailValid = (str: string): boolean => {
  if (isEmptyOrNil(str)) return false

  const regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/gi
  return new RegExp(regEx).test(str.toLowerCase())
}

export const isChatUserOnline = (channel: Channel<DefaultStreamChatGenerics>) => {
  return channel.state.watcher_count > 1;
}

export const handleBookingRedirect = (url: string) => {
  const isEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  window?.open(url, url.match(isEmailRegex) ? '_self' : '_blank')?.focus();
}