import React from "react";
import { AppBar, createStyles, makeStyles, Slide, Theme, Toolbar, Typography, useScrollTrigger } from "@material-ui/core";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
  hideOnScroll?: boolean;
}

function HideOnScroll(props: Props) {
  const { children, window, hideOnScroll } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = (hideOnScroll === false)
  ? false
  : useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .toolbar': {
        display: "flex",
        justifyContent: "space-between",
        padding: 0,
        '& .left': {
          display: "flex",
          flexShrink: 1,
          justifyContent: "flex-start",
        },
        '& .title': {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        '& .leftTitle': {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: theme.spacing(2),
        },
        '& .right': {
          display: "flex",
          flexShrink: 0,
          justifyContent: "flex-end",
        },
      }
    }
  }),
);

const PageHeader = (props: {
  title: string,
  leftChild?: React.ReactNode,
  rightChild?: React.ReactNode,
  hideOnScroll?: boolean,
  elevation?: number,
}): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar color={'inherit'} elevation={props.elevation || 0}>
          <Toolbar className="toolbar">
            <div className="left">
              {props.leftChild || ''}
            </div>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className={props.leftChild ? "title" : "leftTitle"}>
              {props.title}
            </Typography>
            <div className="right">
              {props.rightChild || ''}
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}

export default PageHeader;