import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { If, Then, Else } from 'react-if';
import {
  pluck,
  without,
  defaultTo,
  filter,
  pipe,
} from 'ramda';

import { Add, Folder } from '@material-ui/icons';
import {
  CardContent,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Fab,
} from '@material-ui/core';
import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';
import { FullscreenPaper } from '../bos_common/src';
import { MultiFabContainer } from '../bos_common/src/components/FabContainers';
import MerchandiseListing from '../bos_common/src/components/Merchandise/MerchandiseListing';
import { groupMerchandiseDataByCategories } from '../bos_common/src/services/merchandiseUtils';

import { isEmptyOrNil } from '../utils';
import { Merchandise, MerchandiseApiResponseType, Merchant } from '../services/models';
import MerchantPreviewButton from './MerchantPreviewButton';
import AddCategoryDialog from './Categories/AddCategoryDialog';
import useDOMClipping from '../hooks/useDOMClipping';
import MerchantTemplate from './MerchantTemplate/Index';
import MenuPackageItemCard from './MenuPackageItemCard';
import { isMerchantServicesType } from '../utils/merchantUtils';
import RenderMerchandises from './RenderMerchandise';
import MerchandiseEditDialog from './MerchandiseEditDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& [class*="-toolbarPlaceholder-"]': {
        marginBottom: 0,
      },
    },

    merchandiseListContainer: {
      position: 'relative',
      backgroundColor: '#f5faff',
      flex: '1 1 auto',

      '& .category-info': {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
      '& .sortTable': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        '& .sortable-chosen': {
          color: '#fff',
          opacity: 0.7,
        },
        '& .gridItem': {
          paddingLeft: theme.spacing(2),
          paddingTop: theme.spacing(2),
        },
      },
    },

    emptyMenu: {
      textAlign: 'center',
      margin: theme.spacing(2),

      '& .fabButton': {
        margin: theme.spacing(1),
      },
    },
  })
);

interface RenderPackagesProps {
  packageList: MerchantPackage[];
}

const RenderPackages = ({ packageList = [] }: RenderPackagesProps) => {
  return (
    <If condition={packageList.length > 0}>
      <Then>
        {packageList.map((item: MerchantPackage) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            key={`merchandise_${item.id}`}
            id={`menuitem_${item.id}`}
            sx={{ height: '170px' }}
          >
            <MenuPackageItemCard merchantPackage={item} onMerchandiseClick={(_) => {}} />
          </Grid>
        ))}
      </Then>
    </If>
  );
};

interface MerchandiseListProps {
  merchandiseData?: MerchandiseApiResponseType;
  merchant?: Merchant;
  packageList?: MerchantPackage[];
}

const MerchandiseList = (props: MerchandiseListProps) => {
  const classes = useStyles();
  const [localMercData, setLocalMercData] = useState<MerchandiseApiResponseType>();
  const [dlgOpen, setDlgOpen] = useState<boolean>(false);
  const [editMerchandise, setEditMerchandise] = useState<Merchandise>();
  const [isCategoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const { t } = useTranslation();

  const { merchandiseData, merchant, packageList = [] } = props;

  const ids = React.useMemo(
    () => localMercData?.merchandises.map((item) => `menuitem_${item.id}`) || [],
    [localMercData]
  );
  const { bindScrollEvent, cleanUp } = useDOMClipping({ ids });

  useEffect(() => {
    setLocalMercData(merchandiseData);
  }, [merchandiseData]);

  React.useEffect(() => {
    bindScrollEvent();
    return cleanUp;
  }, []);

  React.useEffect(() => {
    bindScrollEvent();
    return cleanUp;
  }, [localMercData]);

  const merchandisesList = localMercData?.merchandises;
  const categoriesList = localMercData?.mercCategories;

  const handleAddMerchandise = () => {
    setEditMerchandise(undefined);
    setDlgOpen(true);
  };

  const handleCategoryDialogOpen = () => {
    setCategoryDialogOpen(true);
  };

  const onEditMerchandise = (merchandise: Merchandise) => {
    setEditMerchandise(merchandise);
    setDlgOpen(true);
  };

  const groupedMerchandises = useMemo(
    () =>
      groupMerchandiseDataByCategories({
        categories: categoriesList,
        merchandises: merchandisesList || [],
        merchant,
      }),
    [merchandisesList, categoriesList]
  );

  const categories = pipe(
    filter((item: any) => item?.merchandises.length > 0),
    pluck('category'),
    without([null])
  )(defaultTo([], groupedMerchandises));

  return (
    <FullscreenPaper className={classes.root}>
      <If condition={!isEmptyOrNil(localMercData?.merchandises)}>
        <Then>
          <MerchandiseListing
            categories={categories}
            menuItemsAvailable={!isEmptyOrNil(localMercData?.merchandises)}
            isToolbar
          >
            <>
              {groupedMerchandises?.map(({ category, merchandises }, idx: number) => {
                if (merchandises.length <= 0) return <div />;
                return (
                  <CardContent
                    className={classes.merchandiseListContainer}
                    key={idx}
                    id={`category-${category ? category.id : '0'}`}
                  >
                    {category && (
                      <div className="category-info">
                        <Typography variant="body1" component="div">
                          {category.name}
                        </Typography>
                        {category.description && (
                          <Typography variant="subtitle2" component="p" color="textSecondary">
                            {category.description}
                          </Typography>
                        )}
                      </div>
                    )}

                    <Grid container spacing={0}>
                      <RenderMerchandises
                        merchandiseData={localMercData}
                        setMerchandiseData={setLocalMercData}
                        merchandises={merchandises}
                        merchantId={merchant?.id}
                        onEditMerchandise={onEditMerchandise}
                      />
                    </Grid>
                  </CardContent>
                );
              })}
              <If condition={!isEmptyOrNil(packageList)}>
                <CardContent className={classes.merchandiseListContainer} id="packages-container">
                  <div className="category-info">
                    <Typography variant="body1" component="div" sx={{ mb: 2 }}>
                      Packages
                    </Typography>
                    <Grid container spacing={2}>
                      <RenderPackages packageList={packageList} />
                    </Grid>
                  </div>
                </CardContent>
              </If>
            </>
          </MerchandiseListing>

          <MultiFabContainer buttonCount={4}>
            <MerchantPreviewButton merchant={merchant} />
            <Fab
              variant="extended"
              color="secondary"
              component="span"
              onClick={handleCategoryDialogOpen}
            >
              <Folder />
              {t('Menu_Categories')}
            </Fab>
            <If condition={merchant && !isMerchantServicesType(merchant)}>
              <MerchantTemplate />
            </If>
            <Fab variant="extended" color="primary" component="span" onClick={handleAddMerchandise}>
              <Add />
              {t('Menu_MenuItem')}
            </Fab>
          </MultiFabContainer>
        </Then>
        <Else>
          <div className={classes.emptyMenu}>
            <Typography variant="subtitle1" color="textSecondary" component="div">
              {t('Menu_NoMenuText')}
            </Typography>
            <Fab
              className="fabButton"
              variant="extended"
              color="primary"
              component="span"
              onClick={handleAddMerchandise}
            >
              <Add />
              {t('Menu_MenuItem')}
            </Fab>
          </div>
        </Else>
      </If>

      {merchant && (
        <MerchandiseEditDialog
          merchandise={editMerchandise}
          merchant={merchant}
          open={dlgOpen}
          onClose={() => setDlgOpen(false)}
        />
      )}

      <AddCategoryDialog open={isCategoryDialogOpen} setOpen={setCategoryDialogOpen} />
    </FullscreenPaper>
  );
};

export default MerchandiseList;
