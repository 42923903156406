import {
  reduce,
  map,
  pluck,
  flatten,
  pipe,
  groupBy,
  head,
  values,
  sort,
  propOr,
  filter,
  prop,
  descend,
  toPairs,
  concat,
  without,
  mapObjIndexed,
  defaultTo,
  sum,
  divide,
  __,
  path,
  pathOr
} from "ramda"
import { addDays, subDays } from 'date-fns'

import { MerchantSalesStats, UsedCouponDictionary } from "../bos_common/src/types/MerchantSalesStatsType"
import { MerchantCustomerStats } from "../bos_common/src/types/MerchantCustomerStatsType"
import {
  LineItem,
  Merchandise,
  MerchandiseApiResponseType,
  MerchandiseCategory,
  Merchant,
  MerchantAggregatedCustomerStats,
  MerchantAggregatedSalesStats,
  Order
} from "../services/models"
import { MerchantStatsPeriod, OrdersGroupedByDay } from "../components/Stats/types"
import { PercentageComparisonProps as PercentageComparisonType, PlusOrMinus } from "../components/Stats/PercentageComparison"
import { isEmptyOrNil, toFixed2 } from "."
import { generateEverythingElseCategory } from "../bos_common/src/services/merchandiseUtils"
import { MerchantOperationStats } from "../types/MerchantOperationStatsType"
import { MerchantWithMerchandises } from "../context/AppContext"
import { pluralString } from "../utils";

/**
 * This utility function consumes the backend stats and computes the common set of statistics needed across various
 * product interfaces, sales tab or billing statements
 *
 * @param stats
 */
export const formatMerchantSalesStats = (stats?: Array<MerchantSalesStats>, utcTimePointMax?: Date): MerchantAggregatedSalesStats => {
  const merchantStats = {
    stats: stats || [],
    orderAmountsTotal: 0,
    orderTipsTotal: 0,
    orderTaxTotal: 0,
    orderRefundsTotal: 0,
    orderTransactionAmountTotal: 0,
    avgOrderAmount: 0,
    totalOrdersFulfilled: 0,
    totalPointsRedeemed: 0,
    totalPointsAwarded: 0,
    totalDiscount: 0,
    totalGrossSales: 0,
    totalNetSales: 0,
    totalNetTotal: 0,
    totalTotalGrossSales: 0,
    totalItemsSold: 0,
  } as MerchantAggregatedSalesStats;

  if (!stats) return merchantStats

  const currentTS = utcTimePointMax ? utcTimePointMax.getTime() : null;

  const updatedStats = stats.map((record: MerchantSalesStats) => {
    const usedCouponPerCode = record.usedCouponPerCode || {};
    const totalDiscount = Object.keys(usedCouponPerCode)
      .map((key) => usedCouponPerCode[key].totalRedemption)
      // eslint-disable-next-line no-param-reassign
      .reduce((total, n) => { total += n; return total; }, 0);

    const salePerMerchandise = record.salePerMerchandise || {};
    const totalGrossSales = Object.keys(salePerMerchandise)
      .map((key) => salePerMerchandise[key].sale)
      // eslint-disable-next-line no-param-reassign
      .reduce((total, n) => { total += n; return total; }, 0);

    const itemsSold = Object.values(salePerMerchandise)
      .reduce((agg, val) => agg + val.quantity, 0);

    const totalNetSales = totalGrossSales
      - Number(record.totalRefund)
      - Number(totalDiscount)
      - (Number(record.totalPointsRedeemed) / 100);

    const totalNetTotal = totalNetSales - Number(record.totalTransactionCost);
    const totalTotalGrossSales = totalGrossSales + Number(record.totalTax) + Number(record.totalTips);

    return {
      ...record,
      itemsSold,
      totalDiscount,
      totalGrossSales,
      totalNetSales,
      totalNetTotal,
      totalTotalGrossSales,
    }
  })

  updatedStats.forEach((record: MerchantSalesStats) => {
    const timePointTS = new Date(record.timePoint).getTime();
    let ratio = 1;
    if (currentTS) {
      if (currentTS < timePointTS) {
        // skip current time point
        return;
      } else {
        ratio = (currentTS - timePointTS) / 3600000;
        ratio = ratio > 1 ? 1 : ratio;
      }
    }
    merchantStats.totalItemsSold += Number(record.itemsSold) * ratio;
    merchantStats.orderAmountsTotal += Number(record.totalRevenue) * ratio;
    merchantStats.orderTipsTotal += Number(record.totalTips) * ratio;
    merchantStats.orderTaxTotal += String(record.totalTax) !== 'NaN' ? Number(record.totalTax) * ratio : 0;
    merchantStats.orderRefundsTotal += Number(record.totalRefund) * ratio;
    merchantStats.totalOrdersFulfilled += Number(record.totalOrders) * ratio;
    merchantStats.totalPointsRedeemed += Number(record.totalPointsRedeemed) * ratio;
    merchantStats.totalPointsAwarded += Number(record.totalPointsAwarded) * ratio;
    merchantStats.orderTransactionAmountTotal += Number(record.totalTransactionCost) * ratio;
    merchantStats.totalDiscount += Number(record.totalDiscount) * ratio;
    merchantStats.totalGrossSales += Number(record.totalGrossSales) * ratio;
  })

  merchantStats.stats = updatedStats

  if (merchantStats.totalOrdersFulfilled > 0) {
    merchantStats.avgOrderAmount = merchantStats.orderAmountsTotal / merchantStats.totalOrdersFulfilled;
  }

  merchantStats.totalNetSales =
    merchantStats.totalGrossSales
    - merchantStats.orderRefundsTotal
    - merchantStats.totalDiscount
    - (merchantStats.totalPointsRedeemed / 100);

  merchantStats.totalNetTotal =
    merchantStats.totalNetSales
    - merchantStats.orderTransactionAmountTotal;

  merchantStats.totalTotalGrossSales =
    merchantStats.totalGrossSales
    + merchantStats.orderTaxTotal
    + merchantStats.orderTipsTotal;

  return merchantStats
}

export const formatMerchantCustomerStats = (stats?: Array<MerchantCustomerStats>): MerchantAggregatedCustomerStats => {
  const merchantStats = {
    stats: stats || [],
    newCustomers: 0,
    returningCustomers: 0
  } as MerchantAggregatedCustomerStats;

  if (!stats) return merchantStats

  stats.forEach((record: MerchantCustomerStats) => {
    merchantStats.newCustomers += Number(record.newCustomers);
    merchantStats.returningCustomers += Number(record.returningCustomers);
  })
  return merchantStats
}

export type MerchandiseWithStats = Merchandise & { sale: number, quantity: number }
export type CategoryWithStats = MerchandiseCategory & { sale: number, quantity: number }
export type MerchandiseStatsList = Array<MerchandiseWithStats>
export type CategoriesStatsList = Array<CategoryWithStats>
export type MerchandiseModifierStatsList = Array<{ name: string, sale: number, quantity: number }>

type GetSortedMerchandiseStatsListProp = {
  merchandiseData?: MerchandiseApiResponseType,
  merchantStats: MerchantAggregatedSalesStats,
  merchant?: Merchant
}

const getSoldMerchandiseList = ({ merchandiseData, merchantStats }: GetSortedMerchandiseStatsListProp): { [key: string]: MerchandiseWithStats } => {

  const toMerchandise: { [key: string]: Merchandise } = {}
  merchandiseData?.merchandises.forEach((m: Merchandise) => { toMerchandise[m.id] = m });

  const mercSales: { [key: string]: MerchandiseWithStats } = {};
  merchantStats.stats.forEach((record: MerchantSalesStats) => {
    if (record.salePerMerchandise) {
      Object.entries(record.salePerMerchandise).forEach(([mId, itemSale]) => {
        if (mercSales[mId] != null) {
          mercSales[mId].quantity += itemSale.quantity
          mercSales[mId].sale += itemSale.sale
        } else {
          mercSales[mId] = { ...{ name: itemSale.name }, ...toMerchandise[mId], quantity: itemSale.quantity, sale: itemSale.sale };
        }
      })
    }
  });

  return mercSales;
}

export const getSortedMerchandiseStatsList = ({ merchandiseData, merchantStats }: GetSortedMerchandiseStatsListProp): MerchandiseStatsList => {
  if (!merchandiseData || !merchantStats) return []

  const soldMerchandiseList = getSoldMerchandiseList({ merchandiseData, merchantStats })
  const sortedMerchandiseList = Object.values(soldMerchandiseList).sort((a: MerchandiseWithStats, b: MerchandiseWithStats) => b.quantity - a.quantity)
  return sortedMerchandiseList
}

export const getSortedCategoriesStatsList = ({ merchandiseData, merchantStats, merchant }: GetSortedMerchandiseStatsListProp): CategoriesStatsList => {
  if (!merchandiseData || !merchantStats) return []

  const soldMerchandiseList = getSoldMerchandiseList({ merchandiseData, merchantStats })

  const categorySales: { [key: number]: CategoryWithStats } = {}
  const toCateogories: { [key: number]: MerchandiseCategory } = {
    '-1': generateEverythingElseCategory(merchant)
  }
  merchandiseData.mercCategories.forEach((c: MerchandiseCategory) => { toCateogories[c.id] = c })

  Object
    .values(soldMerchandiseList)
    .forEach((item: MerchandiseWithStats) => {
      const categoryId = item.categoryId ?? -1
      const category = toCateogories[categoryId];

      if (categorySales[categoryId] == null) {
        categorySales[categoryId] = { ...category, quantity: item.quantity, sale: item.sale };
      } else {
        categorySales[categoryId].quantity += item.quantity;
        categorySales[categoryId].sale += item.sale;
      }
    })

  const sortedCategorySales = Object.values(categorySales).sort((a: any, b: any) => b.quantity - a.quantity);
  return sortedCategorySales;
}

type ModifierPricePair = [string, string]
export const getSortedModifiersStatsList = ({ merchandiseData, merchantStats }: GetSortedMerchandiseStatsListProp) => {
  if (!merchandiseData || !merchantStats) return []

  const mercModifiersList = pipe(
    map(({ allOrdersList }: OrdersGroupedByDay) => flatten(pluck("lineItems", allOrdersList))),
    reduce((acc: ModifierPricePair, lineItems: Array<LineItem>) => {
      let modifierPairs = [...acc]
      // iterate over each line item to get its customization
      lineItems.forEach(lineItem => {
        const { customization } = lineItem
        if (!isEmptyOrNil(customization))
          modifierPairs = concat(modifierPairs, toPairs(customization))
      })

      return without([], [...modifierPairs]);
    }, []),
    groupBy((modifierPair: ModifierPricePair) => modifierPair[0]),
    mapObjIndexed((modifierPairs: Array<ModifierPricePair>) => {
      const mercModifier = head(modifierPairs)
      return {
        name: mercModifier[0],
        sales: Number(parseFloat(mercModifier[1]) * modifierPairs.length).toFixed(2),
        quantity: modifierPairs.length
      }
    }),
    values,
    filter((modifier: { name: string, sales: number, quantity: number }) => modifier.quantity > 0),
    sort(descend(prop('quantity')))
  )(propOr([], 'allOrdersList', merchantStats))

  return mercModifiersList
}

export const calculateStatsDateRange = (toDate: Date, statsTimePeriod: MerchantStatsPeriod = MerchantStatsPeriod.Day, fromDate?: Date) => {
  /*
    For Weekly Stats - We need the last 7 days data, i.e. (toDate - 6 days) to (toDate)
    For Daily Stats - We need the current day's data i.e. (toDate 00:00) to (toDate 23:59)
  */

  if (statsTimePeriod === MerchantStatsPeriod.Custom && fromDate) return { toDate, fromDate }

  const rangeFromDate = statsTimePeriod === MerchantStatsPeriod.Week ? subDays(toDate, 6) : toDate;
  const rangeToDate = statsTimePeriod === MerchantStatsPeriod.Week ? toDate : addDays(toDate, 1);
  return {
    fromDate: rangeFromDate,
    toDate: rangeToDate,
  }
}

export const getStatsTimePeriodLabel = (statsTimePeriod: MerchantStatsPeriod): string => {
  const statsTimePeriodLabels = {
    [MerchantStatsPeriod.Custom]: 'Custom',
    [MerchantStatsPeriod.Week]: 'Week',
    [MerchantStatsPeriod.Day]: 'Day',
  }

  return statsTimePeriodLabels[statsTimePeriod]
}

export const getStripeTractionFee = (order: Order): number => {
  if (isEmptyOrNil(order)) return 0;
  const { amount = 0, tax = 0, tip = 0 } = order
  const totalOrderAmount = amount + tax + tip
  // The amounts are calculated based on the normal US Stripe fee of 2.9% + $0.30 per transaction
  return (totalOrderAmount * 0.029 + 0.30)
}

export const getComparision = (currentValue: number, previousValue: number, reverse: boolean = false): PercentageComparisonType => {
  let percentage = null;
  let plusOrMinus = PlusOrMinus.none

  if (currentValue > 0 && previousValue > 0) {
    const isCurrentValueGreater = currentValue > previousValue
    const difference = isCurrentValueGreater ? currentValue - previousValue : previousValue - currentValue
    if (difference !== 0) {
      percentage = toFixed2(Number(difference / previousValue * 100));
      if (!isCurrentValueGreater) {
        percentage = `-${percentage}`
      }
      plusOrMinus = isCurrentValueGreater ? PlusOrMinus.plus : PlusOrMinus.minus
    } else if (difference === 0) {
      percentage = 0
      plusOrMinus = PlusOrMinus.plus
    }
  }

  else if (currentValue > 0 && previousValue <= 0) {
    percentage = 100
    plusOrMinus = PlusOrMinus.plus
  }

  else if (currentValue <= 0 && previousValue > 0) {
    percentage = -100
    plusOrMinus = PlusOrMinus.minus
  }

  return {
    percentage,
    plusOrMinus,
    reverse
  }
}

export const getPercentageComparison = (current: object, comparison: object, key: string, reverse: boolean = false): PercentageComparisonType => {
  const currentValue = prop(key, current)
  const previousValue = prop(key, comparison)
  return getComparision(currentValue, previousValue, reverse);
}

export type ExtractedSalesTabDataType = {
  totalGrossSales: number,
  totalNetSales: number,
  totalNetTotal: number,
  totalTotalGrossSales: number,
  totalOrdersAmount: number,
  avgOrderAmount: number,
  totalTipsAmount: number,
  totalTaxAmount: number,
  tipsPercentage: number,
  totalTransactionAmount: number,
  totalRefundsAmount: number,
  totalDiscount: number,
  totalItemsSold: number,
  totalOrdersFulfilledCount: number,
  totalPointsRedeemed: number,
  totalPointsAmountRedeemed: number,
  merchandiseSales: MerchandiseStatsList
}
export const extractSalesTabData = (salesData: MerchantAggregatedSalesStats, merchant?: MerchantWithMerchandises): ExtractedSalesTabDataType => {
  const merchandiseData = merchant?.merchantData
  const totalOrdersAmount = salesData.orderAmountsTotal;
  const totalTipsAmount = salesData.orderTipsTotal;
  const tipsPercentage = defaultTo(0, (totalTipsAmount / totalOrdersAmount * 100));
  const merchandiseSales = getSortedMerchandiseStatsList({ merchandiseData, merchantStats: salesData })
  return {
    totalGrossSales: salesData.totalGrossSales,
    totalNetSales: salesData.totalNetSales,
    totalNetTotal: salesData.totalNetTotal,
    totalTotalGrossSales: salesData.totalTotalGrossSales,
    totalOrdersAmount,
    totalTipsAmount,
    totalTaxAmount: salesData.orderTaxTotal,
    tipsPercentage,
    totalItemsSold: salesData.totalItemsSold,
    totalTransactionAmount: salesData.orderTransactionAmountTotal,
    totalOrdersFulfilledCount: salesData.totalOrdersFulfilled,
    totalRefundsAmount: salesData.orderRefundsTotal,
    totalDiscount: salesData.totalDiscount,
    avgOrderAmount: salesData.avgOrderAmount,
    totalPointsRedeemed: salesData.totalPointsRedeemed,
    totalPointsAmountRedeemed: salesData.totalPointsRedeemed / 100,
    merchandiseSales
  } as ExtractedSalesTabDataType;
}

export type ExtractedCustomerStatsData = {
  totalNewCustomers: number,
  totalReturningCustomers: number
  totalCustomers: number
}
export const extractCustomerStatsData = (salesData: MerchantAggregatedCustomerStats): ExtractedCustomerStatsData => {
  const totalNewCustomers = salesData?.newCustomers ?? 0
  const totalReturningCustomers = salesData?.returningCustomers ?? 0

  return {
    totalNewCustomers,
    totalReturningCustomers,
    totalCustomers: (totalNewCustomers + totalReturningCustomers)
  }
}

export const extractUsedCouponStats = (stats: MerchantSalesStats[]): UsedCouponDictionary[] => {
  return stats
    ? stats
      .map<UsedCouponDictionary>((item) => (item as MerchantSalesStats).usedCouponPerCode)
      .filter((item) => !isEmptyOrNil(item))
    : [];
}

export const extractMaxWaitMinutesAverage = (operationalStatsData: MerchantSalesStats[] | MerchantCustomerStats[] | MerchantOperationStats[] | undefined): number => {
  return reduce((acc: number, item: MerchantOperationStats) => Math.max(acc, Number(item.maxWaitTime)), 0, operationalStatsData || []) || 0;
}

export const extractAverageWaitMinutes = (operationalStatsData: MerchantSalesStats[] | MerchantCustomerStats[] | MerchantOperationStats[] | undefined): number => {
  const totalNoOfOrders = pipe(
    map(path(['waitTimeInfo', 'noOfOrders'])),
    sum,
  )(defaultTo([], operationalStatsData))

  const averageWaitTime = pipe(
    map(path(['waitTimeInfo', 'totalWaitTime'])),
    sum,
    divide(__, totalNoOfOrders || 1)
  )(defaultTo([], operationalStatsData))

  return toFixed2(averageWaitTime);
}

export const extractTotalOrdersFromStats = (operationalStatsData: MerchantSalesStats[] | MerchantCustomerStats[] | MerchantOperationStats[] | undefined): number => {
  return reduce((acc: number, item: MerchantOperationStats) => acc + pathOr(0, ['waitTimeInfo', 'noOfOrders'], item), 0, operationalStatsData || []) || 0;
}

export const transformMinutesToHumanFormat = (minutesValue: number): string | null => {
  if (!minutesValue) {
    return null;
  }

  const result: string[] = [];
  const hours = Math.floor(minutesValue / 60);

  // to round minutes value in case of there are some hours
  const minutes = (hours ? Math.round : toFixed2)(minutesValue % 60);

  if (hours) {
    result.push(pluralString(hours, 'hour'));
  }

  result.push(pluralString(minutes, 'minute'));
  return result.join(' ');
};

export const getComparisonStats = (currentValue: number, previousValue: number, statsPeriod: MerchantStatsPeriod, reverse?: boolean) => {
  if (statsPeriod === MerchantStatsPeriod.Custom) return [];

  return [
    {
      text: 'Last Week',
      difference: getComparision(currentValue, previousValue, reverse)
    }
  ]
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  sortOrder: 'asc' | 'desc',
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return sortOrder === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function tableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const sortOrder = comparator(a[0], b[0]);
    if (sortOrder !== 0) return sortOrder;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
