import React, { useContext } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  FormControl,
  FormHelperText,
  Fab,
  Grid,
  MenuItem,
  InputLabel,
  Select
} from '@material-ui/core';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Save } from '@material-ui/icons';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { If } from 'react-if';
import PhoneInput from 'react-phone-input-2';
import { defaultTo, omit } from 'ramda';

import { UserContext } from 'bos_common/src/context/UserContext';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import AppDrawer from 'bos_common/src/components/AppDrawer';
import { Gender, MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';
import { Merchant } from 'bos_common/src/types/MerchantType';
import { FabContainer } from 'bos_common/src/components/FabContainers';

import { NotificationSeverity } from '../../../types/NotificationSlice';
import { isEmptyOrNil, getAuthHeaders, getAPIErrorMessage } from '../../../utils';
import { AppContext } from '../../../context/AppContext';
import 'react-phone-input-2/lib/material.css'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '40vw',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: 'auto',
      height: `calc(100% - 105px)`,

      '& form': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },

      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '50vw',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        maxWidth: "100%",

        '& .content-container': {
          marginBottom: "10px",
        },
      },

      '& .content-container': {
        '& .MuiFormControl-root': {
          marginTop: theme.spacing(2),
          height: '78px'
        },

        '& input:valid:focus + fieldset': {
          borderLeftWidth: 6,
          padding: '4px !important', // override inline-style
        },

        '& label#gender-select-label': {
          background: "#f5faff",
          padding: "0 5px 0 5px"
        },

        '& .addon-container': {
          position: 'relative',

          '& .addon': {
            position: 'absolute',
            right: '0',
          }
        }
      },
    }
  })
);

interface FormValues {
  [key: string]: any
}

type StaffFormProps = {
  customer?: MerchantCustomer,
  merchant: Merchant,
  onSubmitSuccess: (customer: MerchantCustomer) => void,
}

const CustomerForm = ({ customer, merchant, onSubmitSuccess }: StaffFormProps) => {
  const classes = useStyles()
  const { token } = useContext(UserContext)
  const { triggerNotification } = useContext(AppContext)
  const { t } = useTranslation();

  const [{ loading }, executeCustomerApi] = useAxios<MerchantCustomer>(
    { url: `/customers/` },
    { manual: true }
  );

  const { register, handleSubmit, formState: { errors, isValid, isDirty }, control } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: customer,
  });

  const registerTextField = (keyName: keyof Partial<MerchantCustomer>, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(keyName, options);
    return { ...inputProps, inputRef }
  }

  const onStaffSubmit: SubmitHandler<FormValues> = (data) => {
    const isEditMode = !isEmptyOrNil(customer)
    const method = isEditMode ? 'PUT' : 'POST'
    const headers = getAuthHeaders(token)
    const customerData = isEditMode ? data : omit(['id', 'userId'], data)

    return executeCustomerApi({
      data: customerData,
      method,
      headers,
      ...(customer && { url: `/customers/${customer.id}` })
    }).then((response) => {
      if (response.status === 200) {
        onSubmitSuccess(response.data);
        triggerNotification(true, `Customer ${isEditMode ? 'updated' : 'created'} successfully!`, NotificationSeverity.SUCCESS, true)
      }
    }).catch(err => {
      console.error(err)
      triggerNotification(true, getAPIErrorMessage(err), NotificationSeverity.ERROR, true)
    });
  };

  const disableActions = !isValid || !isDirty

  const isValidPhoneNumber = (value: string | undefined): string | undefined => {
    if (value && value.length < 11) return "Incomplete Phone#";
    return undefined;
  }

  return (
    <div className={classes.root}>
      <SimpleLoader loading={loading} />

      <form onSubmit={handleSubmit(onStaffSubmit)}>
        <Grid container justifyContent="space-evenly" className="content-container">
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              margin="dense"
              label={t("Name")}
              variant="outlined"
              error={!!errors.displayName}
              helperText={errors.displayName && errors.displayName.message}
              {...registerTextField("displayName", {
                required: t("Staffs_AddAStaff_Name_RequiredText"),
                defaultValue: customer?.displayName,
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(errors.phoneNumber)}
              required
            >
              <Controller
                control={control}
                name="phoneNumber"
                rules={{
                  required: defaultTo(true, t("Customers_AddACustomer_Phone_RequiredText")),
                  validate: (v) => isValidPhoneNumber(v)
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    onChange={(value) => field.onChange(`+${value}`)}
                    country="us"
                    onlyCountries={['us', 'pk', 'cn', 'id', 'in']}
                    specialLabel={`${t("CustomerInsights_PhoneNumber")}*`}
                    inputStyle={{
                      width: '100%'
                    }}
                  />
                )}
              />
              <FormHelperText>
                {errors.phoneNumber && errors.phoneNumber.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              label={t("Email")}
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              {...registerTextField("email", {
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email'
                },
                defaultValue: customer?.email
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(errors.gender)}
              variant='outlined'
            >
              <InputLabel id="gender-select-label">{t("Gender")}</InputLabel>
              <Select
                labelId='gender-select-label'
                defaultValue={customer?.gender ? customer.gender : Gender.male}
                {...registerTextField('gender', { required: "Gender is required" })}
              >
                {Object.keys(Gender).map(key => {
                  const gender = Gender[key as keyof typeof Gender]
                  return <MenuItem key={gender} value={gender} sx={{ textTransform: 'capitalize' }}>{t(gender)}</MenuItem>
                })}
              </Select>
              <FormHelperText>
                {errors.gender && errors.gender.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              label={t("Customers_Note")}
              variant="outlined"
              multiline
              maxRows={3}
              error={!!errors.note}
              helperText={errors.note && errors.note.message}
              {...registerTextField("note", {
                defaultValue: customer?.note
              })}
            />
          </Grid>
        </Grid>

        {customer?.id && <input type="hidden" {...register("id")} value={customer.id} />}
        {customer?.userId && <input type="hidden" {...register("userId")} value={customer.userId} />}
        <input type="hidden" {...register("merchantId")} value={merchant.id} />

        <FabContainer alignment="right">
          <Fab
            variant="extended"
            color="primary"
            type='submit'
            disabled={disableActions}
          >
            <Save />
            {t("Save")}
          </Fab>
        </FabContainer>
      </form>
    </div>
  )
}

type CustomerAddEditDrawerProps = {
  open: boolean,
  setOpen: (b: boolean) => void,
  customer?: MerchantCustomer,
  merchant: Merchant,
  onSubmitSuccess: (staff: MerchantCustomer) => void,
  onCancel: () => void,
  customersList?: Array<MerchantCustomer>
}

const CustomerEditDrawer = (props: CustomerAddEditDrawerProps) => {
  const { customer, open, setOpen, merchant, onSubmitSuccess } = props;
  if (!open) {
    return null;
  }

  const { t } = useTranslation()

  const handleClose = () => {
    props.onCancel()
  };

  const handleSubmit = (updatedCustomer: MerchantCustomer) => {
    onSubmitSuccess(updatedCustomer)
    handleClose();
  }

  const title = customer ? t('Customers_EditACustomer') : t('Customers_AddACustomer')

  return (
    <AppDrawer
      anchor='right'
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title={title}
    >
      <If condition={open}>
        <CustomerForm
          merchant={merchant}
          customer={customer}
          onSubmitSuccess={handleSubmit}
        />
      </If>
    </AppDrawer>
  )
}

export default CustomerEditDrawer