import React, { useState } from 'react';
import { MenuItem, MenuList, Dialog, Fab, DialogTitle, DialogContent, Divider, Typography, Box, Theme, makeStyles, createStyles, IconButton } from '@material-ui/core';
import { Close, NavigateNext } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';

import { getDeviceAuthMeta, MerchantDeviceAuthStorageType } from '../../services/useAuthDevice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    storeSelectorDialog: {
      padding: theme.spacing(2),

      '& .dialog-title': {
        fontSize: "20px",
        fontWeight: 700,
      },

      '& .split-button-menu': {
        margin: theme.spacing(2, 1),
        padding: 0,
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',
      },

      '& .menu-item': {
        alignItems: 'center',
        display: 'grid',
        justifyContent: 'space-between',
        gridTemplateColumns: '1fr auto',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,

        '&:last-child': {
          borderBottom: 0,
        },

        '& span': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      },

      '& .MuiDialogTitle-root': {
        position: 'relative',
        padding: theme.spacing(3),
      },

      '& .close-botton': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: theme.spacing(1),
      },

      '& .MuiPaper-root': {
        borderRadius: '20px',

        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1),
          width: "95%"
        }
      }
    }
  })
)

export default function StaffLoginButton(): React.ReactElement | null {
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const from = location.state?.from;
  const classes = useStyles();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [deviceAuthData] = useState(getDeviceAuthMeta());

  if (!deviceAuthData) return null

  const handleMenuItemClick = (index: number,) => {
    const deviceAuth = deviceAuthData[index] as MerchantDeviceAuthStorageType | undefined;
    if (deviceAuth) {
      history.push({
        pathname: `/${deviceAuth.merchantUsername}/stafflogin`,
        state: { from },
      });
    }
    setOpen(false);
  };

  const handleClick = () => {
    const deviceAuth = deviceAuthData[0] as MerchantDeviceAuthStorageType | undefined;
    if (deviceAuth) {
      history.push({
        pathname: `/${deviceAuth.merchantUsername}/stafflogin`,
        state: { from },
      });
    }
  };

  const toggleStaffLoginSelector = () => setOpen(!open);

  return (
    <>
      <Fab
        onClick={handleClick}
        variant="extended"
        color='primary'
        sx={{ 
          width: '100%', 
          mb: 1,  
          
          '& .MuiFab-label': {
            whiteSpace: 'nowrap', 
            textOverflow: 'ellipsis', 
            overflow: 'hidden', 
            display: 'block'
          }
        }}
      >
        {`${t('Signup_MerchantStaffLogin')} "${deviceAuthData[0].merchantOfficialName}"`}
      </Fab>

      <Fab
        onClick={toggleStaffLoginSelector}
        variant="extended"
        color='secondary'
        sx={{ width: '100%' }}
      >
        {t('Signup_LoginOtherStores')}
      </Fab>

      <Dialog
        open={open}
        onBackdropClick={toggleStaffLoginSelector}
        onClose={toggleStaffLoginSelector}
        className={classes.storeSelectorDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <div className="dialog-title">{t('Signup_SelectStore')}</div>
          <IconButton className="close-botton" onClick={toggleStaffLoginSelector}>
            <Close color="primary" />
          </IconButton>
        </DialogTitle>

        <Divider sx={{ borderColor: rgba(0, 0, 0, 0.15) }} />

        <DialogContent>
          <MenuList className="split-button-menu" id="split-button-menu">
            {deviceAuthData.map((deviceAuth, index) => (
              <MenuItem
                key={deviceAuth.merchantId}
                onClick={() => handleMenuItemClick(index)}
                className='menu-item'
              >
                <span>{`${deviceAuth.merchantOfficialName} (@${deviceAuth.merchantUsername})`}</span> <NavigateNext color='primary' />
              </MenuItem>
            ))}
          </MenuList>
        </DialogContent>
      </Dialog>
    </>
  );
}