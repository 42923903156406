/* eslint-disable import/no-cycle */

import { BaseIncrement } from "../BaseIncrement";
import { Merchant } from "../MerchantType";
import CalendarEvent from "./CalendarEventType";

export enum CustomerRelationship {
  parent = 'parent',
  guardian = 'guardian',
  emergencyContact = 'emergencyContact',
  incharge = 'incharge',
}

export type RelatedCustomer = {
  customerId: number;
  customer?: MerchantCustomer;

  relatedId: number;
  related?: MerchantCustomer;

  relationship: CustomerRelationship;
};

export enum Gender {
  male = 'male',
  female = 'female',
  unspecified = 'unspecified',
}
export enum CustomerType {
  AUTO_GENERATED = 'AUTO_GENERATED',
  MANUALLY_CREATED = 'MANUALLY_CREATED',
}
export interface MerchantCustomer extends BaseIncrement {
  userId?: string;

  merchantId: string;
  merchant?: Merchant;

  serviceSessions?: CalendarEvent[];

  displayName?: string;
  picture?: string;
  gender?: Gender;
  email?: string;
  phoneNumber?: string;
  note?: string;
  relatedCustomers?: RelatedCustomer[];

  type: CustomerType;
}