import React, { useMemo } from 'react';
import { Box, Fab, Stack, useTheme } from '@material-ui/core';
import { isEmptyOrNil } from 'bos_common/src';
import { MerchantStory } from 'bos_common/src/types/MerchantStoryType';
import { useHistory } from 'react-router-dom';

interface StoryCTAProps {
    story: MerchantStory;
}

const StoryCTA = (props: StoryCTAProps) => {
    const { story } = props;
    const history = useHistory();
    const theme = useTheme();

    const renderCTA = useMemo(() => {
        const navigateToMenu = () => history.push({ pathname: `/${story.merchant.username}`, state: { from: window.location.pathname } })

        const navigateToExternalLink = () => {
            const url = story?.action?.typeInfo?.externalLink;
            window.open(url, '_blank')
        }

        const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ctaCB: ()=>void) => {
          e.preventDefault();
          e.stopPropagation();
          ctaCB();
        }

        if (isEmptyOrNil(story.action)) {
            return (
                <Stack direction={"row"} spacing={2}>
                    <Fab onClick={(e) => onClick(e, navigateToMenu)} variant="extended" color="primary">
                        Order Now
                    </Fab>
                </Stack>
            );
        } else if (story?.action?.type === 'external-link') {
            return (
                <Stack direction={"row"} spacing={2}>
                    <Fab onClick={(e) => onClick(e, navigateToExternalLink)} variant="extended" color="primary">
                        {story?.action?.typeInfo?.actionButtonText}
                    </Fab>
                </Stack>
            );
        }
        // shouldnt get here
        return;
    }, [story]);

    return (
      <Box sx={{display: 'flex', justifyContent: 'center', margin: 'auto', mt: 2, mb: 2 }}>
        {renderCTA}
      </Box>
    );
}
export default StoryCTA;