import React from 'react'
import { Switch } from '@material-ui/core'
import { If } from 'react-if'

import { isEmptyOrNil } from '../../../utils'
import FieldWrapper from "./FieldWrapper"

type SwitchFormFieldType = {
  defaultValue?: boolean
  disabled?: boolean,
  registerField: () => any
  id?: string
  switchLabel?: string
  fieldLabel: string
}
const SwitchFormField = ({
  defaultValue,
  disabled = false,
  registerField,
  id = 'availability-switch',
  switchLabel = "",
  fieldLabel = ''
}: SwitchFormFieldType) => {

  return (
    <FieldWrapper fieldLabel={fieldLabel}>
      <Switch
        defaultChecked={defaultValue}
        defaultValue={defaultValue}
        name={id}
        id={id}
        readOnly={disabled}
        color='primary'
        {...registerField()}
      />
      <If condition={!isEmptyOrNil(switchLabel)}>
        <label htmlFor={id}>
          {switchLabel}
        </label>
      </If>
    </FieldWrapper>
  )
}

export default SwitchFormField