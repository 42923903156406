import React, { useContext } from "react"
import { Card, CardContent, createStyles, makeStyles, Typography } from "@material-ui/core";
import { If } from "react-if";

import PercentageComparison, { PercentageComparisonProps } from "./PercentageComparison";
import { MerchantStatsContext } from "../../context/MerchantStats/MerchantStatsContext";
import { MerchantStatsPeriod } from "./types";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      position: "relative",

      '& .title-container': {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
      }
    },
  })
)

type MerchantStatsTileProp = {
  header: string,
  value?: string | number,
  subValue?: string | number,
  children?: React.ReactElement | Array<React.ReactElement>,
  subHeader?: string | number,
  title?: string,
  comparisonPercentage?: PercentageComparisonProps
}
const MerchantStatsTile = ({ header, value, subValue, subHeader, title, comparisonPercentage, children }: MerchantStatsTileProp) => {
  const classes = useStyles()
  const { statsTimePeriod } = useContext(MerchantStatsContext)
  const isCustomStatsPeriod = MerchantStatsPeriod.Custom === statsTimePeriod

  return (
    <Card elevation={3} className={classes.root} title={title}>
      <CardContent>
        <Typography sx={{ fontWeight: 'bold', lineHeight: 1, fontSize: 14 }} color="text.secondary" gutterBottom className='title-container'>
          {header} {(comparisonPercentage && !isCustomStatsPeriod) && <PercentageComparison {...comparisonPercentage} />}
        </Typography>
        <If condition={Boolean(children)}>
          {children}
        </If>
        <If condition={value && !Boolean(children)}>
          <Typography sx={{ lineHeight: 1 }} variant="h5" component="div">
            {value}
            <If condition={subValue}>
              <Typography sx={{ mb: 1.5 }} component="span" color="text.secondary">
                &nbsp;{subValue}
              </Typography>
            </If>
          </Typography>
        </If>
        <If condition={subHeader}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {subHeader}
          </Typography>
        </If>
      </CardContent>
    </Card>
  )
};

export default MerchantStatsTile;