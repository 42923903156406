import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import authReducer from "./slice/auth";
import merchantReducer from './slice/merchant';
import merchandiseReducer from './slice/merchandise';
import merchandiseCategoryReducer from './slice/merchandiseCategory';
import notificationReducer from './slice/notification';
import dashboardSettingsReducer from './slice/dashboardSettings';
import merchantServicePackagesReducer from './slice/merchantServicePackages';

const store = configureStore({
  reducer: {
    auth: authReducer,
    merchant: merchantReducer,
    merchandise: merchandiseReducer,
    merchandiseCategory: merchandiseCategoryReducer,
    notification: notificationReducer,
    dashboardSettings: dashboardSettingsReducer,
    packages: merchantServicePackagesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'production'
      ? getDefaultMiddleware().concat(thunk)
      : getDefaultMiddleware().concat(thunk).concat(logger)
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
