import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import { If, Then, Else } from 'react-if'


const MerchandiseStatsListing = ({ loading, children }: { loading: boolean, children: React.ReactElement | Array<React.ReactElement> }) => {
  return (
    <Card elevation={2} className="charts" >
      <CardContent>
        <If condition={loading}>
          <Then>
            <CardHeader subtitle="Loading Merchandises Stats..." />
          </Then>
          <Else>
            {children}
          </Else>
        </If>
      </CardContent>
    </Card>
  )

}

export default MerchandiseStatsListing