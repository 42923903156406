import React, { useMemo } from 'react';
import {
  Card,
  makeStyles,
  Theme,
  createStyles,
  CardActionArea,
  CardMedia,
  Typography,
  Box,
  Stack,
  CardMediaTypeMap,
} from '@material-ui/core';
import { If, Then, Else } from 'react-if';
import { pathOr, propOr } from 'ramda';
import { VisibilityOff, Star as StarIcon, Menu } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { withFallbackPath } from '../bos_common/src';
import { getMerchandiseOverallRatings } from '../bos_common/src/utils';
import { renderPriceWithUnit } from '../bos_common/src/components/Price';
import MerchandiseDescription from '../bos_common/src/components/Merchandise/MerchandiseDescription';
import { RenderSoldOutShort } from '../bos_common/src/components/Merchandise/MerchandiseHelper';
import MerchandiseAllergens from '../bos_common/src/components/Merchandise/MerchandiseAllergens';

import MenuItemCardDropdown from './MenuItemCardDropdown';
import { isEmptyOrNil, isMerchandiseAvailable } from '../utils';
import { Merchandise } from '../services/models';
import TypographyEllipsis from '../bos_common/src/components/TypographyEllipsis';
import { RenderMerchandiseBadgesComponent } from '../bos_common/src/components/Merchandise/MerchandiseBadges';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MenuCardContainer: {
      height: '100%',
      '& .cardHeading': {
        display: 'flex',
        justifyContent: 'space-between',
      },

      '& .details': {
        flexGrow: 1,
        height: '100%',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        overflow: 'hidden',
        '& .dragHandle': {
          cursor: 'pointer',
          paddingLeft: theme.spacing(1),
        },
        '& .priceRow': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
      '& .merchActions': {
        display: 'flex',
        alignItems: 'center',
        gridGap: theme.spacing(0.5),
      },
      '& .hideIcon': {
        opacity: 0.7,
        padding: theme.spacing(0.5),
        verticalAlign: 'middle',
      },
      '& .merchPhoto': {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column',
        width: 'inherit',
        paddingLeft: theme.spacing(1),
        '& .cover-container': {
          position: 'relative',
        },
        '& .cover': {
          width: 120,
          height: 120,
          borderRadius: theme.spacing(1),
        },
      },
      '& .merchPhotoPlaceholder': {
        padding: theme.spacing(1),
      },
      '& .merch-rating': {
        display: 'flex',
        marginTop: '-1.25rem',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        padding: '0 0.25rem',
        borderRadius: '0.25rem',
        boxShadow: '0px 3px 12px rgb(0 0 0 / 7%)',

        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
      },
    },
  })
);

type MenuItemCardProps = {
  merchandise: Merchandise;
  onMerchandiseClick: (merchandise: Merchandise) => void;
  showExtraActions?: boolean;
  showReviews?: boolean;
};

const MenuItemCard = (props: MenuItemCardProps) => {
  const classes = useStyles();

  const { merchandise, onMerchandiseClick, showExtraActions = false, showReviews = false } = props;
  const { photos, name, price } = merchandise;
  const photo = propOr('', 0, photos); // select first photo
  const CardMediaEnhanced: OverridableComponent<CardMediaTypeMap<{}, 'div'>> = useMemo(
    () => withFallbackPath(CardMedia, photo),
    [photo]
  );

  const onMerchandiseItemClick = () => {
    onMerchandiseClick(merchandise);
  };

  const overallRatings = getMerchandiseOverallRatings(merchandise);
  const numberOfReviews = pathOr(0, ['rating', 'reviewCount'])(merchandise);

  const MerchandiseRating = (
    <If condition={showReviews && numberOfReviews >= 3 && overallRatings}>
      <div className="merch-rating">
        <StarIcon color="primary" fontSize="small" />
        <Typography variant="subtitle1" component="span">
          {overallRatings}
        </Typography>
      </div>
    </If>
  );

  return (
    <Card className={classes.MenuCardContainer} elevation={3}>
      <div style={{ display: 'flex', height: '100%' }}>
        <If condition={photo}>
          <Then>
            <CardActionArea className="merchPhoto" onClick={onMerchandiseItemClick}>
              <div className="cover-container">
                <CardMediaEnhanced
                  className="cover"
                  src={`${photo}_small`}
                  title={name}
                  component="img"
                  alt="merch-img"
                />
                <RenderMerchandiseBadgesComponent merchandise={merchandise} />
              </div>
              {MerchandiseRating}
            </CardActionArea>
          </Then>
          <Else>
            <div className="merchPhotoPlaceholder">
              <RenderMerchandiseBadgesComponent merchandise={merchandise} />
            </div>
          </Else>
        </If>

        <Stack className="details" flexDirection="column" justifyContent="space-between">
          <Stack direction="row">
            <CardActionArea onClick={onMerchandiseItemClick} sx={{width: 'calc(100% - 36px)'}}>
              <Box>
                <TypographyEllipsis>{name}</TypographyEllipsis>
              </Box>
              <MerchandiseDescription merchandise={merchandise} />
              <MerchandiseAllergens allergens={merchandise?.ingredientTags} />
              {!isMerchandiseAvailable(merchandise) && <RenderSoldOutShort />}
              {!merchandise.visible && <VisibilityOff className="hideIcon" />}
            </CardActionArea>
            <div className="dragHandle">
              <Menu />
            </div>
          </Stack>
          <div className="priceRow">
            <If condition={isEmptyOrNil(photo)}>{MerchandiseRating}</If>
            <Typography variant="subtitle1" color="textSecondary" component="div">
              {renderPriceWithUnit(price, undefined, merchandise.unit, merchandise.unitQuantity)}
            </Typography>
            <If condition={showExtraActions}>
              <div className="merchActions">
                <MenuItemCardDropdown
                  onEditClick={onMerchandiseItemClick}
                  merchandise={merchandise}
                />
              </div>
            </If>
          </div>
        </Stack>
      </div>
    </Card>
  );
};

export default MenuItemCard;
