import React from 'react'
import { SvgIcon } from "@material-ui/core";

function EmployeeInsightsIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 21, height: 21 }} viewBox="0 0 21 21" {...props} >
      <g clipPath="url(#clip0_6401_38536)">
        <path d="M6.689 5.70697H13.689C13.9651 5.70697 14.189 5.48312 14.189 5.20709V4.20703C14.189 3.93085 13.9651 3.707 13.689 3.707H12.189C12.189 2.60242 11.2936 1.70703 10.1891 1.70703C9.08447 1.70703 8.18909 2.60242 8.18909 3.707H6.689C6.41296 3.707 6.18896 3.93085 6.18896 4.20703V5.20709C6.18912 5.48328 6.41296 5.70697 6.689 5.70697Z" fill="#1AAA5D" />
        <path d="M8.189 11.207C8.189 12.0354 7.5173 12.7071 6.6889 12.7071C5.8605 12.7071 5.18896 12.0354 5.18896 11.207C5.18896 10.3786 5.8605 9.70703 6.6889 9.70703C7.5173 9.70703 8.189 10.3786 8.189 11.207Z" fill="#1AAA5D" />
        <path d="M7.00546 13.707H6.37268C5.23834 13.7086 4.29352 14.5769 4.19678 15.7072H9.18121C9.08447 14.5769 8.13965 13.7086 7.00546 13.707Z" fill="#1AAA5D" />
        <path d="M19.6889 4.70703H15.189V5.20706C15.188 6.03516 14.517 6.70609 13.689 6.707H6.6889C5.86081 6.70609 5.18988 6.035 5.18896 5.20706V4.70703H0.688995C0.412811 4.70703 0.188965 4.93088 0.188965 5.20706V19.207C0.188965 19.4832 0.412811 19.707 0.688995 19.707H19.6889C19.9651 19.707 20.189 19.4832 20.189 19.207V5.20706C20.189 4.93088 19.9651 4.70703 19.6889 4.70703ZM10.189 16.207C10.189 16.4832 9.96512 16.707 9.68893 16.707H3.68903C3.41284 16.707 3.189 16.4832 3.189 16.207V15.8907C3.19235 14.6707 3.8938 13.5606 4.99396 13.0338C3.97894 12.0976 3.91501 10.5161 4.85098 9.50104C5.78711 8.48618 7.36868 8.42209 8.3837 9.35822C9.39856 10.2942 9.46265 11.8758 8.52652 12.8908C8.4809 12.9404 8.43314 12.988 8.3837 13.0338C9.48386 13.5606 10.1855 14.6707 10.189 15.8907V16.207ZM16.6889 16.207H12.689C12.4128 16.207 12.1889 15.9831 12.1889 15.7069C12.1889 15.4309 12.4128 15.2069 12.689 15.2069H16.6889C16.9651 15.2069 17.1889 15.4309 17.1889 15.7069C17.1889 15.9831 16.9651 16.207 16.6889 16.207ZM16.6889 13.2071H11.6891C11.4129 13.2071 11.189 12.9831 11.189 12.7071C11.189 12.4309 11.4129 12.207 11.6891 12.207H16.6889C16.9651 12.207 17.1889 12.4309 17.1889 12.7071C17.1889 12.9832 16.9651 13.2071 16.6889 13.2071ZM16.6889 10.2071H11.6891C11.4129 10.2071 11.189 9.98322 11.189 9.70703C11.189 9.43085 11.4129 9.207 11.6891 9.207H16.6889C16.9651 9.207 17.1889 9.43085 17.1889 9.70703C17.1889 9.98322 16.9651 10.2071 16.6889 10.2071Z" fill="#1AAA5D" />
      </g>
      <defs>
        <clipPath id="clip0_6401_38536">
          <rect width="20" height="20" fill="white" transform="translate(0.188965 0.691406)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EmployeeInsightsIcon