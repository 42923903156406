import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Stack, Typography } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import QRCode from 'qrcode.react';
import { If, Then, Else } from 'react-if';
import { Smartphone } from '@material-ui/icons';

import { ColoredPaper } from '../bos_common/src';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';

import MerchantPageHeader from '../components/MerchantPageHeader';
import CompletedOrdersCallboard from '../components/Callboard/CompletedOrdersCallboard';
import InProgressOrdersCallboard from '../components/Callboard/InProgressOrdersCallboard';
import { CallboardContext } from '../context/CallboardContext/CallboardContext';
import { getMarketPlaceMerchantURL } from '../bos_common/src/services/urls';
import { AppContext } from '../context/AppContext';
import { isEmptyOrNil } from '../utils';
import useFetchMerchant from '../services/useFetchMerchant';

import AppLogo from '../assets/icons/1m-logo.svg'
import CircleIllustration from '../assets/images/circle-illustration.png'
import LadyWithJuiceSrc from '../assets/images/lady-with-juice.png'
import PolygonFilledSrc from '../assets/images/polygon-filled.png'
import PolygonTransparentSrc from '../assets/images/polygon-transparent.png'

import useStyles from '../components/Callboard/styles';
import 'react-alice-carousel/lib/alice-carousel.css';

const SlideWrapper = (
  { children, cssClassName = '' }
    : { children: React.ReactElement | React.ReactElement[], cssClassName?: string }
) => {
  return (
    <div className={`slide-container ${cssClassName}`}>
      <img src={CircleIllustration} alt="circle-illustration" className="circle-illustration" />
      <div className="content">
        {children}
      </div>
    </div>
  )
}

const CallboardBanner = () => {
  const classes = useStyles();
  const { merchant } = useContext(AppContext);

  const MerchantQRCode = (
    <div className="QRCode-wrapper">
      <QRCode
        value={merchant ? getMarketPlaceMerchantURL(merchant) : ''}
        size={256}
        level="Q"
        bgColor="#ffffff"
        fgColor="#000000"
        renderAs="svg"
        imageSettings={{
          src: '/qrcode_logo.svg',
          x: undefined,
          y: undefined,
          height: 60,
          width: 60,
          excavate: true,
        }}
      />
    </div>
  )

  const slides = [
    <SlideWrapper key={1}>
      <img alt="One Market" src={AppLogo} width={200} />
      <Typography
        color="white"
        variant='h2'
        sx={{ fontWeight: 'bold', '& .highlight': { color: '#FFF5F0' }, my: 2, lineHeight: 1 }}
      >
        Scan to Order&nbsp;
        <span className='highlight'>&amp;<br />Earn Rewards</span>
      </Typography>
      {MerchantQRCode}
      <div className="scan-to-order">
        <Smartphone />&nbsp;Scan to Order
      </div>
      <div className="slide-illustration">
        <img src={PolygonFilledSrc} alt="polygon-filled" />
        <img src={PolygonTransparentSrc} alt="polygon-transparent" />
        <img src={LadyWithJuiceSrc} alt="lady-with-juice" />
      </div>
    </SlideWrapper>,
    <SlideWrapper key={2} cssClassName="centered">
      <Stack direction="row" spacing={7} alignItems="center" justifyContent='center'>
        {MerchantQRCode}
        <Typography
          color="white"
          variant='h2'
          align='left'
          sx={{ fontWeight: 'bold', '& .highlight': { color: '#FFF5F0' } }}
        >
          First to Order,<br />
          Big Rewards Await!<br />
        </Typography>
      </Stack>
      <Typography variant="h4" color="white" sx={{ mt: 7, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        Powered by&nbsp;&nbsp;<img alt="One Market" src={AppLogo} width={200} />
      </Typography>
    </SlideWrapper>
  ]

  return (
    <div className={classes.slider}>
      <AliceCarousel
        autoHeight
        infinite
        disableDotsControls
        mouseTracking
        disableButtonsControls
        autoPlay
        autoPlayInterval={10000}
        items={slides}
      />
    </div>
  )
}

const CallboardBannerTimeoutInterval = 5 * 60 * 1000; // Five Minutes

interface IMerchantCallBoardPage {
  merchantUsername: string;
}

const OrdersCallboardPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { merchantUsername } = useParams<IMerchantCallBoardPage>();
  const { loading, inProgressOrdersList, completedOrdersList } = useContext(CallboardContext);
  const [showBanner, setShowBanner] = useState(false);

  // fetch latest merchant data and set it in the AppContext
  const loadingMerchant = useFetchMerchant({ merchantUsername })

  let timer: NodeJS.Timeout;
  const ordersList = [...completedOrdersList, ...inProgressOrdersList]

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    // show Banner when there is no order in the callboard
    if (isEmptyOrNil(ordersList)) {
      timer = setTimeout(() => {
        setShowBanner(true)
      }, CallboardBannerTimeoutInterval)
    } else {
      setShowBanner(false);
    }

    return () => {
      clearTimeout(timer)
    }
  }, [ordersList])


  return (
    <div className='container'>
      <MerchantPageHeader hideOnScroll onBack={onBack} elevation={0} title={""} />
      <SimpleLoader loading={loading} />
      <If condition={showBanner}>
        <Then>
          <CallboardBanner />
        </Then>
        <Else>
          <ColoredPaper elevation={3}>
            <Grid container className={classes.root} spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <InProgressOrdersCallboard />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CompletedOrdersCallboard />
              </Grid>
            </Grid>
          </ColoredPaper>
        </Else>
      </If>
    </div >
  );
}

export default OrdersCallboardPage;