import React from 'react'
import { SvgIcon } from '@material-ui/core'

const TimeOutlineIcon = (props: any) => {
  return (
    <SvgIcon sx={{ width: 80, height: 80 }} viewBox="0 0 80 80" fill="none" {...props}>
      <path d="M73.8281 32.1875H53.8281C53.4844 32.1875 53.2031 32.4687 53.2031 32.8125V36.5625C53.2031 36.9063 53.4844 37.1875 53.8281 37.1875H73.8281C74.1719 37.1875 74.4531 36.9063 74.4531 36.5625V32.8125C74.4531 32.4687 74.1719 32.1875 73.8281 32.1875ZM63.3594 42.8125H53.8281C53.4844 42.8125 53.2031 43.0937 53.2031 43.4375V47.1875C53.2031 47.5313 53.4844 47.8125 53.8281 47.8125H63.3594C63.7031 47.8125 63.9844 47.5313 63.9844 47.1875V43.4375C63.9844 43.0937 63.7031 42.8125 63.3594 42.8125ZM37.2891 25.1953H33.9063C33.4219 25.1953 33.0312 25.5859 33.0312 26.0703V45.4453C33.0312 45.7266 33.1641 45.9844 33.3906 46.1484L45.0234 54.6328C45.4141 54.9141 45.9609 54.8359 46.2422 54.4453L48.25 51.7031V51.6953C48.5313 51.3047 48.4453 50.7578 48.0547 50.4766L38.1563 43.3203V26.0703C38.1641 25.5859 37.7656 25.1953 37.2891 25.1953V25.1953Z" fill="black" />
      <path d="M62.875 52.6484H58.3594C57.9219 52.6484 57.5079 52.8749 57.2735 53.2499C56.2813 54.8203 55.125 56.2734 53.7969 57.6015C51.5079 59.8906 48.8438 61.6874 45.8829 62.9374C42.8125 64.2343 39.5547 64.8906 36.1954 64.8906C32.8282 64.8906 29.5704 64.2343 26.5079 62.9374C23.5469 61.6874 20.8829 59.8906 18.5938 57.6015C16.3047 55.3124 14.5079 52.6484 13.2579 49.6874C11.961 46.6249 11.3047 43.3671 11.3047 39.9999C11.3047 36.6328 11.961 33.3828 13.2579 30.3124C14.5079 27.3515 16.3047 24.6874 18.5938 22.3984C20.8829 20.1093 23.5469 18.3124 26.5079 17.0624C29.5704 15.7656 32.836 15.1093 36.1954 15.1093C39.5625 15.1093 42.8204 15.7656 45.8829 17.0624C48.8438 18.3124 51.5079 20.1093 53.7969 22.3984C55.125 23.7265 56.2813 25.1796 57.2735 26.7499C57.5079 27.1249 57.9219 27.3515 58.3594 27.3515H62.875C63.4141 27.3515 63.7579 26.789 63.5157 26.3124C58.4219 16.1796 48.0938 9.59369 36.5625 9.46088C19.6797 9.24994 5.6563 23.0703 5.62505 39.9374C5.5938 56.8359 19.2891 70.5468 36.1876 70.5468C47.8672 70.5468 58.3672 63.9374 63.5157 53.6874C63.7579 53.2109 63.4063 52.6484 62.875 52.6484V52.6484Z" fill="black" />
    </SvgIcon>
  )
}
export default TimeOutlineIcon;