import React, { useEffect, useState } from 'react';
// src
import { auth0, AuthResultData, getAuthState, loginWithAuth0Token } from '../services/auth0';
import { UserContext } from './UserContext';
import { User } from '../types/UserTypes';
import axios from "../services/backendAxios";
import { getAuthHeaders } from '..';
import { LOCALSTORAGE_APP_KEYS } from '../constants';

const UserContextProvider = (props: {
  children: React.ReactNode,
  skipLogin?: boolean,    // only used for running the web in an native wrapper
}): React.ReactElement => {
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string>('')
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [authenticating, setAuthenticating] = useState<boolean>(true)

  const setLoginState = (u: User | undefined, t: string | undefined) => {
    setUser(u)
    setToken(t || '')

    // if user.phoneNumber is present, then update it else remove the key from session storage
    if (u?.phoneNumber)
      sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER, u?.phoneNumber ?? '')
    else
      sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER)

    // if user.displayName is present, then update it else remove the key from session storage
    if (u?.displayName)
      sessionStorage.setItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME, u?.displayName ?? '')
    else
      sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME)
  }

  useEffect(() => {
    if (!props.skipLogin) {
      const authState = getAuthState()
      if (authState) {
        setAuthenticating(true)
        try {
          auth0.checkSession(
            authState,
            function (err, authResult) {
              if (authResult?.idTokenPayload && authResult?.idToken) {
                const authState1 = getAuthState()
                // make sure no new auth has happened since beginning of this request
                if (authState1 && authState1.nonce === authState.nonce) {
                  loginWithAuth0Token(authResult.idToken, authResult.idTokenPayload)
                    .then((data: AuthResultData | undefined) => {

                      setLoginState(data?.user, data?.token)
                      setAuthenticating(false)
                    }).catch((err) => {
                      setAuthenticating(false)
                    })
                } else {
                  setAuthenticating(false)
                }
              } else {
                console.log(err)
                setAuthenticating(false)
              }
            }
          );
        } catch (err) {
          console.log(err)
          setAuthenticating(false)
        }
      } else {
        setAuthenticating(false)
      }
    } else {
      setAuthenticating(false)
    }
  }, [])

  const refreshUser = () => {
    axios.get<User>('/users/get', { headers: getAuthHeaders(token) }).then(res => setUser(res.data)).catch(err => console.log(err))
  }

  const updateUserPicture = (pictureUrl: string) => {
    if (user) {
      setUser({ ...user, picture: pictureUrl });
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        setLoginState,
        authenticating,
        setAuthenticating,
        loading: userLoading,
        setLoading: setUserLoading,
        refreshUser,
        updateUserPicture
      }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider