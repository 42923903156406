import { If } from 'react-if';
import React, { useState, useContext, useEffect } from 'react';
import { NavigateNext } from '@material-ui/icons';
import { Alert, Button, FormHelperText, IconButton, TextField } from '@material-ui/core';

import { UserContext } from '../../context/UserContext';
import EmailVerificationDialog from './EmailVerificationDialog';
import useAuthStyles from './styles';

import { AuthFieldProps } from './types';
import 'react-phone-input-2/lib/style.css';
import { isEmailValid } from '../../utils';
import { LOCALSTORAGE_APP_KEYS } from '../../constants';

declare let window: any;

const EmailSignup = (props: AuthFieldProps): React.ReactElement => {
  const {
    fullwidth,
    showActionBtn,
    redirectTo,
    isDisabled = undefined,
    actionBtnText = 'Sign Up/Login',
    useErrorAlert = true
  } = props;
  const classes = useAuthStyles({ fullwidth });

  const cachedConsumerEmail = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL)
  const [email, setEmail] = useState(cachedConsumerEmail || '');
  const [emailSignupOpen, setEmailSignupOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { setAuthenticating } = useContext(UserContext);

  const submitEmail = () => {
    // eslint-disable-next-line no-useless-escape
    if (isEmailValid(email)) {
      setEmailSignupOpen(true);
    } else {
      setErrorMsg('Invalid Email');
    }
  };

  const onChangeHandle = (e: { target: { value: React.SetStateAction<string> } }) => {
    setEmail(e.target.value);
    setErrorMsg('');
  };

  // useEffect(() => {
  //   if (!isDisabled && !cachedConsumerEmail) {
  //     submitEmail();
  //   }
  // }, [isDisabled, email])

  return (
    <div className={classes.root}>
      <div className="signUpActionRow">
        <TextField
          className="emailInput"
          required
          label="Email"
          type="email"
          variant="outlined"
          size="small"
          value={email}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              submitEmail();
            }
          }}
          onChange={onChangeHandle}
        />
        <IconButton
          color="primary"
          aria-label="next"
          className="nextButton"
          onClick={submitEmail}
        >
          <NavigateNext />
        </IconButton>
      </div>

      <If condition={errorMsg && useErrorAlert}>
        <Alert severity="error">{errorMsg}</Alert>
      </If>
      <If condition={errorMsg && !useErrorAlert}>
        <FormHelperText sx={{ color: 'red' }}>{errorMsg}</FormHelperText>
      </If>

      {showActionBtn && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={submitEmail}
          fullWidth
          sx={{ marginTop: 1 }}
          {...(isDisabled && { disabled: true })}
        >
          {actionBtnText}
        </Button>
      )}
      <EmailVerificationDialog
        email={email}
        open={emailSignupOpen}
        setOpen={setEmailSignupOpen}
        redirectTo={redirectTo}
      />
    </div>
  );
};

export default EmailSignup;
