import React, { useContext } from 'react';
import { Fab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { If } from 'react-if';

import { getAuthHeaders, isQuickSmsActionEnabled } from '../../../../utils';
import { isOrderReminderLimitExceeded } from "../../../../utils/orderUtils";

import { AppContext } from '../../../../context/AppContext';
import { MerchantOrdersContext } from '../../../../context/MerchantOrders/MerchantOrdersContext';

import { UserContext } from '../../../../bos_common/src/context/UserContext';
import axios from '../../../../bos_common/src/services/backendAxios';

import { Order } from '../../../../services/models';
import { NotificationSeverity } from '../../../../types/NotificationSlice';
import { OrderActionTemplateProps } from './types';

const RemindCustomerFab = (props: Omit<OrderActionTemplateProps, 'closeDrawer'>) => {
  const { order } = props;
  const { t } = useTranslation();

  const { token } = useContext(UserContext);
  const { triggerNotification, merchant } = useContext(AppContext)
  const { updateOrders } = useContext(MerchantOrdersContext);
  // const dashboardSettings = useAppSelector(getDashboardSettings)

  // functions to handle action
  const handleConfirm = () => {
    axios.post<Order>(
      `merchants/orders/${order.id}/reminder`,
      {},
      { headers: getAuthHeaders(token) }
    ).then((response) => {
      if (response.status === 200) {
        triggerNotification(true, `SMS reminder for ${order.userDisplayName} sent successfully.`, NotificationSeverity.SUCCESS)
        setTimeout(() => {
          response.data && updateOrders([response.data]);
        }, 100)
      } else {
        triggerNotification(true, `Failed to send SMS reminder for ${order.userDisplayName}'s order.`, NotificationSeverity.WARNING)
      }
    });
  }

  const showFabAction = !isOrderReminderLimitExceeded(order) && isQuickSmsActionEnabled(merchant)

  return (
    <If condition={showFabAction}>
      <Fab
        aria-label="sms-reminder"
        onClick={handleConfirm}
        variant='extended'
        size='medium'
        title={t("RemindCustomer")}
      >
        {t('RemindCustomer')}
      </Fab>
    </If>
  )
}

export default RemindCustomerFab