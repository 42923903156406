import React, { useMemo, useState } from "react";
import { Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Stack } from "@material-ui/core";

import { isEmptyOrNil } from "bos_common/src";
import InputSearchBox from "bos_common/src/components/InputSearchBox";
import MerchantPackage from "bos_common/src/types/crm/MerchantPackageType";

import { useMerchantMenuContext } from "../../../context/MenuContext/MerchantMenuContext";
import ServicePackagesList from "../../../components/MerchantCustomers/components/ServicePackagesList";

const PackagesMenu = () => {
  const [searchQuery, setSearchQuery] = useState<string>();

  const { state } = useMerchantMenuContext();

  const { t } = useTranslation();

  // Filter List based on search query
  const filteredPackagesList: MerchantPackage[] | undefined = useMemo(() => {
    if(isEmptyOrNil(state.packages)) return [];

    if (!searchQuery || isEmptyOrNil(searchQuery)) {
      // sort selected classes at the top of list
      return state.packages;
    }

    return state.packages.filter((merchandise: MerchantPackage) => {
      return merchandise.name?.toLowerCase().includes(searchQuery.toLowerCase())
    })
  }, [state.packages, searchQuery])

  return (
    <Stack direction="column" gap={2}>
      <InputSearchBox
        placeholder={t('Package_Search')}
        onChangeQuery={(s) => setSearchQuery(s)}
        leftChild={<Search />}
      />

      <ServicePackagesList packages={filteredPackagesList} />
    </Stack>
  )
}

export default PackagesMenu;