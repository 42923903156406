import React from 'react';
import {
  Drawer,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  IconButton,
  useMediaQuery,
  DrawerProps,
} from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import { Else, If, Then } from 'react-if';
import classNames from 'classnames';

import { ColoredPaper } from './Papers';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface AppDrawerProps {
  children: React.ReactElement;
  anchor: Anchor;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  title?: React.ReactNode | string;
  drawerContainerClass?: string;
  drawerProps?: DrawerProps,
  hideCloseButton?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
      width: '100%',
      maxWidth: 350,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset !important',
        width: 'unset !important',
      },
      '& .drawer-body': {
        flex: '1 1 auto',
      },
    },
    drawer: {
      '& .toolbar': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .drawerTitle': {
        textAlign: 'center'
      },
      '& .content': {
        padding: '20px'
      },
    },
  })
);

const AppDrawer = (props: AppDrawerProps): React.ReactElement => {
  const {
    children,
    anchor,
    open,
    setOpen,
    onClose,
    title,
    drawerContainerClass,
    drawerProps,
    hideCloseButton = false
  } = props

  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const toggleDrawer = (openDrawer: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(openDrawer);

    if (!openDrawer && onClose) {
      onClose();
    }
  };

  return (
    <Drawer
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
      classes={{ paper: classNames([classes.paper, drawerContainerClass]) }}
      anchor={isMobile ? 'bottom' : anchor}
      open={open}
      onClose={toggleDrawer(false)}
      {...drawerProps}
    >
      <ColoredPaper className={classes.drawer} role="presentation">
        <If condition={!hideCloseButton}>
          <IconButton className="closeBtn" onClick={toggleDrawer(false)}>
            <If condition={isMobile}>
              <Then>
                <ExpandMore color="primary" fontSize="large" />
              </Then>
              <Else>
                <Close color="primary" fontSize="large" />
              </Else>
            </If>
          </IconButton>
        </If>
        <If condition={Boolean(title)}>
          <Typography variant="h6" component="div" className='drawerTitle'>
            {title}
          </Typography>
        </If>
        {children}
      </ColoredPaper>
    </Drawer>
  );
};

export default AppDrawer;
