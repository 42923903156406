import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";
import { Merchant } from "../../types/MerchantType";
import { randomID } from "../../utils";

const usernameConfig = {
  dictionaries: [adjectives, animals, colors],
  separator: "_",
  length: 3,
};

export const generateUserName = (config = usernameConfig): string =>
  uniqueNamesGenerator(config); // big_red_donkey

export const generateChannelName = (
  merchant: Merchant,
  username: string
): string => `${merchant.username}_${username}_${randomID()}`; // bitesizedtreats_big_red_donkey_

export const getAdminUserName = (merchant: Merchant): string =>
  `${merchant.username}_admin`;
