import React, { useContext, useEffect, useRef, useState } from 'react';
import { differenceInMinutes } from 'date-fns';
import useAxios from 'axios-hooks';
import { utcToZonedTime } from 'date-fns-tz';
import { filter, pipe, prop, propOr, reverse, sortBy, values } from 'ramda';
import { io, Socket } from 'socket.io-client';

import { AppType, ClientType, SocketEvent, Message, useInterval } from '../../bos_common/src';
import { UserContext } from '../../bos_common/src/context/UserContext';
import axios, { backendURL } from '../../bos_common/src/services/backendAxios';

import { AppContext } from '../AppContext';
import { Order, OrderStatus, OrderType } from '../../services/models';
import { getAuthHeaders, getClientTimezone, MappedOrders, orderMap } from '../../utils';
import { getOrdersMinTimestamp } from '../../utils/orderUtils';
import { CallboardContext } from './CallboardContext';

type CallboardContextProviderProps = {
  children: React.ReactElement | React.ReactElement[],
}

const isOrderFulfilledWithinLastFifteenMinutes = (order: Order) => {
  const clientZonedReadyTime = order.readyTime ? utcToZonedTime(order.readyTime, getClientTimezone()) : null;
  return clientZonedReadyTime && differenceInMinutes(new Date(), new Date(clientZonedReadyTime)) <= 15
}

const isOrderInQueue = (order: Order) => {
  const enqueueTime = order.enqueueTime ? utcToZonedTime(order.enqueueTime, getClientTimezone()) : null;
  return enqueueTime && differenceInMinutes(new Date(), enqueueTime) >= 0;
}

const isInprogressOrder = (o: Order) => {
  return (o.status === OrderStatus.PAID) && isOrderInQueue(o);
}

const isCompletedOrder = (o: Order) => {
  return (o.status === OrderStatus.FULFILLED) && isOrderFulfilledWithinLastFifteenMinutes(o);
}

const isCallboardOrder = (order: Order) => {
  if (!order) return false
  // all pickupType orders are valid as CallBoardOrder
  return propOr('', 'type', order) === OrderType.PICKUP
}

const orderRefreshInterval = (10 * 60 * 1000) // 10 minutes
const waitTimeInterval = (3 * 60 * 1000); // 3 minutes for wait time refreshing.

class CallBoardFetchingStatus {
  static isFetching: boolean = false;
  static lastFetched: string = getOrdersMinTimestamp();

  static setFetching(fetching: boolean) {
    CallBoardFetchingStatus.isFetching = fetching;
    if (fetching === true) {
      this.lastFetched = new Date().toUTCString();
    }
  }
}

type CallboardOrdersList = {
  inProgressOrdersList: Array<Order>,
  completedOrdersList: Array<Order>,
}



const CallboardContextProvider = (props: CallboardContextProviderProps) => {
  const { children } = props

  const { merchant } = useContext(AppContext);
  const { user, token } = useContext(UserContext);
  const [newOrder, setNewOrder] = useState<Order | null>()

  const SOCKET_CONN_OPTIONS = {
    reconnectionDelay: 5000,
    reconnectionDelayMax: 30000,
    auth: {
      token,
    },
  }

  const [callboardOrdersList, setCallboardOrdersList] = useState<CallboardOrdersList>({
    inProgressOrdersList: [],
    completedOrdersList: []
  })


  const socketRef = useRef<Socket | null>();

  // fetch the merchant wait time
  const [{ data: merchantWaitTime, loading: loadingWaitTime }, getMerchantWaitTime] = useAxios({
    url: `/merchants/${merchant?.id}/waitTime`,
    headers: getAuthHeaders(token)
  }, { manual: true });

  const fetchOrdersListForCallboard = async (orderStatus: OrderStatus[], minTimestampOffset?: string) => {

    try {
      let ordersList = [];
      const lastFetched = CallBoardFetchingStatus.lastFetched;
      CallBoardFetchingStatus.setFetching(true);
      const minTSOffset = minTimestampOffset ?? lastFetched;

      // fetch the merchant's orders for the past hour
      const response = await axios.get(`/merchants/orders`, {
        params: {
          minTimestampOffset: minTSOffset,
          merchantId: merchant?.id,
          orderStatus,
          filterKey: 'updatedAt'
        },
        headers: getAuthHeaders(token)
      })

      if (response.status === 200) {
        ordersList = propOr([], 'data', response).filter(isCallboardOrder)
      }
      CallBoardFetchingStatus.setFetching(false);
      return ordersList
    } catch (error) {
      CallBoardFetchingStatus.setFetching(false);
      return []
    }
  }

  const refreshCallboard = async (minTimestampOffset?: string) => {
    if (!CallBoardFetchingStatus.isFetching) {
      const ordersList = await fetchOrdersListForCallboard([OrderStatus.PAID, OrderStatus.FULFILLED], minTimestampOffset);
      partialUpdateOrders(ordersList);
    }
  }

  const partialUpdateOrders = (newOrders: Order[]) => {
    const ordersMap: MappedOrders = {
      ...orderMap([...callboardOrdersList.completedOrdersList, ...callboardOrdersList.inProgressOrdersList]),
      ...orderMap(newOrders),
    }
    setCallboardOrdersList({
      inProgressOrdersList: pipe(values, filter(isInprogressOrder), sortBy(prop('enqueueTime')))(ordersMap),
      completedOrdersList: pipe(values, filter(isCompletedOrder), sortBy(prop('readyTime')), reverse)(ordersMap),
    })
  }

  const setupSocketEvents = () => {
    if (user && merchant) {
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
      }

      const socket = io(backendURL, {
        ...SOCKET_CONN_OPTIONS,
        query: {
          merchantId: merchant?.id,
          operatorId: user.id,
          appType: AppType.MERCHANT,
          clientType: ClientType.Web,
        }
      });
      const topic = merchant.id;

      socket.on(topic, (data: Message) => {
        if (merchant.id === data.merchantId) {
          const newOrder = data.payload?.order as Order;
          if (!newOrder) return

          if ([SocketEvent.CALLBOARD_UPDATED, SocketEvent.ORDER_UPDATED, SocketEvent.NEW_ORDER].includes(data.event)) {
            setNewOrder(newOrder)
          }
        }
      });
      socketRef.current = socket;
    }
  };

  // first fetch
  useEffect(() => {
    if (merchant && user) {
      const minTimestampOffset = new Date(Date.now() - 120 * 60 * 1000).toUTCString();
      refreshCallboard(minTimestampOffset);
      getMerchantWaitTime();
      setupSocketEvents();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
      }
    }
  }, [merchant?.id, user?.id]);

  useInterval(() => {
    const minTimestampOffset = new Date(Date.now() - 120 * 60 * 1000).toUTCString();
    refreshCallboard(minTimestampOffset);
  }, orderRefreshInterval)

  useInterval(() => {
    getMerchantWaitTime();
  }, waitTimeInterval)

  useEffect(() => {
    if (!newOrder) return;
    refreshCallboard();
    getMerchantWaitTime();
  }, [newOrder])

  const callboardContextValue = {
    completedOrdersList: callboardOrdersList.completedOrdersList,
    inProgressOrdersList: callboardOrdersList.inProgressOrdersList,
    getMerchantWaitTime,
    merchantWaitTime,
    loading: CallBoardFetchingStatus.isFetching || loadingWaitTime
  }

  return (
    <CallboardContext.Provider value={callboardContextValue}>
      {children}
    </CallboardContext.Provider>
  )
}

function areEqual() { return true }

export default React.memo(CallboardContextProvider, areEqual);