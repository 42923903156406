import React, { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Rating,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Else, If, Then } from "react-if";
import { pathOr, prop } from "ramda";
import { MoreVert, Star as StarIcon } from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";

import { Review } from "../types/ReviewType";
import { formatDate, isEmptyOrNil } from "../utils";
import { Merchant } from "../types/MerchantType";
import { StyledRedChip } from "./StyledComponents";
import { getMarketPlaceMerchantURL, getMarketPlaceURL } from "../services/urls";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "100%",

      "& .reviewCardSection": {
        padding: theme.spacing(1),

        "&.userSection": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          "& .userAvatar": {
            height: theme.spacing(5),
            width: theme.spacing(5),
          },
          "& .merchandise-link": {
            fontWeight: 'bold',
            color: 'black',
            textDecoration: 'none'
          },
        },
        "& .ratingBox": {
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",

          "&.boxJumbo": {
            gridGap: theme.spacing(1),
            marginBottom: theme.spacing(1),
            "& .MuiSvgIcon-root": {
              fontSize: "1rem"
            },
          },

          "& .ratingItem": {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0.2),
            marginLeft: theme.spacing(0.6),

            "& .MuiSvgIcon-root": {
              fontSize: "1rem"
            },
          },
        },
      },

      "& .userDetails": {
        padding: theme.spacing(1),
      },
      "& .reviewDate": {
        color: theme.palette.secondary.light,
      },
    },
    dropdownLink: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  })
);

interface UserReviewCardProps {
  review: Review;
  merchant?: Merchant | null;
  showMerchandiseTitle?: boolean;
  showMerchandiseDetails?: boolean;
  isMerchant?: boolean;
  redirectUrl?: string
}

const UserReviewCard = (props: UserReviewCardProps) => {
  const classes = useStyles();
  const location = useLocation();

  const { isMerchant = false, review, showMerchandiseTitle = false, showMerchandiseDetails = false, merchant, redirectUrl } = props;
  const getRatingValue = (key: string) => pathOr(0, ["rating", key], review);

  const [cardDropdownAnchor, setCardDropdownAnchor] = React.useState<null | HTMLElement>(null);
  const isCardDropdownOpened = Boolean(cardDropdownAnchor);

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCardDropdownAnchor(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setCardDropdownAnchor(null);
  };

  const toolTipLabel = (
    <Tooltip title="One Market has verified that the person writing the review was an actual customer at this store...this is authentic, genuine, and real feedback">
      <Typography variant="subtitle2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        VERIFIED
      </Typography>
    </Tooltip>
  );

  const RenderMerchandiseName = () => {
    const { merchandise } = review

    if (isEmptyOrNil(merchandise)) return <b>{'a merchandise (no longer available)'}</b>
    if (isEmptyOrNil(redirectUrl)) return <b>{merchandise.name}</b>

    return (
      <Link
        to={{pathname: redirectUrl!, state: {from: location.pathname}}}
        className='merchandise-link'
      >
        {merchandise.name}
      </Link>
    )
  }

  return (
    <Card className={`${classes.root} reviewCard`}>
      <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
        <If condition={showMerchandiseDetails}>
          <Then>
            <Stack width="100%">
              <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" width="100%">
                <Stack direction="row" gap="8px" className="reviewCardSection userSection">
                  <Avatar
                    className="userAvatar"
                    src={pathOr("", ["user", "picture"], review)}
                  />
                  <Typography variant="subtitle2">
                    <b>{pathOr("A customer", ["user", "displayName"], review)}</b> reviewed&nbsp;
                    <RenderMerchandiseName />
                    <div className="reviewDate">{formatDate(review.createdAt)}</div>
                  </Typography>
                </Stack>

                {!isEmptyOrNil(merchant) && isMerchant && (
                  <div>
                    <IconButton
                      id="dropdownButton"
                      aria-controls={isCardDropdownOpened ? 'dropdown-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isCardDropdownOpened ? 'true' : undefined}
                      onClick={handleDropdownClick}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={cardDropdownAnchor}
                      id="dropdown-menu"
                      open={isCardDropdownOpened}
                      onClose={handleDropdownClose}
                      MenuListProps={{
                        'aria-labelledby': 'dropdownButton',
                      }}
                    >
                      <MenuItem onClick={handleDropdownClose}>
                        <a
                          className={classes.dropdownLink}
                          rel="noreferrer"
                          target="_blank"
                          href={`${getMarketPlaceMerchantURL(merchant!)}/item/${pathOr("", ["merchandise", "id"], review)}`}
                        >
                          View the item
                        </a>
                      </MenuItem>
                      <MenuItem onClick={handleDropdownClose}>
                        <a
                          className={classes.dropdownLink}
                          rel="noreferrer"
                          target="_blank"
                          href={`${getMarketPlaceURL()}/order/${prop("orderId", review)}`}
                        >
                          View the order
                        </a>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </Box>
            </Stack>
          </Then>

          <Else>
            <Box className="reviewCardSection userSection">
              <Avatar
                className="userAvatar"
                src={pathOr("", ["user", "picture"], review)}
              />
              <Box sx={{ ml: 1 }}>
                <Typography variant="subtitle2">
                  {pathOr("", ["user", "displayName"], review)}
                  <div className="reviewDate">
                    {formatDate(review.createdAt)}
                  </div>
                </Typography>
              </Box>
            </Box>
          </Else>
        </If>

        <If condition={showMerchandiseTitle && !isEmptyOrNil(review?.merchandise?.name)}>
          <Typography variant="subtitle2" noWrap>
            {pathOr("", ['merchandise', 'name'], review)}
          </Typography>
        </If>
      </Box>
      <Divider />
      <Box className="reviewCardSection ratings">
        <Box className="ratingBox boxJumbo" justifyContent="flex-start">
          <Typography variant="subtitle2">Overall rating</Typography>
          <Rating
            name="size-medium"
            icon={<StarIcon color="primary" />}
            precision={0.5}
            value={getRatingValue("overall")}
            readOnly
          />

          <StyledRedChip label={toolTipLabel} color="primary" size="small" />
        </Box>

        <Stack direction="row" flexWrap="wrap" sx={{ justifyContent: "space-between", gap: "2px" }}>
          <Box className="ratingBox">
            <Typography variant="subtitle2">Taste</Typography>
            <div className="ratingItem">
              <StarIcon color="primary" />
              <Typography variant="subtitle2" className="ratingNumber">
                {getRatingValue("taste").toFixed(2)}
              </Typography>
            </div>
          </Box>

          <Box className="ratingBox">
            <Typography variant="subtitle2">Portion</Typography>
            <div className="ratingItem">
              <StarIcon color="primary" />
              <Typography variant="subtitle2" className="ratingNumber">
                {getRatingValue("portion").toFixed(2)}
              </Typography>
            </div>
          </Box>

          <Box className="ratingBox">
            <Typography variant="subtitle2">Value</Typography>
            <div className="ratingItem">
              <StarIcon color="primary" />
              <Typography variant="subtitle2" className="ratingNumber">
                {getRatingValue("value").toFixed(2)}
              </Typography>
            </div>
          </Box>

        </Stack>
      </Box>
      <Divider />
      <Box className="reviewCardSection">
        <Typography variant="subtitle2">{review.comment}</Typography>
      </Box>
    </Card>
  );
};

export default UserReviewCard;