import React, { useContext, useMemo } from 'react';
import { pathOr } from 'ramda';

import { AppContext } from '../../../../context/AppContext';
import { LineItem, MerchandiseApiResponseType, Order } from '../../../../services/models';
import { expandOrderLineItems, sortLineItemsByCategory } from '../../../../utils/orderUtils';
import OrderItemCard from '../../OrderItemCard';
import { OrderItemProps } from '../../OrderCard';

type LineItemActionsProps = {
  order: Order,
  orderItemProps: OrderItemProps,
  expandedLineItems?: boolean
}
const LineItemActions = (props: LineItemActionsProps) => {
  const { order, orderItemProps, expandedLineItems = false } = props;

  const appContext = useContext(AppContext);
  const merchantData: MerchandiseApiResponseType = pathOr(
    {},
    ['merchant', 'merchantData'],
    appContext
  );

  const updatedOrder = useMemo(() => expandOrderLineItems(order, expandedLineItems), [order])
  const lineItems = sortLineItemsByCategory(updatedOrder, merchantData)

  return (
    <div className='line-item-actions-container'>
      {
        lineItems.map((item: LineItem, index) => (
          <div className='line-item-action' key={`${item.id}-${item.customizationSignature}-${index}`}>
            <OrderItemCard
              item={item}
              index={index}
              order={order}
              {...orderItemProps}
            />
          </div>
        ))
      }
    </div>
  )

}

export default LineItemActions;