import React, { useContext, useCallback, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  Theme,
  makeStyles,
  createStyles,
  Fab,
  Switch,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import { If, Then, Else } from 'react-if';
import { useForm } from 'react-hook-form';
import { defaultTo, path, pathOr } from 'ramda';

import { ColoredPaper, FullscreenPaper, getAuthHeaders } from '../bos_common/src';
import { UserContext } from '../bos_common/src/context/UserContext';
import axios from '../bos_common/src/services/backendAxios';

import { Merchandise } from '../services/models';
import { isMerchandiseAvailable } from '../utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '40vw',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: 'auto',

      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '50vw',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        maxWidth: "100%",

        '& .content-container': {
          marginBottom: "10px",
        },

        '& .footer-container': {
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      },

      '& .drawer-header': {
        paddingLeft: '0'
      },

      '& .title-container': {
        marginBottom: theme.spacing(2),

        '& .merchandise-title': {
          textAlign: 'center'
        }
      },

      '& .content-container': {
        '& .MuiFormControl-root': {
          marginTop: theme.spacing(2),
          height: '78px'
        },
      },

      '& .footer-container': {
        paddingLeft: '20px',
        paddingRight: '20px',
        position: "absolute",
        bottom: '20px',

        '& .save-action': {
          width: "100%"
        }
      }
    },
    formContainer: {
      [theme.breakpoints.down('sm')]: {
        height: '80vh',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        paddingTop: '10px',
      }
    },
    availabilitySwitch: {
      borderRadius: '8px',
      border: '1px solid #bcc0c4',
      padding: '8px 0px',
      position: 'relative',
      marginTop: theme.spacing(2),

      '& .switch-label': {
        fontSize: "0.8rem",
        lineHeight: '1.4375em',
        fontFamily: '"Manrope","Helvetica","Arial",sans-serif',
        color: 'rgba(0, 0, 0, 0.6)',
        backgroundColor: "#f5faff",
        padding: "0 5px",

        position: 'absolute',
        top: '-8px',
        left: '10px',

      }
    }
  })
);

const getDefaultValues = (merchandise: Merchandise) => ({
  inventory: {
    dailyMaxStock: pathOr(undefined, ['inventory', 'dailyMaxStock'], merchandise),
    dailyCurrentStock: pathOr(undefined, ['inventory', 'dailyCurrentStock'], merchandise),
  }
})
interface FormValues extends Merchandise {
  [key: string]: any
}
type MerchandiseInventoryFormProps = {
  merchandise: Merchandise,
  onClose: () => void
  onSuccessfulUpdate: () => void
}
const MerchandiseInventoryForm = ({ merchandise, onClose, onSuccessfulUpdate }: MerchandiseInventoryFormProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { token } = useContext(UserContext)
  const { t } = useTranslation();

  const [_isMerchandiseAvailable, toggleMerchandiseAvailability] = useState<boolean>(isMerchandiseAvailable(merchandise));

  const {
    register, handleSubmit, formState, watch, reset, setValue, trigger
  } = useForm<FormValues>({ mode: "onBlur", defaultValues: getDefaultValues(merchandise) || {} })

  const { errors, isSubmitting, isDirty, isValid } = formState

  const registerField = (name: string, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);
    const defaultValue = defaultTo(null, path(name.split('.'), merchandise))

    return {
      ...inputProps, inputRef, defaultValue, onChange: (e: any) => {
        inputProps.onChange(e)
        options.afterOnChange && options.afterOnChange(e)
      }
    }
  }

  const updateMerchandiseSettings = useCallback(
    (formData: FormValues) => {
      const { id } = merchandise
      const updatedMerchandise = {
        ...formData,
        inventory: {
          dailyMaxStock: pathOr(null, ['inventory', 'dailyMaxStock'], formData),
          dailyCurrentStock: pathOr(null, ['inventory', 'dailyCurrentStock'], formData),
        }
      }

      axios
        .put(
          `/merchants/merchandises/${id}`,
          updatedMerchandise,
          { headers: getAuthHeaders(token) },
        )
        .then(() => { onSuccessfulUpdate() })
        .catch((error) => console.error(error))
    },
    [merchandise]
  )

  const onCloseForm = () => {
    reset();
    onClose();
  }

  const dailyStockValue = watch(`inventory.dailyCurrentStock`)
  const dailyMaxValue = watch(`inventory.dailyMaxStock`)

  const onDailyCountUpdate = () => {
    const isAvailable = Number.isNaN(dailyStockValue) ? true : dailyStockValue > 0
    toggleMerchandiseAvailability(isAvailable)
    trigger()
  }

  useEffect(() => {
    onDailyCountUpdate()
  }, [dailyStockValue])

  if (!merchandise) return <p>Unable to load merchandise</p>

  const onDailyMaxUpdate = () => {
    if (
      (dailyStockValue > dailyMaxValue) || (!dailyStockValue && dailyMaxValue)
    ) {
      setValue('inventory.dailyCurrentStock', dailyMaxValue)
    }
  }


  const handleAvailabilitySwitchChange = (e: any) => {
    const newValue = !_isMerchandiseAvailable
    toggleMerchandiseAvailability(!_isMerchandiseAvailable)
    setValue('inventory.dailyCurrentStock', newValue ? dailyMaxValue ?? null : 0 as never)
    trigger('inventory.dailyCurrentStock')
  }

  return (
    <FullscreenPaper>
      <ColoredPaper className={classes.formContainer}>
        <form onSubmit={handleSubmit(updateMerchandiseSettings)}>
          {/* Header */}
          <Grid container item xs={12} alignItems='center' justifyContent='space-between' className='drawer-header'>
            <Grid item xs={1}>
              <If condition={isMobile}>
                <Then>
                  <IconButton onClick={onCloseForm} color="primary">
                    <ExpandMore fontSize='large' />
                  </IconButton>
                </Then>
                <Else>
                  <IconButton onClick={onCloseForm} color="primary">
                    <Close fontSize='large' />
                  </IconButton>
                </Else>
              </If>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around" spacing={1} className={classes.root}>
            {/* Merchandise Title */}
            <Grid container item xs={12} className='title-container'>
              <Grid item xs={12}>
                <Typography variant='h5' component="h5" className='merchandise-title'>
                  {merchandise.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div" className='merchandise-title'>
                  {t("Settings")}
                </Typography>
              </Grid>
            </Grid>

            {/* Merchandise Settings */}
            <Grid container item xs={12} className='content-container' spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("Inventory_List_DailyMax")}
                  type='number'
                  disabled={isSubmitting}
                  error={!!errors.inventory?.dailyMaxStock}
                  helperText={errors.inventory?.dailyMaxStock?.message}
                  inputProps={{
                    step: '1',
                    min: 0,
                    onBlur: onDailyMaxUpdate
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('inventory.dailyMaxStock', {
                    validate: {
                      gteZero: (value: number) => value && (value > 0 || t("Inventory_Settings_DailyMax_ValidateZero"))
                    },
                    valueAsNumber: true
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("Inventory_Settings_CurrentStock")}
                  type='number'
                  disabled={isSubmitting}
                  error={!!errors.inventory?.dailyCurrentStock}
                  helperText={errors.inventory?.dailyCurrentStock?.message}
                  inputProps={{
                    step: '1',
                    min: 0,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('inventory.dailyCurrentStock', {
                    validate: {
                      ltDailyMax: (value: number) => value && dailyMaxValue && (value <= dailyMaxValue || t("Inventory_Settings_CurrentStock_ValidateMax")),
                      gteZero: (value: number) => value && (value >= 0 || t("Inventory_Settings_CurrentStock_ValidateZero"))
                    },
                    valueAsNumber: true
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className={classes.availabilitySwitch}>
                  <span className='switch-label'>{t("Inventory_Availability")}</span>
                  <Switch
                    checked={_isMerchandiseAvailable}
                    onChange={handleAvailabilitySwitchChange}
                    color="primary"
                    name='merchandise-availability-switch'
                    inputProps={{ 'aria-label': 'in-stock' }}
                    id='merchandise-availability-switch'
                  />
                  <label
                    htmlFor='merchandise-availability-switch'
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    {_isMerchandiseAvailable ? t("InStock") : t("OutOfStock")}
                  </label>
                </div>
              </Grid>
            </Grid>

            {/* Merchandise Footer */}
            <Grid container item xs={12} className='footer-container' alignItems="center" justifyContent="space-evenly">
              <Grid item xs={12}>
                <Fab
                  className='save-action'
                  variant="extended"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >

                  {
                    isSubmitting
                      ? (<><CircularProgress size={25} />&nbsp; {t("Updating")}</>)
                      : t('Save')
                  }
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ColoredPaper>
    </FullscreenPaper>
  )
}

export default MerchandiseInventoryForm