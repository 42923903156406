import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// src
import { ColoredPaper, getUTCDate } from '../../../bos_common/src';
import SimpleLoader from '../../../bos_common/src/components/SimpleLoader';
import { StyledTab, StyledTabPanel, StyledTabs } from '../../../components/StyledTab';
import SalesReport from './SalesReport';
import SalesSummary from './SalesSummary';
import SalesTransaction from './SalesTransaction';

import { MerchantSalesStats } from '../../../bos_common/src/types/MerchantSalesStatsType';
import { MerchantStatsPeriod } from '../../../components/Stats/types';
import { AppContext } from '../../../context/AppContext';
import { MerchantStatsContext } from '../../../context/MerchantStats/MerchantStatsContext';
import { MerchantAggregatedSalesStats } from '../../../services/models';
import { a11yTabProps } from '../../../utils';
import { extractSalesTabData, formatMerchantSalesStats } from '../../../utils/merchantStatsUtils';
import useStatsApiService from '../statsApiService';
import SearchOrders from './SearchOrders';
import { Box } from '@material-ui/core';

const getOneWeekAgoCurrentTimePoint = (period: MerchantStatsPeriod) => {
  const currentTimePoint = getUTCDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  // const minutesPassed = currentTimePoint.getMinutes();
  // const hoursPassed = currentTimePoint.getHours();
  // currentTimePoint.setMinutes(0, 0, 0);
  // if (period === MerchantStatsPeriod.Week) {
  //   currentTimePoint.setHours(0);
  // }
  return currentTimePoint;
}

const MerchantSales = () => {
  const [currentTab, setCurrentTab] = useState<string>('summary')
  const { t } = useTranslation()

  const { merchant } = useContext(AppContext);
  const { statsTimePeriod } = useContext(MerchantStatsContext);

  const {
    isLoading,
    currentStatsData,
    comparisonStatsData: comparisonStatsDataOrigin
  } = useStatsApiService('/merchants/stats')

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue);
  };

  // Don't use comparison when period is custom
  const comparisonStatsData = statsTimePeriod === MerchantStatsPeriod.Custom ? undefined : comparisonStatsDataOrigin;
  const oneWeekAgoTimePoint = getOneWeekAgoCurrentTimePoint(statsTimePeriod);
  const currentSalesData: MerchantAggregatedSalesStats = useMemo(() => formatMerchantSalesStats(currentStatsData?.stats as Array<MerchantSalesStats>), [currentStatsData?.stats]);
  const comparisonSalesData: MerchantAggregatedSalesStats = useMemo(() => formatMerchantSalesStats(comparisonStatsData?.stats as Array<MerchantSalesStats>, oneWeekAgoTimePoint), [comparisonStatsData?.stats, oneWeekAgoTimePoint.getTime()]);

  const currentSales = useMemo(() => extractSalesTabData(currentSalesData, merchant), [currentSalesData, merchant]);
  const comparisonSales = useMemo(() => extractSalesTabData(comparisonSalesData, merchant), [comparisonSalesData, merchant]);

  return (
    <ColoredPaper elevation={1}>
      <SimpleLoader loading={isLoading} />
      <Box sx={{ maxWidth: '95vw' }}>
        <StyledTabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="date range picker tabs"
          variant="scrollable"
        >
          <StyledTab label={t("Summary")} value='summary' {...a11yTabProps('summary')} />
          <StyledTab label={t("MerchantService_Reports")} value='report' {...a11yTabProps('report')} />
          <StyledTab label={t("Transactions")} value='transaction' {...a11yTabProps('transaction')} />
          <StyledTab label={t("MerchantSales_SearchOrders")} value='search-orders' {...a11yTabProps('search-orders')} />
        </StyledTabs>

        <StyledTabPanel value={currentTab} tabKey="summary" >
          <SalesSummary
            currentSales={currentSales}
            currentSalesData={currentSalesData}
            comparisonSales={comparisonSales}
            comparisonSalesData={comparisonSalesData}
            merchandiseData={merchant?.merchantData}
            currentStatsApiData={currentStatsData}
          />
        </StyledTabPanel>
        <StyledTabPanel value={currentTab} tabKey="report">
          <SalesReport
            currentSalesData={currentSalesData}
          />
        </StyledTabPanel>
        <StyledTabPanel value={currentTab} tabKey="transaction">
          <SalesTransaction />
        </StyledTabPanel>
        <StyledTabPanel value={currentTab} tabKey="search-orders">
          <SearchOrders />
        </StyledTabPanel>
      </Box>
    </ColoredPaper>
  )
}


export default MerchantSales