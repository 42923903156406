import { createAsyncThunk } from "@reduxjs/toolkit";
import { Merchant } from "bos_common/src/types/MerchantType";
import { getPackages } from "../../../apiServices/packagesService";
import { getAuthHeaders } from "../../../utils";
import { RootState } from "../../store";
import { getToken } from "../auth/authSelector";

export const fetchPackages = createAsyncThunk(
  'merchandise/fetchPackages',
  async (merchant: Merchant|undefined, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const token: string = getToken(state);

    if (!merchant?.id) {
      throw new Error('merchant is null');
    }

    const response = await getPackages(merchant.id, { headers: getAuthHeaders(token) });
    return response.data;
  }
);
