import React, { useCallback, useEffect, useState } from 'react';
import { Slide, Stack, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { If, Then } from 'react-if';
import classNames from 'classnames';
import { pathOr, splitEvery } from 'ramda';

import { useInterval } from '../../bos_common/src';
import { displayOrderShortCode } from '../../bos_common/src/services/orderUtils';

import { Order } from '../../services/models';
import { isEmptyOrNil } from '../../utils';

interface RenderPaginationDotsProps {
  totalPages: number,
  currentPage: number
}

const RenderPaginationDots = (props: RenderPaginationDotsProps) => {
  const { totalPages, currentPage } = props
  const paginationDots = []

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  for (let i = 0; i < totalPages; i++) {
    const paginationDotClasses = classNames(['pagination-dot'], { 'active': i === currentPage })

    paginationDots.push(
      <span key={`pagination_dot_${i}`} className={paginationDotClasses} />
    )
  }

  return (
    <Stack className='pagination-container' direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <If condition={!isMobile}>
        <Typography className='pagination-label' variant='body1' fontWeight='bold'>Page {currentPage + 1} of {totalPages}</Typography>
      </If>
      <div className='pagination-dots-container'>
        {paginationDots}
      </div>
    </Stack>
  )
}

interface CallboardOrderProps {
  orders: Array<Order>,
  itemsPerPage?: number
}

const CallboardOrdersList = (props: CallboardOrderProps) => {
  const { orders, itemsPerPage = 20 } = props;

  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0); // 0 based index
  const [paginatedList, setPaginatedList] = useState<Array<Array<Order>>>([]);

  useEffect(() => {
    if (!isEmptyOrNil(orders)) {
      const _paginatedList = splitEvery(itemsPerPage, orders)
      const totalPages = Math.ceil(orders.length / itemsPerPage)

      setPaginatedList(_paginatedList)
      setTotalPages(totalPages);
    } else {
      setPaginatedList([])
    }
  }, [orders])

  const paginationCallback = useCallback(() => {
    const nextPage = currentPage >= totalPages - 1 ? 0 : currentPage + 1
    setCurrentPage(nextPage)
  }, [totalPages]);

  useInterval(paginationCallback, 6000);

  const RenderOrderNumbers = () => {
    const list = pathOr([], [currentPage], paginatedList)
    if (isEmptyOrNil(list)) return null;

    return (
      <>
        <Slide direction="left" in unmountOnExit>
          <div className='order-numbers-container'>
            {list.map((order: Order) => (
              <span className='order-number-chip' key={order.id}>
                {displayOrderShortCode(order)}
              </span>
            ))}
          </div>
        </Slide>
        <RenderPaginationDots totalPages={totalPages} currentPage={currentPage} />
      </>
    )
  }
  return (
    <div className='content-container'>
      <If condition={!isEmptyOrNil(paginatedList)}>
        <Then>
          <RenderOrderNumbers />
        </Then>
      </If>
    </div>
  )
}

function areEqual(prevProps: CallboardOrderProps, nextProps: CallboardOrderProps) {
  return prevProps.orders === nextProps.orders
}

export default React.memo(CallboardOrdersList, areEqual);