import React, { useContext } from 'react'
import {
  Card,
  CardContent,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  useMediaQuery,
  Switch,
  Typography
} from '@material-ui/core';
import { If, Then, Else } from 'react-if';
import { ascend, sort, defaultTo, prop } from 'ramda';

import axios from '../../bos_common/src/services/backendAxios';
import { getAuthHeaders } from '../../bos_common/src';
import { UserContext } from '../../bos_common/src/context/UserContext';

import { MerchandiseModifier } from '../../services/models';
import { isEmptyOrNil } from '../../utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inventoryTable: {
      padding: theme.spacing(2),
      '& .category-info': {
        marginBottom: theme.spacing(2)
      },
      '& .inventory-table': {
        '& thead > tr > th': {
          backgroundColor: 'transparent',
          color: theme.palette.grey[400],
          textTransform: 'uppercase',

          '&:first-child': {
            paddingLeft: 0
          }
        },

        '& tbody tr': {
          cursor: 'pointer'
        },
        '& tbody > tr > td': {
          '&.row-operation': {
            '& p': {
              marginTop: `-${theme.spacing(1)}`,
              marginRight: theme.spacing(1),
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.6rem'
              }
            }
          },
          '&:first-child': {
            paddingLeft: 0
          }
        },
      },
      '& .modifiers-title': {
        paddingBottom: theme.spacing(2)
      }
    },
    merchandiseActionDrawer: {
      [theme.breakpoints.down('sm')]: {
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }
      }
    }
  })
);

type MerchandiseModifierInventoryProps = {
  modifiers?: Array<MerchandiseModifier>,
  refetchMerchandises: () => void
}
const MerchandiseModifierInventory = (props: MerchandiseModifierInventoryProps) => {
  const { token } = useContext(UserContext)
  const { t } = useTranslation();

  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const { modifiers = [], refetchMerchandises } = props
  const labels = {
    name: t("Inventory_List_Name"),
    description: t("Description"),
    availability: isMobile ? t("Inventory_List_Aval") : t("Inventory_Availability"),
    inStock: t('InStock'),
    outOfStock: t('OutOfStock'),
  }

  const toggleItemAvailability = (modifier: Pick<MerchandiseModifier, 'id' | 'available'>) => {
    const { id } = modifier

    axios
      .put(
        `/merchants/modifiers/${id}`,
        { available: modifier.available },
        { headers: getAuthHeaders(token) },
      )
      .catch((error) => console.error(error))
      .finally(() => refetchMerchandises());
  }

  const sortedModifiers = sort(ascend(prop('name')), defaultTo([], modifiers))

  return (
    <CardContent>
      <Card className={classes.inventoryTable}>
        {/* Id set to category--10 to coordinate with the menu item pill id for the scroll to section */}
        <div className="modifiers-title" id="category--10">
          <Typography variant="body1" component="div">{t("Modifiers")}</Typography>
          <Typography variant="subtitle2" component="p" color="textSecondary">{t("Modifiers_Desc")}</Typography>
        </div>

        <If condition={isEmptyOrNil(sortedModifiers)}>
          <Then>
            <Typography variant="subtitle2" component="p" color="textSecondary">
              {t("Inventory_NoData")}
            </Typography>
          </Then>
          <Else>
            <TableContainer className="inventory-table">
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{labels.name}</TableCell>
                    <If condition={!isMobile}>
                      <TableCell>{labels.description}</TableCell>
                    </If>
                    <TableCell align="right">{labels.availability}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedModifiers?.map((modifier: MerchandiseModifier) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={modifier.id}
                    >
                      <TableCell>{modifier.name}</TableCell>

                      {/* hide description for mobile */}
                      <If condition={!isMobile}>
                        <TableCell>{!isEmptyOrNil(modifier.description) ? modifier.description : t("Inventory_Modifiers_NoDescription")}</TableCell>
                      </If>

                      <TableCell align="right" className="row-operation">
                        <Switch
                          checked={modifier.available}
                          onClick={(e: any) => e.stopPropagation()}
                          onChange={(e) => toggleItemAvailability({ id: modifier.id, available: e.target.checked })}
                          color="primary"
                          name="in-stock"
                          inputProps={{ 'aria-label': 'in-stock' }}
                        />
                        <Typography variant="subtitle2" component="p" color="textSecondary">
                          {modifier.available ? labels.inStock : labels.outOfStock}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Else>
        </If>
      </Card>
    </CardContent>
  )
}

export default MerchandiseModifierInventory