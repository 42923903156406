import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { defaultTo } from "ramda";
import { DASHBOARD_SETTINGS } from "../../../constants/localStorageKeys";

export interface DashboardSettingsState { quickSmsAction: boolean }
type DashboardSettingsSliceCaseReducer = SliceCaseReducers<DashboardSettingsState>;

const initialState = {
  quickSmsAction: false
}

export const dashboardSettingsSlice = createSlice<
  DashboardSettingsState,
  DashboardSettingsSliceCaseReducer
>({
  name: 'dashboardSettings',
  initialState,
  reducers: {
    initializeDashboardSettings: (state, action) => {
      const dashboardSettings: DashboardSettingsState = defaultTo(initialState, JSON.parse(`${localStorage.getItem(DASHBOARD_SETTINGS)}`));

      return ({
        ...state,
        ...dashboardSettings
      })
    },
    updateDashboardSettings: (state, action: PayloadAction<DashboardSettingsState>) => {
      const { payload } = action;

      localStorage.setItem(DASHBOARD_SETTINGS, JSON.stringify(payload))

      return ({
        ...state,
        ...payload
      })
    }
  }
})

export const { updateDashboardSettings, initializeDashboardSettings } = dashboardSettingsSlice.actions

export default dashboardSettingsSlice.reducer

