import { AppType } from "./AppType";
import { Base } from "./Base";
import { BaseIncrement } from "./BaseIncrement";
import { DeviceType } from "./DeviceType";
import {
  MerchandiseModifier,
  MerchandiseModifiersType,
} from "./MerchandiseType";
import { Merchant } from "./MerchantType";
import { OrderSubscriber } from "./OrderSubscriber";
import { Review } from "./ReviewType";
import { User } from "./UserTypes";

/**
 * The states machine of order status
 *
 *    PAID => FULFILLED
 *
 *    for unclaimed merchants' orders. Set by the operator
 *    PAID => CONFIRMED
 *
 *                /  PAID => FULFILLED
 *    OPEN_CHECK ==  VOIDED
 *                \  PAID_EXTERNALLY
 *
 *    REFUNDED, PREPARING, PENDING are deprecated
 */

export enum OrderStatus {
  PENDING = "pending",
  OPEN_CHECK = "open-check",
  PAID = "paid",
  PREPARING = "preparing",
  FULFILLED = "fulfiled",
  CONFIRMED = "confirmed",
  REFUNDED = "refunded",
  VOIDED = "voided",
  PAID_EXTERNALLY = "paid-externally",
  PREPAID_VOUCHER = "prepaid-voucher", // Prepaid deals
}

export enum OrderType {
  DINEIN = "dine-in",
  PICKUP = "pickup",
  RESERVATION = "reservation",
}

export enum OrderPickupType {
  TO_GO = "to-go",
  EAT_HERE = "eat-here",
}

/**
 * customization: a map from modifiers to their respective prices, note that the price could be different from their current values.
 *                Thus, the order needs to capture their state in time
 * price: the total price of this item, including prices from modifiers
 */
export interface LineItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  note: string | undefined;
  customization: MerchandiseModifiersType;
  customizationDisplayOrder: Array<string>;
  appliedModifiers?: MerchandiseModifier[];
  refunded: boolean;
  categoryId?: string;
  customizationSignature: number | undefined;
  pointsRedeemed?: number;
  quantityRedeemed?: number;
  remainInventory: number | undefined;
  couponRedeemedAmount?: number;
}

export interface OrderCancellationInfo extends BaseIncrement {
  user: User;
  order: Order;
  lineItems: LineItem[] | null;
  itemRefundAmount: number | null;
  customRefundAmount: number | null;
  taxRefundAmount: number;
  tipRefundAmount: number;
}

export interface PointsRedemptionInfo {
  pointsRedeemed: number;
  amountRedeemed: number;
}

export interface CouponRedemptionInfo {
  couponId: number;
  amountRedeemed: number;
  couponCode: string;
}

export interface VoucherRedemptionInfo {
  voucherId: number;
  amountRedeemed: number;
  lineItems: Partial<LineItem>[];
}

export interface BeforeRefundInfo {
  amount: number;
  tip: number;
  tax: number;
}

export interface SourceInfo {
  device: DeviceType;
  app: AppType;
  sessionId?: string;
}

export enum SkipPaymentReason {
  ORDER_VOIDED = OrderStatus.VOIDED,
  PAID_EXTERNALLY = OrderStatus.PAID_EXTERNALLY,
}

export interface OrderVoidOrSkipPaymentInfo extends Base {
  orderId: string;
  user?: User;
  reason: SkipPaymentReason;
  priorStatus: OrderStatus;
  paymentMethod: string;
}

export interface AffiliateRedemptionInfo {
  affiliateCode: string;
}

export interface PreAuthInfo {
  amount: number;
  currency: string;
  connectedAccountCustomerId: string;
  connectedAccountPaymentMethodId: string;
  paymentIntentId: string;
  status: PreAuthStatus
}

export enum PreAuthStatus {
  TO_BE_AUTHED = 'TO_BE_AUTHED',
  PRE_AUTHED = 'PRE-AUTHED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED_NEW_INTENT_CREATED = 'CANCELLED-NEW-INTENT-CREATED',
  CANCELLED = 'CANCELLED',
}

export interface Order extends Base {
  id: string;
  orderNo: number | null;
  userId: string | null;
  userDisplayName: string | null;
  merchantId: string;
  merchant?: Partial<Merchant>;
  description: string;
  amount: number;
  tax: number;
  tip: number;
  currency: string;
  paymentId: string | null;
  paymentMethodTypes: string[];
  paymentMethod: string;
  lineItems: LineItem[];
  status: OrderStatus;
  type: OrderType;
  tableId?: number;
  readyTime: Date | null;
  toPickupTime: Date;
  enqueueTime: Date;
  oneMarketPoints: number;
  refundedAmount: number;
  production: boolean;
  cancellationInfo: OrderCancellationInfo[] | null;
  subscriber: OrderSubscriber | null;
  pointsRedemptionInfo: PointsRedemptionInfo | null;
  couponRedemptionInfo: CouponRedemptionInfo | null;
  voucherRedemptionInfo: VoucherRedemptionInfo | null;
  affiliateRedemptionInfo: AffiliateRedemptionInfo | null;
  beforeRefundInfo: BeforeRefundInfo | null;
  sourceInfo: SourceInfo;
  remindersSentCount: number;
  voidOrSkipPaymentInfo: OrderVoidOrSkipPaymentInfo | null;
  pickupType: OrderPickupType | null;
  reviews: Review[] | null;
  preAuthInfo: PreAuthInfo | null;
}
