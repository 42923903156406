import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { pathOr } from 'ramda';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import { useEffect } from 'react';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import SpecialHoursShifts from "./SpecialHoursShifts"

type SpecialDateRangeProps = {
  currentHourIndex: number;
};

const SpecialDateRange = ({ currentHourIndex }: SpecialDateRangeProps) => {

  const { watch, setValue } = useFormContext();
  const specialHoursList = watch('specialHours');

  const handleDateChange = (dateValue: Date | null, dateType: 'startDate' | 'endDate') => {

    const formattedDate = dateValue ? format(dateValue || new Date(), "MM-dd-yyyy") : 'N/A'
    setValue(`specialHours[${currentHourIndex}].data.${dateType}`, formattedDate)

  };

  useEffect(() => {
    setValue(`specialHours[${currentHourIndex}].data.startDate`, null)
    setValue(`specialHours[${currentHourIndex}].data.endDate`, null)
  }, [])

  return (
    <>
      <div className="dateRangeWrapper">
        <div className="dateRangeContent">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={new Date()}
              maxDate={specialHoursList[currentHourIndex].data.endDate || null}
              value={specialHoursList[currentHourIndex].data.startDate}
              onChange={(newValue: Date | null) => {
                handleDateChange(newValue, 'startDate');
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  helperText=""
                  size="small"
                  sx={{ maxWidth: '265px', width: '100%' }}
                />
              )}
            />
            -
            <DatePicker
              minDate={pathOr(new Date(), [currentHourIndex, 'data', 'startDate'], specialHoursList)}
              onError={() => setValue(`specialHours.${currentHourIndex}.data.endDate`, specialHoursList[currentHourIndex].data.startDate)}
              value={specialHoursList[currentHourIndex].data.endDate}
              onChange={(newValue: Date | null) => {
                handleDateChange(newValue, 'endDate');
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  helperText=""
                  size="small"
                  sx={{ maxWidth: '265px', width: '100%' }}
                />
              )}
            />
          </LocalizationProvider>
        </div>

        <div className="divider" />

        <SpecialHoursShifts currentHourIndex={currentHourIndex} />

      </div>

    </>
  );
};

export default SpecialDateRange;
