import * as React from 'react';
import { Box, Grid, Typography, Theme, createStyles, makeStyles, Checkbox, FormControlLabel, CardActionArea } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import { InfoOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

// src
import { LineItem, Order, OrderStatus } from '../../services/models';
import MerchandiseModifiers from '../../bos_common/src/components/Merchandise/MerchandiseModifiers';
import CollapsibleContainer from '../../bos_common/src/components/CollapsibleContainer';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { isEmptyOrNil } from '../../utils';
import renderPrice from 'bos_common/src/components/Price';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .item-checkbox-label-container': {
        display: 'flex',
        marginRight: 0,
        flex: 1,
        '& .MuiFormControlLabel-label': {
          width: "100%",
        }
      },

      '& .item-label-collapsible': {
        marginRight: theme.spacing(2),
      },

      '& .item-label': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
      },

      '& .order-remain-inventory': {
        backgroundColor: '#F5F5F5',
      },

      '& .order-notes-container': {
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        backgroundColor: '#F5F5F5',
        '& .notes': {
          fontWeight: 'bold',
          color: theme.palette.warning.main,
        },
        '& .title': {
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }
      },

      '& ul': {
        backgroundColor: '#F5FAFB',

        '& li': {
          padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
          borderTop: `1px solid #efefef`,
          paddingLeft: theme.spacing(3),
        }
      }
    },
  })
);

const RenderItemRemainingQuantity = ({ item }: { item: LineItem }) => {
  const { t } = useTranslation();
  return (
    item.remainInventory != null
      ? <div className='order-remain-inventory'>
        <Typography variant="body2" color="text.secondary" alignItems="center" display="flex">
          <InfoOutlined fontSize="small" />&nbsp;{t("Order_PostOrderInventory")}: {item.remainInventory}
        </Typography>
      </div>
      : null
  );
}

export type OrderItemCardProps = {
  item: LineItem,
  order: Order,
  index: number,
  readOnly?: boolean,
  disableCheckbox?: ((item: LineItem) => boolean) | boolean,
  defaultChecked?: boolean | ((item: LineItem, index: number) => boolean),
  strikeThrough?: boolean,
  detailedMode?: boolean,
  onCheckboxChangeAction?: (params: { checkboxValue: boolean, order: Order, item: LineItem, index: number }) => void,
}

const OrderItemCard = (props: OrderItemCardProps) => {
  const classes = useStyles();
  const {
    item,
    index,
    order,
    readOnly,
    onCheckboxChangeAction = () => { },
    disableCheckbox = false,
    defaultChecked = false,
    strikeThrough = false,
    detailedMode = false,
  } = props

  const isDefaultChecked = typeof defaultChecked === 'function' ? defaultChecked(item, index) : defaultChecked
  // use disableCheckbox flag or disable checkbox if item is already refunded
  const _disableCheckbox = typeof disableCheckbox === 'function' ? disableCheckbox(item) : disableCheckbox
  const isCheckboxDisabled = (
    _disableCheckbox
    || item.refunded
    || [OrderStatus.PAID_EXTERNALLY, OrderStatus.VOIDED].includes(order.status)
  );

  const [checked, toggleCheckbox] = React.useState<boolean>(isDefaultChecked)
  const { t } = useTranslation();
  const hasNoteOnItem = Boolean(item.note);

  const setCheckbox = (value: boolean) => {
    toggleCheckbox(value);
    onCheckboxChangeAction({ checkboxValue: value, order, item, index })
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const checkboxValue = event.target.checked;
    setCheckbox(checkboxValue)
  }

  const RenderItemNote = () => {
    return (
      <If condition={hasNoteOnItem}>
        <Then>
          <div className='order-notes-container'>
            <Typography variant="body1" color="text.secondary" className='title'>
              {t("ItemNotes")}
            </Typography>
            <Typography variant="body1" className='notes'>
              {item.note}
            </Typography>
          </div>
        </Then>
      </If>
    )
  }

  const RenderItemQuantity = () => (
    <If condition={item.quantity}>
      <Grid item>
        <Grid item>
          <Typography className="item-quantity" variant="subtitle1" color="div">
            x{item.quantity}
          </Typography>
        </Grid>
      </Grid>
    </If>
  )

  const RenderItemCheckboxLabel = () => (
    <div className='item-label'>
      <span>{strikeThrough && (order.status === OrderStatus.PAID && checked) ? <s>{item.name}</s> : item.name}</span>
      <RenderItemQuantity />

      <If condition={detailedMode}>
        <span>&nbsp;&nbsp;&nbsp;{renderPrice(item.price)}</span>
      </If>
    </div>
  )

  const RenderItemLabel = () => {
    return (
      <>
        <If condition={readOnly}>
          <Then>
            <div className='item-label'>
              <Typography variant="subtitle1" component="div">
                {item.name}
              </Typography>
              <RenderItemQuantity />
            </div>
          </Then>
          <Else>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  disabled={isCheckboxDisabled}
                />
              }
              className='item-checkbox-label-container'
              label={<RenderItemCheckboxLabel />}
            />
          </Else>
        </If>
        <RenderItemRemainingQuantity item={item} />
      </>
    )
  }

  return (
    <Grid container className={classes.root} alignItems="baseline">
      <Grid item xs alignItems="flex-start" justifyContent="flex-start">
        <CardActionArea onClick={() => !isCheckboxDisabled && setCheckbox(!checked)}>
          <If condition={!isEmptyOrNil(item.customization) || !isEmptyOrNil(item.note)}>
            <Then>
              <CollapsibleContainer
                defaultOpen={hasNoteOnItem || detailedMode}
                label={(
                  <div className="item-label-collapsible">
                    <RenderItemLabel />
                  </div>
                )}
              >
                <MerchandiseModifiers modifiers={item.customization} modifiersDisplayOrder={item.customizationDisplayOrder} />
                {/* This should always render as long as the note exists. */}
                <RenderItemNote />
              </CollapsibleContainer>
            </Then>
            <Else>
              <RenderItemLabel />
            </Else>
          </If>
        </CardActionArea>
      </Grid>
    </Grid>
  );
}

export default OrderItemCard
