import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Case, Default, If, Switch } from 'react-if';
import { User } from '../../../bos_common/src/types/UserTypes';
import { AppContext } from '../../../context/AppContext';
import { MerchantOrdersContext } from '../../../context/MerchantOrders/MerchantOrdersContext';
import { OrderStatus } from '../../../services/models';
import { NotificationSeverity } from '../../../types/NotificationSlice';
import { PassCodeConfirmationDialog } from '../PassCodeConfirmationDialog';
import KitchenPrintOrderAction from './ActionTemplates/KitchenPrintOrderAction';
import OrderActionSelector from './ActionTemplates/OrderActionSelector';
import PrintReceiptOrderAction from './ActionTemplates/PrintReceiptOrderAction';
import RefundOrderAction from './ActionTemplates/RefundOrderAction';
import RemindCustomerAction from './ActionTemplates/RemindCustomerAction';
import { OrderActionTemplateProps } from './ActionTemplates/types';
import { OrderActionsContext } from './context/OrderActionsContext';

const OrderActionsSelection = (props: OrderActionTemplateProps) => {
  const { merchant, localPrinter} = useContext(AppContext);
  const { orderAction, setOrderAction } = useContext(OrderActionsContext);
  const { t } = useTranslation();

  const hasKitchenPrinter =
    localPrinter || merchant?.device?.labelPrinterID || merchant?.device?.kitchenPrinterID;

  const forLabel = merchant?.device?.labelPrinterID != null;
  const { triggerNotification } = useContext(AppContext);
  const { setOrderStatus } = useContext(MerchantOrdersContext);
  const [dialogShown, setDialogShown] = useState<boolean>(false);

  const closePassCodeDialog = () => {
    setOrderAction(undefined);
    setDialogShown(false);
  };

  const onSuccessVoidAuth = async (user: User, token: string) => {
    const { order } = props;

    const onSuccessCallback = () => {
      triggerNotification(
        true,
        `Order for ${order.userDisplayName} is voided`,
        NotificationSeverity.SUCCESS
      );
      closePassCodeDialog();
    };

    await setOrderStatus({
      id: order.id,
      nextOrderStatus: OrderStatus.VOIDED,
      onSuccessCallback,
      staffToken: token,
    });
    closePassCodeDialog();
  };

  const onSuccessPaidExternallyAuth = async (user: User, token: string) => {
    const { order } = props;

    const onSuccessCallback = () => {
      triggerNotification(
        true,
        `Order for ${order.userDisplayName} is marked as paid`,
        NotificationSeverity.SUCCESS
      );
      closePassCodeDialog();
    };

    await setOrderStatus({
      id: order.id,
      nextOrderStatus: OrderStatus.PAID_EXTERNALLY,
      onSuccessCallback,
      staffToken: token,
    });
    closePassCodeDialog();
  };

  return (
    <Switch>
      <Case condition={orderAction === 'voided'}>
        <PassCodeConfirmationDialog
          isOpen={orderAction === 'voided' || orderAction === 'paid-externally' || dialogShown}
          onClose={closePassCodeDialog}
          title={t('Order_VoidedTitle')}
          onSuccessAuth={onSuccessVoidAuth}
        />
      </Case>
      <Case condition={orderAction === 'paid-externally'}>
        <PassCodeConfirmationDialog
          isOpen={orderAction === 'voided' || orderAction === 'paid-externally' || dialogShown}
          title={t('Order_PaidExternally_Title')}
          onClose={closePassCodeDialog}
          onSuccessAuth={onSuccessPaidExternallyAuth}
        />
      </Case>
      <Case condition={orderAction === 'refund'}>
        <RefundOrderAction {...props} />
      </Case>
      <Case condition={orderAction === 'kitchenPrint'}>
        <If condition={hasKitchenPrinter}>
          <KitchenPrintOrderAction {...props} forLabel={forLabel} />
        </If>
      </Case>
      <Case condition={orderAction === 'printReceipt'}>
        <If condition={merchant?.device?.receiptPrinterID}>
          <PrintReceiptOrderAction {...props} />
        </If>
      </Case>
      <Case condition={orderAction === 'reminder'}>
        <RemindCustomerAction {...props} />
      </Case>
      <Default>
        <OrderActionSelector {...props} />
      </Default>
    </Switch>
  );
};

export default OrderActionsSelection;
