import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import {
  Alert,
  Button,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Save } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

// src
import { AppContext } from '../context/AppContext';
import { ColoredPaper, getAuthHeaders } from '../bos_common/src';
import { UserContext } from '../bos_common/src/context/UserContext';
import { Merchant, StripeAccountStatus } from '../services/models';
import useFetchMerchant from '../services/useFetchMerchant';

interface IMerchantProfileParams {
  merchantUsername: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAlert-root': {
        margin: theme.spacing(1),
      },
    },
    content: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& .actionButton': {
        width: "100%",
      },
    },
  })
);

const MerchantPayoutPage = () => {
  const title = 'Bank Account';
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantProfileParams>();
  const { merchant } = useContext(AppContext);
  const { user, token } = useContext(UserContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const isAdmin = merchant?.ownerId === user?.id

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    history.push(`/biz/create`);
  }

  const merchantLoading = useFetchMerchant({ merchantUsername })

  const [{ data: stripeAccountStatus, loading: stripeAccountLoading }] = useAxios<StripeAccountStatus>({
    url: '/merchants/payouts',
    params: { username: merchantUsername },
    headers: getAuthHeaders(token),
  });

  const [{ loading: updatePayoutLoading }, executePayoutPut] = useAxios<{ url: string, merchant: Merchant }>(
    { url: '/merchants/payouts', method: 'POST' },
    { manual: true }
  );

  const onUpdateStripPayout = () => {
    const returnURL = `${window.location.origin}/${merchant?.username}/payout`
    executePayoutPut({
      data: {
        id: merchant?.id,
        returnURL: returnURL,
      },
      headers: getAuthHeaders(token),
    }).then((response) => {
      if (response.status === 200 && response.data) {
        window.location.href = response.data.url;
      }
    });
  }

  const onVisitStripeDashboard = () => {
    if (!stripeAccountStatus?.chargesEnabled) {
      return null;
    }
    window.location.href = stripeAccountStatus.loginLink;
  }

  const renderStripeStatus = (enabled: boolean) =>
    enabled ? (
      <Alert severity="success">
        {t("BankAccount_SuccessText", { email: stripeAccountStatus?.email })}
      </Alert>
    ) : (
      <Alert severity="error">
        {t("BankAccount_ErrorText")}
      </Alert>
    );

  const renderStripePermissionReminder = () =>
    !isAdmin && (
      <Alert severity="warning">
        {t("BankAccount_WarnText")}
      </Alert>
    )

  const renderStripeConnect = (create: boolean) => {
    const btnText = create ? t("BankAccount_Setup") : t("BankAccount_View");
    return (
      <div className={classes.content}>
        {updatePayoutLoading ? (
          <LoadingButton pending pendingPosition="start" startIcon={<Save />} variant="outlined">
            {btnText}
          </LoadingButton>
        ) : (
          <Button variant="contained" color="primary" disabled={!isAdmin} className="actionButton" onClick={() => {
            if (create) {
              onUpdateStripPayout()
            } else {
              onVisitStripeDashboard()
            }
          }}>
            {btnText}
          </Button>
        )}
      </div>
    );
  };

  const loading = merchantLoading || stripeAccountLoading;

  return (
    <div className="container">
      {loading && <LinearProgress />}
      {!loading && (
        <ColoredPaper className={classes.root}>
          <CardHeader
            subheader={t("BankAccount_SubHead")}
          />
          <Divider />
          <CardContent>
            {renderStripeStatus(!!stripeAccountStatus?.chargesEnabled)}
            {renderStripePermissionReminder()}
            {renderStripeConnect(!stripeAccountStatus?.chargesEnabled)}
          </CardContent>
        </ColoredPaper>
      )}
    </div>
  );
};

export default MerchantPayoutPage;
