import React, { useMemo, useContext, useEffect } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { If, Then, Else } from 'react-if';
import { useTranslation } from 'react-i18next';
import { find, propEq } from 'ramda';

import { MerchandiseStatsListingProps } from '../types';
import { isEmptyOrNil } from '../../../utils';
import { AppContext } from '../../../context/AppContext';
import {
  getSortedCategoriesStatsList,
  CategoriesStatsList,
  CategoryWithStats,
  getPercentageComparison,
} from '../../../utils/merchantStatsUtils';

import useStyles from './styles';
import PercentageComparison from '../PercentageComparison';

const CategoryStatsTable = (
  props: MerchandiseStatsListingProps & {
    setReport: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  }
) => {
  const classes = useStyles();
  const { merchant } = useContext(AppContext);
  const { t } = useTranslation();

  const { merchandiseData, merchantStats, comparisonStats, setReport } = props;
  const categoriesList: CategoriesStatsList = useMemo(
    () => getSortedCategoriesStatsList({ merchandiseData, merchantStats, merchant }),
    [merchandiseData, merchantStats, merchant]
  );
  const comparisonCategoriesList: CategoriesStatsList = useMemo(
    () =>
      comparisonStats
        ? getSortedCategoriesStatsList({
            merchandiseData,
            merchantStats: comparisonStats,
            merchant,
          })
        : [],
    [merchandiseData, comparisonStats, merchant]
  );

  const RenderComparison = ({ item }: { item: CategoryWithStats }) => {
    const comparisonItem = find(propEq('id', item.id), comparisonCategoriesList);
    if (!comparisonItem) return null;

    const percentageComparison = getPercentageComparison(item, comparisonItem, 'sale');
    return (
      <div>
        <PercentageComparison {...percentageComparison} />
      </div>
    );
  };

  useEffect(() => {
    setReport([
      {
        name: t('Sales_CategoriesStats'),
      },
      {
        name: t('Category'),
        value: `${t('Sales_MerchandiseStats_Sales')} ($)`,
        ' ': t('Sales_MerchandiseStats_TimesOrdered'),
      },
      ...categoriesList.map((o) => ({
        name: o.name,
        value: o.sale,
        ' ': o.quantity,
      })),
    ]);
  }, [categoriesList]);

  return (
    <>
      <Typography
        sx={{ fontWeight: 'bold', lineHeight: 1 }}
        variant="subtitle2"
        color="text.secondary"
        gutterBottom
      >
        {t('Sales_CategoriesStats')}
      </Typography>
      <If condition={!isEmptyOrNil(categoriesList)}>
        <Then>
          <TableContainer className={classes.statsListingTable}>
            <Table stickyHeader aria-label="sticky table" size="small">
              {/* table header */}
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('Category')}</TableCell>
                  <TableCell align="right">{t('Sales_MerchandiseStats_Sales')} ($)</TableCell>
                  <TableCell align="right">{t('Sales_MerchandiseStats_TimesOrdered')}</TableCell>
                </TableRow>
              </TableHead>

              {/* table body */}
              <TableBody>
                {categoriesList.map((item: CategoryWithStats, idx: number) => (
                  <TableRow key={`category-${item.id ?? 0}`}>
                    <TableCell width={20} sx={{ color: 'text.secondary' }}>
                      {idx + 1}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      ${parseFloat(`${item.sale}`).toFixed(2)}
                      <RenderComparison item={item} />
                    </TableCell>
                    <TableCell align="right" width={50}>
                      {item.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Then>
        <Else>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {t('Sales_NoData')}
          </Typography>
        </Else>
      </If>
    </>
  );
};

export default CategoryStatsTable;
