import React from "react";
import { Divider } from "@material-ui/core";
import { LineItem, Order } from "../../types/OrderTypes";
import OrderItemCard from "./OrderItemCard";
import OrderSummary from "../OrderSummary";

export default function OrderItems(props: {
  order: Order,
}): React.ReactElement {

  const { order } = props;
  return (
    <div>
      {/* <Typography gutterBottom variant="h6" component="div">
        Receipt
      </Typography> */}
      {order.lineItems.map((item: LineItem) => {
        return (
          <OrderItemCard item={item} key={`${item.id}-${item.customizationSignature}`} />
        )
      })}
      <Divider light={true} variant="middle" />
      <OrderSummary order={order} />
    </div>
  )
}