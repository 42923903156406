import { Box, Fab, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack, List } from '@material-ui/icons';
import { lazy, Suspense, useContext, useMemo, useState } from 'react';
//  后面有时间 要去弄下 组件的过渡效果
// import { TransitionGroup, CSSTransition, } from 'react-transition-group'
import { useTranslation } from 'react-i18next';
import SimpleLoader from '../../bos_common/src/components/SimpleLoader';
import { MerchandiseModifierCategory } from '../../services/models';
import ModalCard from './ModalCard';
import { StepStart, TemplateContext, TemplateProvider } from './Variable';

const getStepsView = (name: string) => {
  const StepView = lazy(() => import(`./${name}`));
  return <StepView />;
};

const MerchantTemplate = () => {
  const { steps, changeSteps, info, goBack, changeCategory } = useContext(TemplateContext);

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    changeSteps([StepStart]);
    setOpen(false);
    // storage.remove('category');
    changeCategory({
      name: '',
    } as MerchandiseModifierCategory);
    // TODO sto
  };

  const step = useMemo(() => {
    return steps[steps.length - 1];
  }, [steps]);

  const beforeStep = useMemo(() => {
    return steps[steps.length - 2];
  }, [steps]);

  const StepView = useMemo(() => {
    return getStepsView(step.name === 'Start' ? 'Lists' : step.name);
  }, [step, info]);

  return (
    <Box sx={{ pl: 0, width: { xs: '100%', sm: 'auto' }, textAlign: 'center' }}>
      <Fab variant="extended" color="secondary" component="span" onClick={() => handleClickOpen()}>
        <List />
        {t('Modifiers')}
      </Fab>
      <ModalCard
        open={open}      
        onClose={handleClose}
        renderHead={() => (
          <>
            <Grid item xs={2} display="flex" flexDirection="row" alignItems="center">
              {beforeStep && (
                <>
                <IconButton onClick={goBack} color="inherit">
                  <ArrowBack />
                </IconButton>
                <Typography
                  noWrap
                  textOverflow="ellipsis"
                  sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {beforeStep.desc || ''}
                </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={7} container justifyContent="center">
              <Typography component="span" variant="h6" noWrap textOverflow="ellipsis">
                {step.desc}
              </Typography>
            </Grid>
          </>
        )}
      >
        <Suspense fallback={<SimpleLoader loading />}>{StepView}</Suspense>
      </ModalCard>
    </Box>
  );
};

const TemplateView = () => {
  return (
    <TemplateProvider>
      <MerchantTemplate />
    </TemplateProvider>
  );
};

export default TemplateView;
