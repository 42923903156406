import React, { useCallback, useContext } from 'react';
import { isEmpty } from 'ramda';

import { MerchandiseWithCategory } from '../../bos_common/src/services/merchandiseUtils';
import { getAuthHeaders } from '../../bos_common/src';
import { UserContext } from '../../bos_common/src/context/UserContext';
import MerchandiseLisiting from '../../bos_common/src/components/Merchandise/MerchandiseListing';
import axios from '../../bos_common/src/services/backendAxios';

import { Merchandise, MerchandiseApiResponseType, MerchandiseCategory } from '../../services/models';
import MerchandiseInventoryForm from '../MerchandiseSettingsForm';

import MerchandiseInventoryTable from './MerchandiseInventoryTable';

type MerchandiseInventoryProps = {
  categories: Array<MerchandiseCategory>,
  merchandiseData?: MerchandiseApiResponseType,
  groupedMerchandiseData: Array<MerchandiseWithCategory>,
  loading: boolean,
  isToolbar?: boolean,
  refetchMerchandises: () => void
}
const MerchandiseInventory = (props: MerchandiseInventoryProps) => {
  const { token } = useContext(UserContext)

  const { categories, merchandiseData, groupedMerchandiseData, loading, refetchMerchandises, isToolbar = false } = props

  const toggleItemAvailability = useCallback(
    (merchandise: Pick<Merchandise, 'id' | 'inventory' >) => {
      const { id, inventory } = merchandise

      axios
        .put(
          `/merchants/merchandises/${id}`,
          { id, inventory },
          { headers: getAuthHeaders(token) },
        )
        .catch((error) => console.error(error))
        .finally(() => refetchMerchandises());
    },
    [merchandiseData],
  )

  const onSettingsSubmission = (props: any) => {
    refetchMerchandises();
    props.onClose();
  }

  return (
    <MerchandiseLisiting
      categories={categories}
      menuItemsAvailable={!isEmpty(merchandiseData?.merchandises)}
      loading={loading}
      isToolbar={isToolbar}
    >
      {
        groupedMerchandiseData.map(({ category, merchandises }) => (
          <MerchandiseInventoryTable
            onItemUpdateCallback={toggleItemAvailability}
            category={category}
            merchandises={merchandises}
            key={`merchandise-table-${category ? category.id : '0'}`}
            MerchandiseActionComponent={(props: any) =>
              <MerchandiseInventoryForm
                {...props}
                onClose={() => props.onClose()}
                onSuccessfulUpdate={() => onSettingsSubmission(props)}
              />
            }
          />
        ))
      }
    </MerchandiseLisiting>
  )
}

export default MerchandiseInventory