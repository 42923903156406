import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  createStyles, IconButton, makeStyles, Theme, Typography,
  DialogContent, DialogActions, Button, TextField, InputAdornment, Stack
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';


// src
import axios from 'bos_common/src/services/backendAxios';
import renderPrice from 'bos_common/src/components/Price';
import { CalculatedOrderTotal, calculateOrderTotal } from 'bos_common/src/services/orderPricingUtils';
import { getAuthHeaders } from 'bos_common/src/utils';
import { UserContext } from 'bos_common/src/context/UserContext';
import { isOpenCheckPreAuthed } from 'bos_common/src/services/orderUtils';

import { Merchant, Order, OrderStatus, OrderType } from '../../services/models';
import { AppContext } from '../../context/AppContext';
import { MerchantOrdersContext } from '../../context/MerchantOrders/MerchantOrdersContext';
import { NotificationSeverity } from '../../types/NotificationSlice';
import CustomDialog from "../common/CustomDialog";
import PassCodeConfirmationDialog from './PassCodeConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .dialog-content': {
        padding: theme.spacing(2),
        textAlign: 'center'
      },

      '& .dialog-actions': {
        justifyContent: 'center',
        width: "100%",
        gridGap: theme.spacing(2),

        '& .action-btn': {
          borderRadius: theme.spacing(2),
          textTransform: 'capitalize',
          boxShadow: "0px 0px 6px rgb(0 0 0 / 15%)",
        }
      },
    }
  })
);

type OrderMarkAsPaidDialogProps = {
  open: boolean;
  setOpen: (_: boolean) => void,
  order: Order;
  merchant: Merchant;
  onCloseCheckSuccessCb?: (order: Order) => void,
};

const OrderMarkAsPaidDialog = (props: OrderMarkAsPaidDialogProps): JSX.Element | null => {
  const { open, setOpen, order, merchant, onCloseCheckSuccessCb } = props
  if (!open) return null;

  const classes = useStyles()
  const { t } = useTranslation();
  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext);
  const { setOrderStatus } = useContext(MerchantOrdersContext);

  const [orderTotal, setOrderTotal] = useState<CalculatedOrderTotal>({amount: 0, tax: 0, tip: 0});
  const [editableInput, setEditableInput] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const isPreAuthed = isOpenCheckPreAuthed(order);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setOrderTotal(calculateOrderTotal(order, merchant));
    }
  }, [open])


  const toggleDialog = () => setOpen(!open);

  const handleClose = () => {
    setShowConfirmationDialog(false);
    toggleDialog()
  }

  const handleConfirm = () => {
    if (isPreAuthed) {
      closeCheck();
    } else {
      setShowConfirmationDialog(true)
    }
  }

  const closeCheck = async () => {
    setLoading(true);
    axios({
      url: `/orders/openCheck/${order?.id}`,
      method: 'PUT',
      data: {
        id: order.id,
        status: OrderStatus.PAID,
        currency: order.currency,
        isPreAuthPayment: true,
        tax: orderTotal.tax,
        tip: orderTotal.tip,
        amount: orderTotal.amount,
        lineItems: [],
      },
      headers: getAuthHeaders(token),
    }).then((results) => {
      if (results.status === 200) {
        if (onCloseCheckSuccessCb) {
          onCloseCheckSuccessCb(order);
        } else {
          triggerNotification(
            true,
            `Tab for ${order.userDisplayName} is closed`,
            NotificationSeverity.SUCCESS
          );
        }
        handleClose();
      } else {
        triggerNotification(
          true,
          `Failed to close tab for ${order.userDisplayName}`,
          NotificationSeverity.ERROR
        );
      }
    }).catch((err) => {
      triggerNotification(
        true,
        `Failed to close tab for ${order.userDisplayName}`,
        NotificationSeverity.ERROR
      );
    }).finally(() => setLoading(false));
  }

  const onSuccessPaidExternallyAuth = async (_: any, token: string) => {
    const onSuccessCallback = () => {
      triggerNotification(
        true,
        `Order for ${order.userDisplayName} is marked as paid`,
        NotificationSeverity.SUCCESS
      );
      handleClose();
      setLoading(false);
    };

    setLoading(true);
    await setOrderStatus({
      id: order.id,
      nextOrderStatus: OrderStatus.PAID_EXTERNALLY,
      onSuccessCallback,
      staffToken: token,
      options: {
        updatedOrderAmount: orderTotal.amount
      }
    });
    setLoading(false);
  };

  const title = useMemo(() => {
    if (isPreAuthed) {
      return order.type === OrderType.DINEIN
        ? t('CloseCustomerTab', { name: order.userDisplayName })
        : t('CompleteOrder', { name:  order.userDisplayName });
    } else {
      return t('MarkOrderAsPaid', { name: order.userDisplayName});
    }
  }, [order.id])

  return (
    <>
      <CustomDialog
        title={title}
        open={open}
        setOpen={toggleDialog}
        className={classes.root}
        maxWidth="sm"
        isLoading={loading}
        fullWidth
        footerActions={
          <DialogActions className='dialog-actions'>
            {/* @ts-ignore */}
            <Button className='action-btn' variant="text" color="error" onClick={toggleDialog}>{t("Cancel")}</Button>
            <Button className='action-btn' variant="text" color="primary" onClick={() => setEditableInput(true)}>{t("EditPrice")}</Button>
            <Button className='action-btn' variant="contained" color="primary" onClick={handleConfirm}>{t("Confirm")}</Button>
          </DialogActions>
        }>
        <DialogContent className='dialog-content'>
          <If condition={editableInput}>
            <Then>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <TextField
                  autoFocus
                  value={orderTotal.amount}
                  variant="standard"
                  type='number'
                  inputProps={{ step: '0.01', min: '0' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    sx: { fontSize: 24 }
                  }}
                  sx={{ mb: 1, width: 120, ml: 6 }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrderTotal({
                    ...orderTotal,
                    amount: Number(event.target.value),
                  })}
                />
                <div>
                  <IconButton size="small" color='primary' onClick={() => setEditableInput(false)}><Check /></IconButton>
                </div>
              </Stack>
            </Then>
            <Else>
              <Typography variant='h5'>{renderPrice(orderTotal.amount)}</Typography>
            </Else>
          </If>
          <Typography variant='body1'>{t('MarkAsPaid_CorrectPrice')}</Typography>
        </DialogContent>
      </CustomDialog >

      {showConfirmationDialog &&
        <PassCodeConfirmationDialog
          isOpen={showConfirmationDialog}
          title={t('Order_PaidExternally_Title')}
          onClose={handleClose}
          onSuccessAuth={onSuccessPaidExternallyAuth}
        />
      }
    </>
  );
};

export default OrderMarkAsPaidDialog