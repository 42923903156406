import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { subSeconds } from 'date-fns';
import {
  createStyles, makeStyles, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TableSortLabel, Theme, Typography
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Else, If, Then } from 'react-if';

// src
import { UserContext } from 'bos_common/src/context/UserContext';
import { ColoredPaper, getAPIErrorMessage, getAuthHeaders } from 'bos_common/src';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { MerchantEmployeesStats } from 'bos_common/src/types/MerchantEmployeesStats';
import renderPrice from 'bos_common/src/components/Price';
import InputSearchBox from 'bos_common/src/components/InputSearchBox';

import { MerchantStatsPeriod } from '../../../components/Stats/types';
import { MerchantStatsContext } from '../../../context/MerchantStats/MerchantStatsContext';
import { AppContext } from '../../../context/AppContext';
import { NotificationSeverity } from '../../../types/NotificationSlice';
import { isEmptyOrNil } from '../../../utils';
import { MerchantEmployeeStatsApiResponse } from '../../../services/models';
import { getComparator, tableSort } from '../../../utils/merchantStatsUtils';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(2)
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

type EmployeeStatsTableProps = { statsList: MerchantEmployeesStats[]; loading: boolean }

const EmployeeStatsTable = ({ statsList = [], loading = false }: EmployeeStatsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [sortOrder, setOrder] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof MerchantEmployeesStats>('totalTips');

  const sortedList = useMemo(() => {
    return tableSort(statsList, getComparator(sortOrder, orderBy))
  }, [statsList, sortOrder, orderBy])

  const createSortHandler = (property: keyof MerchantEmployeesStats) => () => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper} elevation={2} className={classes.table}>
      <Table aria-label="employee stats table">
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography><b>{t("EmployeeName")}</b></Typography></TableCell>
            <TableCell
              component="th"
              width={150}
              sortDirection={orderBy === 'totalTips' ? sortOrder : false}
            >
              <TableSortLabel
                active={orderBy === "totalTips"}
                direction={orderBy === "totalTips" ? sortOrder : 'asc'}
                onClick={createSortHandler("totalTips")}
              >
                <b>{t("BillingStatements_Tips")}</b>
                {orderBy === "totalTips" ? (
                  <span className={classes.visuallyHidden}>
                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>

            </TableCell>
          </TableRow>
        </TableHead>
        <If condition={isEmptyOrNil(statsList) && !loading}>
          <Then>
            <caption>
              <Typography variant='body2'>{t("CustomerInsights_NoData")}</Typography>
            </caption>
          </Then>
          <Else>
            <TableBody>
              {sortedList.map((employee) => (
                <TableRow key={`row-${employee.staffId}`} hover className="tableRow">
                  <TableCell>
                    {employee.displayName}
                  </TableCell>
                  <TableCell width={150}>{renderPrice(Number(employee.totalTips))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Else>
        </If>
      </Table>
    </TableContainer >
  )
}

const EmployeeInsights = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState<string>();

  const { merchant, triggerNotification } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { statsTimePeriod, statsDateRange } = useContext(MerchantStatsContext);

  const merchantUsername = merchant?.username;
  const isDailyTimePeriod = statsTimePeriod === MerchantStatsPeriod.Day

  const [{ data: statsData, loading: loadingStats }, refreshStats] =
    useAxios<MerchantEmployeeStatsApiResponse>(
      {
        url: '/merchants/employee-stats',
        params: {
          merchantUsername,
          statsDateRange: JSON.stringify({
            fromDate: statsDateRange?.fromDate,
            toDate: statsDateRange?.toDate && isDailyTimePeriod
              ? subSeconds(statsDateRange?.toDate, 1)
              : statsDateRange?.toDate,
          }),
        },
        headers: getAuthHeaders(token),
      },
      { manual: true }
    );

  const { stats: employeeStatsList = [] } = statsData ?? {}

  // refresh stats when date range selection changes
  useEffect(() => {
    refreshStats()
      .catch((err) => {
        const msg = getAPIErrorMessage(err);
        if (msg) { triggerNotification(true, msg, NotificationSeverity.ERROR); }
      })
  }, [statsDateRange, merchantUsername]);

  // Filter List based on search query
  const filteredList = useMemo(() => {
    if (isEmptyOrNil(searchQuery)) return employeeStatsList;
    const caseInsensitiveStr = searchQuery!.toLowerCase()
    return employeeStatsList.filter(({ displayName = '' }) => {
      return displayName.toLowerCase().includes(caseInsensitiveStr)
    })
  }, [employeeStatsList, searchQuery])

  return (
    <ColoredPaper elevation={0}>
      <SimpleLoader loading={loadingStats} />
      <InputSearchBox
        onChangeQuery={(s) => setSearchQuery(s)}
        placeholder={`${t("Employee_Search")}...`}
        leftChild={<Search />}
      />
      <EmployeeStatsTable statsList={filteredList} loading={loadingStats} />
    </ColoredPaper>
  );
};

export default EmployeeInsights