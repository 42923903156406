import React, { useContext, useEffect, useState } from 'react';
import { Chip, createStyles, Theme, makeStyles, Toolbar, Stack, useMediaQuery, Button } from '@material-ui/core';
import classNames from 'classnames';
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';

import {
  MerchantOrdersContext,
  ORDER_FILTERS,
} from '../../context/MerchantOrders/MerchantOrdersContext';
import { OrderTabs } from '../../constants/orders';
import { Merchant } from '../../services/models';
import BranchSelector from '../../pages/MerchantOrders/commons/BranchSelector';
import { isEmptyOrNil } from '../../utils';
import OrderSummaryDialog from './OrderSummaryDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: 'hidden',
      overflowX: 'auto',
      background: '#f5faff',
      minHeight: 'unset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),

      '& .order-filter-chip': {
        minWidth: '50px',
        textAlign: 'center',
        marginRight: theme.spacing(1),
        color: 'rgba(0,0,0,0.6)',
        backgroundColor: 'white',
        boxShadow: `0px 0px 3px 3px rgba(0,0,0, 0.05)`,
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
          minWidth: '100px',
        },

        '&.filter-selected': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          boxShadow: `0px 0px 3px 3px rgba(0,0,0, 0.1)`,
        },
      },
      '& .summary-button': {
        '& .MuiButton-label': {
          textTransform: 'none',
        }
      },
    },
    mobileStoreSelector: {
      background: '#f5faff',
      padding: theme.spacing(2),
      '& .store-selector-container': {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
    }
  })
);

type OrderFiltersList = Array<{ label: string, value: ORDER_FILTERS }>
const OrderFilters = ({ merchant }: { merchant?: Merchant }) => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const { selectedTab, setSelectedFilter, selectedFilter, searchOrderItems, isUserOneMOperator, loadingOrders } = useContext(MerchantOrdersContext);

  const [filtersList, setFiltersList] = useState<OrderFiltersList>([]);
  const [showOrderSummaryDialog, setShowOrderSummaryDialog] = useState<boolean>(false);

  const isOpenCheckEnabled = merchant?.orderingConfig?.enabledOpenCheck ?? false
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const MERCHANT_NEW_FILTERS_LIST = [
    { label: t("All"), value: ORDER_FILTERS.all },
    { label: t("OrderAhead"), value: ORDER_FILTERS.orderAhead },
    ...(isOpenCheckEnabled ? [{ label: t("OpenCheck"), value: ORDER_FILTERS.openCheck }] : []),
    // { label: "Paid", value: ORDER_FILTERS.paid },
    // { label: "Unpaid", value: ORDER_FILTERS.unPaid },
    { label: t("Refunded"), value: ORDER_FILTERS.refunded },
    // { label: "Dine-in", value: ORDER_FILTERS.dineIn },
    // { label: "Pickup", value: ORDER_FILTERS.pickUp },
  ]

  const MERCHANT_COMPLETED_FILTERS_LIST = [
    { label: t("All"), value: ORDER_FILTERS.all },
    { label: t("Completed_PaidExternally"), value: ORDER_FILTERS.paidExternally },
    { label: t("Voided"), value: ORDER_FILTERS.voided },
    { label: t("Refunded"), value: ORDER_FILTERS.refunded },
    // { label: "Dine-in", value: ORDER_FILTERS.dineIn },
    // { label: "Pickup", value: ORDER_FILTERS.pickUp },
  ]

  const OPERATOR_NEW_FILTERS_LIST = [
    { label: t("All"), value: ORDER_FILTERS.all },
    { label: t("OrderAhead"), value: ORDER_FILTERS.orderAhead },
    { label: t("Refunded"), value: ORDER_FILTERS.refunded },
  ]

  const OPERATOR_COMPLETED_FILTERS_LIST = [
    { label: t("All"), value: ORDER_FILTERS.all },
    { label: t("Refunded"), value: ORDER_FILTERS.refunded },
    { label: t("Confirmed"), value: ORDER_FILTERS.confirmed },
  ]

  useEffect(() => {
    let list = [];

    if (isUserOneMOperator) {
      list = selectedTab === OrderTabs.new
        ? OPERATOR_NEW_FILTERS_LIST
        : OPERATOR_COMPLETED_FILTERS_LIST
    } else {
      list = selectedTab === OrderTabs.new
        ? MERCHANT_NEW_FILTERS_LIST
        : MERCHANT_COMPLETED_FILTERS_LIST
    }
    setFiltersList(list);
  }, [selectedTab, i18n.language])

  const onFilterClick = (value: ORDER_FILTERS) => {
    setSelectedFilter(value);
    searchOrderItems('');
  };

  const RenderOrderFilters = () => {
    return filtersList.map(({ label, value }) => {
      const isSelected = value === selectedFilter[selectedTab];

      return (
        <Chip
          className={classNames('order-filter-chip', { 'filter-selected': isSelected })}
          key={`${label}-${value}`}
          label={label}
          variant="filled"
          onClick={() => onFilterClick(value)}
          clickable
        />
      );
    })
  }

  return (
    <>
      <Toolbar className={classes.root}>
        <If condition={isMobile}>
          <Then>
            {RenderOrderFilters()}
          </Then>
          <Else>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
              <div>
                {RenderOrderFilters()}
              </div>
              <Stack direction="row" alignItems="center">
                <BranchSelector />
                <Button
                  color="primary"
                  className="summary-button"
                  disabled={loadingOrders}
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() =>
                    setShowOrderSummaryDialog(true)}
                >
                  {t('OrdersSummary')}
                </Button>
              </Stack>
            </Stack>
          </Else>
        </If>
      </Toolbar>
      <If condition={isMobile}>
        <div className={classes.mobileStoreSelector}>
          <BranchSelector />
          <Button
            color="primary"
            disabled={loadingOrders}
            variant="contained"
            className="summary-button"
            fullWidth
            onClick={() =>
              setShowOrderSummaryDialog(true)}
          >
            {t('OrdersSummary')}
          </Button>
        </div>
      </If>
      <OrderSummaryDialog
        showDrawer={showOrderSummaryDialog}
        setShow={setShowOrderSummaryDialog}
      />
    </>
  );
};

export default OrderFilters;
