import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router';

import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { UserContext } from '../bos_common/src/context/UserContext'
import { AppContext } from '../context/AppContext';
import useFetchMerchant from '../services/useFetchMerchant';
import { MerchantType } from '../services/models';
import { isEmptyOrNil, userIsOneMOperator } from '../utils';

interface IMerchantDashParams {
  merchantUsername: string;
}

const MerchantDashboardPage = () => {
  const { merchantUsername } = useParams<IMerchantDashParams>();
  const { merchant } = useContext(AppContext);
  const { user, authenticating } = useContext(UserContext)
  let fetching = false

  // redirect to orders hub if the user is an operator
  const isOneMOperator = userIsOneMOperator(user)
  if (isOneMOperator) return <Redirect to='/orders-hub' />

  if (merchantUsername || user) {
    fetching = useFetchMerchant({ merchantUsername, ownerId: user?.id, email: user?.email })
  }

  const loading = fetching || authenticating;
  if (loading) return <SimpleLoader loading={loading} />

  if (isEmptyOrNil(merchant)) {
    return <Redirect to='/create' />
  }

  let route = '/notfound'
  const { username, type } = merchant!

  // redirect to product type merchants, if type is not service and username exists 
  if (username) {
    route = type === MerchantType.services ? `/${username}/calendar` : `/${username}/orders`
  }

  return <Redirect to={route} />
};

export default MerchantDashboardPage;
