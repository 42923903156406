import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid, Card, Stack, useMediaQuery } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { Else, If, Then } from 'react-if';
import { head } from 'ramda';
import { useTranslation } from 'react-i18next';

import { getAuthState } from 'bos_common/src/services/auth0';
import { ColoredPaper } from '../bos_common/src/components/Papers';
import { UserContext } from '../bos_common/src/context/UserContext';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import AuthorizeUser from '../bos_common/src/components/Auth';

import StaffLoginButton from '../components/Login/StaffLoginButton';
import MerchantAppLogo from '../components/common/MerchantAppLogo';
import LanguageSelector from '../components/Login/LanguageSelector';

import LoginPeopleIllustration from '../assets/images/login-people-illustration.svg';
import useLoginPageStyles from '../components/Login/pageStyles';
import { getDeviceAuthMeta, MerchantDeviceAuthStorageType } from '../services/useAuthDevice';
import { isEmptyOrNil } from '../utils';

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const from = location.state?.from || { pathname: '/' };
  const { user, authenticating } = useContext(UserContext)
  const classes = useLoginPageStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [deviceAuthData] = useState(getDeviceAuthMeta());
  const authState = getAuthState()

  useEffect(() => {
    if (user) {
      setTimeout(() => history.replace({ pathname: from.pathname, hash: 'openDrawer' }), 1000)
    }
  }, [user]);

  // redirect to staffLogin page, if previous auth state has token and deviceAuthData is also not empty
  if (authState.token && !isEmptyOrNil(deviceAuthData)) {
    const deviceAuth = head(deviceAuthData!) as MerchantDeviceAuthStorageType | undefined;
    if (deviceAuth) {
      history.replace({ pathname: `/${deviceAuth.merchantUsername}/stafflogin`, state: { from }, })
    }
  }

  const RenderLoginForm = () => {
    return (
      <Card className='login-form-container'>
        <div className='content-container'>
          <If condition={!user && !authenticating}>
            <AuthorizeUser
              redirectTo={from.pathname}
              actionBtnText={t("Login")}
              fullwidth
              showActionBtn
            />
          </If>
        </div>

        <If condition={!isEmptyOrNil(deviceAuthData)}>
          <Then>
            <div className='divider-container'>
              <span className='or-span'>OR</span>
              <Divider orientation="horizontal" className="login-form-divider" />
            </div>

            <div className='content-container'>
              <StaffLoginButton />
            </div>
          </Then>
        </If>

        <div className='divider-container'>
          <Divider orientation="horizontal" className="login-form-divider" />
        </div>

        <LanguageSelector />
      </Card>
    )
  }

  return (
    <div className="container">
      <ColoredPaper className={classes.root}>
        <SimpleLoader loading={authenticating} />

        <div className='layout-container'>
          <div className='login-illustration'>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? "center" : "flex-end"} justifyContent={'space-between'} sx={{ maxWidth: '1280px', padding: !isMobile ? "0 40px" : "0", width: '100%', margin: '0 auto' }}>
              <If condition={!isMobile}>
                <Then>
                  <img src={LoginPeopleIllustration} className={'login-people'} />
                </Then>

                <Else>
                  <MerchantAppLogo />
                </Else>
              </If>
              <RenderLoginForm />
            </Stack>
          </div>

          <div className='content'>
            <Grid container>
              <Grid item sm={6}>
                <MerchantAppLogo />
              </Grid>
            </Grid>
          </div>
        </div>
      </ColoredPaper>
    </div>
  );
};

export default LoginPage;
