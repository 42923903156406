import { differenceInMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  defaultTo,
  move,
  reduce,
  pipe,
  propOr,
  find,
  map,
  propEq,
  pathOr,
  sort,
  prop,
  dissoc,
  flatten,
  ascend,
  head
} from "ramda";
import { getClientTimezone, isEmptyOrNil } from ".";

import { PRIORITIZED_ORDERS_LIST } from "../constants/localStorageKeys";
import { MerchantWithMerchandises } from "../context/AppContext";
import { LineItem, Merchandise, MerchandiseApiResponseType, MerchandiseCategory, Merchant, Order, OrderStatus } from "../services/models";

const getTime = (dateStr: Date) => new Date(dateStr).getTime();
export const sortOrders = (a: Order, b: Order, status?: OrderStatus) => {
  if (status === OrderStatus.FULFILLED) {
    if (a.orderNo && b.orderNo) {
      return Number(b.orderNo) - Number(a.orderNo);
    } else if (a.readyTime && b.readyTime) {
      return getTime(b.readyTime) - getTime(a.readyTime);
    }
    return getTime(b.createdAt) - getTime(a.createdAt);
  }
  if (a.enqueueTime && b.enqueueTime) {
    return getTime(a.enqueueTime) - getTime(b.enqueueTime);
  }
  return getTime(a.createdAt) - getTime(b.createdAt);
};

export const isOrderCancelledOrRefunded = (order: Order) => !isEmptyOrNil(head(order?.cancellationInfo ?? []))
export const isOrderFulfilled = (order: Order) => order.status === OrderStatus.FULFILLED
export const isOrderReminderLimitExceeded = (order: Order) => order.remindersSentCount >= 1
export const isFutureOrder = (timeToCompare: Date = new Date()) => (order: Order) => {
  if (!order.toPickupTime || order.status === OrderStatus.FULFILLED) return false

  const pickupTime = prop('toPickupTime', order)
  const convertedPickupTime = utcToZonedTime(pickupTime, getClientTimezone())
  // future order = toPickupTimeInLocalTimezone - timeToCompare > 15
  return differenceInMinutes(convertedPickupTime, timeToCompare) > 15
}

type FilterAndSortOrdersParams = {
  status?: OrderStatus | Array<OrderStatus>,
  customFilterFn?: (order: Order) => boolean,
  orders?: Array<Order>,
}
export const filterAndSortOrders = (params: FilterAndSortOrdersParams): Order[] => {
  const { status, customFilterFn = (_: Order) => true, orders } = params;

  const filtered = orders?.filter((order) => {
    // if status isn't available simply check the customFilterFn
    if (!status)
      return customFilterFn(order)

    // else use the status filter and customFilterFn
    const statusFilter = Array.isArray(status) ? status.includes(order.status) : order.status === status
    return statusFilter && customFilterFn(order)
  }) || []
  const filteredList = filtered.sort((a, b,) => sortOrders(a, b))

  // Sort Orders Based on Order Priority List store within local Storage
  const ordersPriorityList = defaultTo([], JSON.parse(`${localStorage.getItem(PRIORITIZED_ORDERS_LIST)}`));
  return reduce((acc: Order[], orderPriorityKey: string) => {
    const orderIndex = acc.findIndex(order => `${order.merchantId}-${order.id}` === orderPriorityKey)
    return orderIndex !== -1 ? move(orderIndex, 0, acc) : acc
  }, filteredList, ordersPriorityList);
}

export const getFulfilledOrders = (params: Partial<FilterAndSortOrdersParams>) =>
  filterAndSortOrders({
    status: [OrderStatus.FULFILLED, OrderStatus.PAID_EXTERNALLY, OrderStatus.VOIDED],
    ...params,
  })

export const getRefundedOrders = (params: Partial<FilterAndSortOrdersParams>) =>
  filterAndSortOrders({
    ...params,
    customFilterFn: isOrderCancelledOrRefunded
  })

export const getFutureOrders = (params: Partial<FilterAndSortOrdersParams>) =>
  filterAndSortOrders({
    ...params,
    customFilterFn: isFutureOrder(new Date()),
  })

export const sortLineItemsByCategory = (order: Order, merchantData: MerchandiseApiResponseType): Array<LineItem> => pipe(
  propOr([], 'lineItems'),
  map((item: LineItem) => {
    const selectedMerchandise: Merchandise | undefined = find(
      propEq('id', item.id),
      pathOr([], ['merchandises'], merchantData)
    );
    const categoryItem: MerchandiseCategory | undefined = find(
      propEq('id', selectedMerchandise?.categoryId),
      pathOr([], ['mercCategories'], merchantData)
    );

    return { ...item, categoryOrder: categoryItem?.displayOrder ?? -1 };
  }),
  sort(ascend(prop('categoryOrder')))
)(order);

export const expandOrderLineItems = (order: Order, expandedLineItems: boolean = false): Order => {
  if (!expandedLineItems) return order

  const mappedLineItemsList = order.lineItems.map((lineItem: LineItem) => {
    const items = []
    for (let i = 0; i < lineItem.quantity; i += 1) {
      items.push({ ...dissoc('quantity', lineItem), itemIndex: i })
    }
    return items;
  })
  return {
    ...order,
    lineItems: flatten(mappedLineItemsList)
  }
}

export const getCurrentOrderTable = (order: Order, merchant?: MerchantWithMerchandises | Partial<Merchant>): string => {
  const currentTable = pipe(
    propOr([], "qrCodes"),
    find(propEq('id', order.tableId)),
    propOr("", "identifier")
  )(merchant || [])
  return currentTable;
}

export const getOrdersMinTimestamp = () => {
  const minDate = new Date();
  // 3am in the day
  minDate.setHours(3, 0, 0, 0);

  if (minDate.getTime() > Date.now()) {
    minDate.setDate(minDate.getDate() - 1)
  }
  const minTimestampOffset = minDate.toUTCString();
  return minTimestampOffset
}