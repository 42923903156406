import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import axios from 'axios';

console.dir(`${process.env.PUBLIC_URL}`);

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.use(resourcesToBackend((language, namespace, callback) => {
  if (namespace !== 'translation') {
    axios.get(`/locales/${namespace}.${language}.json`).then(res => {
      if (res.status === 200) {
        callback(null, res.data);
      }
    }).catch(error => {
      console.log(error.toString())
      callback(error, null)
    })
  }
}))
.init({
  fallbackLng: localStorage.getItem('bos_locale') || 'enUS',
  lng: localStorage.getItem('bos_locale') || 'enUS',
  ns: ['bos'],
  debug: false,
  detection: {
    lookupLocalStorage: 'bos_locale'
  },
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false
  }
  // backend: {
  //   loadPath: 'http://localhost:3000/locales/{{ns}}.{{lng}}.json'
  // }
})

export default i18n;