import React from 'react';
import { useHistory } from 'react-router';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import MerchantPageHeader from '../components/MerchantPageHeader';

const NoneExistPage = () => {
  const title = 'Page not found';
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <div>
      <MerchantPageHeader onBack={onBack} title={title} />
      <Card variant="outlined">
        <CardHeader title="Page not found" />
        <Divider />
        <CardContent>
          <p>
            Sorry that we could not find the page even after we turned our warehouse upside down.
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default NoneExistPage;
