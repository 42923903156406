import React, { useRef, useEffect } from "react";
import { forceCheck } from 'react-lazyload';
import { SwipeableDrawer, Divider, Grid, Toolbar, makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { If, Then } from "react-if";
import classNames from "classnames";

import { ColoredPaper } from "./Papers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .MuiPaper-root': {
        overflow: "unset",
        borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,

        '&:before': {
          content: "''",
          position: "absolute",
          top: -15,
          left: "50%",
          transform: "translateX(-50%)",
          pointerEvents: "none",
          width: "130px",
          height: "6px",
          borderRadius: "50px",
          background: "rgba(255, 255, 255, 0.5)"
        },
      },
      '& .toolbarWrapper': {
        overflow: "auto",
        maxHeight: "500px",
      },

      '& .toolbar': {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& .MuiTypography-root': {
          lineHeight: 1.2,
        },
      },
      '& .content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }
    },
  }),
);


const BottomDrawer = (props: {
  children: React.ReactElement,
  open: boolean,
  setOpen: (open: boolean) => void,
  onClose?: () => void,
  title?: React.ReactNode | string,
  keepMounted?: boolean,
  anchor?: "bottom" | "left" | "top" | "right",
  variant?: 'permanent' | 'persistent' | 'temporary';
  className?: string,
  maxHeightPx?: number,
  fullWidth?: boolean,
}): React.ReactElement => {
  const classes = useStyles()
  const {
    open,
    setOpen,
    onClose,
    title,
    children,
    keepMounted = true,
    anchor = 'bottom',
    className = "",
    variant = 'temporary',
    maxHeightPx,
    fullWidth = false,
  } = props;
  const scrollTimeoutRef = useRef<number>();

  useEffect(() => {
    clearTimeout(scrollTimeoutRef.current);
  }, [])

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event && event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open)
    if (!open && onClose) {
      onClose()
    }
  }

  const onContainerScroll = () => {
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = window.setTimeout(forceCheck, 100);
  }

  return (
    <SwipeableDrawer
      variant={variant}
      ModalProps={{ keepMounted }}
      anchor={anchor}
      open={open}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      className={classNames([classes.drawer], {
        [className]: Boolean(className)
      })}>
      <ColoredPaper
        className="toolbarWrapper"
        elevation={0}
        role="presentation"
        style={{ maxHeight: maxHeightPx ? `${maxHeightPx}px` : 'initial' }}
        onScroll={onContainerScroll}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={fullWidth ? 12 : 8} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
            <If condition={!!title}>
              <Then>
                <Toolbar className="toolbar">
                  <Typography variant="h6" component="div">
                    {title}
                  </Typography>
                </Toolbar>
                <Divider />
              </Then>
            </If>
            {children}
          </Grid>
        </Grid>
      </ColoredPaper>
    </SwipeableDrawer>
  )
}

export default BottomDrawer