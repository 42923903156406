
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { getAuthHeaders, isEmptyOrNil } from "bos_common/src";
import { Merchandise } from "bos_common/src/types/MerchandiseType";
import MerchantPackage from "bos_common/src/types/crm/MerchantPackageType";
import MerchantMembership, { MembershipType } from "bos_common/src/types/crm/MerchantMembershipType";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMerchant } from "../../redux/slice/merchant/merchantSelector";
import { fetchMerchandises } from "../../redux/slice/merchandise/merchandiseActions";
import { getMerchandiseRaw } from "../../redux/slice/merchandise/merchandiseSelector";

import { ADD_CLASSES, ADD_ONE_MEMBERSHIP, ADD_ONE_PACKAGE, ADD_ONE_TRIAL, ADD_PACKAGES_AND_MEMBERSHIPS, EDIT_ONE_MEMBERSHIP, EDIT_ONE_PACKAGE, EDIT_ONE_TRIAL, REMOVE_ONE_MEMBERSHIP, REMOVE_ONE_PACKAGE, REMOVE_ONE_TRIAL, UPDATE_MEMBERSHIPS, UPDATE_PACKAGES, UPDATE_TRIALS } from "./constants";
import { getMembership } from "../../apiServices/packagesService";
import { UserContext } from "bos_common/src/context/UserContext";

type MerchantMenuAction =
  | {
    type: typeof ADD_PACKAGES_AND_MEMBERSHIPS;
    payload: {
      packages: MerchantPackage[],
      memberships: MerchantMembership[],
    };
  }
  | {
    type: typeof ADD_CLASSES,
    payload: Merchandise[]
  }
  | {
    type: typeof UPDATE_PACKAGES,
    payload: MerchantPackage[],
  }
  | {
    type: typeof UPDATE_MEMBERSHIPS,
    payload: MerchantMembership[],
  }
  | {
    type: typeof REMOVE_ONE_PACKAGE,
    payload: number,
  }
  | {
    type: typeof ADD_ONE_PACKAGE | typeof ADD_ONE_MEMBERSHIP | typeof EDIT_ONE_PACKAGE | typeof EDIT_ONE_MEMBERSHIP | typeof EDIT_ONE_TRIAL | typeof ADD_ONE_TRIAL,
    payload: Merchandise
  }
  | {
    type: typeof REMOVE_ONE_PACKAGE | typeof REMOVE_ONE_MEMBERSHIP,
    payload: number,
  }
  | {
    type: typeof REMOVE_ONE_TRIAL,
    payload: number,
  }
  | {
    type: typeof UPDATE_TRIALS,
    payload: MerchantMembership[],
  };

type MenuContextType = {
  classes: Merchandise[];
  packages: MerchantPackage[];
  memberships: MerchantMembership[];
  trials: MerchantMembership[];
}

export type MerchantMenuContextType = {
  state: MenuContextType;
  dispatch: Dispatch<MerchantMenuAction>;
};

type MerchantMenuType = {
  classes: Merchandise[],
  packages: MerchantPackage[],
  memberships: MerchantMembership[],
  trials: MerchantMembership[],
}

const initialContent: MerchantMenuType = {
  classes: [],
  packages: [],
  memberships: [],
  trials: [],
};

export const MerchantMenuContext = createContext<MerchantMenuContextType>({
  state: {
    ...initialContent,
  },
  dispatch: () => undefined,
});

export const useMerchantMenuContext = () => useContext(MerchantMenuContext);

const reducer = (state: MenuContextType, action: any) => {
  switch (action.type) {
    case ADD_CLASSES: {
      return {
        ...state,
        classes: action.payload,
      }
    }
    case ADD_PACKAGES_AND_MEMBERSHIPS:
      return {
        ...state,
        packages: action.payload.packages,
        memberships: action.payload.memberships.filter((item: MerchantMembership) => item.type !== MembershipType.trial || item.price === 0),
        trials:
          action.payload.memberships
            .filter((item: MerchantMembership) =>
              item.type === MembershipType.trial
              || item.price === 0
            )
      };

    case UPDATE_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      }

    case UPDATE_MEMBERSHIPS:
      return {
        ...state,
        memberships: action.payload,
      }

    case UPDATE_TRIALS:
      return {
        ...state,
        trials: action.payload,
      }

    case EDIT_ONE_PACKAGE: {
      const updatedPackages = state.packages.map((data) => {
        if (data.id === action.payload.id) {
          return action.payload;
        } else {
          return data;
        }
      })
      return {
        ...state,
        packages: updatedPackages,
      }
    }

    case EDIT_ONE_MEMBERSHIP: {
      const updatedMemberships = state.memberships.map((data) => {
        if (data.id === action.payload.id) {
          return action.payload;
        } else {
          return data;
        }
      })
      return {
        ...state,
        memberships: updatedMemberships,
      }
    }

    case REMOVE_ONE_PACKAGE: {
      const updatedPackages = state.packages.filter(item => item.id !== action.payload);
      return {
        ...state,
        packages: updatedPackages,
      }
    }

    case REMOVE_ONE_MEMBERSHIP: {
      const updatedMemberships = state.memberships.filter(item => item.id !== action.payload);
      return {
        ...state,
        memberships: updatedMemberships,
      }
    }

    case REMOVE_ONE_TRIAL: {
      const updatedTrials = state.trials.filter(item => item.id !== action.payload);

      return {
        ...state,
        trials: updatedTrials,
      }
    }

    case ADD_ONE_PACKAGE: {
      const updatedPackages = [...state.packages, action.payload];

      return {
        ...state,
        packages: updatedPackages,
      }
    }

    case ADD_ONE_MEMBERSHIP: {
      const updatedMemberships = [...state.memberships, action.payload];

      return {
        ...state,
        memberships: updatedMemberships,
      }
    }

    case ADD_ONE_TRIAL: {
      const updatedTrials = [...state.trials, action.payload];

      return {
        ...state,
        trials: updatedTrials,
      }
    }

    case EDIT_ONE_TRIAL: {
      const updatedTrials = state.trials.map((data) => {
        if (data.id === action.payload.id) {
          return action.payload;
        } else {
          return data;
        }
      })
      return {
        ...state,
        trials: updatedTrials,
      }
    }
    default:
      return state;
  }
};

type MerchantMenuContextProviderProps = {
  children: React.ReactElement;
}

export const MerchantMenuContextProvider = (props: MerchantMenuContextProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialContent);
  const { token } = useContext(UserContext);

  const reduxDispatch = useAppDispatch();
  const merchant = useAppSelector(getMerchant);
  const merchandiseData = useAppSelector(getMerchandiseRaw);

  useEffect(() => {
    if (!merchant || isEmptyOrNil(merchant)) return;

    getMembership(merchant.id, { headers: getAuthHeaders(token) })
      .then((response) => {
        dispatch({
          type: ADD_PACKAGES_AND_MEMBERSHIPS,
          payload: response.data,
        })
      })
    reduxDispatch(fetchMerchandises(merchant.username));
  }, [merchant?.id])

  useEffect(() => {
    if(isEmptyOrNil(merchandiseData)) return;

    dispatch({
      type: ADD_CLASSES,
      payload: merchandiseData?.merchandises,
    })
  }, [merchandiseData])

  return (
    <MerchantMenuContext.Provider value={{ state, dispatch }}>
      {children}
    </MerchantMenuContext.Provider>
  );
};
