import { Card, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { find, omit, prop, propEq, sortBy, sum, values } from 'ramda';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateEverythingElseCategory } from '../../../bos_common/src/services/merchandiseUtils';
import { ItemSale } from '../../../bos_common/src/types/MerchantSalesStatsType';
import BoxWithClick from '../../../components/common/BoxWithClick';
import MerchandiseStatsListing from '../../../components/Stats/MerchandiseStatsListing';
import MerchantSalesChart from '../../../components/Stats/MerchantSalesChart';
import MerchantStatsCard from '../../../components/Stats/MerchantStatsCard';
import MerchantStatsTile from '../../../components/Stats/MerchantStatsTile';
import CategoryStatsTable from '../../../components/Stats/StatsTables/CategoryStatsTable';
import CouponStatsTable from '../../../components/Stats/StatsTables/CouponStatsTable';
import MerchandiseStatsTable from '../../../components/Stats/StatsTables/MerchandiseStatsTable';
import { AppContext } from '../../../context/AppContext';
import { MerchantStatsContext } from '../../../context/MerchantStats/MerchantStatsContext';
import {
  Merchandise,
  MerchandiseApiResponseType,
  MerchandiseCategory,
  MerchantAggregatedSalesStats,
  MerchantStatsApiResponse,
} from '../../../services/models';
import { getClientTimezone, toFixed2 } from '../../../utils';
import {
  ExtractedSalesTabDataType,
  getComparisonStats,
  getPercentageComparison,
} from '../../../utils/merchantStatsUtils';

enum SalesSummaryTabs {
  totalSales = 'totalSales',
  grossSales = 'grossSales',
  netSales = 'netSales',
  netTotal = 'netTotal',
}

interface SalesSummaryProps {
  currentSales: ExtractedSalesTabDataType;
  currentSalesData: MerchantAggregatedSalesStats;
  comparisonSales: ExtractedSalesTabDataType;
  comparisonSalesData: MerchantAggregatedSalesStats;
  loadingMerchandises?: boolean;
  merchandiseData?: MerchandiseApiResponseType;
  currentStatsApiData?: MerchantStatsApiResponse;
}

const SalesSummary = ({
  currentSales,
  comparisonSales,
  currentSalesData,
  comparisonSalesData,
  loadingMerchandises = false,
  merchandiseData,
  currentStatsApiData,
}: SalesSummaryProps) => {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState(SalesSummaryTabs.totalSales);
  const { merchant } = useContext(AppContext);
  // report
  const { statsTimePeriod, setReport } = useContext(MerchantStatsContext);
  const [merchandiseStatsReport, setMerchandiseStatsReport] = useState<Record<string, any>[]>([]);
  const [categoryStatsReport, setCategoryStatsReport] = useState<Record<string, any>[]>([]);
  const [couponStatsReport, setCouponStatsReport] = useState<Record<string, any>[]>([]);

  const getValue = (v: number, isAmount: boolean = true) =>
    v !== 0 ? `${isAmount ? '$ ' : ''}${v.toFixed(2)}` : 'N/A';

  const getIntValue = (v: number) => v !== 0 ? v : 'N/A';

  const [charts, statsCardMap, statsTileMap] = useMemo(() => {
    const {
      totalTotalGrossSales,
      totalGrossSales,
      totalNetSales,
      totalNetTotal,
      totalOrdersFulfilledCount,
      tipsPercentage,
      totalTaxAmount,
      totalTipsAmount,
      avgOrderAmount,
      totalDiscount,
      totalRefundsAmount,
      totalItemsSold,
      totalTransactionAmount,
      totalPointsAmountRedeemed,
    } = currentSales;

    const {
      totalTotalGrossSales: cmpTotalTotalGrossSales,
      totalGrossSales: cmpTotalGrossSales,
      totalNetSales: cmpTotalNetSales,
      totalNetTotal: cmpTotalNetTotal,
    } = comparisonSales;

    const newCharts = {
      [SalesSummaryTabs.totalSales]: {
        key: 'totalTotalGrossSales',
        label: t('Sales_TotalTotalGrossSales'),
        value: getValue(totalTotalGrossSales),
      },
      [SalesSummaryTabs.grossSales]: {
        key: 'totalGrossSales',
        label: t('Sales_GrossSales'),
        value: getValue(totalGrossSales),
      },
      [SalesSummaryTabs.netSales]: {
        key: 'totalNetSales',
        label: t('Sales_NetSales'),
        value: getValue(totalNetSales),
      },
      [SalesSummaryTabs.netTotal]: {
        key: 'totalNetTotal',
        label: t('Sales_NetTotal'),
        value: getValue(totalNetTotal),
      },
    };
    const newStatsCardMap = {
      [SalesSummaryTabs.totalSales]: {
        headTitle: t('Sales_TotalTotalGrossSales'),
        headValue: getValue(totalTotalGrossSales),
        comparisonData: getComparisonStats(
          totalTotalGrossSales,
          cmpTotalTotalGrossSales,
          statsTimePeriod
        ),
      },
      [SalesSummaryTabs.grossSales]: {
        headTitle: t('Sales_GrossSales'),
        headValue: getValue(totalGrossSales),
        comparisonData: getComparisonStats(totalGrossSales, cmpTotalGrossSales, statsTimePeriod),
      },
      [SalesSummaryTabs.netSales]: {
        headTitle: t('Sales_NetSales'),
        headValue: getValue(totalNetSales),
        comparisonData: getComparisonStats(totalNetSales, cmpTotalNetSales, statsTimePeriod),
      },
      [SalesSummaryTabs.netTotal]: {
        headTitle: t('Sales_NetTotal'),
        headValue: getValue(totalNetTotal),
        comparisonData: getComparisonStats(totalNetTotal, cmpTotalNetTotal, statsTimePeriod),
      },
    };
    const newStatsTileMap = {
      totalOrders: {
        header: t('Sales_TotalOrders'),
        value: getIntValue(totalOrdersFulfilledCount),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalOrdersFulfilledCount'
        ),
      },
      avgSale: {
        header: t('Sales_AvgSale'),
        value: getValue(avgOrderAmount),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'avgOrderAmount'
        ),
      },
      taxes: {
        header: t('Sales_Taxes'),
        value: getValue(totalTaxAmount),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalTaxAmount'
        ),
      },
      tips: {
        header: t('Sales_Tips'),
        value: getValue(totalTipsAmount),
        subValue: tipsPercentage > 0 ? `(${tipsPercentage.toFixed(2)}%)` : undefined,
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalTipsAmount'
        ),
      },
      totalDiscount: {
        header: t('Sales_TotalDiscount'),
        value: getValue(totalDiscount),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalDiscount'
        ),
      },
      totalRefunds: {
        header: t('Sales_TotalRefunds'),
        value: getValue(totalRefundsAmount),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalRefundsAmount'
        ),
      },
      oneMPointsAmountRedeemed: {
        header: t('Sales_PointsRedeemedDollars'),
        value: getValue(totalPointsAmountRedeemed),
      },
      transactionFees: {
        header: t('Sales_TransactionFees'),
        value: getValue(totalTransactionAmount),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalTransactionAmount'
        ),
      },
      totalItemsSold: {
        header: t('Sales_TotalItemsSold'),
        value: getIntValue(totalItemsSold),
        comparisonPercentage: getPercentageComparison(
          currentSales,
          comparisonSales,
          'totalItemsSold'
        ),
      },
    };

    return [newCharts, newStatsCardMap, newStatsTileMap];
  }, [currentSales, comparisonSales, statsTimePeriod]);

  useEffect(() => {
    const render = () => {
      const sortMercCategories: MerchandiseCategory[] = [
        ...sortBy(prop('createdAt'), merchant?.merchantData?.mercCategories),
        generateEverythingElseCategory(merchant),
      ];

      const sortMerchandises: Merchandise[] = sortBy(
        prop('createdAt'),
        merchant?.merchantData?.merchandises
      );
      sortMerchandises.push(
        ...values(
          omit(
            sortMerchandises.map((o) => o.id),
            currentSalesData.stats.reduce<Record<string, any>>((o, item) => {
              return {
                ...o,
                ...item.salePerMerchandise,
              };
            }, {})
          )
        )
      );

      const titleRow = [
        '',
        '',
        'Total topline',
        ...Array(8).fill(''),
        'Category sales',
        ...Array(sortMercCategories.length * 2 - 1).fill(''),
        'Product sales',
        ...Array(sortMerchandises.length - 1).fill(''),
      ];

      const details = currentSalesData.stats.map((item) => {
        const currentTimezone = getClientTimezone();
        const zoneDate = utcToZonedTime(
          new Date(item.createdAt),
          merchant?.timeZone || currentTimezone
        );
        const itemSales: ItemSale[] = values(item.salePerMerchandise).map((o: ItemSale) => {
          const mercCategories = find(propEq('id', o.categoryId), sortMercCategories);
          return {
            ...o,
            categoryId: mercCategories?.id ?? -1,
          };
        });

        const totalTips = toFixed2(Number(item.totalTips));
        const totalTax = toFixed2(Number(item.totalTax));
        const totalGrossSales = toFixed2(item.totalGrossSales);
        const totalTotalGrossSales = toFixed2(item.totalTotalGrossSales);

        let row = {
          Date: format(zoneDate, `MM/dd/yyyy${statsTimePeriod === 'day' ? ' HH' : ''}`),
          Day: format(zoneDate, 'EEEE'),
          'Total gross sales $': `$${totalTotalGrossSales}`,
          'Total tips': `$${totalTips}`,
          'Total tax': `$${totalTax}`,
          'Gross sales $': `$${totalGrossSales}`,
          Discount: `$${item.totalDiscount.toFixed(2)}`,
          Refund: `$${Number(item.totalRefund).toFixed(2)}`,
          'Net sales': `$${item.totalNetSales.toFixed(2)}`,
          '$ / order': `$${(Number(totalGrossSales) / item.totalOrders).toFixed(2)}`,
          'Total Order': item.totalOrders,
        };

        sortMercCategories.forEach((o) => {
          const filterItemSales = itemSales.filter((oo) => oo.categoryId === o.id);
          row = {
            ...row,
            [`${o.name} sales $`]: filterItemSales.length
              ? `$${sum(filterItemSales.map((oo) => oo.sale))}`
              : '',
            [`# of ${o.name}`]: filterItemSales.length
              ? sum(filterItemSales.map((oo) => oo.quantity))
              : '',
          };
        });

        sortMerchandises.forEach((o, i) => {
          const merchandise = find(propEq('id', o.id), itemSales);
          row = { ...row, [` ${o.name} `]: merchandise?.sale ? `$${merchandise?.sale}` : '' };
        });

        return row;
      });

      const cardReport = [
        ...values(statsCardMap).map((o: Record<string, any>) => ({
          name: o.headTitle,
          value: o.headValue,
        })),
        ...values(statsTileMap).map((o: Record<string, any>) => ({
          name: o.header,
          value: o.value,
        })),
      ];

      return {
        report: details.length
          ? [
              ...cardReport,
              { name: ' ' },
              ...merchandiseStatsReport,
              { name: ' ' },
              ...categoryStatsReport,
              { name: ' ' },
              ...couponStatsReport,
            ]
          : [],
        reportDetails: {
          titleRow,
          table: details,
        },
      };
    };
    setReport({ render: currentSalesData.stats.length ? render : undefined });
  }, [statsCardMap, statsTileMap, currentSalesData, merchandiseStatsReport, categoryStatsReport, couponStatsReport]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={2}>
            <MerchantSalesChart
              currentWeekSalesData={currentSalesData}
              lastWeekSalesData={comparisonSalesData}
              timeZone={merchant?.timeZone}
              dataKey={charts[selectedCard].key}
              chartLabel={charts[selectedCard].label}
              chartTitle={charts[selectedCard].value}
            />
          </Card>
        </Grid>

        <Grid item md={3} sm={6} xs={6}>
          <BoxWithClick
            isSelected={selectedCard === SalesSummaryTabs.totalSales}
            handleClick={() => setSelectedCard(SalesSummaryTabs.totalSales)}
          >
            <MerchantStatsCard
              {...statsCardMap[SalesSummaryTabs.totalSales]}
              infoTooltip={t('Sales_TotalTotalGrossSales_InfoTooltip')}
            />
          </BoxWithClick>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BoxWithClick
            isSelected={selectedCard === SalesSummaryTabs.grossSales}
            handleClick={() => setSelectedCard(SalesSummaryTabs.grossSales)}
          >
            <MerchantStatsCard
              {...statsCardMap[SalesSummaryTabs.grossSales]}
              infoTooltip={t('Sales_GrossSales_InfoTooltip')}
            />
          </BoxWithClick>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BoxWithClick
            isSelected={selectedCard === SalesSummaryTabs.netSales}
            handleClick={() => setSelectedCard(SalesSummaryTabs.netSales)}
          >
            <MerchantStatsCard
              {...statsCardMap[SalesSummaryTabs.netSales]}
              infoTooltip={t('Sales_NetSales_InfoTooltip')}
            />
          </BoxWithClick>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <BoxWithClick
            isSelected={selectedCard === SalesSummaryTabs.netTotal}
            handleClick={() => setSelectedCard(SalesSummaryTabs.netTotal)}
          >
            <MerchantStatsCard
              {...statsCardMap[SalesSummaryTabs.netTotal]}
              infoTooltip={t('Sales_NetTotal_InfoTooltip')}
            />
          </BoxWithClick>
        </Grid>

        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.totalOrders} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.avgSale} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.taxes} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.tips} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.totalDiscount} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.totalRefunds} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.oneMPointsAmountRedeemed} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.transactionFees} />
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <MerchantStatsTile {...statsTileMap.totalItemsSold} />
        </Grid>
      </Grid>

      {/* row 2 */}
      <Grid container item spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <MerchandiseStatsListing loading={loadingMerchandises}>
            <MerchandiseStatsTable
              merchandiseList={currentSales.merchandiseSales}
              comparisonList={[]}
              // comparisonList={comparisonSales.merchandiseSales}
              setReport={setMerchandiseStatsReport}
            />
          </MerchandiseStatsListing>
        </Grid>
        <Grid item xs={12} md={6}>
          <MerchandiseStatsListing loading={loadingMerchandises}>
            <CategoryStatsTable
              merchandiseData={merchandiseData}
              merchantStats={currentSalesData}
              // comparisonStats={comparisonSalesData}
              setReport={setCategoryStatsReport}
            />
          </MerchandiseStatsListing>
        </Grid>
        <Grid item xs={12} md={6}>
          <MerchandiseStatsListing loading={loadingMerchandises}>
            <CouponStatsTable
              currentStatsApiData={currentStatsApiData}
              setReport={setCouponStatsReport}
            />
          </MerchandiseStatsListing>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesSummary;
