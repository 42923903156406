import React, { useState } from 'react'
import { If, Then, Else } from 'react-if'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

// src
import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType'
import AppDrawer from 'bos_common/src/components/AppDrawer'
import { Merchant, MerchantType } from 'bos_common/src/types/MerchantType'

import { StyledTab, StyledTabPanel, StyledTabs } from '../../StyledTab'
import { a11yTabProps, isEmptyOrNil } from '../../../utils'
import CustomerPersonalInformation from './CustomerPersonalInformation'
import CustomerActivityTab from './CustomerActivityTab'
import CustomerMembershipManagement from './MembershipManagement/CustomerMembershipManagement'
import ProductCustomerActivityTab from './ProductCustomerActivityTab'
import { isMerchantServicesType } from '../../../utils/merchantUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      maxWidth: '600px',

      '& .drawerTitle': {
        textAlign: "left",
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(2)
      }
    }
  })
)
interface MerchantCustomerTabsProps {
  open: boolean,
  setOpen: (b: boolean) => void,
  customer?: MerchantCustomer,
  merchant: Merchant,
  onCancel: () => void,
  editCustomer: (_: MerchantCustomer) => void,
}

const MerchantCustomerTabs = (props: MerchantCustomerTabsProps) => {
  const classes = useStyles();
  const { customer, open, setOpen, editCustomer, merchant } = props;
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('info');


  if (!customer) return null;

  const handleClose = () => {
    props.onCancel()
    setSelectedTab('info')
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };
  return (
    <AppDrawer
      anchor='right'
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title={customer.displayName}
      drawerContainerClass={classes.drawer}
    >
      <If condition={open}>
        <div>
          <If condition={isMerchantServicesType(merchant)}>
            <Then>
              <StyledTabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="merchant-customer-tabs">
                <StyledTab label={t("Customers_PersonalInfo")} value="info" {...a11yTabProps("info")} />
                <StyledTab label={t("Customers_Membership")} value="membership" {...a11yTabProps("membership")} />
                <StyledTab label={t("Customers_ActivityLog")} value="log" {...a11yTabProps("log")} />
              </StyledTabs>
            </Then>
            <Else>
              <StyledTabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="merchant-customer-tabs">
                <StyledTab label={t("Customers_PersonalInfo")} value="info" {...a11yTabProps("info")} />
                <StyledTab label={t("Customers_ActivityLog")} value="log" {...a11yTabProps("log")} />
              </StyledTabs>
            </Else>
          </If>

          <StyledTabPanel value={selectedTab} tabKey="info">
            <CustomerPersonalInformation customer={customer} editCustomer={editCustomer} />
          </StyledTabPanel>

          <If condition={isMerchantServicesType(merchant)}>
            <StyledTabPanel value={selectedTab} tabKey="membership">
              <CustomerMembershipManagement
                merchant={props.merchant}
                customer={customer}
              />
            </StyledTabPanel>
          </If>

          <StyledTabPanel value={selectedTab} tabKey="log">
            <If condition={!isEmptyOrNil(customer)}>
              <Then>
                <If condition={merchant.type === MerchantType.products}>
                  <Then>
                    <ProductCustomerActivityTab customer={customer} />
                  </Then>
                  <Else>
                    <CustomerActivityTab customer={customer} />
                  </Else>
                </If>
              </Then>
            </If>
          </StyledTabPanel>
        </div>
      </If>
    </AppDrawer>
  )
}

export default MerchantCustomerTabs