import React, { useState } from 'react'
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

import { User } from '../../services/models';
import OutlinedFab from '../common/OutlinedFab';
import PassCodeConfirmationDialog from './PassCodeConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .outlined-fab": {
        marginBottom: theme.spacing(2),
      },
    }
  })
)

type OrderRefundFabProps = {
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  onSuccessAuth: (user: User, token: string) => void;
  outlined?: boolean;
  className?: string
}
const OrderRefundFab = (props: OrderRefundFabProps) => {
  const classes = useStyles();
  const {
    onClick,
    label = "Confirm",
    disabled = false,
    onSuccessAuth,
    outlined = false,
    className = ''
  } = props;

  const [isConfirmationDialogOpen, toggleConfirmationDialog] = useState<boolean>(false)
  const FabComponent = outlined ? OutlinedFab : Fab;

  const handleOnClick = () => {
    onClick && onClick();
    toggleConfirmationDialog(true);
  }

  const fabClasses = classNames(className, {
    "outlined-fab": outlined
  })

  return (
    <div className={classes.root}>
      <FabComponent
        variant='extended'
        onClick={handleOnClick}
        disabled={disabled}
        className={fabClasses}
        {...(!outlined && { color: 'primary' })}
      >
        {label}
      </FabComponent>
      <PassCodeConfirmationDialog
        title='Input passcode to confirm'
        isOpen={isConfirmationDialogOpen}
        onClose={() => toggleConfirmationDialog(false)}
        onSuccessAuth={onSuccessAuth}
      />
    </div>
  )
}

export default OrderRefundFab;