import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core'

// src
import { MerchantAggregatedSalesStats } from '../../../services/models'
import renderPrice from '../../../bos_common/src/components/Price'

interface SalesReportProps {
  currentSalesData: MerchantAggregatedSalesStats
}

const SalesReportTable = (props: { aggregatedStats: MerchantAggregatedSalesStats }) => {
  const { aggregatedStats } = props;
  const { t } = useTranslation();

  const renderValue = (value: number, color: string = 'primary') => {
    return <Typography color={color}>{renderPrice(value)}</Typography>
  }

  const TableRows = [
    {
      label: t("BillingStatements_TotalOrders"),
      value: <Typography color="primary">{aggregatedStats.totalOrdersFulfilled}</Typography>
    },
    {
      label: t("BillingStatements_Taxes"),
      value: renderValue(aggregatedStats.orderTaxTotal)
    },
    {
      label: t("BillingStatements_Tips"),
      value: renderValue(aggregatedStats.orderTipsTotal)
    },
    {
      label: t("BillingStatements_GrossSales"),
      value: renderValue(aggregatedStats.totalGrossSales)
    },
    {
      label: t("BillingStatements_Refunds"),
      value: renderValue(aggregatedStats.orderRefundsTotal, 'error')
    },
    {
      label: t("BillingStatements_DiscountRedeemed"),
      value: renderValue(aggregatedStats.totalDiscount, 'error')
    },
    {
      label: t("BillingStatements_RewardsRedeemed"),
      value: renderValue(aggregatedStats.totalPointsRedeemed / 100, 'error')
    },
    {
      label: t("BillingStatements_NetSales"),
      value: renderValue(aggregatedStats.totalNetSales)
    },
    {
      label: `${t("BillingStatements_TransactionFees")} (2.9%+30¢)`,
      value: renderValue(aggregatedStats.orderTransactionAmountTotal, 'error')
    },
    {
      label: <strong>{t("BillingStatements_NetTotal")}</strong>,
      value: renderValue(aggregatedStats.totalNetTotal)
    },
  ]

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="stats table" size="medium">
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography><b>{t("MerchantService_Reports")}</b></Typography></TableCell>
            {/* TODO: Add functionality to Download PDF of Reports Table */}
            {/* <TableCell component="th" align="right"><Button color="primary" variant="text" startIcon={<Download />}>Download PDF</Button></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {TableRows.map(row => (
            <TableRow key={`row-${row.label}`}>
              <TableCell component="th" scope="row">{row.label}</TableCell>
              <TableCell align="right" >{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  )
}

const SalesReport = ({
  currentSalesData,
}: SalesReportProps) => {

  return (
    <div>
      <SalesReportTable aggregatedStats={currentSalesData} />
    </div>
  )
}

export default SalesReport