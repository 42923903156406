import React from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, Divider, Typography, useTheme
} from '@material-ui/core';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import { ExpandMore } from '@material-ui/icons';

// src
import { MerchantSalesStats } from 'bos_common/src/types/MerchantSalesStatsType';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';

import { Order, User } from '../../services/models';
import { formatDate, isEmptyOrNil, userIsAdmin } from '../../utils';
import RenderOrderListTable from './RenderOrderListTable';

type OrdersHistoryData = MerchantSalesStats & {
  orders: Array<Order>;
};

type RenderOrdersHistoryProps = OrdersHistoryData & {
  user?: User;
  timeZone: string;
  setOrder: (order: Order) => void;
  setOpen: (bool: boolean) => void;
  showOrdersHistory: (timePoint: Date) => void;
  loading?: boolean
};

const RenderOrdersHistory = (props: RenderOrdersHistoryProps) => {
  const { user, timePoint, timeZone, orders, totalOrders, setOrder, setOpen, showOrdersHistory, loading = false } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const handleOnChange = (e: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
    if (expanded) {
      showOrdersHistory(timePoint);
    }
  };

  const dateStr = formatDate(utcToZonedTime(timePoint, timeZone), 'MM/dd/yyyy');

  return (
    <Accordion key={timePoint.toUTCString()} onChange={handleOnChange}>
      <AccordionSummary
        style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
        expandIcon={<ExpandMore color="primary" />}
        aria-controls="panel-content"
      >
        <>
          <If condition={userIsAdmin(user)}>
            <Then>
              <Typography>
                {dateStr} - {totalOrders} {t('Orders')}
              </Typography>
            </Then>
            <Else>
              <Typography>{dateStr}</Typography>
            </Else>
          </If>
        </>
      </AccordionSummary>

      <AccordionDetails>
        <Divider />
        <SimpleLoader loading={loading} />

        <If condition={!isEmptyOrNil(orders)}>
          <RenderOrderListTable
            orders={orders}
            handleOrderClick={(order) => {
              setOrder(order);
              setOpen(true);
            }}
          />
        </If>
      </AccordionDetails>
    </Accordion>
  );
};

export default RenderOrdersHistory