import React, { useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Fab } from '@material-ui/core';
import { User } from '../../bos_common/src/types/UserTypes';
import { AppContext } from '../../context/AppContext';
import StaffPasscodeInput from '../common/StaffPasscodeInput';
import { useTranslation } from 'react-i18next';

export type RenderConfirmationDialogProps = {
  onSuccessAuth: (user: User, token: string) => void,
  isOpen: boolean,
  title?: string,
  onClose: () => void,
}

export const PassCodeConfirmationDialog = (props: RenderConfirmationDialogProps) => {
  const { onSuccessAuth, isOpen, title, onClose } = props
  const { merchant } = useContext(AppContext)
  const { t } = useTranslation();

  if (!merchant) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <StaffPasscodeInput
          onSuccessAuth={(user, token) => {
            onSuccessAuth(user, token);
            onClose();
          }}
          merchant={merchant}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 20, paddingTop: 20 }}>
        <Fab
          variant='extended'
          color='secondary'
          size="medium"
          onClick={onClose}
        >
          {t("Cancel")}
        </Fab>
      </DialogActions>
    </Dialog>
  )
}

export default PassCodeConfirmationDialog;
