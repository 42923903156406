import React, { useContext } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import { useFormContext } from 'react-hook-form';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import { AddAPhoto, ChevronLeft, ChevronRight, Close } from '@material-ui/icons';

import ImageUploadButton from '../../bos_common/src/components/ImageUploadButton';

import { uploadThumbnail } from 'bos_common/src';
import axios from 'bos_common/src/services/backendAxios';

import 'react-alice-carousel/lib/alice-carousel.css';
import { AppContext } from '../../context/AppContext';
import { NotificationSeverity } from '../../types/NotificationSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .remove-photo-btn': {
        position: 'absolute',
        top: 3,
        right: 3,
      },
      '& .carousel-item': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(2),
        alignItems: 'center',
        height: 316,
        position: 'relative',
        '& .add-photo-icon': {
          marginRight: theme.spacing(1),
        }
      },
      '& .carousel-photo': {
        width: 300,
        height: 300,
        objectFit: 'cover',
      },
      '& .carousel-prev-btn': {
        position: 'absolute',
        left: 3,
        top: '40%',
      },
      '& .carousel-nex-button': {
        position: 'absolute',
        right: 3,
        top: '40%',
      },
    },
  })
);

type MediaCarouselProps = {
  photosData: string[],
  videosData: string[],
  setLoading?: (_: boolean) => void;
}
const MediaCarousel = (props: MediaCarouselProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    photosData = [],
    videosData = [],
    setLoading
  } = props;

  const { triggerNotification } = useContext(AppContext);
  const { setValue, register } = useFormContext();

  const [, executeGetUploadUrl] = useAxios(
    { url: '/upload-url', method: 'POST' },
    { manual: true }
  );

  const handleDragStart = (e: any) => e.preventDefault();

  const onRemovePhoto = (photo: string) => {
    const newPhotos = photosData ? [...photosData] : [];
    const index = newPhotos.indexOf(photo)
    if (index > -1) {
      newPhotos.splice(index, 1)
      register('photos');
      setValue('photos', newPhotos, { shouldDirty: true })
    }
  }

  const onRemoveVideo = (video: string) => {
    const newVideos = videosData ? [...videosData] : [];
    const index = newVideos.indexOf(video)
    if (index > -1) {
      newVideos.splice(index, 1)
      register('videos')
      setValue('videos', newVideos, { shouldDirty: true })
    }
  }

  const onRemoveMedia = (mediaItem: string, mediaType: 'photos' | 'videos') => {
    if (mediaType === 'photos') {
      onRemovePhoto(mediaItem);
    } else {
      onRemoveVideo(mediaItem);
    }
  }

  const photos = photosData.map((data) => (
    <div className="carousel-item" key={data}>
      <img
        className="carousel-photo"
        src={data}
        onDragStart={handleDragStart}
        alt="menu item"
      />

      <Fab
        size='small'
        className="remove-photo-btn"
        onClick={() => { onRemoveMedia(data, 'photos') }}>
        <Close />
      </Fab>
    </div>
  ));

  const videos = videosData.map((data) => (
    <div className="carousel-item" key={data}>
      <video width={300} height={300} controls>
        <source src={data} />
      </video>
      <Fab
        size='small'
        className="remove-photo-btn"
        onClick={() => { onRemoveMedia(data, 'videos') }}>
        <Close />
      </Fab>
    </div>
  ));

  const RenderPrevButton = () => (
    <Fab size="small" className="carousel-prev-btn">
      <ChevronLeft />
    </Fab>
  )

  const RenderNextButton = () => (
    <Fab size="small" className="carousel-nex-button">
      <ChevronRight />
    </Fab>
  )

  const onAddMedia = (data: File[]) => {
    setLoading && setLoading(true);

    executeGetUploadUrl()
      .then((response) => {
        if (response.status === 200) {
          const f0 = data[0];
          const mediaType = f0.type.split('/')[0];
          const { url, uploadUrl } = response.data;
          if (url) {
            axios
              .request<{ url: string }>({
                method: 'put',
                url: uploadUrl,
                data: f0,
                headers: { 'content-type': f0.type },
              })
              .then(() => {
                if (mediaType === 'video') {
                  const newVideos = videosData ? [...videosData] : [];
                  newVideos.push(url);
                  setValue('videos', newVideos, { shouldDirty: true });

                  triggerNotification(true, `Video upload successfully`, NotificationSeverity.SUCCESS, true)
                } else {
                  uploadThumbnail(url, f0)
                  const newPhotos = photosData ? [...photosData] : [];
                  newPhotos.push(url);
                  setValue('photos', newPhotos, { shouldDirty: true });
                  setLoading && setLoading(false);
                  triggerNotification(true, `Photo upload successfully`, NotificationSeverity.SUCCESS, true)
                }


                setLoading && setLoading(false);
              });
          }
        }
      });
  }

  const mediaItems = [...videos, ...photos];

  mediaItems.push(
    <div className="carousel-item">
      <ImageUploadButton
        accepts={'image/*|video/*'}
        multiple
        handleFiles={(data: File[]) => onAddMedia(data)}
      >
        <Fab variant="extended" component="span">
          <AddAPhoto className="add-photo-icon" />
          {t('Add_PhotoVideo')}
        </Fab>
      </ImageUploadButton>
    </div>
  );

  return (
    <div className={classes.root}>
      <AliceCarousel
        autoHeight
        items={mediaItems}
        mouseTracking
        renderPrevButton={RenderPrevButton}
        renderNextButton={RenderNextButton}
      />
    </div>
  );
};

export default MediaCarousel