import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import renderPrice from './Price';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
  }),
)

export default function PriceSummary(props: {
  currency: string,
  amount: number,
  prefix?: string,
  postfix?: string,
}): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.prefix}
      {" "}
      {renderPrice(parseFloat(`${props.amount}`), props.currency.toLocaleUpperCase())}
      {" "}
      {props.postfix}
    </div>
  )
}