import React, { useContext } from 'react';

import { MerchantType } from 'bos_common/src/types/MerchantType';
import { AppContext } from '../../context/AppContext';

import SideMenuProducts from './SideMenuProducts';
import SideMenuServices from './SideMenuServices';

const SideMenuSwitcher = (): JSX.Element => {
  const { merchant } = useContext(AppContext);

  switch (merchant?.type) {
    case MerchantType.services:
      return (<SideMenuServices />);

    case MerchantType.products:
    default:
      return (<SideMenuProducts />);
  }
}

export default SideMenuSwitcher;
