import React from "react";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { If } from "react-if";

import { isEmptyOrNil } from "bos_common/src";
import classNames from "classnames";
import SimpleLoader from "bos_common/src/components/SimpleLoader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        borderRadius: theme.spacing(2.5),
        '& .MuiLinearProgress-root': {
          width: '100% !important',
        },
      },

      "& .dialogHeader": {
        textAlign: "center",
        position: "relative",
        padding: theme.spacing(2.7, 2),
      },

      "& .crossButton": {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: theme.spacing(1),
      },

      "& .dialogFooter": {
        justifyContent: "center",
        gridGap: theme.spacing(2),
        padding: theme.spacing(2.5),
      }
    },
  })
);

interface CustomDialogProps extends DialogProps {
  open: boolean;
  setOpen: (_: boolean) => void;
  title: string;
  children: React.ReactElement;
  footerActions?: React.ReactElement;
  isLoading?: boolean;
}

const CustomDialog = (props: CustomDialogProps) => {
  const { open, setOpen, title, children, footerActions, className, isLoading = false, ...rest } = props;

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  }

  const dialogClassNames = classNames(classes.root, {
    [className!]: !isEmptyOrNil(className)
  })

  return (
    <Dialog {...rest} className={dialogClassNames} onClose={handleClose} keepMounted={false} open={open}>
      <DialogTitle className="dialogHeader">
        <Typography variant="h6" lineHeight={1.3} fontWeight={700} component="span" textAlign="center">{title}</Typography>

        <IconButton className="crossButton" onClick={handleClose}>
          <Close color="primary" />
        </IconButton>
      </DialogTitle>
      <SimpleLoader loading={isLoading} />
      <Divider />

      <DialogContent sx={{ padding: 1 }}>
        {children}
      </DialogContent>

      <If condition={!isEmptyOrNil(footerActions)}>
        <DialogActions className="dialogFooter">
          {footerActions}
        </DialogActions>
      </If>
    </Dialog>
  )
}

export default CustomDialog;