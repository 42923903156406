import { isAfter } from "date-fns";
import { Merchandise } from "../types/MerchandiseType";
import { CouponValueType, ItemType, MerchantCoupon } from "../types/MerchantCouponType";
import { MerchantVoucher } from "../types/MerchantVoucherType";
import { isMerchandiseAvailable } from "./merchandiseUtils";

export const isCouponValid = (coupon?: MerchantCoupon | MerchantVoucher): boolean => {
  if (!coupon || !coupon.live) {
    return false;
  }

  if (coupon.limit && coupon.usedCount > coupon.limit) {
    return false;
  }

  if (coupon.valueType === CouponValueType.PERCENTAGE && coupon.value > 1) {
    return false;
  }

  const now = new Date();
  if (now < coupon.startDate || now > coupon.endDate) {
    return false;
  }

  return true;
};

export const isCouponValidOnMerchandise = (merchandise: Merchandise, coupon?: MerchantCoupon, includeFreeItems?: boolean) => {
  const result = getItemsEligibleForCoupon([merchandise], coupon, includeFreeItems);
  return (result && result.length > 0);
}

export function getCouponFreeMerchandises<D extends Merchandise>(merchandises: D[], coupon?: MerchantCoupon) {
  if (!coupon || !isCouponValid(coupon)) {
    return null;
  }

  if (coupon.freeItems) {
    const mercCopy = merchandises.slice();
    mercCopy.sort((a, b) => a.price > b.price ? 1 : -1);
    const match = mercCopy.filter((item) => {
      const result = coupon.freeItems?.filter((aitem) => {
        if (aitem.type === ItemType.CATEGORY) {
          return aitem.id === item.categoryId;
        } else if (aitem.type == ItemType.MERCHANDISE) {
          return aitem.id === item.id;
        }
        return false;
      });
      return (result?.length || 0) > 0
    });
    return match;
  }

  return null;
}

export function getItemsEligibleForCoupon<D extends Merchandise>(merchandises: D[], coupon?: MerchantCoupon | MerchantVoucher, includeFreeItems?: boolean) {
  if (!coupon || !isCouponValid(coupon)) {
    return null;
  }

  const couponItems = [
    ...(coupon?.affectedItems ?? []),
    ...(('freeItems' in coupon) && includeFreeItems && coupon.freeItems
      ? coupon.freeItems
      : []
    )
  ]

  if (couponItems.length > 0) {
    const eligibleItems = merchandises.filter((item: Merchandise) => {
      const match = couponItems?.filter((aitem) => {
        if (aitem.type === ItemType.CATEGORY) {
          return aitem.id === item.categoryId;
        } else if (aitem.type == ItemType.MERCHANDISE) {
          return aitem.id === item.id;
        }
        return false;
      });
      return (match?.length || 0) > 0;
    });
    return eligibleItems;
  }

  return merchandises;
}

export const isValidExpiryDate = (endDate: Date) => {
  if (!endDate) return false;

  const today = new Date();
  const _endDate = new Date(endDate)
  return _endDate.getFullYear() <= 9000 && isAfter(_endDate, today)
}

export const filterComboByItemsAvailability = (coupons: MerchantCoupon[], merchandises: Merchandise[]): MerchantCoupon[] => {
  const merchandiseTable: {[key: string]: Merchandise} = {};
  merchandises.forEach((item) => {
    merchandiseTable[item.id] = item;
  });

  const filteredCoupons = coupons
    .filter((item) => {
      return item.affectedItems?.map((item) => merchandiseTable[item.id])
        .every((item) => {
          if (!item) {
            return false;
          }

          return isMerchandiseAvailable(item);
        });
    });

  return filteredCoupons;
}