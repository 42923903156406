export const MERCHANT_EXPENSE_LEVELS = [
  {
    label: "Under $10",
    value: 1,
  },
  {
    label: "$10-$30",
    value: 10,
  },
  {
    label: "$30-$60",
    value: 30,
  },
  {
    label: "$60+",
    value: 60,
  },
];

export enum LOCALSTORAGE_APP_KEYS {
  USER_PHONE_NUMBER = "user-phone-number",
  USER_DISPLAY_NAME = "user-display-name",
  USER_EMAIL = "user-email",
  USER_ADDRESS = "user-address",
  SAN_MATEO_MARKETING_TEST_EXPERIMENT = "san-mateo-marketing-test-experiment",
  RAFFLE = "raffle",
  RAFFLE_MERCHANT = "raffle_merchant",
  DEV_EMAIL_LOGIN = 'dev-email-login',
}

export const DUMMY_PHONE_NUMBER = "+11234567890";
export const DUMMY_PHONE_PASSCODE = "1234";