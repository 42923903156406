import React from 'react';
import { MerchantStory } from 'bos_common/src/types/MerchantStoryType';
import { Box, Card, CardContent, CardMedia, Grid, Typography, useTheme } from '@material-ui/core';
import { pathOr } from 'ramda';
import { useHistory } from 'react-router';
import StoryCTA from './StoryCTA';

interface StorySummaryProps {
  story: MerchantStory;
}

const StorySummary = (props: StorySummaryProps) => {
  const { story } = props;
  const history = useHistory();
  const theme = useTheme();

  const handleStoryClick = (story: MerchantStory) => {
    history.push(`/${story.merchant.username}/stories/${story.id}`);
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={`story_${story.id}`} p={1}>
      <Card onClick={() => handleStoryClick(story)} sx={{ cursor: 'pointer' }}>
        {pathOr(null, ['photos', 0], story) &&
          <CardMedia
            component="img"
            alt={story.title}
            height="140"
            image={pathOr(null, ['photos', 0], story)}
          />}
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {story.title}
          </Typography>
          <Typography gutterBottom variant="subtitle2" component="div">
            {story.subtitle}
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <StoryCTA story={story} />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default StorySummary;