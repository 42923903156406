import React from "react"
import { useTranslation } from "react-i18next";
import { Else, If, Then } from "react-if";
import { Typography } from "@material-ui/core";

import ConfirmationAlert from "bos_common/src/components/ConfirmationAlert"
import { SubscriptionType } from "../../../Calendar/types";

type ConfirmSubscriptionAlertProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  subscriptionType: SubscriptionType;
  isConfirmDisabled?: boolean;
}

const ConfirmSubscriptionAlert = (props: ConfirmSubscriptionAlertProps) => {
  const { isOpen, handleCancel, handleConfirm, subscriptionType, isConfirmDisabled=false } = props;
  const { t } = useTranslation();

  return (
    <ConfirmationAlert
      isOpen={isOpen}
      title={"Confirm"}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      isConfirmDisabled={isConfirmDisabled}
      cancelLabel={t('Cancel')}
      confirmLabel={t('Confirm')}>
      <If condition={subscriptionType === SubscriptionType.PACKAGE}>
        <Then>
          Do you want to add this package to this customer?
        </Then>
        <Else>
          Do you want to add this membership to this customer?
        </Else>
      </If>
    </ConfirmationAlert>
  )
}

export default ConfirmSubscriptionAlert;