import React, { useEffect } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SortDirection,
  TableSortLabel,
} from '@material-ui/core';
import { If, Then, Else } from 'react-if';
import { find, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { isEmptyOrNil } from '../../../utils';
import {
  getPercentageComparison,
  MerchandiseStatsList,
  MerchandiseWithStats,
} from '../../../utils/merchantStatsUtils';

import useStyles from './styles';
import PercentageComparison from '../PercentageComparison';

type MerchandiseStatsTableProps = {
  merchandiseList: MerchandiseStatsList;
  comparisonList?: MerchandiseStatsList;
  setReport: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
};

enum TableColumnName {
  NAME = 'name',
  SALES = 'sales',
  QUANTITY = 'quantity',
}

type SortOrder = 'desc' | 'asc';

const MerchandiseStatsTable = ({
  merchandiseList = [],
  comparisonList = [],
  setReport,
}: MerchandiseStatsTableProps) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState<TableColumnName>(TableColumnName.SALES);
  const [sortOrder, setSortOrder] = React.useState<SortOrder>('desc');
  const { t } = useTranslation();

  const onSort = (property: TableColumnName) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (
    a: MerchandiseWithStats,
    b: MerchandiseWithStats,
    orderBy: TableColumnName
  ): number => {
    const idOrder = a.id > b.id ? -1 : 1;
    switch (orderBy) {
      case TableColumnName.NAME:
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return idOrder;
      case TableColumnName.QUANTITY:
        return b.quantity === a.quantity ? idOrder : b.quantity - a.quantity;
      case TableColumnName.SALES:
      default:
        return b.sale === a.sale ? idOrder : b.sale - a.sale;
    }
  };

  const getComparator = (sortOrder: string, orderBy: TableColumnName) => {
    return sortOrder === 'desc'
      ? (a: MerchandiseWithStats, b: MerchandiseWithStats) => descendingComparator(a, b, orderBy)
      : (a: MerchandiseWithStats, b: MerchandiseWithStats) => -descendingComparator(a, b, orderBy);
  };

  type Comparator<T> = (value: T, other: T) => number;

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (arr: MerchandiseStatsList, comparator: Comparator<MerchandiseWithStats>) => {
    return arr.sort((a, b) => comparator(a, b));
  };

  const RenderComparison = ({ item }: { item: MerchandiseWithStats }) => {
    const comparisonItem = find(propEq('id', item.id), comparisonList);
    if (!comparisonItem) return null;

    const percentageComparison = getPercentageComparison(item, comparisonItem, 'sale');
    return (
      <div>
        <PercentageComparison {...percentageComparison} />
      </div>
    );
  };

  useEffect(() => {
    setReport([
      {
        name: t('Sales_MerchandiseStats'),
      },
      {
        name: t('Name'),
        value: `${t('Sales_MerchandiseStats_Sales')} ($)`,
        ' ': t('Sales_MerchandiseStats_TimesOrdered'),
      },
      ...merchandiseList.map((o) => ({
        name: o.name,
        value: o.sale,
        ' ': o.quantity,
      })),
    ]);
  }, [merchandiseList]);

  return (
    <>
      <Typography
        sx={{ fontWeight: 'bold', lineHeight: 1, fontSize: 14 }}
        color="text.secondary"
        gutterBottom
      >
        {t('Sales_MerchandiseStats')}
      </Typography>

      <If condition={!isEmptyOrNil(merchandiseList)}>
        <Then>
          <TableContainer className={classes.statsListingTable}>
            <Table stickyHeader aria-label="sticky table" size="small">
              {/* table header */}
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell
                    sortDirection={
                      (orderBy === TableColumnName.NAME ? sortOrder : false) as SortDirection
                    }
                  >
                    <TableSortLabel
                      active={orderBy === TableColumnName.NAME}
                      direction={orderBy === TableColumnName.NAME ? sortOrder : 'asc'}
                      onClick={onSort(TableColumnName.NAME)}
                    >
                      {t('Name')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="right"
                    sortDirection={
                      (orderBy === TableColumnName.SALES ? sortOrder : false) as SortDirection
                    }
                  >
                    <TableSortLabel
                      active={orderBy === TableColumnName.SALES}
                      direction={orderBy === TableColumnName.SALES ? sortOrder : 'asc'}
                      onClick={onSort(TableColumnName.SALES)}
                    >
                      {t('Sales_MerchandiseStats_Sales')}($)
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="right"
                    sortDirection={
                      (orderBy === TableColumnName.QUANTITY ? sortOrder : false) as SortDirection
                    }
                  >
                    <TableSortLabel
                      active={orderBy === TableColumnName.QUANTITY}
                      direction={orderBy === TableColumnName.QUANTITY ? sortOrder : 'asc'}
                      onClick={onSort(TableColumnName.QUANTITY)}
                    >
                      {t('Sales_MerchandiseStats_TimesOrdered')}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* table body */}
              <TableBody>
                {stableSort(merchandiseList, getComparator(sortOrder, orderBy)).map((item, idx) => (
                  <TableRow key={`${item.id}-${idx}`}>
                    <TableCell width={20} sx={{ color: 'text.secondary' }}>
                      {idx + 1}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      ${item.sale.toFixed(2)}
                      <RenderComparison item={item} />
                    </TableCell>
                    <TableCell align="right" width={50}>
                      {item.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Then>
        <Else>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t('Sales_NoData')}
          </Typography>
        </Else>
      </If>
    </>
  );
};

export default MerchandiseStatsTable;
