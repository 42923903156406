import React from 'react'
import { SvgIcon } from "@material-ui/core";

function TableIcon(props: any) {
  return (
    <SvgIcon sx={{ width: 16, height: 'auto', color: "rgba(0, 0, 0, 0.6)" }} viewBox="0 0 28 22" fill="none" {...props} >
      <path d="M27.2798 7.97325L25.3731 1.30659C25.2956 1.0267 25.1284 0.779959 24.8972 0.604161C24.666 0.428363 24.3836 0.3332 24.0931 0.333252H3.90646C3.30646 0.333252 2.78646 0.733252 2.62646 1.30659L0.719793 7.97325C0.479793 8.81325 1.11979 9.66659 1.99979 9.66659H4.93313L3.33313 21.6666H5.99979L6.89313 14.9999H21.1198L21.9998 21.6666H24.6665L23.0665 9.66659H25.9998C26.8798 9.66659 27.5198 8.81325 27.2798 7.97325ZM7.23979 12.3333L7.59979 9.66659H20.3998L20.7598 12.3333H7.23979Z" />
    </SvgIcon>
  );
}

export default TableIcon
