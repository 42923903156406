import React from 'react'
import {
  IconButton, List, ListItem, Table, TableBody, TableCell, TableContainer, TableFooter,
  TableHead, TablePagination, TableRow, Theme, useMediaQuery, useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@material-ui/icons';

// src
import { Order } from '../../services/models';
import { displayDateTime, displayHourMinutes, } from '../../utils';
import { displayOrderShortCode } from '../../bos_common/src/services/orderUtils';
import renderPrice from '../../bos_common/src/components/Price';

interface RenderOrderListTableProps {
  orders: Order[],
  handleOrderClick?: (_: Order) => void,
  showFullDate?: boolean;
  paginationOptions?: {
    currentRowPage: number;
    setCurrentRowPage: (_: number) => void;
    count: number;
    rowsPerPage?: number
  }
}

const RenderOrderListTable = ({ orders = [], handleOrderClick, showFullDate, paginationOptions }: RenderOrderListTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { currentRowPage = 0, setCurrentRowPage, count = 0, rowsPerPage = 30 } = paginationOptions || {};

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentRowPage?.(newPage);
  };

  return (
    <List component="div" disablePadding>
      <ListItem style={{ padding: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: theme.palette.grey[500] }}>
                  {t('OrderHistory_List_Customer')}
                </TableCell>
                <TableCell style={{ color: theme.palette.grey[500] }}>
                  {t('OrderHistory_List_OrderNumber')}
                </TableCell>
                <TableCell style={{ color: theme.palette.grey[500] }}>
                  {t('OrderHistory_List_PickupTime')}
                </TableCell>
                <TableCell style={{ color: theme.palette.grey[500] }}>
                  {t('OrderHistory_List_OrderType')}
                </TableCell>
                <TableCell style={{ color: theme.palette.grey[500] }}>
                  {t('OrderHistory_List_OrderStatus')}
                </TableCell>
                <TableCell style={{ color: theme.palette.grey[500] }}>
                  {t('OrderHistory_List_Total')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.userDisplayName}</TableCell>
                  <TableCell>{displayOrderShortCode(order)}</TableCell>
                  <TableCell>
                    {showFullDate ? displayDateTime(order.toPickupTime) : displayHourMinutes(order.toPickupTime)}
                  </TableCell>
                  <TableCell className="capitalize">{order.type}</TableCell>
                  <TableCell className="capitalize">{order.status}</TableCell>
                  <TableCell>{renderPrice(order.amount)}</TableCell>
                  {handleOrderClick && (
                    <TableCell align="right">
                      <IconButton
                        aria-label="see more"
                        size="small"
                        color="primary"
                        onClick={() => handleOrderClick(order)}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>

            {paginationOptions && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={isMobile ? 3 : 7}
                    count={count}
                    page={currentRowPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                  />
                </TableRow>
              </TableFooter>
            )}

          </Table>
        </TableContainer>
      </ListItem>
    </List>
  )
}

export default RenderOrderListTable