import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, CardContent, Fab, MenuItem, Select, Switch, TextField, useTheme } from '@material-ui/core';
import { Preview, Save } from '@material-ui/icons';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { If, Then } from 'react-if';

import { ColoredPaper, isEmptyOrNil, uploadThumbnail } from 'bos_common/src';
import { MultiFabContainer } from 'bos_common/src/components/FabContainers';
import ImageUploadAvatar from 'bos_common/src/components/ImageUploadAvatar';
import { MediaInfo } from 'bos_common/src/types/MerchantStoryType';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { getMerchantStoryPreviewURL } from 'bos_common/src/services/urls';

import MerchantPageHeader from '../../components/MerchantPageHeader';
import { AppContext } from '../../context/AppContext';
import { NotificationSeverity } from '../../types/NotificationSlice';
import { diffMediaInfo } from '../../utils/merchantStoryUtils';

interface IMerchantStoryEditPageParams {
  merchantUsername: string;
  storyId: string;
}

const MerchantStoryEditPage = () => {
  const { merchantUsername, storyId } = useParams<IMerchantStoryEditPageParams>();
  const [heroSrc, setHeroSrc] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<string>("external-link");
  const [showActionInfo, setShowActionInfo] = useState<boolean>(false);
  const [media, setMedia] = useState<MediaInfo[]>([]);

  const history = useHistory();
  const theme = useTheme();
  const { merchant, triggerNotification } = useContext(AppContext)

  const { register, handleSubmit, watch, setValue, formState: { errors }, control } = useForm();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      handleDroppingFiles(acceptedFiles);
    },
    noClick: true
  });

  const [loadingUpload, executeGetUploadUrl] = useAxios(
    { url: '/upload-url', method: 'POST' },
    { manual: true }
  );
  const [{ data: story, loading: loadingStory }, executeGetStory] = useAxios(
    { url: `/stories/${storyId}`, method: 'GET' },
    { manual: true }
  );
  const [uploadingStoryEdit, executeUploadStoryEdit] = useAxios(
    { url: `/merchants/${merchant?.id}/stories/${storyId}`, method: 'PATCH' },
    { manual: true }
  );

  useEffect(() => {
    executeGetStory();
  }, []);

  useEffect(() => {
    if (!isEmptyOrNil(story)) {
      if (story?.action?.type === 'external-link') {
        setAction(story.action.type);
        setShowActionInfo(true);
      }
      if (!isEmptyOrNil(story!.photos)) {
        setHeroSrc(story!.photos[0])
      }
    }
  }, [story]);

  // this is needed because we have to register text fields first before setting value
  useEffect(() => {
    if (showActionInfo) {
      if (action === 'external-link') {
        setValue("storyExternalLinkText", story!.action!.typeInfo!.actionButtonText);
        setValue("storyExternalLinkURL", story!.action!.typeInfo!.externalLink);
      }
    }
  }, [showActionInfo]);

  const onBack = useCallback(() => {
    history.push(`/${merchantUsername}/stories`)
  }, [history]);

  const RenderActions = useCallback(() => {
    if (!action || isEmptyOrNil(action)) return null;

    if (action === 'external-link') {
      return (
        <div>
          <TextField
            fullWidth
            required
            label="External Link Text"
            helperText="The text that will appear for the link"
            variant="outlined"
            sx={{ mb: theme.spacing(2) }}
            {...registerField("storyExternalLinkText")}
          />
          <TextField
            fullWidth
            required
            label="External Link URL"
            variant="outlined"
            sx={{ mb: theme.spacing(2) }}
            {...registerField("storyExternalLinkURL")}
          />
        </div>
      );
    }

    if (action === 'menu-item') {
      return (
        <div>menu item</div>
      );
    }

    return null;
  }, [action]);

  const watchStoryDescription = watch('content');

  const registerField = (name: string, options?: any) => {
    // @ts-ignore
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  const onSubmit = (data: any) => {
    const getAction = (action: string) => {
      switch (action) {
        case "external-link":
          return {
            type: 'external-link',
            typeInfo: {
              externalLink: data.storyExternalLinkURL,
              actionButtonText: data.storyExternalLinkText
            }
          };
        default:
          return {
            type: 'external-link',
            typeInfo: {
              externalLink: data.storyExternalLinkURL,
              actionButtonText: data.storyExternalLinkText
            }
          }
      }
    }

    let formData = {
      ...data,
      photos: [heroSrc],
      action: getAction(action),
      media: diffMediaInfo(data.content, media),
      status: data.storyPublished ? 'published' : 'draft'
    }

    executeUploadStoryEdit({ data: formData })
      .then((results) => {
        if (results.status === 200) {
          if (results.data) {
            triggerNotification(true, 'Story was updated successfully!', NotificationSeverity.SUCCESS, true);
          }
        }
      })
  }

  const uploadFile = (file: any) => {
    const isVideoFile = file.type.indexOf('video') > -1

    return new Promise((resolve, reject) => {
      executeGetUploadUrl()
        .then((response) => {
          if (response.status === 200) {
            const f0 = file;
            const { url, uploadUrl } = response.data;
            axios.request<{ url: string }>({
              method: 'put',
              url: uploadUrl,
              data: f0,
              headers: { 'content-type': f0.type },
            })
              .then(() => {
                return isVideoFile ? uploadUrl : uploadThumbnail(url, f0)
              })
              .then(() => {
                resolve(url);
              })
          }
        });
    });
  }

  const onPhotoSelect = async (files: any, photoType: string) => {
    setLoading(true);
    setValue("content", `Uploading image...\n\n ${watchStoryDescription ?? ''}`);

    const publicUrl = await uploadFile(files[0]);
    // place hero image at top of content
    const updatedStoryDescription = `${watchStoryDescription ?? ''} \n![story-image](${publicUrl})\n`;
    const heroPhotoMediaInfo: MediaInfo = {
      s3Key: publicUrl as string,
      type: 'photo',
      publicUrl: publicUrl as string

    }

    setMedia([...media, heroPhotoMediaInfo]);
    setHeroSrc(publicUrl as string);
    setValue("content", updatedStoryDescription);
    setLoading(false);
  }

  const handleDroppingFiles = async (files: any) => {
    setLoading(true);
    setValue("content", `${watchStoryDescription ?? ''} \nUploading content...\n`);

    const fileUploadPublicURLs: Array<{ url: string, type: string }> = [];
    const mediaInfo: MediaInfo[] = [];

    for (let i = 0; i < files.length; i++) {
      const publicUrl = await uploadFile(files[i]) as string;

      const type = files[i].type.indexOf('video') > -1 ? "video" : 'photo'
      fileUploadPublicURLs.push({ url: publicUrl, type });

      mediaInfo.push({
        s3Key: publicUrl,
        type,
        publicUrl
      });
    }

    const markdownFormattedUrls = fileUploadPublicURLs.map(({ url, type }) => {
      return `![${type === 'photo' ? 'story-image' : 'video'}](${url})\n`;
    });

    // place images at bottom of content
    setValue("content", `${watchStoryDescription ?? ''}\n ${markdownFormattedUrls.reduce((prev, curr) => `${prev} ${curr}`)}`);
    setMedia([...media, ...mediaInfo]);
    setLoading(false);
  }

  const handleActionChange = (event: any) => {
    setAction(event.target.value);
  }

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title="Story" />
      <ColoredPaper>
        <SimpleLoader loading={loading} />

        <If condition={story}>
          <Then>
            {
              story &&
              (<CardContent>
                <ImageUploadAvatar
                  heroSrc={heroSrc}
                  showHeroCover
                  showLogo={false}
                  onPhotoSelect={onPhotoSelect}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box sx={{ p: 2}} gap={2} flexDirection="column" display="flex">
                  <Controller
                    control={control}
                    name="storyPublished"
                    defaultValue={story.status === 'published' ? true : false}
                    render={({
                      field: { onChange, value = false, ref }
                    }) => (
                      <div>
                        <Switch
                          checked={value}
                          onChange={onChange}
                          color="primary"
                          inputRef={ref}
                          name='merchant-story-published'
                          inputProps={{ 'aria-label': 'true' }}
                          id='merchant-story-published'
                        />
                        <label
                          htmlFor='merchant-story-published'
                          onClick={(e: any) => e.stopPropagation()}>
                          {value ? 'Published to feed' : 'Hidden from feed'}
                        </label>
                      </div>
                    )}
                  />
                  <TextField
                    fullWidth
                    required
                    defaultValue={story.title}
                    label="Title"
                    variant="outlined"
                    sx={{ mb: theme.spacing(2) }}
                    {...registerField("title")}
                  />
                  <TextField
                    fullWidth
                    defaultValue={story.subtitle}
                    label="Subtitle"
                    variant="outlined"
                    sx={{ mb: theme.spacing(2) }}
                    {...registerField("subtitle")}
                  />
                  <section {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <TextField
                      fullWidth
                      required
                      defaultValue={story.content}
                      multiline
                      minRows={5}
                      maxRows={10}
                      label="Story Content"
                      helperText="drag and drop images to upload into markdown format"
                      variant="outlined"
                      sx={{ mb: theme.spacing(2) }}
                      {...registerField("content")}
                    />
                  </section>

                  <Select
                    onChange={handleActionChange}
                    sx={{ mb: theme.spacing(2) }}
                    label="Action"
                    value={action}
                  >
                    <MenuItem value="external-link">External Link</MenuItem>
                    {/* TODO: Implement more actions */}
                  </Select>

                  <RenderActions />

                  <MultiFabContainer fabStyle={{ justifyContent: 'flex-end' }}>
                    <Fab type="button" variant="extended" color="secondary">
                      <Preview />
                      <a target='_blank' rel="noreferrer" href={getMerchantStoryPreviewURL(story)}>{t("Preview")}</a>
                    </Fab>
                    <Fab type="submit" variant="extended" color="primary">
                      <Save />
                      {t("Save")}
                    </Fab>
                  </MultiFabContainer>
                  </Box>
                </form>
              </CardContent>)
            }
          </Then>
        </If>

        <If condition={!story && !loadingStory}>
          <CardContent sx={{ p: 4 }}>
            Could not find the story. Retry by refreshing the page.
          </CardContent>
        </If>
      </ColoredPaper>
    </div>
  );
}

export default MerchantStoryEditPage;