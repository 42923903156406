export const getMinOffset = (date?: Date) => date ? date.getHours() * 60 + date.getMinutes() : 0

/**
 * Return rounded minutes offset from begin of the day
 */
 export const getRoundedMinutesOffset = (date: Date): number => {
  const h = date.getHours()
  const m = date.getMinutes()

  const roundedM: number = (((m + 10) / 20 | 0) * 20) % 60
  const roundedH24: number = Math.floor((((m / 100) + .5) | 0) + h);

  return roundedH24 * 60 + roundedM;
}