import React, { useContext } from "react";
import ChevronRight from '@material-ui/icons/ChevronRight';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import { UserContext } from 'bos_common/src/context/UserContext';
import { isFeatureEnabled } from "bos_common/src/services/featureFlagUtils";

import { AppContext } from '../../context/AppContext';
import { userIsStaff, userIsAdmin } from '../../utils';
import LocalesSelect from '../common/LocalesSelect';
import SideMenuContainer from "./SideMenuContainer";

const SideMenuServices = (): JSX.Element => {
  const history = useHistory();
  const { merchant, featureFlags } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const isStaff = userIsStaff(user);
  const isAdmin = userIsAdmin(user);
  const { t } = useTranslation();
  const merchantUsername = merchant?.username || '';

  return (
    <SideMenuContainer>
      <>
        <Card className="headerWrap">
          <CardContent className="header">
            <Grid container justifyContent='flex-start'>
              <Grid item xs={12} sm={6}>
                <div className="avatarContainer">
                  <Avatar alt={merchant?.officialName || merchant?.username} src={merchant?.logo} className="avatar" />
                  <Typography variant="subtitle1">
                    {merchant?.username}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <LocalesSelect />
          </CardContent>
        </Card>

        <Divider />

        <List className='content'>
          {/* Staff OR Admin Actions */}
          <If condition={isStaff || isAdmin}>
            <Then>
              <ListItem
                button
                key="Calendar"
                onClick={() => {
                  history.push(`/${merchantUsername}/calendar`);
                }}>
                <ListItemText primary={t("MerchantService_Calendar")} />
                <ChevronRight />
              </ListItem>
              <ListItem
                button
                key="Customers"
                onClick={() => {
                  history.push(`/${merchantUsername}/customers`);
                }}>
                <ListItemText primary={t("MerchantService_Customers")} />
                <ChevronRight />
              </ListItem>
            </Then>
          </If>

          <If condition={isAdmin}>
            <Then>
              <ListItem
                button
                key="Reports"
                onClick={() => {
                  history.push(`/${merchantUsername}/reports`);
                }}>
                <ListItemText primary={t("MerchantService_Reports")} />
                <ChevronRight />
              </ListItem>
              <ListItem
                button
                key="Services"
                onClick={() => {
                  history.push(`/${merchantUsername}/menu`);
                }}>
                <ListItemText primary={t("Services_Menu")} />
                <ChevronRight />
              </ListItem>

              <Divider />

              <ListItem
                button
                key="Engagements"
                onClick={() => {
                  history.push(`/${merchantUsername}/engagements`);
                }}>
                <ListItemText primary={t("MerchantService_Engagements")} />
                <ChevronRight />
              </ListItem>
              <If condition={isFeatureEnabled(featureFlags, 'enabledFeed')}>
                <ListItem
                  button
                  key="Feed"
                  onClick={() => {
                    history.push(`/${merchantUsername}/stories`);
                  }}>
                  <ListItemText primary={t("MerchantService_Feed")} />
                  <ChevronRight />
                </ListItem>
              </If>

              <Divider />

              <ListItem
                button
                key="Staffs"
                onClick={() => {
                  history.push(`/${merchantUsername}/staffs`);
                }}>
                <ListItemText primary={t("Staffs")} />
                <ChevronRight />
              </ListItem>
              <ListItem
                button
                key="business"
                onClick={() => {
                  history.push(`/${merchantUsername}/bizsettings`);
                }}>
                <ListItemText primary={t("MyStore")} />
                <ChevronRight />
              </ListItem>
            </Then>
          </If>

          <Divider />

          {/* Logout Action */}
          <ListItem
            button
            key="account"
            onClick={() => {
              history.push('/user');
            }}
          >
            <ListItemText primary={t("Account")} />
            <ChevronRight />
          </ListItem>
        </List >

        <Divider />
      </>
    </SideMenuContainer>
  )
}

export default SideMenuServices;
