import React from "react";
import { Chip, Typography } from "@material-ui/core";
import { Else, If, Then } from "react-if";

import { displayOrderShortCode, isDineInOrder, isPickupASAP } from "../../bos_common/src/services/orderUtils";
import { Merchant, Order, OrderStatus } from "../../services/models";
import { displayDateTime } from "../../utils";
import { getCurrentOrderTable } from "../../utils/orderUtils";
import { useTranslation } from "react-i18next";
import { DeviceType, getDeviceLabel } from "bos_common/src/types/DeviceType";

const RenderAppChip = ({ order }: { order: Order }) => {

  return (
    <Chip
      label={getDeviceLabel(order.sourceInfo.device)}
      variant="outlined"
      color='primary'
      size="small"
      sx={{fontSize: 12, }}
    />
  )
}

type OrderInfoProps = {
  order: Order;
  merchant?: Merchant;
}

const OrderInfo = (props: OrderInfoProps) => {
  const { order, merchant } = props;
  const { createdAt, readyTime, type, toPickupTime } = order
  const { t } = useTranslation();

  let endStatelabel: string | null = null;
  switch (order.status) {
    case OrderStatus.FULFILLED:
    case OrderStatus.PAID:
    case OrderStatus.OPEN_CHECK:
      endStatelabel = 'Fulfilled';
      break;
    case OrderStatus.VOIDED:
      endStatelabel = 'Voided';
      break;
    case OrderStatus.PAID_EXTERNALLY:
      endStatelabel = 'Paid externally';
      break;
    default:
      console.log("unknown status");
      break;
  }

  return (
    <div>
      <RenderAppChip order={order} />
      <Typography variant="subtitle1" color="div" component="div">
        {t("Order_No")}: {displayOrderShortCode(order)}
      </Typography>
      <Typography variant="subtitle1" color="div" component="div">
        {t("Order_Created")}: {displayDateTime(createdAt)}
      </Typography>
      <Typography variant="subtitle1" color="div" component="div">
        {t("Order_Type")}: {type}
      </Typography>
      <Typography variant="subtitle1" color="div" component="div">
        <If condition={isDineInOrder(order)}>
          <Then>
            {t("Order_DineIn")}: Table {getCurrentOrderTable(order, merchant)}
          </Then>
          <Else>
            <If condition={isPickupASAP(order)}>
              <Then>
                {t("Order_Pickup")}: ASAP
              </Then>
              <Else>
                {t("Order_Pickup")}: {displayDateTime(toPickupTime)}
              </Else>
            </If>
          </Else>
        </If>
      </Typography>
      <If condition={Boolean(merchant)}>
        <Typography variant="subtitle1" color="div" component="div">
            {t("At")}: {merchant?.officialName}
        </Typography>
      </If>
      <If condition={order.status === OrderStatus.CONFIRMED}>
        <Then>
          <Typography variant="subtitle1" color="div" component="div">
            Confirmed by One Market Operator
          </Typography>
        </Then>
        <Else>
          <Typography variant="subtitle1" color="div" component="div">
            {endStatelabel}: {readyTime ? displayDateTime(readyTime) : 'n/a'}
          </Typography>
        </Else>
      </If>
    </div>
  );
};

export default OrderInfo;