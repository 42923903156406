import React, { useContext, useState } from 'react';
import { Box, Button, Card, IconButton, InputAdornment, Stack, TextField, Typography } from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import { PlatformChatContext } from './context/PlatformChatContext';
import { generateUserName } from './utils';
import { isEmptyOrNil } from '../../utils';

interface ChatSettingsProps {
  ctaLabel?: string
  welcomeMessage?: string
}

const ChatSettings = (props: ChatSettingsProps) => {
  const [error, setError] = useState<string>("");

  const {
    ctaLabel = "Start Chat!",
    welcomeMessage = "Please select a username to use in this chat"
  } = props;
  const { username, setUsername, toggleChat, isMerchantChat } = useContext(PlatformChatContext);

  const isDisabled = isEmptyOrNil(username);
  
  const updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const username = event.target.value;
    const characterLimit = 30;
    const isValidName = /^[a-zA-Z0-9_]+$/g.test(username);

    if(username.length > characterLimit) {
      setError(`Username must be less than ${characterLimit} characters`);
      return;
    }

    if(username.includes(" ")) {
      setError("Can't use spaces in username");
      return;
    }

    if(!isValidName && username !== "") {
      setError("Only these characters are allowed: alphabets, 0-9 and _");
      return;
    }

    setUsername(event.target.value);
    setError("");
  };

  const GenerateUsernameButton = () => {
    const onClick = () => {
      setUsername(generateUserName())
    }
    return (
      <InputAdornment position="end" >
        <IconButton
          aria-label="toggle password visibility"
          onClick={onClick}
          edge="end"
        >
          <Replay />
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card sx={{ mt: 3, p: 2, width: "100%", maxWidth: 720 }}>
        <form onSubmit={() => toggleChat()}>
          <Stack spacing={2}>
            <Typography variant='body1'>{welcomeMessage}</Typography>
            <TextField
              placeholder='username'
              variant="outlined"
              value={username}
              disabled={isMerchantChat}
              onChange={updateUsername}
              required
              size="small"
              InputProps={{
                endAdornment: !isMerchantChat && <GenerateUsernameButton />
              }}
              helperText={!isEmptyOrNil(error) && error}
              error={!isEmptyOrNil(error)}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => toggleChat()}
              fullWidth
              sx={{ marginTop: 1 }}
              disabled={isDisabled}
            >
              {ctaLabel}
            </Button>
          </Stack>
        </form>
      </Card>
    </Box>
  )
}

export default ChatSettings