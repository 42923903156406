import React, { useContext, useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  IconButton,
  Fab,
  Grid,
  Paper,
  InputAdornment,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { omit, pluck, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { getAuthHeaders } from 'bos_common/src/utils';
import axios from 'bos_common/src/services/backendAxios';
import { ColoredPaper, FullscreenPaper } from 'bos_common/src';
import { UserContext } from 'bos_common/src/context/UserContext';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';
import { MultiFabContainer } from 'bos_common/src/components/FabContainers';
import ConfirmationAlert from 'bos_common/src/components/ConfirmationAlert';
import MerchantMembership, { MembershipType } from 'bos_common/src/types/crm/MerchantMembershipType';

import { useAppSelector } from '../redux/hooks';
import { getMerchant } from '../redux/slice/merchant/merchantSelector';

import { ServiceFormType } from '../constants';
import { Merchandise } from '../services/models';
import MediaCarousel from './common/MediaCarousel';
import { AppContext } from '../context/AppContext';
import { NotificationSeverity } from '../types/NotificationSlice';
import { useMerchantMenuContext } from '../context/MenuContext/MerchantMenuContext';
import { getMerchandiseDispatchType, isEmptyOrNil, uploadThumbnail } from '../utils';
import EligibleClassesList, { ClassesType } from './ServicesEligibleClasses/EligibleClassesList';
import { REMOVE_ONE_MEMBERSHIP, REMOVE_ONE_PACKAGE, REMOVE_ONE_TRIAL } from '../context/MenuContext/constants';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        marginTop: theme.spacing(2),
      },
      '& .dlgTitle': {
        textAlign: 'center',
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
      },
    },

    availabilitySwitch: {
      borderRadius: '8px',
      border: '1px solid #bcc0c4',
      padding: '8px 0px',
      position: 'relative',
      marginTop: theme.spacing(2),

      '& .switch-label': {
        fontSize: "0.8rem",
        lineHeight: '1.4375em',
        fontFamily: '"Manrope","Helvetica","Arial",sans-serif',
        color: 'rgba(0, 0, 0, 0.6)',
        backgroundColor: "#f5faff",
        padding: "0 5px",

        position: 'absolute',
        top: '-8px',
        left: '10px',
      }
    },

    closeButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

interface FormValues extends Partial<MerchantPackage>, Partial<MerchantMembership> {
  eligibleClasses?: Array<Merchandise>;
}

interface MerchandiseEditDialogProps {
  open: boolean,
  merchantPackage?: MerchantPackage | MerchantMembership,
  onClose: () => void,
  title: string,
  type: ServiceFormType,
  isTrialMembership?: boolean,
}

const PackageMembershipFormDialog = (props: MerchandiseEditDialogProps) => {
  const { merchantPackage, open, onClose, title, type, isTrialMembership = false } = props;

  if (!open) return null;

  const merchant = useAppSelector(getMerchant);

  const merchantId = merchant?.id;

  const API_ENDPOINT = type === ServiceFormType.package ? `/merchants/${merchantId}/packages` : `/merchants/${merchantId}/membership`;

  const classes = useStyles();
  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext);
  const { dispatch } = useMerchantMenuContext();
  const { t } = useTranslation();

  const [showConfirmationAlert, toggleConfirmationAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let deleteMerchandise = false


  const getMerchandises = (): Merchandise[] => {
    const isPackageTypeItem = type === ServiceFormType.package

    if (isPackageTypeItem) {
      return pluck('merchandise', propOr([], 'packageMerchandise', merchantPackage))
    }

    return propOr([], 'merchandises', merchantPackage)
  }

  const [{ error: updateMerchandiseError, loading: merchandiseLoading }, executeMerchandiseApi] = useAxios<Merchandise>(
    { url: API_ENDPOINT },
    { manual: true }
  );

  const handleClose = () => {
    reset();
    onClose();
  };

  const onMerchandiseSubmit: SubmitHandler<FormValues> = (formValues) => {
    // eslint-disable-next-line no-nested-ternary
    const method = deleteMerchandise ? 'DELETE' : (merchantPackage ? 'PUT' : 'POST');
    const url = isEmptyOrNil(merchantPackage)
      ? API_ENDPOINT
      : (`${API_ENDPOINT}/${merchantPackage!.id}`)

    const dispatchMerchandiseAddType = getMerchandiseDispatchType(type, !isEmptyOrNil(merchantPackage), isTrialMembership);
    const dispatchMerchandiseDeleteType = type === ServiceFormType.package ? REMOVE_ONE_PACKAGE : REMOVE_ONE_MEMBERSHIP;

    const headers = getAuthHeaders(token)
    const data = {
      ...omit(['eligibleClasses'], formValues),
      ...(isTrialMembership && { type: ServiceFormType.trial }),
      merchandiseIds: !isEmptyOrNil(formValues.eligibleClasses) ? pluck('id', formValues.eligibleClasses) : []
    }

    return executeMerchandiseApi({
      data: { ...data },
      method,
      url,
      headers
    })
      .then((response) => {
        triggerNotification(true, `Menu updated successfully`, NotificationSeverity.SUCCESS, true)
        if (response.status === 200) {
          if (!deleteMerchandise) {
            dispatch({
              type: dispatchMerchandiseAddType,
              payload: response.data
            })
          } else {
            dispatch({
              type: isTrialMembership ? REMOVE_ONE_TRIAL : dispatchMerchandiseDeleteType,
              payload: formValues.id as number
            })
          }
          deleteMerchandise = false
          reset();
          onClose();
        }
      }).catch(() => {
        triggerNotification(true, `Failed to update menu`, NotificationSeverity.ERROR, true)
      });
  };

  const onDeleteMerchandise = () => {
    deleteMerchandise = true
    handleSubmit(onMerchandiseSubmit)()
    toggleConfirmationAlert(false);
  }

  const methods = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      name: undefined,
      price: isTrialMembership ? 0 : undefined,
      description: undefined,
      merchantId,
      photos: undefined,
      videos: undefined,
      id: undefined,
      live: true,
      creditLimit: 1,
      eligibleClasses: [],
      ...(isTrialMembership && { creditLimit: 1 }),
      ...(!isEmptyOrNil(merchantPackage) && {
        ...merchantPackage, eligibleClasses: getMerchandises()
      }),
    }
  });

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    },
    reset,
    watch,
    control,
  } = methods;

  const registerTextField = (name: any, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  // @ts-ignore
  const photos = watch('photos', propOr([], 'photos', merchantPackage) as (src: any) => string[] | undefined)
  // @ts-ignore
  // TODO: check how to fixed nested type issue
  const videos = watch('videos', propOr([], 'videos', merchantPackage) as (src: any) => string[] | undefined)

  const isLoading = merchandiseLoading || loading;

  return (
    <FormProvider {...methods}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-edit-merchantPackage"
        className={classes.root}
      >
        <DialogTitle id="form-dialog-edit-merchantPackage" className="dlgTitle">
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
          {title}
        </DialogTitle>

        <Divider />
        <SimpleLoader loading={isLoading} />

        <FullscreenPaper>
          <ColoredPaper>
            <DialogContent>
              <form onSubmit={handleSubmit(onMerchandiseSubmit)}>
                <Grid container justifyContent="space-evenly" spacing={2}>
                  <Grid item xs={12} md={5}>
                    <Paper variant="outlined">
                      <MediaCarousel
                        photosData={photos || []}
                        videosData={videos || []}
                        setLoading={setLoading}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          // merchantPackage name
                          fullWidth
                          required
                          label={t("Name")}
                          helperText={t("Menu_MenuItem_Name_HelperText")}
                          error={!!errors.name}
                          {...registerTextField('name', { required: t("Menu_MenuItem_Name_RequiredText") })}
                        />
                      </Grid>

                      {
                        !isTrialMembership && (
                          <Grid item xs={12}>
                            <TextField
                              // merchantPackage price
                              fullWidth
                              required
                              helperText={t("Menu_MenuItem_Price_HelperText")}
                              label={t("Price")}
                              type="number"
                              error={!!errors.price}
                              inputProps={{ step: '0.01', min: '0' }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }}
                              {...registerTextField('price', { required: t("Menu_MenuItem_Price_RequiredText") })}
                            />
                          </Grid>
                        )
                      }

                      {type === ServiceFormType.package && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            type='number'
                            error={!!errors.creditLimit}
                            inputProps={{ step: '1', min: '1' }}
                            label={t("Total_Classes")}
                            {...registerTextField('creditLimit')}
                          />
                        </Grid>
                      )}

                      {type === ServiceFormType.membership && !isTrialMembership && (
                        <Grid item xs={12}>
                          <FormControl sx={{width: "100%"}}>
                            <InputLabel sx={{top: (theme) => theme.spacing(-0.8), left: (theme) => theme.spacing(1.6)}} variant="standard" id="uncontrolled-native">
                              Type
                            </InputLabel>
                            <Select
                              labelId="uncontrolled-native"
                              variant="outlined"
                              required
                              fullWidth
                              inputProps={{ 'aria-label': 'true' }}
                              label="Type"
                              MenuProps={{
                                disablePortal: true,
                              }}
                              defaultValue={propOr(MembershipType.monthly, 'type', merchantPackage)}
                              {...registerTextField('type')}
                            >
                              <MenuItem
                                key={MembershipType.monthly}
                                value={MembershipType.monthly}
                              >
                                {MembershipType.monthly}
                              </MenuItem>
                              <MenuItem
                                key={MembershipType.yearly}
                                value={MembershipType.yearly}
                              >
                                {MembershipType.yearly}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          minRows={2}
                          maxRows={10}
                          label={t("Description")}
                          {...registerTextField('description')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EligibleClassesList
                          name={ClassesType.eligibleClasses}
                          message={type === ServiceFormType.membership ? t('Service_MembershipEligibleClassMessage') : t('Service_PackageEligibleClassMessage')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.availabilitySwitch}>
                          <span className='switch-label'>{t("Menu_MenuItem_Visibility")}</span>
                          <Controller
                            control={control}
                            name="live"
                            render={({
                              field: { onChange, value }
                            }) => (
                              <>
                                <Switch
                                  checked={value}
                                  color="primary"
                                  onChange={onChange}
                                  inputProps={{ 'aria-label': 'true' }}
                                />
                                <label
                                  htmlFor='merchandise-visibility-switch'
                                  onClick={(e: any) => e.stopPropagation()}
                                >
                                  {value ? t("VisibleCustomer") : t("HiddenCustomer")}
                                </label>
                              </>
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {merchantPackage && <input type="hidden" {...register('id')} value={merchantPackage.id} />}
                <input type="hidden" {...register('merchantId')} value={merchantId} />

                <MultiFabContainer>
                  <>
                    {merchantPackage && (
                      <Fab
                        variant="extended"
                        color="secondary"
                        className="deleteFab"
                        onClick={() => toggleConfirmationAlert(true)}
                      >
                        <Delete />
                        {t("Delete")}
                      </Fab>
                    )}
                    <Fab
                      type="submit"
                      disabled={isSubmitting}
                      variant="extended"
                      color="primary">
                      <Save />
                      {t("Save")}
                    </Fab>
                  </>
                </MultiFabContainer>
              </form>
            </DialogContent>

            <ConfirmationAlert
              isOpen={showConfirmationAlert}
              title={t("Menu_DeleteMenuItem")}
              onCancel={() => toggleConfirmationAlert(false)}
              onConfirm={() => onDeleteMerchandise()}
              cancelLabel={t("Cancel")}
              confirmLabel={t("Confirm")}>
              {t("Menu_DeleteMenuItem_Description")}
            </ConfirmationAlert>
          </ColoredPaper>
        </FullscreenPaper>
      </Dialog >
    </FormProvider>
  )
}

export default PackageMembershipFormDialog
