import React from "react";

export type ErrorReporterParams = { source: string, error: Error, errorInfo: React.ErrorInfo }

interface ErrorBoundaryProps {
  source: string
  errorFallbackComponent?: React.ReactElement
  reportError?: (params: ErrorReporterParams) => void
}

const defaultErrorBoundary = <h1 style={{ textAlign: 'center' }}>Something went wrong</h1>

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    const {
      source,
      reportError
    } = this.props;

    reportError && reportError({source, error, errorInfo});
  }

  render() {
    const {
      errorFallbackComponent = defaultErrorBoundary
    } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return errorFallbackComponent;
    }

    return this.props.children;
  }
}

export default ErrorBoundary