import axios from 'axios';
import { isEmptyOrNil } from 'bos_common/src';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';  
import React, { createRef, forwardRef, useImperativeHandle } from 'react';

type HtmlToCanvasProps = {
  children: React.ReactChild;
};

export type HtmlToCanvasRefProps = {
  download: (fileName: string) => Promise<void>;
  print: () => Promise<void>;
};

const HtmlToCanvas = forwardRef<HtmlToCanvasRefProps, HtmlToCanvasProps>(({ children }, ref) => {
  const contentRef = createRef<HTMLDivElement>();

  const renderCanvas = async () => {
    const html = contentRef.current!;

    const loads: Promise<any>[] = [];

    [
      { name: 'img', srcKey: 'src' },
      { name: 'image', srcKey: 'xlink:href' },
    ].forEach(tag => {
      const nodes = html.querySelectorAll(tag.name) as unknown as HTMLOrSVGImageElement[];

      nodes.forEach(img => {
        const src = img.getAttribute(tag.srcKey)!;
        if (isEmptyOrNil(src) || /base64/.test(src) || /not-cache$/.test(src)) return;

        const newSrc = `${src}?not-cache`;

        switch (tag.name) {
          case 'img':
            img.setAttribute(tag.srcKey, newSrc);
            img.setAttribute('crossorigin', 'anonymous');
            break;
          case 'image':
            loads.push(
              new Promise<void>(resolve => {
                const finish = (dataURL: string) => {
                  // eslint-disable-next-line no-param-reassign
                  img.onload = () => {
                    resolve();
                  };
                  img.setAttribute(tag.srcKey, dataURL);
                };

                if (/\//.test(src) || src.indexOf(window.location.origin)) {
                  axios.get(src, { responseType: 'blob' }).then(res => {
                    const reader = new FileReader();
                    reader.onload = e => {
                      finish((e.currentTarget as any).result);
                    };
                    reader.readAsDataURL(res.data);
                  });
                } else {
                  const newImg = new Image();
                  newImg.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = newImg.width;
                    canvas.height = newImg.height;
                    canvas.getContext('2d')!.drawImage(newImg, 0, 0);
                    finish(canvas.toDataURL('image/webp'));
                  };
                  newImg.crossOrigin = 'anonymous';
                  newImg.src = newSrc;
                }
              }),
            );
            break;
          // no default
        }
      });
    });

    await Promise.all(loads);

    return html2canvas(html, { useCORS: true });
  };

  useImperativeHandle(ref, () => ({
    download: fileName =>
      new Promise(resolve => {
        renderCanvas().then((canvas: HTMLCanvasElement) => {
          canvas.toBlob(blob => {
            saveAs(blob!, fileName);
            resolve();
          });
        });
      }),

    print: () =>
      new Promise(resolve => {
        renderCanvas().then((canvas: HTMLCanvasElement) => {
          const iframe = document.createElement('iframe');
          iframe.setAttribute('style', 'position:fixed;left:-10000px;top:-10000px');
          document.body.appendChild(iframe);
          iframe.onload = () => {
            setTimeout(() => {
              iframe.parentNode?.removeChild(iframe);
              resolve();
            }, 0);
          };
          const doc = iframe.contentWindow!.document;
          doc.write(`<img id="content"/>`);
          doc.write(`<script>
        var img = document.getElementById('content');
        img.onload = () => {
          window.print();
        };
        img.src = '${canvas.toDataURL()}';
      </script>`);
          doc.close();
        });
      }),
  }));

  return <div ref={contentRef}>{children}</div>;
});

export default HtmlToCanvas;
