import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';

import PlatformChatContextProvider from 'bos_common/src/components/PlatformChat/context/PlatformChatContextProvider';
import { ColoredPaper, isEmptyOrNil } from 'bos_common/src';
import { isPlatformChatEnabled } from 'bos_common/src/services/featureFlagUtils';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';

import config from '../config/config';
import { AppContext } from '../context/AppContext';
import useFetchMerchant from '../services/useFetchMerchant';
import MerchantPageHeader from '../components/MerchantPageHeader';
import AdminChat from '../components/AdminChat';
import { isMerchantServicesType } from '../utils/merchantUtils';

interface IMerchantOfflinePresenceParams {
  merchantUsername: string;
}

const PlatformChatPage = () => {
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantOfflinePresenceParams>();
  const location = useLocation();
  const { t } = useTranslation()

  const { merchant, featureFlags } = useContext(AppContext);

  const loading = useFetchMerchant({ merchantUsername })

  const isChatEnabled = featureFlags && isPlatformChatEnabled(featureFlags)
  const isServicesMerchant = merchant && isMerchantServicesType(merchant)

  const handleBack = () => {
    history.goBack();
  };

  if (!merchant) {
    handleBack();
  }

  if (!isChatEnabled || !isServicesMerchant) {
    return (<Redirect to={{ pathname: '/', state: location.state }} />);
  }

  const sourceInfo = {
    device: config.appConfig.deviceType,
    app: config.appConfig.appType,
  }

  return (
    <div className='container'>
      <Box>
        <MerchantPageHeader onBack={handleBack} title={t("CustomerService")} />
      </Box>

      <ColoredPaper>
        <SimpleLoader loading={loading} />
        <If condition={!isEmptyOrNil(merchant)}>
          <PlatformChatContextProvider
            merchant={merchant}
            apiKey={config.appConfig.streamChatId}
            username={`${merchantUsername}_admin`}
            sourceInfo={sourceInfo}
            isMerchantChat
            chatStarted
          >
            <AdminChat />
          </PlatformChatContextProvider>
        </If>
      </ColoredPaper>
    </div>
  )
}

export default PlatformChatPage
