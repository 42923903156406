import React, { useContext, useEffect } from 'react'
import { subDays, subSeconds } from "date-fns";
import useAxios from 'axios-hooks';

// src
import { AppContext } from '../../context/AppContext';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { MerchantStatsContext } from '../../context/MerchantStats/MerchantStatsContext';
import { MerchantStatsPeriod } from '../../components/Stats/types';
import { StatsType } from '../../bos_common/src/types/MerchantSalesStatsType';
import { getAPIErrorMessage, getAuthHeaders } from '../../utils';
import { calculateStatsDateRange } from '../../utils/merchantStatsUtils';
import { MerchantStatsApiResponse } from '../../services/models';
import { NotificationSeverity } from '../../types/NotificationSlice';

function useStatsApiService(apiUrl: string) {
  const { token } = useContext(UserContext);
  const { merchant, triggerNotification } = useContext(AppContext);
  const { statsTimePeriod, statsDateRange } = useContext(MerchantStatsContext);
  const merchantUsername = merchant?.username;
  const isCustomStatsPeriod = statsTimePeriod === MerchantStatsPeriod.Custom
  const isDailyTimePeriod = statsTimePeriod === MerchantStatsPeriod.Day
  const statsType = isDailyTimePeriod ? StatsType.HOURLY : StatsType.DAILY;

  const [{ data: currentStatsData, loading: loadingStats }, refreshStats] =
    useAxios<MerchantStatsApiResponse>(
      {
        url: apiUrl,
        params: {
          merchantUsername,
          statsType,
          statsDateRange: JSON.stringify({
            fromDate: statsDateRange?.fromDate,
            toDate: statsDateRange?.toDate && isDailyTimePeriod
              ? subSeconds(statsDateRange?.toDate, 1)
              : statsDateRange?.toDate,
          }),
        },
        headers: getAuthHeaders(token),
      },
      { manual: true }
    );

  // used a separate hook to fetch comparision data because we don't want to override the currentStatsData variable
  const [{ data: comparisonStatsData, loading: loadingComparisonStats }, fetchComparisonStats] =
    useAxios<MerchantStatsApiResponse>(
      {
        url: apiUrl,
        headers: getAuthHeaders(token),
      },
      { manual: true }
    );

  // refresh stats when date range selection changes
  useEffect(() => {
    refreshStats()
      .catch((err) => {
        const msg = getAPIErrorMessage(err);
        if (msg) { triggerNotification(true, msg, NotificationSeverity.ERROR); }
      })

    if (!isCustomStatsPeriod) {
      const comparisonTimePeriod = calculateStatsDateRange(
        subDays((isDailyTimePeriod ? statsDateRange?.fromDate : statsDateRange?.toDate) || new Date(), 7),
        statsTimePeriod
      );
      fetchComparisonStats({
        params: {
          merchantUsername,
          statsType,
          statsDateRange: JSON.stringify({
            fromDate: comparisonTimePeriod.fromDate,
            toDate: isDailyTimePeriod ? subSeconds(comparisonTimePeriod.toDate, 1) : comparisonTimePeriod.toDate,
          }),
        },
      })
        .then(() => { })
        .catch((err) => {
          const msg = getAPIErrorMessage(err);
          if (msg) { triggerNotification(true, msg, NotificationSeverity.ERROR); }
        });
    }
  }, [statsDateRange, isCustomStatsPeriod, merchantUsername]);

  const isLoading = loadingStats || loadingComparisonStats;

  return {
    isLoading,
    currentStatsData,
    comparisonStatsData,
  }
};

export default useStatsApiService