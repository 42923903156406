import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams } from 'react-router';
// @ts-ignore
import MerchantProfilePage from '../MerchantProfilePage';

import MenuListCard from '../../components/common/MenuListCard';
import MerchantPageHeader from '../../components/MerchantPageHeader';
import MerchantSelectorCTA from '../../components/MerchantSelectorCTA';
import useFetchMerchant from '../../services/useFetchMerchant';
import MerchantOrderingConfigPage from '../MerchantOrderingConfigPage';
import MerchantPayoutPage from '../MerchantPayoutPage';
import MerchantQRCodesPage from '../MerchantQRCodesPage';
import MerchantBillingStatementsPage from '../MerchantBillingStatementsPage';
import MerchantDeviceStatusPage from '../MerchantDeviceStatusPage';
import BankAccountIcon from '../../assets/icons/BankAccountIcon';
import StoreInfoIcon from '../../assets/icons/StoreInfoIcon';
import OrderingSettingsIcon from '../../assets/icons/OrderingSettingsIcon';
import QRCodeIcon from '../../assets/icons/QRcodeIcon';
import OrderQueueIcon from '../../assets/icons/OrderQueueIcon';
import BillingStatementsIcon from '../../assets/icons/BillingStatementsIcon';
import DeviceStatusIcon from '../../assets/icons/DeviceStatusIcon';
import TwoColumnLayout from '../../components/common/TwoColumnLayout';
import { AppContext } from '../../context/AppContext';
import { isMerchantServicesType } from '../../utils/merchantUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .pageContent': {
        padding: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
        gridGap: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'unset',
        },
        [theme.breakpoints.between('sm', 'md')]: {
          gridTemplateColumns: '200px 1fr',
        },
      },

      '& .side-menu': {
        '& .menuListContainer': {
          [theme.breakpoints.up('sm')]: {
            position: 'sticky',
          },
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(1),
        },
      },
    },
  })
);

interface IMerchantStatsPage {
  merchantUsername: string;
}

const MerchantBizsettings = () => {
  const { t } = useTranslation();
  const pageTitle = t('MyStore');

  const { merchantUsername } = useParams<IMerchantStatsPage>();
  useFetchMerchant({ merchantUsername });
  const { merchant } = useContext(AppContext);
  const isProductMerchant = merchant && !isMerchantServicesType(merchant);

  const menuItems = isProductMerchant
  ? {
    dashManagement: [
      {
        path: `/${merchantUsername}/bizsettings/profile`,
        label: t('StoreInfo'),
        icon: <StoreInfoIcon />
      },
      {
        path: `/${merchantUsername}/bizsettings/ordering-config`,
        label: t('OrderingSettings'),
        icon: <OrderingSettingsIcon />,
      },
      {
        path: `/${merchantUsername}/bizsettings/qrcodes`,
        label: t('QRCodes'),
        icon: <QRCodeIcon />,
      },
    ],

    dashBilling: [
      {
        path: `/${merchantUsername}/bizsettings/payout`,
        label: t('BankAccount'),
        icon: <BankAccountIcon />,
      },
      {
        path: `/${merchantUsername}/bizsettings/billing-statements`,
        label: t('BillingStatements'),
        icon: <BillingStatementsIcon />,
      }
    ],

    dashDeviceStatus: [
      {
        path: `/${merchantUsername}/bizsettings/device-status`,
        label: t('Device_Status'),
        icon: <DeviceStatusIcon />,
      },
      {
        path: `/${merchantUsername}/callboard`,
        label: t('Order_Queue'),
        icon: <OrderQueueIcon />,
      }
    ]
  } :
  {
    dashManagement: [
      {
        path: `/${merchantUsername}/bizsettings/profile`,
        label: t('StoreInfo'),
        icon: <StoreInfoIcon />
      },
    ],

    dashBilling: [
      {
        path: `/${merchantUsername}/bizsettings/payout`,
        label: t('BankAccount'),
        icon: <BankAccountIcon />,
      },
      {
        path: `/${merchantUsername}/bizsettings/billing-statements`,
        label: t('BillingStatements'),
        icon: <BillingStatementsIcon />,
      }
    ],
  }


  return (
    <>
      <div className="container">
        <MerchantPageHeader
          title={pageTitle}
          hideOnScroll={false}
          elevation={0}
          leftChild={<MerchantSelectorCTA />}
        />
        <TwoColumnLayout
          leftSide={
            <>
              <MenuListCard title={t('StoreManagement')} menuItems={menuItems.dashManagement} dropdown={true} beginOpen={true} />
              { menuItems.dashBilling && <MenuListCard title={t('Billing')} menuItems={menuItems.dashBilling} dropdown={true} />}
              { menuItems.dashDeviceStatus && <MenuListCard title={t('Devices&Displays')} menuItems={menuItems.dashDeviceStatus} dropdown={true} />}
            </>
          }
        >
          <Switch>
            <Redirect
              exact
              from="/:merchantUsername/bizsettings"
              to="/:merchantUsername/bizsettings/profile"
            />

            {/* dashManagement */}
            <Route
              exact
              path="/:merchantUsername/bizsettings/profile"
              component={MerchantProfilePage}
            />
            <Route
              exact
              path="/:merchantUsername/bizsettings/ordering-config"
              component={MerchantOrderingConfigPage}
            />
            <Route
              exact
              path="/:merchantUsername/bizsettings/qrcodes"
              component={MerchantQRCodesPage}
            />

            {/* dashBilling */}
            <Route
              exact
              path="/:merchantUsername/bizsettings/payout"
              component={MerchantPayoutPage}
            />
            <Route
              exact
              path="/:merchantUsername/bizsettings/billing-statements"
              component={MerchantBillingStatementsPage}
            />

            {/* dashDeviceStatus */}
            <Route
              exact
              path="/:merchantUsername/bizsettings/device-status"
              component={MerchantDeviceStatusPage}
            />

          </Switch>
        </TwoColumnLayout>
      </div >
    </>
  );
};

export default MerchantBizsettings;
