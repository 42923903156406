import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../bos_common/src/context/UserContext';

export const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
  const { user } = useContext(UserContext)
  const location = useLocation()

  return (
    <Route  {...rest}>
      {(user) ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};
