export enum AppType {
  MERCHANT = "Merchant",
  MARKETPLACE = "Marketplace",
  ZEROANDAPP = "zero_and_app",
  INTERNAL = 'INTERNAL',
  PRINTERAPP ='PrinterApp',
  VIAAPP = 'ViaApp',
  All = "All",
  UNKNOWN = 'Unknown',
}

export const getAppType = (url: string): AppType => {
  if (url.includes('manage.1m.app') || url.includes('manage.via.1m.app')) {
    return AppType.MERCHANT
  } else if (url.includes('via.1m.app')) {
    return AppType.VIAAPP
  }else if (url.includes('1m.app')) {
    return AppType.MARKETPLACE
  }

  return AppType.UNKNOWN
}