import React, { useState } from 'react'
import { Alert, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import ReactCodeInput from 'react-code-input';
import useAxios from 'axios-hooks';
import { Merchant, User } from '../../services/models';
import { getJWTTokenFromHeader } from '../../bos_common/src/services/auth0';
import { getDeviceAuthToken } from '../../services/useAuthDevice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',

      '& .react-code-input': {
        display: 'flex !important',
        justifyContent: 'space-between',
        '& input[type=number]': {
          paddingRight: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },

      '& .passcode-error': {
        paddingTop: 0,
        paddingBottom: 0
      },

    }
  })
);

type StaffPasscodeInputProps = {
  label?: string,
  disabled?: boolean,
  merchant: Merchant,
  onSuccessAuth: (user: User, token: string) => void,
  passcodeLength?: number,
  autoFocus?: boolean,
}

const StaffPasscodeInput = (props: StaffPasscodeInputProps) => {
  const classes = useStyles();
  const [passcode, setPasscode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { disabled, label, merchant, onSuccessAuth, passcodeLength = 4, autoFocus = true } = props;

  const [{ data, loading: loginLoading }, executeStaffLogin] = useAxios<User>(
    { url: '/login/merchant/staff', method: 'POST' },
    { manual: true }
  )

  const onCodeChange = (newPasscode: string) => {
    setPasscode(newPasscode)
    setErrorMsg('');

    if (newPasscode.length === 4) {
      executeStaffLogin({
        data: {
          merchantUsername: merchant.username,
          passcode: newPasscode,
          deviceAuthToken: getDeviceAuthToken(merchant.username)
        }
      }).then((response) => {
        const { token } = getJWTTokenFromHeader(response)
        if (response.status === 200 && token) {
          const loggedInUser: User = response.data
          onSuccessAuth(loggedInUser, token)
        } else {
          setErrorMsg(`Failed to login with error code:${response.status}`)
        }
      }).catch((err) => {
        setErrorMsg(err.response.data?.errors?.message)
      })
    }
  }

  return (
    <div className={classes.root}>
      <ReactCodeInput
        type='number'
        disabled={disabled || loginLoading}
        fields={passcodeLength}
        name="staff-passcode"
        inputMode="numeric"
        value={passcode}
        onChange={onCodeChange}
        autoFocus={autoFocus}
      />
      {
        errorMsg &&
        <Alert severity="warning" className='passcode-error'>
          {errorMsg}
        </Alert>
      }
      {
        label &&
        <Typography variant="subtitle1" color="textSecondary" component="div">
          {label}
        </Typography>
      }
    </div>
  )
}

export default StaffPasscodeInput