import React, { useContext } from 'react';
import { Fab } from '@material-ui/core';

import { getAuthHeaders } from '../../../../utils';
import { AppContext } from '../../../../context/AppContext';
import { MerchantOrdersContext } from '../../../../context/MerchantOrders/MerchantOrdersContext';

import { UserContext } from '../../../../bos_common/src/context/UserContext';
import axios from '../../../../bos_common/src/services/backendAxios';

import { OrderActionTemplateProps } from './types';
import useActionTemplateStyles from './styles';
import ActionHeader from './ActionHeader';
import { Order } from '../../../../services/models';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity } from '../../../../types/NotificationSlice';

const RemindCustomerAction = (props: OrderActionTemplateProps) => {
  const { order, closeDrawer } = props;
  const classes = useActionTemplateStyles();
  const { t } = useTranslation();

  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext)
  const { updateOrders } = useContext(MerchantOrdersContext);

  // functions to handle action
  const handleConfirm = () => {
    axios.post<Order>(
      `merchants/orders/${order.id}/reminder`,
      {},
      { headers: getAuthHeaders(token) }
    ).then((response) => {
      if (response.status === 200) {
        triggerNotification(true, `SMS reminder for ${order.userDisplayName} sent successfully.`, NotificationSeverity.SUCCESS)
        setTimeout(() => {
          response.data && updateOrders([response.data]);
          closeDrawer();
        }, 100)
      } else {
        triggerNotification(true, `Failed to send SMS reminder for ${order.userDisplayName}'s order.`, NotificationSeverity.WARNING)
      }
    });
  }

  const subtitle = t("Order_Remind_Subtitle")

  return (
    <div className={classes.actionTemplateContainer}>
      <ActionHeader title={t("RemindCustomer")} subtitle={subtitle} />

      <div className='actions-container'>
        <Fab
          variant="extended"
          color="primary"
          component="span"
          onClick={handleConfirm}
        >
          {t("Confirm")}
        </Fab>
      </div>
    </div>
  )
}

export default RemindCustomerAction