import React from "react";
import { Paper, PaperProps, useTheme } from "@material-ui/core";

export const ColoredPaper = (props: PaperProps): React.ReactElement => {
  const theme = useTheme()

  const style = {
    backgroundColor: theme.palette.background.paper,
    flex: '1 1 auto',
  }

  return (
    <Paper style={style} square={true} {...props}>
      {props.children}
    </Paper>
  )
}

export const FullscreenPaper = (props: PaperProps): React.ReactElement => {
  const style = {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column' as const,
    borderRadius: 0,
  }

  return (
    <Paper style={style} square={true} {...props}>
      {props.children}
    </Paper>
  )
}

export const WhitePaper = (props: PaperProps): React.ReactElement => {
  const style = {
    backgroundColor: '#fff',
  }
  return (
    <Paper style={style} {...props}>
      {props.children}
    </Paper>
  )
}

export const AvatarPaper = (props: PaperProps): React.ReactElement => {
  const theme = useTheme()
  const style = {
    borderRadius: '50%',
    padding: theme.spacing(.5)
  }
  const finalProps = { elevation: 3, ...props } as PaperProps

  return (
    <Paper style={style} {...finalProps}>
      {props.children}
    </Paper>
  )
}