// TODO: Need to move it to bos_common

import { Alert, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { If, Then, Else } from 'react-if';

import { Merchant, SpecialHours } from '../../bos_common/src/types/MerchantType';
import { daysOfWeek, getSpecialTimings, getTimeLabelFromOffset, isEmptyOrNil } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: theme.spacing(0.5),

      '& .special-hours-contianer': {
        display: 'grid',
        gridTemplateColumns: '82px auto',

        '& .range-label': {},

        '& .timings-container': {
          '& ul': {
            listStyle: 'none',
            padding: `0 ${theme.spacing(1)}`,
            margin: 0,
          },
        },
      },
    },
  })
);

const HourGroup = ({ day, hourGroups }: { day: string; hourGroups: SpecialHours[] }) => {
  return (
    <div className="special-hours-contianer" key={`hours_${day}`}>
      <span className="range-label">{day}</span>
      <span className="timings-container">
        <ul>
          {hourGroups.map((hours: SpecialHours) => {
            const { fromMinOffset, toMinOffset } = hours;
            const timing =
              fromMinOffset == 0 || toMinOffset == 0
                ? 'Closed'
                : `${getTimeLabelFromOffset(fromMinOffset)} - ${getTimeLabelFromOffset(
                    toMinOffset
                  )}`;

            return <li key={`${day}_${timing}`}>{timing}</li>;
          })}
        </ul>
      </span>
    </div>
  );
};

const SpecialTimings = ({ customizations }: { customizations?: SpecialHours[] }) => {

  if (!customizations) {
    return null;
  }

  const classes = useStyles();
  const formattedSpecialTimings = getSpecialTimings(customizations);
  const SpecialTimings: Array<React.ReactElement> = [];

  const days = Array.from(daysOfWeek.values());
  days.forEach((day: string) => {
    const hourGroups = formattedSpecialTimings[day];
    if (hourGroups != null) {
      SpecialTimings.push(<HourGroup key={day} day={day} hourGroups={hourGroups} />);
    }
  });

  Object.keys(formattedSpecialTimings).forEach((key: string) => {
    if (!days.includes(key)) {
      const hourGroups = formattedSpecialTimings[key];
      if (hourGroups != null) {
        SpecialTimings.push(<HourGroup day={key} hourGroups={hourGroups} />);
      }
    }
  });

  return <div className={classes.root}>{SpecialTimings}</div>;
};

type MerchantStoreHourProps = {
  merchant?: Merchant;
};

const MerchantStoreHours = ({ merchant }: MerchantStoreHourProps): React.ReactElement => {
  const { hours } = merchant || {};
  const noTimingsAvailable =
    !hours ||
    (isEmptyOrNil(hours?.defaultFromMinOffset) &&
      isEmptyOrNil(hours?.defaultToMinOffset) &&
      isEmptyOrNil(hours.customizations));

  const { t } = useTranslation();
  return (
    <div className="timings-container">
      <If condition={noTimingsAvailable}>
        <Then>
          <Alert severity="warning" sx={{mb: 2}}>
            {t('BusinessHours_NoHoursMessage')}
          </Alert>
        </Then>
        <Else>
          <If
            condition={
              !isEmptyOrNil(hours?.defaultFromMinOffset) && !isEmptyOrNil(hours?.defaultToMinOffset)
            }
          >
            {`${getTimeLabelFromOffset(hours?.defaultFromMinOffset)} - ${getTimeLabelFromOffset(
              hours?.defaultToMinOffset
            )}`}
          </If>
          <If condition={!isEmptyOrNil(hours?.customizations)}>
            <SpecialTimings customizations={hours?.customizations} />
          </If>
        </Else>
      </If>
    </div>
  );
};

export default MerchantStoreHours;
