import React, { useContext, useState } from 'react';
import useAxios from 'axios-hooks';
import { useHistory, useParams } from 'react-router-dom';
import {
  CardHeader,
  IconButton,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Paper,
  CardContent,
  Fab,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { find } from 'ramda';
import { Add, ChevronRight, Link } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { If } from 'react-if';

import { ColoredPaper } from 'bos_common/src';
import { UserContext } from 'bos_common/src/context/UserContext';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { MultiFabContainer } from 'bos_common/src/components/FabContainers';
import { getManageMerchantURL } from 'bos_common/src/services/urls';
import { MerchantStaff, StaffRole } from 'bos_common/src/types/MerchantStaff';

import { AppContext } from '../context/AppContext';
import { NotificationSeverity } from '../types/NotificationSlice';
import { Merchant, User } from '../services/models';
import useFetchMerchant from '../services/useFetchMerchant';

import MerchantPageHeader from '../components/MerchantPageHeader';
import StaffEditDialog from '../components/StaffEditDialog';
import { getAuthHeaders } from '../utils';

interface IMerchantStaffsParams {
  merchantUsername: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
  })
);

const canManageStaff = (staffList: MerchantStaff[], user?: User, merchant?: Merchant): boolean => {
  if (!user) return false;

  const { id } = user;
  const isAdmin = ({ userId, role }: MerchantStaff) => userId === id && role === StaffRole.ADMIN

  return (id === merchant?.ownerId) || Boolean(find(isAdmin, staffList))
}

const MerchantStaffsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantStaffsParams>()
  const { merchant, triggerNotification } = useContext(AppContext)
  const { token, user } = useContext(UserContext)
  const [dlgOpen, setDlgOpen] = useState(false)
  const [editStaff, setEditStaff] = useState<MerchantStaff>()
  const { t } = useTranslation();

  useFetchMerchant({ merchantUsername })

  const [{ data: staffList = [], loading }, refresh] = useAxios<MerchantStaff[]>({
    url: '/merchants/staffs',
    params: { merchantUsername },
    headers: getAuthHeaders(token),
  });

  const staffManagementEnabled = canManageStaff(staffList, user, merchant);

  const handleAddStaff = () => {
    setEditStaff(undefined)
    setDlgOpen(true);
  };

  const onStaffUpdated = (staff: MerchantStaff) => {
    setDlgOpen(false)
    refresh()
  }

  const onEditStaff = (staff: MerchantStaff) => {
    setEditStaff(staff)
    setDlgOpen(true)
  }

  const onCancelEdit = () => {
    setDlgOpen(false)
  }

  const onBack = () => {
    history.goBack()
  }
  const RowRenderer = (props: { staff: MerchantStaff }): React.ReactElement => {
    const { staff } = props

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {staff.displayName}
        </TableCell>
        <TableCell>{staff.role}</TableCell>
        <If condition={staffManagementEnabled}>
          <TableCell align="right">{staff.passcode}</TableCell>
        </If>
        <If condition={staffManagementEnabled}>
          <TableCell align="right">
            <IconButton aria-label="edit row" size="small" onClick={() => onEditStaff(staff)}>
              <ChevronRight sx={{ color: "primary.main" }} />
            </IconButton>
          </TableCell>
        </If>
      </TableRow>
    )
  }

  const copyLinkToClipboard = () => {
    if (!merchant) {
      console.error('merchant is undefined');
      return;
    }

    navigator.clipboard.writeText(`${getManageMerchantURL()}/${merchant?.username}/stafflogin`)
      .then(() =>
        triggerNotification(true, t("Staff_loginLink"), NotificationSeverity.SUCCESS, true)
      )
      .catch(() =>
        triggerNotification(true, `Error while copying login link`, NotificationSeverity.ERROR, true)
      );
  }

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={t("Staffs")} />
      <SimpleLoader loading={loading} />

      <ColoredPaper className={classes.root}>
        <CardContent>
          {!loading && staffList.length < 1 && (
            <CardHeader subheader="No staffs yet" />
          )}

          {staffList.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="staff table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Name")}</TableCell>
                    <TableCell>{t("Staffs_List_Role")}</TableCell>
                    <If condition={staffManagementEnabled}>
                      <TableCell align="right">{t("Staffs_List_Passcode")}</TableCell>
                    </If>
                    <If condition={staffManagementEnabled}>
                      <TableCell />
                    </If>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffList.sort((a, b) => a.displayName.localeCompare(b.displayName)).map((staff) => <RowRenderer key={staff.id} staff={staff} />)}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </ColoredPaper>
      <If condition={staffManagementEnabled}>
        <MultiFabContainer>
          <>
            <Fab
              variant="extended"
              color="secondary"
              onClick={copyLinkToClipboard}>
              <Link /> {t("Staffs_Button_CopyAccessLink")}
            </Fab>

            <Fab
              variant="extended"
              color="primary"
              onClick={handleAddStaff}>
              <Add />{t("Staffs_Button_AddAStaff")}
            </Fab>
          </>
        </MultiFabContainer>
      </If>
      {merchant && staffManagementEnabled &&
        <StaffEditDialog
          staff={editStaff}
          open={dlgOpen}
          setOpen={setDlgOpen}
          onSubmitSuccess={onStaffUpdated}
          onCancel={onCancelEdit}
          merchant={merchant}
          staffList={staffList}
        />
      }
    </div>
  );
};

export default MerchantStaffsPage;