import React, { useState } from "react"
import { Accordion as MaterialAccordion, AccordionDetails, AccordionSummary, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandMoreOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
  })
);

type AccordionType = {
  children: React.ReactElement;
  title: string;
  expanded?: boolean;
}

const Accordion = (props: AccordionType) => {
  const { title, children, expanded=false } = props;

  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <MaterialAccordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2" fontWeight={600}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </MaterialAccordion>
  )
}

export default Accordion;