import { Alert, Snackbar } from '@material-ui/core';
import { Color } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import { mergeDeepLeft } from 'ramda';
import { useContext, useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { UserContext } from '../bos_common/src/context/UserContext';
import { backendURL } from '../bos_common/src/services/backendAxios';
import { displayOrderShortCode } from '../bos_common/src/services/orderUtils';
import { AppType, ClientType, Message, SocketEvent } from '../bos_common/src/types';
import { AppContext } from '../context/AppContext';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getNotifications } from '../redux/slice/notification/notificationSelector';
import {
  addNotification,
  clearNotification,
  removeNotification,
} from '../redux/slice/notification/notificationSlice';
import { localWebsocket } from '../services/localPrinterService';
import { IdentityRole, Order } from '../services/models';
import { Notification, NotificationSeverity } from '../types/NotificationSlice';
import {
  isEmptyOrNil,
  isNotificationSoundEnabled,
  repeatAudio,
  userIsOneMOperator,
} from '../utils';

const RenderNotifications = (notifications: Notification[]) => {
  const reduxDispatch = useAppDispatch();

  const handleCloseNotif = (ts: number) => {
    reduxDispatch(removeNotification(ts));
  };

  return (
    <div>
      {notifications.map((notif) => (
        <Alert
          style={{ marginBottom: 8 }}
          key={`${notif.ts}`}
          onClose={() => handleCloseNotif(notif.ts)}
          severity={(notif.notificationBody?.severity as Color) || 'info'}
        >
          {notif.notificationBody?.bodyText || ''}
        </Alert>
      ))}
    </div>
  );
};

const NotificationPage = () => {
  const { merchant, localPrinter, setUpdatedOrder } = useContext(AppContext);
  const { user, token } = useContext(UserContext);
  const notifications = useAppSelector(getNotifications);
  const reduxDispatch = useAppDispatch();
  const userRef = useRef(user);
  const socketRef = useRef<Socket | null>();

  const [, postLocalWebsocket] = useAxios(localWebsocket, { manual: true });

  const SOCKET_CONN_OPTIONS = {
    reconnectionDelay: 5000,
    reconnectionDelayMax: 30000,
    auth: {
      token,
    },
  };

  const getSocketEventAction = (
    order: Order | undefined,
    notificationMessage: string,
    variant?: NotificationSeverity
  ) => {
    if (order) {
      setUpdatedOrder(order);

      if (isEmptyOrNil(notificationMessage)) return;

      const notif = {
        ts: Date.now(),
        order,
        notificationBody: {
          bodyText: notificationMessage,
          hideAction: false,
          severity: variant,
          open: true,
        },
      } as Notification;

      reduxDispatch(addNotification(notif));

      // moved this notifAudio inside the getSocketEventAction to avoid false null if element is not loaded on component mount.
      const notifAudio = document.getElementById('app_notif_audio') as HTMLAudioElement;
      const playNotificationSound = isNotificationSoundEnabled(userRef.current);
      if (notifAudio && playNotificationSound) {
        repeatAudio(notifAudio, 3);
      }
    }
  };

  const handleSocketEvent = (data: Message) => {
    const newOrder = data.payload?.order as Order;
    if (!newOrder) return;

    switch (data.event) {
      case SocketEvent.NEW_ORDER:
        {
          const notifBody = newOrder.userDisplayName
            ? `New order from ${newOrder.userDisplayName}!`
            : `New order #${displayOrderShortCode(newOrder)}!`;
          getSocketEventAction(newOrder, notifBody, NotificationSeverity.SUCCESS);
        }
        break;
      case SocketEvent.ORDER_UPDATED:
        {
          const notifBody = newOrder.userDisplayName
            ? `Order from ${newOrder.userDisplayName} is updated!`
            : `Order #${displayOrderShortCode(newOrder)} is updated!`;
          getSocketEventAction(newOrder, notifBody, NotificationSeverity.SUCCESS);
        }
        break;
      case SocketEvent.FUTURE_ORDER_PREP_REMINDER:
        {
          const notifBody = newOrder.userDisplayName
            ? `${newOrder.userDisplayName} expects to pick up his order in 15 minutes`
            : `Order #${displayOrderShortCode(newOrder)} needs to be picked up in 15 minutes`;
          getSocketEventAction(newOrder, notifBody, NotificationSeverity.WARNING);
        }
        break;
      default:
        console.log('UNKNOWN EVENT', data.event);
        break;
    }
  };

  useEffect(() => {
    if (!user) return;
    userRef.current = user;

    const getSocket = (query?: Record<string, any>) => {
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
      }
      const option = {
        ...SOCKET_CONN_OPTIONS,
        query: {
          operatorId: user.id,
          appType: AppType.MERCHANT,
          clientType: ClientType.Web,
          ...query,
        },
      };

      if (localPrinter) {
        postLocalWebsocket({
          data: {
            url: backendURL,
            option: mergeDeepLeft(
              {
                auth: { token: '' },
                query: {
                  appType: AppType.PRINTERAPP,
                },
              },
              option
            ),
          },
        }).catch();
      }

      return io(backendURL, option);
    };

    if (userIsOneMOperator(user)) {
      const socket = getSocket();
      const topic = user.id;
      socket.on(topic, (data: Message) => {
        if (data.operatorId === user.id) handleSocketEvent(data);
      });
      socketRef.current = socket;
    } else if (merchant) {
      if (user.role === IdentityRole.MerchantAdmin || user.role === IdentityRole.MerchantStaff) {
        const socket = getSocket({ merchantId: merchant?.id });
        const topic = merchant.id;
        socket.on(topic, (data: Message) => {
          if (merchant.id === data.merchantId) handleSocketEvent(data);
        })
        socketRef.current = socket;
      }
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
        socketRef.current = null;
      }
    };
  }, [user?.id, merchant?.id]); // use ids to avoid repeated subscriptions

  const handleClearNotification = () => {
    reduxDispatch(clearNotification(null));
  };

  const open = notifications.length > 0;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      transitionDuration={300}
      ClickAwayListenerProps={{
        onClickAway: handleClearNotification,
      }}
      open={open}
      onClose={handleClearNotification}
    >
      {RenderNotifications(notifications)}
    </Snackbar>
  );
};

export default NotificationPage;