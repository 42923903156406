import { BaseIncrement } from 'bos_common/src/types/BaseIncrement';
import CalendarEvent, { ServiceTips } from 'bos_common/src/types/crm/CalendarEventType';
import { CalendarSchedule, EventRepetitionOptions } from 'bos_common/src/types/crm/CalendarScheduleType';
import CustomerScheduleEntry, { ChangeAppliedTo } from 'bos_common/src/types/crm/CustomerScheduleEntryType';
import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';

import { Merchandise, Merchant, MerchantStaff } from '../../services/models';

export enum EventType {
  SERVICE = 'Service',
  CLASS = 'Class',
}

export interface BigCalendarEvent extends CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  resourceId: number;
  subtitle?: string;
  description: string;
}

export type CalendarResource = {
  resourceId: number;
  resourceTitle: string;
};

export type CalendarHourMinuteType = {
  hour: number;
  minute: number;
};

export type CalendarService = {
  id: number;
  name: string;
  price: number;
};

export type StaffEvent = {
  title: string;
  eventType: EventType;
  start: CalendarHourMinuteType;
  end: CalendarHourMinuteType;
  attendees: Array<CustomerScheduleEntry>;
  currentService?: CalendarService;
  services?: Array<CalendarService>;
  notes?: string;
};

export type StaffWithEvent = {
  staffName: string;
  events: Array<StaffEvent>;
};

export interface CalendarState {
  eventsList: Array<BigCalendarEvent>;
  staffs: MerchantStaff[];
}

export interface CalenderEventPayload {
  id?: number;
  title: string;
  date: Date;
  startDate: Date;
  endDate: Date;
  repeatsUntilDate?: Date;
  merchantPackageId?: number;
  merchandiseId?: string;
  staffId?: number;
  repeat: EventRepetitionOptions;
  notes?: string;
  attendees?: Array<number>;
  serviceTips?: ServiceTips;
  changeAppliedTo?: ChangeAppliedTo;
}

export enum SubscriptionType {
  PACKAGE = "package",
  MEMBERSHIP = "membership"
}
