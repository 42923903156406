import React, { useMemo, useState } from "react";
import { Search } from "@material-ui/icons";
import { Stack } from "@material-ui/core";

import { isEmptyOrNil } from "bos_common/src";
import InputSearchBox from "bos_common/src/components/InputSearchBox";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { useMerchantMenuContext } from "../../../context/MenuContext/MerchantMenuContext";
import ServiceMembershipsList from "../../../components/MerchantCustomers/components/ServiceMembershipsList";

const TrialsMenu = () => {
  const [searchQuery, setSearchQuery] = useState<string>();

  const { state } = useMerchantMenuContext();

  // Filter List based on search query
  const filteredMembershipsTrialsList: MerchantMembership[] | undefined = useMemo(() => {
    if (isEmptyOrNil(state.trials)) return [];

    if (!searchQuery || isEmptyOrNil(searchQuery)) {
      // sort selected classes at the top of list
      return state.trials;
    }

    return state.trials.filter((merchandise: MerchantMembership) => {
      return merchandise.name?.toLowerCase().includes(searchQuery.toLowerCase())
    })
  }, [state.trials, searchQuery])

  return (
    <Stack direction="column" gap={2}>
      <InputSearchBox
        placeholder="Search Trials"
        onChangeQuery={(s) => setSearchQuery(s)}
        leftChild={<Search />}
      />

      <ServiceMembershipsList memberships={filteredMembershipsTrialsList} isTrialMembership={true} />
    </Stack>
  )
}

export default TrialsMenu;