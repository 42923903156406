import React, { useContext, useEffect, useState } from 'react';
import { useTheme, Checkbox, createStyles, Fab, FormControl, FormHelperText, InputLabel, ListItemText, makeStyles, MenuItem, OutlinedInput, Select, TextField, Theme } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import axios from '../../bos_common/src/services/backendAxios';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { getAuthHeaders } from '../../bos_common/src';

import { MerchandiseCategory, OrderType } from '../../services/models';
import { getHighestDisplayOrderCategory, isEmptyOrNil } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMerchandiseCategories } from '../../redux/slice/merchandise/merchandiseSelector';
import { merchandiseFetchCategoriesSucceed } from '../../redux/slice/merchandise/merchandiseActions';

import { NotificationSeverity } from '../../types/NotificationSlice';
import DialogWrapper from '../common/DialogWrapper';
import { AppContext } from '../../context/AppContext';
import { has } from 'ramda';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitCategory: {
      '& .closeIcon': {
        position: 'absolute',
        top: 0,
        right: 0,
        cursor: 'pointer',
      },
      '& .dialogFooterButton': {
        display: 'flex',
        justifyContent: 'center',
        gridGap: theme.spacing(4),
        padding: theme.spacing(2),
      },
    },
  })
);

export interface RenameCategoryDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  categoryId?: number;
  setCurrentCategoryId?: (id: number) => void;
  setAreCategoriesChanged: (value: boolean) => void;
}

type FormValues = {
  title: string;
  exclusiveOrderTypes: Array<OrderType>;
};

const SubmitCategoryDialog = (props: RenameCategoryDialogProps) => {
  const { open, setOpen, title, categoryId, setCurrentCategoryId, setAreCategoriesChanged } = props;

  const [currentCategoryItem, setCurrentCategoryItem] = useState<MerchandiseCategory>();
  const [isSaveDisabled, setSaveDisabled] = useState(true)

  const theme = useTheme();
  const classes = useStyles();
  const reduxDispatch = useAppDispatch();
  const { merchant, triggerNotification } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const categoriesList = useAppSelector(getMerchandiseCategories);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    setValue,
    reset,
    watch,
    getValues,
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      exclusiveOrderTypes: []
    },
  });

  useEffect(() => {
    if (isDirty)
      setSaveDisabled(!isValid)
  }, [isDirty, isValid])

  const formValues = getValues();
  const exclusiveOrderTypes = watch('exclusiveOrderTypes', formValues.exclusiveOrderTypes);

  useEffect(() => {
    if (categoryId) {
      const currentItem = categoriesList.find((item) => item.id === categoryId);

      if (currentItem) {
        setCurrentCategoryItem(currentItem);
        setValue('title', currentItem.name);
        setValue('exclusiveOrderTypes', currentItem.exclusiveOrderTypes);
      }
    }
  }, [categoryId, currentCategoryItem]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleOnSaveClick = (data: any) => {
    setSaveDisabled(true)

    if (!categoryId) {
      axios
        .post<MerchandiseCategory>(
          '/merchandises/categories',
          {
            name: data.title,
            exclusiveOrderTypes,
            merchantId: merchant?.id,
            displayOrder: getHighestDisplayOrderCategory(categoriesList) + 1,
          },
          { headers: getAuthHeaders(token) }
        )
        .then(({ data }) => {
          if (data) {
            reduxDispatch(merchandiseFetchCategoriesSucceed(data));
            reset();
            setAreCategoriesChanged(true);
          }
          setOpen(false);
          triggerNotification(
            true,
            `Category added successfully`,
            NotificationSeverity.SUCCESS,
            true
          );
        })
        .catch(() => {
          triggerNotification(
            true,
            `Error while adding category`,
            NotificationSeverity.ERROR,
            true
          );
        });
    } else {
      axios
        .put(
          `/merchandises/categories/${categoryId}`,
          {
            name: data.title,
            exclusiveOrderTypes,
            merchantId: merchant?.id,
            description: data.description,
            displayOrder: data.displayOrder,
          },
          { headers: getAuthHeaders(token) }
        )
        .then(({ data }) => {
          if (data) {
            reduxDispatch(merchandiseFetchCategoriesSucceed(data));
            setAreCategoriesChanged(true);
            reset();
          }
          triggerNotification(
            true,
            `Category updated successfully`,
            NotificationSeverity.SUCCESS,
            true
          );
          setOpen(false);
        })
        .catch(() => {
          triggerNotification(true, `Category update failed`, NotificationSeverity.ERROR, true);
        });
    }

    setCurrentCategoryId && setCurrentCategoryId(-1);
  };

  const handleExclusiveOrderTypeChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value
    setValue('exclusiveOrderTypes', selectedValues)
    setSaveDisabled(false)
  };

  const registerField = (name: any, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);

    return { ...inputProps, inputRef };
  };

  const validateCategoryTitle = (value: string) => {
    if (!value) return;

    const reservedWords = ["featured", "everything else"]
    if (reservedWords.includes(value.toLocaleLowerCase())) return t("Menu_Categories_Title_IsFeatured", { value: value });

    if (!categoryId) {
      // when creating a new category
      return (
        !Boolean(categoriesList.find((item) => item.name === value)) ||
        t("Menu_Categories_Title_Find", { value: value })
      );
    }
  };

  return (
    <DialogWrapper width="400px" title={title} handleClose={handleClose} open={open}>
      <form onSubmit={handleSubmit(handleOnSaveClick)} className={classes.submitCategory}>
        <TextField
          fullWidth
          label={t("Menu_Categories_Title")}
          helperText={errors.title && errors.title?.message}
          error={!!errors.title}
          {...registerField('title', {
            required: t('Menu_Categories_Title_RequiredText'),
            validate: {
              validateCategoryTitle,
            },
          })}
          onChange={() => setSaveDisabled(false)}
        />

        <FormControl sx={{ mt: 1.2, width: '100%' }}>
          <InputLabel
            sx={{ top: -5, left: theme.spacing(2) }}
            id="exclusive-order-type-select"
          >
            {t("Menu_Categories_ExclusiveOrderTypes")}
          </InputLabel>
          <Select
            labelId="exclusive-order-type-select"
            multiple
            value={exclusiveOrderTypes ?? []}
            onChange={handleExclusiveOrderTypeChange}
            input={<OutlinedInput label={t("Menu_Categories_ExclusiveOrderTypes")} />}
            renderValue={(selected) => selected.join(', ')}
          >
            {
              Object.keys(OrderType)
                .map((key) => {
                  const value = OrderType[key]
                  const isChecked = !isEmptyOrNil(exclusiveOrderTypes) && exclusiveOrderTypes.indexOf(value) > -1

                  return (
                    <MenuItem key={key} value={value}>
                      <Checkbox checked={isChecked} />
                      <ListItemText primary={value} />
                    </MenuItem>
                  )
                })
            }
          </Select>
          <FormHelperText>{t('Menu_Categories_ExclusiveOrderTypes_Help')}</FormHelperText>
        </FormControl>

        <div className="dialogFooterButton">
          <Fab
            variant="extended"
            color="primary"
            size="medium"
            sx={{ pl: 4, pr: 4 }}
            type="submit"
            disabled={isSaveDisabled}
          >
            {t("Save")}
          </Fab>
        </div>
      </form>
    </DialogWrapper>
  );
};

export default SubmitCategoryDialog;
