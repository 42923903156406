import React, { useContext, useMemo, useState } from 'react'
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Divider, Grid, Typography, } from '@material-ui/core';
import { defaultTo } from 'ramda';

// src
import { MerchantStatsContext } from '../../../context/MerchantStats/MerchantStatsContext';
import { AppContext } from '../../../context/AppContext';
import { MerchantAggregatedCustomerStats } from '../../../services/models';
import { ColoredPaper } from '../../../bos_common/src';
import SimpleLoader from '../../../bos_common/src/components/SimpleLoader';
import { MerchantCustomerStats } from '../../../bos_common/src/types/MerchantCustomerStatsType';
import MerchantStatsChart from '../../../components/Stats/MerchantStatsChart';
import MerchantStatsCard from '../../../components/Stats/MerchantStatsCard';
import {
  extractCustomerStatsData, formatMerchantCustomerStats, getComparisonStats
} from '../../../utils/merchantStatsUtils';
import BoxWithClick from '../../../components/common/BoxWithClick';
import useStatsApiService from '../statsApiService';

type ChartType = 'newCustomer' | 'returningCustomer'

const CustomerInsights = () => {
  const [selectedChart, setSelectedChart] = useState<ChartType>('newCustomer');
  const { t } = useTranslation();

  const { merchant } = useContext(AppContext);
  const { statsTimePeriod } = useContext(MerchantStatsContext);

  const {
    isLoading,
    currentStatsData,
    comparisonStatsData
  } = useStatsApiService('/merchants/customer-stats')

  const currentCustomerStatsData: MerchantAggregatedCustomerStats = useMemo(
    () => formatMerchantCustomerStats(currentStatsData?.stats as Array<MerchantCustomerStats>),
    [currentStatsData?.stats]
  );

  const comparisonCustomerStatsData: MerchantAggregatedCustomerStats = useMemo(
    () => formatMerchantCustomerStats(comparisonStatsData?.stats as Array<MerchantCustomerStats>),
    [comparisonStatsData?.stats]
  );

  const currentStats = useMemo(
    () => extractCustomerStatsData(currentCustomerStatsData),
    [currentCustomerStatsData]
  );
  const comparisionStats = useMemo(() => extractCustomerStatsData(comparisonCustomerStatsData), [comparisonCustomerStatsData])

  const getTotalPercentage = (value: number) => {
    return defaultTo(0, (value / currentStats.totalCustomers * 100)).toFixed(2);
  }

  return (
    <ColoredPaper>
      <SimpleLoader loading={isLoading} />
      <div className="dashboard-container">
        <If condition={!currentStatsData}>
          <Then>{!isLoading && <CardHeader subheader={t('CustomerInsights_NoData')} />}</Then>
          <Else>
            <CardHeader
              title={currentStats.totalCustomers}
              subheader={t('CustomerInsights_TotalCustomers')}
              titleTypographyProps={{
                variant: 'h4',
              }}
            />
            <Divider />
            <CardContent>
              <div className="sections-container">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <BoxWithClick isSelected={selectedChart === 'newCustomer'} handleClick={() => setSelectedChart('newCustomer')}>
                      <MerchantStatsCard
                        headTitle={t('CustomerInsights_NewCustomers')}
                        headValue={
                          <Typography sx={{ fontSize: '2rem' }}>
                            {currentStats.totalNewCustomers}&nbsp;
                            <Typography variant='subtitle2' component="span">
                              ({getTotalPercentage(currentStats.totalNewCustomers)}%)
                            </Typography>
                          </Typography>
                        }
                        comparisonData={
                          getComparisonStats(
                            currentStats.totalNewCustomers,
                            comparisionStats.totalNewCustomers,
                            statsTimePeriod
                          )
                        }
                      />
                    </BoxWithClick>
                  </Grid>
                  <Grid item xs={6}>
                    <BoxWithClick isSelected={selectedChart === 'returningCustomer'} handleClick={() => setSelectedChart('returningCustomer')}>
                      <MerchantStatsCard
                        headTitle={t('CustomerInsights_Returning')}
                        headValue={
                          <Typography sx={{ fontSize: '2rem' }}>
                            {currentStats.totalReturningCustomers}&nbsp;
                            <Typography variant='subtitle2' component="span">
                              ({getTotalPercentage(currentStats.totalReturningCustomers)}%)
                            </Typography>
                          </Typography>
                        }
                        comparisonData={
                          getComparisonStats(
                            currentStats.totalReturningCustomers,
                            comparisionStats.totalReturningCustomers,
                            statsTimePeriod
                          )
                        }
                      />
                    </BoxWithClick>
                  </Grid>
                  <Grid item xs={12}>
                    <Card elevation={3}>
                      <If condition={selectedChart === 'newCustomer'}>
                        <Then>
                          <MerchantStatsChart
                            chartTitle={`${currentStats.totalNewCustomers}`}
                            chartLabel={t('CustomerInsights_NewCustomers')}
                            lastWeekSalesData={comparisonStatsData}
                            currentWeekSalesData={currentStatsData}
                            timeZone={merchant?.timeZone}
                            dataKey="newCustomers"
                          />
                        </Then>
                        <Else>
                          <MerchantStatsChart
                            chartLabel={t('CustomerInsights_Returning')}
                            chartTitle={`${currentStats.totalReturningCustomers}`}
                            lastWeekSalesData={comparisonStatsData}
                            currentWeekSalesData={currentStatsData}
                            timeZone={merchant?.timeZone}
                            dataKey="returningCustomers"
                          />
                        </Else>
                      </If>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Else>
        </If>
      </div>
    </ColoredPaper>
  );
};

export default CustomerInsights