import {
  createStyles, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useMediaQuery
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { If } from 'react-if';
import { useHistory, useParams } from 'react-router';
import { ColoredPaper, getAuthHeaders } from '../bos_common/src';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { UserContext } from '../bos_common/src/context/UserContext';
import axios from '../bos_common/src/services/backendAxios';
import { AppContext } from '../context/AppContext';
import { Merchant } from '../services/models';
import useFetchMerchant from '../services/useFetchMerchant';
import { DeviceStatus } from '../types/DeviceStatus';
import { isEmptyOrNil } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    }
  })
)

const getDeviceStatus = async (merchant: Merchant, token: string): Promise<DeviceStatus> => {
  const response = await axios.get<DeviceStatus>(`/devices/merchants/${merchant.id}`, {
    headers: getAuthHeaders(token),
  });
  return response.data;
}

interface IMerchantOfflinePresenceParams {
  merchantUsername: string;
}

const MerchantDeviceStatusPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useContext(UserContext);

  const { merchant } = useContext(AppContext);
  const { merchantUsername } = useParams<IMerchantOfflinePresenceParams>();
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>();

  const { t } = useTranslation()

  const onBack = () => {
    history.goBack();
  };

  const loading = useFetchMerchant({merchantUsername})

  useEffect(() => {
    if (merchant) {
      getDeviceStatus(merchant, token).then(data => { setDeviceStatus(data) });
    }

  }, [merchant, token]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  
  return (
    <div className="container">
      <ColoredPaper>
        <SimpleLoader loading={loading} />
        <If condition={!isEmptyOrNil(merchant)}>
          <div className={classes.root}>
            <TableContainer component={Paper}>
              <Table aria-label="caption table" size="small">
                <caption>Updated at { new Date().toLocaleString() }</caption>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <Typography><b>{t("Devices")}</b></Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography><b>{isMobile ? t("No_Of_Conn") : t("Connections")}</b></Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(deviceStatus || {}).map((key: string) => (
                    <TableRow
                      key={key}>
                      <TableCell component="td" scope="row">
                        {key.toUpperCase()}
                      </TableCell>
                      <TableCell>
                        { deviceStatus![key].length }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </If>
      </ColoredPaper>
    </div>
  );
};

export default MerchantDeviceStatusPage;
