import React, { useContext, useState } from "react";
import { CardActionArea, createStyles, Dialog, DialogContent, DialogTitle, Divider, IconButton, makeStyles, MenuItem, MenuList, Stack, Theme, Typography } from "@material-ui/core";
import { Close, LanguageOutlined, NavigateNext } from "@material-ui/icons";
import { rgba } from "polished";
import { useTranslation } from "react-i18next";
import { prop } from "ramda";

import i18n from "../../utils/i18n";
import { AppContext } from "../../context/AppContext";
import { LocalesType, LOCAL_LIST, SupportedLocales } from "../../constants/locale";
import CustomDialog from "../common/CustomDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),

      '& .split-button-menu': {
        margin: theme.spacing(2, 1),
        padding: 0,
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',
      },

      '& .menu-item': {
        alignItems: 'center',
        display: 'grid',
        justifyContent: 'space-between',
        gridTemplateColumns: '1fr auto',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,

        '&:last-child': {
          borderBottom: 0,
        },

        '& span': {
          maxWidth: theme.spacing(17.5),
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    }
  })
)

const LanguageSelector = () => {
  const { locale, setLocale } = useContext(AppContext);
  const classes = useStyles();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleMenuItemClick = (v: SupportedLocales) => {
    localStorage.setItem('bos_locale', v);
    i18n.changeLanguage(v);
    setLocale(v);
  }
  const toggleLanguageSelector = () => setOpen(!open)
  const selectedLangauge = prop('label', LOCAL_LIST.find((item: LocalesType) => item.value === locale))
  return (
    <CardActionArea className="language-selector" onClick={toggleLanguageSelector}>
      <div className='content-container'>
        <Stack direction={'row'} alignItems={'center'} justifyContent='center'>
          <LanguageOutlined className='language-selector' />
          <Typography variant='subtitle1' textAlign={'center'} className='language-selector'>
            {t('Language')}: {selectedLangauge}
          </Typography>
        </Stack>

        <CustomDialog
          title={t('Language')}
          open={open}
          setOpen={toggleLanguageSelector}
          onBackdropClick={toggleLanguageSelector}
          onClose={toggleLanguageSelector}
          className={classes.root}
          fullWidth
        >
          <DialogContent>
            <MenuList className="split-button-menu" id="split-button-menu">
              {LOCAL_LIST.map((localeData) => (
                <MenuItem
                  key={localeData.value}
                  selected={locale === localeData.value}
                  onClick={() => handleMenuItemClick(localeData.value)}
                  className='menu-item'
                >
                  <span>{localeData.label}</span> <NavigateNext color='primary' />
                </MenuItem>
              ))}
            </MenuList>
          </DialogContent>
        </CustomDialog>
      </div>
    </CardActionArea>
  )
}

export default LanguageSelector