import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import classNames from 'classnames';

import { isEmptyOrNil } from '../../utils';

type Props = {
  reverse?: boolean
}
const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      "&.plus": {
        color: ({ reverse }) => reverse ? "#e53935" : "#66bb6a"
      },
      "&.minus": {
        color: ({ reverse }) => reverse ? "#66bb6a" : "#e53935"
      }
    }
  })
)

export enum PlusOrMinus {
  plus = 'plus',
  minus = 'minus',
  none = 'none'
}
export type PercentageComparisonProps = {
  percentage: number | string | null,
  plusOrMinus: PlusOrMinus,
  reverse?: boolean,
  customClass?: string
}
const PercentageComparison = (props: PercentageComparisonProps) => {
  const { percentage, plusOrMinus, customClass, reverse } = props
  const classes = useStyles({ reverse });
  let symbol;

  switch (plusOrMinus) {
    case 'plus': symbol = "+"
      break;
    case 'minus':
    default: symbol = "";
      break;
  }

  const containerClasses = classNames(classes.root, [plusOrMinus, customClass])

  if (isEmptyOrNil(percentage)) return null;

  return (
    <span className={containerClasses}>
      <Typography sx={{ fontSize: 14 }} component='span' gutterBottom>
        {symbol}{percentage}%
      </Typography>
    </span>
  )
}

export default PercentageComparison