import * as Sentry from "@sentry/react"
import { RewriteFrames } from "@sentry/integrations"
import axios from "axios"

import { Logger, LogLevels } from "./loggerTypes"
import { isProduction } from "../../utils"
import config from '../../config/config'

console.log("------------ SENTRY LOG------------ ")
console.log("------------ SENTRY LOG------------ ")
console.log(`config.buildEnv: ${config.appConfig.buildEnv}`)

/**
 * We use two Sentry instances
 * DSN is used for error and exception logging
 */
const DSN: string = "https://0412873c30c644429077285af9e9eb88@o1031572.ingest.sentry.io/5998320"

/**
 * An array of strings or regexps that'll be used to ignore specific errors based on their type/message
 */
const IGNORE_ERRORS: (string | RegExp)[] = []

const _userId: string | null = null
let _userProperties: Record<string, any> = {}

const clientConfiguration: Partial<Sentry.BrowserOptions> = {
  // production or development
  environment: config.appConfig.buildEnv,

  // Required to resolve correct transport within webview
  transport: Sentry.Transports.FetchTransport,

  // // Used to resolve source maps uploaded at build time
  // release: window.COMMIT_HASH,

  // Capture stack traces for messages
  attachStacktrace: true,

  // Pass each stack frame through rewriter function
  integrations: integrations =>
    integrations
      .filter(({ name }) => name !== "TryCatch")
      .concat([
        new RewriteFrames({
          iteratee: rewriteFrame,
        }),
        new Sentry.Integrations.TryCatch({
          requestAnimationFrame: false,
        }),
      ]),

  ignoreErrors: IGNORE_ERRORS,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 0.5,
}

/**
 * This is used to filter out error BEFORE being sent to any logging service (Sentry/Firebase/Snowplow)
 * @param error
 */
export function shouldIgnoreError(error: string | Error): boolean {
  // Filter out Axios cancel errors
  if (axios.isCancel(error)) {
    return true
  }

  if (typeof error === "string") {
    return IGNORE_ERRORS.some(matcher => error.match(matcher))
  }

  if (typeof error?.name === "string") {
    if (IGNORE_ERRORS.some(matcher => error.name.match(matcher))) {
      return true
    }
  }

  if (typeof error?.message === "string") {
    if (IGNORE_ERRORS.some(matcher => error.message.match(matcher))) {
      return true
    }
  }

  return false
}

/**
 * Rewrite filepaths from webviews to relative paths
 * So that they match our source maps in Sentry
 */
export function rewriteFrame(frame: Sentry.StackFrame): Sentry.StackFrame {
  frame.filename = frame.filename && frame.filename.replace(/^.*\/js\//, "~/js/")
  return frame
}

const beforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const { originalException } = hint
  // TODO: add any formatting if required
  if (!isProduction()) {
    console.log("------------------")
    console.log("logging error to sentry")
    console.log(event)
    console.log("------------------")
  }

  return event
}

// Set up the primary and secondary instances
Sentry.init({ ...clientConfiguration, dsn: DSN, beforeSend })

/**
 * Logging function wraps the message with relevant
 * context and posts it to Sentry
 */
function sentryLog(
  level: LogLevels,
  exception: string | Error,
  data?: { [s: string]: any },
  tags?: { [s: string]: string }
) {
  // Info and debug are no-ops with this logger
  if ([LogLevels.info, LogLevels.debug].includes(level)) {
    return
  }

  if (shouldIgnoreError(exception)) {
    return
  }

  // Use primary instance for serious errors, secondary hub for warnings and below
  const hub: Sentry.Hub = Sentry.getCurrentHub()

  hub.withScope(scope => {
    scope.setLevel((level as any) as Sentry.Severity)
    const fingerprint = ["{{default}}"]
    if (exception instanceof Error) {
      fingerprint.push(exception.message)
    }
    scope.setFingerprint(fingerprint)

    if (data) {
      Object.keys(data).forEach(key => {
        scope.setExtra(key, data[key])
      })

      /*
        set context for the data passed, 
        otherwise that'll get added to Additional Data in Sentry
        Additional data is not visible on the event issue page
      */
      scope.setContext("Additional Data", data)
    }

    if (tags) {
      Object.keys(tags).forEach(key => {
        scope.setTag(key, tags[key])
      })
    }

    hub.captureException(exception)
  })
}

/**
 * Set the user ID in sentry, combined with userProperties if already set
 */
export function setUserId(userId: string): void {
  updateUserScope(userId, _userProperties)
}

/**
 * Set the user properties in sentry, combined with user ID if already set
 */
export function setUserProperties(userProperties: Record<string, any>): void {
  _userProperties = userProperties
  updateUserScope(_userId, _userProperties)
}

/**
 * Combine the user data and set on the Sentry scope
 */
export function updateUserScope(userId: string | null = null, userProperties: Record<string, any> | null = {}): void {
  Sentry
    .getCurrentHub()
    .configureScope(scope => {
      const userData = {
        ...userProperties,
        ...(userId && { id: userId }),
      }

      scope.setUser(userData)
    })
}

/**
 * Helper function for adding tags to the sentry scope
 * @param key arbitrary string, becomes filterable in Sentry UI
 */
export function setTag(key: string, value: string | boolean | number | null): void {
  Sentry
    .getCurrentHub()
    .configureScope((scope: Sentry.Scope) => {
      scope.setTag(key, value)
    })
}

// Configurable logger module to push events to Sentry
const sentryLogger: Logger = {
  fatal: sentryLog.bind(null, LogLevels.fatal),
  error: sentryLog.bind(null, LogLevels.error),
  warning: sentryLog.bind(null, LogLevels.warning),
  debug: sentryLog.bind(null, LogLevels.debug),
  info: sentryLog.bind(null, LogLevels.info),
  setUserId,
  setUserProperties,
  setVersion: (version: string) => {
    setTag("version", version)
  },
  setDeviceId: (deviceId: string) => {
    // Set both as a tag and in user context
    setTag("deviceId", deviceId)
    setUserProperties({ ..._userProperties, deviceId })
  },
  setTag
}

export default sentryLogger
