import React from 'react';
import { createStyles, Dialog, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';

type DialogStyleProps = {
  width?: string;
}

const useStyles = makeStyles<Theme, DialogStyleProps>((theme) =>
  createStyles({
    dialogWrapper: {
      '& .MuiDialog-paper': {
        maxWidth: ({width}) => width && width,
        width: "100%",
      },

      '& .MuiPaper-root': {
        padding: '20px 50px',
      },

      '& .closeIcon': {
        position: 'absolute',
        top: "30px",
        right: "20px",
        cursor: 'pointer',
      },
      
      '& .dialogTitle': {
          textAlign: "center",
          padding: "5px 20px",
          marginBottom: "20px",

          '& .MuiTypography-root': {
              fontWeight: "bold"
          }
      },

      '& .dialogSubTitle': {
          textAlign: "center",
          color: "#777777",
          margin: 0,
          marginBottom: "20px",
      }
    },
  })
);

type DialogWrapperProps = {
  handleClose: () => void;
  open: boolean;
  title: string;
  subTitle?: string;
  children: React.ReactChild;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  width?: string;
};

const DialogWrapper = (props: DialogWrapperProps) => {
  const { open, title, subTitle, children, handleClose, maxWidth, width } = props;

  const classes = useStyles({...props});

  return (
    <Dialog
      className={classes.dialogWrapper}
      maxWidth={maxWidth || 'xs'}
      onClose={handleClose}
      open={open}
    >
      <Close onClick={handleClose} className="closeIcon" />
      <DialogTitle className="dialogTitle">{title}</DialogTitle>

      {subTitle && <p className="dialogSubTitle">{subTitle}</p>}

      {children}
    </Dialog>
  );
};

export default DialogWrapper;
