import React, { useContext } from 'react';
import { Fab } from '@material-ui/core';
import { propOr } from 'ramda';

import { getAuthHeaders } from '../../../../utils';
import { AppContext } from '../../../../context/AppContext';

import { UserContext } from '../../../../bos_common/src/context/UserContext';
import axios from '../../../../bos_common/src/services/backendAxios';

import { OrderActionTemplateProps } from './types';
import useActionTemplateStyles from './styles';
import ActionHeader from './ActionHeader';
import OrderItems from '../../../../bos_common/src/components/Order/OrderItems';
import OrderInfo from '../../OrderInfo';
import { useTranslation } from 'react-i18next';
import { NotificationSeverity } from '../../../../types/NotificationSlice';


const PrintReceiptOrderAction = (props: OrderActionTemplateProps) => {
  const { order, closeDrawer } = props;
  const classes = useActionTemplateStyles();
  const { t } = useTranslation();

  const { token } = useContext(UserContext);
  const { triggerNotification, merchant } = useContext(AppContext)

  // functions to handle action
  const handleConfirm = () => {
    const OnFailureMessage = `Failed to print receipt for ${order.userDisplayName}'s order.`
    axios.post(
      `merchants/orders/${order.id}/receipt-print`,
      null,
      { headers: getAuthHeaders(token) }
    ).then((response) => {
      if (response.status === 200) {
        triggerNotification(true, `Receipt for ${order.userDisplayName}' order is printed.`, NotificationSeverity.SUCCESS)
      } else {
        triggerNotification(true, OnFailureMessage, NotificationSeverity.WARNING)
      }
    }).catch(err => {
      triggerNotification(true, propOr(OnFailureMessage, 'message', err), NotificationSeverity.ERROR)
    });
    closeDrawer()
  }

  const ctaButton = t("Order_Print_ButtonText");

  return (
    <div className={classes.actionTemplateContainer}>
      <ActionHeader title={t("UserReceipt")} />

      <OrderInfo order={order} merchant={merchant} />
      <OrderItems order={order} />

      <div className='actions-container'>
        <Fab
          variant="extended"
          color="primary"
          component="span"
          onClick={handleConfirm}>
          {ctaButton}
        </Fab>
      </div>
    </div>
  )
}

export default PrintReceiptOrderAction