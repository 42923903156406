import React, { createRef, useContext } from 'react';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import { Button, Grid, Card, CardContent, Typography, Avatar, Divider, Paper, StylesProvider } from '@material-ui/core';
import { If } from 'react-if';

import { LineItem, Merchant, Order, OrderStatus } from '../services/models';
import { getMarketPlaceOrderStatusURL } from '../bos_common/src/services/urls';
import { displayOrderShortCode } from '../bos_common/src/services/orderUtils';
import { displayDateTime } from '../utils';
import OrderItemCard from './Order/OrderItemCard';

import "./sticker.css";

const OrderSlogan = () => {
  const slogans = [
    "If a turtle does not have a shell, is it naked or homeless?",
    "Your smile is a curve that get a lot of things straight",
    "Any day above ground is a good day",
    "There is no time like the pleasant",
  ]
  const index = Math.floor(Math.random() * slogans.length)
  return (
    <Grid container>
      <Paper variant="outlined" className="slogan" >
        <Typography variant="subtitle1">
          {slogans[index]}
        </Typography>
      </Paper>
    </Grid>
  )
}

const OrderSticker = (props: {
  merchant: Merchant,
  order: Order,
  lineItem: LineItem,
  index: number,
  total: number,
}) => {
  const { order, merchant, lineItem, index, total } = props;
  const { officialName, logo } = merchant;
  const { userDisplayName, readyTime } = order;

  const url = getMarketPlaceOrderStatusURL(order);
  const stickerCardRef = createRef<HTMLDivElement>();

  return (
    <StylesProvider injectFirst>
      <Card elevation={0}>
        <Card style={{ padding: 8, margin: 16 }}>
          <div className="sticker" ref={stickerCardRef} >
            <Grid container direction={"row"}>
              <Grid item xs={3}>
                <Avatar alt={officialName} variant="rounded" className="logo" src={logo} />
              </Grid>
              <Grid item xs={9}>
                <If condition={!!userDisplayName}>
                  <Typography variant="subtitle1" component="div">
                    {userDisplayName}
                  </Typography>
                </If>
                <Typography variant="subtitle1" component="div">
                  {`Order no.: ${displayOrderShortCode(order)} (${index + 1}/${total})`}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  {readyTime && displayDateTime(readyTime)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <div className="items">
              <OrderItemCard
                item={lineItem}
                index={index}
                order={order}
                readOnly
              />
            </div>
            <OrderSlogan />
            <Divider />
            <Grid container>
              <Grid item xs={12} justifyContent="center" display="flex">
                <QRCode value={url} size={80} />
              </Grid>
              <Grid item xs={12} justifyContent="center" display="flex" style={{ marginTop: 8 }}>
                <Typography variant="subtitle1" component="div">
                  Scan to claim your 1M points
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Card>
        <CardContent>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="secondary">
                Print
              </Button>
            )}
            pageStyle={"@page { size: auto landscape; }"}
            content={() => stickerCardRef.current}
          />
        </CardContent>
      </Card>
    </StylesProvider>
  )
}


const flattenOrderItems = (items: LineItem[]): LineItem[] => {
  const flattendItems: LineItem[] = [];
  items.forEach((item) => {
    for (let i = 0; i < item.quantity; i++) {
      const quantity = 1;
      const itemCopy = { ...item, quantity } as LineItem;
      flattendItems.push(itemCopy);
    }
  });
  return flattendItems;
};

const MerchantOrderSticker = (props: {
  merchant: Merchant,
  order: Order,
}) => {
  const { readyTime, status, lineItems } = props.order;

  if (!readyTime || status !== OrderStatus.FULFILLED) {
    return null;
  }

  const onManageXPrinter = () => {


  }

  const flattendItems = flattenOrderItems(lineItems)

  return (
    <div>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          Sticker/Receipt preview
        </Typography>
        <Grid container>
          {flattendItems.map((item, index) => {
            return (
              <OrderSticker
                key={index}
                merchant={props.merchant}
                order={props.order}
                lineItem={item}
                index={index}
                total={flattendItems.length} />
            )
          }
          )}
        </Grid>
      </CardContent>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          Printer management
        </Typography>
        <Grid container>
          <Button variant="contained" color="secondary" onClick={onManageXPrinter}>
            Xprinter
          </Button>
        </Grid>
      </CardContent>
    </div>
  );
};

export default MerchantOrderSticker;