import React, { useMemo } from 'react';
import { Icon, Stack, Tooltip } from '@material-ui/core';
import { isEmpty } from 'ramda';

// src
import {
  CaffeineFreeIcon, ColdIcon, DairyFreeIcon, GluttenFreeIcon, HalalIcon,
  HotIcon, KosherIcon, LactoseFreeIcon, LocallyGrownIcon, PeanutFreeIcon, ShellfishFreeIcon, SoyFreeIcon,
  SpicyIcon, VeganIcon, VegetarianIcon
} from '../../assets/icons/allergens';
import { AllergenIngredients } from '../../types/MerchandiseType';

interface MerchandiseAllergensProps {
  allergens?: AllergenIngredients | null,
  iconSize?: "small" | "inherit" | "large" | "medium",
  limit?: number
}

export const AllergenIngredientsLabelsMap = {
  'nuts_free': 'Nuts Free',
  'shellfish_free': 'Shellfish Free',
  'soy_free': 'Soy Free',
  'gluten_free': 'Gluten Free',
  'dairy_free': 'Dairy Free',
  'caffeine_free': 'Caffiene Free',
  'cold': 'Cold',
  'hot': 'Hot',
  'spicy': 'Spicy',
  'halal': 'Halal',
  'kosher': 'Kosher',
  'vegan': 'Vegan',
  'vegetarian': 'Vegetarian',
  'locally_grown': 'Locally Grown',
  'lactose_free': 'Lactose Free'
}

export const AllergenIngredientsList: Array<keyof typeof AllergenIngredientsLabelsMap> =
  Object.keys(AllergenIngredientsLabelsMap) as Array<keyof typeof AllergenIngredientsLabelsMap>;

export const getAllergenIcon = (allergen: keyof AllergenIngredients) => {
  switch (allergen) {
    case "halal":
      return HalalIcon
    case "vegan":
      return VeganIcon
    case "spicy":
      return SpicyIcon
    case "kosher":
      return KosherIcon
    case "caffeine_free":
      return CaffeineFreeIcon
    case "cold":
      return ColdIcon
    case "dairy_free":
      return DairyFreeIcon
    case "gluten_free":
      return GluttenFreeIcon
    case "hot":
      return HotIcon
    case "nuts_free":
      return PeanutFreeIcon
    case "shellfish_free":
      return ShellfishFreeIcon
    case "soy_free":
      return SoyFreeIcon
    case "vegetarian":
      return VegetarianIcon
    case "lactose_free":
      return LactoseFreeIcon
    case "locally_grown":
    default:
      return LocallyGrownIcon
  }
};

const MerchandiseAllergens = ({ allergens, iconSize = 'medium', limit = -1 }: MerchandiseAllergensProps): JSX.Element | null => {
  if (!allergens || isEmpty(allergens)) return null;

  const allergensList = useMemo(() => {
    const list = limit > 0 ? Object.keys(allergens).slice(0, limit) : Object.keys(allergens);
    return list.reduce<JSX.Element[]>((acc, key: string) => {
      if (allergens[key]) {
        acc.push(
          <Tooltip title={AllergenIngredientsLabelsMap[key]} key={key}>
            <Icon key={key} component={getAllergenIcon(key as keyof AllergenIngredients)} fontSize={iconSize} />
          </Tooltip>
        )
      }
      return acc;
    }, []);
  }, [allergens]);

  return (
    <Stack direction={'row'} alignItems={'center'} gap={0.5} flexWrap={'wrap'}>
      {allergensList}
    </Stack>
  );
}

export default MerchandiseAllergens;
