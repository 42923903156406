import { Theme } from "@material-ui/core"

export const chatContainerStyle = (theme: Theme) => {
  return {
    height: "100%",

    "& .str-chat__main-panel": {
      height: "100%",
      display: "grid",
      alignItems: "flex-end",
      gridTemplateRows: "1fr auto",
      overflow: "auto",
      padding: theme.spacing(0, 5),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 2, 0)
      },
    },

    "& .str-chat__input-flat": {
      padding: 0,
      position: "sticky",
      bottom: 0,
      borderRadius: theme.spacing(1),
    },

    "& .str-chat__date-separator": {
      marginBottom: theme.spacing(3),
      padding: 0,
      justifyContent: "center",
      borderRadius: theme.spacing(1),

      "& .str-chat__date-separator-line": {
        display: "none",
      },

      "& .str-chat__date-separator-date": {
        background: theme.palette.background.paper,
        padding: theme.spacing(1),
      },
    }
  }
}

export const ChatMessageInputStyle = (theme: Theme) => {
  return {
    '& *': {
      '&:focus': {
        outline: 'none',
        boxShadow: 'unset',
      },
    },

    '& .str-chat__li': {
      display: 'flex',
    },

    '& .str-chat__input-flat-wrapper': {
      alignItems: 'center',
    },

    "& .str-chat__input-flat-emojiselect": {
      top: "50%",
      transform: "translateY(-50%)",
      left: theme.spacing(1),
    },

    "& .rfu-file-upload-button": {
      top: "50%",
      transform: "translateY(-50%)",

      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(0.5),
      },
    },

    "& .str-chat__input-flat": {
      "& .rta__textarea": {
        [theme.breakpoints.down("sm")]: {
          minHeight: theme.spacing(5),
          maxHeight: theme.spacing(5),
        },
      },
    },

    '& .str-chat': {
      '&.messaging': {
        background: "transparent",

        '& .str-chat__list': {
          background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #FFFFFF',
        },

        '& .str-chat__input-flat': {
          background: 'rgba(54, 66, 69, 0.05)',
          borderRadius: theme.spacing(6),
          padding: theme.spacing(0, 1.5),

          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0.5)
          },

          '& .str-chat__textarea>textarea': {
            background: 'unset',
            paddingLeft: theme.spacing(5),

            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(1.4, 5)
            },

            '&:focus': {
              boxShadow: 'unset',
            }
          }
        },
      }
    },

    '& .str-chat__send-button': {
      background: theme.palette.primary.main,
      width: theme.spacing(5),
      height: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(6),
      padding: 0,
      paddingLeft: theme.spacing(0.4),
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },

      '& svg': {
        '& path': {
          fill: theme.palette.background.paper,
        }
      }
    }
  }
}