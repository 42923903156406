import { MerchandiseCategory } from '../../../services/models';
import MerchandiseCategorySliceState from '../../../types/MerchandiseCategorySlice';
import { RootState } from '../../store';
import merchandiseCategory from './merchandiseCategorySlice';

export const getMerchandiseCategoriesFromSlice = (state: RootState): MerchandiseCategory[] => {
    const { entities } = state.merchandiseCategory as MerchandiseCategorySliceState;
    return Object.values(entities.byId);
  }

export default merchandiseCategory;
