import * as locales from '@material-ui/core/locale';

export type SupportedLocales = keyof typeof locales;

export interface LocalesType {
  label: string;
  value: SupportedLocales;
}

export const LOCAL_LIST: LocalesType[] = [
  { label: 'English', value: 'enUS' },
  { label: 'Español', value: 'esES' },
  { label: '简体中文', value: 'zhCN' },
];
