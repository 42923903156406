import React from 'react';
import classNames from 'classnames';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';

import { ColoredPaper } from './Papers';

// default alignment is center for mobile and right for web
type Alignment = 'default' | 'center' | 'right';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:({ alignment, buttonCount }: any)=> ({
      boxShadow: "none !important",
      '& .fab': {
        position: 'fixed',
        width: '100%',
        bottom: theme.spacing(1),
        right: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        zIndex: 10,
        pointerEvents: 'none',
        '& .MuiButtonBase-root': {
          pointerEvents: 'auto',
        },
        ...( alignment !== 'right' && {
          [theme.breakpoints.down('sm')]: {
            '& .MuiButtonBase-root': {
              width: 'calc(100% - 32px)',
              marginRight: 0,
              marginLeft: 0,
            }
          }}
        ),
      },
      '& .MuiFab-root.backdrop': {
        margin: theme.spacing(3),
        visibility: 'hidden',
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(3*buttonCount),
        }
      },
      '& .multifab': {
        position: 'fixed',
        width: '100%',
        bottom: theme.spacing(1),
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        zIndex: 10,
        pointerEvents: 'none',
        '& .MuiButtonBase-root': {
          pointerEvents: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          justifyContent: 'center',
          '& .MuiButtonBase-root': {
            width: 'calc(100% - 32px)',
            marginRight: 0,
            marginLeft: 0,
          }
        }
      },
      '& .MuiFab-root': {
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      '& .MuiSvgIcon-root': {
        paddingRight: theme.spacing(1),
      },
    }),
  }),
);
interface Props {
  children: React.ReactElement | Array<React.ReactElement>,
  alignment?: Alignment,
}

interface FabProps extends Props {
  fadeTop?: boolean,
  fabStyle?: React.CSSProperties,
  className?: string,
}

interface MultiFabProps extends FabProps {
  buttonCount?: number,
}

/**
 * CTA FAB custom styling, mainly for top fading.
 *
 * @param {boolean} fadeTop is top section need to be faded.
 * @returns {React.CSSProperties}
 */
const ctaFadeTopStyling = (fadeTop = false): React.CSSProperties => {
  return fadeTop
      ? {
          background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
          right: 0,
          bottom: 0,
      } : {}
}

export const MultiFabContainer = ({
  children,
  fabStyle = {},
  fadeTop = false,
  alignment = 'default',
  buttonCount = 2,
}: MultiFabProps) => {
  const classes = useStyles({ alignment, buttonCount })
  return (
    <ColoredPaper elevation={0} className={classes.root}>
      <Fab className="backdrop" />
      <div className="multifab" style={{...ctaFadeTopStyling(fadeTop), ...fabStyle}}>
        {children}
      </div>
    </ColoredPaper>
  )
}

export const FabContainer = ({
  children,
  fabStyle = {},
  fadeTop = false,
  alignment = 'default',
  className=""
}: FabProps) => {
  const buttonCount = 1;
  const classes = useStyles({ alignment, buttonCount });

  const colorPaperClasses = classNames(classes.root, { [className]: Boolean(className)})

  return (
    <ColoredPaper elevation={0} className={colorPaperClasses}>
      <Fab className="backdrop" />
      <div className={"fab"} style={{...ctaFadeTopStyling(fadeTop), ...fabStyle}}>
        {children}
      </div>
    </ColoredPaper>
  )
}