import React from 'react'
import { SvgIcon } from "@material-ui/core";

function CustomerInsightsIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 20, height: 15 }} viewBox="0 0 20 15" {...props} >
      <path d="M8.83332 14.5C8.83332 14.5 7.66666 14.5 7.66666 13.3333C7.66666 12.1667 8.83332 8.66667 13.5 8.66667C18.1667 8.66667 19.3333 12.1667 19.3333 13.3333C19.3333 14.5 18.1667 14.5 18.1667 14.5H8.83332ZM13.5 7.5C14.4282 7.5 15.3185 7.13125 15.9749 6.47487C16.6312 5.8185 17 4.92826 17 4C17 3.07174 16.6312 2.1815 15.9749 1.52513C15.3185 0.868749 14.4282 0.5 13.5 0.5C12.5717 0.5 11.6815 0.868749 11.0251 1.52513C10.3687 2.1815 9.99999 3.07174 9.99999 4C9.99999 4.92826 10.3687 5.8185 11.0251 6.47487C11.6815 7.13125 12.5717 7.5 13.5 7.5Z" fill="#1AAA5D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.75199 14.4998C6.57904 14.1356 6.49278 13.7363 6.49999 13.3332C6.49999 11.7523 7.29332 10.1248 8.75866 8.99318C8.02726 8.76782 7.26526 8.65762 6.49999 8.66651C1.83332 8.66651 0.666656 12.1665 0.666656 13.3332C0.666656 14.4998 1.83332 14.4998 1.83332 14.4998H6.75199Z" fill="#1AAA5D" />
      <path d="M5.91667 7.49984C6.69022 7.49984 7.43208 7.19255 7.97906 6.64557C8.52604 6.09858 8.83333 5.35672 8.83333 4.58317C8.83333 3.80962 8.52604 3.06776 7.97906 2.52078C7.43208 1.97379 6.69022 1.6665 5.91667 1.6665C5.14312 1.6665 4.40125 1.97379 3.85427 2.52078C3.30729 3.06776 3 3.80962 3 4.58317C3 5.35672 3.30729 6.09858 3.85427 6.64557C4.40125 7.19255 5.14312 7.49984 5.91667 7.49984Z" fill="#1AAA5D" />
    </SvgIcon>
  );
}

export default CustomerInsightsIcon