import { MediaInfo } from "bos_common/src/types/MerchantStoryType"

export const diffMediaInfo = (description: string, media: MediaInfo[]): MediaInfo[] => {
  let newMedia: MediaInfo[] = [];
  media.forEach((item: MediaInfo) => {
    if (description.indexOf(item.publicUrl) > -1) {
      newMedia.push(item);
    }
  })
  return newMedia;
}