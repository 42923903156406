import React, { useContext, useState, useEffect } from "react";
import { Alert, CardContent, LinearProgress } from "@material-ui/core";
import { auth0, AuthResultData, loginWithAuth0Token, storeAuthMeta, storeAuthState } from "../services/auth0";
import { useHistory } from "react-router-dom";
import { FullscreenPaper } from "../components/Papers";
import { UserContext } from "../context/UserContext";
import { LOCALSTORAGE_APP_KEYS } from "../constants";

const CallbackPage = (): React.ReactElement => {
  const history = useHistory()
  const [errorMsg, setErrorMsg] = useState<string>()
  const { user, setLoginState, authenticating, setAuthenticating } = useContext(UserContext)

  useEffect(() => {
    if (window.location.hash) {
      setAuthenticating(true)
      auth0.parseHash({ hash: window.location.hash }, function (err, authResult) {
        if (authResult) {
          storeAuthState({ nonce: authResult.idTokenPayload?.nonce, state: authResult.state })
          if (authResult.idTokenPayload && authResult.idToken) {
            const cachedDisplayName = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME);
            const cachedPhoneNumber = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER);
            const cachedEmail = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL);
            const cachedAddress = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_ADDRESS);
            const userPayload = {
              ...authResult.idTokenPayload,
              ...(cachedDisplayName && { 'name': cachedDisplayName }),
              ...(cachedPhoneNumber && { 'phone_number': cachedPhoneNumber }),
              ...(cachedEmail && { 'email': cachedEmail }),
              ...(cachedAddress && { 'address': cachedAddress }),
            }

            loginWithAuth0Token(authResult.idToken, userPayload)
              .then((data: AuthResultData | undefined) => {
                sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_DISPLAY_NAME);
                sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER);
                sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_EMAIL);
                sessionStorage.removeItem(LOCALSTORAGE_APP_KEYS.USER_ADDRESS);
                setLoginState(data?.user, data?.token)
                storeAuthMeta(undefined, data?.user?.phoneNumber, data?.user?.email)
                setAuthenticating(false)
                if (authResult.state) {
                  const [authState, hash] = authResult.state.split('#')
                  const [baseUrl, search] = authState.split('?')
                  history.replace({ pathname: baseUrl, hash, search })
                }
              }).catch((err) => {
                setErrorMsg(err.message)
                setAuthenticating(false)
              })
          } else {
            setAuthenticating(false)
          }
          // if (authResult.accessToken) {
          //   auth0.client.userInfo(authResult.accessToken, function(err, user) {
          //   });
          // }
        } else {
          setAuthenticating(false)
          if (user) {
            history.replace({ pathname: '/', hash: 'openDrawer' })
          } else {
            history.replace({ pathname: '/login' })
          }
        }
      });
    }
  }, [])

  return (
    <FullscreenPaper>
      {authenticating && <LinearProgress />}
      <CardContent>
        {authenticating &&
          <Alert severity='info'>Logging in...</Alert>
        }
        {!authenticating && (
          <>
            {user && <Alert severity='success'>You have been logged in and will be redirected back to the store momentarily...</Alert>}
            {/* {!user &&
            <Redirect to={{
              pathname: '/login',
              state: { errorMessage: errorMsg },
            }} />
          } */}
          </>
        )}
      </CardContent>
    </FullscreenPaper>
  )
}

export default CallbackPage