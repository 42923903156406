export enum ClientType {
  iOS = "iOS",
  iPadOS = "iPadOS",
  Android = "Android",
  Windows = "Windows",
  MacOS = "MacOS",
  Linux = "Linux",
  Web = "Web",
  Unknown = "Unknown",
  All = "All",
}

export const getClientType = (): ClientType => {
  return ClientType.Web
}