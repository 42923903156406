import React, { SetStateAction } from "react";
import { User } from "../types/UserTypes";

export interface UserContextState {
  user?: User
  token: string
  // store login state in memory only
  setLoginState: (u: User | undefined, t: string | undefined) => void
  authenticating: boolean
  setAuthenticating: (l: boolean) => void
  loading: boolean
  setLoading: (l: boolean) => void
  refreshUser: () => void,
  updateUserPicture: (picture: string)=>void
}

export const UserContext = React.createContext<UserContextState>({
  token: '',
  setLoginState: (u: User | undefined, t: string | undefined) => ({}),
  authenticating: false,
  setAuthenticating: (value: SetStateAction<boolean>) => ({}),
  loading: false,
  setLoading: (value: SetStateAction<boolean>) => ({}),
  refreshUser: () => ({}),
  updateUserPicture: (picture: string) => ({})
})