import { If } from 'react-if';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { isEmptyOrNil } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      background: theme.palette.background.paper,
      borderRadius: '8px',
      boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.07)',

      '& .heading': {
        fontWeight: 700,
        fontSize: '14px',
        margin: 0,
        color: theme.palette.secondary.main,
      },
      
      '& .cardHeader': {
        marginBottom: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      },
    },
  })
);

type CardWrapperProps = {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  heading: string;
  headerItems?: React.ReactNode;
};

const CardWrapper = (props: CardWrapperProps) => {
  const { heading, children, headerItems } = props;

  const classes = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <div className="cardHeader">
        <h3 className="heading">{heading}</h3>
        <If condition={!isEmptyOrNil(headerItems)}>
          {headerItems}
        </If>
      </div>

      <div className="cardContentWrapper">{children}</div>
    </div>
  );
};

export default CardWrapper;
