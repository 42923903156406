import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  createStyles,
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  menuItemClasses,
  withStyles,
  ListItemIcon,
  Box,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),

      '& .MuiCardContent-root': {
        padding: '0 !important',
      },
    },
  })
);

const ListItemLink = withStyles((theme: Theme) => ({
  root: {
    color: '#364245',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,

    '&.Mui-selected': {
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    },
  },
}))((props: any) => {
  const { classes, label, to, icon, secondaryAction } = props;
  const location = useLocation();

  return (
    <ListItem className={classes.root} selected={to === location.pathname} component={Link} to={to}>
      {icon !== false && (
        <ListItemIcon sx={{ minWidth: 40 }}>{icon ?? <ChevronRight />}</ListItemIcon>
      )}
      <ListItemText primary={label} />
      <Box>{secondaryAction}</Box>
    </ListItem>
  );
});

export type CardMenuItem = {
  path: string;
  label: string;
  icon?: React.ReactNode | boolean;
  action?: React.ReactNode;
};

type MenuListCardProps = {
  menuItems: CardMenuItem[];
  title: string;
  dropdown?: boolean;
  beginOpen?: boolean;
};

const MenuListCard = (props: MenuListCardProps) => {

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {noSsr: true});
  const { menuItems, title, dropdown=false, beginOpen=false} = props;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(dropdown ? (beginOpen || (isMobile ? false : true)): true);

  if (!menuItemClasses || menuItems.length === 0) return null;

  const renderMenuItems = () => {
    return menuItems.map((item: CardMenuItem, idx: number) => (
      <div key={`item-${idx}`}>
        {idx !== 0 && <Divider />}
        <ListItem
          button
          component={ListItemLink}
          to={item.path}
          label={item.label}
          icon={item.icon}
          secondaryAction={item.action}
        />
      </div>
    ));
  };
  const handleClick = () => {
    setOpen(!open);
  }
  return (
    <Card elevation={3} className={classes.root}>
      <CardHeader
        onClick={handleClick}
        title={title}
        titleTypographyProps={{
          variant: 'h6',
        }}
        sx={{ py: 1 }}
        action={
          dropdown ?
          <IconButton onClick={handleClick} style={{transform: (open ? 'rotate(90deg)': 'rotate(0)')}}>
            <ChevronRight />
          </IconButton> : null
        }
      />
      <Divider />
      <CardContent style={{display: (!dropdown ? 'block' : open ? 'block': 'none')}}>
        <List component="nav" sx={{ p: 0 }}>
          {renderMenuItems()}
        </List>
      </CardContent>
    </Card>
  );
};

export default MenuListCard;
