import React from 'react';
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core';

import { MerchandiseCategory } from '../../services/models';
import { useAppSelector } from '../../redux/hooks';
import { getMerchandiseCategories } from '../../redux/slice/merchandise/merchandiseSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      gridGap: '10px',
      padding: '5px 20px',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    categoryChip: {
      background: '#eaebec',
      color: '#8f9598',
      border: '1px solid #91999b',
      borderRadius: '4px',
      fontSize: '16px',

      '&.selected': {
        background: '#e8f5f6',
        color: '#8ebfc7',
        borderColor: '#abd0d7',
      },
    },
  })
);

type CategoriesListProps = {
  selectedCategoryId?: number;
  merchandiseCategoryId?: number;
  setSelectedCategoryId: (id: number) => void;
};

const CategoriesList = ({
  selectedCategoryId,
  merchandiseCategoryId,
  setSelectedCategoryId,
}: CategoriesListProps) => {

  const classes = useStyles();

  const categoriesList = useAppSelector(getMerchandiseCategories);

  return (
    <div className={classes.root}>
      {categoriesList.map((data: MerchandiseCategory) => {
        const { name, id } = data;

        const isSelected = selectedCategoryId === id;
        const disabled = merchandiseCategoryId === id;

        return (
          <Chip
            key={`${name}_${id}`}
            className={`${classes.categoryChip} ${isSelected && 'selected'}`}
            label={name}
            disabled={disabled}
            onClick={() => setSelectedCategoryId(id)}
          />
        );
      })}
    </div>
  );
};

export default CategoriesList;