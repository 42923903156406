import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CardContent, createStyles, Fab, Theme, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { filter, pipe, propOr, propEq, map } from 'ramda';
import { useTranslation } from 'react-i18next';

import useFetchMerchant from '../services/useFetchMerchant';
import { Merchant, MerchantQRCode, QRCodeType } from '../services/models';
import { AppContext } from '../context/AppContext';

import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { FabContainer } from '../bos_common/src/components/FabContainers';
import SectionList from '../components/common/SectionList';
import { ColoredPaper } from '../bos_common/src';
import AppDrawer from '../bos_common/src/components/AppDrawer';
import { getMarketPlaceMerchantQRCodeURL, getMarketPlaceMerchantURL } from '../bos_common/src/services/urls';

import RenderMerchantQRCode, { RenderMerchantQRCodeData } from '../components/QRCode/MerchantQRCode';
import NewQRCodeDialog from '../components/QRCode/NewQRCodeDialog';
import { isEmptyOrNil } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionListContainer: {
      overflowY: 'scroll',
      '& .MuiCardActionArea-root': {
        width: '100%',
      },
      '& .MuiCardHeader-action': {
        alignSelf: 'center',
      }
    },
    drawerContainer: {
      width: '400px',
      maxWidth: 'unset'
    }
  })
)

interface IMerchantOfflinePresenceParams {
  merchantUsername: string;
}

type QRSectionDataList = {
  label: string,
  action: (item?: RenderMerchantQRCodeData) => void,
  item?: RenderMerchantQRCodeData
}

type QRSectionList = Array<{
  title: string,
  dataList: Array<QRSectionDataList>
}>

const MerchantInstorePresencePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { merchant } = useContext(AppContext)
  const { merchantUsername } = useParams<IMerchantOfflinePresenceParams>();
  const { t } = useTranslation();

  const [qrCodeData, setQrCodeData] = useState<RenderMerchantQRCodeData>();
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const [isNewQrDialogOpen, toggleNewQrDialog] = useState(false);

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    return <Redirect to='/biz/create' />
  }

  const loading = useFetchMerchant({ merchantUsername })

  const handleQrSelection = (item?: RenderMerchantQRCodeData) => {
    if (!item) return

    setQrCodeData(item)
    toggleDrawer(true);
  }

  // tranform QRCode data so that it can be shown in the RenderMerchantQRCode component
  const generateQRCodeList = (merchant: Merchant, qrCodesList: MerchantQRCode[], type: QRCodeType): QRSectionDataList[] => {
    const qrMatchesType = propEq('type', type)
    const mappedQrCodesList = pipe(
      filter(qrMatchesType),
      map((qrCode: MerchantQRCode) => ({
        label: qrCode.identifier,
        action: handleQrSelection,
        item: {
          url: getMarketPlaceMerchantQRCodeURL(merchant, qrCode),
          fileName: `${qrCode.identifier}-QRCode-on-OneMarket.png`,
          title: `Table ${qrCode.identifier}`,
          slogan: qrCode.slogan
        }
      }))
    )(qrCodesList)

    return mappedQrCodesList
  }

  const qrSectionsList: QRSectionList = [
    {
      title: t('QRCodes_StoreFront'),
      dataList: [
        {
          label: merchant?.officialName || 'Store',
          action: handleQrSelection,
          item: {
            url: merchant ? getMarketPlaceMerchantURL(merchant) : '',
            title: merchant?.officialName || '',
            logo: merchant?.logo,
            subtitle: merchant?.category,
            slogan: "Scan to Order and Get Rewards",
            fileName: `${merchant?.username}-QRCode-on-OneMarket.png`
          }
        }
      ]
    },
    {
      title: t("QRCodes_TableQRCodes"),
      dataList: merchant ? generateQRCodeList(merchant, propOr([], 'qrCodes', merchant), QRCodeType.Table) : []
    }
  ]

  return (
    <div className="container">
      <ColoredPaper>
        <SimpleLoader loading={loading} />
        {!loading && !merchant && <Redirect to='/biz/create' />}
        {!loading && merchant &&
          <CardContent className={classes.sectionListContainer}>
            {
              qrSectionsList.map(
                (section, idx) => (
                  !isEmptyOrNil(section.dataList) &&
                  (<SectionList
                    key={`${section.title}-${idx}`}
                    title={section.title}
                    dataList={section.dataList}
                  />)
                )
              )
            }
            <FabContainer alignment='center'>
              <Fab variant="extended" color="primary" onClick={() => toggleNewQrDialog(true)}>
                <Add />
                {t("QRCodes_Button_TableQRCode")}
              </Fab>
            </FabContainer>

            {qrCodeData && (
              <AppDrawer
                anchor='right'
                open={isDrawerOpen}
                title={t('QRCodes')}
                setOpen={toggleDrawer}
                drawerContainerClass={classes.drawerContainer}
              >
                <RenderMerchantQRCode {...qrCodeData} />
              </AppDrawer>
            )}
          </CardContent>
        }
      </ColoredPaper>
      <NewQRCodeDialog
        isOpen={isNewQrDialogOpen}
        onClose={() => toggleNewQrDialog(false)}
      />
    </div>
  );
};

export default MerchantInstorePresencePage;
