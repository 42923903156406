import React, { useContext, useState } from 'react';
import { Chip, createStyles, Fab, makeStyles, Theme } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import DialogWrapper from '../common/DialogWrapper';
import { Merchandise, MerchandiseCategory } from '../../services/models';
import axios from '../../bos_common/src/services/backendAxios';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { getAuthHeaders } from '../../utils';
import CategoriesList from './CategoriesList';
import { useAppDispatch } from '../../redux/hooks';
import { updateMerchandiseCategoriesSucceed } from '../../redux/slice/merchandise/merchandiseActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addToCategories: {
      paddingBottom: '20px',

      '& .footerButtonWrapper': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  })
);

export interface AddItemToCategoryDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  merchandise: Merchandise;
}

const AddItemToCategoryDialog = ({ open, setOpen, merchandise }: AddItemToCategoryDialogProps) => {
  const classes = useStyles();
  const { token } = useContext(UserContext);
  const { t } = useTranslation();
  const reduxDispatch = useAppDispatch();

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    if (selectedCategoryId != null) {
      axios
        .put<Merchandise>(
          `merchants/merchandises/${merchandise.id}`,
          { categoryId: selectedCategoryId },
          { headers: getAuthHeaders(token) }
        )
        .then((response) => {
          if (response.status === 200) {
            reduxDispatch(updateMerchandiseCategoriesSucceed(response.data));
          }
          setOpen(false);
        })
        .catch((err) => {
        });
    }
  };

  return (
    <DialogWrapper
      title={t("Menu_MoveCategory")}
      subTitle={t("Menu_MoveCategory_SubTitle")}
      handleClose={handleClose}
      open={open}
    >
      <div className={classes.addToCategories}>
        <CategoriesList
          selectedCategoryId={selectedCategoryId}
          merchandiseCategoryId={merchandise.categoryId}
          setSelectedCategoryId={setSelectedCategoryId}
        />

        <div className="footerButtonWrapper">
          <Fab
            className="actionButton"
            variant="extended"
            color="primary"
            component="span"
            size="medium"
            disabled={selectedCategoryId === undefined}
            onClick={handleContinue}
          >
            {t("Continue")}
          </Fab>
        </div>
      </div>
    </DialogWrapper>
  );
};

export default AddItemToCategoryDialog;
