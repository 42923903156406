import React from 'react';
import { makeStyles, createStyles, Theme, LinearProgress } from '@material-ui/core';
import { Else, If, Then } from 'react-if';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 4,
			width: '100%',
		},
	}),
)

type SimpleLoaderProps = {
	loading: boolean,
	backgroundColor?: string,
	fixed?: boolean
}

const SimpleLoader = ({ loading = false, backgroundColor = 'transparent', fixed = false }: SimpleLoaderProps) => {
	const classes = useStyles()

	return (
		<If condition={loading}>
			<Then>
				<LinearProgress style={{width: fixed ? '100%' : 'inherit', position: fixed ? 'fixed' : 'relative'}} sx={{ zIndex: 10 }} />
			</Then>
			<Else>
				<div className={classes.root} style={{ backgroundColor, position: fixed ? 'fixed' : 'relative' }}/>
			</Else>
		</If>
	)
}

export default SimpleLoader