import { BaseIncrement } from "./BaseIncrement";
import CalendarEvent from "./crm/CalendarEventType";
import { User } from "./UserTypes";

export enum StaffRole {
  SERVER = "server",
  KITCHEN = "kitchen",
  ADMIN = "admin",
  FINANCE = "finance",
}

export interface MerchantStaffWorkingHour extends BaseIncrement {
  dayOfWeek: number;
  startTimeOffset: number;
  endTimeOffset: number;
  staff: MerchantStaff;
}

export interface MerchantStaff extends BaseIncrement {
  merchantId: string;

  userId: string;
  user?: Partial<User>;

  displayName: string;
  passcode: string;
  role: StaffRole;
  deviceIds?: string[];

  workingHours?: MerchantStaffWorkingHour[];
  calendarEvents?: CalendarEvent[];
  services?: Array<any>
}
