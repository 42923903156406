import React, { useContext, useState } from "react"
import { Alert, Box, Button, Card, createStyles, IconButton, makeStyles, Stack, Theme, Typography } from "@material-ui/core";
import { Delete, Warning } from "@material-ui/icons";
import { darken } from "polished";
import { If } from "react-if";
import useAxios, { RefetchOptions } from "axios-hooks";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { format } from "date-fns";

import { MerchantCustomer } from "bos_common/src/types/crm/MerchantCustomerType";
import { getAuthHeaders, isEmptyOrNil, pluralString } from "bos_common/src";
import { UserContext } from "bos_common/src/context/UserContext";
import CustomerSubscriptionBalance from "bos_common/src/types/crm/CustomerSubscriptionBalanceType";

import DeleteMembershipDialog from "./DeleteMembershipDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .paymentError": {
        color: theme.palette.error.main,

        "& .MuiAlert-message": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }
      },

      "& .deleteButton": {
        color: theme.palette.error.main,
        fontSize: theme.spacing(2),
        cursor: "pointer",
        transition: "all 200ms linear",

        "&:hover": {
          color: darken(0.1, theme.palette.error.main)
        }
      },
    },
  })
);

type MembershipDetailsCardProps = {
  customer: MerchantCustomer;
  subscription: CustomerSubscriptionBalance;
  refetchSubscriptions: (config?: AxiosRequestConfig<any> | undefined, options?: RefetchOptions | undefined) => AxiosPromise<any>;
  setLoading: (_: boolean) => void;
}

const getRemainingCreditsForPackageSubscription = (subscription: CustomerSubscriptionBalance): number => {
  const { redeemedCredits, merchantPackage } = subscription;
  if (isEmptyOrNil(merchantPackage)) return 0;

  return subscription.totalCredits! - redeemedCredits;
}

const MembershipDetailsCard = (props: MembershipDetailsCardProps) => {
  const { customer, refetchSubscriptions, setLoading, subscription } = props;

  const [showDeleteMembershipDialog, setShowDeleteMembershipDialog] = useState<boolean>(false);
  const { token } = useContext(UserContext);
  const classes = useStyles();

  const {
    merchantPackage,
    merchantPackageId,
    merchantMembership,
    merchantMembershipId,
    merchant,
    isExpired,
    startDate,
    updatedAt,
    endDate,
    id: subscriptionId
  } = subscription

  const remainingCredits = getRemainingCreditsForPackageSubscription(subscription)

  const [{ loading }, executeMarkAsPaid] = useAxios(
    {
      url: `/customers/${customer?.id}/subscriptions?resubscribe=true`,
      method: 'post',
    },
    { manual: true }
  )

  const isPackageSubscription = !isEmptyOrNil(merchantPackage)
  const subscriptionName = isPackageSubscription ? merchantPackage?.name : merchantMembership?.name

  const RenderMarkAsPaid = () => {
    const onMarkAsPaidClick = async () => {
      try {
        setLoading(true);

        const data = {
          id: subscription.id,
          merchantId: merchant?.id,
          ...(isPackageSubscription ? { merchantPackageId } : { merchantMembershipId }),
        }

        await executeMarkAsPaid({
          data,
          headers: getAuthHeaders(token)
        })
        setLoading(false)
        refetchSubscriptions()
      } catch (error) {
        setLoading(false);
      }
    }

    if (!isExpired) return null
    return (
      <Alert className="paymentError" icon={<Warning />} severity="error" >
        Payment Due
        <Button
          //@ts-ignore
          variant="error"
          sx={{ padding: "0 8px", m: 0 }}
          onClick={onMarkAsPaidClick}
          disabled={loading}
        >
          Mark as Paid
        </Button>
      </Alert>
    )
  }

  return (
    <Box className={classes.root}>
      <Card sx={{ mt: 1, p: 2 }}>
        <RenderMarkAsPaid />
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
          <Box>
            <Typography variant="subtitle1" component="div" className='cardHeading'>
              {subscriptionName}
            </Typography>
            <div>added on {format(new Date(startDate || updatedAt), "MM/dd/yyyy")}</div>
            <If condition={Boolean(merchantMembership && endDate)}>
              <div>{isExpired ? 'expired' : 'expire'} on {format(new Date(endDate), "MM/dd/yyyy")}</div>
            </If>
            <If condition={!isEmptyOrNil(remainingCredits) && !merchantMembershipId}>
              <Typography variant="h6" fontWeight={700}>{pluralString(remainingCredits, 'class', 'classes')} remaining</Typography>
            </If>
          </Box>

          <Box>
            <IconButton
              className="deleteButton"
              onClick={() => setShowDeleteMembershipDialog(true)}
              disabled={loading}
            >
              <Delete />
            </IconButton>
          </Box>
        </Stack>
      </Card>

      <If condition={showDeleteMembershipDialog}>
        <DeleteMembershipDialog
          open={showDeleteMembershipDialog}
          setOpen={setShowDeleteMembershipDialog}
          subscriptionId={subscriptionId}
          customer={customer}
          refetchSubscriptions={refetchSubscriptions}
          setLoading={setLoading}
        />
      </If>
    </Box>
  )
}

export default MembershipDetailsCard;