import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { format } from "date-fns";
import { map, sortBy, pipe, prop, defaultTo } from 'ramda';
import { MerchantStatsApiResponse } from "../../services/models";
import { isEmptyOrNil } from "../../utils";
import { DateOptions, MerchantStatsPeriod } from "./types";

export const DAYS_OF_WEEK_FULL_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
export const HOURS_OF_DAY = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm', '12am'];

export type StatsChartProps = {
  lastWeekSalesData: MerchantStatsApiResponse | undefined,
  currentWeekSalesData: MerchantStatsApiResponse | undefined,
  timeZone: string | undefined,
  dataKey?: string,
  chartLabel?: string,
  chartTitle?: string,
  chartDescription?: string,
}

export interface Serial {
  name: number;
  x: number;
  y: number;
}

export interface AverageDaily {
  hrsDuration: number;
  revenueTotal: number;
  revenueAvg: number;
}

export interface AverageDailyDictionary {
  [key: string]: AverageDaily
}

export interface AxesValues<T> {
  x: T[];
  y: number[]
}

export interface SeriesDataComponents {
  series: Serial[];
  lineChart?: AxesValues<string>;
  barChart?: AxesValues<string>;
  avgTable?: AverageDailyDictionary;
}

export const useChartStyles = makeStyles((theme: Theme) =>
  createStyles({
    statsChartBlankslate: {
      paddingLeft: theme.spacing(2),
      paddingRight: 0
    }
  }),
);

export const getStatsDateRangeLabel = (
  statsTimePeriod: MerchantStatsPeriod = MerchantStatsPeriod.Day,
  dateRange: DateOptions = { fromDate: new Date(), toDate: new Date() },
  dateFormat: string = "MM/dd"
): string => {
  try {
    const { fromDate, toDate } = dateRange

    return statsTimePeriod === MerchantStatsPeriod.Week || statsTimePeriod === MerchantStatsPeriod.Custom
      ? `${format(new Date(fromDate), dateFormat)} - ${format(new Date(toDate), dateFormat)}`
      : `${format(new Date(fromDate), dateFormat)}`
  }
  catch (err) {
    return ''
  }
}

export const getBarChartLayoutConfig = (config: Partial<Plotly.Layout>, isWeeklyStats = false): Plotly.Layout => {
  return {
    type: 'bar',
    autosize: true,
    xaxis: {
      anchor: 'free',
      categoryorder: 'array',
      fixedrange: true,
      categoryarray: isWeeklyStats ? DAYS_OF_WEEK : HOURS_OF_DAY,
    },
    yaxis: {
      title: '',
      tickformat: `$`,
      automargin: true,
      fixedrange: true,
    },
    legend: {
      orientation: 'h',
      xanchor: 'center',
      x: 0.5,
      y: -0.15,
    },
    margin: {
      r: 1,
      t: 20,
      l: 1,
      b: 20,
    },
    ...config
  } as Plotly.Layout
};

export type FormattedChartData = {
  series: Array<any>,
  lineChart: {
    x: Array<number>,
    y: Array<number>
  },
  barChart: {
    x: Array<number>,
    y: Array<number>
  }
}
function formatChartDataFromStats<T>(stats: Array<T>, statsPeriod: MerchantStatsPeriod, calculateDataPoint: (group: T) => any): FormattedChartData {
  const isCustomStats = statsPeriod === MerchantStatsPeriod.Custom;
  const isWeeklyStats = statsPeriod === MerchantStatsPeriod.Week;


  // generate chart series
  const series = pipe(
    map((group: T) => calculateDataPoint(group)),
    sortBy(prop(isCustomStats ? 'name' : 'x'))
  )(defaultTo([], stats))

  // generate line chart points
  const lineChartXPoints = series.map((item: any) => isCustomStats ? item.x : isWeeklyStats ? DAYS_OF_WEEK[item.x] : HOURS_OF_DAY[item.x]);
  const lineChartYPoints = series.map((item: any) => item.y);

  // generate area chart points
  const barChartXPoints = [...lineChartXPoints];
  const barChartYPoints = [...lineChartYPoints];

  if (isWeeklyStats) {
    for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
      if (lineChartXPoints.indexOf(DAYS_OF_WEEK[i]) === -1) {
        barChartXPoints.splice(i, 0, DAYS_OF_WEEK[i]);
        barChartYPoints.splice(i, 0, 0);
      }
    }
  } else {
    let xPointsIndex: number = -1;
    const startIndex = !isEmptyOrNil(lineChartXPoints) ? HOURS_OF_DAY.findIndex((str) => str === lineChartXPoints[0].x) : 0;
    const endIndex = !isEmptyOrNil(lineChartXPoints) ? HOURS_OF_DAY.findIndex((str) => str === lineChartXPoints[lineChartXPoints.length - 1].x) : 0;

    for (let i = startIndex; i < endIndex; i++) {
      xPointsIndex = lineChartXPoints.indexOf(HOURS_OF_DAY[i]);

      if (xPointsIndex === -1) {
        barChartXPoints.splice(i, 0, HOURS_OF_DAY[i]);
        barChartYPoints.splice(i, 0, 0);
      }
    }
  }

  return {
    series,
    lineChart: {
      x: lineChartXPoints,
      y: lineChartYPoints,
    },
    barChart: {
      x: barChartXPoints,
      y: barChartYPoints,
    }
  }
}

export function generateBarChartData<T>(stats: Array<T>, statsPeriod: MerchantStatsPeriod, calculateDataPoint: (group: T) => any,) {
  const formattedData = formatChartDataFromStats(stats, statsPeriod, calculateDataPoint)
  return formattedData
}

export function generatePieChartData<T>(stats: Array<T>, statsPeriod: MerchantStatsPeriod, calculateDataPoint: (group: T) => any,) {
  const { lineChart } = formatChartDataFromStats(stats, statsPeriod, calculateDataPoint)

  const pieChart = lineChart.y.reduce((acc: number, current: number) => acc + current, 0)

  return pieChart
}

export function generateSalesChartSeries<T>(stats: Array<T>, statsPeriod: MerchantStatsPeriod | undefined, calculateDataPoint: (group: T) => any): SeriesDataComponents {
  const isCustomStats = statsPeriod === MerchantStatsPeriod.Custom;
  const isWeeklyStats = statsPeriod === MerchantStatsPeriod.Week;
  const generateSeriesData = (): Serial[] => {
    const series = pipe(
      map((group: T) => calculateDataPoint(group)),
      sortBy(prop(isCustomStats ? 'name' : 'x'))
    )(defaultTo([], stats))

    return series
  }

  // generate series data
  const series = generateSeriesData();

  // generate line chart points
  const lineChartXPoints = series.map((item: any) => isCustomStats ? item.x : isWeeklyStats ? DAYS_OF_WEEK[item.x] : HOURS_OF_DAY[item.x]);
  const lineChartYPoints = series.map((item: any) => item.y);

  // generate area chart points
  const barChartXPoints = [...lineChartXPoints];
  const barChartYPoints = [...lineChartYPoints];

  // summary of y axis, in sales case, the key is the x axis,
  // and the value is the sum of revenue,
  const table: { [key: string]: number } = {}

  // Average daily revenye look up table, the key is the x axis
  const avgTable: { [key: string]: AverageDaily } = {}

  if (isWeeklyStats) {
    // loop through days of a week
    for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
      if (lineChartXPoints.indexOf(DAYS_OF_WEEK[i]) === -1) {
        // Remove points without any value from axes
        barChartXPoints.splice(i, 0, DAYS_OF_WEEK[i]);
        barChartYPoints.splice(i, 0, 0);
      }
      else {
        // Sum revenue of the day, each item represent hour time point
        table[DAYS_OF_WEEK[i]] = series
          .filter((item: Serial) => item.x === i)
          .map((item: Serial) => item.y)
          // eslint-disable-next-line no-return-assign
          .reduce((sum: number, n: number) => (sum += n), 0);

        // get today's series only
        const tempSeries = series.filter((item: Serial) => item.x === i);
        // sum revenue of the day
        const tempSeriesTotalRevenue = tempSeries
          .map((item: Serial) => item.y)
          // eslint-disable-next-line no-return-assign
          .reduce((sum: number, n: number) => (sum += n), 0);

        avgTable[DAYS_OF_WEEK[i]] = {
          hrsDuration: tempSeries.length,
          revenueTotal: tempSeriesTotalRevenue,
          revenueAvg: tempSeriesTotalRevenue / tempSeries.length, // recalculate daily average revenue
        }
      }
    }


  } else {
    let xPointsIndex: number = -1;
    const startIndex = !isEmptyOrNil(lineChartXPoints) ? HOURS_OF_DAY.findIndex((str) => str === lineChartXPoints[0]) : 0;
    const endIndex = !isEmptyOrNil(lineChartXPoints) ? HOURS_OF_DAY.findIndex((str) => str === lineChartXPoints[lineChartXPoints.length - 1]) : 0;

    for (let i = startIndex; i < endIndex; i++) {
      xPointsIndex = lineChartXPoints.indexOf(HOURS_OF_DAY[i]);

      // remove axes that doesn't contain any value
      if (xPointsIndex === -1) {
        barChartXPoints.splice(i, 0, HOURS_OF_DAY[i]);
        barChartYPoints.splice(i, 0, 0);
      }
    }
  }

  return {
    series,
    lineChart: {
      x: isWeeklyStats ? Object.keys(table) : lineChartXPoints,
      y: isWeeklyStats ? Object.values(table) : lineChartYPoints,
    },
    barChart: {
      x: isWeeklyStats ? Object.keys(table) : barChartXPoints,
      y: isWeeklyStats ? Object.values(table) : barChartYPoints,
    },
    avgTable
  }
}
