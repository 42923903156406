import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  IconButton,
  Drawer,
  useMediaQuery
} from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import { If, Then, Else } from 'react-if';

// src
import { Order, OrderStatus, } from '../../../services/models';
import { OrderActionsContextProvider } from './context/OrderActionsContext';
import OrderActionsScreen from './OrderActionsScreen';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
      width: '100%',
      maxWidth: 350,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset'
      },
      '& .drawer-body': {
        flex: '1 1 auto',
      }
    },
  }),
);

type OrderActionsDrawerProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  order: Order | undefined,
  handleSetOrderStatus: (id: string, nextOrderStatus: OrderStatus, onSuccessCallback?: () => void) => void;
}

const OrderActionsDrawer = (props: OrderActionsDrawerProps): React.ReactElement | null => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { open, order, setOpen, handleSetOrderStatus } = props
  if (!order) return null

  const closeDrawer = () => { setOpen(false) }

  const RenderDrawerCloseButton = () => {
    return (
      <IconButton onClick={closeDrawer}>
        <If condition={isMobile}>
          <Then><ExpandMore color="primary" fontSize="large" /></Then>
          <Else>
            <Close color="primary" fontSize="large" />
          </Else>
        </If>
      </IconButton>
    )
  }

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      classes={{ paper: classes.paper }}
      onClose={closeDrawer}
    >
      <OrderActionsContextProvider handleSetOrderStatus={handleSetOrderStatus}>
        <div className="drawer-body" role="presentation">
          <RenderDrawerCloseButton />
          <OrderActionsScreen order={order} closeDrawer={closeDrawer} />
        </div>
      </OrderActionsContextProvider>
    </Drawer>
  )
}

export default OrderActionsDrawer