import React from 'react';
import { useTheme } from '@material-ui/core';

import SimpleLoader from '../../../bos_common/src/components/SimpleLoader';

import { Merchant } from '../../../services/models';
import OrdersHeader from '../../../components/Order/OrdersHeader';
import MerchantOrderTabs from './MerchantOrderTabs';

type MerchantOrdersPageProps = {
  merchant?: Merchant;
  loading?: boolean;
};

const MerchantOrdersPage = ({ merchant, loading }: MerchantOrdersPageProps) => {
  const theme = useTheme();
  return (
    <div className="container">
      <OrdersHeader merchant={merchant} />
      <SimpleLoader loading={!!loading} backgroundColor={theme.palette.background.default} />
      <MerchantOrderTabs />
    </div>
  );
};

function areEqual(prevProps: MerchantOrdersPageProps, nextProps: MerchantOrdersPageProps) {
  return prevProps.merchant === nextProps.merchant && nextProps.loading === prevProps.loading
}

export default React.memo(MerchantOrdersPage, areEqual)
