import { Alert, IconButton, Button, Card, CircularProgress, Divider, Grid, Stack, Theme, Typography, useMediaQuery } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router";
import useAxios from 'axios-hooks';
import { If, Then, Else } from 'react-if';
import { ChevronLeft } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { getAuthState, getJWTTokenFromHeader, storeAuthState } from '../bos_common/src/services/auth0';
import { UserContext } from '../bos_common/src/context/UserContext';
import { ColoredPaper } from '../bos_common/src/components/Papers';

import { AppContext } from '../context/AppContext';
import { User } from '../services/models';

import useFetchMerchant from '../services/useFetchMerchant';
import StaffPasscodeInput from '../components/common/StaffPasscodeInput';
import MerchantAppLogo from '../components/common/MerchantAppLogo';

import LoginPeopleIllustration from '../assets/images/login-people-illustration.svg';
import useLoginPageStyles from '../components/Login/pageStyles';
import LanguageSelector from '../components/Login/LanguageSelector';

interface IMerchantStaffLoginParams {
  merchantUsername: string;
}

enum LoginStrategy {
  WITH_TOKEN = 'with_token',
  WITH_CODE = 'with_code',
  UNKNOWN = 'unknown',
}

const MerchantStaffLoginPage = (): React.ReactElement => {
  const classes = useLoginPageStyles();

  const { merchantUsername } = useParams<IMerchantStaffLoginParams>();
  const { merchant } = useContext(AppContext)
  const { setLoginState } = useContext(UserContext)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [loginStrategy, setLoginStrategy] = useState<LoginStrategy>(LoginStrategy.UNKNOWN)

  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<{ from: { pathname: string } }>();
  const from = location.state?.from || { pathname: `/${merchantUsername}` };

  const merchantLoading = useFetchMerchant({ merchantUsername })

  const [{ data, loading: loginLoading }, executeStaffLogin] = useAxios<User>(
    { url: '/login/merchant/staff', method: 'POST' },
    { manual: true }
  )

  const successLogin = (user: User, token: string) => {
    setLoginState(user, token)
    storeAuthState({ token })
    history.replace(from);
  }

  useEffect(() => {
    if (merchant) {
      const { token } = getAuthState()
      if (token) {
        setLoginStrategy(LoginStrategy.WITH_TOKEN)
        executeStaffLogin({
          data: {
            token,
            merchantUsername,
          }
        }).then((response) => {
          const { token } = getJWTTokenFromHeader(response)
          if (response.status === 200 && token) {
            const user: User = response.data
            successLogin(user, token)
          } else {
            setLoginStrategy(LoginStrategy.WITH_CODE)
          }
        }).catch(() => {
          setLoginStrategy(LoginStrategy.WITH_CODE)
        })
      } else {
        setLoginStrategy(LoginStrategy.WITH_CODE)
      }
    }
  }, [merchant])

  const loading = merchantLoading || loginLoading

  const onBack = () => {
    history.replace('/');
  };

  const RenderLoginWithCode = () => {
    if (!merchant) return null

    return (
      <>
        <div className="content-container" style={{ paddingBottom: '16px' }}>
          <Typography gutterBottom variant="subtitle1" component="div" textAlign={'center'} color={'secondary'}>
            {t('Signup_EnterYourPin')}
          </Typography>
        </div>

        <div className='content-container' style={{paddingTop: 0}}>
          <StaffPasscodeInput
            onSuccessAuth={successLogin}
            merchant={merchant}
            disabled={loading}
          />
        </div>
      </>
    )
  }

  const RenderLoginWithToken = () => (
    <div className="spinner content-container">
      <h1>Logging in</h1>
    </div>
  )

  const RenderMerchantNotFound = () => (
    <div className={'content-container'}>
      <Alert severity="warning">
        {`Unable to find "${merchantUsername}", please confirm the online name of the store and try again.`}
      </Alert>
    </div>
  )

  const RenderLoginForm = () => {
    if (!merchantLoading) {
      if (!merchant) {
        return <RenderMerchantNotFound />
      }

      switch (loginStrategy) {
        case LoginStrategy.WITH_CODE:
          return <RenderLoginWithCode />
        case LoginStrategy.WITH_TOKEN:
          return <RenderLoginWithToken />
        default:
      }
    }

    return (<div />)
  }

  return (
    <ColoredPaper className={classes.root}>
      <div className='layout-container merchant-login'>
        <If condition={isMobile}>
          <div className='header-container staff-login-header'>
            <IconButton onClick={onBack}>
              <ChevronLeft color='primary' fontSize="large" />
            </IconButton>
            <Typography gutterBottom variant="h6" component="div" textAlign={'center'} sx={{ margin: 0 }}>
              {merchant?.officialName || t("Signup_MerchantStaffLogin")}
            </Typography>
          </div>
        </If>
        <div className='login-illustration'>
          <Stack direction={ isMobile ? 'column' : 'row'} gap="24px" alignItems={ isMobile ? "center" : "flex-end"} justifyContent={'space-between'} sx={{ maxWidth: '1280px', padding: !isMobile ? "0 40px" : "0", width: '100%', margin: '0 auto' }}>
            <If condition={!isMobile}>
              <Then>
                <img src={LoginPeopleIllustration} className={'login-people'} />
              </Then>
            </If>
            <div className='login-form-container'>
              <Card variant='outlined' sx={{border: 0}}>
                <If condition={!isMobile}>
                  <Then>
                    <div className='header-container staff-login-header'>
                      <IconButton onClick={onBack}>
                        <ChevronLeft color='primary' fontSize="large" />
                      </IconButton>
                      <Typography gutterBottom variant="h6" component="div" textAlign={'center'} sx={{ margin: 0 }}>
                        {merchant?.officialName || t("Signup_MerchantStaffLogin")}
                      </Typography>
                    </div>
                    <div className='divider-container'>
                      <Divider orientation="horizontal" className="login-form-divider" />
                    </div>
                  </Then>
                </If>

                <If condition={loading}>
                  <Then>
                    <div className='content-container' style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                      <CircularProgress size={'4rem'} />
                    </div>
                  </Then>
                  <Else>
                    <RenderLoginForm />

                    <div className='divider-container'>
                      <Divider orientation="horizontal" className="login-form-divider" />
                    </div>
                    <LanguageSelector />
                  </Else>
                </If>
              </Card>
            </div>
          </Stack>
        </div>

        <div className='content'>
          <Grid container>
            <Grid item sm={6}>
              <MerchantAppLogo />
            </Grid>
          </Grid>
        </div>
      </div>
    </ColoredPaper>
  )
}

export default MerchantStaffLoginPage