import React from "react";
import { Else, If, Then } from "react-if";

import { MerchantType } from "bos_common/src/types/MerchantType";

import ProductMenuPage from "./ProductMenuPage";
import ServiceMenuPage from "./ServiceMenuPage";

import { useAppSelector } from "../../redux/hooks";
import { getMerchant } from "../../redux/slice/merchant/merchantSelector";
import { isEmptyOrNil } from "bos_common/src";

const MerchandiseMenuPage = () => {
  const merchant = useAppSelector(getMerchant);
  if(isEmptyOrNil(merchant)) return null;

  return (
    <If condition={merchant?.type === MerchantType.services}>
      <Then>
        <ServiceMenuPage />
      </Then>
      <Else>
        <ProductMenuPage />
      </Else>
    </If>
  )
}

export default MerchandiseMenuPage;
