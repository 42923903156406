import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ReactSortable } from 'react-sortablejs';
import { omit } from 'ramda';

import { MerchandiseCategory } from '../../services/models';
import { getMerchandiseCategories } from '../../redux/slice/merchandise/merchandiseSelector';
import { useAppSelector } from '../../redux/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      gridGap: '10px',
      padding: '5px 10px',
      justifyContent: 'center',

      '& .sortable-chosen': {
        borderColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
        color: '#fff',
        opacity: 0.7,
      },
    },

    categoryChip: {
      background: '#eaebec',
      color: '#8f9598',
      border: '1px solid #91999b',
      borderRadius: '4px',
      fontSize: '16px',
    },
  })
);

type DraggableCategoriesListProps = {
  handleRenameCategoryDialog: (id: number) => void;
  handleDeleteCategory: (id: number) => void;
  categoriesList: MerchandiseCategory[];
  setCategoriesList: Dispatch<SetStateAction<MerchandiseCategory[]>>;
  setAreCategoriesChanged: (data: boolean) => void;
};

const DraggableCategoriesList = ({
  handleRenameCategoryDialog,
  handleDeleteCategory,
  setCategoriesList,
  setAreCategoriesChanged,
  categoriesList
}: DraggableCategoriesListProps) => {
  
  const classes = useStyles();
  
  const reduxCategoriesList = useAppSelector(getMerchandiseCategories);

  useEffect(() => {
    setCategoriesList(reduxCategoriesList);
  }, [reduxCategoriesList]);

  const handleCategoriesUpdate = () => {
    setAreCategoriesChanged(true);
    const updatedCategories = categoriesList.map((data: MerchandiseCategory, index: number) => {
      omit(['chosen', 'selected'], data);

      return {
        ...data,
        displayOrder: index,
      };
    }).sort((a, b) => a.displayOrder-b.displayOrder);
    setCategoriesList(updatedCategories);
  };

  return (
    <ReactSortable
      className={classes.root}
      animation={150}
      list={categoriesList}
      setList={setCategoriesList}
      onEnd={handleCategoriesUpdate}
    >
      {categoriesList.map((data: MerchandiseCategory, index: number) => {
        const { id, name } = data;

        return (
          <Chip
            key={`category-${index}`}
            className={classes.categoryChip}
            label={name}
            onDelete={() => handleDeleteCategory(id)}
            onClick={() => handleRenameCategoryDialog(id)}
          />
        );
      })}
    </ReactSortable>
  );
};

export default DraggableCategoriesList;
