import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';

// src
import { FabContainer } from 'bos_common/src/components/FabContainers';
import { CustomerType, MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';
import { Merchant } from 'bos_common/src/types/MerchantType';
import axios from 'bos_common/src/services/backendAxios';
import TypographyEllipsis from 'bos_common/src/components/TypographyEllipsis';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';

import CustomerEditDrawer from './CustomerEditDrawer';
import MerchantCustomerTabs from './MerchantCustomerTabs';
import { formatDate, getAPIErrorMessage, isEmptyOrNil } from '../../../utils';
import { AppContext } from '../../../context/AppContext';
import { NotificationSeverity } from '../../../types/NotificationSlice';
import InputSearchBox from 'bos_common/src/components/InputSearchBox';
import { isMerchantServicesType } from '../../../utils/merchantUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: theme.spacing(2, 0),
      width: '100%',

      '& .MuiTable-root': {
        '& .tableRow': {
          cursor: 'pointer',

          '& .MuiTableCell-root': {
            fontSize: theme.spacing(2),
          },

          '& .deleteBtn': {
            [theme.breakpoints.up('md')]: {
              visibility: 'hidden',
            },
          },

          '&:hover': {
            '& .deleteBtn': {
              visibility: 'visible',
            },
          },
        },
      },
    },
  })
);

interface CustomersListTableProps {
  merchant: Merchant;
  customersList: MerchantCustomer[];
  onRowClick: (_: MerchantCustomer) => void;
  onRowActionClick: (_: string) => void;
  currentRowPage: number;
  setCurrentRowPage: (_: number) => void;
  count: number;
}

const CustomersListTable = ({
  merchant,
  customersList = [],
  onRowClick,
  onRowActionClick,
  currentRowPage,
  setCurrentRowPage,
  count,
}: CustomersListTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentRowPage(newPage);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    const customerId = anchorEl?.getAttribute('data-id');
    if (customerId) onRowActionClick(customerId);
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} elevation={2} className={classes.table}>
      <Table stickyHeader aria-label="customers table">
        <TableHead>
          <TableRow>
            <TableCell component="th">
              <Typography>
                <b>{t('Name')}</b>
              </Typography>
            </TableCell>
            <TableCell component="th">
              <Typography>
                <b>{t('Phone')}</b>
              </Typography>
            </TableCell>
            <If condition={!isMobile}>
              <Then>
                <TableCell component="th">
                  <Typography>
                    <b>{t('Customer_Since')}</b>
                  </Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography>
                    <b>{t('Visit_Count')}</b>
                  </Typography>
                </TableCell>
              </Then>
            </If>
            {isMerchantServicesType(merchant) && <TableCell width={40} />}
          </TableRow>
        </TableHead>

        <If condition={isEmptyOrNil(customersList)}>
          <Then>
            <caption>{t('Customers_EmptyList')}</caption>
          </Then>
          <Else>
            <TableBody>
              {customersList.map((customer: any) => (
                <TableRow
                  key={`row-${customer.id}`}
                  hover
                  onClick={() => onRowClick(customer)}
                  className="tableRow"
                >
                  <TableCell>{customer.displayName}</TableCell>
                  <TableCell>{customer.phoneNumber ?? '-'}</TableCell>
                  <If condition={!isMobile}>
                    <Then>
                      <TableCell>{formatDate(customer.customerSince, "mm/dd/yy")}</TableCell>
                      <TableCell>
                        {customer.serviceCount || customer.visitCount || '0'}
                      </TableCell>
                    </Then>
                  </If>
                  {isMerchantServicesType(merchant) &&
                    <TableCell width={40}>
                      <IconButton
                        aria-describedby={`${customer.id}`}
                        data-id={`${customer.id}`}
                        onClick={handleClick}
                        sx={{ color: 'error.main' }}
                        className="deleteBtn"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </TableCell>
                  }
                </TableRow>
              ))}

              <Popover
                id={`${anchorEl}`}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    {t('Customers_DeleteWarning')}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Button variant="contained" color="primary" size="small" onClick={handleDelete}>
                      {t('Yes')}
                    </Button>
                    <Button variant="text" size="small" onClick={() => setAnchorEl(null)}>
                      {t('No')}
                    </Button>
                  </Stack>
                </Box>
              </Popover>
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={isMobile ? 3 : 5}
                  count={count}
                  page={currentRowPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={30}
                  rowsPerPageOptions={[]}
                />
              </TableRow>
            </TableFooter>
          </Else>
        </If>
      </Table>
    </TableContainer>
  );
};

interface CustomersListViewProps {
  customersList: MerchantCustomer[];
  count: number;
  merchant: Merchant;
  refreshCustomersList: () => void;
  selectedGroup: string;
  isLoading: boolean;
  currentRowPage: number;
  setCurrentRowPage: (_: number) => void;
  setSearchQuery: (_: string) => void;
}

const CustomersListView = ({
  selectedGroup,
  merchant,
  customersList = [],
  count = 0,
  refreshCustomersList,
  isLoading = false,
  currentRowPage,
  setCurrentRowPage,
  setSearchQuery,
}: CustomersListViewProps) => {
  const { t } = useTranslation();
  const { triggerNotification } = useContext(AppContext);

  const [createCustomerDrawer, setDrawerOpen] = useState<boolean>(false);
  const [infoDrawer, setInfoDrawerOpen] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<MerchantCustomer>();
  // const { token } = useContext(UserContext);
  // const readOnlyMode = selectedGroups.length > 0;

  // Filter List based on search query

  const handleAddCustomer = () => {
    setSelectedCustomer(undefined);
    setDrawerOpen(true);
  };

  const onCustomerUpdated = () => {
    setDrawerOpen(false);
    refreshCustomersList();
  };

  const onCustomerSelect = (customer: MerchantCustomer) => {
    setSelectedCustomer(customer);
    setInfoDrawerOpen(true);
  };

  const onEditCustomer = (customer: MerchantCustomer) => {
    setSelectedCustomer(customer);
    setDrawerOpen(true);
    setInfoDrawerOpen(false);
  };

  const deleteCustomer = async (customerId: string) => {
    axios
      .delete(`/customers/${customerId}`)
      .then(() => {
        triggerNotification(true, 'Customer Deleted Successfully!', NotificationSeverity.SUCCESS);
        refreshCustomersList();
      })
      .catch((err) => {
        triggerNotification(true, getAPIErrorMessage(err), NotificationSeverity.ERROR);
      });
  };

  const onCancelEdit = () => {
    setSelectedCustomer(undefined);
    setDrawerOpen(false);
  };

  const onCancelInfo = () => {
    setSelectedCustomer(undefined);
    setInfoDrawerOpen(false);
  };

  return (
    <>
      <SimpleLoader loading={isLoading} />
      <If condition={isEmptyOrNil(selectedGroup)}>
        <Then>
          <Typography gutterBottom variant="h6" sx={{ textTransform: 'capitalize' }}>
            {t('Customers_AllCustomers')}
          </Typography>
        </Then>
        <Else>
          {/* <If condition={selectedGroups.length === 1}> */}
          {/* <Then> */}
          <Typography gutterBottom variant="h6" sx={{ textTransform: 'capitalize' }}>
            {selectedGroup}
          </Typography>
          {/* </Then> */}
          {/* <Else>
              <Alert severity="info" sx={{ mb: 2 }}>
                {t('AudienceGroup_MultipleSelectionText')}
              </Alert>
            </Else>
          </If> */}
        </Else>
      </If>
      <InputSearchBox onChangeQuery={(s) => setSearchQuery(s)} placeholder={t('Search')} />
      <CustomersListTable
        merchant={merchant}
        customersList={customersList}
        onRowActionClick={deleteCustomer}
        onRowClick={onCustomerSelect}
        currentRowPage={currentRowPage}
        setCurrentRowPage={setCurrentRowPage}
        count={count}
      />
      <FabContainer>
        <Fab variant="extended" color="primary" onClick={handleAddCustomer}>
          <Add /> {t('Customers_AddACustomer')}
        </Fab>
      </FabContainer>

      <MerchantCustomerTabs
        customer={selectedCustomer}
        open={infoDrawer}
        setOpen={setInfoDrawerOpen}
        onCancel={onCancelInfo}
        merchant={merchant}
        editCustomer={onEditCustomer}
      />

      <CustomerEditDrawer
        customer={selectedCustomer}
        open={createCustomerDrawer}
        setOpen={setDrawerOpen}
        onSubmitSuccess={onCustomerUpdated}
        onCancel={onCancelEdit}
        merchant={merchant}
        customersList={customersList}
      />
    </>
  );
};

export default CustomersListView;
