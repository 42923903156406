import React from "react";
import { Channel, DefaultGenerics, StreamChat } from "stream-chat";

import { Merchant } from "../../../types/MerchantType";

export interface PlatformChatContextState {
  username: string,
  setUsername: (_: string) => void,

  isChatStarted: boolean,
  toggleChat: () => void,

  isChatDisabled: boolean,

  merchant?: Merchant,

  channel?: Channel,
  setChatChannel: (channel: Channel | undefined) => void;

  chatClient?: StreamChat<DefaultGenerics> | undefined
  setChatClient: (chatClient: StreamChat<DefaultGenerics> | undefined) => void;

  errorMessage?: string;
  isMerchantChat: boolean;

  cleanUpChat: () => void;
}

export const PlatformChatContext = React.createContext<PlatformChatContextState>({
  username: '',
  setUsername: (_: string) => ({}),

  isChatDisabled: false,

  isChatStarted: false,
  toggleChat: () => ({}),

  setChatClient: (chatClient: StreamChat<DefaultGenerics> | undefined) => ({}),

  setChatChannel: (channel: Channel | undefined) => ({}),

  errorMessage: '',
  isMerchantChat: false,

  cleanUpChat: () => ({})
})