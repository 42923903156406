import React, { useState } from 'react';
import { Collapse, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { If, Then, Else } from 'react-if'
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(.5)} 0`,

      '& .collapsible-container': {
        cursor: 'pointer',

        '& .collapsible-content': {
          display: 'flex',
          width: '100%'
        },

        '& .collapsible-label': {
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.spacing(0.5),
          '& .expandIcon': {
            marginLeft: theme.spacing(0.5),
          }
        }
      }
    }
  })
);

type CollapsibleContainerProps = {
  label: React.ReactElement | string,
  children: React.ReactElement | React.ReactElement[],
  defaultOpen?: boolean,
  isCollapsible?: boolean,
  showCollapseIcon?: boolean,
}

const CollapsibleContainer = (props: CollapsibleContainerProps) => {
  const {
    label,
    children,
    defaultOpen = false,
    isCollapsible = true,
    showCollapseIcon = true
  } = props
  const classes = useStyles()
  const [isOpen, toggleCollapse] = useState(defaultOpen)

  const onClick = (e: any) => {
    e.stopPropagation();

    if (!isCollapsible) return;
    toggleCollapse(!isOpen)
  }

  return (
    <div className={classes.root}>
      <Grid container className='collapsible-container'>
        <Grid item xs={12}>
          <div className='collapsible-label' onClick={onClick}>
            {label}
            <If condition={isCollapsible && showCollapseIcon}>
              <If condition={isOpen}>
                <Then><ExpandLess color='primary' fontSize='small' className="expandIcon" /></Then>
                <Else><ExpandMore color='primary' fontSize='small' className="expandIcon" /></Else>
              </If>
            </If>
          </div>
        </Grid>
        <Collapse in={isOpen} className='collapsible-content'>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Collapse>
      </Grid>
    </div>
  )
}

export default CollapsibleContainer;