import React from "react";
import {
  useScrollTrigger, makeStyles, Theme, createStyles, AppBar,
  Toolbar, Grid, CardHeader, Slide, useMediaQuery
} from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { If, Then, Else } from "react-if";

// src
import { Merchant } from "../../services/models";
import DocumentMetaTags from "../common/DocumentMetaTags";

import MerchantSelectorCTA from "../MerchantSelectorCTA";
import SideMenuSwitcher from "../SideMenu/SideMenuSwitcher";
import { isEmptyOrNil } from "../../utils";

import OrderTabButtons from "./OrderTabButtons";
import OrderFilters from "./OrderFilters";
import SearchOrder from "./SearchOrder";

type HideOnScrollProps = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .toolbar': {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
      '& .barRow1': {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        width: '100%',

        '& .grid-container': {
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        },
        '& .merchant-selector-cta': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        '& .MuiAvatar-root': {
          width: theme.spacing(6),
          height: theme.spacing(6),
        },
        '& .right': {
          display: "flex",
          flexShrink: 0,
          justifyContent: "flex-end",
          alignItems: 'center'
        },
        '& .MuiCardHeader-content': {
          overflow: 'hidden',
        },
        '& .store-name': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .store-subtitle': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
      '& .barRow2': {
        width: '100%',
        minHeight: '72px',

        [theme.breakpoints.down('sm')]: {
          minHeight: '151px',
        },

        '&.noMerchant': {
          minHeight: '65px',

          [theme.breakpoints.down('sm')]: {
            minHeight: '140px',
          },
        },
      },
    },
  }),
);

type OrderHeaderProps = {
  merchant?: Merchant,
}
const OrdersHeader = (props: OrderHeaderProps): React.ReactElement | null => {
  const classes = useStyles()
  const { merchant } = props
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation()

  const barRow2Classes = classNames({
    barRow2: true,
    noMerchant: isEmptyOrNil(merchant)
  })

  return (
    <DocumentMetaTags>
      <div className={classes.root}>
        <HideOnScroll {...props}>
          <AppBar color='inherit' elevation={3}>
            <Toolbar className='toolbar'>

              <div className="barRow1">
                <Grid className='grid-container' container item justifyContent="start" flexDirection="row" xs={10} sm={11}>
                  <If condition={!isEmptyOrNil(merchant)}>
                    <Then>
                      <MerchantSelectorCTA />
                    </Then>
                    <Else>
                      <CardHeader elevation={3} title={t("Orders")} />
                    </Else>
                  </If>
                  {!isMobile && (<OrderTabButtons />)}
                </Grid>

                {!isMobile && (<SearchOrder />)}

                <div className="right">
                  <SideMenuSwitcher />
                </div>
              </div>

              {isMobile && (<SearchOrder />)}
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        {/* shown behind barRow1 for height adjustment */}
        <div className={barRow2Classes} />
        <OrderFilters merchant={merchant} />
      </div>
    </DocumentMetaTags>
  );
}

export default OrdersHeader