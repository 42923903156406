export const NORMAL_BUSINESS_HOURS = [
  {
    days: [],
    shifts: [
      {
        enabled: true,
        startTimeOffset: undefined,
        endTimeOffset: undefined,
      },
    ],
  },
];

export const SPECIAL_BUSINESS_HOURS = [
  {
    type: 'DATE_RANGE',
    data: {
      startDate: new Date(),
      endDate: new Date(),
      shifts: [
        {
          enabled: true,
          startTimeOffset: undefined,
          endTimeOffset: undefined,
        },
      ],
    },
  },
];
