import { Views, View } from 'react-big-calendar';
import React from 'react';

import { MerchantStaff } from 'bos_common/src/types/MerchantStaff';
import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';
import CustomerScheduleEntry, { AttendeeStatus, ChangeAppliedTo } from 'bos_common/src/types/crm/CustomerScheduleEntryType';
import { Merchandise } from 'bos_common/src/types/MerchandiseType';
import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';

import { BigCalendarEvent, CalenderEventPayload } from '../types';

export type EventsData = {
  [key: number]: BigCalendarEvent;
};

interface CalendarContextState {
  fetchCalendarEvents: () => void;
  addCalendarEvent: (_: CalenderEventPayload) => any;
  updateCalendarEvent: (_: CalenderEventPayload) => any;
  deleteCalendarEvent: (_: number, option: ChangeAppliedTo) => any
  loading: boolean;
  customersList: MerchantCustomer[];

  eventsData: EventsData;
  setEventsData: (el: EventsData | ((p: EventsData) => EventsData)) => void;

  staffList: MerchantStaff[];

  selectedStaffList: MerchantStaff[];
  setSelectedStaffList: (sl: MerchantStaff[]) => void;

  selectedEvent?: BigCalendarEvent;
  setSelectedEvent: (_?: BigCalendarEvent) => void;

  updateCustomerAttendanceAndNote: (a: CustomerScheduleEntry, attendance: AttendeeStatus, note?: string) => void;
  updateEventPaymentAmount: (_: number) => any;

  services: Merchandise[];

  packages: MerchantPackage[];

  calendarView: View;
  setCalendarView: (_: View) => void;
}

export const CalendarContext = React.createContext<CalendarContextState>({
  fetchCalendarEvents: () => { },
  addCalendarEvent: () => { },
  updateCalendarEvent: () => { },
  deleteCalendarEvent: async () => true,

  loading: false,
  customersList: [],

  staffList: [],

  selectedStaffList: [],
  setSelectedStaffList: () => { },

  eventsData: {},
  setEventsData: () => { },

  selectedEvent: undefined,
  setSelectedEvent: () => { },

  calendarView: Views.DAY,
  setCalendarView: () => { },

  updateCustomerAttendanceAndNote: () => { },
  updateEventPaymentAmount: () => { },

  services: [],

  packages: [],
});
