import { MerchantPromotion, PromotionCriteriaType } from "./MerchantPromotionType";
import { Merchant } from "./MerchantType";

export enum CouponValueType {
  /* Get 2$ off */
  ABSOLUTE = 'absolute',

  /* Get 20% off */
  PERCENTAGE = 'percentage',

  /* Buy a drink and a side, get 2$ off */
  COMBO_ABSOLUTE = 'combo_absolute',

  /* Buy a drink and a side, get 20% off */
  COMBO_PERCENTAGE = 'combo_percentage',

  /* Buy one drink, get second drink for free */
  BUY_ONE_GET_ONE_FREE = 'buy_one_get_one_free',

  /* Buy one drink, get second drink for free, discount limit to 1 item only */
  BUY_ONE_GET_ONE_ONLY_FREE = 'buy_one_get_one_only_free',

  /* Buy one sandwich, get a side for free */
  BUY_X_GET_Y_FREE = 'buy_x_get_y_free',

  /* Get one free item when meeting criteria */
  GET_ONE_FREE = 'get_one_free',

  /* Buy one drink, get second drink 50% off */
  BUY_ONE_GET_ONE_PERCENTAGE = 'buy_one_get_one_percentage',

  /* Buy one drink, get second drink 50% off, discount limit to 1 item only */
  BUY_ONE_GET_ONE_ONLY_PERCENTAGE = 'buy_one_get_one_only_percentage',
}

export enum ItemType {
  CATEGORY = 'category',
  MERCHANDISE = 'merchandise',
}

export interface AffectedItem {
  type: ItemType,
  id: number | string;
  qty?: number
}

export interface CouponCriteria {
  type: PromotionCriteriaType,
  value?: number
}

export interface CouponRoutine {
  dayOfWeek: number[];
  startMinOffset: number;
  endMinOffset: number;
}

export interface MerchantCoupon {
  id: number;
  title: string;
  description?: string;
  merchant: Merchant;
  code: string;
  valueType: CouponValueType;
  value: number;
  criteriaType: PromotionCriteriaType;
  criteriaValue: number;
  limit: number | null;
  usedCount: number;
  startDate: Date;
  endDate: Date;
  live: boolean;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  promotion?: MerchantPromotion;
  affectedItems?: AffectedItem[];
  freeItems?: AffectedItem[];
  criteriaList?: CouponCriteria[];
  routines?: CouponRoutine[];
  photos?: string[];
}
