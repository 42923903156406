import React, { useMemo, useState } from "react"
import { Search } from "@material-ui/icons";
import { append, pluck, without } from "ramda";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Box, Button, Checkbox, DialogActions, DialogContent, Divider, Fab, FormControlLabel, Grid, Stack, Theme, Typography } from "@material-ui/core";

import { isEmptyOrNil } from "bos_common/src";
import { Merchandise } from "bos_common/src/types/MerchandiseType";
import InputSearchBox from "bos_common/src/components/InputSearchBox";

import CustomDialog from "../common/CustomDialog"
import { useMerchantMenuContext } from "../../context/MenuContext/MerchantMenuContext";
import { Else, If, Then } from "react-if";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .listWrapper": {
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
        borderRadius: theme.spacing(1),
        maxHeight: '500px',
        overflow: "auto",
      },

      "& .titlesContainer": {
        padding: theme.spacing(1.5, 2),
      },

      "& .checkboxWrapper": {
        width: "100%",
        marginRight: 0,
        gap: theme.spacing(8),
        padding: theme.spacing(0.5, 2),
        marginLeft: 0,
        boxSizing: "border-box",
      },

      '& .actionButton': {
        boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
        color: theme.palette.secondary.main,
        textTransform: "unset",
      }
    },
  })
);

type AddClassesDialogProps = {
  isOpen: boolean;
  setOpen: (_: boolean) => void;
}

const AddClassesDialog = (props: AddClassesDialogProps) => {
  const { isOpen, setOpen } = props;

  const { getValues, setValue, trigger, register } = useFormContext();

  const selectedItems = pluck('id', getValues('eligibleClasses'));

  const [searchQuery, setSearchQuery] = useState<string>();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>(selectedItems);

  const classes = useStyles();

  const { state } = useMerchantMenuContext();

  const { t } = useTranslation();

  const getSelectedItems = (selectedItemIds: string[]) => {
    const selectedItems = state.classes.filter(item => selectedItemIds.includes(item.id));

    return selectedItems;
  }

  // Filter List based on search query
  const filteredItemsList: Merchandise[] = useMemo(() => {
    if (!searchQuery || isEmptyOrNil(searchQuery)) {
      // sort selected classes at the top of list
      return state.classes;
    }

    return state.classes.filter((merchandise: Merchandise) => {
      return merchandise.name?.toLowerCase().includes(searchQuery.toLowerCase())
    })
  }, [state.classes, searchQuery])

  const toggleDialog = () => {
    setOpen(!isOpen);
  }

  const handleApplyClick = () => {
    register('eligibleClasses');
    setValue('eligibleClasses', getSelectedItems(selectedItemIds));
    trigger('eligibleClasses')
    toggleDialog();
  }

  const handleCheckboxClick = (selectedId: string) => {
    let selectedItems = [];

    if (selectedItemIds.includes(selectedId)) {
      selectedItems = without([selectedId], selectedItemIds);
    } else {
      selectedItems = append(selectedId, selectedItemIds);
    }

    setSelectedItemIds(selectedItems);
  }

  const handleSelectAll = () => {
    const items = pluck('id', filteredItemsList);
    setSelectedItemIds(items);
  }

  const handleSelectNone = () => {
    setSelectedItemIds([]);
  }

  return (
    <CustomDialog
      title={t('Service_AddEligibleClasses')}
      open={isOpen}
      setOpen={toggleDialog}
      maxWidth="sm"
      fullWidth
      footerActions={
        <DialogActions sx={{ justifyContent: 'center', width: "100%" }}>
          <Fab
            onClick={toggleDialog}
            variant="extended"
            color="secondary"
            sx={{ textTransform: "unset", pl: 7, pr: 7 }}
          >
            {t("Back")}
          </Fab>

          <Fab
            onClick={handleApplyClick}
            variant="extended"
            color="primary"
            sx={{ textTransform: "unset", pl: 7, pr: 7 }}
          >
            {t("Apply")}
          </Fab>
        </DialogActions>
      }
    >
      <DialogContent className={classes.root}>
        <InputSearchBox
          placeholder="Search"
          onChangeQuery={(s) => setSearchQuery(s)}
          leftChild={<Search />}
        />

        <Stack sx={{ padding: (theme) => theme.spacing(2) }} direction="row" gap={2} justifyContent="center">
          <Button className="actionButton" onClick={handleSelectNone}>{t('SelectNone')}</Button>
          <Button className="actionButton" onClick={handleSelectAll}>{t('SelectAll')}</Button>
        </Stack>

        <Box className="listWrapper">
          <If condition={!isEmptyOrNil(filteredItemsList)}>
            <Then>
              <Stack direction="row" gap={7} className="titlesContainer">
                <Typography variant="body1" fontWeight="700">
                  {t('Select')}
                </Typography>

                <Typography variant="body1" fontWeight="700">
                  {t('Name')}
                </Typography>
              </Stack>
            </Then>
            <Else>
              <Typography variant="body1" textAlign="center" p={3}>{t('Message_NoClasses')}</Typography>
            </Else>
          </If>

          {
            filteredItemsList.map((data) => {
              const { name, id } = data;

              return (
                <React.Fragment key={id}>
                  <Divider />

                  <FormControlLabel
                    className="checkboxWrapper"
                    control={
                      <Checkbox checked={selectedItemIds.includes(id)} onChange={() => handleCheckboxClick(id)} />
                    }
                    label={name}
                  />
                </React.Fragment >
              )
            })
          }
        </Box>
      </DialogContent>
    </CustomDialog >
  )
}

export default AddClassesDialog;
