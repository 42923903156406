import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthSliceStateEntities } from "../../../types/AuthSlice";

export const authFetching = createAction('auth/setLoadingAuth');

export const authFetchSucceed = createAction<AuthSliceStateEntities>('auth/updateAuth');

export const authFetchFailed = createAction('auth/stopLoadingAuth');

export const fetchAuth = createAsyncThunk(
  'auth/fetchAuth',
  async (payload: AuthSliceStateEntities) => {
    return payload;
  }
);
