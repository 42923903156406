import {
  MerchandiseApiResponseType,
  MerchantAggregatedSalesStats,
  MerchantStatsApiResponse,
  Order,
} from '../../services/models';

export type MerchantStatsSelection = 'week' | 'day' | 'custom';

export type DateOptions = {
  fromDate: Date;
  toDate: Date;
};

export enum MerchantStatsPeriod {
  Week = 'week',
  Day = 'day',
  Custom = 'custom',
}

export type OrdersGroupedByDay = {
  timePoint: string;
  allOrdersList: Array<Order>;
};

export type MerchandiseStatsListingProps = {
  merchandiseData?: MerchandiseApiResponseType;
  merchantStats: MerchantAggregatedSalesStats;
  comparisonStats?: MerchantAggregatedSalesStats;
  title?: string;
};
export interface CouponStatsTableProps {
  currentStatsApiData?: MerchantStatsApiResponse;
}
