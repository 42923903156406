import React, { useContext } from "react";
import ChevronRight from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { If, Then, Else } from 'react-if';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

import { UserContext } from 'bos_common/src/context/UserContext';

import { AppContext } from '../../context/AppContext';
import { userIsStaff, userIsAdmin, userIsOneMOperator } from '../../utils';
import LocalesSelect from '../common/LocalesSelect';
import { OrderPageType } from '../../pages/OrderPageType';
import MerchantOnlineOrderingSwitch from '../MerchantOnlineOrderingSwitch';
import MerchantInStoreOrderingSwitch from '../MerchantInStoreOrderingSwitch';
import SideMenuContainer from "./SideMenuContainer";

const SideMenuProducts = (): JSX.Element => {
  const history = useHistory();
  const { merchant } = useContext(AppContext);
  const { user } = useContext(UserContext);
  const isStaff = userIsStaff(user);
  const isAdmin = userIsAdmin(user);
  const { t } = useTranslation();

  const isOneMOperator = userIsOneMOperator(user);
  const merchantUsername = merchant?.username || '';

  return (
    <SideMenuContainer>
      <>
        <Card className="headerWrap">
          <CardContent className="header">
            <Typography variant="h6" component="div">
              {user?.displayName || user?.phoneNumber}
            </Typography>
            <LocalesSelect />
          </CardContent>
        </Card>

        <Divider />

        <If condition={!isOneMOperator}>
          <Then>
            <Divider />
            <div className="blue-background-section">
              <MerchantOnlineOrderingSwitch />
              <MerchantInStoreOrderingSwitch />
            </div>
          </Then>
        </If>

        <Divider />

        <List className='content'>
          <If condition={isOneMOperator}>
            <Then>
              <ListItem
                button
                key="Orders Hub"
                onClick={() => {
                  history.push(`/orders-hub/${OrderPageType.NEW}`);
                }}>
                <ListItemText primary={t("OrdersHub")} />
                <ChevronRight />
              </ListItem>
            </Then>
            <Else>
              {/* Owner Actions */}
              <If condition={!isStaff && !isAdmin}>
                <ListItem
                  button
                  key="Create"
                  onClick={() => {
                    history.push('/create');
                  }}>
                  <ListItemText primary={t("CreateYourStore")} />
                  <ChevronRight />
                </ListItem>
              </If>

              {/* Staff OR Admin Actions */}
              <If condition={isStaff || isAdmin}>
                <Then>
                  <ListItem
                    button
                    key="Orders"
                    onClick={() => {
                      history.push(`/${merchantUsername}/orders/${OrderPageType.NEW}`);
                    }}>
                    <ListItemText primary={t("Orders")} />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    key="Inventory"
                    onClick={() => {
                      history.push(`/${merchantUsername}/inventory`);
                    }}>
                    <ListItemText primary={t("Inventory")} />
                    <ChevronRight />
                  </ListItem>
                </Then>
              </If>

              <If condition={isStaff || !isAdmin}>
                <Then>
                  <ListItem
                    button
                    key="Order Queue"
                    onClick={() => {
                      history.push(`/${merchantUsername}/callboard`);
                    }}>
                    <ListItemText primary={t("Order_Queue")} />
                    <ChevronRight />
                  </ListItem>
                </Then>
              </If>

              {/* Admin Only Actions */}
              <If condition={isAdmin}>
                <Then>
                  <ListItem
                    button
                    key="Reviews"
                    onClick={() => {
                      history.push(`/${merchantUsername}/reviews`);
                    }}>
                    <ListItemText primary={t("Reviews")} />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    key="Reports"
                    onClick={() => {
                      history.push(`/${merchantUsername}/reports`);
                    }}>
                    <ListItemText primary={t("MerchantService_Reports")} />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    key="Customers"
                    onClick={() => {
                      history.push(`/${merchantUsername}/customers`);
                    }}>
                    <ListItemText primary={t("MerchantService_Customers")} />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    key="menu"
                    onClick={() => {
                      history.push(`/${merchantUsername}/menu`);
                    }}>
                    <ListItemText primary={t("Menu")} />
                    <ChevronRight />
                  </ListItem>

                  <Divider />

                  <ListItem
                    button
                    key="Staffs"
                    onClick={() => {
                      history.push(`/${merchantUsername}/staffs`);
                    }}>
                    <ListItemText primary={t("Staffs")} />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    key="business"
                    onClick={() => {
                      history.push(`/${merchantUsername}/bizsettings`);
                    }}>
                    <ListItemText primary={t("MyStore")} />
                    <ChevronRight />
                  </ListItem>
                </Then>
              </If>
            </Else>
          </If>

          <Divider />

          {/* Logout Action */}
          <ListItem
            button
            key="account"
            onClick={() => {
              history.push('/user');
            }}
          >
            <ListItemText primary={t("Account")} />
            <ChevronRight />
          </ListItem>
        </List >

        <Divider />
      </>
    </SideMenuContainer>
  )
}

export default SideMenuProducts;
