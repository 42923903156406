import React, { useContext, useState } from 'react';
import { MoreVert, Edit, Send, StarBorder, Star, Delete } from '@material-ui/icons';
import {
  ClickAwayListener,
  createStyles,
  makeStyles,
  MenuList,
  Paper,
  Popper,
  Theme,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { If, Then } from 'react-if';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getAuthHeaders } from '../bos_common/src';
import axios from '../bos_common/src/services/backendAxios';
import { UserContext } from '../bos_common/src/context/UserContext';
import ConfirmationAlert from '../bos_common/src/components/ConfirmationAlert';

import { Merchandise, Merchant } from '../services/models';
import { isMerchantServicesType } from '../utils/merchantUtils';
import AddItemToCategoryDialog from './Categories/AddItemToCategoryDialog';

import {
  fetchMerchandises,
  addFeaturedCategoriesSucceed,
  deleteMerchandiseItemSucceed,
} from '../redux/slice/merchandise/merchandiseActions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getMerchant } from '../redux/slice/merchant/merchantSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .dropdown-button': {
        margin: '0 !important',
        marginRight: `-${theme.spacing(1)} !important`,
      },
    },

    menuDropdownWrapper: {
      zIndex: 99,

      '& .MuiMenu-paper': {
        maxWidth: '250px',
        width: '100%',
      },

      '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    menuDropdownItem: {
      gridGap: '15px',
      padding: '10px 25px',
      transition: 'all 200ms linear',
      color: '#414141',

      '&:hover': {
        background: '#edf7f8',
        color: '#2098aa',
      },

      '&.warning': {
        color: '#ff6565',
      },
    },
  })
);

type MenuItemCardDropdownProps = {
  onEditClick: () => void;
  merchandise: Merchandise;
};

interface IMerchandisePageParams {
  merchantUsername: string;
}

const MenuItemCardDropdown = ({ onEditClick, merchandise }: MenuItemCardDropdownProps) => {
  const [isCatagoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [isDeleteAlertOpen, toggleDeleteAlert] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { merchantUsername } = useParams<IMerchandisePageParams>();
  const classes = useStyles();
  const merchant = useAppSelector(getMerchant);

  const isServicesMerchant = isMerchantServicesType(merchant || {} as Merchant)

  const reduxDispatch = useAppDispatch();

  const { token } = useContext(UserContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleEditClick = () => {
    onEditClick();
    handleToggle();
  };

  const handleCategoryDialogOpen = () => {
    setCategoryDialogOpen(true);
    handleToggle();
  };

  const handleFeatureItem = () => {
    const { featured, ...rest } = merchandise;
    axios
      .put(
        `/merchants/merchandises/${merchandise.id}`,
        { ...rest, featured: !featured },
        { headers: getAuthHeaders(token) }
      )
      .then((response) => {
        if (response.status === 200) {
          reduxDispatch(addFeaturedCategoriesSucceed(response.data));
        }
      });
    handleToggle();
  };

  const handleDeleteItem = () => {
    axios
      .delete(`merchants/merchandises/${merchandise.id}`, { headers: getAuthHeaders(token) })
      .then((response) => {
        if (response.status === 200) {
          reduxDispatch(deleteMerchandiseItemSucceed(merchandise.id));
          // Get all the data again
          reduxDispatch(fetchMerchandises(merchantUsername));
          toggleDeleteAlert(false);
          handleToggle();
        }
      });
  };

  const handleCloseDeleteAlert = () => {
    toggleDeleteAlert(false);
    handleToggle();
  };

  return (
    <div className={classes.root}>
      <IconButton
        ref={anchorRef}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-label="more"
        color="primary"
        onClick={handleToggle}
        className="dropdown-button"
      >
        <MoreVert />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.menuDropdownWrapper}
        placement="auto-end"
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              <MenuItem className={classes.menuDropdownItem} onClick={handleEditClick}>
                <Edit /> {t('Edit')}
              </MenuItem>
              <If condition={!isServicesMerchant}>
                <Then>
                  <MenuItem className={classes.menuDropdownItem} onClick={handleCategoryDialogOpen}>
                    <Send />{' '}
                    {merchandise.categoryId != null ? t('Menu_MoveCategory') : t('Menu_AddCategory')}
                  </MenuItem>
                  <MenuItem className={classes.menuDropdownItem} onClick={handleFeatureItem}>
                    {merchandise.featured ? (
                      <>
                        <StarBorder />
                        {t('Menu_UnfeatureItem')}
                      </>
                    ) : (
                      <>
                        <Star /> {t('Menu_FeatureItem')}
                      </>
                    )}
                  </MenuItem>
                </Then>
              </If>
              <MenuItem
                className={`${classes.menuDropdownItem} warning`}
                onClick={() => toggleDeleteAlert(true)}
              >
                <Delete /> {t('Delete')}
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>

      <ConfirmationAlert
        isOpen={isDeleteAlertOpen}
        title={t('Menu_DeleteMenuItem')}
        onCancel={handleCloseDeleteAlert}
        onConfirm={handleDeleteItem}
        cancelLabel={t('Cancel')}
        confirmLabel={t('Confirm')}
      >
        {t('Menu_DeleteMenuItem_Description')}
      </ConfirmationAlert>

      <AddItemToCategoryDialog
        open={isCatagoryDialogOpen}
        setOpen={setCategoryDialogOpen}
        merchandise={merchandise}
      />
    </div>
  );
};

export default MenuItemCardDropdown;
