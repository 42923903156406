import React from "react";
import { Box, DialogContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { Else, If, Then } from "react-if";

import { isEmptyOrNil } from "bos_common/src";
import CustomerSubscriptionBalance from "bos_common/src/types/crm/CustomerSubscriptionBalanceType";
import CustomDialog from "../../../common/CustomDialog";

type PastMembershipsDialogProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
  expiredSubscriptions: CustomerSubscriptionBalance[];
}

const PastMembershipsDialog = (props: PastMembershipsDialogProps) => {
  const { open, setOpen, expiredSubscriptions } = props;

  return (
    <CustomDialog
      title="Past Memberships & Packages"
      open={open}
      setOpen={setOpen}
      maxWidth="sm"
      fullWidth>
      <DialogContent sx={{minHeight: '400px'}}>
        <If condition={isEmptyOrNil(expiredSubscriptions)}>
          <Then>
            <Typography variant="subtitle1" component="div" textAlign="center">No past memberships/packages found</Typography>
          </Then>
          <Else>
            <Table component={Paper} elevation={3} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "8px 16px", fontWeight: "bold" }}>Type</TableCell>
                  <TableCell sx={{ padding: "8px 16px", fontWeight: "bold" }}>Date Added</TableCell>
                  <TableCell sx={{ padding: "8px 16px", fontWeight: "bold" }}>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  expiredSubscriptions.map((data: CustomerSubscriptionBalance) => {
                    const { startDate, createdAt, endDate, deletedOn, merchantPackage, merchantMembership, id } = data;
                    const subscriptionStartDate = startDate || createdAt
                    const subscriptionEndDate = deletedOn || endDate
                    if (!isEmptyOrNil(merchantPackage)) {
                      return (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`subscription_package_${id}`}>
                          <TableCell>{merchantPackage!.name}</TableCell>

                          <If condition={!isEmptyOrNil(subscriptionStartDate)}>
                            <Then>
                              <TableCell>{format(new Date(subscriptionStartDate), "MM/dd/yyyy")}</TableCell>
                            </Then>
                            <Else>
                              <TableCell> --- </TableCell>
                            </Else>
                          </If>

                          <If condition={!isEmptyOrNil(subscriptionEndDate)}>
                            <Then>
                              <TableCell>{format(new Date(subscriptionEndDate), "MM/dd/yyyy")}</TableCell>
                            </Then>
                            <Else>
                              <TableCell> --- </TableCell>
                            </Else>
                          </If>
                        </TableRow>
                      )
                    }

                    if (!isEmptyOrNil(merchantMembership)) {
                      return (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`subscription_package_${id}`}>
                          <TableCell>{merchantMembership!.name}</TableCell>

                          <If condition={!isEmptyOrNil(subscriptionStartDate)}>
                            <Then>
                              <TableCell>{format(new Date(subscriptionStartDate), "MM/dd/yyyy")}</TableCell>
                            </Then>
                            <Else>
                              <TableCell> --- </TableCell>
                            </Else>
                          </If>

                          <If condition={!isEmptyOrNil(subscriptionEndDate)}>
                            <Then>
                              <TableCell>{format(new Date(subscriptionEndDate), "MM/dd/yyyy")}</TableCell>
                            </Then>
                            <Else>
                              <TableCell> --- </TableCell>
                            </Else>
                          </If>
                        </TableRow>
                      )
                    }
                  })
                }
              </TableBody>
            </Table>
          </Else>
        </If>
      </DialogContent>
    </CustomDialog>
  )
}

export default PastMembershipsDialog;
