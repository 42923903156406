import React, { useCallback, useMemo } from 'react';
import {
  Card,
  makeStyles,
  Theme,
  createStyles,
  CardActionArea,
  CardMedia,
  Typography,
  Box,
  Stack,
  CardMediaTypeMap,
} from '@material-ui/core';
import { If, Then } from 'react-if';
import { propOr } from 'ramda';
import { Menu, VisibilityOff } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';
import MerchantMembership, { MembershipType } from 'bos_common/src/types/crm/MerchantMembershipType';
import { withFallbackPath } from 'bos_common/src';
import MerchandiseDescription from 'bos_common/src/components/Merchandise/MerchandiseDescription';
import TypographyEllipsis from 'bos_common/src/components/TypographyEllipsis';
import { renderPriceWithUnit } from 'bos_common/src/components/Price';

import ServiceItemCardDropdown from '../../ServiceItemCardDropdown';
import { MerchandiseUnit } from 'bos_common/src/types/MerchandiseType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MenuCardContainer: {
      height: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',

      '& .cardContent': {
        display: 'flex',
        gridGap: theme.spacing(2),
        height: '100%',
      },

      '& .details': {
        flexGrow: 1,
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',

        '& .dragHandle': {
          cursor: 'pointer',
          paddingLeft: theme.spacing(1),
        },

        '& .priceRow': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'unset',
        },
      },

      '& .merchActions': {
        display: 'flex',
        alignItems: 'center',
        gridGap: theme.spacing(0.5),
      },

      '& .merchPhoto': {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column',
        width: 'inherit',

        '& .coverContainer': {
          position: 'relative',
        },

        '& .cover': {
          width: 120,
          height: 120,
          borderRadius: theme.spacing(1),
        },
      },
    },
  })
);

type MembershipPackageItemCardProps = {
  merchandise: MerchantPackage | MerchantMembership;
  onMerchandiseClick: (merchandise: MerchantPackage | MerchantMembership) => void;
  showExtraActions?: boolean;
  showReviews?: boolean;
  handleDeleteItem: (_: number) => void;
};

const MembershipPackageItemCard = (props: MembershipPackageItemCardProps) => {
  const classes = useStyles();

  const { merchandise, onMerchandiseClick, handleDeleteItem, showExtraActions = false, showReviews = false } = props;
  const { photos, name, price } = merchandise;
  const photo = propOr('', 0, photos); // select first photo
  const CardMediaEnhanced: OverridableComponent<CardMediaTypeMap<{}, 'div'>> = useMemo(
    () => withFallbackPath(CardMedia, photo),
    [photo]
  );

  const onMerchandiseItemClick = () => {
    onMerchandiseClick(merchandise);
  };

  const renderPrice = () => {
    const membership = merchandise as MerchantMembership | undefined;
    if (membership && membership.type) {
      switch (membership.type) {
        case MembershipType.yearly:
          return renderPriceWithUnit(membership.price, undefined, MerchandiseUnit.YEAR);
        case MembershipType.monthly:
          return renderPriceWithUnit(membership.price, undefined, MerchandiseUnit.MONTH);
        case MembershipType.trial:
          return "Trial class";
      }
    }
    const mPackage = merchandise as MerchantPackage | undefined;
    if (mPackage && mPackage.creditLimit) {
      return renderPriceWithUnit(mPackage.price, undefined, MerchandiseUnit.CLASS, mPackage.creditLimit);
    }
  };

  return (
    <Card className={classes.MenuCardContainer} elevation={3}>
      <div className='cardContent'>
        <If condition={photo}>
          <Then>
            <CardActionArea className="merchPhoto" onClick={onMerchandiseItemClick}>
              <div className="coverContainer">
                <CardMediaEnhanced
                  className="cover"
                  src={`${photo}_small`}
                  title={name}
                  component="img"
                  alt="merch-img"
                />
              </div>
            </CardActionArea>
          </Then>
        </If>

        <Stack className="details" flexDirection="column" justifyContent="space-between">
          <Stack direction="row">
            <CardActionArea onClick={onMerchandiseItemClick} sx={{width: 'calc(100% - 36px)'}}>
              <Box>
                <TypographyEllipsis>{name}</TypographyEllipsis>
              </Box>
              <MerchandiseDescription merchandise={merchandise} />
              {!merchandise.live && <VisibilityOff className="hideIcon" />}
            </CardActionArea>
            <div className="dragHandle">
              <Menu />
            </div>
          </Stack>
          <div className="priceRow">
            <Typography variant="subtitle1" color="textSecondary" component="div">
              {renderPrice()}
            </Typography>
            <If condition={showExtraActions}>
              <div className="merchActions">
                <ServiceItemCardDropdown
                  onEditClick={onMerchandiseItemClick}
                  merchandise={merchandise}
                  handleDeleteItem={handleDeleteItem}
                />
              </div>
            </If>
          </div>
        </Stack>
      </div>
    </Card>
  );
};

export default MembershipPackageItemCard;
