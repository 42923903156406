import React, { useContext } from "react"
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { RefetchOptions } from "axios-hooks";
import { useTranslation } from "react-i18next";

import axios from "bos_common/src/services/backendAxios";
import { UserContext } from "bos_common/src/context/UserContext";
import { getAuthHeaders } from "bos_common/src/utils";
import { MerchantCustomer } from "bos_common/src/types/crm/MerchantCustomerType";
import ConfirmationAlert from "bos_common/src/components/ConfirmationAlert";

type DeleteMembershipDialogProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
  customer: MerchantCustomer;
  subscriptionId: number;
  refetchSubscriptions: (config?: AxiosRequestConfig<any> | undefined, options?: RefetchOptions | undefined) => AxiosPromise<any>;
  setLoading: (_: boolean) => void;
}

const DeleteMembershipDialog = (props: DeleteMembershipDialogProps) => {
  const { open, setOpen, subscriptionId, customer, refetchSubscriptions, setLoading } = props;
  const { token } = useContext(UserContext);
  const { t } = useTranslation();

  const handleDeleteClick = () => {
    setLoading(true);
    axios
      .delete(
        `/customers/${customer.id}/subscriptions/${subscriptionId}`,
        { headers: getAuthHeaders(token) }
      )
      .then(() => {
        setLoading(false);
        refetchSubscriptions();
        setOpen(false);
      })
      .catch(() => {
        setLoading(false);
      })
  }

  return (
    <ConfirmationAlert
      isOpen={open}
      title={"Delete Membership"}
      onCancel={() => setOpen(false)}
      onConfirm={handleDeleteClick}
      cancelLabel={t('Cancel')}
      confirmLabel={t('Confirm')}>
      Are you sure you want to delete {customer.displayName}’s membership? <br />This action cannot be undone and the customer’s remaining credits will be permanently deleted.
    </ConfirmationAlert>
  )
}

export default DeleteMembershipDialog;