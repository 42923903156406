import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  createStyles,
  Select,
  MenuItem,
} from '@material-ui/core';
import { rgba } from 'polished';

import SpecialDateRange from "./SpecialDateRange"
import { getHourRanges, HourOption } from '../../utils';
import { StyledTabPanel } from '../StyledTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .dateRangeWrapper': {
        display: 'grid',
        alignItems: 'center',

        '& .dateRangeContent': {
          display: 'flex',
          alignItems: "center",
          gap: theme.spacing(2),
          padding: theme.spacing(0, 2),
          marginBottom: theme.spacing(1),

          "&:first-child": {
            marginBottom: 0,
          },

          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
            flexDirection: "column",
            gap: 0,
            height: "105px",
            flexWrap: "wrap",
            width: "79%",
            justifyContent: "center",
          }
        }
      },

      '& .weekDaysWrapper': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
      },

      '& .MuiTabs-root': {
        minHeight: 'unset',
        padding: theme.spacing(2),
        paddingTop: 0,

        '& .MuiTabs-flexContainer': {
          display: 'inline-flex',
          border: '1px solid #E34D28',
          borderRadius: '5px',
        },

        '& .MuiButtonBase-root': {
          minHeight: 'unset',
          textTransform: 'unset',
          fontSize: '14px',
          fontWeight: 700,
          background: rgba(theme.palette.error.main, 0.15),
          color: theme.palette.error.main,

          '&.Mui-disabled': {
            opacity: 0.5
          },

          '&.Mui-selected': {
            background: theme.palette.error.main,
            color: theme.palette.background.paper,
          },
        },

        '& .MuiTabs-indicator': {
          display: 'none',
        },

      },

      '& .actionsWrapper': {
        display: 'flex',
        gap: theme.spacing(4.2),
        padding: `0 ${theme.spacing(3.1)} ${theme.spacing(2)}`,
      },
    },
  })
);

const SpecialHoursTabs = ({ currentHourIndex }: { currentHourIndex: number; }) => {
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [hoursOptions, setHoursOptions] = useState<Array<HourOption>>([]);

  const classes = useStyles();

  useEffect(() => {
    setHoursOptions(getHourRanges());
  }, []);

  return (
    <Box className={classes.root}>
      <StyledTabPanel value={currentTab} tabKey={0}>
        <div className="divider" />

        <div className="weekDaysWrapper">
          <Select defaultValue={1} variant="outlined" size="small" sx={{ minWidth: '265px' }}>
            {hoursOptions.map((option) => (
              <MenuItem key={option.label} value={option.minuteOffset}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          -
          <Select defaultValue={1} variant="outlined" size="small" sx={{ minWidth: '265px' }}>
            {hoursOptions.map((option) => (
              <MenuItem key={option.label} value={option.minuteOffset}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </StyledTabPanel>

      <StyledTabPanel value={currentTab} tabKey={1}>
        <SpecialDateRange currentHourIndex={currentHourIndex} />
      </StyledTabPanel>
    </Box>
  );
};

export default SpecialHoursTabs;
