import { StructuredAddress } from "./AddressType";
import { Base } from "./Base";
import { BaseIncrement } from "./BaseIncrement";
import { ClassifiedEntry } from "./ClassifiedType";
import { Merchandise } from "./MerchandiseType";
import {
  NormalHours,
  SpecialHours as SpecialHoursArray,
} from "./MerchantConfigType";
import {
  MerchantCoupon,
} from "./MerchantCouponType";
import { MerchantDevice } from "./MerchantDeviceType";
import { MerchantFollower } from "./MerchantFollower";
import { MerchantOrderingConfig } from "./MerchantOrderingConfigType";
import { MerchantPromotion } from "./MerchantPromotionType";
import { MerchantStripe } from "./MerchantStripeType";
import { MerchantVoucher } from "./MerchantVoucherType";
import { Review } from "./ReviewType";

export interface SpecialHours {
  // minutes offset from begin of the day, e.g. 480 => 9am
  fromMinOffset: number;
  // e.g. minutes offset from begin of the day
  toMinOffset: number;
  // Monday, Tuesday, ... Sunday
  dayOfWeek: string;
  // mm-dd
  specificDate: string;
}

export interface OperationHours {
  // "default from minutes offset" from begin of the day, e.g. 540 => 9am
  defaultFromMinOffset: number;
  // minutes offset from begin of the day, e.g. 1500 => 1am next day
  defaultToMinOffset: number;
  customizations: SpecialHours[];
}

export enum QRCodeType {
  Social = "social",
  Table = "table",
}

export interface MerchantQRCode {
  id: number;
  type: QRCodeType;
  identifier: string;
  slogan?: string;
  createdAt: Date;
  updatedAt: Date;
  version: number;
}

export enum MerchantInheritanceType {
  MENU = 'menu',
  COUPON = 'coupon',
  REPORT = 'report',
}

export interface ParentMerchant {
  id: string;
  inheritance: MerchantInheritanceType[];
}

export interface MultiStore {
  // Used by dine app to determine whether to show multi-store picker. Should be set to true for
  // both the parent merchant (dummy node) and all the children merchants
  isMultiStore: boolean;
  // Used as the label for the multi-store picker options
  label?: string;
}

export enum MerchantTag {
  Hot = "Hot",
  Deal = "Deal",
  LocalChoice = "Local Choice",
}
export interface MerchantOneLiner {
  description: string;
  tag: MerchantTag;
  displayOrder: number;
}

export interface MerchantRating {
  reviewCount: number;
  overallRating: number; // average
  ratingSum: number;
}

export enum MerchantType {
  products = 'products',
  deprecated = 'product', // temporal value
  services = 'services',
}
export interface Merchant extends Base {
  username: string;
  id: string;
  parentMerchant?: ParentMerchant;
  email: string;
  officialName: string;
  category: string;
  phoneNumber?: string;
  logo?: string;
  heroPhoto?: string;
  address?: string;
  structuredAddress?: StructuredAddress;
  timeZone?: string;
  instagramLink?: string;
  ownerId?: string;
  description?: string;
  production: boolean;
  online: boolean;
  kioskOrdering: boolean;
  hours: OperationHours;
  stripe?: MerchantStripe;
  orderingConfig?: MerchantOrderingConfig;
  device?: MerchantDevice;
  qrCodes?: MerchantQRCode[];
  expenseLevel: number;
  promotions?: MerchantPromotion[];
  coupons?: MerchantCoupon[];
  classifieds?: ClassifiedEntry[];
  rating: MerchantRating;
  reviews: Review[];
  thirdPartyReviewCount: number;
  thirdPartyRating: number;
  ranking: number;
  claimed: boolean;
  normalHours: NormalHours[];
  specialHours: SpecialHoursArray[];
  followers: MerchantFollower[];
  vouchers: MerchantVoucher[];
  type: MerchantType;
  multiStore?: MultiStore;
}

export enum TotalSavingPercentageType {
  SAVING_MERCHANTS = "total_saving_merchants",
  SAVING_CONSUMERS = "total_saving_consumers",
}

export interface MerchantGuideRecommendation extends BaseIncrement {
  title?: string;
  subtitle?: string;
  description?: string;
  merchant: Merchant;
  merchandise: Merchandise;
  displayOrder?: number;
  visible?: boolean;
}

export enum MerchantGuideType {
  oneMarket = "one_market",
  external = "external",
}

export interface MerchantGuide extends BaseIncrement {
  title: string;
  subtitle?: string;
  description?: string;
  heroPhoto?: string;
  recommendations: MerchantGuideRecommendation[];
  type: MerchantGuideType;
  url?: string;
}

export interface UserVoucherEntry extends BaseIncrement {
  voucher: MerchantVoucher;

  orderId: string;

  userId: string;

  phoneNumber: string;

  // expiry date to use the voucher
  expiryDate: Date;
}

export enum PromotionValueType {
  ABSOLUTE_DISCOUNT = "absolute_discount",
  PERCENTAGE_DISCOUNT = "percentage_discount",
  PERCENTAGE_REWARDS = "percentage_rewards",
  ABSOLUTE_REWARDS = "absolute_rewards",
}
