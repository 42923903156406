import { Primitive } from "react-hook-form";

export enum LogLevels {
  "fatal" = "fatal",
  "error" = "error",
  "warning" = "warning",
  "debug" = "debug",
  "info" = "info",
}

export interface LoggingFn {
  (exception: string | Error, data?: { [s: string]: any }, tags?: { [s: string]: string }): void
}

export interface Logger {
  fatal: LoggingFn
  error: LoggingFn
  warning: LoggingFn
  info: LoggingFn
  debug: LoggingFn

  setVersion(version: string): void

  setUserId(userId: number | string | null): void

  setDeviceId(deviceId: string): void

  setUserProperties(userProperties: Record<string, any>): void

  setTag(key: string, value: Primitive): void
}