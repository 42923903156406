import { Merchandise } from "../types/MerchandiseType";
import { MerchantStory } from "../types/MerchantStoryType";
import { Merchant, MerchantQRCode, MerchantType, QRCodeType } from "../types/MerchantType";
import { Order } from "../types/OrderTypes";
import { User } from "../types/UserTypes";
import { isEmptyOrNil } from "../utils";

export const getManageMerchantURL = (withWWW?: boolean): string => {
  const prefix = withWWW ? 'https://www.' : 'https://';
  if (process.env.REACT_APP_IS_WH_STAGING === '1') {
    return `${prefix}staging-wh.manage.1m.app`;
  }
  if (process.env.REACT_APP_IS_STAGING === '1') {
    return `${prefix}staging.manage.1m.app`
  } else if (process.env.NODE_ENV == "production") {
    return `${prefix}manage.1m.app`
  } else {
    return "http://localhost:3000"
  }
}

export const getMarketPlaceURL = (withWWW?: boolean): string => {
  const prefix = withWWW ? 'https://www.' : 'https://';
  if (process.env.REACT_APP_IS_WH_STAGING === '1') {
    return `${prefix}staging-wh.1m.app`;
  }
  if (process.env.REACT_APP_IS_STAGING === '1') {
    return `${prefix}staging.1m.app`
  } else if (process.env.NODE_ENV == "production") {
    return `${prefix}1m.app`
  } else {
    return "http://localhost:3000"
  }
}

export const getMarketPlaceMerchantURL = (merchant: Merchant, user?: User, merchandise?: Merchandise): string => {
  let url = `${getMarketPlaceURL(true)}/${merchant.username}`
  if (merchandise) {
    url = `${url}/item/${merchandise.id}`
  } else if (merchant.type === MerchantType.services) {
    url = `${url}/store`
  }

  return user ? `${url}?suid=${user.id}` : url
}

export const getMerchantStoryPreviewURL = (story: MerchantStory): string => {
  return `${getMarketPlaceURL(true)}/${story.merchant.username}/story/${story.id}?previewStory=true`
}

export const getMerchantAffiliateLink = (merchant: Merchant, user: User, merchandise?: Merchandise, campaign?: string): string => {
  const merchantUrl = getMarketPlaceMerchantURL(merchant, user, merchandise)
  if (isEmptyOrNil(user.affiliateCode)) return merchantUrl
  return `${merchantUrl}&affiliate=${user.affiliateCode}&utm_campaign=${campaign ??
    'affiliate'}&utm_content=${user.affiliateCode}&utm_medium=affiliate&utm_source=1m`
}

export const getReferralUrl = (merchant: Merchant, user: User, couponCode: string, campaign?: string) => {
  const referralUrl =
  `${getMarketPlaceMerchantURL(merchant, user)}&pc=${couponCode}&referral=true&utm_campaign=${campaign ??
    'referral'}&utm_content=${user.id}&utm_medium=referral&utm_source=1m`;

  return referralUrl;
}

export const getMarketPlaceOrderStatusURL = (order: Order): string => {
  return `${getMarketPlaceURL()}/order/${order.id}`
}

export const getMarketPlaceMerchantQRCodeURL = (merchant: Merchant, qrcode: MerchantQRCode) => {
  const searchParmas = new URLSearchParams()
  switch (qrcode.type) {
    case QRCodeType.Table:
      searchParmas.append('tableid', `${qrcode.id}`);
      break;
    case QRCodeType.Social:
    default:
      searchParmas.append('qrid', `${qrcode.id}`);
      break;
  }

  return `${getMarketPlaceURL(true)}/${merchant.username}?${searchParmas.toString()}`
}

export const urlToCampaign = (url: string, campaign: string, content: string, source?: string) =>
  `${url}?utm_campaign=${campaign}&utm_content=${content}&utm_medium=tracking&utm_source=${
    source ?? '1m'
  }`;
