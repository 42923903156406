import React, {useCallback, useEffect, useState} from 'react';
import {createStyles, makeStyles, Chip, Icon, Theme} from '@material-ui/core';
import classNames from 'classnames';
import {rgba} from 'polished';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      fontWeight: 600,
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(-1),
    },
    item: {
      border: '1px solid transparent',
      borderRadius: theme.spacing(2.5),
      fontSize: theme.spacing(1.75),
      height: theme.spacing(5),
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1, .5),
    },
    active: {
      borderColor: rgba(26, 170, 93, 0.7),
      backgroundColor: rgba(26, 170, 93, 0.15),
      color: theme.palette.secondary.main,
    },
  }),
);

type Item<ID = string> = {
  icon?: any;
  id: ID;
  label: React.ReactNode;
};

interface ChipsListProps {
  items: Array<Item>;
  defaultValue?: Array<Item['id']>;
  onChange: (selectedValues: Array<Item['id']>) => void;
  multiple?: boolean;
}

const ChipsList = (props: ChipsListProps) => {
  const {items, defaultValue = [], onChange, multiple = false} = props;
  const classes = useStyles();

  const [selected, setSelected] = useState(defaultValue);

  const onItemClick = useCallback((id: Item['id']) => () => {
    setSelected(selectedList => {
      if (selectedList.includes(id)) {
        return multiple
          ? selectedList.filter(selectedId => selectedId !== id)
          : [];
      } else {
        return multiple
          ? selectedList.concat(id)
          : [id];
      }
    });
  }, []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const getItemClassName = useCallback((id: string) => {
    return classNames(classes.item, {
      [classes.active]: selected.includes(id),
    });
  }, [selected]);

  return (
    <div className={classes.list}>
      {items.map(({id, label, icon}) => (
        <Chip
          key={id}
          className={getItemClassName(id)}
          label={label}
          {...(icon ? {icon: <Icon component={icon} />} : {})}
          onClick={onItemClick(id)}
          clickable
        />
      ))}
    </div>
  );
};

export default ChipsList;
