import React, { useContext, useMemo, useState } from "react";
import useAxios from "axios-hooks";
import { Else, If, Then } from "react-if";
import { ReactSortable } from "react-sortablejs";
import { ascend, clone, map, pick, prop, sortWith } from "ramda";
import { Alert, createStyles, makeStyles, Stack, Theme } from "@material-ui/core";

import { getAuthHeaders, isEmptyOrNil } from "bos_common/src";
import { UserContext } from "bos_common/src/context/UserContext";

import MenuItemCard from "./MenuItemCard";
import { ADD_CLASSES } from "../context/MenuContext/constants";
import { Merchandise, MerchandiseApiResponseType } from "../services/models";
import { useMerchantMenuContext } from "../context/MenuContext/MerchantMenuContext";
import FullScreenLoader from "./common/FullScreenLoader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },

      '& .sortable-chosen': {
        color: '#fff',
        opacity: 0.7,
      },
    },
    narrowRoot: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },

      '& .sortable-chosen': {
        color: '#fff',
        opacity: 0.7,
      },
    },
  })
);

type RenderMerchandiseProps = {
  merchandiseData?: MerchandiseApiResponseType;
  setMerchandiseData?: any;
  merchandises: Merchandise[];
  merchantId: string | undefined;
  narrow?: boolean;
  onEditMerchandise: (merchandise: Merchandise) => void;
};

const RenderMerchandises = (props: RenderMerchandiseProps) => {
  const {
    merchandiseData,
    merchandises = [],
    setMerchandiseData,
    merchantId,
    onEditMerchandise,
    narrow=false
  } = props;

  const [merchandisesArr, setMerchandisesArr] = useState<Merchandise[]>([]);
  const { token } = useContext(UserContext);
  const { dispatch } = useMerchantMenuContext();

  const classes = useStyles();

  // request function
  const [{ loading }, putMerchandises] = useAxios({
    url: `/merchants/merchandises?merchantId=${merchantId}`,
    headers: getAuthHeaders(token),
    method: 'POST'
  }, { manual: true });

  // init sort
  useMemo(() => {
    const sortByProps = sortWith([ascend(prop('displayOrder')), ascend(prop('name'))]);
    setMerchandisesArr(sortByProps(merchandises));
  }, [merchandises]);

  // sortTable sort ent
  const onFinishSorting = (): void => {
    // clone merchandises data
    const merchandisesCopy: Merchandise[] = clone(merchandiseData?.merchandises);

    // reassign the sort field
    const merchandisesSort: Merchandise[] = merchandisesArr.map((item: Merchandise, index) => {
      // delete all fields of this sort
      merchandisesCopy.forEach((ele: Merchandise, idx: number) => {
        if (item.id === ele.id) {
          merchandisesCopy.splice(idx, 1);
        }
      });
      return { ...item, displayOrder: index + 1 };
    });

    // modify origin data
    setMerchandiseData && setMerchandiseData({ ...merchandiseData, merchandises: [...merchandisesSort, ...merchandisesCopy] });

    const sortedData = map((item: Merchandise) => pick(['id', 'displayOrder'], item), merchandisesSort)

    // send modify request
    putMerchandises({
      method: 'PUT',
      data: sortedData,
    });

    if (isEmptyOrNil(setMerchandiseData)) {
      dispatch({
        type: ADD_CLASSES,
        payload: merchandisesSort
      })
    }
  };

  return (
    <If condition={!isEmptyOrNil(merchandises)}>
      <Then>
        <FullScreenLoader loading={loading} />
        <ReactSortable
          className={narrow ? classes.narrowRoot : classes.root}
          ghostClass="sortTableGhost"
          forceFallback
          list={merchandisesArr}
          handle=".dragHandle"
          animation={0}
          setList={setMerchandisesArr}
          onEnd={onFinishSorting}
        >
          {merchandisesArr.map((merchandise: Merchandise) => (
            <MenuItemCard
              merchandise={merchandise}
              onMerchandiseClick={onEditMerchandise}
              showExtraActions
              key={merchandise.id}
            />
          ))}
        </ReactSortable>
      </Then>
      <Else>
        <If condition={!loading}>
          <Stack height="100%" alignItems="center" justifyContent="center">
            <Alert severity="info">No data available</Alert>
          </Stack>
        </If>
      </Else>
    </If>
  );
};

export default RenderMerchandises;
