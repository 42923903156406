import React from 'react'
import { Chip, styled, Theme } from "@material-ui/core"
import { rgba } from "polished"

export const StyledRedChip = styled(Chip)(({ theme }: { theme: Theme }) => ({
  '&.MuiChip-filled': {
    backgroundColor: rgba(theme.palette.primary.main, 0.15),
    boxShadow: 'none',
    '& .MuiChip-label': {
      color: theme.palette.primary.main,
    }
  }
}))