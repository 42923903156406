/**
 * Different device / scenariios the app will live in
 */
export enum DeviceType {
  Regular = "regular",      // running in a web environment
  Kiosk = "kiosk",          // embed web app in an ipad app for kiosk
  KDS = "kds",
  Handheld = "handheld",
  AppEmbed = "app_embed",   // embed web app into a native shell for mobile phones
  WebviewEmbed = "webview_embed",   // embed app web view into a native shell for mobile phones
  OneMApp = "one_m_app",    // one market native app
  ZeroAndApp = 'zero_and_app', // native app deved by zeroand eng team
  Unknown = "Unknown",
}

export const getDeviceType = (): DeviceType => {
  return DeviceType.Regular
}

export const getDeviceLabel = (type: DeviceType): string => {
  switch (type) {
    case DeviceType.Regular:
      return 'Mobile';
    case DeviceType.Kiosk:
      return 'Kiosk';
    case DeviceType.ZeroAndApp:
      return 'Zero& App';
    case DeviceType.AppEmbed:
    case DeviceType.WebviewEmbed:
      return '1m App';
    default:
      return 'Unknown';
  }
}