import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import { MerchandiseModifiersType } from "../../types/MerchandiseType"

const useStyles = makeStyles({
  root: {
    marginTop: 0,
    marginBottom: 0,
    paddingInlineStart: 0,
    listStyleType: 'none',

    '& li': {
      '&:before': {
        content: "'\u{2014}'",
        marginRight: '5px'
      }
    },
  }
})

type MerchandiseModifiersProps = {
  modifiers?: MerchandiseModifiersType,
  modifiersDisplayOrder?: Array<string>,
}
const MerchandiseModifiers = ({ modifiers, modifiersDisplayOrder}: MerchandiseModifiersProps) => {
  const classes = useStyles();
  if (!modifiers)
    return null

  const modifierKeys = Object.keys(modifiers);
  const toDisplayList = (modifiersDisplayOrder?.length === modifierKeys.length) ? modifiersDisplayOrder : modifierKeys;

  return (
    <Typography variant="overline" color="textSecondary" component="div" className="merchandise-modifier" style={{ lineHeight: 1.2, textTransform: 'none' }}>
      <ul className={classes.root}>
        {toDisplayList.map((name) => <li key={name}>{name}</li>)}
      </ul>
    </Typography>
  )
}

export default MerchandiseModifiers