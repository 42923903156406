import { AxiosRequestConfig, AxiosResponse } from "axios";
import axios, { backendAPIURL } from "../bos_common/src/services/backendAxios";
import { MerchandiseApiResponseType } from "../bos_common/src/types/MerchandiseType";

type MerchandiseResponse = AxiosResponse<MerchandiseApiResponseType>;

export const getMerchandises = (merchantUsername: string, request: AxiosRequestConfig): Promise<MerchandiseResponse> => {
  const url = new URL(`${backendAPIURL}/merchants/merchandises`);
  url.searchParams.append('merchantUsername', merchantUsername);
  url.searchParams.append('ignoreHours', "true");
  return axios.get<MerchandiseApiResponseType>(
    url.toString(),
    { headers: request.headers }
  );
}

export default {
  getMerchandises,
}
