import React, { useContext, useEffect, useMemo, useState } from 'react';
import assert from 'assert';
import { filter, pathOr, prop, propOr, reverse, pipe, map, flatten, without } from 'ramda';
import { isSameDay } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime/index.js';

import axios from '../../bos_common/src/services/backendAxios';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { getAuthHeaders } from '../../bos_common/src';

import { AppContext } from "../AppContext";
import { NotificationSeverity } from '../../types/NotificationSlice';
import {
  CancelOrderApiHandlerOptions, MerchantBranchFilterType, MerchantOrdersContext, ORDER_FILTERS,
  ORDER_SEARCH_BAR_FILTERS, SetOrderStatusType
} from "./MerchantOrdersContext";
import { isEmptyOrNil, MappedMerchants, MappedOrders, MappedUsers, userIsOneMOperator } from '../../utils';
import { Merchant, Order, OrderStatus, OrderType } from '../../services/models';
import { filterAndSortOrders, isFutureOrder, isOrderCancelledOrRefunded } from '../../utils/orderUtils';
import { OrderTabs } from '../../constants/orders';

export interface OrderDataMap {
  orders: MappedOrders,
  users: MappedUsers,
  merchants?: MappedMerchants,
};

type MerchantOrderContextProviderProps = {
  children: React.ReactElement | React.ReactElement[],
  updateOrders?: (_: Order[]) => void,
  ordersData?: OrderDataMap,
  loadingOrders?: boolean
  branches?: Merchant[];
}

const defaultFilterState = {
  [OrderTabs.new]: ORDER_FILTERS.all,
  [OrderTabs.completed]: ORDER_FILTERS.all
}

export type OrdersSearchStateType = { value: string | undefined, searchType: ORDER_SEARCH_BAR_FILTERS }

// status list for the merchant staff user role
export const ORDER_STATUS_LIST_FOR_STAFF = {
  [OrderTabs.new]: [OrderStatus.PAID, OrderStatus.CONFIRMED, OrderStatus.OPEN_CHECK],
  [OrderTabs.completed]: [OrderStatus.FULFILLED, OrderStatus.PAID_EXTERNALLY, OrderStatus.VOIDED]
}

// status list for the merchant OneM Operator user role
export const ORDER_STATUS_LIST_FOR_OPERATORS = {
  [OrderTabs.new]: [OrderStatus.PAID],
  [OrderTabs.completed]: [OrderStatus.FULFILLED, OrderStatus.CONFIRMED]
}

const transformMerchantBranchData = (data?: Merchant[]): MerchantBranchFilterType[]|undefined => data && pipe(
  map((i: Merchant) => {
    return { id: i.id, selected: true };
  }),
  flatten,
  without([undefined])
)(data)

export const matchBranchFilter = (merchantId: string, date: Date, filter: MerchantBranchFilterType) =>
  filter.id === merchantId && (!filter.date || isSameDay(date, filter.date));

const MerchantOrdersContextProvider = (props: MerchantOrderContextProviderProps) => {
  const { triggerNotification, merchant } = useContext(AppContext);
  const { token, user } = useContext(UserContext)
  const isUserOneMOperator = userIsOneMOperator(user);

  const {
    children,
    updateOrders = (_: Order[]) => { },
    ordersData,
    loadingOrders = false,
    branches,
  } = props

  const [selectedTab, setSelectedTab] = useState<OrderTabs>(OrderTabs.new);
  const [selectedFilter, _setSelectedFilter] = useState(defaultFilterState);
  const [searchBarState, setSearchBarState] = useState<OrdersSearchStateType>({ value: "", searchType: ORDER_SEARCH_BAR_FILTERS.searchByName });
  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const [merchantBranches, setMerchantBranches] = useState<Merchant[]|undefined>();
  const [selectedBranches, setSelectedBranches] = useState<MerchantBranchFilterType[]|undefined>();

  const orders: MappedOrders = useMemo(() => propOr({}, 'orders', ordersData), [ordersData]);
  const users: MappedUsers = useMemo(() => propOr({}, 'users', ordersData), [ordersData]);
  const merchants: MappedMerchants = useMemo(() => propOr({}, 'merchants', ordersData), [ordersData]);

  const allowedOrderStatuses = [OrderStatus.CONFIRMED, OrderStatus.FULFILLED, OrderStatus.PAID, OrderStatus.OPEN_CHECK, OrderStatus.VOIDED, OrderStatus.PAID_EXTERNALLY]

  useEffect(() => {
    setSelectedBranches(transformMerchantBranchData(branches));
    setMerchantBranches(branches);
  }, [branches])

  useEffect(() => setOrdersList(getFilteredOrdersList(selectedTab, selectedBranches)), [orders, selectedTab, selectedFilter, selectedBranches])

  // update order status endpoint
  const setOrderStatus = async ({ id, nextOrderStatus, onSuccessCallback, staffToken, options }: SetOrderStatusType) => {
    assert(allowedOrderStatuses.includes(nextOrderStatus));
    const searchParams = new URLSearchParams()

    if (!isEmptyOrNil(prop('waitTimeInMinutes', options)))
      searchParams.append('waitTimeInMinutes', prop('waitTimeInMinutes', options))

    return axios.put<Order>(`merchants/orders/${id}?${searchParams.toString()}`,
      {
        id,
        status: nextOrderStatus,
        ...(!isEmptyOrNil(options?.updatedOrderAmount) && { amount: options?.updatedOrderAmount })
      },
      { headers: getAuthHeaders(staffToken ?? token), })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            response.data && updateOrders([response.data]);
            onSuccessCallback && onSuccessCallback()
          }, 100);
        }
        return true;
      })
      .catch((err) => {
        triggerNotification(true, propOr(`Failed to Update Order Status!`, 'message', err), NotificationSeverity.ERROR);
        return false;
      });
  }

  // update order status endpoint
  const cancelOrder = (order: Order, options: CancelOrderApiHandlerOptions) => {
    const { restoreInventory, customRefundAmount, staffToken, onSuccessCallback = () => { } } = options

    // populating the query params
    const searchParams = new URLSearchParams()
    searchParams.append('restoreInventory', `${Boolean(restoreInventory)}`)
    if (!isEmptyOrNil(customRefundAmount))
      searchParams.append('customRefundAmount', customRefundAmount as string)

    axios.post<Order>(`merchants/orders/${order.id}/cancel?${searchParams.toString()}`,
      {
        id: order.id,
        voidOrSkipPaymentInfo: order.voidOrSkipPaymentInfo,
        lineItems: order.lineItems,
        status: order.status,
      },
      { headers: getAuthHeaders(staffToken ?? token) })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            response.data && updateOrders([response.data]);
            onSuccessCallback && onSuccessCallback()
          }, 100);
        }
      })
      .catch((err) => {
        triggerNotification(true, propOr(`Failed to refund order!`, 'message', err), NotificationSeverity.ERROR);
      });
  }

  const getFilteredOrdersList = (selectedTab: OrderTabs, selectedBranches?: MerchantBranchFilterType[]) => {
    const orderArray = Object.values(orders || {});

    const orderStatusList = isUserOneMOperator ? ORDER_STATUS_LIST_FOR_OPERATORS : ORDER_STATUS_LIST_FOR_STAFF

    const ordersListForSelectedTab = selectedTab === OrderTabs.new
      ? filterAndSortOrders({ orders: orderArray, status: orderStatusList[OrderTabs.new] })
      : reverse(filterAndSortOrders({ orders: orderArray, status: orderStatusList[OrderTabs.completed] }))

    const timezone = merchant?.timeZone || 'America/Los_Angeles';
    const ordersForBranches = (!selectedBranches)
      ? ordersListForSelectedTab
      : ordersListForSelectedTab.filter((order: Order) => {
          // convert to local timezone to figure out day boundaries
          const localTime = utcToZonedTime(order.toPickupTime, timezone)
          const match = selectedBranches.find((branch) => ((branch.id === order.merchantId)
            && (!branch.date || isSameDay(branch.date, localTime))
            && branch.selected));
          return !!match;
        });

    switch (selectedFilter[selectedTab]) {
      case ORDER_FILTERS.paid:
        return filter((order: Order) => order.status === OrderStatus.PAID, ordersForBranches)
      case ORDER_FILTERS.unPaid:
        return filter((order: Order) => isEmptyOrNil(order.paymentId), ordersForBranches)
      case ORDER_FILTERS.dineIn:
        return filter((order: Order) => order.type === OrderType.DINEIN, ordersForBranches)
      case ORDER_FILTERS.pickUp:
        return filter((order: Order) => order.type === OrderType.PICKUP, ordersForBranches)
      case ORDER_FILTERS.orderAhead:
        return filter((order: Order) => isFutureOrder(new Date())(order), ordersForBranches)
      case ORDER_FILTERS.openCheck:
        return filter((order: Order) => order.status === OrderStatus.OPEN_CHECK, ordersForBranches)
      case ORDER_FILTERS.refunded:
        return filter((order: Order) => isOrderCancelledOrRefunded(order), ordersForBranches)
      case ORDER_FILTERS.paidExternally:
        return filter((order: Order) => order.status === OrderStatus.PAID_EXTERNALLY, ordersForBranches)
      case ORDER_FILTERS.voided:
        return filter((order: Order) => order.status === OrderStatus.VOIDED, ordersForBranches)
      case ORDER_FILTERS.confirmed:
        return filter((order: Order) => order.status === OrderStatus.CONFIRMED, ordersForBranches)
      case ORDER_FILTERS.all:
      default:
        return ordersForBranches
    }
  }

  const searchOrderItems = (searchText: string, searchType: ORDER_SEARCH_BAR_FILTERS = ORDER_SEARCH_BAR_FILTERS.searchByName) => {
    const filteredOrdersList = getFilteredOrdersList(selectedTab, undefined)

    setSearchBarState({ value: searchText, searchType })

    if (isEmptyOrNil(searchText)) {
      setOrdersList(filteredOrdersList)
    } else {
      let searchedItemsList;

      switch (searchType) {
        case ORDER_SEARCH_BAR_FILTERS.searchByName:
          searchedItemsList = filter((order: Order) => order.userDisplayName?.toLowerCase().includes(searchText.toLowerCase()), filteredOrdersList)
          break;
        case ORDER_SEARCH_BAR_FILTERS.searchByPhone:
          searchedItemsList = filter((order: Order) => {
            const isMatchedSubscriberNumber = order.subscriber?.phoneNumber.includes(searchText)
            const isMatchedUserNumber = pathOr('', [order.userId, 'phoneNumber'], users).includes(searchText)
            return isMatchedSubscriberNumber || isMatchedUserNumber
          }, filteredOrdersList)
          break;
        case ORDER_SEARCH_BAR_FILTERS.searchByAmount:
          searchedItemsList = filter((order: Order) => Number(searchText) == order.amount, filteredOrdersList)
          break;
        case ORDER_SEARCH_BAR_FILTERS.searchByOrderNumber:
          searchedItemsList = filter((order: Order) => order.orderNo?.toString().includes(searchText), filteredOrdersList)
          break;
        default:
          searchedItemsList = filteredOrdersList
      }

      setOrdersList(searchedItemsList)
    }
  }

  const setSelectedFilter = (filterValue: ORDER_FILTERS) => {
    _setSelectedFilter({
      ...selectedFilter,
      [selectedTab]: filterValue
    })
  }

  const merchantOrderContextValue = {
    setOrderStatus,
    cancelOrder,

    updateOrders,

    selectedTab,
    setSelectedTab,

    merchantBranches,
    selectedBranches,
    setSelectedBranches,

    ordersList,

    selectedFilter,
    setSelectedFilter,

    getFilteredOrdersList,

    searchOrderItems,
    searchBarState,

    users,
    merchants,

    isUserOneMOperator,
    loadingOrders
  }

  return (
    <MerchantOrdersContext.Provider value={merchantOrderContextValue}>
      {children}
    </MerchantOrdersContext.Provider>
  )
};

export default MerchantOrdersContextProvider;
