import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { differenceInDays, format, addDays, startOfDay } from 'date-fns';
import { Else, If, Then } from 'react-if';
import { pathOr } from 'ramda';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { ColoredPaper, getAuthHeaders, isEmptyOrNil } from 'bos_common/src'
import { UserContext } from 'bos_common/src/context/UserContext'

import { MerchantStatsContext } from "../../../context/MerchantStats/MerchantStatsContext";
import { getMerchant } from '../../../redux/slice/merchant/merchantSelector'
import { useAppSelector } from '../../../redux/hooks'
import { MerchantStatsPeriod } from '../../../components/Stats/types'
import InfoTooltip from '../../../components/common/InfoTooltip'

interface PayoutReport {
  startingBalance: number;
  activity: number;
  netBalanceWithActivity: number;
  payouts: number;
  endingBalance: number;
}

interface DateRange {
  fromDate: Date;
  toDate: Date;
}

type PayoutReportTableProps = {
  data: PayoutReport | undefined;
  responseDateRange: DateRange | undefined
}

const PayoutReportTable = (props: PayoutReportTableProps) => {
  const { data, responseDateRange } = props;

  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Typography variant='h6' fontWeight="bold" marginBottom={2}>{t('PayoutsInsights_PayoutBalance')}</Typography>

      <Grid container spacing={2}>
        <Grid item xs={9} display="flex" direction="row">
          <Typography variant="body1">{t("PayoutsInsights_StartingBalance")} - {format(responseDateRange?.fromDate || new Date(), 'PPpp')}</Typography>
          <InfoTooltip
            title={"The remaining balance One Market will pay you at the beginning of this selected period"}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <IconButton sx={{p: 0, ml: 1}}>
              <InfoIcon />
            </IconButton>
          </InfoTooltip>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Typography>$ {data?.startingBalance}</Typography>
        </Grid>

        <Grid item xs={9} display="flex" direction="row">
          <Typography variant="body1">{t("PayoutsInsights_AccountActivity")}</Typography>
          <InfoTooltip
            title={"Earnings from the seleced period, including net sales, tip and tax."}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <IconButton sx={{p: 0, ml: 1}}>
              <InfoIcon />
            </IconButton>
          </InfoTooltip>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Typography>$ {data?.activity}</Typography>
        </Grid>

        <Grid item xs={9} display="flex" direction="row">
          <Typography variant="body1">{t("PayoutsInsights_NetBalance")}</Typography>
          <InfoTooltip
            title={"Starting balance plus account activity."}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <IconButton sx={{p: 0, ml: 1}}>
              <InfoIcon />
            </IconButton>
          </InfoTooltip>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Typography>$ {data?.netBalanceWithActivity}</Typography>
        </Grid>

        <Grid item xs={9} display="flex" direction="row">
          <Typography variant="body1">{t("PayoutsInsights_TotalPayouts")}</Typography>
          <InfoTooltip
            title={"Amount paid to you from One Market during the selected period. Note that payout happens on every working day, and the amount depends on the payment settlement with the bank."}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <IconButton sx={{p: 0, ml: 1}}>
              <InfoIcon />
            </IconButton>
          </InfoTooltip>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Typography>$ {data?.payouts}</Typography>
        </Grid>

        <Grid item xs={9} display="flex" direction="row">
          <Typography variant="body1">{t("PayoutsInsights_EndingBalance")} - {format(responseDateRange?.toDate || new Date(), 'PPpp')}</Typography>
          <InfoTooltip
            title={"The remaining balance One Market will pay you at the end of this selected period."}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <IconButton sx={{p: 0, ml: 1}}>
              <InfoIcon />
            </IconButton>
          </InfoTooltip>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Typography>$ {data?.endingBalance}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const PayoutInsights = () => {
  const { t } = useTranslation();
  const { statsDateRange, statsTimePeriod } = useContext(MerchantStatsContext);
  const { token } = useContext(UserContext);
  const [data, setData] = useState<PayoutReport>()
  const [responseDateRange, setResponseDateRange] = useState<DateRange>()

  const merchant = useAppSelector(getMerchant);

  const dateRange = useCallback(() => {
    if (statsTimePeriod === MerchantStatsPeriod.Week || statsTimePeriod === MerchantStatsPeriod.Custom) {
      return {
        fromDate: startOfDay(statsDateRange?.fromDate || new Date()),
        toDate: addDays(statsDateRange?.toDate || new Date(), 1),
      }
    }
    return {
      fromDate: startOfDay(statsDateRange?.fromDate || new Date()),
      toDate: startOfDay(statsDateRange?.toDate || new Date()),
    }
  }, [statsDateRange])

  const searchParams = new URLSearchParams()

  searchParams.append("merchantUsername", merchant?.username ?? "")
  searchParams.append("merchantId", merchant?.id ?? "")
  searchParams.append("statsDateRange", JSON.stringify(dateRange()))
  searchParams.append("statsType", statsTimePeriod)

  const [{ loading }, refetchPayouts] = useAxios(
    {
      url: `/merchants/reports/payout?${searchParams}`,
      method: "get",
      headers: getAuthHeaders(token),
    },
    { useCache: false }
  );

  useEffect(() => {
    refetchPayouts()
      .then((res) => {
        const payoutData = pathOr(undefined, ['data', 'data'], res);
        setData(payoutData);
        setResponseDateRange({
          fromDate: new Date(res.data.startDate),
          toDate: new Date(res.data.endDate),
        });
      })
      .catch(() => {
        setData(undefined);
      })
  }, [statsDateRange])


  const notAvailable = statsDateRange?.toDate && differenceInDays(new Date(), statsDateRange?.toDate) < 2;

  return (
    <ColoredPaper>
      <If condition={loading}>
        <Then>
          <Stack direction="row" justifyContent="center" alignItems="center" p={2} gap={1}>
            <Alert
              severity='info'
              //@ts-ignore
              icon={<CircularProgress sx={{ width: 22, height: 22 }} size="sm" color="info" />}
            >
              {t('PayoutInsight_PendingMessage')}&nbsp;
              {notAvailable ? t('PayoutInsight_NotAvailable') : ''}
            </Alert>
          </Stack>
        </Then>
        <Else>
          <If condition={isEmptyOrNil(data)}>
            <Then>
              <Stack direction="row" justifyContent="center" alignItems="center" p={2} gap={1}>
                <Alert severity='info'>
                  {t('PayoutInsight_NoDataMessage')}&nbsp;
                  {notAvailable ? t('PayoutInsight_NotAvailable') : ''}
                </Alert>
              </Stack>
            </Then>
            <Else>
              <PayoutReportTable responseDateRange={responseDateRange} data={data} />
            </Else>
          </If>
        </Else>
      </If>
    </ColoredPaper>
  )
}

export default PayoutInsights;
