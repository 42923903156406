
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { isEmptyOrNil } from 'bos_common/src';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';

import MerchandiseList from '../../components/MerchandiseList';
import useFetchMerchant from '../../services/useFetchMerchant';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import MerchantPageHeader from '../../components/MerchantPageHeader';
import { getMerchant } from '../../redux/slice/merchant/merchantSelector';
import { fetchMerchandises } from '../../redux/slice/merchandise/merchandiseActions';
import { getMerchandiseLoadingState, getMerchandiseRaw } from '../../redux/slice/merchandise/merchandiseSelector';
import { MerchantType } from '../../services/models';

interface IMerchandisePageParams {
  merchantUsername: string;
}

const ProductMenuPage = () => {

  const history = useHistory();
  const { merchantUsername } = useParams<IMerchandisePageParams>();
  const { t } = useTranslation()
  const reduxDispatch = useAppDispatch();
  const loading = useAppSelector(getMerchandiseLoadingState);
  const mercData = useAppSelector(getMerchandiseRaw);
  const merchantLoading = useFetchMerchant({ merchantUsername });
  const merchant = useAppSelector(getMerchant);

  const isServicesMerchant = merchant?.type === MerchantType.services

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    history.push(`/create`);
  }

  React.useEffect(() => {
    if (!isEmptyOrNil(merchant)) {
      reduxDispatch(fetchMerchandises(merchant?.username));
    }
  }, [merchant?.id])

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={t(isServicesMerchant ? "Services" : "Menu")} hideOnScroll={false} elevation={0} />
      <SimpleLoader loading={loading || merchantLoading} />
      {merchant && mercData && (
        <MerchandiseList
          merchandiseData={mercData}
          merchant={merchant}
        />
      )}
    </div>
  );
}

export default ProductMenuPage;