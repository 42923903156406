import React, { useContext } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Fab,
  Grid,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Delete, Replay, Save } from '@material-ui/icons';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { If } from 'react-if';
import classNames from 'classnames';

import { MerchantStaff, StaffRole, Merchant } from '../services/models';
import { UserContext } from '../bos_common/src/context/UserContext';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { getAuthHeaders, isEmptyOrNil } from '../utils';
import AppDrawer from '../bos_common/src/components/AppDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '40vw',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: 'auto',
      height: `calc(100% - 105px)`,

      '& form': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },

      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '50vw',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        maxWidth: "100%",

        '& .content-container': {
          marginBottom: "10px",
        },

        '& .footer-container': {
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      },

      '& .content-container': {
        '& .MuiFormControl-root': {
          marginTop: theme.spacing(2),
          height: '78px'
        },

        '& input:valid:focus + fieldset': {
          borderLeftWidth: 6,
          padding: '4px !important', // override inline-style
        },

        '& label#role-select-label': {
          background: "#f5faff",
          padding: "0 5px 0 5px"
        },

        '& .addon-container': {
          position: 'relative',

          '& .addon': {
            position: 'absolute',
            right: '0',
          }
        }
      },

      '& .footer-container': {
        padding: theme.spacing(2),

        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: theme.spacing(2),

        '&.fullWidth': {
          gridTemplateColumns: "1fr",
        },
        [theme.breakpoints.down('sm')]: {
          gridColumnGap: theme.spacing(10),
        },
      },

    }
  })
);

interface FormValues extends MerchantStaff { }

type StaffFormProps = {
  staff?: MerchantStaff,
  merchant: Merchant
  onSubmitSuccess: (staff: MerchantStaff) => void,
  staffList?: Array<MerchantStaff>
}
const StaffForm = ({ staff, merchant, onSubmitSuccess, staffList }: StaffFormProps) => {
  let deleteStaff = false
  const classes = useStyles()
  const { token } = useContext(UserContext)
  const { t } = useTranslation();

  const [{ loading }, executeStaffApi] = useAxios<MerchantStaff>(
    { url: '/merchants/staffs' },
    { manual: true }
  );

  const generatePasscode = () => `${Math.floor(1000 + Math.random() * 9000)}`

  const { register, handleSubmit, formState: { errors, isValid, isDirty }, setValue, trigger, watch, getValues } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: staff,
  });

  //@ts-ignore
  const passcode = watch('passcode')

  const registerTextField = (name: keyof MerchantStaff, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  const onStaffSubmit: SubmitHandler<FormValues> = (data) => {
    const method = deleteStaff
      ? 'DELETE'
      : (staff ? 'PUT' : 'POST')
    const headers = getAuthHeaders(token)

    return executeStaffApi({ data, method, headers }).then((response) => {
      if (response.status === 200) {
        onSubmitSuccess(response.data);
      }
    });
  };

  const onDeleteStaff = () => {
    deleteStaff = true
    onStaffSubmit(getValues())
  }

  const disableActions = !isValid || !isDirty

  const GeneratePasscodeBtn = () => {
    const onClick = () => {
      setValue("passcode", generatePasscode(), { shouldDirty: true, shouldValidate: true, shouldTouch: true })
      trigger("passcode")
    }
    return (
      <InputAdornment position="end" >
        <IconButton
          aria-label="toggle password visibility"
          onClick={onClick}
          edge="end"
        >
          <Replay />
        </IconButton>
      </InputAdornment>
    )
  }

  const isPassCodeUnique = (value: number): string | undefined => {
    if (`${value}` === staff?.passcode) return undefined;

    const isPasscodeExisting = staffList?.find((item) => item?.passcode === `${value}`)

    return isPasscodeExisting ? "Passcode must be unique" : undefined
  }

  return (
    <div className={classes.root}>
      <SimpleLoader loading={loading} />

      <form onSubmit={handleSubmit(onStaffSubmit)}>
        <Grid container justifyContent="space-evenly" className="content-container">
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              margin="dense"
              label={t("Name")}
              variant="outlined"
              error={!!errors.displayName}
              helperText={errors.displayName && errors.displayName.message}
              {...registerTextField("displayName", {
                required: t("Staffs_AddAStaff_Name_RequiredText"),
                defaultValue: staff?.displayName
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              margin="dense"
              label={t("Staffs_AddAStaff_Passcode")}
              type="number"
              variant="outlined"
              error={!!errors.passcode}
              helperText={errors.passcode && errors.passcode.message}
              {...registerTextField("passcode", {
                required: t("Staffs_AddAStaff_Passcode_RequiredText"),
                minLength: { value: 4, message: t("Staffs_AddAStaff_Passcode_ValidateMinMax") },
                maxLength: { value: 4, message: t("Staffs_AddAStaff_Passcode_ValidateMinMax") },
                defaultValue: staff?.passcode,
                validate: {
                  isUnique: (value: number) => isPassCodeUnique(value)
                }
              })}
              InputProps={{
                endAdornment: <GeneratePasscodeBtn />
              }}
              InputLabelProps={{
                shrink: !isEmptyOrNil(passcode)
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              error={Boolean(errors.role)}
              fullWidth
              required
            >
              <InputLabel id="role-select-label">{t("Staffs_AddAStaff_Role")}</InputLabel>
              <Select
                labelId='role-select-label'
                defaultValue={staff?.role || StaffRole.SERVER}
                {...registerTextField('role', { required: "Role is required" })}
              >
                {Object.keys(StaffRole).map(key => {
                  const role = StaffRole[key as keyof typeof StaffRole]
                  return <MenuItem key={role} value={role}>{role}</MenuItem>
                })}
              </Select>
              <FormHelperText>
                {errors.role && errors.role.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <input type="hidden" {...register("id")} value={staff?.id} />
        <input type="hidden" {...register("userId")} value={staff?.userId} />
        <input type="hidden" {...register("merchantId")} value={merchant.id} />

        <div className={classNames("footer-container", { "fullWidth": isEmptyOrNil(staff) })}>
          <If condition={!isEmptyOrNil(staff)}>
            <Fab
              variant="extended"
              onClick={onDeleteStaff}
              color="secondary"
              type='button'
            >
              <Delete />
              {t("Delete")}
            </Fab>
          </If>

          <Fab
            variant="extended"
            color="primary"
            type='submit'
            disabled={disableActions}
          >
            <Save />
            {t("Save")}
          </Fab>
        </div>
      </form>
    </div>
  )
}

type StaffEditDialogProps = {
  open: boolean,
  setOpen: (b: boolean) => void,
  staff?: MerchantStaff,
  merchant: Merchant,
  onSubmitSuccess: (staff: MerchantStaff) => void,
  onCancel: () => void,
  staffList?: Array<MerchantStaff>
}
const StaffEditDialog = (props: StaffEditDialogProps) => {
  const { staff, open, setOpen, merchant, onSubmitSuccess, staffList } = props
  const { t } = useTranslation()

  const handleClose = () => {
    props.onCancel()
  };

  const handleSubmit = (updatedStaff: MerchantStaff) => {
    onSubmitSuccess(updatedStaff)
    handleClose();
  }

  const title = staff ? t('Staffs_EditAStaff') : t('Staffs_AddAStaff')

  return (
    <AppDrawer
      anchor='right'
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title={title}
    >
      <If condition={open}>
        <StaffForm
          merchant={merchant}
          staff={staff}
          onSubmitSuccess={handleSubmit}
          staffList={staffList}
        />
      </If>
    </AppDrawer>
  )
}

export default StaffEditDialog