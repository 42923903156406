import {
  Card,
  CardProps,
  Divider,
  Grid,
  IconButton,
  Modal,
  ModalProps,
  ModalTypeMap,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SimpleLoader from '../../bos_common/src/components/SimpleLoader';

type ModalCardProps = ModalProps &
  ModalTypeMap['props'] & {
    cardProps?: CardProps;
    title?: string | JSX.Element;
    loading?: boolean;
    renderHead?: () => JSX.Element;
  };

const ModalCard = ({
  children,
  loading,
  title,
  renderHead,
  cardProps,
  sx,
  ...props
}: ModalCardProps) => {
  return (
    <Modal
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Card
        sx={{
          // height: { xs: '50vh', sm: 'auto' },
          maxHeight: '95vh',
          maxWidth: '95%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          ...cardProps?.sx,
        }}
      >
        <Grid
          container
          sx={{ minHeight: 55, pl: 2, pr: 2 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {renderHead?.() || (
            <>
              <Grid item xs={2} />
              <Grid
                item
                xs={8}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography variant="h6" noWrap>
                  {title}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={() => {
                // @ts-ignore
                props.onClose?.();
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />
        <SimpleLoader loading={loading!} />
        {children}
      </Card>
    </Modal>
  );
};

export default ModalCard;
