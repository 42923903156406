import React from 'react';
import { Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

type SectionListData = {
  label: string,
  action: (item?: any) => void
  item?: any
}
type SectionListProps = {
  dataList: Array<SectionListData>,
  title: string
}
const SectionList = (props: SectionListProps) => {
  const { title, dataList } = props

  return (
    <CardContent>
      <Typography gutterBottom variant="h6" component="h2">
        {title}
      </Typography>

      <Grid container spacing={2}>
        {
          dataList.map((data: SectionListData, idx: number) => {
            return (
              <Grid key={`${data.label}-${idx}`} item xs={12}>
                <Card elevation={3}>
                  <CardActionArea onClick={() => data.action(data.item)}>
                    <CardHeader
                      title={
                        <Typography variant="body1" component="div">
                          {data.label}
                        </Typography>
                      }
                      action={
                        <IconButton style={{ padding: 0 }}>
                          <ChevronRight />
                        </IconButton>
                      }
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </CardContent>

  )
}

export default SectionList