import { AppBar, Box, Button, createStyles, makeStyles, Stack, Theme } from '@material-ui/core';
import { FileDownload, MonetizationOn } from '@material-ui/icons';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router';
// @ts-ignore
import { Parser } from 'json2csv';

import { MerchantType } from 'bos_common/src/types/MerchantType';
import { FullscreenPaper } from 'bos_common/src';


import { AppContext } from '../../context/AppContext';
import { MerchantStatsContext } from '../../context/MerchantStats/MerchantStatsContext';

import { NotificationSeverity } from '../../types/NotificationSlice';
import MenuListCard from '../../components/common/MenuListCard';
import MerchantPageHeader from '../../components/MerchantPageHeader';
import MerchantSelectorCTA from '../../components/MerchantSelectorCTA';
import StatsRangeSelector from '../../components/Stats/StatsRangeSelector';
import useFetchMerchant from '../../services/useFetchMerchant';
import CustomerInsights from './components/CustomerInsights';
import MerchantSales from './components/MerchantSales';
import OperationInsights from './components/OperationInsights';
import EmployeeInsights from './components/EmployeeInsights';
import PayoutInsights from './components/PayoutInsights';

import EmployeeInsightsIcon from '../../assets/icons/EmployeeInsightsIcon';
import CustomerInsightsIcon from '../../assets/icons/CustomerInsightsIcon';
import OperationsIcon from '../../assets/icons/OperationsIcon';
import SalesIcon from '../../assets/icons/SalesIcon';
import TwoColumnLayout from '../../components/common/TwoColumnLayout';

interface IMerchantStatsPage {
  merchantUsername: string;
}

const DownloadReport = (props: { setShowAlert: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const location = useLocation();
  const { merchant } = useContext(AppContext);
  const { report, statsTimePeriod, statsDateRange } = useContext(MerchantStatsContext);
  const show = /reports\/sales/.test(location.pathname);

  if (!show || !merchant || !statsDateRange?.fromDate || !statsDateRange?.toDate) {
    return null;
  }

  const formatDate = (time: Date) =>
    format(utcToZonedTime(time, merchant.timeZone || 'America/Los_Angeles'), 'yyyyMMdd');

  const onDownLoad = () => {
    if (!report.render) {
      props.setShowAlert(true);
      return;
    }
    const reportData = report.render();
    try {
      const renderCsv = (json?: Record<string, any>) => {
        const json2csvParser = new Parser({
          delimiter: ',',
          quote: '',
        });
        return json2csvParser.parse(json);
      };
      const getReportName = (type: string) => `${merchant?.officialName}_${formatDate(statsDateRange?.fromDate)}${statsTimePeriod === 'day'
        ? '' : `_${formatDate(statsDateRange?.toDate)}`}${type ? `_${type}` : ''}.csv`;

      const downloadCsv = (value: string, type: string = '') => {
        const aLink = document.createElement('a');
        const blob = new Blob([value], { type: 'text/plain' });
        aLink.download = getReportName(type);
        aLink.href = URL.createObjectURL(blob);
        aLink.click();
        // @ts-ignore
        URL.revokeObjectURL(blob);
      };
      const reportDetailsCsv = renderCsv(reportData.reportDetails?.table);
      downloadCsv(`${reportData.reportDetails?.titleRow.join()}\n${reportDetailsCsv}`, 'chart');
      const reportCsv = renderCsv(reportData.report);
      setTimeout(() => {
        downloadCsv(reportCsv);
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          width: 36,
          pl: 0,
          pr: 0,
          minWidth: 0,
        }}
        onClick={onDownLoad}
      >
        <FileDownload />
      </Button>
    </>
  );
};

const MerchantReports = () => {
  const { t } = useTranslation();
  const pageTitle = t('MerchantService_Reports');

  const { merchant, triggerNotification } = useContext(AppContext);
  const { merchantUsername } = useParams<IMerchantStatsPage>();
  useFetchMerchant({ merchantUsername });

  const isServicesMerchant = merchant?.type === MerchantType.services

  const menuItems = [
    ...(isServicesMerchant ?
      [
        {
          path: `/${merchantUsername}/reports/employee-insights`,
          label: t('EmployeeInsights'),
          icon: <EmployeeInsightsIcon />,
        },
        {
          path: `/${merchantUsername}/reports/payouts`,
          label: t('PayoutsInsights'),
          icon: <MonetizationOn color="primary" sx={{width: 21}} />,
        }
      ] : [
        {
          path: `/${merchantUsername}/reports/sales`,
          label: t('Sales'),
          icon: <SalesIcon />,
          action: (
            <DownloadReport
              setShowAlert={() =>
                triggerNotification(
                  true,
                  'No sales data to export on this date',
                  NotificationSeverity.ERROR
                )
              }
            />
          ),
        },
        {
          path: `/${merchantUsername}/reports/customer-insights`,
          label: t('CustomerInsights'),
          icon: <CustomerInsightsIcon />,
        },
        {
          path: `/${merchantUsername}/reports/operational-insights`,
          label: t('OperationalInsights'),
          icon: <OperationsIcon />,
        },
        {
          path: `/${merchantUsername}/reports/payouts`,
          label: t('PayoutsInsights'),
          icon: <MonetizationOn color="primary" sx={{ width: 21 }} />,
        }
      ]
    ),
  ];

  return (
    <div className="container">
      <MerchantPageHeader
        title={pageTitle}
        hideOnScroll={false}
        elevation={0}
        leftChild={<MerchantSelectorCTA />}
      />
      <AppBar position="sticky" color="inherit">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <StatsRangeSelector />
        </Box>
      </AppBar>

      <TwoColumnLayout
        leftSide={<MenuListCard title={t('Management')} menuItems={menuItems} />}
      >
        <Switch>
          <Redirect
            exact
            from="/:merchantUsername/reports"
            to={`/:merchantUsername/reports/${isServicesMerchant ? 'employee-insights' : 'sales'}`}
          />
          <Route exact path="/:merchantUsername/reports/sales" component={MerchantSales} />
          <Route
            exact
            path="/:merchantUsername/reports/customer-insights"
            component={CustomerInsights}
          />
          <Route
            exact
            path="/:merchantUsername/reports/employee-insights"
            component={EmployeeInsights}
          />
          <Route
            exact
            path="/:merchantUsername/reports/operational-insights"
            component={OperationInsights}
          />
          <Route
            exact
            path="/:merchantUsername/reports/payouts"
            component={PayoutInsights}
          />
        </Switch>
      </TwoColumnLayout>
    </div>
  );
};

export default MerchantReports;
