import React, { useContext, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import axios from 'axios';
import { useForm, SubmitHandler, FormProvider, useFormContext } from 'react-hook-form';
import {
  TextField,
  CardContent,
  LinearProgress,
  Fab,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import { propOr } from 'ramda';
import { useTranslation } from 'react-i18next';
// src
import { UserContext } from '../bos_common/src/context/UserContext';
import { MultiFabContainer } from '../bos_common/src/components/FabContainers';
import { getAuthHeaders } from '../bos_common/src';
import BusinessHoursDetails from "../components/BusinessHours/BusinessHoursDetails";
import { Merchant, User } from '../services/models';
import AppToastMessage from './common/AppToastMessage';
import MerchantPreviewButton from './MerchantPreviewButton';
import { getHourRanges, hasSpecialChars, HourOption, isValidURL, uploadThumbnail } from '../utils';
import ImageUploadAvatar from '../bos_common/src/components/ImageUploadAvatar';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';

interface FormValues extends Merchant { }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profilePhoto: {
      display: "flex",
      justifyContent: 'center',
      position: 'relative',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      "& .heroPhotoWrapper": {
        width: "100%",
        position: "relative",
        "& .heroPhoto": {
          width: "100%",
          minHeight: '200px',
          maxHeight: '250px',
          border: "1px solid",
          objectFit: "cover",
          borderColor: theme.palette.grey[400],
          borderRadius: theme.spacing(1),
        },
        "& .heroPhotoUpload": {
          position: 'absolute',
          bottom: theme.spacing(1.5),
          right: theme.spacing(1.5),
          '& .MuiButtonBase-root': {
            padding: 0,
            fontSize: '12px',
          }
        },
      },
      "& .logoSection": {
        position: 'absolute',
        bottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
        '& .logo': {
          width: theme.spacing(12),
          height: theme.spacing(12),
        },
      },
    },
    root: {
      '& .MuiFormControl-root': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      '& .MuiInputLabel-root': {
        backgroundColor: 'rgb(245, 250, 255)'
      },
      '& .MuiButtonBase-root': {
        '& a': {
          textDecoration: 'none',
        }
      },
    },
  })
);

type RenderMerchantProfileFormProps = {
  merchant?: Merchant,
  user?: User,
  onProfileSubmit: SubmitHandler<FormValues>
}
const RenderMerchantProfileForm = (props: RenderMerchantProfileFormProps) => {
  const classes = useStyles();
  const { merchant, user, onProfileSubmit } = props;

  const { handleSubmit, register, watch, formState, trigger, setValue } = useFormContext()
  const { errors, isSubmitting } = formState

  const [hoursOptions, setHoursOptions] = useState<Array<HourOption>>([]);
  const [logoSrc, setLogoSrc] = useState<string>(merchant?.logo || "");
  const [heroSrc, setHeroSrc] = useState<string>(merchant?.heroPhoto || "");
  const [loading, setLoading] = useState<boolean>(false);

  const [, executeGetUploadUrl] = useAxios(
    { url: '/upload-url', method: 'POST' },
    { manual: true }
  );

  const { t } = useTranslation();

  // profile form handlers
  const ownerID = merchant ? merchant.ownerId : user?.id

  useEffect(() => {
    setHoursOptions(getHourRanges())
  }, [])

  const registerTextField = (name: string, options?: any) => {
    // @ts-ignore
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  const storeOpeningTime = watch('hours.defaultFromMinOffset')
  const storeClosingTime = watch('hours.defaultToMinOffset')

  useEffect(() => {
    trigger(['hours.defaultFromMinOffset', 'hours.defaultToMinOffset'])
  }, [storeOpeningTime, storeClosingTime])

  const updateLogo = (src: string) => {
    setLogoSrc(src);
    setValue('logo', src, { shouldDirty: true });
  }

  const updateHero = (src: string) => {
    setHeroSrc(src);
    setValue('heroPhoto', src, { shouldDirty: true });
  }

  const onPhotoSelect = (files: any, photoType: string) => {
    setLoading(true);
    executeGetUploadUrl()
      .then(async (response) => {
        if (response.status === 200) {
          const f0 = files[0];
          const { url, uploadUrl } = response.data;
          axios.request<{ url: string }>({
            method: 'put',
            url: uploadUrl,
            data: f0,
            headers: { 'content-type': f0.type },
          })
            .then(() => {
              return uploadThumbnail(url, f0)
            })
            .then(() => {

              if (photoType === "Hero") {
                updateHero(url);
              }
              else {
                updateLogo(url);
              }

              setLoading(false);
            })
            .catch(_ => setLoading(false));
        }
      });
  }

  return (
    <>
      <SimpleLoader loading={loading} />
      <CardContent className={classes.root}>
        <ImageUploadAvatar
          logoSrc={logoSrc}
          heroSrc={heroSrc}
          showHeroCover
          onPhotoSelect={onPhotoSelect}
        />
        <form onSubmit={handleSubmit(onProfileSubmit)}>
          <Grid container justifyContent="space-evenly">
            <Grid item sm={10} md={5}>
              <TextField
                fullWidth
                required
                label={t("OfficialName")}
                helperText={t("StoreInfo_OfficialName_HelperText")}
                variant="outlined"
                defaultValue={merchant?.officialName}
                {...registerTextField("officialName", { required: t("StoreInfo_OfficialName_RequiredText") })}
              />
              <TextField
                fullWidth
                required
                label={t("AccountName")}
                variant="outlined"
                error={!!errors.username}
                defaultValue={merchant?.username}
                InputProps={{
                  startAdornment: <InputAdornment position="start">1m.app/</InputAdornment>,
                }}
                helperText={
                  errors.username
                    ? errors.username.message
                    : t("StoreInfo_AccountName_HelperText")
                }
                {...registerTextField("username", {
                  required: t("StoreInfo_AccountName_RequiredText"),
                  validate: {
                    isValidUsername: (value: string) => hasSpecialChars(value) ? t("StoreInfo_AccountName_ValidateText") : undefined
                  }
                })}
              />
              <TextField
                fullWidth
                label={t("Category")}
                variant="outlined"
                error={!!errors.category}
                helperText={
                  errors.category
                    ? errors.category.message
                    : t("StoreInfo_Category_HelperText")
                }
                defaultValue={merchant?.category || ''}
                {...registerTextField("category", {
                  validate: {
                    maxLength: (value: string) => value && value.length > 30 ? t("StoreInfo_Category_ValidateText") : undefined
                  }
                })}
              />
              <TextField
                fullWidth
                label={t("Email")}
                type="email"
                variant="outlined"
                error={!!errors.email}
                helperText={
                  errors.email
                    ? errors.email.message
                    : t("StoreInfo_Email_HelperText")
                }
                defaultValue={merchant?.email || ''}
                {...registerTextField("email", { required: t("StoreInfo_Email_RequiredText") })}
              />
              <TextField
                fullWidth
                label={t("StoreInfo_BusinessPhoneNumber")}
                variant="outlined"
                defaultValue={merchant?.phoneNumber || ''}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber
                    ? errors.phoneNumber.message
                    : t("StoreInfo_BusinessPhoneNumber_HelperText")
                }
                {...registerTextField("phoneNumber")}
              />
              <TextField
                fullWidth
                label="Instagram"
                variant="outlined"
                error={!!errors.instagramLink}
                helperText={errors?.instagramLink?.message}
                defaultValue={merchant?.instagramLink || ''}
                {...registerTextField("instagramLink", {
                  validate: {
                    isAUrl: (value: string) => value && !isValidURL(value) ? t("StoreInfo_Instagram_ValidateText") : undefined
                  }
                })}
              />
            </Grid>

            <Grid item sm={10} md={5}>
              <TextField
                fullWidth
                required
                label={t("Address")}
                variant="outlined"
                error={!!errors.address}
                defaultValue={merchant?.address}
                {...registerTextField("address", { required: t("StoreInfo_Address_RequiredText") })}
              />

              <TextField
                fullWidth
                multiline
                label={t("Description")}
                variant="outlined"
                defaultValue={merchant?.description}
                helperText={t("StoreInfo_Description_HelperText")}
                {...registerTextField("description")}
              />
              <BusinessHoursDetails />
            </Grid>
          </Grid>

          <input type="hidden" {...register("timeZone")} value={merchant?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone} />
          <input type="hidden" {...register("id")} value={merchant?.id} />
          <input type="hidden" {...register("ownerId")} value={ownerID} />
          <input type="hidden" {...register("logo")} value={logoSrc} />
          <input type="hidden" {...register("heroPhoto")} value={heroSrc} />
          <MultiFabContainer fabStyle={{justifyContent: 'flex-end'}}>
            <MerchantPreviewButton merchant={merchant} />
            <Fab type="submit" variant="extended" color="primary" disabled={isSubmitting}>
              <Save />
              {t("Save")}
            </Fab>
          </MultiFabContainer>
        </form>
      </CardContent>
    </>
  )
}

type MerchantProfileProps = {
  merchant?: Merchant;
  onUpdatedMerchant?: (merchant: Merchant) => void;
}
export default function MerchantProfile(props: MerchantProfileProps) {
  const { user, token } = useContext(UserContext);

  const { merchant, onUpdatedMerchant = () => { } } = props
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const merchantMethod = merchant ? 'PUT' : 'POST'
  const [{ error: updateProfileError }, executeProfilePut] = useAxios(
    {
      url: '/merchants',
      method: merchantMethod,
      headers: getAuthHeaders(token)
    },
    { manual: true }
  );

  const onProfileSubmit: SubmitHandler<FormValues> = (formValues) => {
    const data = {
      ...formValues,
      normalHours: propOr([], 'normalHours', merchant),
      specialHours: propOr([], 'specialHours', merchant),
    }

    executeProfilePut({ data })
      .then((response) => {
        setShowAlert(true)
        if (response.status === 200) {
          if (response.data) {
            onUpdatedMerchant(response.data);
          }
        }
      }).catch((err) => {
        setShowAlert(true)
      })
  }

  const formMethods = useForm<Merchant>({
    mode: 'onBlur',
    defaultValues: {},
  });

  const { formState } = formMethods

  return (
    <FormProvider {...formMethods}>
      {formState.isSubmitting && <LinearProgress />}

      <AppToastMessage
        showAlert={showAlert}
        onCloseAlert={setShowAlert}
        error={Boolean(updateProfileError)}
      />

      {/* @ts-ignore */}
      <RenderMerchantProfileForm
        merchant={merchant}
        user={user}
        onProfileSubmit={onProfileSubmit}
      />
    </FormProvider>
  );
}
