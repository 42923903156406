import React, { useContext } from 'react';
import { Alert, Box } from '@material-ui/core';

import { PlatformChatContext } from './context/PlatformChatContext';
import { isEmptyOrNil } from '../../utils';
import { rgba } from 'polished';

const ChatErrorMessage = () => {
  const { errorMessage } = useContext(PlatformChatContext);

  if (isEmptyOrNil(errorMessage)) return null;

  return (
    <Box sx={{ height: '100vh', width: '100vw', background: rgba(0, 0, 0, 0.7), display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Alert severity="info">
        {errorMessage}
      </Alert>
    </Box>
  )
}

export default ChatErrorMessage;