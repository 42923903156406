import React from 'react';
import { makeStyles, Theme, createStyles, CardHeader, Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '200px',
      flexGrow: 1,

      '& .logo': {
        width: '100px',
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
        borderRadius: "21px",

        [theme.breakpoints.down('sm')]: {
        borderRadius: theme.spacing(2),
        width: theme.spacing(6.5),
        }
      },

      '& .logoTitle': {
        [theme.breakpoints.down('sm')]: {
          fontSize: "21px",
        }
      },

      '& .logoSubTitle': {
        [theme.breakpoints.down('sm')]: {
          fontSize: "16px",
        }
      },
    },
  })
);

const MerchantAppLogo = () => {
  const classes = useStyles()
  const logoPath = `${process.env.PUBLIC_URL}/logo.svg`

  const merchantLogoClasses = classNames([classes.root, 'merchant-logo-container'])

  return (
    <div className={merchantLogoClasses}>
      <img src={logoPath} alt="One Market Merchant" className="logo" />

      <CardHeader
        title={
          <Typography variant="h4" component="div" color='primary' className="logoTitle" sx={{ textAlign: 'left' }}>
            One Market
          </Typography>
        }
        subheader={
          <Typography variant="h6" component="div" className="logoSubTitle" sx={{ textAlign: 'left' }}>
            Merchant
          </Typography>
        }
      />
    </div>
  )
}

export default MerchantAppLogo;