import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";

import { Merchant } from "../services/models";
import { AppContext } from "../context/AppContext";

import { UserContext } from "../bos_common/src/context/UserContext";
import { getAuthHeaders } from "../bos_common/src";

import AppSwitch from "./common/AppSwitch";

const MerchantOnlineOrderingSwitch = (): React.ReactElement | null => {
  const { token } = useContext(UserContext)
  const { merchant, setMerchant } = useContext(AppContext)
  const { t } = useTranslation();

  const [checked, setChecked] = useState<boolean>(merchant?.online || false)
  const [{ loading }, executeMerchantSwitch] = useAxios<Merchant>(
    { url: '/merchants', method: 'PUT' },
    { manual: true }
  );

  if (!merchant) return null

  const onChangeHandler = (isChecked: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => {
    event.stopPropagation()
    setChecked(isChecked)

    // send to backend
    executeMerchantSwitch({
      data: {
        id: merchant.id,
        online: isChecked,
      },
      headers: getAuthHeaders(token),
    }).then((response) => {
      if (response.status === 200) {
        const updatedMerchant = response.data as Merchant | undefined
        if (updatedMerchant) {
          setMerchant(updatedMerchant)
        }
      }
    }).catch((err) => {
      setChecked(!isChecked) // revert update
    })
  }

  return (
    <AppSwitch
      label={t("OnlineOrdering")}
      checked={checked}
      disabled={loading}
      onChangeHandler={onChangeHandler}
    />
  )
}

export default MerchantOnlineOrderingSwitch;