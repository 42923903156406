import React from 'react';
import {
  Card,
  makeStyles,
  Theme,
  createStyles,
  CardActionArea,
  Typography,
  Box,
  Stack,
} from '@material-ui/core';
import renderPrice from '../bos_common/src/components/Price';
import TypographyEllipsis from '../bos_common/src/components/TypographyEllipsis';
import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MenuCardContainer: {
      height: '100%',
      
      '& .details': {
        flexGrow: 1,
        height: '100%',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        overflow: 'hidden',

        '& .row': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',

          '& .MuiButtonBase-root': {
            margin: theme.spacing(1),
          },
        },
      },
    },

  })
);


type MenuPackageItemCardProps = {
  merchantPackage: MerchantPackage;
  onMerchandiseClick: (merchantPackage: MerchantPackage) => void;
  showExtraActions?: boolean;
};

const MenuPackageItemCard = (props: MenuPackageItemCardProps) => {
  const classes = useStyles();
  const { merchantPackage, onMerchandiseClick, showExtraActions = false } = props;

  const onMerchandiseItemClick = () => {
    onMerchandiseClick(merchantPackage)
  }

  return (
    <Card className={classes.MenuCardContainer} elevation={3}>
      <div style={{ display: 'flex', height: '100%' }}>
        <Stack className='details' flexDirection="column" justifyContent="space-between">
          <CardActionArea onClick={onMerchandiseItemClick}>
            <Box >
              <TypographyEllipsis>{merchantPackage.name}</TypographyEllipsis>
            </Box>
          </CardActionArea>
          <div className="row">
            <Typography variant="subtitle1" color="textSecondary" component="div">
              {renderPrice(merchantPackage.price)}
            </Typography>
          </div>
        </Stack>
      </div>
    </Card>
  );
}

export default MenuPackageItemCard;