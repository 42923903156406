import React, { useContext } from 'react'
import { makeStyles, Tab, Tabs, createStyles, Theme } from '@material-ui/core'
import { Check, HourglassEmpty } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

// src
import { a11yTabProps } from '../../utils';
import { OrderTabs } from '../../constants/orders';
import { MerchantOrdersContext, ORDER_FILTERS } from '../../context/MerchantOrders/MerchantOrdersContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1 1 auto',
      // marginLeft: theme.spacing(2),
      '& .MuiTabs-flexContainer': {
        '& .MuiButtonBase-root': {
          boxShadow: `0px 0px 3px 3px rgba(0,0,0, 0.05)`,
          margin: theme.spacing(1),
          borderRadius: 5,
          minWidth: '140px',

          '& .label-with-icon': {
            display: "flex",
            alignItems: 'center',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: '400',
          },

          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            borderBottom: 0,
            boxShadow: `0px 0px 3px 2px rgba(0,0,0, 0.1)`,
          },
        }
      },
      '& .MuiTabs-scrollableX': {
        alignItems: 'center',
        display: 'flex',
        '& .MuiTabs-indicator': {
          display: 'none'
        },
      },
    },
  }),
);

function OrderTabButtons() {
  const classes = useStyles()
  const { selectedTab, setSelectedTab, setSelectedFilter, getFilteredOrdersList, searchOrderItems, isUserOneMOperator } = useContext(MerchantOrdersContext)
  const { t } = useTranslation();

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
    setSelectedFilter(ORDER_FILTERS.all);
    searchOrderItems('');
  };

  const orderCounts = {
    new: getFilteredOrdersList(OrderTabs.new).length,
    completed: getFilteredOrdersList(OrderTabs.completed).length
  }

  const StaffLabels = {
    new: t("New"),
    completed: t("Completed")
  }

  const OperatorLabels = {
    new: t("New"),
    completed: t("Confirmed")
  }

  const tabLabels = isUserOneMOperator ? OperatorLabels : StaffLabels

  return (
    <Tabs
      variant="scrollable"
      value={selectedTab}
      onChange={onTabChange}
      aria-label="Orders Tab"
      className={classes.root}
    >
      <Tab
        value={OrderTabs.new}
        label={<span className="label-with-icon"><HourglassEmpty />&nbsp;{tabLabels.new}&nbsp;({orderCounts.new})</span>}
        {...a11yTabProps(OrderTabs.new)}
      />
      <Tab
        value={OrderTabs.completed}
        label={<span className="label-with-icon"><Check />&nbsp;{tabLabels.completed}&nbsp;({orderCounts.completed})</span>}
        {...a11yTabProps(OrderTabs.completed)}
      />
    </Tabs>
  )
}

export default OrderTabButtons
