import React, { useContext, useMemo, useState } from 'react'
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardHeader, Grid, } from '@material-ui/core';

// src
import { MerchantStatsContext } from '../../../context/MerchantStats/MerchantStatsContext';
import { AppContext } from '../../../context/AppContext';
import BoxWithClick from '../../../components/common/BoxWithClick';
import { ColoredPaper } from '../../../bos_common/src';
import SimpleLoader from '../../../bos_common/src/components/SimpleLoader';
import MerchantStatsChart from '../../../components/Stats/MerchantStatsChart';
import MerchantStatsCard from '../../../components/Stats/MerchantStatsCard';
import {
  extractAverageWaitMinutes, extractMaxWaitMinutesAverage, extractTotalOrdersFromStats, getComparisonStats, transformMinutesToHumanFormat,
} from '../../../utils/merchantStatsUtils';
import { getStatsDateRangeLabel } from '../../../components/Stats/chartUtils';
import useStatsApiService from '../statsApiService';

type ChartType = 'avgWaitTime' | 'maxWaitTime'

const OperationInsights = () => {
  const { merchant } = useContext(AppContext);
  const [selectedChart, setSelectedChart] = useState<ChartType>('avgWaitTime');

  const {
    isLoading,
    currentStatsData,
    comparisonStatsData
  } = useStatsApiService('/merchants/operation-stats')

  const { statsTimePeriod } = useContext(MerchantStatsContext);
  const { t } = useTranslation();

  const highestWaitMinutes = useMemo(() => extractMaxWaitMinutesAverage(currentStatsData?.stats), [currentStatsData]);
  const totalAverageMinutes = useMemo(() => extractAverageWaitMinutes(currentStatsData?.stats), [currentStatsData]);
  const highestWaitTimeString = useMemo(() => transformMinutesToHumanFormat(highestWaitMinutes), [highestWaitMinutes]);
  const totalAverageTimeString = useMemo(() => transformMinutesToHumanFormat(totalAverageMinutes), [totalAverageMinutes]);
  const comparisonHighestWaitMinutes = useMemo(() => extractMaxWaitMinutesAverage(comparisonStatsData?.stats), [comparisonStatsData]);
  const comparisonTotalAverageMinutes = useMemo(() => extractAverageWaitMinutes(comparisonStatsData?.stats), [comparisonStatsData]);
  const totalOrdersCount = useMemo(() => extractTotalOrdersFromStats(currentStatsData?.stats), [currentStatsData]);

  const dateLabel = getStatsDateRangeLabel(statsTimePeriod,
    {
      toDate: currentStatsData?.toDate ?? new Date(),
      fromDate: currentStatsData?.fromDate ?? new Date()
    }, 'MM/dd/yy');

  const CardFooterNote = `${t('BasedOn')} ${totalOrdersCount} ${t('Orders')}`

  return (
    <ColoredPaper>
      <SimpleLoader loading={isLoading} />
      <Box className="dashboard-container" p={2}>
        <If condition={!currentStatsData}>
          <Then>{!isLoading && <CardHeader subheader="no customers insights available" />}</Then>
          <Else>
            <div className="sections-container">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BoxWithClick isSelected={selectedChart === 'avgWaitTime'} handleClick={() => setSelectedChart('avgWaitTime')}>
                    <MerchantStatsCard
                      headTitle={t('OperationalInsight_MaxWaitTime')}
                      headValue={highestWaitTimeString || 'N/A'}
                      footer={CardFooterNote}
                      comparisonData={
                        getComparisonStats(
                          highestWaitMinutes,
                          comparisonHighestWaitMinutes,
                          statsTimePeriod,
                          true
                        )
                      }
                    />
                  </BoxWithClick>
                </Grid>
                <Grid item xs={6}>
                  <BoxWithClick isSelected={selectedChart === 'maxWaitTime'} handleClick={() => setSelectedChart('maxWaitTime')}>
                    <MerchantStatsCard
                      headTitle={t('OperationalInsight_AverageWaitTime')}
                      headValue={totalAverageTimeString || 'N/A'}
                      footer={CardFooterNote}
                      comparisonData={
                        getComparisonStats(
                          totalAverageMinutes,
                          comparisonTotalAverageMinutes,
                          statsTimePeriod,
                          true
                        )
                      }
                    />
                  </BoxWithClick>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={3}>
                    <If condition={selectedChart === 'avgWaitTime'}>
                      <Then>
                        <MerchantStatsChart
                          chartLabel={t('OperationalInsight_MaxWaitTime')}
                          chartTitle={dateLabel}
                          chartDescription={CardFooterNote}
                          lastWeekSalesData={comparisonStatsData}
                          currentWeekSalesData={currentStatsData}
                          timeZone={merchant?.timeZone}
                          dataKey="maxWaitTime"
                        />
                      </Then>
                      <Else>
                        <MerchantStatsChart
                          chartLabel={t('OperationalInsight_AverageWaitTime')}
                          chartTitle={dateLabel}
                          chartDescription={CardFooterNote}
                          lastWeekSalesData={comparisonStatsData}
                          currentWeekSalesData={currentStatsData}
                          timeZone={merchant?.timeZone}
                          dataKey="averageWaitTime"
                        />
                      </Else>
                    </If>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Else>
        </If>
      </Box>
    </ColoredPaper>
  );
};

export default OperationInsights
