import React, { useContext } from 'react';
import DocumentMeta from 'react-document-meta';

import { AppContext } from '../../context/AppContext';

type DocumentMetaTagsProps = {
  children: React.ReactElement
}
const DocumentMetaTags = ({ children }: DocumentMetaTagsProps) => {
  const { merchant } = useContext(AppContext);

  const meta = {
    title: `Management - ${merchant ? `${merchant.officialName}` : '1m.app'}`,
  }
  return (
    <DocumentMeta {...meta}>
      {children}
    </DocumentMeta>
  )
}

export default DocumentMetaTags;