import { ClassifiedEntry } from "./ClassifiedType";
import { OperationHours, SpecialHours } from "./MerchantType";
import { OrderType } from "./OrderTypes";
import { Rating, Review } from "./ReviewType";

export interface Inventory {
  dailyMaxStock: number | null;
  dailyCurrentStock: number | null;
  lastSoldOut?: Date | null;
}

export interface AllergenIngredients {
  nuts_free?: boolean;
  shellfish_free?: boolean;
  soy_free?: boolean;
  gluten_free?: boolean;
  dairy_free?: boolean;
  caffeine_free?: boolean;
  cold?: boolean;
  hot?: boolean;
  spicy?: boolean;
  halal?: boolean;
  kosher?: boolean;
  vegan?: boolean;
  vegetarian?: boolean;
  locally_grown?: boolean;
  lactose_free?: boolean;
}
export interface MerchandiseBadges {
  new?: boolean;
  hot?: boolean;
  deal?: boolean;
  seasonal?: boolean;
}

export enum MerchandiseUnit {
  DEFAULT = "default",
  POUND = "pound",
  HOUR = "hour",
  MINUTES = "minutes",
  KILOGRAM = "kg",
  EACH = "each",
  BAG = "bag",
  CASE = "case",
  PC = "pc",
  BOX = "box",
  YEAR = "year",
  MONTH = "month",
  CLASS = "class",
}

export interface MerchandiseRating {
  rating: Rating;
  ratingSum: Rating;
  reviewCount: number;
}

export interface Merchandise {
  id: string;
  merchantId: string;
  name: string;
  description: string;
  price: number;
  featured: boolean;
  photos: string[] | undefined;
  videos: string[] | undefined;
  modifierTemplateId?: number;
  categoryId?: number;
  inventory?: Inventory;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  pointsToRedeem?: number;
  visible: boolean;
  displayOrder: number;
  reviews: Review[];
  rating: MerchandiseRating;
  ranking: number;
  classifieds?: ClassifiedEntry[];
  ingredientTags: AllergenIngredients | null;
  badges: MerchandiseBadges | null;
  unitsSold: number;
  unit: MerchandiseUnit;
  unitQuantity: number;
}

// Modifiers of a merchandise, such as different sugar levels, or bobas
export interface MerchandiseModifier {
  id: number;
  merchantId: string;
  name: string;
  price: number;
  description: string;
  isDefault: boolean;
  available: boolean;
  createdAt: Date;
  updatedAt: Date;
  version: number;
}

// Categories of the modifiers of a merchandise, e.g. sugar level
export interface MerchandiseModifierCategory {
  id: number;
  merchantId: string;
  name: string;
  modifierIds: number[];
  minQuantity: number;
  maxQuantity: number;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  description: string;
}

/**
 * A set of categories of modifiers, for example, a template could include:
 *  Sugar level        ---- modifier category
 *     option 1: 100%  ---- modifier
 *     option 2: 50%   ---- modifier
 *  Ice level          ---- modifier category
 *     option 1: 100%  ---- modifier
 *     option 2: 25%   ---- modifier
 */
export interface MerchandiseModifierTemplate {
  id: number;
  merchantId: string;
  name: string;
  modifierCategoryIds: number[];
  createdAt: Date;
  updatedAt: Date;
  version: number;
  applied?: number;
}

// Categories of the merchandise, e.g. Appetizer, desserts
export interface MerchandiseCategory {
  id: number;
  merchantId: string;
  name: string;
  description: string;
  displayOrder: number;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  hours?: OperationHours;
  exclusiveOrderTypes: Array<OrderType>;
}

export interface MerchandiseApiResponseType {
  merchandises: Merchandise[];
  modiTemplates: MerchandiseModifierTemplate[];
  modiCategories: MerchandiseModifierCategory[];
  modifiers: MerchandiseModifier[];
  mercCategories: MerchandiseCategory[];
}

/**
 * The list of modifiers applied to a merchandise
 */
export interface MerchandiseModifiersType {
  [key: string]: number;
}
