import React, { useState } from 'react'
import { OrderStatus } from '../../../../services/models';

export type OrderAction = "prioritize" | "kitchenPrint" | "voided" | "paid-externally" | "refund" | "reminder" | "printReceipt" | "closed-open-check" | undefined;

export interface OrderActionsContextState {
  orderAction?: OrderAction
  setOrderAction: (action: OrderAction) => void
  handleSetOrderStatus: (id: string, nextOrderStatus: OrderStatus, onSuccessCallback?: () => void) => void
}

const OrderActionsContext = React.createContext<OrderActionsContextState>({
  setOrderAction: () => { },
  handleSetOrderStatus: () => { }
})

const OrderActionsContextProvider = (props: {
  children: React.ReactNode
  handleSetOrderStatus: (id: string, nextOrderStatus: OrderStatus, onSuccessCallback?: () => void) => void
}) => {
  const [orderAction, setOrderAction] = useState<OrderAction>();

  return (
    <OrderActionsContext.Provider value={{
      orderAction,
      setOrderAction,
      handleSetOrderStatus: props.handleSetOrderStatus
    }}>
      {props.children}
    </OrderActionsContext.Provider>
  )
}

export { OrderActionsContext, OrderActionsContextProvider }