import React from "react";
import { Link } from "react-router-dom";

const TOSString = ({ CTA = 'Continue' }: { CTA?: string }): React.ReactElement => {
  return (
    <div style={{fontSize: 'small', textAlign: 'center'}}>
      By clicking on {CTA}, you agree to our
      <Link target="_blank" to='/help/privacy-policy'>{' '}Terms of Services{' '}</Link>
      and
      <Link target="_blank" to='/help/terms-of-use'>{' '}Privacy Policy</Link>.
    </div>
  )
}

export default TOSString