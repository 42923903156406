import React, { useContext } from 'react'
import { flatten, groupBy, pipe, pluck, prop, sum, values } from 'ramda';
import {
  Card, CardContent, createStyles, DialogContent, Divider, makeStyles, Stack, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// src
import { LineItem } from 'bos_common/src/types/OrderTypes';
import { MerchantOrdersContext } from '../../context/MerchantOrders/MerchantOrdersContext';
import CustomDialog from '../common/CustomDialog'

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& .order-details-card': {
      borderRadius: theme.spacing(1),
    },
    '& .order-summary-table': {
      margin: theme.spacing(2, 0),
      borderRadius: theme.spacing(1),
      boxShadow: `0px 3px 12px rgb(0 0 0 / 7%)`
    }
  }
}))

interface OrderSummaryDialogProps {
  showDrawer: boolean;
  setShow: (_: boolean) => void;
}

const OrderSummaryDialog = (props: OrderSummaryDialogProps) => {
  const classes = useStyles();
  const { showDrawer, setShow } = props;
  const { ordersList } = useContext(MerchantOrdersContext)
  const { t } = useTranslation()

  if (!showDrawer) return null;

  const toggleDialog = () => {
    setShow(!showDrawer);
  }

  const groupByItemsInOrder = pipe(
    pluck('lineItems'),
    flatten,
    groupBy(prop('id')),
    values
  )(ordersList)

  const getItemsQuantity = (items: LineItem[]): number => pipe(flatten, pluck('quantity'), sum)(items)

  return (
    <CustomDialog
      title={t('OrdersSummary')}
      open={showDrawer}
      setOpen={setShow}
      onClose={toggleDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent className={classes.root}>
        <Card className="order-details-card">
          <CardContent>
            <Typography variant="body2" >
              <b>{t('OrderDetails')}</b>
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2" color="textSecondary" sx={{ width: 100 }}>
                {t('Sales_TotalOrders')}
              </Typography>
              <Typography variant="body2" >
                {ordersList.length} {t('orders')}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2" color="textSecondary" sx={{ width: 100 }}>
                {t('TotalItems')}
              </Typography>
              <Typography variant="body2" >
                {getItemsQuantity(groupByItemsInOrder)} {t('Items')}
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        <TableContainer className="order-summary-table">
          <Table aria-label="order summary table" size="small">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="body2" gutterBottom> <b>{t('Item')}</b></Typography></TableCell>
                <TableCell align='center' width={100}><Typography variant="body2" gutterBottom><b>{t('Quantity')}</b></Typography></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                groupByItemsInOrder.map((lineItems: LineItem[], idx: number) => (
                  <TableRow
                    key={`summary-item-${idx}`}
                    tabIndex={-1}
                  >
                    <TableCell>{lineItems[0]?.name ?? ''}</TableCell>
                    <TableCell align='center' >{getItemsQuantity(lineItems)}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </CustomDialog>
  )
}

export default OrderSummaryDialog