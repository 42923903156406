import React, { useMemo, useState } from "react"
import { Search } from "@material-ui/icons";
import { Stack } from "@material-ui/core";

import { isEmptyOrNil } from "bos_common/src";
import InputSearchBox from "bos_common/src/components/InputSearchBox";
import { Merchandise } from "bos_common/src/types/MerchandiseType";

import ServiceMerchandiseList from "../../../components/ServiceMerchandiseList";
import { useMerchantMenuContext } from "../../../context/MenuContext/MerchantMenuContext";

const ClassesMenu = () => {
  const [searchQuery, setSearchQuery] = useState<string>();

  const { state } = useMerchantMenuContext();

  // Filter List based on search query
  const filteredClassesList: Merchandise[] | undefined = useMemo(() => {
    if(isEmptyOrNil(state.classes)) return [];

    if (!searchQuery || isEmptyOrNil(searchQuery)) {
      // sort selected classes at the top of list
      return state.classes;
    }

    return state.classes.filter((merchandise: Merchandise) => {
      return merchandise.name?.toLowerCase().includes(searchQuery.toLowerCase())
    })
  }, [state.classes, searchQuery])

  return (
    <Stack direction="column" gap={2}>
      <InputSearchBox
        placeholder="Search classes"
        onChangeQuery={(s) => setSearchQuery(s)}
        leftChild={<Search />}
      />

      <ServiceMerchandiseList merchandises={filteredClassesList} />
    </Stack>
  )
}

export default ClassesMenu;