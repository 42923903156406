import { useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Box, createStyles, makeStyles, Paper, Theme, Stack } from '@material-ui/core';

// src
import { Merchant } from 'bos_common/src/types/MerchantType';
import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';
import { UserContext } from 'bos_common/src/context/UserContext';
import { FullscreenPaper, getAuthHeaders } from 'bos_common/src';

import AudienceGroupsList, { AudienceGroupsCount } from './components/AudienceGroupsList';
import CustomersListView from './components/CustomersListView';
import { t } from 'i18next';

type AudienceGroupResponse = {
  count: number;
  data: MerchantCustomer[];
};

const AudienceGroupTags = {
  All: 'all',
  Trial: 'active-trial',
  'Trial Expired': 'expired-trial',
  'New Customers': 'new',
  'Recurring Customers': 'recurring',
  'Package Customers': 'active-package',
  'Expired Package': 'expired-package',
  Members: 'active-membership',
  'Lapsed Customers': 'lapsed',
  Followers: 'followers',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',

      '& .pageContent': {
        height: 'inherit',
        padding: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '300px 1fr',
        gridGap: theme.spacing(2),
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'unset',
        },
        [theme.breakpoints.between('sm', 'md')]: {
          gridTemplateColumns: '260px 1fr',
        },
      },

      '& .side-panel': {
        [theme.breakpoints.up('sm')]: {
          position: 'sticky',
          top: theme.spacing(8),
          height: `calc(100vh - 96px)`,
        },

        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(4),
        },

        '& .customized-chip': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
          fontWeight: 500,
        },
      },
    },
  })
);

interface MerchantCustomersProps {
  merchant: Merchant;
}

const MerchantCustomers = ({ merchant }: MerchantCustomersProps) => {
  const classes = useStyles();
  const [selectedGroup, setSelectedGroup] = useState<string>(t('Orders_New_All'));
  const [currentRowPage, setCurrentRowPage] = useState<number>(0);
  const { token } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState('');

  // fetch all customers list on component mount
  const [{ data: audienceGroupCount = [], loading: loadingCount }, refreshCount] = useAxios<
    AudienceGroupsCount[]
  >(
    {
      url: `/merchants/${merchant?.id}/audience-groups/count`,
      headers: getAuthHeaders(token),
      method: 'GET',
    },
    { manual: true }
  );

  const [{ data: customerResponse, loading: loadingCustomers }, refreshCustomers] =
    useAxios<AudienceGroupResponse>(
      {
        url: `/merchants/${merchant?.id}/audience-groups/${AudienceGroupTags[selectedGroup]}`,
        headers: getAuthHeaders(token),
        params: { pageNumber: currentRowPage + 1, searchQuery: searchQuery },
        method: 'GET',
      },
      { manual: true }
    );

  const refreshData = () => {
    refreshCustomers();
    refreshCount();
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    refreshCustomers();
  }, [selectedGroup, currentRowPage]);

  useEffect(() => {
    setCurrentRowPage(0);
    refreshCustomers();
  }, [searchQuery]);

  return (
    <FullscreenPaper className={classes.root}>
      <Stack direction="row" className="pageContent">
        <Box className="side-panel" component={Paper}>
          <AudienceGroupsList
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            audienceGroupCount={audienceGroupCount}
            isLoading={loadingCount}
            setCurrentRowPage={setCurrentRowPage}
          />
        </Box>
        <Box className="content-container">
          {customerResponse && (
            <CustomersListView
              selectedGroup={selectedGroup}
              merchant={merchant}
              customersList={customerResponse!.data}
              count={customerResponse!.count}
              refreshCustomersList={refreshData}
              isLoading={loadingCustomers}
              currentRowPage={currentRowPage}
              setCurrentRowPage={setCurrentRowPage}
              setSearchQuery={setSearchQuery}
            />
          )}
        </Box>
      </Stack>
    </FullscreenPaper>
  );
};

export default MerchantCustomers;
