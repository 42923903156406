import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, createStyles, Grid, IconButton, makeStyles, Stack, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { rgba } from 'polished';
import useAxios from 'axios-hooks';
import { MoreVert } from '@material-ui/icons';
import { If, Then, Else } from 'react-if';

import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';
import { DeviceType } from 'bos_common/src/types/DeviceType';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { isEmptyOrNil, toFixed2 } from 'bos_common/src';

import BoxWithClick from '../../common/BoxWithClick';
import { GetActivityLogResponse } from '../types';
import { getFormattedDate } from '../merchantCustomerService';
import { Order, OrderType } from '../../../services/models';
import OrderInfoDrawer from '../../Order/OrderInfoDrawer';

interface ProductCustomerActivityTabProps {
  customer: MerchantCustomer;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: 'auto',
    },
    activityCard: {
      '& .MuiCardContent-root': {
        minHeight: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    activityList: {
      '& .logItem': {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: rgba(theme.palette.background.default, 0.5),

      }
    }
  })
)

type OrderActivityLogListProps = {
  list: Order[]
}

const OrderActivityLogList = ({ list }: OrderActivityLogListProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [customerOrderDrawer, setCustomerOrderDrawerOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();

  const handleOrderDetailsClick = (order: Order) => {
    setSelectedOrder(order);
    setCustomerOrderDrawerOpen(true);
  }

  return (
    <div className={classes.activityList}>
      <If condition={isEmptyOrNil(list)}>
        <Then>
          <Typography sx={{ my: 2 }} color="textSecondary">No Activity logs exist</Typography>
        </Then>
        <Else>
          {list.map((order: Order) => {
            const { toPickupTime, id, amount, tip, type, updatedAt } = order;
            return (
              <Box key={id} className="logItem">
                <Stack direction="row" alignItems="center">
                  <Box>
                    <Typography variant='subtitle1' fontWeight={700}>{type} on {getFormattedDate(toPickupTime)}</Typography>
                    <Typography variant='subtitle1' color="textSecondary">${toFixed2(amount)} + ${toFixed2(tip)} tips</Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }} flex={1} justifyContent="flex-end">
                    <IconButton
                      aria-label="see more"
                      size="small"
                      color="primary"
                      onClick={(e) => handleOrderDetailsClick(order)}
                    >
                      <MoreVert />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            );
          })}
        </Else>
      </If>
      <OrderInfoDrawer
        open={customerOrderDrawer}
        setOpen={setCustomerOrderDrawerOpen}
        order={selectedOrder}
        refreshHistory={() => { }}
      />
    </div>
  )
}

type OrderActivityTabs = OrderType | DeviceType.Kiosk

type OrderActivityCardType = {
  label: string,
  value: number,
  type: OrderActivityTabs
}

const OrderActivityCard = (props: OrderActivityCardType) => {
  const classes = useStyles()
  const { label, value } = props

  return (
    <Card className={classes.activityCard}>
      <CardContent>
        <Typography className="cardValue" variant='h4'>{value}</Typography>
        <Typography className="cardLabel" variant='subtitle1' lineHeight={1.2}>{label}</Typography>
      </CardContent>
    </Card>
  )
}

const ProductCustomerActivityTab = (props: ProductCustomerActivityTabProps) => {

  const classes = useStyles()
  const { customer } = props;

  const [selectedOrderType, setSelectedOrderType] = useState<OrderActivityTabs>()
  const [filteredOrderList, setFilteredOrderList] = useState<Order[]>([])

  const [{ data: orderActivityLogs, loading }] = useAxios<GetActivityLogResponse>({
    url: `/merchant/${customer.merchantId}/customers/${customer.id}/activity-log/`,
    params: {
      customerType: customer.type
    },
    method: "GET"
  })

  useEffect(() => {
    if (isEmptyOrNil(orderActivityLogs)) return;
    const { productMerchantsActivityLog } = orderActivityLogs!;
    if (isEmptyOrNil(selectedOrderType)) setFilteredOrderList(productMerchantsActivityLog)
    else {
      const filteredOrderLogs = productMerchantsActivityLog.filter((order) => {
        return selectedOrderType === DeviceType.Kiosk ?
          order.sourceInfo.device === selectedOrderType :
          order.type === selectedOrderType;
      });
      setFilteredOrderList(filteredOrderLogs)
    }
  }, [orderActivityLogs, selectedOrderType])

  const orderActivityFilters: OrderActivityCardType[] = useMemo(() => {
    if (isEmptyOrNil(orderActivityLogs)) return [];
    const { productMerchantsActivityLog } = orderActivityLogs!;
    return [
      {
        label: 'Reservations',
        value: productMerchantsActivityLog.filter((order: Order) => order.type === OrderType.RESERVATION).length,
        type: OrderType.RESERVATION,
      },
      {
        label: 'Dine-In Orders',
        value: productMerchantsActivityLog.filter((order: Order) => order.type === OrderType.DINEIN).length,
        type: OrderType.DINEIN,
      },
      {
        label: 'Pickup Orders',
        value: productMerchantsActivityLog.filter((order: Order) => order.type === OrderType.PICKUP).length,
        type: OrderType.PICKUP,
      },
      {
        label: 'Kiosk Orders',
        value: productMerchantsActivityLog.filter((order: Order) => order.sourceInfo.device === DeviceType.Kiosk).length,
        type: DeviceType.Kiosk,
      },
    ]
  }, [orderActivityLogs]);

  return (
    <>
      <SimpleLoader loading={loading} />
      <div className={classes.root}>
        <Grid container spacing={2}>
          {orderActivityFilters.map((order: OrderActivityCardType) => (
            <Grid item xs={6} md={3} key={order.label}>
              <BoxWithClick
                isSelected={selectedOrderType === order.type}
                handleClick={() => setSelectedOrderType(order.type === selectedOrderType ? undefined : order.type)}
              >
                <OrderActivityCard {...order} />
              </BoxWithClick>
            </Grid>
          ))}
        </Grid>
        <If condition={!loading || !isEmptyOrNil(filteredOrderList)}>
          <OrderActivityLogList list={filteredOrderList} />
        </If>
      </div>
    </>
  )
}
export default ProductCustomerActivityTab;