import React from 'react';
import { Fab, styled } from '@material-ui/core';

const OutlinedFab = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.secondary.main}`,

  "&:disabled":{
    border: 'none'
  },

  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  }
}))

export default OutlinedFab