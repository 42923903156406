import { pathEq } from "ramda";

import { FeatureFlags } from "../types/FeatureFlagTypes";

export const isFeatureEnabled = (
  featureFlags: FeatureFlags | undefined,
  feature: string
) => {
  return pathEq([feature], 1, featureFlags);
};

export const isCouponEnabled = (featureFlags: FeatureFlags | undefined) => {
  return isFeatureEnabled(featureFlags, "enableCoupons");
};

export const isPlatformChatEnabled = (
  featureFlags: FeatureFlags | undefined
): boolean => {
  return pathEq(["enableChat"], 1, featureFlags);
};
