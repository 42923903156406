import React, { useEffect, useState, useContext } from "react";
import {
  Alert,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  LinearProgress,
  makeStyles,
  Slide,
  Theme,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { useHistory, useLocation } from "react-router-dom";
import ReactCodeInput from "react-code-input";

// src
import {
  auth0,
  getAuthMeta,
  storeAuthMeta,
  isPhoneNumberCached,
} from "../../services/auth0";
import TOSString from "../TOSString";
import axios from "../../services/backendAxios";
import { User } from "../../types/UserTypes";
import { UserContext } from "../../context/UserContext";
import { DUMMY_PHONE_NUMBER, DUMMY_PHONE_PASSCODE } from "../../constants";
import { getAPIErrorMessage } from "../../utils";
import { AuthMethod } from "../../types/AuthMethod";

const SMS_CODE_LENGTH = 4;
declare let window: any;

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);
Transition.displayName = "SlideUpTransition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiDialogTitle-root": {
        textAlign: "center",
      },
      "& .dlgContent": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        "& input[type=number]": {
          paddingRight: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
      "& .dlgActions": {
        display: "flex",
        justifyContent: "space-evenly",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
      },
      "& .MuiAlert-root": {
        backgroundColor: "transparent",
        padding: 0,
      },
      "& .TOSText": {
        paddingTop: 0,
      },
    },
  })
);

const PhoneVerificationDialog = (props: {
  phoneNumber: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  redirectTo?: string;
}): React.ReactElement => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [progress, setProgress] = useState(-1);
  const [hasConsent, setConsent] = useState(false);
  const [code, setCode] = useState("");
  const [codeInputKey, setCodeInputKey] = useState(0);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false)
  const [showDialog, setShowDialog] = useState(false);

  const { phoneNumber: phoneNo, open, setOpen, redirectTo } = props;
  const phoneNumber = phoneNo.startsWith("+") ? phoneNo : `+${phoneNo}`;
  const location = useLocation();
  const history = useHistory();
  const { setLoginState, setAuthenticating } = useContext(UserContext)

  const kickOffTimer = () => {
    if (progress < 0) {
      setProgress(60);
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 0) {
            clearInterval(timer);
          }
          return prevProgress - 1;
        });
      }, 1000);
    }
  };

  const sendSMSCode = () => {
    const authMeta = getAuthMeta();
    const lastTimeSMS = authMeta?.lastTimeSMS || 0;
    const phoneHashArr = authMeta?.phoneHashArray || [];

    // handle user consent
    if (isPhoneNumberCached(phoneHashArr, phoneNumber)) {
      setConsent(true);
    } else {
      axios
        .get<boolean>("/users/exist", {
          params: { phoneNumber },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            setConsent(true);
          }
          setConsent(false);
        });
    }

    // if one minute has passed
    if (progress <= 60) {
      setLoading(true);
      setCode("");
      setErrorMsg("");
      if (phoneNumber.indexOf(DUMMY_PHONE_NUMBER) === -1) {
        auth0.passwordlessStart(
          {
            connection: "sms",
            send: "code",
            phoneNumber,
          },
          (err, result) => {
            if (err) {
              if (err.statusCode === 400) {
                setIsInvalidPhoneNumber(true)
              }
              setLoading(false);
              // need to handle different error code
              setErrorMsg(err.description || err.error);
            } else {
              storeAuthMeta(Date.now());
              kickOffTimer();
              setLoading(false);
            }
          }
        );
      } else {
        setLoading(false);
      }
    }
  };

  const authorizeSMS = () => {
    if (code?.length === SMS_CODE_LENGTH) {
      if (phoneNumber.indexOf(DUMMY_PHONE_NUMBER) !== -1) {
        if (code !== DUMMY_PHONE_PASSCODE) {
          setLoading(false);
          setErrorMsg('Wrong passcode, please try again');
        } else {
          // call 1m auth endpoints with phone and passcode
          axios.post<User>("/login", {
            phoneNumber,
            authMethod: AuthMethod.PHONE,
          }).then((response) => {
            setLoading(false);
            setOpen(false);
            const user = response.data as User;
            setLoginState(user, undefined);
            storeAuthMeta(undefined, user.phoneNumber);
          }).catch((err) => {
            const message = (getAPIErrorMessage(err) || err?.error) as string;
            setLoading(false);
            setErrorMsg(message);
          });
        }
      } else {
        // show drawer by default
        const state = redirectTo || location.pathname;
        auth0.passwordlessLogin(
          {
            connection: "sms",
            phoneNumber,
            verificationCode: code,
            state,
          },
          (err) => {
            if (err) {
              setLoading(false);
              setErrorMsg(err.error_description || err.error);
            } else {
              setLoading(false);
              setOpen(false);
            }
          }
        );
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMsg("");
    setCodeInputKey(codeInputKey + 1);
    setCode("");
    setProgress(-1)
  };

  const handleSignup = () => {
    setLoading(true);
    authorizeSMS();
  };

  const onCodeChange = (c: string) => {
    setCode(c);
  };

  useEffect(() => {
    if (open) {
      if (phoneNumber.indexOf(DUMMY_PHONE_NUMBER) !== -1) {
        setShowDialog(true);
        sendSMSCode();
      } else if (window?.webkit && window?.webkit.messageHandlers.authMessageHandler) {
        setAuthenticating(true)
        window?.webkit.messageHandlers.authMessageHandler.postMessage(phoneNumber);
      } else if (window?.authMessageHandler) {
        setAuthenticating(true)
        window.authMessageHandler.postMessage(phoneNumber)
      } else {
        setShowDialog(true);
        sendSMSCode();
      }
    } else {
      setShowDialog(false);
    }
  }, [open]);

  useEffect(() => {
    if (hasConsent && code.length === SMS_CODE_LENGTH && !loading) {
      handleSignup();
    }
  }, [code]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Phone verification"
      TransitionComponent={Transition}
      keepMounted
      open={showDialog}
      className={classes.root}
    >
      <DialogTitle>Enter your verification code</DialogTitle>
      {loading && <LinearProgress />}

      <DialogContent className="dlgContent">
        <ReactCodeInput
          type="number"
          fields={SMS_CODE_LENGTH}
          autoFocus
          name="phone-verification-code"
          inputMode="numeric"
          value={code}
          key={codeInputKey}
          onChange={onCodeChange}
        />
        <Typography
          gutterBottom
          variant="subtitle2"
          color="textSecondary"
          component="div"
        >
          Code sent to {phoneNumber}
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions className="dlgActions">
        <Fab
          onClick={sendSMSCode}
          color="inherit"
          variant="extended"
          size="medium"
          disabled={loading || progress > 0 || isInvalidPhoneNumber}
        >
          {progress > 0 ? `Resend (:${progress})` : `Resend`}
        </Fab>
        <Fab
          onClick={handleSignup}
          color="primary"
          variant="extended"
          size="medium"
          disabled={loading || code.length !== SMS_CODE_LENGTH}
        >
          Continue
        </Fab>
      </DialogActions>
      {!hasConsent && (
        <DialogContent className="TOSText">
          <TOSString />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default PhoneVerificationDialog;