import React, { useContext, useState } from 'react';
import { Views, View } from 'react-big-calendar';
import useAxios from 'axios-hooks';
import { lensPath, pathEq, pathOr, pluck, propOr, zipObj, over } from 'ramda';

import { UserContext } from 'bos_common/src/context/UserContext';
import { MerchantStaff } from 'bos_common/src/types/MerchantStaff';
import axios from 'bos_common/src/services/backendAxios';
import { getAPIErrorMessage, getAuthHeaders, isEmptyOrNil } from 'bos_common/src';
import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType';
import { AttendeeStatus, ChangeAppliedTo, CustomerScheduleEntry } from 'bos_common/src/types/crm/CustomerScheduleEntryType';
import { Merchandise } from 'bos_common/src/types/MerchandiseType';
import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';

import { AppContext } from '../../../context/AppContext';
import { BigCalendarEvent, CalenderEventPayload } from '../types';
import { formatCalendarEvents, sortItemsAlphabetically } from '../utils';
import { CalendarContext, EventsData } from './CalendarContext';
import { NotificationSeverity } from '../../../types/NotificationSlice';

type CalendarContextProviderProps = {
  children: React.ReactElement | React.ReactElement[];
};

const CalendarContextProvider = (props: CalendarContextProviderProps) => {
  const { children } = props;

  const { merchant, triggerNotification } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const [loading, setIsLoading] = useState<boolean>(false);
  const [eventsData, setEventsData] = useState<EventsData>({});
  const [staffList, setStaffList] = useState<MerchantStaff[]>([]);
  const [selectedStaffList, setSelectedStaffList] = useState<MerchantStaff[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<BigCalendarEvent>();
  const [customersList, setCustomersList] = useState<MerchantCustomer[]>([])

  const [calendarView, setCalendarView] = useState<View>(Views.DAY)

  const [{ services, packages }, setServicesAndPackages] = useState<{ services: Merchandise[], packages: MerchantPackage[] }>({ services: [], packages: [] });

  const [_calendarResponse, executeFetch] = useAxios(
    {
      url: `merchants/${merchant?.id}/calendar`,
    },
    { manual: true }
  );

  const [_customersResponse, fetchCustomersList] = useAxios(
    {
      url: `customers/merchant/${merchant?.id}`,
    },
    { manual: true }
  );

  const [_servicesAndPackages, fetchServicesAndPackages] = useAxios(
    {
      url: `merchants/${merchant?.id}/services-and-packages`,
    }
  )

  const addCalendarEvent = async (payload: CalenderEventPayload) => {
    if (isEmptyOrNil(payload)) return false;

    try {
      const addEventResponse = await axios.post(
        `merchants/${merchant?.id}/calendar`,
        { ...payload },
        { headers: getAuthHeaders(token) }
      );

      await fetchCalendarEvents();
      triggerNotification(true, 'Calendar Event created successfully!', NotificationSeverity.SUCCESS)
      return true;
    } catch (e) {
      console.log(e);
      triggerNotification(true, getAPIErrorMessage(e, 'Failed to create Calendar Event!'), NotificationSeverity.ERROR)
      return false;
    }
  };

  const updateCalendarEvent = async (payload: CalenderEventPayload) => {
    const id = payload.id || selectedEvent?.id;
    if (isEmptyOrNil(payload) || !id)
      return false;

    try {
      await axios.put(
        `merchants/${merchant?.id}/calendar/event/${id}`,
        { ...payload },
        { headers: getAuthHeaders(token) }
      );

      await fetchCalendarEvents();
      triggerNotification(true, 'Calendar Event updated successfully!', NotificationSeverity.SUCCESS)
      return true;
    } catch (e) {
      console.log(e);
      triggerNotification(true, getAPIErrorMessage(e, 'Failed to update Calendar Event!'), NotificationSeverity.ERROR)
      return false;
    }
  };

  const deleteCalendarEvent = async (eventId: number, option: ChangeAppliedTo): Promise<boolean> => {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('changeAppliedTo', `${option}`);
      await axios.delete(
        `merchants/${merchant?.id}/calendar/${eventId}?${searchParams.toString()}`,
        { headers: getAuthHeaders(token) }
      )

      await fetchCalendarEvents();
      triggerNotification(true, 'Calendar Event deleted successfully!', NotificationSeverity.SUCCESS)
      return true;
    } catch (e) {
      console.log(e);
      triggerNotification(true, getAPIErrorMessage(e), NotificationSeverity.ERROR)
      return false;
    }
  }

  const fetchCalendarEvents = async () => {
    setIsLoading(true);

    const eventsResponse = await executeFetch();
    const { status, data } = eventsResponse;

    if (status !== 200) {
      setEventsData({});
      setStaffList([]);
      setSelectedStaffList([]);
      setIsLoading(false);
      return;
    }

    const { eventsList, staffs } = formatCalendarEvents(data);
    const ids = pluck('id', eventsList)
    const zippedEventsData = zipObj(ids, eventsList)
    setEventsData(zippedEventsData);
    if (!isEmptyOrNil(selectedEvent))
      setSelectedEvent(zippedEventsData[selectedEvent!.id])
    setStaffList(staffs);
    setSelectedStaffList(staffs);
    setIsLoading(false);
  };

  const updateCustomerAttendanceAndNote = async (attendee: CustomerScheduleEntry, status: AttendeeStatus, note?: string) => {
    if ((isEmptyOrNil(status) && isEmptyOrNil(note)) || !selectedEvent || !merchant) return [];

    try {
      const attendanceResponse: CustomerScheduleEntry = propOr({}, 'data',
        await axios.post(
          `customers/${attendee.customerId}/attendance/${attendee.eventId}`,
          {
            ...(!isEmptyOrNil(status) && { status }),
            ...(!isEmptyOrNil(note) && { note }),
          },
          { headers: getAuthHeaders(token) }
        )
      )

      const thisAttendeeName = pathOr('', ['customer', 'displayName'], attendee)
      const existing = propOr({}, selectedEvent?.id, eventsData)
      const customerIndex = existing.customerEntries?.findIndex((a: CustomerScheduleEntry) =>
        pathEq(['customer', 'displayName'], thisAttendeeName, a)
      );

      if (customerIndex !== -1 && !isEmptyOrNil(attendanceResponse)) {
        const updatedEvent: BigCalendarEvent = over(
          lensPath(['customerEntries', customerIndex]),
          (entry: CustomerScheduleEntry) => ({ ...entry, ...attendanceResponse }),
          existing
        )

        const updatedEventsData = {
          ...eventsData,
          [existing.id]: updatedEvent
        }

        setSelectedEvent(updatedEvent);
        setEventsData(updatedEventsData);

        return updatedEvent?.customerEntries ?? []
      }
    }
    catch (err) {
      console.error({ err })
      triggerNotification(true, 'Failed to add Note to the customer!', NotificationSeverity.ERROR)
      return [];
    }
  }

  const updateEventPaymentAmount = async (amount: number) => {
    if (!selectedEvent || !merchant) return 0;

    try {
      await axios.post(
        `merchants/${merchant.id}/calendar/event/${selectedEvent.id}/tips`,
        { amount },
        { headers: getAuthHeaders(token) }
      )

      const existing = propOr({}, selectedEvent?.id, eventsData)

      const updatedEvent: BigCalendarEvent = over(
        lensPath(['serviceTips', 'amount']),
        () => amount,
        existing
      )

      const updatedEventsData = {
        ...eventsData,
        [existing.id]: updatedEvent
      }

      setSelectedEvent(updatedEvent);
      setEventsData(updatedEventsData);

      return amount;
    } catch (err) {
      console.error({ err })
      triggerNotification(true, 'Failed to update event tips!', NotificationSeverity.ERROR)
      return selectedEvent.serviceTips?.amount ?? 0;
    }
  }

  React.useEffect(() => {
    if (isEmptyOrNil(merchant)) return;

    fetchCustomersList()
      .then((res) => {
        const { data, status } = res;
        if (status !== 200) setCustomersList([]);
        setCustomersList(
          sortItemsAlphabetically(data, 'displayName')
        )
      })

    fetchServicesAndPackages()
      .then((res) => {
        const { data, status } = res;
        if (status !== 200) setServicesAndPackages({ packages: [], services: [] });
        setServicesAndPackages(data)
      })


  }, [merchant?.id])

  const calendarContextValue = {
    fetchCalendarEvents,
    addCalendarEvent,
    updateCalendarEvent,
    deleteCalendarEvent,
    loading,
    eventsData,
    setEventsData,
    staffList,
    selectedStaffList,
    setSelectedStaffList,
    selectedEvent,
    setSelectedEvent,
    updateCustomerAttendanceAndNote,
    updateEventPaymentAmount,
    customersList,
    services,
    packages,
    calendarView,
    setCalendarView
  };

  return (
    <CalendarContext.Provider value={calendarContextValue}>
      {children}
    </CalendarContext.Provider>
  );
};

function areEqual() {
  return true;
}

export default React.memo(CalendarContextProvider, areEqual);
