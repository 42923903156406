import { flatten, omit, pathOr, pipe, propOr } from 'ramda';
import { differenceInDays, isFuture } from 'date-fns';

import CalendarEvent from 'bos_common/src/types/crm/CalendarEventType';

import { MerchantStaff } from '../../services/models';
import { BigCalendarEvent, CalendarState } from './types';
import { mapIndexed } from '../../utils';

export const getFormattedCalendarEvent = (
  resourceId: number,
  event: CalendarEvent
): BigCalendarEvent => {
  const { date, merchantPackage, calendarSchedule } = event;

  const start = new Date(event.date);
  const end = new Date(calendarSchedule?.endDate!);

  return {
    ...event,
    date: new Date(date),
    start,
    end,
    resourceId,
    subtitle: propOr('', 'name', merchantPackage),
    description: propOr('', 'description', merchantPackage),
  };
};

export const formatCalendarEvents = (staffsList: MerchantStaff[]): CalendarState => {
  // list of staff members for the calendar view
  const staffs = staffsList.map((staff) => {
    const mappedStaff = omit('calendarEvents', staff);
    return {
      ...mappedStaff,
      displayName: pathOr('', ['user', 'displayName'], mappedStaff),
    };
  });

  // list of events for each resource (staff)
  const eventsMapper = (staff: MerchantStaff) => {
    const { id: resourceId } = staff;
    const calendarEvents: CalendarEvent[] = propOr([], 'calendarEvents', staff);
    const _staff = omit('calendarEvents', staff);

    return calendarEvents.map((event) => ({
      ...getFormattedCalendarEvent(resourceId, event),
      staff: _staff,
    }));
  };

  const eventsList = pipe(mapIndexed(eventsMapper), flatten)(staffsList);

  return { eventsList, staffs };
};

export const sortItemsAlphabetically = <Data extends Record<string, any>>(data: Data[], key: keyof Data) => {
  return data.sort((a, b) => {
    const stringA = a[key];
    const stringB = b[key];

    if (stringA && stringB) {
      return stringA.localeCompare(stringB);
    }

    // eslint-disable-next-line no-nested-ternary
    return stringA ? -1 : stringB ? 1 : 0;
  });
};

export const getDateTimeString = (date: Date, timeOffset = 0): Date =>
  new Date(new Date(date).setHours(0, 0, 0) + timeOffset);

export const isEventOlderThanOneWeek = (ev?: CalendarEvent): boolean =>
  Boolean(ev && differenceInDays(new Date(), ev.date) >= 6);

export const isEventInTheFuture = (ev?: CalendarEvent): boolean =>
  Boolean(ev && isFuture(ev.date));

// google palette
// export const colorsForStaff = [
//   'rgb(213, 0, 0)',
//   'rgb(244, 81, 30)',
//   'rgb(51, 182, 121)',
//   'rgb(3, 155, 229)',
//   'rgb(121, 134, 203)',
//   'rgb(97, 97, 97)',
//   'rgb(230, 124, 115)',
//   'rgb(246, 191, 38)',
//   'rgb(11, 128, 67)',
//   'rgb(63, 81, 181)',
//   'rgb(142, 36, 170)'
// ];

// palette 2
export const colorsForStaff = [
  '#FBF8CC',
  '#FDE4CF',
  '#FFCFD2',
  '#F1C0E8',
  '#CFBAF0',
  '#A3C4F3',
  '#90DBF4',
  '#8EECF5',
  '#98F5E1',
  '#B9FBC0'
];

// palette 3
// export const colorsForStaff = [
//   '#FFADAD',
//   '#FFC2A9',
//   '#FFD6A5',
//   '#FDFFB6',
//   '#CAFFBF',
//   '#B3FBDF',
//   '#AAE0EF',
//   '#A0C4FF',
//   '#BDB2FF',
//   '#FFC6FF'
// ]

