import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  IconButton,
  Fab,
  Grid,
  Paper,
  Switch,
  Typography,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { omit, pipe, propOr, reduce, keys, without, pluck } from 'ramda';
import { useTranslation } from 'react-i18next';

import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { ColoredPaper, FullscreenPaper } from 'bos_common/src';
import { getAuthHeaders } from 'bos_common/src/utils';
import { MultiFabContainer } from 'bos_common/src/components/FabContainers';
import { UserContext } from 'bos_common/src/context/UserContext';
import ConfirmationAlert from 'bos_common/src/components/ConfirmationAlert';
import { UNIT_TO_DISPLAY } from 'bos_common/src/components/Price';
import { MerchandiseBadgesList } from '../bos_common/src/components/Merchandise/MerchandiseBadges';
import { AllergenIngredientsLabelsMap, AllergenIngredientsList, getAllergenIcon } from 'bos_common/src/components/Merchandise/MerchandiseAllergens';

import { useAppDispatch } from '../redux/hooks';
import { deleteMerchandiseItemSucceed, createMerchandiseSucceeded } from '../redux/slice/merchandise/merchandiseActions';

import ChipsList from './common/ChipsList';
import MediaCarousel from './common/MediaCarousel';
import { AppContext } from '../context/AppContext';
import { isEmptyOrNil, isMerchandiseAvailable } from '../utils';
import { isMerchantServicesType } from '../utils/merchantUtils';
import { NotificationSeverity } from '../types/NotificationSlice';
import { AllergenIngredients, Merchandise, MerchandiseUnit, Merchant } from '../services/models';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        marginTop: theme.spacing(2),
      },
      '& .dlgTitle': {
        textAlign: 'center',
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
      },
    },
    badgeName: {
      textTransform: 'capitalize',
    },
    closeButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    availabilitySwitch: {
      borderRadius: '8px',
      border: '1px solid #bcc0c4',
      padding: '8px 0px',
      position: 'relative',
      marginTop: theme.spacing(2),

      '& .switch-label': {
        fontSize: "0.8rem",
        lineHeight: '1.4375em',
        fontFamily: '"Manrope","Helvetica","Arial",sans-serif',
        color: 'rgba(0, 0, 0, 0.6)',
        backgroundColor: "#f5faff",
        padding: "0 5px",

        position: 'absolute',
        top: '-8px',
        left: '10px',
      }
    },
    chipsListTitle: {
      padding: theme.spacing(2),
    },
    chipsListItems: {
      padding: theme.spacing(2),
    },
  })
);

interface FormValues extends Merchandise {}

interface MerchandiseEditDialogProps {
  open: boolean,
  merchandise?: Merchandise,
  merchant: Merchant,
  onClose: () => void,
}

const MERCHANDISE_API_ENDPOINT = '/merchants/merchandises'

const getMerchandiseTitle = (merchandise?: Merchandise, isServicesMerchant?: boolean) => {
  if(!isEmptyOrNil(merchandise) && isServicesMerchant) {
    return "Service_Class_Edit";
  }

  if(!isEmptyOrNil(merchandise) && !isServicesMerchant) {
    return "Menu_MenuItem_Edit";
  }

  if(isEmptyOrNil(merchandise) && isServicesMerchant) {
    return "Service_Class_Add";
  }

  if(isEmptyOrNil(merchandise) && !isServicesMerchant) {
    return "Menu_MenuItem_Add";
  }

  return "Service_Class_Edit"
}

const MerchandiseEditDialog = (props: MerchandiseEditDialogProps) => {
  const { merchandise, open, merchant, onClose } = props;

  if(!open) return null;

  const merchantId = merchant?.id;

  const classes = useStyles();
  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext);
  const { t } = useTranslation();
  const reduxDispatch = useAppDispatch();

  const [_isMerchandiseAvailable, toggleMerchandiseAvailability] = useState<boolean>(false);
  const [showConfirmationAlert, toggleConfirmationAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let deleteMerchandise = false

  const isServicesMerchant = isMerchantServicesType(merchant)

  const [defaultIngredientTags, defaultBadges] = useMemo(() => {
    return ['ingredientTags', 'badges'].map(key => {
      return pipe(
        propOr({}, key),
        keys,
        without([undefined, ''])
      )(merchandise);
    });
  }, [merchandise]);

  useEffect(() => {
    toggleMerchandiseAvailability(isMerchandiseAvailable(merchandise))
  }, [merchandise])

  const [{ error: updateMerchandiseError, loading: merchandiseLoading }, executeMerchandiseApi] = useAxios<Merchandise>(
    { url: MERCHANDISE_API_ENDPOINT },
    { manual: true }
  );

  const handleClose = () => {
    toggleMerchandiseAvailability(isMerchandiseAvailable(merchandise))
    reset();
    onClose();
  };

  const onMerchandiseSubmit: SubmitHandler<FormValues> = (formValues) => {
    // eslint-disable-next-line no-nested-ternary
    const method = deleteMerchandise ? 'DELETE' : (merchandise ? 'PUT' : 'POST');

    const url = !merchandise
      ? MERCHANDISE_API_ENDPOINT
      : (`${MERCHANDISE_API_ENDPOINT}/${merchandise.id}`)

    const [ingredientTags, badges] = ['ingredientTags', 'badges'].map(key => {
      return pipe(
        propOr([], key),
        reduce((acc: any, v: any) => ({ ...acc, [v.value]: true }), {})
      )(formValues);
    });

    const headers = getAuthHeaders(token)
    const data = {
      ...formValues,
      ingredientTags,
      badges,
      available: _isMerchandiseAvailable,
      inventory: {
        ...formValues.inventory,
        dailyCurrentStock: _isMerchandiseAvailable ? null : 0
      },
      unitQuantity: parseFloat(`${formValues.unitQuantity || 0}`),
    }

    const pointsToRedeem = isEmptyOrNil(data.pointsToRedeem) ? null : data.pointsToRedeem;

    return executeMerchandiseApi({
      data: { ...data, pointsToRedeem },
      method,
      url,
      headers
    })
      .then((response) => {
        deleteMerchandise = false
        triggerNotification(true, `Business updated successfully`, NotificationSeverity.SUCCESS, true)
        if (response.status === 200) {
          if (!deleteMerchandise)
            reduxDispatch(createMerchandiseSucceeded(response.data))
          else
            reduxDispatch(deleteMerchandiseItemSucceed(response.data.id))
          reset();
          onClose();
        }
      }).catch(() => {
        triggerNotification(true, `Failed to update menu`, NotificationSeverity.ERROR, true)
      });
  };

  const onDeleteMerchandise = () => {
    deleteMerchandise = true
    handleSubmit(onMerchandiseSubmit)()
    toggleConfirmationAlert(false);
  }

  const methods = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      name: undefined,
      price: undefined,
      description: undefined,
      merchantId,
      photos: undefined,
      id: undefined,
      ingredientTags: undefined,
      badges: undefined,
      unit: isServicesMerchant ? MerchandiseUnit.MINUTES : MerchandiseUnit.DEFAULT,
      ...(!isEmptyOrNil(merchandise) && {
        ...omit(['ingredientTags', 'badges'], merchandise)
      })
    }
  });


  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    },
    setValue,
    reset,
    watch,
    control,
  } = methods;

  const registerTextField = (name: keyof Merchandise, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);
    return { ...inputProps, inputRef }
  }

  // @ts-ignore
  const photos = watch('photos', propOr([], 'photos', merchandise) as (src: any) => string[] | undefined)
  // @ts-ignore
  // TODO: check how to fixed nested type issue
  const videos = watch('videos', propOr([], 'videos', merchandise) as (src: any) => string[] | undefined)

  const onChipListChange = useCallback((key: 'badges' | 'ingredientTags', list: string[]) => (selectedValues: string[]) => {
    const valuesToSave = list
      .filter(value => selectedValues.includes(value))
      .map(value => ({ value }));
    // @ts-expect-error - TS mismatch
    setValue(key, valuesToSave, { shouldDirty: true, shouldTouch: true });
  }, []);

  const isLoading = merchandiseLoading || loading;

  return (
    <FormProvider {...methods}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-edit-merchandise"
        className={classes.root}
      >
        <DialogTitle id="form-dialog-edit-merchandise" className="dlgTitle">
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
          {t(getMerchandiseTitle(merchandise, isServicesMerchant))}
        </DialogTitle>

        <Divider />
        <SimpleLoader loading={isLoading} />

        <FullscreenPaper>
          <ColoredPaper>
            <DialogContent>
              <form onSubmit={handleSubmit(onMerchandiseSubmit)}>
                <Grid container justifyContent="space-evenly" spacing={2}>
                  <Grid item xs={12} md={5}>
                    <Paper variant="outlined">
                      <MediaCarousel
                        photosData={photos || []}
                        videosData={videos || []}
                        setLoading={setLoading}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          // merchandise name
                          fullWidth
                          required
                          label={t("Name")}
                          helperText={t("Menu_MenuItem_Name_HelperText")}
                          error={!!errors.name}
                          {...registerTextField('name', { required: t("Menu_MenuItem_Name_RequiredText") })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          // merchandise price
                          fullWidth
                          required
                          helperText={t("Menu_MenuItem_Price_HelperText")}
                          label={t("Price")}
                          type="number"
                          error={!!errors.price}
                          inputProps={{ step: '0.01', min: '0' }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                          }}
                          {...registerTextField('price', { required: t("Menu_MenuItem_Price_RequiredText") })}
                        />
                      </Grid>
                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            // merchandise unit qty
                            fullWidth
                            label={t("UnitQty")}
                            type="number"
                            error={!!errors.unitQuantity}
                            inputProps={{ step: '0.1', min: '0' }}
                            {...registerTextField('unitQuantity', { required: t("Menu_MenuItem_Price_RequiredText") })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            // merchandise unit
                            id="outlined-select-currency-native"
                            fullWidth
                            select
                            label={t("Unit")}
                            SelectProps={{ native: true }}
                            {...registerTextField('unit')}>
                            {Object
                              .entries(UNIT_TO_DISPLAY)
                              .map((opt) => (
                                <option key={opt[0]} value={opt[0]}>
                                  {opt[1] ?? 'none'}
                                </option>
                              ))
                            }
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <FormHelperText sx={{ ml: 1.75 }}>Price per unit quantity, e.g. $XX per 2 hours.</FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          minRows={2}
                          maxRows={10}
                          label={t("Description")}
                          {...registerTextField('description')}
                        />
                      </Grid>

                      {!isServicesMerchant && (
                        <Grid item xs={6}>
                          <div className={classes.availabilitySwitch}>
                            <span className='switch-label'>{t("Menu_MenuItem_Availability")}</span>
                            <label>
                              <Switch
                                checked={_isMerchandiseAvailable}
                                onChange={(e) => toggleMerchandiseAvailability(!_isMerchandiseAvailable)}
                                color="primary"
                                inputProps={{ 'aria-label': 'in-stock' }}
                              />
                              {_isMerchandiseAvailable ? t("InStock") : t("OutOfStock")}
                            </label>
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <div className={classes.availabilitySwitch}>
                          <span className='switch-label'>{t("Menu_MenuItem_Visibility")}</span>
                          <Controller
                            control={control}
                            name="visible"
                            render={({
                              field: { onChange, value }
                            }) => (
                              <>
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                  color="primary"
                                  name='merchandise-visibility-switch'
                                  inputProps={{ 'aria-label': 'true' }}
                                  id='merchandise-visibility-switch'
                                />
                                <label
                                  htmlFor='merchandise-visibility-switch'
                                  onClick={(e: any) => e.stopPropagation()}
                                >
                                  {value ? t("VisibleCustomer") : t("HiddenCustomer")}
                                </label>
                              </>
                            )}
                          />
                        </div>
                      </Grid>

                      {!isServicesMerchant && (
                        <>
                          <Grid item xs={12}>
                            <Paper>
                              <Typography variant="subtitle1" className={classes.chipsListTitle}>
                                Add Badge (limit to 1)
                              </Typography>
                              <Divider />
                              <div className={classes.chipsListItems}>
                                <ChipsList
                                  items={
                                    MerchandiseBadgesList.map(badgeId => ({
                                      id: badgeId,
                                      label: <span className={classes.badgeName}>{badgeId}</span>,
                                    }))
                                  }
                                  defaultValue={defaultBadges}
                                  onChange={onChipListChange('badges', MerchandiseBadgesList)}
                                />
                              </div>
                            </Paper>
                          </Grid>
                          <Grid item xs={12}>
                            <Paper>
                              <Typography variant="subtitle1" className={classes.chipsListTitle}>
                                Mark Ingredients (multiple choices)
                              </Typography>
                              <Divider />
                              <div className={classes.chipsListItems}>
                                <ChipsList
                                  items={
                                    AllergenIngredientsList.map(item => ({
                                      id: item,
                                      label: AllergenIngredientsLabelsMap[item],
                                      icon: getAllergenIcon(item as keyof AllergenIngredients),
                                    }))
                                  }
                                  defaultValue={defaultIngredientTags}
                                  onChange={onChipListChange('ingredientTags', AllergenIngredientsList)}
                                  multiple
                                />
                              </div>
                            </Paper>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {merchandise && <input type="hidden" {...register('id')} value={merchandise.id} />}
                <input type="hidden" {...register('merchantId')} value={merchantId} />

                <MultiFabContainer>
                  <>
                    {
                      merchandise &&
                      (
                        <Fab
                          variant="extended"
                          color="secondary"
                          className="deleteFab"
                          onClick={() => toggleConfirmationAlert(true)}
                        >
                          <Delete />
                          {t("Delete")}
                        </Fab>
                      )
                    }
                    <Fab
                      type="submit"
                      disabled={isSubmitting}
                      variant="extended"
                      color="primary">
                      <Save />
                      {t("Save")}
                    </Fab>
                  </>
                </MultiFabContainer>
              </form>
            </DialogContent>

            <ConfirmationAlert
              isOpen={showConfirmationAlert}
              title={t("Menu_DeleteMenuItem")}
              onCancel={() => toggleConfirmationAlert(false)}
              onConfirm={() => onDeleteMerchandise()}
              cancelLabel={t("Cancel")}
              confirmLabel={t("Confirm")}
            >
              {t("Menu_DeleteMenuItem_Description")}
            </ConfirmationAlert>
          </ColoredPaper>
        </FullscreenPaper>
      </Dialog >
    </FormProvider>
  )
}

export default MerchandiseEditDialog
