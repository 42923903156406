import { anyPass, isEmpty, isNil, addIndex, map, pipe, propOr, propEq, defaultTo, without, path, pathOr, flatten, groupBy, mapObjIndexed, ascend, sort, prop, find } from 'ramda';
import formatTime from 'date-fns/format';

import { formatDate } from 'bos_common/src';
import { DAYS_OF_WEEK_FULL_NAMES } from '../components/Stats/chartUtils';
import { PRIORITIZED_ORDERS_LIST } from '../constants/localStorageKeys';
import {
  User, Order, LineItem, Merchandise, IdentityRole, MerchandiseCategory,
  Merchant, MerchantStaff, StaffRole, SpecialHours, OperationHours
} from '../services/models';
import config from '../config/config'
import { ServiceFormType } from '../constants';
import { ADD_ONE_MEMBERSHIP, ADD_ONE_PACKAGE, ADD_ONE_TRIAL, EDIT_ONE_MEMBERSHIP, EDIT_ONE_PACKAGE, EDIT_ONE_TRIAL } from '../context/MenuContext/constants';

export const isProduction = () => process.env.NODE_ENV === "production" && config.env.isStaging === 0

export const isEmptyOrNil = anyPass([isEmpty, isNil]);
export const mapIndexed = addIndex(map);

export const isValidURL = (str: string) => {
  const pattern = new RegExp(/^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/);
  return pattern.test(str);
};

export const hasSpecialChars = (value: string) => {
  return value && (/[^a-zA-z0-9\-\.\_\&]/gi.test(value) || /[\\\[\]\^\`]/gi.test(value));
};

export type MappedUsers = { [key: string]: User };
export const userMap = (users?: User[]): MappedUsers => {
  const toUsers: MappedUsers = {};
  users?.forEach((user) => {
    toUsers[user.id] = user;
  });
  return toUsers;
};

export type MappedOrders = { [key: string]: Order };
export const orderMap = (orders?: Order[]): MappedOrders => {
  const toOrders: MappedOrders = {};
  orders?.forEach((order) => {
    toOrders[order.id] = order;
  });
  return toOrders;
};

export type MappedMerchants = { [key: string]: Merchant };
export const merchantsMap = (merchants?: Merchant[]): MappedMerchants => {
  const toMerchants: MappedMerchants = {};
  merchants?.forEach((merchant) => {
    toMerchants[merchant.id] = merchant;
  });
  return toMerchants;
};

export const getClientTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertToUtcDate = (date: Date = new Date()) =>
  new Date(date).getTime() + 60000 * -new Date().getTimezoneOffset();

export const a11yTabProps = (index: any) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export const getLocalStorageLineItemKey = (
  item: LineItem,
  order?: Order,
  lineItemUniqueIdentifier?: string
) =>
  `${lineItemUniqueIdentifier ? `${lineItemUniqueIdentifier}-` : ''}${order?.id}-${item.id}${item.customizationSignature ? `-${item.customizationSignature}` : ''
  }`;

export const clearLineItemsFromLocalStorage = (order?: Order) => {
  if (isEmptyOrNil(order)) return;

  const lineItemLocalStorageKeys = pipe(
    propOr([], 'lineItems'),
    map((item: LineItem, index: number) => getLocalStorageLineItemKey(item, order, `item_${index}`))
  )(order);

  lineItemLocalStorageKeys.forEach((key: string) => {
    localStorage.removeItem(key);
  });

  const localStorageOrderPriorityQueue = defaultTo(
    [],
    JSON.parse(`${localStorage.getItem(PRIORITIZED_ORDERS_LIST)}`)
  );
  const orderPriorityKey = `${order?.merchantId}-${order?.id}`;

  localStorage.setItem(
    PRIORITIZED_ORDERS_LIST,
    JSON.stringify(without([orderPriorityKey], localStorageOrderPriorityQueue))
  );
};

export const getAllLineItemsFromLocalStorage = (order?: Order): Array<string> => {
  if (isEmptyOrNil(order)) return [];
  let indexPointer = 0;

  const lineItemKeys: any = [];
  const lineItemLocalStorageKeys = pipe(
    propOr([], 'lineItems'),
    mapIndexed((item: LineItem) => {
      const { quantity } = item;
      const keys = [];

      for (let index = 0; index < quantity; index++) {
        keys.push(getLocalStorageLineItemKey(item, order, `item_${indexPointer}`));
        indexPointer++;
      }
      return keys;
    }),
    flatten
  )(order);

  lineItemLocalStorageKeys.forEach((key: string) => {
    const lineItemValue = localStorage.getItem(key);
    if (lineItemValue) lineItemKeys.push({ [key]: lineItemValue });
  });

  return lineItemKeys;
};

export const getOrderCountDownColor = (t: Date, alpha: number): string => {
  const timestamp = new Date(t).getTime();
  const now = new Date().getTime();
  const minutesDiff = Math.round((timestamp - now) / 1000 / 60);

  if (minutesDiff < -60 * 24) {
    return `rgba(255, 0, 0, ${alpha})`;
  } else if (minutesDiff < -60) {
    const time = Math.round(minutesDiff / 60);
    if (time === -1) return `rgba(247, 194, 106, ${alpha})`;
    if (time === -2) return `rgba(255, 149, 110, ${alpha})`;
    if (time === -3) return `rgba(237, 107, 38, ${alpha})`;
    if (time <= -4) return `rgba(255, 0, 0, ${alpha})`;
  }
  return '#00000024';
};

export interface HourOption {
  label: string;
  minuteOffset: number;
}
type HourIncrementOption = 30 | 60
export const getHourRanges = (increment: HourIncrementOption = 30) => {
  const hourOptions: HourOption[] = [];
  let minuteOffset = 0;
  for (let i = 0; i < 24; i += 1) {
    const hour12 = ((i + 11) % 12) + 1;
    const postfix = i > 11 ? "PM" : "AM";
    hourOptions.push({
      label: `${hour12}:00 ${postfix}`,
      minuteOffset,
    } as HourOption);
    if (increment === 30) {
      hourOptions.push({
        label: `${hour12}:${increment} ${postfix}`,
        minuteOffset: minuteOffset + increment,
      } as HourOption);
    }
    minuteOffset += 60;
  }
  return hourOptions;
};

export const isMerchandiseAvailable = (merchandise: Merchandise | undefined) => {
  if (!merchandise) return false;

  const currentDailyStock = path(['inventory', 'dailyCurrentStock'], merchandise);
  if (isEmptyOrNil(currentDailyStock)) return true;

  return currentDailyStock > 0;
};

export const userIsOneMOperator = (user?: User) =>
  user?.role === IdentityRole.OneMOperator || user?.role === IdentityRole.OneMOperatorAdmin;

export const userIsStaff = (user?: User) => user?.role === IdentityRole.MerchantStaff;

export const userIsAdmin = (user?: User) => user?.role === IdentityRole.MerchantAdmin;

export const UserIsFinanceStaff = (user?: User, staff?: MerchantStaff) =>
  user?.role === IdentityRole.MerchantStaff && staff?.role === StaffRole.FINANCE;

export const isNotificationSoundEnabled = (user: User | undefined) =>
  pathOr(true, ['applicationPreferences', 'notificationSound'], user);

// @ts-ignore
export const getPaddedNumber = (number: number, minimumIntegerDigits = 2) =>
  number.toLocaleString('en-US', {
    minimumIntegerDigits,
    useGrouping: false,
  });

export const getHighestDisplayOrderCategory = (categories: MerchandiseCategory[]) => {
  let highestNumber = 0;
  categories.map((data) => {
    if (data.displayOrder > highestNumber) {
      highestNumber = data.displayOrder;
    }
  });

  return highestNumber;
};

/**
 * 根据 keys 筛选同时排序
 * @param list 对象数组
 * @param keys 筛选排序字符串数组
 * @param propName 筛选排序字符串数组
 * @returns
 */
export const filterSortBy = <T extends Record<string, any>[]>(
  list: T,
  keys: (string | number)[] = [],
  propName: string = 'id'
) => {
  const items: Record<string, any>[] = [];
  keys.forEach((key) => {
    const item = find(propEq(propName, Number(key)), list);
    if (item) items.push(item);
  });
  return items as T;
};

type FormattedSpecialTimings = {
  [key: string]: Array<SpecialHours>;
};

export const getSpecialTimings = (customizations: SpecialHours[]): FormattedSpecialTimings => {
  const createDayOfWeekGroups = (customization: SpecialHours) =>
    customization.dayOfWeek || customization.specificDate;
  const groupedHours = pipe(
    groupBy(createDayOfWeekGroups),
    mapObjIndexed((value: Array<SpecialHours>, day: string) =>
      sort(ascend(prop('fromMinOffset')), value)
    )
  )(customizations);

  return groupedHours;
};

export const getTimeLabelFromOffset = (timeOffset: number | null | undefined, format = 'p'): string => {
  return timeOffset == null ? 'N/A' : formatTime(new Date().setHours(0, timeOffset, 0), format);
};

export const hoursForDate = (merchant: Merchant, date: Date): null | SpecialHours[] | OperationHours => {
  const dayOfWeek = DAYS_OF_WEEK_FULL_NAMES[date.getDay()];
  const dateStr = formatDate(date, 'MM-dd');

  const { hours } = merchant;
  const noTimingsAvailable = !hours || (isEmptyOrNil(hours?.defaultFromMinOffset) && isEmptyOrNil(hours?.defaultToMinOffset) && isEmptyOrNil(hours.customizations))
  if (noTimingsAvailable) {
    return null;
  }

  if (hours?.customizations) {
    const formattedSpecialTimings = getSpecialTimings(hours.customizations);
    const hourGroups = formattedSpecialTimings[dateStr] || (dayOfWeek ? formattedSpecialTimings[dayOfWeek] : null);
    if (hourGroups) {
      return hourGroups;
    }
  }

  return hours;
}

// ['bite_sized_treats', 'kazka', 'kazka_popup']
const QUICK_ACTION_ENABLED_MERCHANTS: Array<string> = ['b_7bsx0ka2j', 'b_wfj9o5b71', 'b_q9zmwdt0j']

export const isQuickSmsActionEnabled = (merchant?: Merchant) => {
  if (!merchant) return false;
  const { id } = merchant;

  return QUICK_ACTION_ENABLED_MERCHANTS.includes(id)
}

export const isStaffOrderViewDetailedMode = (staff?: User) =>
  pathOr(true, ['applicationPreferences', 'orderDetailsCard'], staff);

export const daysOfWeek = new Map([
  [0, 'Monday'],
  [1, 'Tuesday'],
  [2, 'Wednesday'],
  [3, 'Thursday'],
  [4, 'Friday'],
  [5, 'Saturday'],
  [6, 'Sunday'],
]);

let reservationBaseURL = 'http://localhost:8000';
if (process.env.NODE_ENV === 'production') {
  reservationBaseURL = 'https://api.1m.app';
  if (process.env.REACT_APP_IS_STAGING === '1') {
    reservationBaseURL = 'https://staging.api.1m.app';
  }
  if (process.env.REACT_APP_IS_WH_STAGING === '1') {
    reservationBaseURL = 'https://www.staging.reservation-console.1m.app';
  }
}
export const reservationURL = reservationBaseURL

export const getMerchandiseDispatchType = (formType: ServiceFormType, isMerchandiseAvailable: boolean, isTrial=false) => {
  if(formType === ServiceFormType.package && !isMerchandiseAvailable) {
    return ADD_ONE_PACKAGE;
  }

  if(formType === ServiceFormType.membership && !isMerchandiseAvailable && !isTrial) {
    return ADD_ONE_MEMBERSHIP;
  }

  if(formType === ServiceFormType.membership && !isMerchandiseAvailable && isTrial) {
    return ADD_ONE_TRIAL;
  }

  if(formType === ServiceFormType.package && isMerchandiseAvailable) {
    return EDIT_ONE_PACKAGE;
  }

  if(formType === ServiceFormType.membership && isMerchandiseAvailable && !isTrial) {
    return EDIT_ONE_MEMBERSHIP;
  }

  if(formType === ServiceFormType.membership && isMerchandiseAvailable && isTrial) {
    return EDIT_ONE_TRIAL;
  }

  return ADD_ONE_PACKAGE;
}


export * from '../bos_common/src/utils';
