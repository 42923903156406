import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button, Checkbox, createStyles, DialogActions, DialogContent, Fab,
  List, ListItem, ListItemText, makeStyles, Stack, Theme, Typography,
} from '@material-ui/core';
import { join, pipe, pluck, uniq, without, filter } from 'ramda';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';

import { Merchant } from 'bos_common/src/types/MerchantType';
import { Order } from 'bos_common/src/types/OrderTypes';

import { AppContext } from '../../../context/AppContext';
import { formatDate, getTimeLabelFromOffset, hoursForDate, isEmptyOrNil } from '../../../utils';
import { MerchantBranchFilterType, MerchantOrdersContext } from '../../../context/MerchantOrders/MerchantOrdersContext';
import { isParentMerchant } from '../../../utils/merchantUtils';
import { matchBranchFilter } from '../../../context/MerchantOrders/MerchantOrdersContextProvider';
import CustomDialog from '../../../components/common/CustomDialog';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogContent: {
    maxHeight: '400px',
    minHeight: '150px',
    overflow: 'auto',
    padding: theme.spacing(0, 2),

    '&:first-of-type': {
      paddingTop: 0,
    },

    '& .MuiListItem-root': {
      cursor: 'pointer',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  storeSelector: {
    display: 'inline-flex',
    '& .pickup-locations-button': {
      position: 'relative',
      border: '1px solid',
      textTransform: 'capitalize',
      fontSize: '14px',
      fontColor: theme.palette.text.secondary,
      width: '200px',
      [theme.breakpoints.up('md')]: {
        width: '250px',
      },

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 32px)',
        marginBottom: theme.spacing(2),
      },

      '& span.label': {
        position: 'absolute',
        fontSize: '13px',
        top: -12,
        left: 10,
        color: 'rgb(0,0,0, 50%)',
        backgroundColor: '#f5faff'
      },

      '& .MuiButton-label': {
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    }
  }
}))

interface BranchSelectDialogProps {
  showDrawer: boolean,
  setShow: (_: boolean) => void,
  isLoading: boolean,
  merchantBranches: Merchant[],
}

const BranchSelectDialog = ({ showDrawer, setShow, isLoading, merchantBranches }: BranchSelectDialogProps) => {
  if (!showDrawer) return null;

  const { t } = useTranslation()
  const classes = useStyles()
  const { selectedBranches, setSelectedBranches, getFilteredOrdersList, selectedTab } = useContext(MerchantOrdersContext);
  const [ selected, setSelected ] = useState<MerchantBranchFilterType[]|undefined>()
  const selectedAll = useMemo(() => selected === undefined || !selected.find((filter) => !filter.selected), [selected]);

  const branchOrderCount = useMemo(() => {
    const agg = new Map<string, Map<number, number>>();
    if (showDrawer) {
      merchantBranches.forEach((merchant) => {
        agg.set(merchant.id, new Map<number, number>());
      })
      const ordersList = getFilteredOrdersList(selectedTab);
      ordersList.forEach((order) => {
        const d = new Date(order.toPickupTime);
        const ts = d.setHours(0,0,0,0);
        const mercMap = agg.get(order.merchantId);
        if (mercMap) {
          const count = mercMap.get(ts);
          mercMap.set(ts, count ? count + 1 : 1);
        } else {
          const mercMap1 = new Map<number, number>();
          mercMap1.set(ts, 1);
          agg.set(order.merchantId, mercMap1);
        }
      })
    }
    return agg;
  }, [showDrawer])

  useEffect(() => {
    const today = new Date();
    const flattened: MerchantBranchFilterType[] = [];
    branchOrderCount.forEach((branchMap, id) => {
      if (branchMap.size <= 0) {
        const branch = selectedBranches?.find((filter) => matchBranchFilter(id, today, filter));
        flattened.push({id, selected: !!(branch?.selected)});
      } else {
        branchMap.forEach((_, ts) => {
          const date = new Date(ts);
          const branch = selectedBranches?.find((filter) => matchBranchFilter(id, date, filter));
          flattened.push({id, date, selected: !!(branch?.selected)});
        })
      }
    })
    setSelected(flattened);
  }, [branchOrderCount]);



  const toggleDialog = () => {
    setShow(!showDrawer);
  }

  const getMerchantOpeningHours = (m: Merchant, date: Date) => {
    const hours = hoursForDate(m, date) || []
    const firstHoursBatchOnDate = hours[0]
    if (!firstHoursBatchOnDate) return '';
    return `${getTimeLabelFromOffset(firstHoursBatchOnDate.fromMinOffset)} - ${getTimeLabelFromOffset(firstHoursBatchOnDate.toMinOffset)}`
  }

  const getMerchantLabel = (m: Merchant, date?: Date): string => {
    const today = new Date();
    const storeDate = date ?? today;
    const dateString = isSameDay(today, storeDate) ? 'Today' : formatDate(storeDate, 'EEEE');
    const storeHours = getMerchantOpeningHours(m, storeDate);

    return  `${dateString} ${storeHours}`;
  }

  const isBranchSelected = (merchantId: string, date?: Date) => {
    const today = new Date();
    const storeDate = date ?? today;
    const filter = selected?.find((fil) => matchBranchFilter(merchantId, storeDate, fil));
    return filter === undefined || filter.selected;
  }

  const handleBranchSelect = (merchantId: string, date?: Date) => {
    if (!selected) return;

    if (merchantId === 'all') {
      setSelected(selected?.map((filter) => ({...filter, selected: !selectedAll})));
      return;
    }

    const today = new Date();
    const index = selected.findIndex((filter) => matchBranchFilter(merchantId, date||today, filter));
    if (index > -1) {
      selected[index].selected = !selected[index].selected;
      setSelected([...selected])
    } else {
      setSelected([...selected, {id: merchantId, date, selected: true }])
    }
  }

  const handleConfirmClick = () => {
    if (selected) {
      setSelectedBranches(selected);
    }
    toggleDialog();
  }

  return (
    <CustomDialog
      title={t('StoreSelectorLabel')}
      open={showDrawer}
      setOpen={setShow}
      onClose={toggleDialog}
      maxWidth="sm"
      fullWidth
      isLoading={isLoading}
      footerActions={
        <DialogActions sx={{ justifyContent: 'center', width: "100%", p: 0 }}>
          <Fab
            onClick={handleConfirmClick}
            disabled={isLoading || isEmptyOrNil(selected)}
            variant="extended"
            color="primary"
            sx={{ textTransform: "unset", pl: 7, pr: 7 }}
          >
            {t("Confirm")}
          </Fab>
        </DialogActions>
      }
    >
      <DialogContent className={classes.dialogContent}>
        <List>
          <ListItem
            key='all'
            onClick={() => handleBranchSelect('all')}
            disabled={isLoading}>
            <Checkbox color='primary' checked={selectedAll} />
            <ListItemText primary={t("StoreSelectorLabelAll")} />
          </ListItem>
          {merchantBranches.map((m: Merchant) => {
            const renderListItem = (date?: Date, noOfOrders: number = 0) => (
              <ListItem
                key={`${m.username}-${date}`}
                onClick={() => handleBranchSelect(m.id, date)}
                disabled={isLoading}
                sx={{ alignItems: 'flex-start' }}
              >
                <Checkbox color='primary' checked={isBranchSelected(m.id, date)} sx={{ pt: 0 }} />
                <Stack>
                  <ListItemText primary={m.officialName} sx={{ m: 0 }} />
                  <Typography variant='subtitle2' color="textSecondary">
                    {getMerchantLabel(m, date)}
                  </Typography>
                </Stack>
                <Typography align='right' sx={{ flex: 1, ml: 1 }} color="textSecondary" variant='subtitle2'>Orders: {noOfOrders}</Typography>
              </ListItem>
            )

            const mercMap = branchOrderCount.get(m.id);
            if (!mercMap || mercMap.size === 0) {
              return renderListItem();
            }

            const elements: JSX.Element[] = [];
            mercMap.forEach((count, ts) => elements.push(renderListItem(new Date(ts), count)));
            return elements;
          })}
        </List>
      </DialogContent>
    </CustomDialog>
  )
}

const BranchSelector = () => {
  const classes = useStyles()
  const { t } = useTranslation();

  const { merchant } = useContext(AppContext);
  const { merchantBranches, selectedBranches, loadingOrders } = useContext(MerchantOrdersContext);
  const [showSelectionDialog, setShowSelectionDialog] = useState(false);

  const getRenderValue = (selected: MerchantBranchFilterType[]) => {
    const selectedAll = !selected.find((filter) => !filter.selected);
    if (selectedAll) {
      return `All locations`;
    }

    const selectedIds: string[] = pluck('id', selected.filter((item) => item.selected));
    return pipe(
      filter((m: Merchant) => selectedIds.includes(m.id)),
      pluck('officialName'),
      uniq,
      without([undefined, '']),
      join(', ')
    )(merchantBranches)
  }

  /* don't render the store selector if
    - the merchant object is not loaded
    - does not have any branches
    - the merchant is not a parent merchant
  */
  if (!merchant || !isParentMerchant(merchant) || merchantBranches === undefined) return null;

  return (
    <div className={classes.storeSelector}>
      <Button
        variant="text"
        disabled={loadingOrders}
        color="inherit"
        onClick={() => setShowSelectionDialog(true)}
        className="pickup-locations-button">
        <span className='label'>{t('StoreSelectorLabel')}</span>
        {getRenderValue(selectedBranches || [])}
      </Button>
      <BranchSelectDialog
        showDrawer={showSelectionDialog}
        setShow={setShowSelectionDialog}
        merchantBranches={merchantBranches}
        isLoading={loadingOrders}
      />
    </div>
  )
}

export default BranchSelector;