import React from "react";
import { Alert } from "@material-ui/core";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import useAxios from "axios-hooks";

import PageHeader from "bos_common/src/components/PageHeader"
import SimpleLoader from "bos_common/src/components/SimpleLoader";
import { ShortURL } from "../bos_common/src/types/ShortURLType";
import RenderMerchantQRCode from "../components/QRCode/MerchantQRCode";
import { FullscreenPaper } from "bos_common/src";
import { getMarketPlaceURL } from "bos_common/src/services/urls";

interface IShortenURLPageParams {
  identifier: string,
}

const ShortURLPage = (): React.ReactElement => {
  const history = useHistory();
  const { identifier } = useParams<IShortenURLPageParams>();

  const [{data, loading}] = useAxios<ShortURL>(
    { url: '/shorten-url', method: 'get', params: { identifier } },
  );

  return (
    <div className="container">
      <SimpleLoader loading={loading} />
      <PageHeader title={"Shortened URL"} />
      <FullscreenPaper>
        { data && data.redirectURL &&
          <RenderMerchantQRCode
            url={`${getMarketPlaceURL()}/go/${identifier}`}
            title={`${data.identifier} final url: ${data.redirectURL}`}
            fileName={data.identifier}
          />
        }
        { !data && !loading &&
          <Alert sx={{m: 2}} severity={"warning"}>
            Cannot find URL &quot;{identifier}&quot;
          </Alert>
        }
      </FullscreenPaper>
    </div>
  );
}

export default ShortURLPage;