import React, { useCallback, useContext, useMemo, useState } from "react"
import {
  createStyles, DialogActions, DialogContent,
  makeStyles, MenuItem, MenuList, Theme, Box, Typography, Checkbox, Fab, Tooltip
} from "@material-ui/core";
import { Search, WarningAmber } from "@material-ui/icons";
import { Else, If, Then } from "react-if";
import { append, includes, pipe, pluck, propOr, without } from "ramda";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MerchantCustomer } from "bos_common/src/types/crm/MerchantCustomerType";
import InputSearchBox from "bos_common/src/components/InputSearchBox";
import axios from "bos_common/src/services/backendAxios";
import { UserContext } from "bos_common/src/context/UserContext";
import CustomerScheduleEntry, { AttendeeStatus, ChangeAppliedTo } from "bos_common/src/types/crm/CustomerScheduleEntryType";
import { EventRepetitionOptions } from "bos_common/src/types/crm/CalendarScheduleType";

import { getAPIErrorMessage, getAuthHeaders, isEmptyOrNil } from "../../utils";
import { AppContext } from "../../context/AppContext";
import { NotificationSeverity } from "../../types/NotificationSlice";
import CustomDialog from "../common/CustomDialog";

import { CalendarContext } from "./context/CalendarContext";
import RecurringEventDialog from "./RecurringEventDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),

      '& .customers-list': {
        boxShadow: "0px 3px 6px rgb(0 0 0 / 7%)",
        margin: theme.spacing(2, 0),
        maxHeight: 300,
        overflow: 'auto'
      },

      '& .menu-item': {
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        gridGap: theme.spacing(2),

        '&:last-child': {
          borderBottom: 0,
        },

        '& span': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      },
    }
  })
)

export type ManageAttendeeDialogProps = {
  isOpen: boolean,
  setOpen: (_: boolean) => void,
  isEditMode?: boolean,
}

const ManageAttendeeDialog = (props: ManageAttendeeDialogProps): JSX.Element | null => {
  const { isOpen, setOpen, isEditMode = false } = props;

  if (!isOpen) return null;

  const { setValue, watch, trigger } = useFormContext();
  const attendeesList: CustomerScheduleEntry[] = watch('attendees');
  const watchService = watch('service');
  const currentService = !isEmptyOrNil(watchService) && JSON.parse(watchService)

  const currentAttendeesList = attendeesList.map((i: CustomerScheduleEntry) => Number(i.customerId));

  const [searchQuery, setSearchQuery] = useState<string>();
  const [selectedCustomerIds, setSelectedIds] = useState<number[]>(currentAttendeesList ?? [])
  const [removedCustomerIds, setRemovedIds] = useState<number[]>([])
  const [showRecurringEventDialog, setShowRecurringEventDialog] = useState<boolean>(false);
  const [processing, setProcessing] = useState(false);
  const [changesAppliedTo, setChangesAppliedTo] = useState<ChangeAppliedTo>(ChangeAppliedTo.currentEvent);

  const { t } = useTranslation()
  const classes = useStyles();

  const { customersList = [], selectedEvent, fetchCalendarEvents } = useContext(CalendarContext)
  const { merchant, triggerNotification } = useContext(AppContext)
  const { token } = useContext(UserContext)

  const isRecurringEvent = selectedEvent?.calendarSchedule?.repeat !== EventRepetitionOptions.once

  const toggleDialog = () => setOpen(!isOpen);

  const isCustomerSelected = useCallback(
    (customerId: number) => Boolean(selectedCustomerIds.find(item => item === customerId)),
    [selectedCustomerIds],
  )

  // Filter List based on search query
  const filteredCustomersList: MerchantCustomer[] = useMemo(() => {
    if (!searchQuery || isEmptyOrNil(searchQuery)) {
      // sort selected customers at the top of list
      return customersList.map((i) => ({ ...i, displayOrder: isCustomerSelected(i.id) ? i.id : 0 }))
    }

    return customersList.filter((customer) => {
      return customer.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
    })
  }, [customersList, searchQuery])

  const handleMenuItemClick = (customerId: number) => {
    let selectedItems = [];
    let removedItems = removedCustomerIds;

    if (selectedCustomerIds.includes(customerId)) {
      selectedItems = without([customerId], selectedCustomerIds)

      if (currentAttendeesList.includes(customerId)) {
        removedItems = append(customerId, removedCustomerIds)
      }
    } else {
      selectedItems = append(customerId, selectedCustomerIds)
    }

    if (removedCustomerIds.includes(customerId)) {
      removedItems = without([customerId], removedCustomerIds)
    }

    setSelectedIds(selectedItems)
    setRemovedIds(removedItems);
  }

  const getSelectedCustomers = (selectedCustomerIds: number[]) => {
    const selectedCustomers = customersList.filter(i => selectedCustomerIds.includes(i.id))
    const attendees: Partial<CustomerScheduleEntry>[] = selectedCustomers.map(i => ({
      customer: i,
      customerId: i.id,
      status: AttendeeStatus.CREATED
    }))
    return attendees;
  }

  const onUpdateAttendees = () => {
    setProcessing(true)
    axios.put(`/merchants/${merchant?.id}/calendar/event/${selectedEvent?.id}/attendees`, {
      addedAttendees: selectedCustomerIds,
      removedAttendees: removedCustomerIds,
      changeAppliedTo: changesAppliedTo
    }, {
      headers: getAuthHeaders(token)
    })
      .then(() => {
        setValue('attendees', getSelectedCustomers(selectedCustomerIds));
        trigger('attendees')
        fetchCalendarEvents();
        triggerNotification(true, t('Calender_ChangeAttendeeSuccess'), NotificationSeverity.SUCCESS);
        toggleDialog();
        setProcessing(false)
      })
      .catch((err) => {
        console.error({ err })
        setProcessing(false)
        triggerNotification(true, getAPIErrorMessage(err, t('Calender_ChangeAttendeeFailure')), NotificationSeverity.ERROR)
      })
  }

  const onAddAttendees = () => {
    setValue('attendees', getSelectedCustomers(selectedCustomerIds))
    toggleDialog();
  }

  const handleAddClick = () => {
    if (isEditMode && !showRecurringEventDialog && isRecurringEvent) {
      setShowRecurringEventDialog(true);
      return;
    }

    if (isEditMode)
      onUpdateAttendees()
    else {
      onAddAttendees()
    }
  }

  const disableSaveButton = (isEmptyOrNil(selectedCustomerIds) && isEmptyOrNil(removedCustomerIds));

  return (
    <CustomDialog
      title={t('Calendar_ManageAttendee')}
      open={isOpen}
      setOpen={toggleDialog}
      maxWidth="sm"
      fullWidth
      isLoading={processing}
      footerActions={
        <DialogActions sx={{ justifyContent: 'center', width: "100%" }}>
          <Fab
            onClick={handleAddClick}
            disabled={disableSaveButton}
            variant="extended"
            color="primary"
            sx={{ textTransform: "unset", pl: 7, pr: 7 }}
          >
            {t("Save")}
          </Fab>
        </DialogActions>
      }
    >
      <DialogContent className={classes.root}>
        <Box className="search-container">
          <InputSearchBox
            placeholder={t("Calender_SearchCustomers")}
            onChangeQuery={(s) => setSearchQuery(s)}
            leftChild={<Search />}
          />
        </Box>
        <If condition={isEmptyOrNil(filteredCustomersList)}>
          <Then>
            <Typography variant="body1" sx={{ my: 2 }}>
              {t("Customers_EmptyList")}
            </Typography>
          </Then>
          <Else>
            <MenuList className="customers-list">
              {filteredCustomersList.map((customer) => {
                const isSelected = isCustomerSelected(customer.id)

                const isPackageSubscribed = pipe(
                  propOr({}, 'customerSubscriptions'),
                  pluck('merchantPackageId'),
                  includes(currentService.value)
                )(customer)

                return (
                  <MenuItem
                    key={customer.id}
                    onClick={() => handleMenuItemClick(customer.id)}
                    className='menu-item'
                    selected={isSelected}
                  >
                    <Checkbox checked={isSelected} color="primary" />
                    {customer.displayName}

                    <If condition={currentService.type === "package" && !isPackageSubscribed}>
                      <Tooltip placement="left" title={t('Customer_PackageWarning') as string}>
                        <WarningAmber sx={{ marginLeft: "auto", color: "#ff9800" }} />
                      </Tooltip>
                    </If>
                  </MenuItem>
                )
              })}
            </MenuList>
          </Else>
        </If>

        <If condition={showRecurringEventDialog}>
          <RecurringEventDialog
            title={t('Calendar_EditRecurringEvent')}
            open={showRecurringEventDialog}
            setOpen={setShowRecurringEventDialog}
            isSubmitButtonEnabled={true}
            handleFormSubmit={handleAddClick}
            changesAppliedTo={changesAppliedTo}
            setChangesAppliedTo={setChangesAppliedTo}
          />
        </If>
      </DialogContent>
    </CustomDialog>
  )
}

export default ManageAttendeeDialog;