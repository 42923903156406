import React, { useContext } from "react";
import { CardContent, CardHeader, Typography, useTheme } from '@material-ui/core';
import { utcToZonedTime } from 'date-fns-tz';
import { format, getDay, getHours } from 'date-fns';
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist";
import { useTranslation } from "react-i18next";

import { MerchantSalesStats } from "../../bos_common/src/types/MerchantSalesStatsType";
import { MerchantStatsContext } from "../../context/MerchantStats/MerchantStatsContext";

import { MerchantAggregatedSalesStats } from "../../services/models"
import { getClientTimezone, isEmptyOrNil } from "../../utils";

import { MerchantStatsPeriod } from "./types";
import { useChartStyles, generateSalesChartSeries, getBarChartLayoutConfig } from "./chartUtils";

// Fix for Plotly memory leak
const Plot = createPlotlyComponent(Plotly);
const COMPARE_PRO_RATE = false;

interface MerchantSalesChartProps {
  lastWeekSalesData: MerchantAggregatedSalesStats,
  currentWeekSalesData: MerchantAggregatedSalesStats,
  timeZone: string | undefined,
  dataKey: string,
  chartLabel?: string,
  chartTitle?: string,
  chartDescription?: string,
}

const MerchantSalesChart = ({
  lastWeekSalesData,
  currentWeekSalesData,
  timeZone,
  chartLabel,
  chartTitle,
  chartDescription,
  dataKey = ''
}: MerchantSalesChartProps) => {
  const theme = useTheme();
  const classes = useChartStyles()
  const { statsTimePeriod } = useContext(MerchantStatsContext);
  const currentTimezone = getClientTimezone()
  const finalTimeZone = timeZone || currentTimezone;
  const { t } = useTranslation();

  const isLastWeekDataAvailable = lastWeekSalesData && !isEmptyOrNil(lastWeekSalesData?.stats)
  const isCurrentWeekDataAvailable = currentWeekSalesData && !isEmptyOrNil(currentWeekSalesData?.stats)

  // blankslate
  if (!isLastWeekDataAvailable && !isCurrentWeekDataAvailable) {
    return (
      <CardHeader
        className={classes.statsChartBlankslate}
        subheader={t("CustomerInsights_NoData")}
      />
    );
  }

  const { stats: lwStats } = lastWeekSalesData;
  const { stats: cwStats } = currentWeekSalesData;

  const isCustomStats = statsTimePeriod === MerchantStatsPeriod.Custom
  const isWeeklyStats = statsTimePeriod === MerchantStatsPeriod.Week

  const lwSalesTitle = t(`Sales_LastWeek`);
  const cwSalesTitle = t('Sales_SalesDaily');

  const calculateSalesDataPoint = (key: string) => (stat: MerchantSalesStats) => {
    const zonedTime = utcToZonedTime(new Date(stat.timePoint), finalTimeZone).getTime()
    let xAxisData: number | Date | string = isWeeklyStats ? getDay(zonedTime) : getHours(zonedTime)
    if (isCustomStats) { xAxisData = format(zonedTime, 'MM/dd/yy') }

    return {
      name: zonedTime,
      x: xAxisData,
      y: Number(stat[key])
    }
  }

  const lwSalesSeries = generateSalesChartSeries<MerchantSalesStats>(
    lwStats,
    statsTimePeriod,
    calculateSalesDataPoint(dataKey),
  )

  const cwSalesSeries = generateSalesChartSeries<MerchantSalesStats>(
    cwStats,
    statsTimePeriod,
    calculateSalesDataPoint(dataKey),
  )

  const alterChartSeries = () => {
    const { avgTable: lwAvgTable } = lwSalesSeries;
    const { avgTable: cwAvgTable } = cwSalesSeries;

    // exit if one of the series (last week or current week doesn't exist)
    if (!lwAvgTable || !cwAvgTable) {
      return;
    }

    // build unique key list using Set
    const xAxesSet = new Set<string>([
      ...Object.keys(lwAvgTable),
      ...Object.keys(cwAvgTable),
    ]);

    [...xAxesSet].forEach((x: string) => {

      if (!lwAvgTable[x] || !cwAvgTable[x]) {
        return;
      }

      if (lwAvgTable[x].hrsDuration === cwAvgTable[x].hrsDuration) {
        return;
      }

      // alter last week series if the hour duration different between last week and current week
      // make last week use the pro rate/average per hour * current week duration
      const index: number = lwSalesSeries?.lineChart?.x.findIndex((item) => item === x) as number;
      if (lwSalesSeries?.lineChart?.y[index]) {
        lwSalesSeries.lineChart.y[index] = lwAvgTable[x].revenueAvg * cwAvgTable[x].hrsDuration;
      }

      if (lwSalesSeries?.barChart?.y[index]) {
        lwSalesSeries.barChart.y[index] = lwAvgTable[x].revenueAvg * cwAvgTable[x].hrsDuration;
      }
    });
  }

  if (COMPARE_PRO_RATE) {
    alterChartSeries();
  }

  const lwSalesBarChartData: Plotly.Data = {
    x: lwSalesSeries?.barChart?.x,
    y: lwSalesSeries?.barChart?.y,
    name: lwSalesTitle,
    hovertemplate: `%{y}, %{x}`,
    type: 'bar',
    opacity: .7,
    marker: {
      color: theme.palette.grey[400],
    },
    legendgroup: lwSalesTitle
  };

  const lwSalesLineChartData: Plotly.Data = {
    x: lwSalesSeries?.lineChart?.x,
    y: lwSalesSeries?.lineChart?.y,
    name: '',
    hovertemplate: `%{x} %{y}`,
    type: 'scatter',
    mode: 'lines+markers',
    line: {
      shape: 'spline',
      dash: 'dash',
    },
    marker: {
      color: theme.palette.grey[400],
      line: {
        width: 2,
      }
    },
    showlegend: false,
    legendgroup: lwSalesTitle
  };

  const cwSalesBarChartData: Plotly.Data = {
    x: cwSalesSeries?.barChart?.x,
    y: cwSalesSeries?.barChart?.y,
    name: cwSalesTitle,
    hovertemplate: `%{x} %{y}<extra></extra>`,
    type: 'bar',
    opacity: .7,
    marker: {
      color: '#1AAA5D',
    },
    legendgroup: cwSalesTitle
  };

  const cwSalesLineChartData: Plotly.Data = {
    x: cwSalesSeries?.lineChart?.x,
    y: cwSalesSeries?.lineChart?.y,
    name: cwSalesTitle,
    hovertemplate: `%{x} %{y}<extra></extra>`,
    type: 'scatter',
    mode: 'lines+markers',
    line: {
      shape: 'spline',
      dash: 'dash',
    },
    marker: {
      color: '#1AAA5D',
      line: {
        width: 2,
      }
    },
    showlegend: false,
    legendgroup: cwSalesTitle
  }

  const data: Plotly.Data[] = [
    ...(!isCustomStats ?
      [lwSalesBarChartData,
        lwSalesLineChartData]
      : []),
    cwSalesBarChartData,
    cwSalesLineChartData
  ];

  const layout: Plotly.Layout = getBarChartLayoutConfig({
    barmode: 'group',
    autosize: true,
  }, isWeeklyStats);

  const config: Partial<Plotly.Config> = {
    responsive: false,
    displayModeBar: false,
  }

  return (
    <div className="charts">
      <CardContent>
        <Typography variant="body1" gutterBottom>{chartLabel}</Typography>
        <Typography variant="h4">{chartTitle}</Typography>
        <Typography variant="body2">{chartDescription}</Typography>
      </CardContent>
      <Plot
        divId="merchant_orders"
        data={data}
        layout={layout}
        config={config}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

export default MerchantSalesChart;
