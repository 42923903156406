import { MerchandiseUnit } from "../types/MerchandiseType";
import { pluralString } from "../utils";

// render simple price with proper currency
const renderPrice = (value: number | undefined, currency = 'USD'): string => {
  if (value === undefined) {
    return '';
  }

  const valueString = Number(value).toLocaleString(
    navigator.language,
    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  );

  switch (currency) {
    case 'USD':
    default:
      return `$${valueString}`;
  }
};

export const UNIT_TO_DISPLAY = {
  [MerchandiseUnit.DEFAULT]: null,
  [MerchandiseUnit.HOUR]: 'hour',
  [MerchandiseUnit.MINUTES]: 'min',
  [MerchandiseUnit.KILOGRAM]: 'kg',
  [MerchandiseUnit.POUND]: 'lb',
  [MerchandiseUnit.EACH]: 'each',
  [MerchandiseUnit.BAG]: 'bag',
  [MerchandiseUnit.CASE]: 'case',
  [MerchandiseUnit.PC]: 'pc',
  [MerchandiseUnit.BOX]: 'box',
  [MerchandiseUnit.YEAR]: 'year',
  [MerchandiseUnit.MONTH]: 'month',
  [MerchandiseUnit.CLASS]: 'class',
};

const renderUnit = (unit = MerchandiseUnit.DEFAULT, unitQuantity = 1): string => {
  const unitStr = UNIT_TO_DISPLAY[unit];
  if (!unitStr) {
    return '';
  }

  if (unitQuantity === 1) {
    return unitStr;
  }

  let plural;

  switch (unit) {
    case MerchandiseUnit.KILOGRAM:
    case MerchandiseUnit.POUND:
    case MerchandiseUnit.EACH:
      plural = unitStr
      break;
    case MerchandiseUnit.BOX:
      plural = 'boxes'
      break;
    case MerchandiseUnit.CLASS:
      plural = 'classes'
      break;
    default:
      break;
  }

  return pluralString(unitQuantity, unitStr, plural);
}

export const renderPriceWithUnit = (
  value: number | undefined,
  currency = 'USD',
  unit = MerchandiseUnit.DEFAULT,
  unitQuantity = 1): string => {

  const renderedUnit = renderUnit(unit, unitQuantity);
  return renderedUnit
    ? `${renderPrice(value, currency)} / ${renderedUnit}`
    : renderPrice(value, currency);
}

export default renderPrice;
