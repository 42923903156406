import { BaseIncrement } from "../BaseIncrement";
import { Merchandise } from "../MerchandiseType";
import { Merchant } from "../MerchantType";

export enum MembershipType {
  yearly = 'yearly',
  monthly = 'monthly',
  trial = 'trial',
}

export interface MerchantMembership extends BaseIncrement {
  photos?: string[];
  videos?: string[];
  merchant?: Merchant;
  merchantId: string;

  price: number;
  live: boolean;
  type: MembershipType;
  creditLimit?: number;
  name: string;
  description?: string;
  deletedAt?: Date;
  merchandises: Merchandise[];
}

export default MerchantMembership;