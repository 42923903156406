import React, { useContext, useRef, useState } from 'react'
import { Theme, useMediaQuery, createStyles, makeStyles } from '@material-ui/core';
import { If, Then, Else } from 'react-if';
import { Transition } from 'react-transition-group';
import { CellMeasurerCache, List, Collection, AutoSizer, CellMeasurer } from 'react-virtualized';
import { groupBy, head, keys, pipe, values, filter, map, all, pathOr } from 'ramda';

// src
import { AppContext } from '../../../context/AppContext';
import { MerchantOrdersContext } from '../../../context/MerchantOrders/MerchantOrdersContext';
import { LineItem, Order, OrderStatus } from '../../../services/models'

import OrderCard from '../../../components/Order/OrderCard'
import OrderCardActions from '../../../components/Order/OrderCardActions';
import OrderAdminOperationInfo from '../../../components/Order/OrderRefundedByUserInfo';
import OrderActionsDrawer from '../../../components/Order/OrderActionsDrawer';

import { clearLineItemsFromLocalStorage, getAllLineItemsFromLocalStorage, getLocalStorageLineItemKey, isEmptyOrNil, isStaffOrderViewDetailedMode, MappedUsers } from '../../../utils';
import { isOrderFulfilled } from '../../../utils/orderUtils';
import { NotificationSeverity } from '../../../types/NotificationSlice';
import { UserContext } from '../../../bos_common/src/context/UserContext';
import OrderMarkAsPaidDialog from '../../../components/Order/OrderMarkAsPaidDialog';
import { isParentMerchant } from '../../../utils/merchantUtils';

const useStyles = makeStyles(() =>
  createStyles({
    collection: {
      // @ts-ignore
      overflowY: 'auto !important'
    }
  })
);

const useAnimateStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      '& .transition-container': {
        transition: '1s',
        /* Hidden init state */
        opacity: 0,
        transform: 'scale(0.1)',
        '&.enter, &.entered': {
          /* Animate in state */
          opacity: 1,
          transition: '0.5s',
          transform: `scale(1)`
        },
        '&.exited, &.exiting': {
          /* Animate in state */
          opacity: 0,
          transform: `scale(0.1)`
        },
      }
    }
  })
);

enum PopupType {
  ACTION_PANEL = 'action_panel',
  MARK_AS_PAID_MODAL = 'mark_as_paid_modal',
}

interface AnimateOrderCardProps {
  children: React.ReactElement | Array<React.ReactElement>,
  orderId: string,
  cardToAnimate?: string
}

const AnimateOrderCard = (props: AnimateOrderCardProps) => {
  const { cardToAnimate, orderId } = props
  const classes = useAnimateStyles({})

  return <div className={classes.root}>
    <Transition unmountOnExit timeout={500} in={cardToAnimate !== orderId}>
      {(state) => (
        <div className={`transition-container ${state}`}>
          {props.children}
        </div>
      )}
    </Transition>
  </div>
}

const areAllLineItemsChecked = (order: Order) => {
  const lineItemKeys = getAllLineItemsFromLocalStorage(order)
  if (isEmptyOrNil(lineItemKeys)) return false

  const { lineItems } = order

  const selectedItemKeys = pipe(
    filter((data: any) => head(values(data)) === 'true'),
    groupBy((data: any) => {
      const key = head(keys(data))
      const [index, orderId, itemId] = key.split('-')
      return itemId
    }),
    map((group: { [key: string]: Array<string> }) => group.length)
  )(lineItemKeys);

  const hasItemBeenCompleted = (item: LineItem) => {
    const selectedQuantity = selectedItemKeys[item.id]
    return (item.quantity === selectedQuantity)
  }
  const allItemsSelected = all(hasItemBeenCompleted, lineItems)

  return allItemsSelected
}

interface RenderOrderCardProps {
  order: Order,
  style: any,
  togglePopup: (popup: PopupType, open: boolean, order?: Order) => void,
  cardToAnimate: any,
  toUsers: MappedUsers,
  detailedMode?: boolean,
  showMerchantInfo?: boolean,
}

const RenderOrderCard = ({ order, style, togglePopup, cardToAnimate, toUsers, detailedMode, showMerchantInfo }: RenderOrderCardProps) => {

  const { triggerNotification } = useContext(AppContext);
  const { setOrderStatus, isUserOneMOperator, merchants: mappedMerchants } = useContext(MerchantOrdersContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const _isOrderFulfilled = isOrderFulfilled(order)

  const isItemChecked = (item: LineItem, index: number) => {
    if (_isOrderFulfilled) {
      return true;
    }
    const currentItemlocalStorageKey = getLocalStorageLineItemKey(item, order, `item_${index}`);
    const lsValue = localStorage.getItem(currentItemlocalStorageKey);
    if (lsValue) {
      return JSON.parse(lsValue);
    }
    return false;
  }

  const onCheckboxChangeAction = ({ checkboxValue, order, item, index }: { checkboxValue: boolean, order: Order, item: LineItem, index: number }) => {
    const currentItemlocalStorageKey = getLocalStorageLineItemKey(item, order, `item_${index}`);
    localStorage.setItem(currentItemlocalStorageKey, JSON.stringify(checkboxValue));
  }

  const handleCardActionClick = async (id: string, status: OrderStatus, order?: Order, options?: { [key: string]: any }) => {
    const setStatusSuccess = (message?: string) => {
      triggerNotification(true, message || `Order for ${order?.userDisplayName} has been succesfully completed!`, NotificationSeverity.SUCCESS);
      clearLineItemsFromLocalStorage(order)
    }

    if (status === OrderStatus.OPEN_CHECK) {
      togglePopup(PopupType.MARK_AS_PAID_MODAL, true, order);
    } else if (status === OrderStatus.PAID) {
      cardToAnimate.current = order?.id;
      // if the action is performed by an operator, move the order to the confirmed status
      const nextOrderStatus = isUserOneMOperator ? OrderStatus.CONFIRMED : OrderStatus.FULFILLED
      const isSuccess = await setOrderStatus({ id, nextOrderStatus, options })

      if (isSuccess) setStatusSuccess()
      cardToAnimate.current = undefined;
    }
    else if (status === OrderStatus.PAID_EXTERNALLY || status === OrderStatus.VOIDED) {
      togglePopup(PopupType.ACTION_PANEL, true, order);
    }
    else if (status === OrderStatus.FULFILLED) {
      togglePopup(PopupType.ACTION_PANEL, true, order);
    }
  }

  return (
    <div key={order.id} style={style}>
      <AnimateOrderCard
        key={order.id}
        orderId={order.id}
        cardToAnimate={cardToAnimate.current}
      >
        <OrderCard
          order={order}
          key={order.id}
          user={order.userId ? toUsers[order.userId] : undefined}
          merchant={mappedMerchants[order.merchantId]}
          fullWidth={isMobile}
          expandedLineItems={!isUserOneMOperator && order.status !== OrderStatus.FULFILLED}
          readOnly={isUserOneMOperator}
          detailedMode={detailedMode}
          showMerchantInfo={showMerchantInfo}
          orderItemProps={{
            onCheckboxChangeAction,
            disableCheckbox: _isOrderFulfilled,
            defaultChecked: isItemChecked,
            strikeThrough: true,
          }}>
          <OrderAdminOperationInfo order={order} />
          <OrderCardActions
            order={order}
            onActionClick={handleCardActionClick}
            handleShowMoreClick={() => togglePopup(PopupType.ACTION_PANEL, true, order)}
          />
        </OrderCard>
      </AnimateOrderCard>
    </div>
  )
}

const RenderMerchantOrders = () => {
  const classes = useStyles({})
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { user: staff } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext);
  const { merchant } = useContext(AppContext);
  const detailedMode = isStaffOrderViewDetailedMode(staff);
  const showMerchantInfo = merchant && isParentMerchant(merchant);
  const { setOrderStatus, ordersList: orders, users: toUsers } = useContext(MerchantOrdersContext);
  const cardToAnimate = useRef<string | undefined>();
  const [drawerState, setDrawerState] = useState<{ open: boolean, order: Order | undefined }>({ open: false, order: undefined });
  const [mapState, setMAPDialogState] = useState<{ open: boolean, order: Order | undefined }>({ open: false, order: undefined });

  const togglePopup = (popup: PopupType, open: boolean, order?: Order | undefined) => {
    switch (popup) {
      case PopupType.ACTION_PANEL:
        setDrawerState(s => ({ ...s, open, ...(order && { order }) }))
        break;
      case PopupType.MARK_AS_PAID_MODAL:
        setMAPDialogState(s => ({ ...s, open, ...(order && { order }) }))
        break;
    }
  }

  const handleSetOrderStatus = async (id: string, nextOrderStatus: OrderStatus, onSuccessCallback?: () => void) => {
    cardToAnimate.current = id;
    const isSuccess = await setOrderStatus({ id, nextOrderStatus })

    if (isSuccess && onSuccessCallback) onSuccessCallback()
    cardToAnimate.current = undefined;
  }

  const onCloseCheckSuccessCb = async (order: Order) => {
    cardToAnimate.current = order.id;
    const isSuccess = await setOrderStatus({ id: order.id, nextOrderStatus: OrderStatus.FULFILLED })
    if (isSuccess) {
      triggerNotification(true, `Order for ${order?.userDisplayName} has been succesfully completed!`, NotificationSeverity.SUCCESS);
      clearLineItemsFromLocalStorage(order)
    }
    cardToAnimate.current = undefined;
  };

  // Defines a pattern of sizes and positions for cells
  const _cellSizeAndPositionGetter = (height: number) => ({ index }: { index: number }) => {
    const columnCount = orders.length
    const columnPosition = index % (columnCount || 1);
    const GUTTER_SIZE = 15;

    const width = detailedMode ? 400 : 300;
    const x = (columnPosition * (GUTTER_SIZE + width)) + 5;

    return { height, width, x, y: 5 }
  }

  const cache = new CellMeasurerCache({
    fixedWidth: true
  });

  // for list view
  const rowRenderer = (OrderCardProps: any) => ({ index, key, style, parent }: any) => {
    const order: Order = orders[index];
    if (isEmptyOrNil(order)) return null

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}>
        {() => (
          <RenderOrderCard
            key={key}
            order={{ ...order, index }}
            style={{ ...style, width: `calc(${style.width} - 16px)`, right: 0, top: style.top + 5, padding: 10 }}
            {...OrderCardProps}
          />
        )}
      </CellMeasurer>
    )
  }

  // for collection view
  const cellRenderer = (OrderCardProps: any) => ({ index, key, style }: any) => {
    const order: Order = orders[index];
    if (isEmptyOrNil(order)) return null

    return (
      <RenderOrderCard
        key={key}
        order={{ ...order, index }}
        style={style}
        {...OrderCardProps}
      />
    );
  };

  // Workaround to get the container height in case of Mobile View
  const OrderTabsElement = window.document.getElementById('order-tabs')
  const OrderCardProps = {
    cardToAnimate,
    togglePopup,
    toUsers,
    detailedMode,
    showMerchantInfo,
  }

  return (
    <>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <If condition={isMobile}>
              <Then>
                <List
                  rowCount={orders.length}
                  height={(OrderTabsElement?.offsetHeight ?? height) - 20}
                  width={width}
                  rowRenderer={rowRenderer(OrderCardProps)}
                  rowHeight={cache.rowHeight}
                  overscanRowCount={4} // just about the minimum we need to have smooth scrolling. authors suggest not going above 10 if possible
                />
              </Then>
              <Else>
                <Collection
                  cellCount={orders.length}
                  cellRenderer={cellRenderer(OrderCardProps)}
                  cellSizeAndPositionGetter={_cellSizeAndPositionGetter(height)}
                  className={classes.collection}
                  height={height}
                  horizontalOverscanSize={4}
                  verticalOverscanSize={0}
                  width={width}
                />
              </Else>
            </If>
          )
        }}
      </AutoSizer>
      <OrderActionsDrawer
        open={drawerState.open}
        order={drawerState.order}
        setOpen={(open) => togglePopup(PopupType.ACTION_PANEL, open)}
        handleSetOrderStatus={handleSetOrderStatus}
      />
      {mapState.order && merchant &&
      <OrderMarkAsPaidDialog
        open={mapState.open}
        setOpen={(open) => togglePopup(PopupType.MARK_AS_PAID_MODAL, open)}
        order={mapState.order}
        merchant={merchant}
        onCloseCheckSuccessCb={onCloseCheckSuccessCb}
      />}
    </>
  )
}

export default RenderMerchantOrders
