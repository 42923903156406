import {
  Box,
  CardHeader,
  createStyles,
  makeStyles, Theme,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import useAxios from 'axios-hooks';
import { addDays } from 'date-fns';
import { t } from 'i18next';
import { assoc, curry, find, map, propEq, propOr, when } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { Link } from 'react-router-dom';
import SimpleLoader from '../../../bos_common/src/components/SimpleLoader';
import { UserContext } from '../../../bos_common/src/context/UserContext';
import { MerchantSalesStats } from '../../../bos_common/src/types/MerchantSalesStatsType';
import AppToastMessage from '../../../components/common/AppToastMessage';
import OrderInfoDrawer from '../../../components/Order/OrderInfoDrawer';
import RenderOrdersHistory from '../../../components/Order/RenderOrderHistory';
import { AppContext } from '../../../context/AppContext';
import { Merchant, Order } from '../../../services/models';
import {
  getAuthHeaders, getClientTimezone, isEmptyOrNil
} from '../../../utils';
import { isParentMerchant } from '../../../utils/merchantUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .searchOrderContainer': {
        position: 'relative',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 32px)',
          margin: theme.spacing(2),
        },
        display: 'flex',

        '& .searchIcon': {
          position: 'absolute',
          top: '50%',
          left: 10,
          transform: 'translateY(-50%)',
        },

        '& .searchField': {
          padding: theme.spacing(2),
          paddingLeft: theme.spacing(5),
          borderRadius: 8,
          border: 0,
          background: grey[100],
          outline: 'none',
          width: '100%',
        },
      },
      '& .filterDropdownContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',

        '& .MuiSelect-selectMenu': {
          color: theme.palette.primary.main,
        },

        '& .filterLabel': {
          color: grey[600],
          padding: 10,
          marginRight: 10,
        },
        '& .searchType': {
          color: 'rgba(0,0,0,0.6)',

          '&:hover': {
            '&:not(.Mui-disabled):before': {
              borderBottom: 0,
            },
          },

          '&:before': {
            borderBottom: 0,
          },

          '&:after': {
            borderBottom: 0,
          },

          '& .MuiSelect-select:focus': {
            background: 'transparent',
          },
        },
      },
      '& .clearfix': {
        clear: 'both',
      },
      '& .capitalize': {
        textTransform: 'capitalize',
      },
    },
  })
);

type OrdersHistoryData = MerchantSalesStats & {
  orders: Array<Order>;
};

const updateOrdersHistory = curry(
  (orders: Array<Order>, timePoint: string, list: Array<OrdersHistoryData>) =>
    map(when(propEq('timePoint', timePoint), assoc('orders', orders)), list)
);

const SalesTransaction = () => {
  // const [search, setSearch] = useState('');
  const [ordersList, setOrdersList] = useState<Array<OrdersHistoryData>>();
  const [selectedTimePoint, setSelectedTimePoint] = useState<Date>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [openDrawer, setOpen] = useState<boolean>(false);
  const [expandedOrder, setOrder] = useState<Order>();

  const classes = useStyles();
  const { user, token } = useContext(UserContext);
  const { merchant } = useContext(AppContext);
  const [storeIds, setStoreIds] = useState<string[]>([]);

  const currentTimezone = getClientTimezone();
  const finalTimeZone = merchant?.timeZone || currentTimezone;

  const [{ data: timePointsList, loading: loadingDates }] = useAxios({
    url: `/merchants/${merchant?.id}/order-history/dates`,
    headers: getAuthHeaders(token),
  });

  const [{ loading: loadingHistory }, getOrdersHistory] = useAxios(
    {
      url: `/merchants/orders`,
      headers: getAuthHeaders(token),
    },
    { manual: true }
  );

  // fetch store branches
  const [{ }, fetchBranches] = useAxios<Merchant[]>(
    { url: `/merchants/${merchant?.id}/store-locations`,},
    { manual: true }
  );

  useEffect(() => {
    if (merchant) {
      setStoreIds([merchant.id]);
      if (isParentMerchant(merchant)) {
        fetchBranches({
          url: `/merchants/${merchant.id}/store-locations`,
          headers: getAuthHeaders(token),
        }).then((response) => {
          if (response.status === 200) {
            setStoreIds(response.data.map((m)=>m.id));
          }
        })
      }
    }
  }, [merchant])

  useEffect(() => {
    if (isEmptyOrNil(timePointsList)) return;

    let orders = timePointsList.map((stats: MerchantSalesStats) => ({
      ...stats,
      timePoint: new Date(stats.timePoint),
      orders: [],
    }));

    orders.sort(
      (o1: OrdersHistoryData, o2: OrdersHistoryData) =>
        o2.timePoint.getTime() - o1.timePoint.getTime()
    );

    setOrdersList(orders);
  }, [timePointsList]);

  const showOrdersHistory = (timePoint: Date, force?: boolean) => {
    const historyData = find(propEq('timePoint', timePoint), ordersList);
    const ordersData = propOr([], 'orders', historyData);

    if (force || (isEmptyOrNil(ordersData) && historyData?.totalOrders > 0)) {
      const fromDate = timePoint;
      const toDate = addDays(fromDate, 1);

      getOrdersHistory({
        params: {
          minTimestampOffset: fromDate.toUTCString(),
          maxTimestampOffset: toDate.toUTCString(),
          storeIds,
          limit: historyData.totalOrders + 1,
          filterKey: 'createdAt',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const updatedOrdersList = updateOrdersHistory(response.data, timePoint, ordersList);
            setOrdersList(updatedOrdersList);
          }
        })
        .catch(() => setShowAlert(true));
    }
  };

  const refreshHistory = (_: Order[]) => {
    // TODO: update particular orders instead of refetching everything
    if (selectedTimePoint) {
      setTimeout(() => {
        showOrdersHistory(selectedTimePoint, true);
      }, 100);
    }
  };


  // const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setSearch(event.target.value);
  // };

  return (
    <div className={classes.root}>
      <SimpleLoader loading={loadingDates} />
      <AppToastMessage
        showAlert={showAlert}
        onCloseAlert={setShowAlert}
        errorMessage={t('OrderHistory_UnableToFetchOrders')}
        error
      />
      <If condition={isEmptyOrNil(timePointsList)}>
        <Then>
          <If condition={!loadingDates}>
            <Then>
              <CardHeader
                subheader={
                  <>
                    {t('OrderHistory_NoEntires')}&nbsp;
                    <Link to="./">{t('Here')}</Link>
                  </>
                }
              />
            </Then>
          </If>
        </Then>
        <Else>
          <Box>
            {/* <Box className="searchOrderContainer">
                <Search className="searchIcon" sx={{ color: '#626262' }} />
                <input
                  className="searchField"
                  placeholder="Search Transactions"
                  type="text"
                  value={search}
                  onChange={handleSearchChange}
                />
              </Box> */}
            <div className="clearfix" />
            <If condition={!isEmptyOrNil(ordersList)}>
              <Then>
                {ordersList?.map((item: OrdersHistoryData) => (
                  <RenderOrdersHistory
                    {...item}
                    key={item.timePoint.toUTCString()}
                    user={user}
                    timePoint={item.timePoint}
                    timeZone={finalTimeZone}
                    setOpen={setOpen}
                    loading={loadingHistory}
                    setOrder={(order) => {
                      setSelectedTimePoint(item.timePoint);
                      setOrder(order);
                    }}
                    showOrdersHistory={showOrdersHistory}
                  />
                ))}
              </Then>
              <Else>
                <Typography sx={{ textAlign: "center", mt: 1 }}>No orders for given/selected date(s)</Typography>
              </Else>
            </If>
          </Box>
        </Else>
      </If>
      <OrderInfoDrawer
        open={openDrawer}
        setOpen={setOpen}
        order={expandedOrder}
        refreshHistory={refreshHistory}
      />
    </div>
  );
};

export default SalesTransaction;
