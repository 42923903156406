import React, { SetStateAction } from 'react';
import { DateOptions, MerchantStatsPeriod } from '../../components/Stats/types';

export interface MerchantStatsContextState {
  statsTimePeriod: MerchantStatsPeriod;
  setStatsTimePeriod: (m: MerchantStatsPeriod) => void;
  statsDateRange?: DateOptions;
  setStatsDateRange: (d: DateOptions) => void;
  report: {
    render?: () => {
      report?: Record<string, any>[];
      reportDetails?: {
        titleRow: string[];
        table: Record<string, any>[];
      };
    };
  };
  setReport: (d: MerchantStatsContextState['report']) => void;
}

export const MerchantStatsContext = React.createContext<MerchantStatsContextState>({
  statsTimePeriod: MerchantStatsPeriod.Day,
  setStatsTimePeriod: (_: SetStateAction<MerchantStatsPeriod>) => {},
  setStatsDateRange: (_: SetStateAction<DateOptions>) => {},
  report: {},
  setReport: (_: MerchantStatsContextState['report']) => {},
});
