import { createStyles, makeStyles, Theme, Typography, TypographyProps, TypographyTypeMap, useTheme } from "@material-ui/core"
import Switch from "react-switch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      '& .MuiTypography-root': {
        lineHeight: 1.5
      },

      "& .react-switch-handle": {
        boxShadow: "rgb(0 0 0 / 30%) 0px 1px 5px !important"
      }
    },
  })
);

type AppSwitchProps = {
  label: string,
  checkedLabel?: string,
  uncheckedLabel?: string,
  checked: boolean,
  disabled?: boolean
  onChangeHandler: (checked: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => void
  typographyProps?: TypographyProps<'div'>
}
const AppSwitch = (props: AppSwitchProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const { label,
    checked = false,
    disabled = false,
    onChangeHandler = () => { },
    checkedLabel = "ON",
    uncheckedLabel = "OFF",
    typographyProps = {}
  } = props

  return (
    <label htmlFor={`swtich-${label}`} className={classes.root}>
      <Typography variant='overline' color='textSecondary' {...typographyProps}>
        {label}
      </Typography>

      <Switch
        disabled={disabled}
        width={80}
        checked={checked}
        onChange={onChangeHandler}
        onColor={theme.palette.primary.light}
        onHandleColor={theme.palette.primary.main}
        boxShadow="0px 1px 5px rgba(0,0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingRight: 16,
              color: 'white',
            }}>
            {uncheckedLabel}
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingLeft: 16,
              color: theme.palette.primary.main,
            }}>
            {checkedLabel}
          </div>
        }
        className="react-switch"
        id={label}
      />
    </label>
  )
}

export default AppSwitch