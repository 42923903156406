import {
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statsListingTable: {
      height: "250px",

      "& th": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[500],
        textTransform: "uppercase",
        fontSize: "0.8rem",
        padding: `${theme.spacing(1)} 0`,

        "& .MuiTableSortLabel-root, .MuiTableSortLabel-icon": {
          fontSize: "0.8rem",
          color: `${theme.palette.grey[500]} !important`
        }
      },

      "& td": {
        padding: `${theme.spacing(1)} 0`,
      }
    }
  })
)

export default useStyles