import React, {useMemo} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {head } from "ramda";
import classNames from 'classnames';

import { MerchandiseBadges, Merchandise } from '../../types/MerchandiseType';

export type MerchandiseBadgesTypes = keyof MerchandiseBadges;

export const MerchandiseBadgesList: MerchandiseBadgesTypes[] = ['new', 'hot', 'deal', 'seasonal'];

const MerchandiseBadgesColors: Record<MerchandiseBadgesTypes, string> = {
  new: '#ff8e25',
  hot: '#e34d28',
  deal: '#fdbf00',
  seasonal: '#7e1925',
};

const MerchandiseBadgesTextColors: Partial<Record<MerchandiseBadgesTypes, string>> = {
  deal: '#000',
};

const useBadgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 3,
      color: '#fff',
      fontSize: theme.spacing(1.5),
      padding: `1px ${theme.spacing(.5)}`,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  }),
);

interface MerchandiseBadgeProps {
  badgeId: MerchandiseBadgesTypes;
}

export const MerchandiseBadge = (props: MerchandiseBadgeProps) => {
  const {badgeId} = props;
  const classes = useBadgeStyles();
  const style = useMemo(() => {
    const obj: {
      background: string;
      color?: string;
    } = {
      background: MerchandiseBadgesColors[badgeId],
    };

    if (MerchandiseBadgesTextColors[badgeId]) {
      obj.color = MerchandiseBadgesTextColors[badgeId];
    }

    return obj;
  }, [badgeId]);


  return (
    <div className={classNames([classes.root, 'merchandise-badge'])} style={style}>{badgeId}</div>
  );
};


const useBadgesGroupStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      right: theme.spacing(1),
      position: 'absolute',
      top: theme.spacing(1),

      [theme.breakpoints.down("md")]: {
        transform: 'rotate(45deg)',
        top: '6px',
        right: '-64px',
        '& .merchandise-badge': {
          width: '120px',
        }
      },
    },
  }),
);

interface RenderMerchandiseBadgesComponentProps {
  merchandise: Merchandise;
}

export const RenderMerchandiseBadgesComponent = ({ merchandise }: RenderMerchandiseBadgesComponentProps) => {
  const classes = useBadgesGroupStyles();
  const badgesIds = useMemo(() => {
    if (!merchandise.badges) {
      return [];
    }

    return Object.keys(merchandise.badges) as MerchandiseBadgesTypes[];
  }, [merchandise.badges]);

  const firstBadgeId = head(badgesIds);
  if (!firstBadgeId) {
    return null;
  }

  return (
    <div className={classes.root}>
      <MerchandiseBadge badgeId={firstBadgeId} />
    </div>
  );
};
