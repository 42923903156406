import React, { useContext } from 'react';
import { Typography, CardHeader, Chip, makeStyles, Theme, createStyles, Divider, Stack, Box } from '@material-ui/core';
import { Person, Phone, Place, Restaurant, Schedule } from '@material-ui/icons';
import { If, Then } from 'react-if';
import { defaultTo, prop, propOr, reduce } from 'ramda';
import { useTranslation } from 'react-i18next';

// src
import { getManageMerchantURL } from 'bos_common/src/services/urls';
import { displayTime } from '../../bos_common/src';
import { displayOrderShortCode, isDineInOrder, isPickupASAP } from '../../bos_common/src/services/orderUtils';
import { UserContext } from '../../bos_common/src/context/UserContext';
import { DeviceType, getDeviceLabel } from '../../bos_common/src/types/DeviceType';

import { LineItem, Merchant, Order, OrderPickupType, OrderStatus, OrderType, User } from '../../services/models';
import { isEmptyOrNil, userIsOneMOperator } from '../../utils';
import { getCurrentOrderTable } from '../../utils/orderUtils';
import TableIcon from '../../assets/icons/TableIcon';
import { getOrderElapsedTimeColor } from './orderCardUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: 'white',

      '& .card-header-content': {
        display: 'grid',
        gridColumnGap: theme.spacing(1),
        gridTemplateColumns: '1fr max-content',
        justifyContent: 'space-between',

        '& .order-title': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 500,
        },
      },
    },

    info: {
      padding: theme.spacing(2),
      paddingBottom: `calc(${theme.spacing(2)} - .5rem)`,
      lineHeight: '1.2',
      '& .MuiTypography-root': {
        lineHeight: '1.2',
      },
      '& .MuiTypography-gutterBottom': {
        marginBottom: '.5rem',
      },
      '& .display-name': {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        textTransform: 'none',
      },
      '& .order-info': {
        '& .order-user': {
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        '& .MuiChip-outlined': {
          border: `1px solid`,
          borderRadius: theme.spacing(0.5),
          padding: `0 ${theme.spacing(0.5)}`,
          marginRight: theme.spacing(0.5),
          height: '20px',
          fontSize: '12px',
          '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 0,
          }
        },
      },
      '& .order-info-title': {
        color: theme.palette.grey[500],
        fontWeight: 'bold',
      },
      '& .phone-info': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .order-header-chip': {
        fontSize: '12px',
      }
    }
  })
);

const RenderOrderCountdown = (t: Date, label: string = ''): React.ReactElement | null => {
  const timestamp = new Date(t).getTime();
  const now = new Date().getTime();

  const minutesDiff = Math.round((now - timestamp) / 1000 / 60);

  if (minutesDiff > 60 * 24) {
    // days
    return (
      <span className="order-countdown">{`${label} (${Math.round(minutesDiff / 60 / 24)}d)`}</span>
    );
  } else if (minutesDiff > 60) {
    // hours
    return (
      <span className="order-countdown">{`${label} (${Math.round(minutesDiff / 60)}h)`}</span>
    );
  } else if (minutesDiff > 5) {
    // 5 miuntes
    return (
      <span className="order-countdown">{`${label} (${minutesDiff}m)`}</span>
    );
  } else if (minutesDiff > 0) {
    // minutes
    return (
      <span className="order-countdown">{`${label} (${minutesDiff}m)`}</span>
    );
  }

  return (<span className="order-countdown">{label}</span>);
};

const RenderOrderElapsedTime = ({ order, merchant }: { order: Order, merchant?: Merchant }) => {
  const { t } = useTranslation();

  if (order.readyTime) {
    switch (order.status) {
      case OrderStatus.FULFILLED:
        return <>{t("Order_List_FulfilledAt", { time: displayTime(order.readyTime) })}</>;
      case OrderStatus.VOIDED:
        return <>{t("Order_List_VoidedAt", { time: displayTime(order.readyTime) })}</>;
      case OrderStatus.PAID_EXTERNALLY:
        return <>{t("Order_List_PaidAt", { time: displayTime(order.readyTime) })}</>;
      default:
        break;
    }
  }

  let orderTypeLabel = t('Pickup');
  const isDineIn = isDineInOrder(order);
  if (isDineIn) {
    const table = getCurrentOrderTable(order, merchant);
    orderTypeLabel = table ? `Table: ${table}` : t('Order_DineIn');
  }

  if (!isDineIn && order.pickupType) {
    orderTypeLabel = order.pickupType === OrderPickupType.EAT_HERE ? t('Order_ForHere') : t('Order_ToGo');
  }

  if (!isPickupASAP(order)) {
    const label = `${orderTypeLabel} ${displayTime(order.toPickupTime)}`;
    return <>{RenderOrderCountdown(order.toPickupTime, label)}</>;
  }

  return <>{RenderOrderCountdown(order.createdAt, orderTypeLabel)}</>;
};

const calculateItemCount = (order: Order) => {
  const countItems = (acc: number, lineItem: LineItem) => acc + lineItem.quantity
  return reduce(countItems, 0, propOr([], 'lineItems', order))
}

const RenderAppChip = ({ order }: { order: Order }) => {
  if ([DeviceType.Kiosk, DeviceType.ZeroAndApp].includes(order.sourceInfo.device)) {
    return (
      <Chip
        label={getDeviceLabel(order.sourceInfo.device)}
        variant="outlined"
        color='primary'
        className='order-header-chip'
      />
    )
  }
  return null;
}

const RenderOrderTypeChip = ({ order }: { order: Order }) => {
  const { t } = useTranslation();
  if (!isPickupASAP(order)) {
    const label = t('OrderAhead');
    return (
      <Chip
        label={label}
        variant="outlined"
        color='primary'
        className='order-header-chip'
      />
    )
  }
  return null;
}

const RenderUserInfo = ({ order, user }: { order: Order, user?: User }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const subscriberPhoneNumber = propOr('', 'phoneNumber', defaultTo(user, prop('subscriber', order)));
  const displayName = order.userDisplayName || user?.displayName || "";
  const userPurchaseProfile = user?.purchaseProfiles?.find((pf) => pf.merchantId === order.merchantId);
  const purchaseProfile = order.subscriber?.purchaseProfile || userPurchaseProfile;
  const visitCount = purchaseProfile && purchaseProfile.visitCount || 1;

  return (
    <div className={classes.info}>
      <div className='order-info'>
        <Stack className="order-user" alignItems='center' direction='row' title={displayName}>
          <Typography variant="subtitle1" color="textPrimary" gutterBottom component="div">
            <Stack alignItems='center' direction='row'>
              <Stack alignItems='center' direction='row' className="display-name">
                <Person sx={{ mr: 0.5 }} />
                <b>{displayName}&nbsp;&nbsp;</b>
              </Stack>
              <Chip
                label={visitCount > 1 ? t("Order_Repeat", { count: visitCount }) : t("Order_FirstTimer")}
                variant="outlined"
                color='primary'
                className='order-header-chip'
              />
              <RenderAppChip order={order} />
              <RenderOrderTypeChip order={order} />
            </Stack>
          </Typography>
        </Stack>

        <If condition={!isEmptyOrNil(subscriberPhoneNumber)}>
          <Then>
            <Typography variant="subtitle1" color="textPrimary" component="div" gutterBottom className='phone-info'>
              <Phone sx={{ mr: 0.5 }} />
              {subscriberPhoneNumber}
            </Typography>
          </Then>
        </If>

      </div>
    </div>
  )
}

const RenderMerchantInfo = ({ order, merchant }: { order: Order, merchant?: Merchant }) => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const phoneNumber = propOr('', 'phoneNumber', merchant);
  const displayName = propOr('', 'officialName', merchant);
  const userName = propOr('', 'username', merchant);
  const toPickupTime = propOr('', 'toPickupTime', order);
  const isDineIn = order.type === OrderType.DINEIN

  if (!merchant) return null;

  return (
    <div className={classes.info}>
      <Typography className="order-info-title" variant="body2" gutterBottom component="p">
        {isDineIn ? t('DineIn') : t('PickUp')}
      </Typography>

      {/* info for 1M operators */}
      <If condition={userIsOneMOperator(user)}>
        <Then>
          <a href={`${getManageMerchantURL()}/${userName}/stafflogin`} target="_blank" rel="noopener noreferrer" title='Click to Staff Login'>
            <Typography variant="subtitle1" color="textPrimary" className='order-info' component="div">
              <div className='order-user' title={displayName}>
                <Stack className="display-name" alignItems='center' direction='row'>
                  <Restaurant sx={{ mr: 0.5 }} />
                  {displayName}
                </Stack>
              </div>
            </Typography>
          </a>
          <If condition={!isEmptyOrNil(phoneNumber)}>
            <Then>
              <Typography variant="subtitle1" color="textPrimary" component="div">
                <Stack className="display-name" alignItems='center' direction='row'>
                  <Phone sx={{ mr: 0.5 }} />
                  {phoneNumber}
                </Stack>
              </Typography>
            </Then>
          </If>
        </Then>
      </If>

      <If condition={!isDineIn}>
        <Typography variant='subtitle1' color="textPrimary" component="div" gutterBottom>
          <Stack className="display-name" alignItems='center' direction='row'>
            <Schedule sx={{ mr: 0.5 }} />
            {!isPickupASAP(order) ? displayTime(toPickupTime, true) : t('ASAP')}
          </Stack>
        </Typography>
      </If>

      <div className='order-user'>
        <Typography variant='subtitle1' color="textPrimary" component="div" gutterBottom>
          <If condition={isDineIn}>
            <Stack className="display-name" alignItems='center' direction='row'>
              <div style={{ marginRight: '8px' }}>
                <TableIcon />
              </div>
              {getCurrentOrderTable(order, merchant)}
            </Stack>
          </If>
          <Stack className="display-name" alignItems='center' direction='row'>
            <Place sx={{ mr: 0.5 }} />
            {merchant.officialName}
          </Stack>
        </Typography>
      </div>
    </div>
  )
}

type OrderCardHeaderProps = {
  order: Order;
  user?: User;
  merchant?: Merchant;
  showMerchantInfo?: boolean;
};
const OrderCardHeader = (props: OrderCardHeaderProps) => {
  const { order, user, merchant, showMerchantInfo = false } = props;
  const classes = useStyles();
  const { user: loggedInUser } = useContext(UserContext);

  const totalItemsInOrder = calculateItemCount(order);
  const elapsedTimeColor = getOrderElapsedTimeColor(order, loggedInUser)

  const RenderOrderCardTitle = () => {
    const orderTitle = `${displayOrderShortCode(order)} (${totalItemsInOrder})`
    return (
      <Typography variant="subtitle1" component="div" className="card-header-content">
        <div className="order-title" title={orderTitle}>
          {orderTitle}
        </div>
        <div>
          <RenderOrderElapsedTime order={order} merchant={merchant} />
        </div>
      </Typography>
    )
  }

  return (
    <>
      <CardHeader
        title={<RenderOrderCardTitle />}
        className={classes.header}
        style={{
          background: `linear-gradient(90deg, ${elapsedTimeColor?.dark} 29%, ${elapsedTimeColor?.main} 0%)`
        }}
      />

      <RenderUserInfo order={order} user={user} />
      <Divider />

      <If condition={showMerchantInfo}>
        <Then>
          <RenderMerchantInfo order={order} merchant={merchant} />
          <Divider />
        </Then>
      </If>
    </>
  );
};

export default OrderCardHeader;
