import { WebAuth } from "auth0-js";
import { Auth0User, User } from "../types/UserTypes";
import { createHash } from 'crypto';
import axios from './backendAxios';
import { AxiosResponse } from "axios";

export enum LOCALSTORAGE_AUTH_KEYS {
  NONCE = 'auth-nonce',
  STATE = 'auth-state',
  LAST_TIME = 'auth-last-time-sms',
  PHONE_HASH = 'phone-no-hash',
  EMAIL_HASH = 'email-addr-hash',
  USER_JWT_TOKEN = 'user-jwt-token',
  MERCHANT_DEVICE_AUTH_TOKEN = 'merchant-device-auth-token',
  PREFER_EMAIL = 'prefer-email'
}

// auth state cached locally
export interface AuthLocalCachedState {
  nonce?: string
  state?: string
  token?: string
  scope?: "openid email" | "openid phone"
}

// auth meta cached locally, non-critical data
export interface AuthLocalCachedMeta {
  lastTimeSMS?: number,
  lastTimeEmail?: number,
  phoneHashArray?: string[],
  emailHashArray?: string[],
}

export interface AuthResultData {
  user: User
  token: string
}

// Base on Auth0, client id is public information, so no need to encrypt here.
// https://auth0.com/docs/applications/application-settings
const domain = 'login.1m.app'
export const auth0 = new WebAuth({
  domain: domain,
  clientID: 'RpXvnwcppfG17ZPCsl2I2Hf9xTPr8yTQ',
  redirectUri: window.location.origin + "/callback",
  responseType: 'token id_token',
  scope: 'openid phone',
})

export const storeAuthMeta = (lastTimeSMS?: number, phoneNumber?: string, email?: string): void => {
  lastTimeSMS && localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.LAST_TIME, String(lastTimeSMS))
  const phoneNumberHash = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.PHONE_HASH)
  const phoneHashArr = phoneNumberHash?.split('|') || []
  const emailNumberHash = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.EMAIL_HASH)
  const emailHashArr = emailNumberHash?.split('|') || []
  if (phoneNumber) {
    const hash = createHash('sha256')
    hash.update(phoneNumber)
    const hex = hash.digest('hex')
    if (phoneHashArr.indexOf(hex) === -1) {
      phoneHashArr.unshift(hex)
      localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.PHONE_HASH, phoneHashArr.slice(0, 5).join('|'))
    }
  }

  if (email) {
    const hash = createHash('sha256')
    hash.update(email)
    const hex = hash.digest('hex')
    if (emailHashArr.indexOf(hex) === -1) {
      emailHashArr.unshift(hex)
      localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.EMAIL_HASH, emailHashArr.slice(0, 5).join('|'))
    }
  }
}

export const getAuthMeta = (): AuthLocalCachedMeta => {
  const lastTimeSMS = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.LAST_TIME) || ''
  const phoneNumberHash = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.PHONE_HASH)
  const phoneHashArr = phoneNumberHash?.split('|') || []
  const emailAddrHash = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.EMAIL_HASH)
  const emailHashArr = emailAddrHash?.split('|') || []
  return {
    lastTimeSMS: lastTimeSMS ? +lastTimeSMS : undefined,
    phoneHashArray: phoneHashArr,
    emailHashArray: emailHashArr,
  }
}

export const isPhoneNumberCached = (phoneHashArr: string[], phoneNumber: string): boolean => {
  const hash = createHash('sha256')
  hash.update(phoneNumber)
  const hex = hash.digest('hex')
  if (phoneHashArr.indexOf(hex) !== -1) {
    return true
  }
  return false
}

export const isEmailAddressCached = (emailHashArr: string[], email: string): boolean => {
  const hash = createHash('sha256')
  hash.update(email)
  const hex = hash.digest('hex')
  if (emailHashArr.indexOf(hex) !== -1) {
    return true
  }
  return false
}


export const storeAuthState = (input: AuthLocalCachedState): void => {
  const nonce = input?.nonce || ''
  const state = input?.state || ''
  const token = input?.token || ''
  localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.NONCE, nonce)
  localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.STATE, state)
  localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.USER_JWT_TOKEN, token)
}

export const getAuthState = (): AuthLocalCachedState => {
  const nonce = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.NONCE) || ''
  const state = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.STATE) || ''
  const token = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.USER_JWT_TOKEN) || ''
  const PREFER_EMAIL = localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.PREFER_EMAIL) === 'true'

  return {
    nonce,
    state,
    token,
    scope: PREFER_EMAIL ? 'openid email' : 'openid phone'
  }
}

export const removeAuthState = (): void => {
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEYS.NONCE)
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEYS.STATE)
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEYS.USER_JWT_TOKEN)
  localStorage.removeItem(LOCALSTORAGE_AUTH_KEYS.PREFER_EMAIL)
}

export function extractJWT(authorization: string): string | undefined {
  let token: string | undefined;

  if (authorization.includes("JWT")) {
    token = authorization.slice(4);
  }

  if (authorization.includes("Bearer")) {
    token = authorization.slice(7);
  }

  return token;
}

export function getJWTTokenFromHeader(
  response: AxiosResponse
): {
  token?: string;
} {
  const authorization = response.headers.authorization;
  let token: string | undefined;
  if (authorization) {
    token = extractJWT(authorization);
  }

  return {
    token,
  };
}

export const loginWithAuth0Token = async (token: string, user?: Auth0User): Promise<AuthResultData | undefined> => {
  const response = await axios.post<User>('/login', {
    token,
    authMethod: 'auth0',
    payload: user,
  })

  const { token: newToken } = getJWTTokenFromHeader(response)
  if (response.status === 200) {
    return { user: response.data, token: newToken || '' }
  } else {
    return undefined
  }
}

export const logout = (logoutURL: string, returnTo?: string) => {
  removeAuthState()
  if (returnTo) {
    const searchParams = new URLSearchParams()
    searchParams.append("to", returnTo)
    auth0.logout({
      returnTo: logoutURL + '?' + searchParams.toString(),
    })
  } else {
    auth0.logout({
      returnTo: logoutURL,
    })
  }
}
