import React, { useContext, useEffect, useState } from 'react'
import { Box, createStyles, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Else, If, Then } from 'react-if';
import useAxios from 'axios-hooks';

// src
import InputSearchBox from 'bos_common/src/components/InputSearchBox';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { UserContext } from 'bos_common/src/context/UserContext';
import { Order } from 'bos_common/src/types/OrderTypes';

import { AppContext } from '../../../context/AppContext';
import { getAuthHeaders, isEmptyOrNil } from '../../../utils';
import OrderInfoDrawer from '../../../components/Order/OrderInfoDrawer';
import RenderOrderListTable from '../../../components/Order/RenderOrderListTable';
import { ORDER_SEARCH_BAR_FILTERS } from '../../../context/MerchantOrders/MerchantOrdersContext';

type OrderSearchResponseType = {
  data: Order[];
  count: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",

      "& .search-filter": {
        position: "absolute",
        top: "50%",
        right: 10,
        transform: "translateY(-50%)",
        color: 'rgba(0,0,0,0.6)',

        "&:hover": {
          "&:not(.Mui-disabled):before": {
            borderBottom: 0
          }
        },

        "&:before": {
          borderBottom: 0
        },

        "&:after": {
          borderBottom: 0
        },

        "& .MuiSelect-select:focus": {
          background: "transparent"
        }
      }
    }
  })
);


const SearchOrders = () => {
  const classes = useStyles();
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [searchField, setSearchField] = useState(ORDER_SEARCH_BAR_FILTERS.searchByName);

  const [currentRowPage, setCurrentRowPage] = useState<number>(0);

  const { merchant } = useContext(AppContext);
  const { token } = useContext(UserContext);

  const [{ data, loading }, fetchOrdersList] = useAxios<OrderSearchResponseType>(
    {
      url: `/merchants/${merchant?.id}/orders/search`,
      headers: getAuthHeaders(token),
      params: {
        pageNumber: currentRowPage + 1,
        search: searchQuery,
        searchField
      },
    },
    { manual: true }
  );

  const { data: orders = [], count = 0 } = data || {};

  useEffect(() => {
    if (!isEmptyOrNil(searchQuery)) {
      fetchOrdersList()
    }

  }, [currentRowPage, searchQuery, searchField])

  useEffect(() => {
    if (!isEmptyOrNil(searchQuery)) {
      setCurrentRowPage(0);
      fetchOrdersList();
    }
  }, [searchQuery, searchField]);

  return (
    <>
      <SimpleLoader loading={loading} />
      <Box p={2}>
        <Box className={classes.root}>
          <InputSearchBox onChangeQuery={setSearchQuery} placeholder={`${t('MerchantSales_SearchOrders')}...`} />

          <Select className="search-filter" value={searchField} onChange={(e) => setSearchField(e.target.value)} label="Search Filter">
            <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByName}>{t("ByName")}</MenuItem>
            <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByAmount}>{t("ByAmount")}</MenuItem>
            <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByPhone}>{t("ByPhone")}#</MenuItem>
            <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByOrderNumber}>{t("ByOrder")}#</MenuItem>
          </Select>
        </Box>

        <Typography sx={{ my: 2 }}>
          <If condition={!isEmptyOrNil(searchQuery) && isEmptyOrNil(orders) && !loading}>
            <Then>{t('Message_NoData')}</Then>
          </If>
        </Typography>

        <If condition={!isEmptyOrNil(searchQuery) && !isEmptyOrNil(orders)}>
          <RenderOrderListTable
            orders={isEmptyOrNil(searchQuery) ? [] : orders}
            handleOrderClick={setSelectedOrder}
            showFullDate={true}
            paginationOptions={{
              count,
              currentRowPage,
              setCurrentRowPage
            }}
          />
        </If>

        <OrderInfoDrawer
          open={!isEmptyOrNil(selectedOrder)}
          setOpen={() => setSelectedOrder(undefined)}
          order={selectedOrder}
          refreshHistory={() => fetchOrdersList()}
        />
      </Box>
    </>
  )
}

export default SearchOrders