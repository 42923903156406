import assert from "assert"
import { LineItem, Order, OrderPickupType, OrderStatus, OrderType, PreAuthStatus } from "../types/OrderTypes"

export const orderCodeFromID = (orderId: string): string => {
  assert(orderId.startsWith('o_'))
  return orderId.slice(2).toUpperCase()
}

export const displayOrderShortCode = (order: Order): string => {
  if (!order) return ''
  if (order.orderNo !== null) {
    return ('0000' + order.orderNo).slice(-4);
  }
  const code = orderCodeFromID(order.id)
  return code.slice(-3)
}

export const isOrderActive = (order: Order): boolean => {
  if (order.status === OrderStatus.PREPARING ||
    order.status === OrderStatus.PAID ||
    order.status === OrderStatus.OPEN_CHECK) {
    return true
  }
  return false;
}

export const isDineInOrder = (order: Order): boolean => order.type === OrderType.DINEIN;
export const isOpenCheckOrder = (order: Order): boolean => order.status === OrderStatus.OPEN_CHECK;

export const isOpenCheckPreAuthed = (order: Order): boolean =>
  isOpenCheckOrder(order) && order.preAuthInfo?.status === PreAuthStatus.PRE_AUTHED;

export const displayOrderStatus = (order: Order, toMerchant?: boolean): string => {
  if(order.cancellationInfo && order.cancellationInfo.length > 0) return 'Refunded'

  switch (order.status) {
    case OrderStatus.PENDING:
      return 'Pending'
    case OrderStatus.PAID:
      return order.type === OrderType.DINEIN ? 'Paid' : 'Received'
    case OrderStatus.PREPARING:
      return 'Preparing'
    case OrderStatus.FULFILLED:
      return 'Completed'
    case OrderStatus.OPEN_CHECK:
      return order.type === OrderType.DINEIN ? 'Open tab' : 'Received';
    case OrderStatus.PAID_EXTERNALLY:
      return toMerchant ? 'Paid externally' : 'Paid'
    case OrderStatus.VOIDED:
      return toMerchant ? 'Voided' : 'Paid'
    default:
  }
  return ''
}

/* If the order has a pickup type, return that (eat-here or to-go) else return empty string */
export const displayPickUpTypeLabel = (order: Order) => {
  const { pickupType } = order;
  switch (pickupType) {
    case OrderPickupType.EAT_HERE:
      return 'Dine In';
    case OrderPickupType.TO_GO:
      return 'To Go';
    default:
      return '';
  }
};

const getTime = (dateStr: Date) => new Date(dateStr).getTime()

export const isPickupASAP = (order: Order): boolean => {
  // pick up time within two minutes of order time
  if (order.toPickupTime && Math.abs(getTime(order.toPickupTime) - getTime(order.createdAt)) < 2 * 60 * 1000) {
    return true
  }
  return false
}

export const sortOrders = (orders: Order[] | undefined): Order[] => {
  return orders?.sort((a, b) => {
    if (a.toPickupTime && b.toPickupTime) {
      return getTime(b.toPickupTime) - getTime(a.toPickupTime)
    } else {
      return getTime(b.createdAt) - getTime(a.createdAt)
    }
  }) || []
}

export const getLineItemKey = (item: LineItem): string => {
  return `${item.id}-${item.customizationSignature}`
}

export const getItemsInOrder = (order: Order, ignoreRefundedItems?: boolean): number => {
  return order.lineItems.reduce((acc, item) => {
    return acc + (ignoreRefundedItems && item.refunded ? 0 : item.quantity)
  }, 0);
};
