import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { LineItem } from '../../types/OrderTypes';
import renderPrice from '../Price'
import MerchandiseModifiers from '../Merchandise/MerchandiseModifiers'
import MerchandiseNote from '../Merchandise/MerchandiseNote'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      '& .rightColumn': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginLeft: theme.spacing(1),
      },
    },
  })
)

export default function OrderItemCard(props: {
  item: LineItem
}): React.ReactElement {
  const classes = useStyles()
  const item = props.item
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid
        item
        xs
        container
        direction="column"
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item>
          <Typography gutterBottom variant="body2" component="div">
            {item.refunded ? <s>{item.name}</s> : item.name}
          </Typography>
          {item.customization && (
            <MerchandiseModifiers modifiers={item.customization} modifiersDisplayOrder={item.customizationDisplayOrder}/>
          )}
          {item.note && <MerchandiseNote note={item.note} />}
        </Grid>
      </Grid>

      <Grid item className="rightColumn">
        <Grid item>
          <Typography variant="body2" component="div">
            {item.refunded ? (
              <s>Qty: {item.quantity}</s>
            ) : (
              `Qty: ${item.quantity}`
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" component="div">
            {item.refunded && '(Refunded) '}
            {renderPrice(item.price * item.quantity)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
