import React, { useState } from 'react';
import { MoreVert, Edit,  Delete } from '@material-ui/icons';
import {
  ClickAwayListener,
  createStyles,
  makeStyles,
  MenuList,
  Paper,
  Popper,
  Theme,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ConfirmationAlert from 'bos_common/src/components/ConfirmationAlert';
import MerchantPackage from 'bos_common/src/types/crm/MerchantPackageType';
import MerchantMembership from 'bos_common/src/types/crm/MerchantMembershipType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .dropdown-button': {
        margin: '0 !important',
        marginRight: `-${theme.spacing(1)} !important`,
      },
    },

    menuDropdownWrapper: {
      zIndex: 99,

      '& .MuiMenu-paper': {
        maxWidth: '250px',
        width: '100%',
      },

      '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    menuDropdownItem: {
      gridGap: '15px',
      padding: '10px 25px',
      transition: 'all 200ms linear',
      color: '#414141',

      '&:hover': {
        background: '#edf7f8',
        color: '#2098aa',
      },

      '&.warning': {
        color: '#ff6565',
      },
    },
  })
);

type ServiceItemCardDropdownProps = {
  onEditClick: () => void;
  merchandise: MerchantPackage | MerchantMembership;
  handleDeleteItem: (_: number) => void;
};

const ServiceItemCardDropdown = ({ onEditClick, handleDeleteItem, merchandise }: ServiceItemCardDropdownProps) => {
  const [isDeleteAlertOpen, toggleDeleteAlert] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleEditClick = () => {
    onEditClick();
    handleToggle();
  };

  const handleCloseDeleteAlert = () => {
    toggleDeleteAlert(false);
    handleToggle();
  };

  return (
    <div className={classes.root}>
      <IconButton
        ref={anchorRef}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-label="more"
        color="primary"
        onClick={handleToggle}
        className="dropdown-button"
      >
        <MoreVert />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.menuDropdownWrapper}
        placement="auto-end"
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              <MenuItem className={classes.menuDropdownItem} onClick={handleEditClick}>
                <Edit /> {t('Edit')}
              </MenuItem>
              <MenuItem
                className={`${classes.menuDropdownItem} warning`}
                onClick={() => toggleDeleteAlert(true)}
              >
                <Delete /> {t('Delete')}
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>

      <ConfirmationAlert
        isOpen={isDeleteAlertOpen}
        title={t('Menu_DeleteMenuItem')}
        onCancel={handleCloseDeleteAlert}
        onConfirm={() => handleDeleteItem(merchandise.id)}
        cancelLabel={t('Cancel')}
        confirmLabel={t('Confirm')}
      >
        {t('Menu_DeleteMenuItem_Description')}
      </ConfirmationAlert>
    </div>
  );
};

export default ServiceItemCardDropdown;
