import React from 'react';
import { Box, createStyles, makeStyles, Tab, Tabs, Theme, withStyles } from '@material-ui/core';

interface StyledTabPanelProps {
  children?: React.ReactNode;
  tabKey: any;
  value: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      height: '100%',
      overflow: 'auto',
      width: '100%',
    }
  })
)

function StyledTabPanel(props: StyledTabPanelProps) {
  const { children, value, tabKey, ...other } = props;
  const classes = useStyles()

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== tabKey}
      id={`tabpanel-${tabKey}`}
      aria-labelledby={`tabpanel-${tabKey}`}
      {...other}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        {value === tabKey && children}
      </Box>
    </div>
  );
}

interface StyledTabsProps {
  value: any;
  onChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
  children: React.ReactElement | Array<React.ReactElement>,
  action?: any,
  variant?: "scrollable" | "standard" | "fullWidth"
}

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    flexContainer: {
      justifyContent: "center"
    },
    indicator: {
      display: 'flex',
      backgroundColor: 'transparent',
      '& > span': {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
)((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
  value: string
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: '1 1 auto',
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      lineHeight: 1.2,
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export {
  StyledTabPanel,
  StyledTab,
  StyledTabs
}