import { Order } from "../services/models";
import { ReduxSlice } from "./ReduxSlice";

export enum NotificationSeverity {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

export type NotificationBody = {
  open: boolean,
  bodyText: string,
  severity: NotificationSeverity,
  hideAction: boolean
}

export type Notification = {
  ts: number,
  order?: Order | null,
  notificationBody?: NotificationBody | null,
}

export interface NotificationSliceStateEntities {
  notifications: Notification[];
}

export type NotificationSliceState = ReduxSlice<NotificationSliceStateEntities>;

export default NotificationSliceState;
