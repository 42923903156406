import React, { useContext } from 'react';
import { Box, Card, Stack, Typography } from '@material-ui/core';

import { WaitTime } from '../../bos_common/src/types/WaitTimeType';

import { CallboardContext } from '../../context/CallboardContext/CallboardContext';
import { pluralString } from '../../utils';

import CallboardOrdersList from './CallboardOrdersList';
import TimeOutlineIcon from './TimeOutlineIcon';
import { If, Then, Else } from 'react-if';

const getWaitTimeString = (minMinutes: number | undefined, maxMinutes: number | undefined) => {
  return minMinutes == null || maxMinutes == null ? 'n/a' : `${minMinutes}-${maxMinutes} mins.`
}

const CallboardHeader = ({ merchantWaitTime }: { merchantWaitTime: WaitTime }) => {
  const { minMinutes, maxMinutes, priorOrdersInQueue = 0, priorItemsInQueue = 0 } = merchantWaitTime || {}

  return (
    <div className='header-container'>
      <Stack direction='column' className='main-header'>
        <If condition={priorOrdersInQueue > 0}>
          <Then>
            <Typography variant='h4'>
              {pluralString(priorOrdersInQueue, 'order')} / {pluralString(priorItemsInQueue, 'item')} in queue
            </Typography>
          </Then>
          <Else>
            <Typography variant='h4'>
              Orders Queue
            </Typography>
          </Else>
        </If>
      </Stack>

      <Stack direction='row' className='wait-time-container' spacing={1}>
        <Stack direction='row' sx={{ width: "100%", alignItems: 'center' }}>
          <TimeOutlineIcon sx={{ width: 30, height: 30 }} />
          <Box sx={{ marginLeft: '8px' }}>
            <Typography variant='h5' component='div' noWrap>{getWaitTimeString(minMinutes, maxMinutes)} est. wait</Typography>
          </Box>
        </Stack>

        <div className='progress-bar-wrapper'>
          <div className='progress-root'>
            <div className='progress-bar-indicator' style={{ width: `${(maxMinutes ?? 0) / 60 * 100}%` }} />
          </div>
        </div>
      </Stack>
    </div>
  )
}

const InProgressOrdersCallboard = () => {
  const { merchantWaitTime, inProgressOrdersList } = useContext(CallboardContext);

  return (
    <Card className='callboard-container'>
      <CallboardHeader merchantWaitTime={merchantWaitTime} />
      <CallboardOrdersList orders={inProgressOrdersList} />
    </Card>
  )
}

export default InProgressOrdersCallboard