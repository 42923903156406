import React, { useEffect, useMemo } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { If, Then, Else } from 'react-if';
import { useTranslation } from 'react-i18next';

import { CouponStatsTableProps } from '../types';
import { isEmptyOrNil } from '../../../utils';

import useStyles from './styles';
import {
  MerchantSalesStats,
  UsedCoupon,
} from '../../../bos_common/src/types/MerchantSalesStatsType';
import { extractUsedCouponStats } from '../../../utils/merchantStatsUtils';

const CouponStatsTable = ({
  currentStatsApiData,
  setReport,
}: CouponStatsTableProps & {
  setReport: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
}) => {
  const classes = useStyles();

  const [couponIds, dataTable] = useMemo(() => {
    const newCouponStats =
      currentStatsApiData && currentStatsApiData.stats
        ? extractUsedCouponStats(currentStatsApiData.stats as MerchantSalesStats[])
        : [];
    const newCouponIds = Object.keys(newCouponStats);
    const newDataTable: { [key: string]: UsedCoupon } = {};
    newCouponIds.forEach((id: string) => {
      Object.keys(newCouponStats[id]).forEach((key) => {
        const coupon: UsedCoupon = newCouponStats[id][key];
        if (!newDataTable[key]) {
          newDataTable[key] = coupon;
        } else {
          newDataTable[key] = {
            ...newDataTable[key],
            usageCount: newDataTable[key].usageCount + coupon.usageCount,
            totalRedemption: newDataTable[key].totalRedemption + coupon.totalRedemption,
          };
        }
      });
    });

    return [newCouponIds, newDataTable];
  }, [currentStatsApiData]);

  const { t } = useTranslation();

  const renderRows = (): JSX.Element[] => {
    let coupon: UsedCoupon;
    const keys = Object.keys(dataTable);
    return keys.map<JSX.Element>((key: string, idx: number) => {
      coupon = dataTable[key];

      return (
        <TableRow key={`coupon-${coupon.id ?? 0}`}>
          <TableCell sx={{ color: 'text.secondary' }} width={20}>
            {idx + 1}
          </TableCell>
          <TableCell>{coupon.code}</TableCell>
          <TableCell align="right">${parseFloat(`${coupon.totalRedemption}`).toFixed(2)}</TableCell>
          <TableCell align="right">{coupon.usageCount}</TableCell>
        </TableRow>
      );
    });
  };

  useEffect(() => { 
    const keys = Object.keys(dataTable);
    setReport([
      {
        name: t('Sales_CouponStats'),
      },
      {
        name: t('Sales_CouponStats_Code'),
        value: `${t('Sales_CouponStats_Redemption')} ($)`,
        ' ': t('Sales_CouponStats_Usage'),
      },
      ...keys.map((k) => {
        const item = dataTable[k];
        return {
          name: item.code,
          value: parseFloat(`${item.totalRedemption}`).toFixed(2),
          ' ': item.usageCount,
        };
      }),
    ]);
  }, [dataTable]);

  return (
    <>
      <Typography
        sx={{ fontWeight: 'bold', lineHeight: 1, fontSize: 14 }}
        color="text.secondary"
        gutterBottom
      >
        {t('Sales_CouponStats')}
      </Typography>
      <If condition={!isEmptyOrNil(couponIds)}>
        <Then>
          <TableContainer className={classes.statsListingTable}>
            <Table stickyHeader aria-label="sticky table" size="small">
              {/* table header */}
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('Sales_CouponStats_Code')}</TableCell>
                  <TableCell align="right">{t('Sales_CouponStats_Redemption')}($)</TableCell>
                  <TableCell align="right" width={50}>
                    {t('Sales_CouponStats_Usage')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* table body */}
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
        </Then>
        <Else>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t('Sales_NoData')}
          </Typography>
        </Else>
      </If>
    </>
  );
};

export default CouponStatsTable;
