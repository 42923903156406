import React, { useContext, useState } from 'react';
import { Login } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { If, Then, Else } from 'react-if';

import { UserContext } from 'bos_common/src/context/UserContext';
import PlatformFooter from 'bos_common/src/components/PlatformFooter';
import VersionNumber from 'bos_common/src/components/VersionNumber';
import { isEmptyOrNil } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 300,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .headerWrap': {
        borderRadius: 0,
      },
      '& .header': {
        padding: '12px 12px 12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .blue-background-section': {
        padding: '8px 0px 8px 0px',
        backgroundColor: theme.palette.background.default,
      },
      '& .content': {
        flexGrow: 1,
      },
    },
  })
);

interface SideMenuContainerProps {
  children: JSX.Element
}

const SideMenuContainer = (props: Readonly<SideMenuContainerProps>) => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { children } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' ||
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const RenderMenuActions = () => (
    <Box
      className={classes.drawer}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {children}

      <PlatformFooter subtitle='Merchant' />
      <VersionNumber />
    </Box >
  );

  return (
    <If condition={!isEmptyOrNil(user)}>
      <Then>
        <div>
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)}>
            <MenuIcon fontSize="large" />
          </IconButton>
          <Drawer
            variant="temporary"
            ModalProps={{
              keepMounted: true,
            }}
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <RenderMenuActions />
          </Drawer>
        </div>
      </Then>
      <Else>
        <IconButton onClick={() => { history.push('/login') }}>
          <Login fontSize="large" />
        </IconButton>
      </Else>
    </If>
  )
};

export default SideMenuContainer;
