import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';

// src
import { AppContext } from '../../context/AppContext';
import useFetchMerchant from '../../services/useFetchMerchant';
import MerchantBizsettings from './MerchantBizsettings';

interface IMerchantReportsParams {
  merchantUsername: string;
}

const BizSettingsPage = () => {
  const { merchantUsername } = useParams<IMerchantReportsParams>();
  const { merchant } = useContext(AppContext);

  if (!merchantUsername && !merchant) {
    return <Redirect to="/" />;
  }

  useFetchMerchant({ merchantUsername });

  return (
    <MerchantBizsettings />
  )
};

export default BizSettingsPage;
