import React, { useEffect, useState } from "react";
import { Add } from "@material-ui/icons";
import { If, Then } from "react-if";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Box, Button, createStyles, Divider, makeStyles, Stack, Theme, Typography } from "@material-ui/core";

import { Merchandise } from "bos_common/src/types/MerchandiseType";
import MerchantPackage from "bos_common/src/types/crm/MerchantPackageType";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { isEmptyOrNil } from "../../utils";
import AddClassesDialog from "./AddClassesDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.07)",
      borderRadius: theme.spacing(1),
    },
  })
);

export enum ClassesType {
  eligibleClasses = 'eligibleClasses',
  eligibleSubscriptions = 'eligibleSubscriptions',
}

type EligibleClassesListProps = {
  name: ClassesType,
  message: string,
}

const EligibleClassesList = (props: EligibleClassesListProps) => {
  const { name, message } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const { watch, setValue, trigger, register, formState: { errors } } = useFormContext();

  const eligibleClassesWatch = watch(name) as Array<Merchandise | MerchantPackage | MerchantMembership>;

  if(isEmptyOrNil(name)) return null;

  useEffect(() => {
    if(!isEmptyOrNil(eligibleClassesWatch)) return;

    register(name, { required: true })
  }, [name, eligibleClassesWatch])

  const handleRemoveClick = (classId: string) => {
    const updatedList = eligibleClassesWatch.filter((item) => `${item.id}` !== classId);
    register(name);
    setValue(name, updatedList || []);
    trigger(name)
  }

  const colorType = !isEmptyOrNil(errors[name]) ? "error": "default";

  return (
    <Box className={classes.root}>
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography p={1.2} pl={2} color={colorType} variant="body1" fontWeight="700">
          {t('Service_EligibleClasses')}*
        </Typography>
        <If condition={!isEmptyOrNil(errors[name])}>
          <Then>
            <Typography color={colorType} variant="body2">{t('Service_FieldRequired')}</Typography>
          </Then>
        </If>
      </Stack>

      <Divider />

      <Typography p={2} variant="body1">{message}</Typography>
      <Divider />

      {
        !isEmptyOrNil(eligibleClassesWatch) && eligibleClassesWatch?.map((data) => {
          const { name, id } = data || {};

          return (
            <div key={id}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
                <Typography>{name}</Typography>
                <Button onClick={() => handleRemoveClick(`${id}`)}>{t('Remove')}</Button>
              </Stack>
              <Divider />
            </div>
          )
        })
      }

      <Box p={2}>
        <Button sx={{ p: 0 }} onClick={() => setShowModal(true)}>
          <Add /> {t('Service_AddEligibleClassesMessage')}
        </Button>
      </Box>

      <If condition={showModal}>
        <If condition={name === ClassesType.eligibleClasses}>
          <AddClassesDialog
            isOpen={showModal}
            setOpen={setShowModal}
          />
        </If>
      </If>
    </Box>
  )
}

export default EligibleClassesList;
