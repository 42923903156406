import React, { useContext, useState } from 'react';
import {
  any,
  pluck,
  pipe,
  map,
  trim,
  isEmpty,
  propOr,
} from 'ramda';
import {
  CardContent,
  Typography,
  Chip,
  createStyles,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  Slider,
  Paper,
  TextField,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { Save } from '@material-ui/icons';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';

import { Merchant } from '../services/models';
import { getAuthHeaders } from '../bos_common/src';
import { UserContext } from '../bos_common/src/context/UserContext';
import { FabContainer } from '../bos_common/src/components/FabContainers';
import {
  MerchantOrderingConfig,
  TipData,
} from '../bos_common/src/types/MerchantOrderingConfigType';

import AppToastMessage from './common/AppToastMessage';
import SwitchFormField from './common/FormFields/SwitchFormField';
import { isEmptyOrNil } from '../utils';
import FieldWrapper from './common/FormFields/FieldWrapper';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';


type FormValues = Partial<MerchantOrderingConfig>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      '& .inputRow': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        '& .MuiFormHelperText-root': {
          marginLeft: theme.spacing(2),
        },
      },
      '& .MuiInputLabel-root': {
        backgroundColor: 'rgb(245, 250, 255)',
      },
      '& .MuiButtonBase-root': {
        '& a': {
          textDecoration: 'none',
        },
      },
      '& .MuiSlider-markLabel': {
        top: 26,
      },
    },
  })
);

const getDefaultFormValues = (merchant: Merchant | undefined): FormValues => {
  const orderingConfig = propOr(undefined, 'orderingConfig', merchant);
  return {
    ...(!isEmptyOrNil(orderingConfig) && orderingConfig),
    taxRate: orderingConfig?.taxRate != null ? orderingConfig.taxRate * 100 : 7.5,
  };
};

// returns the converted values from point format to number format
// i.e. 0.15 will be converted to 15
const getTipsFieldDefaultValue = (orderingConfig?: MerchantOrderingConfig) => {
  const convertedTipValues = pipe(
    propOr([], 'tipOptions'),
    pluck('tipRate'),
    map((tipRate: number) => tipRate * 100)
  )(orderingConfig);
  return convertedTipValues;
};

const Section = ({ title, children }: {
  title: string,
  children: React.ReactElement | React.ReactElement[]
}) => (
  <Paper sx={{p: 2, ml: 1, mr: 1, mb: 2, mt: 1}}>
    <Typography variant="subtitle1" sx={{pb: 2}}>
      {title}
    </Typography>
    {children}
  </Paper>
)

type MerchantOrderingConfigProps = {
  merchant?: Merchant;
  onUpdatedMerchant?: (merchant: Merchant) => void;
};

const MerchantOrderingConfigSettings = (props: MerchantOrderingConfigProps) => {
  const { merchant, onUpdatedMerchant = () => {} } = props;
  const classes = useStyles();
  const { token } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { t } = useTranslation();

  const onKeyDown = (event: React.KeyboardEvent | React.MouseEvent) => {
    const value = event.target && (event.target as any).value;
    if (
      event.type === 'keydown' &&
      (event as React.KeyboardEvent).key === 'Enter' &&
      (isEmpty(value) || isNaN(value))
    ) {
      event.preventDefault();
    }
  };

  const [{ error: updateOrderingConfigError, loading }, executePut] = useAxios(
    {
      url: `/merchants/${merchant?.id}/orderingConfig`,
      method: 'PUT',
      headers: getAuthHeaders(token),
    },
    { manual: true }
  );

  const defaultValues = getDefaultFormValues(merchant);
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    control,
    getValues,
    register,
    watch,
    reset,
  } = useForm<MerchantOrderingConfig>({
    mode: 'onBlur',
    defaultValues,
  });
  const formValues = getValues();

  // @ts-ignore
  const isOrderAheadEnabled = watch('orderAhead', formValues.orderAhead);
  // @ts-ignore
  const isCouponEnabled = watch('enabledCoupons', formValues.enabledCoupons);
  // @ts-ignore
  const isUtensilsAvailable = watch('utensilsAvailable', formValues.utensilsAvailable);
  // @ts-ignore
  const isOpenCheckEnabled = watch('enabledOpenCheck', formValues.enabledOpenCheck);
  // @ts-ignore
  const enableDineInPayment = watch('enableDineInPayment', formValues.enableDineInPayment);
  const showRatings = watch('showRatings');

  const updateOrderingConfig: SubmitHandler<FormValues> = async (updatedOrderingConfig) => {
    const data = {
      ...updatedOrderingConfig,
      disableDineInPayment: !enableDineInPayment,
      taxRate: updatedOrderingConfig?.taxRate != null ? updatedOrderingConfig.taxRate / 100 : 0.075,
    };

    executePut({ data }).then((response) => {
      setShowAlert(true);
      if (response.status === 200) {
        if (response.data) {
          const newMerchant: Merchant = response.data;
          onUpdatedMerchant(newMerchant);
          reset(updatedOrderingConfig);
        }
      }
    });
  };

  const handleTipChange = (tipRates: number[]) => {
    const tipValuesList: Array<number> = pipe(
      map(
        pipe(
          (x: string) => trim(`${x}`),
          (x: number) => ({ tipRate: Number(`${x}`) / 100 })
        )
      )
    )(tipRates);

    // @ts-ignore
    setValue('tipOptions', tipValuesList, { shouldValidate: true });
  };

  const handleOrderAheadChange = (event: Event) => {
    // @ts-ignore
    setValue('numDaysToOrderAhead', event.target.value);
  };

  const registerField = (name: any, options?: any) => {
    const { ref: inputRef, ...inputProps } = register(name, options);

    return { ...inputProps, inputRef };
  };

  return (
    <>
      <SimpleLoader loading={loading || isSubmitting} />

      <AppToastMessage
        showAlert={showAlert}
        onCloseAlert={setShowAlert}
        error={Boolean(updateOrderingConfigError)}
      />

      <CardContent className={classes.root}>
        <form onSubmit={handleSubmit(updateOrderingConfig)}>
          <Grid container justifyContent="space-evenly">
            <Grid item sm={12} md={6}>

              <Section title={"Tip, taxes and 1m points"}>
                <FormControl error={!isEmptyOrNil(errors.tipOptions)} fullWidth className="inputRow">
                  <Controller
                    render={(field) => (
                      <ChipInput
                        {...field}
                        variant="outlined"
                        label={`${t('TipRates')} (%)`}
                        defaultValue={getTipsFieldDefaultValue(merchant?.orderingConfig)}
                        onChange={handleTipChange}
                        clearInputValueOnChange
                        onKeyDown={onKeyDown}
                        onBeforeAdd={(value: any) => !isNaN(value)}
                        chipRenderer={({ chip, className, handleDelete }, key) => (
                          <Chip
                            className={className}
                            key={key}
                            label={Math.round(Number(`${chip}`))}
                            onDelete={handleDelete}
                          />
                        )}
                      />
                    )}
                    name="tipOptions"
                    control={control}
                    rules={{
                      validate: {
                        maxLength: (value: any) =>
                          value && value.length > 3
                            ? (t('OrderingSettings_TipRates_ValidateLength') as string)
                            : undefined,
                        minValue: (value: any) =>
                          value && any((i: TipData) => (i?.tipRate ?? 0) < 0)(value)
                            ? (t('OrderingSettings_TipRates_ValidateMin') as string)
                            : undefined,
                        maxValue: (value: any) =>
                          value && any((i: TipData) => (i?.tipRate ?? 0) > 1)(value)
                            ? (t('OrderingSettings_TipRates_ValidateMax') as string)
                            : undefined,
                      },
                    }}
                  />
                  <FormHelperText>
                    {errors.tipOptions
                      ? (errors.tipOptions as any).message
                      : t('OrderingSettings_TipRates_HelperText')}
                  </FormHelperText>
                </FormControl>

                <TextField
                  fullWidth
                  label={`${t('TaxRate')} (%)`}
                  type="number"
                  className="inputRow"
                  disabled={isSubmitting}
                  error={!!errors.taxRate}
                  defaultValue={defaultValues?.taxRate || 0}
                  helperText={errors.taxRate?.message || ' '}
                  inputProps={{
                    step: '0.001',
                    min: 0,
                    max: 100,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('taxRate', {
                    validate: {
                      lteZero: (value: number) =>
                        value && (value >= 0 || t('OrderingSettings_TaxRate_ValidateZero')),
                      gte100: (value: number) =>
                        value && (value <= 100 || t('OrderingSettings_TaxRate_ValidateHundred')),
                    },
                    valueAsNumber: true,
                  })}
                />

                <TextField
                  fullWidth
                  label={t('OrderingSettings_PointPerDollarSpent')}
                  type="number"
                  className="inputRow"
                  disabled={isSubmitting}
                  defaultValue={defaultValues?.pointsPerDollar}
                  error={!!errors.pointsPerDollar}
                  inputProps={{
                    step: '1',
                    min: 0,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('pointsPerDollar', {
                    validate: {
                      lteZero: (value: number) =>
                        value &&
                        (value >= 0 || t('OrderingSettings_PointPerDollarSpent_ValidateZero')),
                    },
                    valueAsNumber: true,
                  })}
                  helperText={
                    errors.pointsPerDollar
                      ? errors.pointsPerDollar?.message
                      : t('OrderingSettings_PointPerDollarSpent_HelperText')
                  }
                />

                <FormControl fullWidth className="inputRow">
                  <SwitchFormField
                    disabled={isSubmitting}
                    defaultValue={defaultValues?.enabledCoupons || false}
                    registerField={() => registerField('enabledCoupons')}
                    id="accept-coupons-switch"
                    switchLabel={isCouponEnabled ? t('Enabled') : t('Disabled')}
                    fieldLabel={t('AcceptCoupons')}
                  />
                  <FormHelperText>{t('OrderingSettings_AcceptCoupons_HelperText')}</FormHelperText>
                </FormControl>
              </Section>

              <Section title={"Wait time and prep time"}>
                <TextField
                  fullWidth
                  label={t('Base prep time (minute)')}
                  type="number"
                  className="inputRow"
                  disabled={isSubmitting}
                  error={!!errors.basePreparationMinutes}
                  defaultValue={defaultValues?.basePreparationMinutes || 0}
                  helperText={errors.basePreparationMinutes?.message}
                  inputProps={{
                    step: '0.1',
                    min: 0,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('basePreparationMinutes', {
                    validate: {
                      lteZero: (value: number) =>
                        value && (value >= 0 || 'Cannot be less than zero.'),
                    },
                    valueAsNumber: true,
                  })}
                />

                <TextField
                  fullWidth
                  label={t('OrderingSettings_PerItemPrepareTime')}
                  type="number"
                  className="inputRow"
                  disabled={isSubmitting}
                  error={!!errors.averageItemPrepareMinutes}
                  defaultValue={defaultValues?.averageItemPrepareMinutes || 0}
                  helperText={errors.averageItemPrepareMinutes?.message}
                  inputProps={{
                    step: '0.1',
                    min: 0,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('averageItemPrepareMinutes', {
                    validate: {
                      lteZero: (value: number) =>
                        value && (value >= 0 || 'Cannot be less than zero.'),
                    },
                    valueAsNumber: true,
                  })}
                />
              </Section>

              <Section title="Reviews">
                <FormControl fullWidth className="inputRow">
                  <SwitchFormField
                    disabled={isSubmitting}
                    defaultValue={defaultValues?.showRatings}
                    registerField={() => registerField('showRatings')}
                    id="show-merchant-rating"
                    switchLabel={showRatings ? t('Enabled') : t('Disabled')}
                    fieldLabel={t('Merchant_ShowRating')}
                  />
                  <FormHelperText>{t('Merchant_ShowRatingHelp')}</FormHelperText>
                </FormControl>
              </Section>
            </Grid>

            <Grid item sm={12} md={6}>
              <Section title={"Order ahead"}>
                <FormControl fullWidth className="inputRow">
                  <SwitchFormField
                    disabled={isSubmitting}
                    defaultValue={defaultValues?.orderAhead || false}
                    registerField={() => registerField('orderAhead')}
                    id="order-ahead-switch"
                    switchLabel={isOrderAheadEnabled ? t('Enabled') : t('Disabled')}
                    fieldLabel={t('OrderAhead')}
                  />
                  <FormHelperText>{t('OrderingSettings_OrderAhead_HelperText')}</FormHelperText>
                </FormControl>

                <TextField
                  fullWidth
                  label={t('OrderingSettings_PickupStarts')}
                  type="number"
                  className="inputRow"
                  defaultValue={0}
                  inputProps={{
                    step: '10',
                    min: 0,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...registerField('pickupStartsAtOffset')}
                  helperText="Number of minutes when pick up can start after the store opens"
                />

                <FormControl fullWidth className="inputRow">
                  <FieldWrapper fieldLabel={t('OrderingSettings_DaysAllowed')}>
                    <Slider
                      sx={{ width: '70%', display: 'flex', margin: 'auto', paddingBottom: '30px' }}
                      disabled={!formValues.orderAhead}
                      aria-label="orderAhead"
                      defaultValue={merchant?.orderingConfig?.numDaysToOrderAhead}
                      onChange={handleOrderAheadChange}
                      valueLabelDisplay="auto"
                      step={1}
                      marks={[
                        { label: 'Same-day', value: 0 },
                        { label: 1, value: 1 },
                        { label: 2, value: 2 },
                        { label: 3, value: 3 },
                        { label: 4, value: 4 },
                        { label: 5, value: 5 },
                        { label: 6, value: 6 },
                        { label: 7, value: 7 },
                      ]}
                      min={0}
                      max={7}
                    />
                  </FieldWrapper>
                  <FormHelperText>{t('OrderingSettings_DaysAllowed_HelperText')}</FormHelperText>
                </FormControl>

                <FormControl fullWidth className="inputRow">
                  <SwitchFormField
                    disabled={isSubmitting}
                    defaultValue={defaultValues.utensilsAvailable}
                    registerField={() => registerField('utensilsAvailable')}
                    id="utensils-available"
                    switchLabel={isUtensilsAvailable ? t('Available') : t('NotAvailable')}
                    fieldLabel={t('Merchant_UtensilsAvailable')}
                  />
                  <FormHelperText>{t('Merchant_UtensilsAvailableHelp')}</FormHelperText>
                </FormControl>
              </Section>

              <Section title={"QR code dine in"}>
                <FormControl fullWidth className="inputRow">
                  <SwitchFormField
                    disabled={isSubmitting}
                    defaultValue={defaultValues?.enabledOpenCheck || false}
                    registerField={() => registerField('enabledOpenCheck')}
                    id="open-check-switch"
                    switchLabel={isOpenCheckEnabled ? t('Enabled') : t('Disabled')}
                    fieldLabel={t('OpenCheck')}
                  />
                  <FormHelperText>{t('OrderingSettings_OpenCheck_HelperText')}</FormHelperText>
                </FormControl>

                <FormControl fullWidth className="inputRow">
                  <SwitchFormField
                    disabled={isSubmitting}
                    defaultValue={!defaultValues?.disableDineInPayment}
                    registerField={() => registerField('enableDineInPayment')}
                    id="disable-payment-switch"
                    switchLabel={enableDineInPayment ? t('PayApp') : t('PayDesk')}
                    fieldLabel={t('OrderingSettings_Payment')}
                  />
                  <FormHelperText>{t('OrderingSettings_DisablePayment_HelperText')}</FormHelperText>
                </FormControl>
              </Section>
            </Grid>
          </Grid>
          <FabContainer>
            <Fab
              type="submit"
              variant="extended"
              color="primary"
              disabled={isSubmitting || loading || !isValid}
            >
              <Save />
              {t('Save')}
              <If condition={isSubmitting || loading}>
                <Then>
                  <CircularProgress
                    size={'1.5rem'}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      zIndex: 1,
                    }}
                  />
                </Then>
              </If>
            </Fab>
          </FabContainer>
        </form>
      </CardContent>
    </>
  );
};

export default MerchantOrderingConfigSettings;
