export const ADD_PACKAGES_AND_MEMBERSHIPS = 'ADD_PACKAGES_AND_MEMBERSHIPS';
export const UPDATE_PACKAGES = 'UPDATE_PACKAGES';
export const UPDATE_MEMBERSHIPS = 'UPDATE_MEMBERSHIPS';
export const REMOVE_ONE_PACKAGE = 'REMOVE_ONE_PACKAGE';
export const ADD_ONE_PACKAGE = 'ADD_ONE_PACKAGE';
export const ADD_ONE_MEMBERSHIP = 'ADD_ONE_MEMBERSHIP';
export const REMOVE_ONE_MEMBERSHIP = 'REMOVE_ONE_MEMBERSHIP';
export const EDIT_ONE_PACKAGE = 'EDIT_ONE_PACKAGE';
export const EDIT_ONE_MEMBERSHIP = 'EDIT_ONE_MEMBERSHIP';
export const ADD_ONE_TRIAL = 'ADD_ONE_TRIAL';
export const UPDATE_TRIALS = 'UPDATE_TRIALS';
export const EDIT_ONE_TRIAL = 'EDIT_ONE_TRIAL';
export const REMOVE_ONE_TRIAL = 'REMOVE_ONE_TRIAL';
export const ADD_CLASSES = 'ADD_CLASSES';
