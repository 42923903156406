export enum SocketEvent {
  CONNECTION = 'connection',
  NEW_ORDER = 'new_order',
  ORDER_PREPARING = 'order_preparing',
  ORDER_READY = 'order_ready',
  ORDER_REFUNDED = 'order_refunded',
  MERCHANDISE_UPDATED = 'merchandise_updated',
  FUTURE_ORDER_PREP_REMINDER = 'future_order_prep_reminder',
  ORDER_UPDATED = 'order_updated',
  MERCHANT_STATUS_UPDATED = 'merchant_status_updated',
  CALLBOARD_UPDATED = 'callboard_updated'
}

export interface Message {
  event: SocketEvent
  merchantId?: string,
  operatorId?: string,
  orderId?: string,
  payload: { [key: string]: any; };
}