import React from "react";
import { Card, CardContent, createStyles, Fab, makeStyles, Stack, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { Home, Refresh } from "@material-ui/icons";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeButton: {
      margin: `0 ${theme.spacing(1)} !important`
    }
  })
);

type AppErrorPageProps = {
  title?: string | React.ReactNode
}

const AppErrorPage = ({ title = 'Something Went Wrong' }: AppErrorPageProps): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const titleVariant = isMobile ? 'h6' : 'h5'

  const handleBackToHome = () => {
    history.push('/')
    window.location.reload()
  }

  return (
    <div className="container">
      <Card>
        <Typography variant={titleVariant} component='div' sx={{ paddingTop: 2, paddingBottom: 2, textAlign: 'center' }}>{title}</Typography>
      </Card>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={'row'} alignItems='center' justifyContent={'center'}>
            <Fab className={classes.homeButton} variant="extended" color="primary" onClick={handleBackToHome}>
              <Home sx={{ mr: 1 }} /> Back to home
            </Fab>
            <Fab className={classes.homeButton} variant="extended" onClick={() => window.location.reload()}>
              <Refresh sx={{ mr: 1 }} /> Refresh
            </Fab>
          </Stack>
        </CardContent>
      </Card>
    </div >
  );
}

export default AppErrorPage;