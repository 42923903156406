import React, { useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { If, Then, Else } from 'react-if';

import { UserContext } from '../../bos_common/src/context/UserContext';
import { getAuthHeaders } from '../../bos_common/src';

import { Merchant, Order, User } from '../../services/models';
import { AppContext } from '../../context/AppContext';

import MerchantOrdersPageCompact from './commons/Compact';
import MerchantOrdersPageWide from './commons/Wide';
import { merchantsMap, orderMap, userMap } from '../../utils';
import MerchantOrdersContextProvider, { OrderDataMap } from '../../context/MerchantOrders/MerchantOrdersContextProvider';
import axios from '../../bos_common/src/services/backendAxios';

interface OrderData {
  orders: Order[];
  users: User[];
  merchants: Merchant[];
}

class FetchingStatus {
  static isFetching = false;

  static setFetching(fetching: boolean) {
    FetchingStatus.isFetching = fetching;
  }
}

const OperatorOrdersHubPage = () => {
  const { merchant, updatedOrder } = useContext(AppContext);
  const { token } = useContext(UserContext)
  const [ordersData, setOrdersData] = useState<OrderDataMap>();
  const isFetching = FetchingStatus.isFetching;

  const partialUpdateOrders = (newOrders?: Order[], newUsers?: User[], newMerchants?: Merchant[]) => {
    if (newOrders && newOrders.length > 0) {
      setOrdersData({
        orders: { ...ordersData?.orders, ...orderMap(newOrders) },
        users: { ...ordersData?.users, ...userMap(newUsers) },
        merchants: { ...ordersData?.merchants, ...merchantsMap(newMerchants) },
      })
    }
  }

  // 3am in the day
  const minDate = new Date();
  minDate.setHours(3, 0, 0, 0);
  if (minDate.getTime() > Date.now()) {
    minDate.setDate(minDate.getDate() - 1)
  }
  const minTimestampOffset = minDate.toUTCString();

  // fetch orders
  const [{ data: _ordersData, loading: isFetchingOrdersData }, expensiveRefetch] = useAxios<OrderData>({
    url: '/orders-hub',
    headers: getAuthHeaders(token),
    params: {
      minTimestampOffset,
      limit: 400,
    },
  });

  // replace state when do a refetch
  useEffect(() => {
    setOrdersData({
      orders: orderMap(_ordersData?.orders),
      users: userMap(_ordersData?.users),
      merchants: merchantsMap(_ordersData?.merchants),
    });
  }, [_ordersData]);

  // refresh OperatorOrdersHubPage when on a different day
  useEffect(() => {
    if (!isFetchingOrdersData) {
      expensiveRefetch()
    }
  }, [minTimestampOffset])

  useEffect(() => {
    if (updatedOrder) {
      fetchNewOrders(updatedOrder);;
    }

  }, [updatedOrder]);

  const fetchNewOrders = (newOrder?: Order | null) => {
    if (newOrder && !isFetchingOrdersData && !isFetching) {
      FetchingStatus.setFetching(true);
      const orderDate = new Date(newOrder.toPickupTime);
      const fiveMinutesEarlier = new Date(orderDate.getTime() - 5 * 60000).toUTCString();
      axios.get(
        '/orders-hub',
        {
          headers: getAuthHeaders(token),
          params: {
            minTimestampOffset: fiveMinutesEarlier,
            limit: 400,
          },
        },
      ).then((response) => {
        if (response.status === 200) {
          const _newOrderData = response.data as OrderData;
          if (_newOrderData) {
            partialUpdateOrders(_newOrderData.orders, _newOrderData.users, _newOrderData.merchants);
          }
        }
        FetchingStatus.setFetching(false);
      })
    }
  }

  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <MerchantOrdersContextProvider
      updateOrders={partialUpdateOrders}
      ordersData={ordersData}
    >
      <If condition={isWide}>
        <Then>
          <MerchantOrdersPageWide
            merchant={merchant}
            loading={isFetchingOrdersData}
          />
        </Then>
        <Else>
          <MerchantOrdersPageCompact
            merchant={merchant}
            loading={isFetchingOrdersData} />
        </Else>
      </If>
    </MerchantOrdersContextProvider >
  )
};

export default OperatorOrdersHubPage;
