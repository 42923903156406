import { AxiosRequestConfig, AxiosResponse } from "axios";
import { MembershipApiResponseType } from "../bos_common/src/types/crm/ApiType";
import axios, { backendAPIURL } from "../bos_common/src/services/backendAxios";
import { MerchantPackage } from "../bos_common/src/types/crm/MerchantPackageType";

type MerchantPackagesResponse = AxiosResponse<MerchantPackage[]>;
type MerchantMembershipResponse = AxiosResponse<MembershipApiResponseType>;

export const getPackages = (merchantId: string, request: AxiosRequestConfig): Promise<MerchantPackagesResponse> => {
  const url = new URL(`${backendAPIURL}/merchants/${merchantId}/packages`);

  return axios.get<MerchantPackagesResponse, any>(
    url.toString(),
    { headers: request.headers }
  );
}

export const getMembership = (merchantId: string, request: AxiosRequestConfig): Promise<MerchantMembershipResponse> => {
  return axios.get<MerchantMembershipResponse, any>(
    `/merchants/${merchantId}/membership/data`,
    { headers: request.headers }
  );
}

export default {
  getPackages,
  getMembership,
}
