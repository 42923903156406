import React from "react";
import { Fab, styled } from "@material-ui/core";

// added important here so that the outline styles don't get overridden by MUI defaults
const OutlinedFab = styled(Fab)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.white} !important`,
  color: `${theme.palette.secondary.main} !important`,
  border: `1px solid ${theme.palette.secondary.main} !important`,

  "&:disabled": {
    border: "none",
  },

  "&:hover": {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

export default OutlinedFab;
