import React from "react";
import { createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: `${theme.spacing(1)} 0`,

      '& img': {
        marginRight: theme.spacing(1)
      }
    },
  }),
);

interface PlatformFooterProps {
  title?: string
  subtitle?: string
}
const PlatformFooter = (props: PlatformFooterProps): React.ReactElement => {
  const classes = useStyles()
  const { title = "One Market", subtitle = "Buy local, get rewards" } = props

  return (
    <div className={classes.root}>
      <img src="/logo.svg" alt="One Market" width="40" decoding="async" />
      <Link href="/">
        <div>{title}</div>
        <Typography variant="caption" color={'secondary'}>
          {subtitle}
        </Typography>
      </Link>
    </div>
  )
}

export default PlatformFooter