import React from "react"
import { Box, createStyles, makeStyles, Theme, useMediaQuery } from "@material-ui/core";

import BottomDrawer from "bos_common/src/components/BottomDrawer"
import CalendarEventForm from "./CalendarEventForm";
import { BigCalendarEvent } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .addItemWrapper": {
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          width: `50vw`,
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: "70vh"
        }
      },
      "& .toolbarWrapper": {
        height: "100%",

        [theme.breakpoints.up('sm')]: {
          borderTopRightRadius: 0,
        }
      }
    }
  })
)

type AddServiceDrawerProps = {
  eventInfo: { isOpen: boolean, eventData: Partial<BigCalendarEvent> };
  closeDrawer: () => void;
  onOpen: (_: any) => void;
}

const AddServiceDrawer = ({ eventInfo, onOpen, closeDrawer }: AddServiceDrawerProps) => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { isOpen = false, eventData } = eventInfo

  return (
    <BottomDrawer
      open={isOpen}
      setOpen={() => onOpen(eventData as Partial<BigCalendarEvent>)}
      onClose={() => closeDrawer()}
      fullWidth
      anchor={isMobile ? "bottom" : "right"}
      className={classes.root}
    >
      <Box className="addItemWrapper">
        {isOpen && <CalendarEventForm closeDrawer={closeDrawer} eventData={eventData} />}
      </Box>
    </BottomDrawer>
  )
}

export default AddServiceDrawer;