import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Divider,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { Web } from '@material-ui/icons';
import QRCode from 'qrcode.react';
import { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { If } from 'react-if';
import { ColoredPaper } from '../../bos_common/src/components/Papers';
import { isEmptyOrNil } from '../../utils';
import AppFab from '../common/AppFab';
import HtmlToCanvas, { HtmlToCanvasRefProps } from '../common/HtmlToCanvas';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'unset',
      marginTop: theme.spacing(2),

      '& .QRCode-card': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),

        '& .QRCode-paper': {
          margin: theme.spacing(1),
          padding: theme.spacing(3),
        },
        '& .MuiDivider-root': {
          width: '100%',
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
        '& .linkToStore': {
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          color: '#000000',
          marginBottom: theme.spacing(1),
          '& .MuiSvgIcon-root': {
            paddingRight: theme.spacing(1),
          },
        },
      },
      '& .buttonActions': {
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          '& .MuiFab-root': {
            marginBottom: theme.spacing(1),
          },
        },
      },
    },
  })
);

export type RenderMerchantQRCodeData = {
  url: string;
  fileName: string;
  title: string;
  subtitle?: string;
  slogan?: string;
  logo?: string;
};
const RenderMerchantQRCode = (props: RenderMerchantQRCodeData) => {
  const classes = useStyles();
  const canvasRef = createRef<HtmlToCanvasRefProps>();
  const { url, title, subtitle, slogan, logo, fileName } = props;

  const { t } = useTranslation();

  return (
    <ColoredPaper>
      <Card className={classes.root}>
        <HtmlToCanvas ref={canvasRef}>
          <CardContent className="QRCode-card">
            <If condition={!isEmptyOrNil(logo)}>
              <Avatar color="secondary" alt={title} src={logo} />
            </If>

            <Typography variant="h5" component="div">
              {title}
            </Typography>

            <If condition={!isEmptyOrNil(subtitle)}>
              <Typography variant="subtitle1" color="textSecondary" component="div">
                {subtitle}
              </Typography>
            </If>

            <Paper elevation={6} className="QRCode-paper">
              <QRCode
                value={url}
                size={256}
                level="Q"
                bgColor="#ffffff"
                fgColor="#000000"
                renderAs="svg"
                imageSettings={{
                  src: '/qrcode_logo.svg',
                  x: undefined,
                  y: undefined,
                  height: 60,
                  width: 60,
                  excavate: true,
                }}
              />
            </Paper>

            <Link href={url} className="linkToStore">
              <Web />
              {url}
            </Link>
            <Divider />
            <If condition={!isEmptyOrNil(slogan)}>
              <Typography variant="subtitle1" component="div">
                {slogan}
              </Typography>
            </If>
          </CardContent>
        </HtmlToCanvas>

        <CardActions className="buttonActions">
          <AppFab
            variant="extended"
            color="primary"
            size="medium"
            onClick={() => canvasRef.current?.print()}
          >
            {t('Print')}
          </AppFab>
          <AppFab
            variant="extended"
            color="primary"
            size="medium"
            onClick={() => canvasRef.current?.download(fileName)}
          >
            {t('QRCodes_DownloadAsImage')}
          </AppFab>
        </CardActions>
      </Card>
    </ColoredPaper>
  );
};

export default RenderMerchantQRCode;
