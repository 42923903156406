import React from 'react'
import { SvgIcon } from "@material-ui/core";

function StoreInfoIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 18, height: 16 }} viewBox="0 0 18 16" {...props} >
      <path d="M17.3325 5.16797C17.3326 5.01673 17.2914 4.86835 17.2133 4.7388L14.9558 0.976302C14.8071 0.730336 14.5976 0.526779 14.3475 0.385212C14.0973 0.243645 13.8149 0.168832 13.5275 0.167969H4.47083C3.88917 0.167969 3.34167 0.477969 3.0425 0.977135L0.784999 4.7388C0.706957 4.86835 0.665754 5.01673 0.665833 5.16797C0.665833 6.00547 0.987499 6.76297 1.49917 7.34964V15.168C1.49917 15.389 1.58696 15.6009 1.74324 15.7572C1.89952 15.9135 2.11149 16.0013 2.3325 16.0013H8.99917C9.22018 16.0013 9.43214 15.9135 9.58842 15.7572C9.7447 15.6009 9.8325 15.389 9.8325 15.168V11.0013H13.1658V15.168C13.1658 15.389 13.2536 15.6009 13.4099 15.7572C13.5662 15.9135 13.7782 16.0013 13.9992 16.0013H15.6658C15.8868 16.0013 16.0988 15.9135 16.2551 15.7572C16.4114 15.6009 16.4992 15.389 16.4992 15.168V7.34964C17.0108 6.76297 17.3325 6.00547 17.3325 5.16797ZM15.6525 5.37714C15.6012 5.77956 15.4051 6.14951 15.1008 6.41778C14.7965 6.68605 14.4048 6.83424 13.9992 6.83464C13.08 6.83464 12.3325 6.08714 12.3325 5.16797C12.3325 5.1113 12.3117 5.0613 12.3 5.00797L12.3167 5.00464L11.6825 1.83464H13.5275L15.6525 5.37714ZM7.33833 5.22214L8.015 1.83464H9.9825L10.66 5.22214C10.6308 6.1163 9.89917 6.83464 8.99917 6.83464C8.09917 6.83464 7.3675 6.1163 7.33833 5.22214ZM4.47083 1.83464H6.31583L5.6825 5.00464L5.69917 5.00797C5.68667 5.0613 5.66583 5.1113 5.66583 5.16797C5.66583 6.08714 4.91833 6.83464 3.99917 6.83464C3.59349 6.83424 3.20186 6.68605 2.89755 6.41778C2.59324 6.14951 2.3971 5.77956 2.34583 5.37714L4.47083 1.83464ZM7.3325 11.8346H3.99917V9.33463H7.3325V11.8346Z" fill="#1AAA5D" />
    </SvgIcon>
  );
}

export default StoreInfoIcon