import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';

type TypographyEllipsisProps = {
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
  // content?: JSX.Element | string | number;
  sx?: SxProps<Theme>;
  children?: JSX.Element | string;
};

/**
 * Abbreviated Text
 * @param prefix prefix
 * @param children content
 * @param suffix  suffix
 * @param sx
 * @returns
 */
const TypographyEllipsis = ({ prefix, children, suffix, sx }: TypographyEllipsisProps) => (
  <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
    {prefix}
    <Typography component="span" noWrap sx={{ display: 'inline-block', ...sx }}>
      {children}
    </Typography>
    {suffix}
  </Typography>
);

export default TypographyEllipsis;
