import React, {
  AppBar,
  Chip,
  createStyles,
  Theme,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import classNames from "classnames";
import { rgba } from "polished";
import { Link } from "react-scroll";

import { MerchandiseCategory } from "../../types/MerchandiseType";
import { ColoredPaper } from "../Papers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoriesList: {
      display: "flex",
      flexDirection: "row",
      overflowY: "hidden",
      overflowX: "auto",
      margin: 0,
      whiteSpace: "nowrap",
      listStyleType: "none",
      paddingLeft: theme.spacing(2.5),
      flexWrap: "nowrap",
      justifyItems: "center",

      "& .MuiChip-colorSecondary": {
        color: "white",
      },
      "& .item": {
        display: "inline-block",
        padding: `${theme.spacing(2)} 0`,
        marginRight: theme.spacing(2),

        "&::last-child": {
          marginRight: 0,
        },

        "& .active": {
          "& .link": {
            color: theme.palette.primary.main,
            background: rgba(theme.palette.primary.main, 0.15),
            borderColor: theme.palette.primary.main,
          },
        },

        "& .link": {
          color: theme.palette.secondary.light,
          borderRadius: 8,
          padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
          fontSize: "14px",
          background: rgba(theme.palette.secondary.light, 0.15),
          borderColor: theme.palette.secondary.light,

          "& .MuiChip-label": {
            padding: 0,
            marginTop: -1,
          },
        },
      },
    },
    toolbarContainer: {
      position: "fixed",
      width: "100%",
      zIndex: 10,
      backgroundColor: "#fff !important",
      borderRadius: 0,
    },
    toolbarPlaceholder: {
      height: "64px",
      marginBottom: "15px",
    },
  })
);

const sortCategoriesByDisplayOrder = (
  a: MerchandiseCategory,
  b: MerchandiseCategory
) => a.displayOrder - b.displayOrder;

type MerchandiseCategoriesHeaderProps = {
  categories?: Array<MerchandiseCategory>;
  setSelectedCategory: (name: number) => void;
  selectedCategory: number;
  loading?: boolean;
  isToolbar?: boolean;
  customAppbarClass?: string;
  customToolbarClass?: string;
};
const MerchandiseCategoriesHeader = (
  props: MerchandiseCategoriesHeaderProps
): JSX.Element | null => {
  const classes = useStyles();
  const {
    categories = [],
    setSelectedCategory,
    selectedCategory,
    // loading = false,
    isToolbar = false,
    customAppbarClass,
    customToolbarClass,
  } = props;

  if (categories.length === 0) return null;

  const onSetActive = (catId: number) => {
    setSelectedCategory(catId)
    const elem = document.getElementById(`cat-${catId}`);
    if (elem) {
      elem.scrollIntoView()
      elem.scroll({
        left: elem.offsetLeft
      })
    }
  }

  const CategoriesList = (
    <ul className={classes.categoriesList}>
      {categories?.sort(sortCategoriesByDisplayOrder).map((category) => (
        <li key={category.id} className="item" id={`cat-${category.id}`}>
          <Link
            activeClass="active"
            to={`category-${category.id}`}
            spy
            smooth
            duration={500}
            offset={-150}
            onSetActive={() => onSetActive(category.id)}
          >
            <Chip
              label={category.name}
              variant="outlined"
              className="link"
              clickable
              color={selectedCategory === category.id ? "secondary" : "default"}
            />
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      {isToolbar ? (
        <>
          <Toolbar
            component={ColoredPaper}
            elevation={3}
            className={classNames([
              classes.toolbarContainer,
              customToolbarClass,
            ])}
          >
            {CategoriesList}
          </Toolbar>
          <div className={classes.toolbarPlaceholder} />
        </>
      ) : (
        <AppBar
          component={ColoredPaper}
          position="sticky"
          color="default"
          className={classNames([customAppbarClass])}
        >
          {CategoriesList}
        </AppBar>
      )}
    </>
  );
};

export default MerchandiseCategoriesHeader;
