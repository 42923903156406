import React from 'react'
import { SvgIcon } from "@material-ui/core";

function OrderingSettingsIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 18, height: 16 }} viewBox="0 0 18 16" {...props} >
      <path d="M3.16666 15V5M14.8333 0V3.33333V0ZM14.8333 15V6.66667V15ZM9 0V10V0ZM9 15V13.3333V15ZM3.16666 0V1.66667V0Z" stroke="#1AAA5D" stroke-width="1.66667" stroke-linecap="round" />
      <path d="M13.1667 4.9987C13.1667 5.91917 13.9129 6.66536 14.8333 6.66536C15.7538 6.66536 16.5 5.91917 16.5 4.9987C16.5 4.07822 15.7538 3.33203 14.8333 3.33203C13.9129 3.33203 13.1667 4.07822 13.1667 4.9987Z" stroke="#1AAA5D" stroke-width="1.66667" stroke-linecap="round" fill='none'/>
      <path d="M7.33333 11.6667C7.33333 12.5871 8.07952 13.3333 9 13.3333C9.92047 13.3333 10.6667 12.5871 10.6667 11.6667C10.6667 10.7462 9.92047 10 9 10C8.07952 10 7.33333 10.7462 7.33333 11.6667Z" stroke="#1AAA5D" stroke-width="1.66667" stroke-linecap="round"  fill='none'/>
      <path d="M1.5 3.33464C1.5 4.25511 2.24619 5.0013 3.16667 5.0013C4.08714 5.0013 4.83334 4.25511 4.83334 3.33464C4.83334 2.41416 4.08714 1.66797 3.16667 1.66797C2.24619 1.66797 1.5 2.41416 1.5 3.33464Z" stroke="#1AAA5D" stroke-width="1.66667" stroke-linecap="round" fill='none'/>
    </SvgIcon>
  );
}

export default OrderingSettingsIcon