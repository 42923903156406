import React, { useContext, useEffect, useState } from "react"
import { createStyles, Fab, makeStyles, Stack, Theme, Typography } from "@material-ui/core";
import useAxios from "axios-hooks";
import { Else, If, Then } from "react-if";
import { format } from "date-fns";

import { MerchantCustomer } from "bos_common/src/types/crm/MerchantCustomerType";
import { getAuthHeaders, isEmptyOrNil } from "bos_common/src";
import SimpleLoader from "bos_common/src/components/SimpleLoader";
import CustomerSubscriptionBalance from "bos_common/src/types/crm/CustomerSubscriptionBalanceType";
import { UserContext } from "bos_common/src/context/UserContext";

import { Merchant } from "../../../../services/models";

import MembershipDetailsCard from "./MembershipDetailsCard";
import AddSubscriptionDialog from "./AddSubscriptionDialog";
import PastMembershipsDialog from "./PastMembershipsDialog";
import SubscriptionsListWrapper from "./SubscriptionsListWrapper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .emptyMessage": {
        margin: "auto",
        display: "block",
        marginTop: theme.spacing(2),
      }
    },
  })
);

type CustomerMembershipManagementProps = {
  merchant?: Merchant;
  customer: MerchantCustomer;
}

const CustomerMembershipManagement = (props: CustomerMembershipManagementProps) => {
  const { merchant, customer } = props;
  const classes = useStyles();
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [{ data: subscriptionsList = [], loading: subscriptionLoading }, refetchSubscriptions] = useAxios<Array<CustomerSubscriptionBalance>>({
    url: `/customers/${customer?.id}/subscriptions`,
    params: { merchantId: props.merchant?.id },
    headers: getAuthHeaders(token),
  }, { manual: true })

  useEffect(() => {
    if (isEmptyOrNil(customer)) return;
    refetchSubscriptions()
  }, [customer?.id])

  const [showMembershipOpenDialog, setMembershipOpenDialog] = useState<boolean>(false);
  const [showPastMembershipsDialog, setShowPastMembershipsDialog] = useState<boolean>(false);

  const expiredSubscriptions = subscriptionsList.filter((item) => item.isExpired || item.isDeleted);
  const subscribedItems = subscriptionsList.filter((item) => !item.isDeleted);

  return (
    <div className={classes.root}>
      <SimpleLoader loading={loading || subscriptionLoading} />
      <SubscriptionsListWrapper
        handleAddItem={!isEmptyOrNil(subscriptionsList) ? () => setMembershipOpenDialog(true) : undefined}
        handleShowPast={!isEmptyOrNil(subscriptionsList) || !isEmptyOrNil(expiredSubscriptions) ? () => setShowPastMembershipsDialog(true) : undefined}
      >
        <div>
          <If condition={isEmptyOrNil(subscriptionsList)}>
            <Then>
              <Fab
                variant="extended"
                color="primary"
                className="emptyMessage"
                onClick={() => setMembershipOpenDialog(true)}>
                Add a Membership or Package
              </Fab>
            </Then>
            <Else>
              <If condition={isEmptyOrNil(subscribedItems)}>
                <Typography textAlign={"center"}>No active membership/packages found for this customer</Typography>
              </If>
            </Else>
          </If>

          <Stack gap={1.5}>
            {
              subscribedItems?.map((subscription) => {
                const { id, merchantPackage, merchantMembership } = subscription;
                if (!isEmptyOrNil(merchantPackage)) {
                  return (
                    <MembershipDetailsCard
                      subscription={subscription}
                      customer={customer}
                      refetchSubscriptions={refetchSubscriptions}
                      setLoading={setLoading}
                      key={`subscription_${id}`}
                    />
                  )
                }

                if (!isEmptyOrNil(merchantMembership)) {
                  return (
                    <MembershipDetailsCard
                      subscription={subscription}
                      customer={customer}
                      refetchSubscriptions={refetchSubscriptions}
                      setLoading={setLoading}
                      key={`subscription_${id}`}
                    />
                  )
                }

                return null;
              })
            }
          </Stack>

          <If condition={showMembershipOpenDialog}>
            <AddSubscriptionDialog
              open={showMembershipOpenDialog}
              setOpen={setMembershipOpenDialog}
              merchant={merchant}
              customer={customer}
              refetchSubscriptions={refetchSubscriptions}
              setLoading={setLoading}
              subscriptionsList={subscriptionsList}
            />
          </If>

          <If condition={showPastMembershipsDialog}>
            <PastMembershipsDialog
              open={showPastMembershipsDialog}
              setOpen={setShowPastMembershipsDialog}
              expiredSubscriptions={expiredSubscriptions}
            />
          </If>
        </div>
      </SubscriptionsListWrapper>
    </div>
  )
}

export default CustomerMembershipManagement;