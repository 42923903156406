import { createStyles, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { Language } from '@material-ui/icons';
import React, { useContext } from 'react';
import { LOCAL_LIST, SupportedLocales } from '../../constants/locale';
import { AppContext } from '../../context/AppContext';
import i18n from '../../utils/i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 16,
      '& .MuiInput-root': {
        ['&::before']: {
          borderBottom: 0
        },
        ['&::after']: {
          borderBottom: 0
        },
        ["&:hover:before"]: {
          borderBottom: 0
        }
      }
    },
  }),
);

const LocalesSelect: React.FC<{}> = ({ }) => {
  const style = useStyles()
  const { locale, setLocale } = useContext(AppContext);

  const changeLocales = (v: SupportedLocales) => {
    localStorage.setItem('bos_locale', v);
    i18n.changeLanguage(v);
    setLocale(v);
  }

  return (
    <div className={style.root}>
      <Language style={{ fontSize: 'large' }} color={"secondary"}/>&nbsp;
      <Select value={LOCAL_LIST.length ? locale : ""} onChange={(v) => { changeLocales(v.target.value as SupportedLocales) }}>
        {
          LOCAL_LIST.map(locale => (
            <MenuItem value={locale.value} key={locale.value}>{locale.label}</MenuItem>
          ))
        }
      </Select>
    </div>
  )
}

export default LocalesSelect;