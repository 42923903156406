import React, { useContext, useState } from 'react'
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

import { User } from '../../services/models';
import OutlinedFab from '../common/OutlinedFab';
import PassCodeConfirmationDialog from './PassCodeConfirmationDialog';
import ConfirmationAlert from '../../bos_common/src/components/ConfirmationAlert';
import { UserContext } from '../../bos_common/src/context/UserContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .outlined-fab": {
        marginBottom: theme.spacing(2),
      },
    }
  })
)

type OneMOperatorFabProps = {
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  onSuccessAuth: (user: User, token: string) => void;
  outlined?: boolean;
  className?: string
}
const OneMOperatorFab = (props: OneMOperatorFabProps) => {
  const classes = useStyles();
  const {
    onClick,
    label = "Confirm",
    disabled = false,
    onSuccessAuth,
    outlined = false,
    className = ''
  } = props;

  const [isConfirmationDialogOpen, toggleConfirmationDialog] = useState<boolean>(false)
  const FabComponent = outlined ? OutlinedFab : Fab;

  const { token, user } = useContext(UserContext);

  const onFabClick = () => {
    onClick && onClick();
    toggleConfirmationDialog(true);
  }
  const onCancel = () => toggleConfirmationDialog(false)
  const onConfirm = () => onSuccessAuth(user!, token)

  const fabClasses = classNames(className, {
    "outlined-fab": outlined
  })

  return (
    <div className={classes.root}>
      <FabComponent
        variant='extended'
        onClick={onFabClick}
        disabled={disabled}
        className={fabClasses}
        {...(!outlined && { color: 'primary' })}
      >
        {label}
      </FabComponent>
      <ConfirmationAlert
        isOpen={isConfirmationDialogOpen}
        title="Refund Order"
        onCancel={onCancel}
        onConfirm={onConfirm}
      >
        Are you sure you want to refund this order?
      </ConfirmationAlert>
    </div>
  )
}

export default OneMOperatorFab;