import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';

import CardWrapper from '../CardWrapper';
import BusinessHoursDialog from './BusinessHoursDialog';
import { isEmptyOrNil } from '../../utils';
import { getMerchant } from '../../redux/slice/merchant/merchantSelector';
import MerchantStoreHours from "./MerchantStoreHours"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      paddingTop: 0,

      '&>.timings-container': {
        marginBottom: theme.spacing(2),
      },

      '& .editBusinessButton': {
        borderRadius: 5,
        height: '38px',

        '& .MuiFab-label': {
          textTransform: 'capitalize',
          fontSize: '12px',
          fontWeight: 600,
        },
      },

      '& .hourDetails': {
        marginBottom: theme.spacing(1.5),
        fontSize: 16,
        fontWeight: 600,
        color: '#364245',

        '& .daysList': {
          marginBottom: theme.spacing(1),
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          alignItems: 'flex-start',
          gridRowGap: theme.spacing(1),
          gridColumnGap: theme.spacing(1),
        },

        '& .shiftsList': {
          fontWeight: 'normal',
          marginBottom: theme.spacing(0.7),
          fontSize: 14,
        },
      },
    },
  })
);

const BusinessHoursDetails = () => {
  const [isBusinessHoursDialogOpen, setBusinessHoursDialogOpen] = useState<boolean>(false);

  const classes = useStyles();

  const { t } = useTranslation();

  const merchant = useAppSelector(getMerchant);

  const handleBusinessDialogOpen = () => {
    setBusinessHoursDialogOpen(true);
  };

  const isHoursEmpty = isEmptyOrNil(merchant?.normalHours) && isEmptyOrNil(merchant?.specialHours);

  return (
    <CardWrapper heading={t('YourBusinessHours')}>
      <div className={classes.root}>
        <MerchantStoreHours merchant={merchant!} />

        <Fab
          variant="extended"
          color="secondary"
          className="editBusinessButton"
          onClick={handleBusinessDialogOpen}
        >
          {!isHoursEmpty ? t('EditBusinessHours') : t('AddBusinessHours')}
        </Fab>
      </div>
      <BusinessHoursDialog open={isBusinessHoursDialogOpen} setOpen={setBusinessHoursDialogOpen} />
    </CardWrapper>
  );
};

export default BusinessHoursDetails;
