import { MerchantCoupon } from "./MerchantCouponType";
import { Merchant } from "./MerchantType";
import { UserPromotionEntry } from "./UserTypes";

export enum PromotionValueType {
  ABSOLUTE_DISCOUNT = 'absolute_discount',
  PERCENTAGE_DISCOUNT = 'percentage_discount',
  PERCENTAGE_REWARDS = 'percentage_rewards',
  ABSOLUTE_REWARDS = 'absolute_rewards'
}

export enum PromotionCriteriaType {
  NONE = 'none',
  NEW_USER = 'new_user',
  ONCE_PER_USER = 'once_per_user',
  MINIMUM_SPEND = 'minimum_spend',
  MINIMUM_QUANTITY = 'minimum_quantity',
  MAXIMUM_SPEND = 'maximum_spend',
  DEVICE_TYPE = 'device_type',
  REFERRAL_TYPE = 'referral_type',
  COMBO_TYPE = 'combo_type',
}

export interface PromotionRoutine {
  dayOfWeek: number;
  startMinOffset: number;
  endMinOffset: number;
}

export enum MerchantPromotionType {
  MERCHANT = 'merchant',
  PLATFORM = 'platform'
}

export interface MerchantPromotion {
  id: number;
  merchant: Merchant;
  type: MerchantPromotionType;
  code?: string;
  criteriaType: PromotionCriteriaType;
  valueType: PromotionValueType;
  value: number;    // dreprecated, store on coupon
  limit: number;    // dreprecated, store on coupon
  usedCount: number;  // dreprecated, store on coupon
  startDate: Date;
  endDate: Date;
  live: boolean;
  createdAt: Date;
  updtedAt: Date;
  version: number;
  title: string;
  description: string;
  oneliner: string;
  promotionRoutines?: PromotionRoutine[];
  coupons?: MerchantCoupon[];
  promotionEntryRequired: boolean;
  promotionEntries: UserPromotionEntry[]
}
