import React, { useContext } from 'react';
import { Card, Typography } from '@material-ui/core';

import { CallboardContext } from '../../context/CallboardContext/CallboardContext';
import CallboardOrdersList from './CallboardOrdersList';

const CallboardHeader = () => {
  return (
    <div className='header-container'>
      <div className='main-header'>
        <Typography variant='h4'>
          Ready to Pickup
        </Typography>
      </div>
    </div>
  )
}

const CompletedOrdersCallboard = () => {
  const { completedOrdersList = [] } = useContext(CallboardContext);

  return (
    <Card className='callboard-container completed-callboard'>
      <CallboardHeader />
      <CallboardOrdersList orders={completedOrdersList} itemsPerPage={28} />
    </Card>
  )
}

export default CompletedOrdersCallboard