import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles, Fab, makeStyles, Paper, Table, TableBody,
  TableCell, TableContainer, TableRow, Theme, Typography
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { If } from 'react-if'

// src
import { CustomerType, MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType'
import { FabContainer } from 'bos_common/src/components/FabContainers'
import TypographyEllipsis from 'bos_common/src/components/TypographyEllipsis'

import { isEmptyOrNil } from '../../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: 'auto',

      '& .MuiTable-root': {
        tableLayout: 'fixed'
      }
    }
  })
)

interface CustomerPersonalInformationProps {
  customer: MerchantCustomer;
  editCustomer: (_: MerchantCustomer) => void
}

const CustomerPersonalInformation = ({ customer, editCustomer }: CustomerPersonalInformationProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableContainer elevation={0} component={Paper} className={classes.root}>
      <Table aria-label="personal-info-table" size="medium">
        <TableBody>
          <TableRow>
            <TableCell component="th" width={100}>
              <Typography variant='subtitle1' color="textSecondary">{t("Name")}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>{customer.displayName}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" width={100}>
              <Typography variant='subtitle1' color="textSecondary">{t("Email")}</Typography>
            </TableCell>
            <TableCell>
              <TypographyEllipsis>{isEmptyOrNil(customer.email) ? "-" : customer.email}</TypographyEllipsis>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" width={100}>
              <Typography variant='subtitle1' color="textSecondary">{t("Phone")}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>{customer.phoneNumber}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" width={100}>
              <Typography variant='subtitle1' color="textSecondary">{t("Gender")}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1' sx={{ textTransform: 'capitalize' }}>{customer.gender ?? "-"}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" width={100}>
              <Typography variant='subtitle1' color="textSecondary">{t("Customers_Note")}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>{isEmptyOrNil(customer.note) ? "-" : customer.note}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      
      <If condition={customer.type === CustomerType.MANUALLY_CREATED}>
        <FabContainer alignment="right">
          <Fab
            variant='extended'
            color='primary'
            size="medium"
            onClick={() => editCustomer(customer)}
            disabled={customer.type === CustomerType.AUTO_GENERATED}
          >
            <Edit /> {t("Edit")}
          </Fab>
        </FabContainer>
      </If>
    </TableContainer >
  )
}

export default CustomerPersonalInformation