export interface UseDOMClippingProps {
  // handleAnimationFrame: () => void;
  ids: string[];
  offsetPx?: number;
  debounceMs?: number;
}

export interface DOMClippingProperties {
  bindScrollEvent: () => void;
  cleanUp: () => void;
}

const cache: {[key: string]: Element | null} = {}
const domRefs: {[key: string]: HTMLElement | null} = {}

export const useDOMClipping = (props: Readonly<UseDOMClippingProps>): DOMClippingProperties => {
  let timeoutId: NodeJS.Timeout;
  const { ids, offsetPx, debounceMs } = props;
  const offset = offsetPx || 256;
  const debounce = debounceMs || 15;

  if (!document) {
    throw new Error('window.document is undefined');
  }

  const handleAnimationFrame = () => {
    ids.forEach((id: string) => {
      if (!domRefs[`${id}`]) {
        domRefs[`${id}`] = document.getElementById(`${id}`); 
      }

      const currentEl = domRefs[`${id}`]; 
      const rect = currentEl?.getBoundingClientRect();
  
      // If element is available and it is outside the viewport, cache and remove innerHTML from the DOM
      if (currentEl 
        && currentEl.innerHTML 
        && currentEl.innerHTML !== '' 
        && currentEl.innerHTML !== 'undefined' 
        && rect 
        && (rect.y < -offset || rect.y > window.innerHeight + offset)
      ) {
        if (!cache[`${id}`] && currentEl.innerHTML !== 'undefined' && currentEl.innerHTML !== '') {
          cache[`${id}`] = currentEl.firstElementChild;
        }
        currentEl.innerHTML = '';
        // NOTE: adding/removing css class will improve performance but will make the scroll stuttering
        // temp.classList.value = '';
      }
      // Else if innerHTML is inside the viewport, render the inner html from cache
      else if (currentEl && !currentEl.innerHTML && rect && (rect.y >= -offset && rect.y <= window.innerHeight + offset)) {
        if (cache[`${id}`] && !currentEl.innerHTML) {
          currentEl.appendChild(cache[`${id}`] || document);
          // NOTE: adding/removing css class will improve performance but will make the scroll stuttering
          // temp.classList.value = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-lg-4 css-1qsuyyg-MuiGrid-root";
        }
      }
    });
  }

  const handleScroll = () => {
    // Debounce event handler
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      requestAnimationFrame(handleAnimationFrame);
    }, debounce);
  }

  const bindScrollEvent = () => {
    document.onscroll = handleScroll;
  }

  const cleanUp = () => {
    document.removeEventListener('scroll', handleScroll);
    clearTimeout(timeoutId as NodeJS.Timeout);
  }

  return {
    bindScrollEvent, 
    cleanUp,
  }
}

export default useDOMClipping;
