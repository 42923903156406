import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';

import AppContextProvider from './context/AppContextProvider';
import UserContextProvider from './bos_common/src/context/UserContextProvider';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import store from './redux/store';
import App from './App';

import './index.css';

const history = createBrowserHistory();

ReactDOM.render(
  <Suspense fallback={<div>Loading... </div>}>
    <Provider store={store}>
      <BrowserRouter>
        <UserContextProvider>
          <AppContextProvider>
            <App history={history} />
          </AppContextProvider>
        </UserContextProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
