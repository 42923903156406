import React from "react";

import { Order, OrderStatus, PreAuthStatus } from "../types/OrderTypes";
import { createStyles, IconButton, makeStyles, Stack, Theme, Tooltip, Typography } from "@material-ui/core";
import { If, Then } from "react-if";
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { getOrderPriceBreakdown, getOrderTotalItems } from "../services/orderPricingUtils";
import PriceSummary from "./PriceSummary";
import renderPrice from "./Price";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& .priceRow': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .totalPrice': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
  }),
)

export const OrderSummaryTemplate = (props: {
  amount?: number,
  currency: string,
  quantity: number,
  priceBreakdown: Array<[string, number]>,
  isApproximate?: boolean,
  paymentStatus?: string,
}): React.ReactElement => {
  const { amount, currency, priceBreakdown, quantity, isApproximate=false, paymentStatus=undefined } = props;
  const classes = useStyles()

  const totalAmount = amount ?? priceBreakdown.reduce((acc: number, [_, value]) => acc + parseFloat(`${value}`), 0)

  return (
    <div className={classes.root}>
      {priceBreakdown.map((item: [string, number]) => {
        return (
          <div className="priceRow" key={item[0]}>
            <Typography gutterBottom variant="body2" color="textSecondary" component="div">
              {item[0]}
            </Typography>
            <Typography variant="body2" color={item[1] >= 0 ? "textSecondary" : "red"} component="div">
              {renderPrice(item[1])}
            </Typography>
          </div>
        )
      })}
      <div className="totalPrice">
        <Typography variant="subtitle1" component="div" >
          <PriceSummary prefix={`Total (${quantity} items): `} currency={currency} amount={totalAmount} />
        </Typography>
        <If condition={isApproximate}>
          <Tooltip
            title={"Final total will be updated at pick-up"}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <Stack direction="row" alignItems={"center"}>
              <Typography variant="subtitle1" component="div" sx={{ fontStyle: 'italic' }}>
                estimated
              </Typography>
              <IconButton sx={{pl: 1, pr: 0, pt: 0, pb: 0}}>
                <InfoIcon />
              </IconButton>
            </Stack>
          </Tooltip>
        </If>
        <If condition={paymentStatus}>
          <Typography variant="subtitle1" component="div">
            {paymentStatus}
          </Typography>
        </If>
      </div>
    </div>
  )
}

const OrderSummary = (props: {
  order: Order,
  anonymizeCoupon?: boolean,
}): React.ReactElement => {

  const { order, anonymizeCoupon } = props;
  const ignoreRefundedRedeemedItem = order.status === OrderStatus.OPEN_CHECK

  let paymentStatus = undefined;
  if (order.status === OrderStatus.OPEN_CHECK) {
    if (order.preAuthInfo?.status === PreAuthStatus.PRE_AUTHED) {
      paymentStatus = 'Card Authorized';
    } else {
      paymentStatus = 'Unpaid';
    }
  }

  return (
    <OrderSummaryTemplate
      amount={props.order.amount}
      currency={props.order.currency}
      priceBreakdown={getOrderPriceBreakdown(order, ignoreRefundedRedeemedItem, anonymizeCoupon)}
      quantity={getOrderTotalItems(order.lineItems)}
      paymentStatus={paymentStatus}
    />
  );
}

export default OrderSummary