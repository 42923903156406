import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Fab, Grid, Stack } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router';
import useAxios from 'axios-hooks';
import { t } from 'i18next';
import { If, Then, Else } from 'react-if';

import { FabContainer } from 'bos_common/src/components/FabContainers';
import { MerchantStory } from 'bos_common/src/types/MerchantStoryType';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { FullscreenPaper, isEmptyOrNil } from 'bos_common/src';

import MerchantPageHeader from '../../components/MerchantPageHeader';
import StorySummary from '../../components/Stories/StorySummary';
import { AppContext } from '../../context/AppContext';
import useFetchMerchant from '../../services/useFetchMerchant';

interface IMerchantStoriesPageParams {
  merchantUsername: string;
}

const MerchantStoriesPage = () => {
  const history = useHistory();
  const { merchantUsername } = useParams<IMerchantStoriesPageParams>();
  const { merchant } = useContext(AppContext);
  useFetchMerchant({ merchantUsername })

  const onBack = () => {
    history.goBack();
  };

  const [{data: stories, loading: loadingStories}, executeGetMerchantStories] = useAxios<MerchantStory[]>(
    { url: `/stories?merchantUsername=${merchantUsername}`, method: 'GET' },
    { manual: true }
  );

  const [{loading: creaingStory }, executeCreateMerchantStory] = useAxios(
    { url: `/merchants/${merchant?.id}/stories`, method: 'POST'},
    { manual: true }
  );

  useEffect(() => {
    executeGetMerchantStories();
  }, []);

  const handleCreateStory = () => {
    executeCreateMerchantStory()
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            const story = response.data as MerchantStory;
            history.push(`/${merchantUsername}/stories/${story.id}`);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={t("MerchantService_Feed")} />
      <SimpleLoader loading={loadingStories || creaingStory} />
      <If condition={isEmptyOrNil(stories) && !loadingStories}>
        <Then>
          <Stack sx={{p: 10}} gap={2} direction="column" alignItems="center">
            <Fab variant="extended" color="primary" onClick={handleCreateStory}>
              <Add /> Create Story
            </Fab>
            <span>Click on "Create Story" to post your first content to your customers.</span>
          </Stack>
        </Then>
        <Else>
          <FullscreenPaper>
            <Grid container>
              {stories?.map((story) => {
                return (
                  <StorySummary key={story.id} story={story} />
                );
              })}
            </Grid>
          </FullscreenPaper>
          <FabContainer alignment="right">
            <Fab
              variant="extended"
              color="primary"
              onClick={handleCreateStory}
            >
              <Add /> Create Story
            </Fab>
          </FabContainer>
        </Else>
      </If>
    </div>
  );
}

export default MerchantStoriesPage;