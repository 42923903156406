import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';

// src
import { AppContext } from '../../context/AppContext';
import MerchantStatsContextProvider from '../../context/MerchantStats/MerchantStatsContextProvider';
import useFetchMerchant from '../../services/useFetchMerchant';
import MerchantReports from './MerchantReports';

interface IMerchantReportsParams {
  merchantUsername: string;
}

const Page = () => {
  const { merchantUsername } = useParams<IMerchantReportsParams>();
  const { merchant } = useContext(AppContext);

  if (!merchantUsername && !merchant) {
    return <Redirect to="/" />;
  }

  useFetchMerchant({ merchantUsername });

  return (
    <MerchantStatsContextProvider>
      <MerchantReports />
    </MerchantStatsContextProvider>
  )
};

export default Page;
