import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { MerchandiseCategory } from '../../../bos_common/src/types/MerchandiseType';

import { MerchandiseCategorySliceState } from '../../../types/MerchandiseCategorySlice'

type MerchandiseCategorySliceCaseReducer = SliceCaseReducers<MerchandiseCategorySliceState>;

export const merchandiseCategorySlice = createSlice<MerchandiseCategorySliceState, MerchandiseCategorySliceCaseReducer, string>({
  name: 'merchandiseCategory',
  initialState: {
    loading: false,
    entities: {
      ids: [],
      byId: {},
    }
  },
  reducers: {
    setLoadingMerchandiseCategory: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoadingMerchandiseCategory: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateMerchandiseCategory: (state, action: PayloadAction<MerchandiseCategory[]>) => {
      let foundIndex: number;
      const { payload } = action;
      const ids = [...state.entities.ids];
      const byId = {...state.entities.byId}

      payload.forEach((item: MerchandiseCategory) => {
        foundIndex = ids.findIndex((id: number) => id === item.id);
        
        if (foundIndex === -1) {
          ids.push(item.id);
        }

        byId[item.id] = item;
      });

      return {
        ...state,
        loading: false,
        entities: {
          ids,
          byId,
        }
      }
    },

  },
});

// Action creators are generated for each case reducer function
export const { setLoadingMerchandise, stopLoadingMerchandise, updateMerchandise } = merchandiseCategorySlice.actions;

export default merchandiseCategorySlice.reducer;
