import React, { useContext } from 'react';
import { useHistory } from 'react-router';

// src
import MerchantProfile from '../components/MerchantProfile';
import MerchantPageHeader from '../components/MerchantPageHeader';
import { AppContext } from '../context/AppContext';
import { Merchant } from '../services/models';
import { ColoredPaper } from '../bos_common/src/components/Papers';

const MerchantCreatePage = () => {
  const title = 'Set up your online storefront';
  const history = useHistory();
  const { merchant, setMerchant } = useContext(AppContext);

  const onUpdatedMerchant = (merchant: Merchant) => {
    setMerchant(merchant);
    const { username } = merchant;
    history.push(`/${username}/menu`);
  };

  return (
    <div className="container">
      <MerchantPageHeader title={title} />
      <ColoredPaper>
        <MerchantProfile merchant={merchant} onUpdatedMerchant={onUpdatedMerchant} />
      </ColoredPaper>
    </div>
  );
};

export default MerchantCreatePage;
