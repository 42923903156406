import React, { useEffect, useState } from "react";
import { propOr, head } from "ramda";
import { Channel, DefaultGenerics, StreamChat, UserFilters } from "stream-chat";
import useAxios from "axios-hooks";

import { Merchant } from "../../../types/MerchantType";
import { isEmptyOrNil } from "../../../utils";
import { generateChannelName, getAdminUserName } from "../utils";
import { PlatformChatContext } from "./PlatformChatContext";
import AppLogo from '../../../assets/icons/logo.svg'

interface PlatformChatContextType {
  username?: string;
  children: React.ReactNode;
  merchant?: Merchant;
  apiKey: string;
  isMerchantChat?: boolean;
  chatStarted?: boolean;
  sourceInfo?: {
    device: string,
    app: string
  }
}

const PlatformChatContextProvider = (props: PlatformChatContextType): React.ReactElement => {
  const { children, merchant, apiKey, isMerchantChat = false, chatStarted = false, sourceInfo = {} } = props;

  const [username, setUsername] = useState<string>(propOr("", "username", props));

  const [isChatStarted, toggleChat] = useState<boolean>(chatStarted);
  const [isChatDisabled, setIsChatDisabled] = useState<boolean>(false);

  const [{ loading }, authenticateUser] = useAxios({
    url: 'chat/authenticate',
    method: 'POST'
  }, { manual: true })

  const [{ loading: loadingUser }, sendInitializationMessage] = useAxios({
    url: 'chat/initialize',
    method: 'POST'
  }, { manual: true })

  // stream chat 
  const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | undefined>();
  const [channel, setChatChannel] = useState<Channel | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const _toggleChat = () => toggleChat(!isChatStarted)

  const initializeChat = async (merchant: Merchant) => {
    const client = StreamChat.getInstance(apiKey)

    if (isMerchantChat)
      await initializeMerchantChat(merchant, client)
    else
      await initializeUserChat(merchant, client);
  }

  const initializeUserChat = async (merchant: Merchant, chatClient: StreamChat<DefaultGenerics>) => {
    if (!chatClient) {
      setIsChatDisabled(true)
      setErrorMessage(`Unable to start chat at this time.`)
      await cleanUpChat()
      return;
    }

    try {
      await chatClient.setGuestUser({ id: username, name: username });

      setChatClient(chatClient)

      const filter: UserFilters<DefaultGenerics> = {
        name: getAdminUserName(merchant),
      }
      const usersListResponse = await chatClient.queryUsers(filter)
      const merchantAdmin = head(propOr([], 'users', usersListResponse))

      if (!isEmptyOrNil(merchantAdmin)) {
        const merchantUserId = merchantAdmin.id

        const channelIdentifier = generateChannelName(merchant, username)
        const channel = chatClient.channel('messaging', channelIdentifier, {
          // add as many custom fields as you'd like
          image: merchant.logo ?? AppLogo,
          team: `${merchant.username}`,
          name: channelIdentifier,
          members: [merchantUserId],
          sourceInfo
        });

        // added a delay to allow time for channel initialization
        setTimeout(async () => {
          await sendInitializationMessage({
            data: {
              adminChannelId: channel?.id,
              merchantUsername: merchant.username,
              merchantId: merchant.id,
            }
          })
        }, 100)

        setChatChannel(channel)
      } else {
        setIsChatDisabled(true)
        setErrorMessage(`${merchant.officialName} does not support chat yet.`)
        await cleanUpChat()
      }
    } catch (error) {
      setIsChatDisabled(true)
      console.log('error', error)
      setErrorMessage(`Unable to start chat at this time.`)
      await cleanUpChat()
    }
  }

  const initializeMerchantChat = async (merchant: Merchant, chatClient: StreamChat<DefaultGenerics>) => {
    if (!chatClient) {
      setIsChatDisabled(true)
      setErrorMessage(`Unable to start chat at this time.`)
      await cleanUpChat()
      return;
    }

    try {
      setIsChatDisabled(true)
      const authResponse = await authenticateUser({
        data: { username }
      })

      if (authResponse.status === 200) {
        const token = authResponse.data;

        const user = {
          id: username,
          name: username
        }

        await chatClient.connectUser(user, token)
        setChatClient(chatClient)
        setIsChatDisabled(false)
        return;
      }

      throw new Error("Unable to authenticate user")
    } catch (error) {
      setIsChatDisabled(true)
      console.log('error', error)
      setErrorMessage(`Unable to start chat at this time.`)
      await cleanUpChat()
    }
  }

  const cleanUpChat = async () => {
    if (chatClient) {
      await chatClient.disconnectUser();
      //TODO: enable to delete chat on exit
      // delete channel only if the chat is started by the user
      // if (!isMerchantChat && channel) {
      //   const channelIds = [`messaging:${channel.id}` as string]
      //   await chatClient.deleteChannels(channelIds, { hard_delete: true })
      // }
    }
  }

  useEffect(() => {
    if (isChatStarted && merchant) {
      initializeChat(merchant);
    }
  }, [merchant?.id, isChatStarted])

  return (
    <PlatformChatContext.Provider
      value={{
        cleanUpChat,
        username,
        setUsername,
        isChatStarted,
        toggleChat: _toggleChat,
        chatClient,
        setChatClient,
        channel,
        setChatChannel,
        isChatDisabled,
        errorMessage,
        isMerchantChat,
        merchant
      }}
    >
      {children}
    </PlatformChatContext.Provider>
  )
};

export default PlatformChatContextProvider;
