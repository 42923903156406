import React from "react";
import { TypographyProps, Typography, makeStyles, createStyles } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";

import { isEmptyOrNil } from "../../utils";
import { Merchandise } from "../../types/MerchandiseType";
import MerchantPackage from "../../types/crm/MerchantPackageType";
import MerchantMembership from "../../types/crm/MerchantMembershipType";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      '& .block-with-text': {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        maxHeight: '50px',  // for 3 lines based on 1.2 lineheight 1.2*3*14px = 50px
        
        '& p': {
          margin: 0,
          lineHeight: 1.2,
          fontWeight: 400,
        },
      },
    },
  })
);


type MerchandiseDescriptionProp = {
  merchandise: Merchandise | MerchantPackage | MerchantMembership,
  typographyProps?: TypographyProps<"div">,
  className?: string,
}

const MerchandiseDescription = ({ merchandise, typographyProps, className }: MerchandiseDescriptionProp): React.ReactElement => {
  const classes = useStyles();

  const merchDescriptionClasses = classNames(classes.root, {
    [className!]: !isEmptyOrNil(className)
  })
  return (
    <Typography variant="subtitle2" color="textSecondary" component="div" {...typographyProps} className={merchDescriptionClasses}>
      <ReactMarkdown className={'block-with-text'}>
        {merchandise?.description ?? ""}
      </ReactMarkdown>
    </Typography>
  )
}

export default MerchandiseDescription;