import React, { useContext, useMemo } from "react";
import { defaultTo } from 'ramda';
import { CardHeader, Theme, useMediaQuery, makeStyles, Typography, CardContent, useTheme } from '@material-ui/core';
import { utcToZonedTime } from 'date-fns-tz';
import { format, getDay, getHours } from 'date-fns';
import createPlotlyComponent from "react-plotly.js/factory";
import { useTranslation } from "react-i18next";
import Plotly from "plotly.js-basic-dist";

import { MerchantCustomerStats } from "../../bos_common/src/types/MerchantCustomerStatsType";

import { MerchantStatsApiResponse } from "../../services/models"
import { getClientTimezone, isEmptyOrNil } from "../../utils";
import { MerchantStatsContext } from "../../context/MerchantStats/MerchantStatsContext";

import { MerchantStatsPeriod } from "./types";
import { useChartStyles, StatsChartProps, generateBarChartData, getBarChartLayoutConfig } from "./chartUtils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .hoverlayer': {
      '& text': {
        fill: `${theme.palette.common.white} !important`
      }
    }
  }
}))

const getEmptyChartAnnotation = (stats?: Array<any>, isMobile: boolean = false): Partial<Plotly.Layout> => {
  if (!isEmptyOrNil(stats)) return {};

  return {
    ...(isMobile && { height: 50 }),
    annotations: [{
      align: 'center' as const,
      text: 'No data available.',
      showarrow: false
    }],
    xaxis: { showgrid: false, showticklabels: false },
    yaxis: { showgrid: false, showticklabels: false },
  }
}

const CHART_CONFIG: Partial<Plotly.Config> = {
  responsive: false,
  displayModeBar: false,
}

const getChartLayout = (config: Partial<Plotly.Layout> = {}) => {
  return getBarChartLayoutConfig({
    barmode: 'stack',
    autosize: true,
    legend: {
      orientation: 'h' as const,
      xanchor: 'center' as const,
      x: 0.5,
      y: -0.15,
    },
    annotations: [],
    height: 450,
    ...config
  }) as Plotly.Layout
}

const getBarConfig = (config: Partial<Plotly.Data>): Plotly.Data => {
  return ({
    type: 'bar',
    marker: {
      color: '#bcbcbc'
    },
    width: 0.3,
    showlegend: true,
    ...config
  }) as Plotly.Data
}

type YAxisValues = Array<number>
interface GetYAxisRangeType {
  current: YAxisValues,
  comparison: YAxisValues
}

const getYAxisRange = ({ current, comparison }: GetYAxisRangeType): [number, number] => {
  const getMaxInSeries = (series: YAxisValues) => {
    let maxY = 0
    for (let i = 0; i < series.length; i++) {
      const sum = series[i]
      if (sum > maxY) maxY = sum
    }
    return maxY
  }
  const maxY = Math.max(getMaxInSeries(current), getMaxInSeries(comparison));
  return [0, maxY]
}

// Fix for Plotly memory leak
const Plot = createPlotlyComponent(Plotly);

const MerchantStatsChart = ({ lastWeekSalesData, currentWeekSalesData, timeZone, chartLabel, chartTitle, dataKey = '', chartDescription }: StatsChartProps) => {
  const theme = useTheme();
  const chartClasses = useChartStyles()
  const gridLayout = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const { statsTimePeriod } = useContext(MerchantStatsContext);

  const currentTimezone = getClientTimezone()
  const finalTimeZone = timeZone || currentTimezone;
  const { t } = useTranslation();

  const isWeeklyStats = statsTimePeriod === MerchantStatsPeriod.Week
  const isCustomStats = statsTimePeriod === MerchantStatsPeriod.Custom
  const { stats: lwStats } = defaultTo({}, isCustomStats ? {} : lastWeekSalesData) as MerchantStatsApiResponse;
  const { stats: cwStats } = defaultTo({}, currentWeekSalesData) as MerchantStatsApiResponse;

  const calculateSalesDataPoint = (key: string) => (group: MerchantCustomerStats) => {
    const zonedTime = utcToZonedTime(new Date(group.timePoint), finalTimeZone).getTime()
    let xAxisData: number | Date | string = isWeeklyStats ? getDay(zonedTime) : getHours(zonedTime)
    if (isCustomStats) { xAxisData = format(zonedTime, 'MM/dd/yy') }
    return {
      name: zonedTime,
      x: xAxisData,
      y: Number(group[key]),
    }
  }

  const cwCustomersSeries = useMemo(() => generateBarChartData(
    cwStats as Array<MerchantCustomerStats>,
    statsTimePeriod,
    calculateSalesDataPoint(dataKey)
  ), [cwStats, isWeeklyStats])

  const lwCustomersSeries = useMemo(() => generateBarChartData(
    lwStats as Array<MerchantCustomerStats>,
    statsTimePeriod,
    calculateSalesDataPoint(dataKey)
  ), [lwStats, isWeeklyStats])

  // calculate the yAxis so that the two charts can be relative
  const yaxisRange = useMemo(() => getYAxisRange({
    current: cwCustomersSeries.barChart.y,
    comparison: lwCustomersSeries.barChart.y
  }), [cwCustomersSeries, lwCustomersSeries]);

  const isLastWeekDataAvailable = lastWeekSalesData && !isEmptyOrNil(lastWeekSalesData?.stats)
  const isCurrentWeekDataAvailable = currentWeekSalesData && !isEmptyOrNil(currentWeekSalesData?.stats)

  // blankslate
  if (!isLastWeekDataAvailable && !isCurrentWeekDataAvailable) {
    return (
      <CardHeader
        className={chartClasses.statsChartBlankslate}
        subheader={t("CustomerInsights_NoData")}
      />
    );
  }

  // data
  const chartData: Array<Plotly.Data> = [
    getBarConfig({
      hovertemplate: `${chartLabel}<br>%{y}, %{x}<extra></extra>`,
      x: cwCustomersSeries.barChart.x,
      y: cwCustomersSeries.barChart.y,
      name: chartLabel,
      legendgroup: chartLabel,
      marker: {
        color: '#1AAA5D'
      },
    }),
    ...(!isCustomStats ? [
      getBarConfig({
        hovertemplate: `Last Week ${chartLabel}<br>%{y}, %{x}<extra></extra>`,
        x: lwCustomersSeries.barChart.x,
        y: lwCustomersSeries.barChart.y,
        name: `Last Week`,
        legendgroup: `Last Week`,
        marker: {
          color: theme.palette.grey[400],
        }
      })
    ] : [])
  ]

  // layouts
  const chartLayout = getChartLayout({
    barmode: 'group',
    yaxis: {
      title: '',
      automargin: true,
      range: yaxisRange,
      fixedrange: true,
    },
    ...(getEmptyChartAnnotation([...cwStats?.values() ?? [], ...lwStats?.values() ?? []], isMobile))
  })

  return (
    <div className={gridLayout.root}>
      <CardContent>
        <Typography variant="body1" gutterBottom>{chartLabel}</Typography>
        <Typography variant="h4">{chartTitle}</Typography>
        <Typography variant="body2">{chartDescription}</Typography>
      </CardContent>
      <Plot
        divId="merchant_current"
        data={chartData}
        layout={chartLayout}
        config={CHART_CONFIG}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

export default MerchantStatsChart;
