import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CardContent, LinearProgress } from '@material-ui/core';
import { ColoredPaper } from '../bos_common/src/components/Papers';
import { AppContext } from '../context/AppContext';
import { Order, OrderStatus } from '../services/models';
import { getAuthHeaders } from '../utils';
import { UserContext } from '../bos_common/src/context/UserContext';
import MerchantPageHeader from '../components/MerchantPageHeader';
import useFetchMerchant from '../services/useFetchMerchant';
import MerchantOrderSticker from '../components/MerchantOrderSticker';
import useAxios from 'axios-hooks';


interface IMerchantPrintsParams {
  merchantUsername: string;
}

const MerchantPrintsPage = () => {
  const title = 'Order receipts/stickers';
  const history = useHistory();
  const { token } = useContext(UserContext)
  const { merchant } = useContext(AppContext)
  const { merchantUsername } = useParams<IMerchantPrintsParams>();

  const onBack = () => {
    history.goBack();
  };

  if (!merchantUsername) {
    return <Redirect to={'/biz/create'} />
  }

  const merchantLoading = useFetchMerchant({merchantUsername});

  const [{data: orders, loading: orderLoading}] = useAxios<Order[]>({
    url: '/merchants/orders/',
    headers: getAuthHeaders(token),
    params: {
      merchantUsername,
      limit: 1,
      orderStatus: OrderStatus.FULFILLED
    },
  })

  const loading = merchantLoading || orderLoading;

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={title} />
      {loading && <LinearProgress />}
      {!loading && merchant && orders && orders.length>0 &&
        <ColoredPaper>
          <MerchantOrderSticker order={orders[0]} merchant={merchant} />
        </ColoredPaper>
      }
      {!loading && !merchant &&
        <Redirect to={'/biz/create'} />
      }
    </div>
  );
  return null;
}

export default MerchantPrintsPage;