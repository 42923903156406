import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Divider, TextField, Typography } from '@material-ui/core';
import { gt, propOr, uniq, without } from 'ramda';
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';

import { isEmptyOrNil, pluralString } from '../../../../utils';
import { LineItem, Order, User, OrderStatus } from '../../../../services/models';
import { CancelOrderApiHandlerOptions, MerchantOrdersContext } from '../../../../context/MerchantOrders/MerchantOrdersContext';
import { AppContext } from '../../../../context/AppContext';

import { calculateRefundAmount, getOrderTotalItems } from '../../../../bos_common/src/services/orderPricingUtils';
import OrderSummary from '../../../../bos_common/src/components/OrderSummary';
import PriceSummary from '../../../../bos_common/src/components/PriceSummary';

import { OrderActionTemplateProps } from './types';
import useActionTemplateStyles from './styles';
import LineItemActions from './LineItemActions';
import ActionHeader from './ActionHeader';
import OrderRefundFab from '../../OrderRefundFab';
import OneMOperatorFab from '../../OneMOperatorFab';
import { NotificationSeverity } from '../../../../types/NotificationSlice';

const RefundOrderAction = (props: OrderActionTemplateProps) => {
  const { order, closeDrawer } = props;
  const classes = useActionTemplateStyles();
  const { t } = useTranslation();

  const [selectedItemsForRefund, setItemsForRefund] = useState<Array<LineItem>>([]);
  const [restoreInventory, toggleRestoreInventory] = useState<boolean>(false);
  const [customRefundAmount, setCustomRefundAmount] = useState<string>('');
  const isCustomRefundApplied = !isEmptyOrNil(customRefundAmount);

  const { cancelOrder, isUserOneMOperator, setOrderStatus } = useContext(MerchantOrdersContext);
  const { triggerNotification } = useContext(AppContext);

  const isOpenCheckOrder = order.status === OrderStatus.OPEN_CHECK
  const areAllItemsRefunded = order.lineItems.length === order.lineItems.filter(i => i.refunded === true).length
  const enableRefundAction = (!isEmptyOrNil(selectedItemsForRefund) || Number(customRefundAmount) > 0) && (isOpenCheckOrder || order.amount >= 0.50);

  // functions to handle action
  const onSuccessAuth = (user: User, token: string) => {
    const options = {
      restoreInventory,
      ...(!isEmptyOrNil(customRefundAmount) && { customRefundAmount }),
      staffToken: token,
      onSuccessCallback: () => triggerNotification(true, `Order for ${order.userDisplayName} is refunded successfully`, NotificationSeverity.SUCCESS)
    } as CancelOrderApiHandlerOptions

    cancelOrder({ ...order, lineItems: selectedItemsForRefund }, options)
    closeDrawer()
  }

  const onSuccessAuthForVoidOrder = (user: User, token: string) => {
    const options = {
      restoreInventory,
      staffToken: token,
      onSuccessCallback: () => triggerNotification(true, `Order for ${order.userDisplayName} is voided successfully`, NotificationSeverity.SUCCESS)
    } as CancelOrderApiHandlerOptions

    setOrderStatus({ id: order.id, nextOrderStatus: OrderStatus.VOIDED, options })
    closeDrawer()
  }

  const onCheckboxChangeAction = ({ checkboxValue, item }: { checkboxValue: boolean, order: Order, item: LineItem, index: number }) => {
    setCustomRefundAmount('');

    if (checkboxValue) {
      setItemsForRefund(uniq([...selectedItemsForRefund, item]))
    } else {
      setItemsForRefund(without([item], selectedItemsForRefund))
    }
  }

  const handleCustomRefundAmount = (e: any) => {
    const inputValue = e.target.value

    if (!inputValue) {
      setCustomRefundAmount('')
      return
    }

    let value = parseFloat(e.target.value);
    const { amount } = order

    if (value > amount) {
      setCustomRefundAmount(`${amount}`)
    } else {
      setCustomRefundAmount(inputValue)
    }
    toggleRestoreInventory(false)
  }

  const refundTotalAmount = useMemo(() => {
    const refundAmount = isCustomRefundApplied
      ? customRefundAmount
      : calculateRefundAmount(order, selectedItemsForRefund)

    return Math.min(Number(refundAmount), order.amount)
  }, [selectedItemsForRefund, order, customRefundAmount])

  const refundItemQuantity = getOrderTotalItems(selectedItemsForRefund);

  useEffect(() => {
    // if the user is an operator, select all items for refund by default
    if (isUserOneMOperator) setItemsForRefund(order.lineItems)
  }, [])

  const isLineItemDisabled = (item: LineItem) => {
    return gt(propOr(0, 'pointsRedeemed', item), 0) || isCustomRefundApplied
  }

  return (
    <div className={classes.actionTemplateContainer}>
      <ActionHeader
        title={isOpenCheckOrder ? t("Cancel") : t("Order_Refund")}
        subtitle={isOpenCheckOrder ? t("Order_Cancel_Subtitle") : t("Order_Refund_Subtitle")}
      />

      <div className='content-container'>
        <LineItemActions
          order={order}
          orderItemProps={{
            disableCheckbox: isLineItemDisabled,
            defaultChecked: isUserOneMOperator || false,
            onCheckboxChangeAction
          }}
        />

        <If condition={!isOpenCheckOrder}>
          <Then>
            <div className='divider-container'>
              <span className='or-span'>OR</span>
              <Divider />
            </div>
            <div className='custom-refund-amount-container'>
              <TextField
                fullWidth
                label={t("Order_Refund_CustomRefundAmount")}
                type="number"
                disabled={order.amount <= 0 || isOpenCheckOrder}
                variant="outlined"
                value={customRefundAmount}
                inputProps={{
                  step: '0.01',
                  min: 0,
                  max: order.amount
                }}
                onChange={handleCustomRefundAmount}
              />
            </div>
          </Then>
        </If>

        <div className="order-summary">
          <OrderSummary order={order} anonymizeCoupon={true} />

          <If condition={refundTotalAmount > 0}>
            <div className="price-row">
              <Typography gutterBottom color="red" variant="subtitle1" component="div" className="totalPrice">
                <PriceSummary
                  currency={order.currency}
                  amount={isOpenCheckOrder ? 0 : refundTotalAmount}
                  prefix={isCustomRefundApplied
                    ? `${t("Order_Refund_WillRefund")}: `
                    : `${t(isOpenCheckOrder ? "Order_Refund_CancelPrefix" : "Order_Refund_RefundPrefix", { value: pluralString(refundItemQuantity, 'item') })} `}
                />
              </Typography>
            </div>
          </If>
        </div>
      </div>

      <div className='actions-container'>
        <If condition={!isOpenCheckOrder && order.amount <= 0}>
          <Typography gutterBottom color="red" variant="subtitle1" component="div" textAlign='center'>
            {t("Order_Refund_CannotRefund")}
          </Typography>
        </If>
        <If condition={isOpenCheckOrder && areAllItemsRefunded}>
          <Typography gutterBottom color="red" variant="subtitle1" component="div" textAlign='center'>
            {t("Order_Refund_AllCancelled")}
          </Typography>
        </If>

        {/* display the staff Actions if the user is not a operator, else show a simple confirmation dialog */}
        <If condition={!isUserOneMOperator}>
          <Then>
            <If condition={Number(order.amount) === 0}>
              <Then>
                <OrderRefundFab
                  label={t("VoidOrder")}
                  onClick={() => toggleRestoreInventory(true)}
                  onSuccessAuth={onSuccessAuthForVoidOrder}
                  outlined
                />
              </Then>
            </If>
            <OrderRefundFab
              label={t("Order_Button_ConfirmAndRestore")}
              onClick={() => toggleRestoreInventory(true)}
              onSuccessAuth={onSuccessAuth}
              disabled={!enableRefundAction}
              outlined
            />
            <OrderRefundFab
              label={t("Confirm")}
              onClick={() => toggleRestoreInventory(false)}
              onSuccessAuth={onSuccessAuth}
              disabled={!enableRefundAction}
            />
          </Then>
          <Else>
            <OneMOperatorFab onSuccessAuth={onSuccessAuth} />
          </Else>
        </If>
      </div>
    </div>
  )
}

export default RefundOrderAction