import { path, head } from 'ramda';
import { Merchandise, MerchandiseCategory } from '../types/MerchandiseType';
import { MerchantCoupon } from '../types/MerchantCouponType';
import { PromotionCriteriaType } from '../types/MerchantPromotionType';
import { Merchant } from '../types/MerchantType';
import { OrderType } from '../types/OrderTypes';
import { isEmptyOrNil } from '../utils';
import { isCouponValidOnMerchandise } from './CouponUtils';

export type MerchandiseWithCategory = {
  merchandises: Array<Merchandise>;
  category: MerchandiseCategory | null;
};

export const generateEverythingElseCategory = (merchant?: Merchant) => ({
  id: -1,
  displayOrder: 9999,
  merchantId: merchant?.id || '',
  name: 'Everything else',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  version: 0,
  exclusiveOrderTypes: [],
});

interface IGroupMerchandiseDataByCategories {
  categories?: Array<MerchandiseCategory>;
  merchandises?: Array<Merchandise>;
  merchant?: Merchant;
  orderType?: OrderType;
}
export const groupMerchandiseDataByCategories = ({
  categories = [],
  merchandises = [],
  merchant,
  orderType
}: IGroupMerchandiseDataByCategories): Array<MerchandiseWithCategory> => {
  let groupedData = [];

  const hasCategoryInMerchandises = merchandises.filter((i) => !!i.categoryId).length > 0;
  if (categories.length > 0) {
    categories?.forEach((category: MerchandiseCategory) => {
      if (orderType && !isEmptyOrNil(category.exclusiveOrderTypes) && !category.exclusiveOrderTypes.includes(orderType)) return;

      const merchandises: Merchandise[] = [];
      groupedData.push({
        category,
        merchandises,
      } as MerchandiseWithCategory);
    });
  }

  // add default category
  const defaultCategory = hasCategoryInMerchandises
    ? generateEverythingElseCategory(merchant)
    : null;

  groupedData.push({
    category: defaultCategory,
    merchandises: [],
  });

  // grouping merchandises to the different categories
  merchandises.reduce((acc: any, item: Merchandise) => {
    const categoryIndex = acc.findIndex(
      ({ category }: MerchandiseWithCategory) => category && category.id === item.categoryId
    );
    if ((item.categoryId && categoryIndex === -1)) return acc;

    const finalIndex = categoryIndex === -1 ? acc.length - 1 : categoryIndex;

    acc[finalIndex].merchandises.push(item);
    return acc;
  }, groupedData);

  const featuredMerchandises = merchandises.filter((item: Merchandise) => item.featured === true);
  if (featuredMerchandises.length > 0) {
    groupedData.push({
      category: {
        id: -2,
        displayOrder: -1,
        merchantId: merchant?.id || '',
        name: 'Featured Items',
        description: 'Selected best items and specialty at our store',
        createdAt: new Date(),
        updatedAt: new Date(),
        version: 0,
        exclusiveOrderTypes: [],
      },
      merchandises: featuredMerchandises,
    });
  }

  const sortMerchandiseByName = (a: Merchandise, b: Merchandise) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  // sorting the data by merchandise name asc and categories display order asc
  groupedData = groupedData
    .filter(({ merchandises }) => merchandises.length > 0)
    .map((group) => ({ ...group, merchandises: group.merchandises.sort(sortMerchandiseByName) }))
    .sort((a: MerchandiseWithCategory, b: MerchandiseWithCategory) => {
      return (a.category?.displayOrder || 0) - (b.category?.displayOrder || 0);
    });

  return groupedData;
};

export const hasInventoryData = (merchandise: Merchandise | undefined | null): boolean => {
  // It checks for both undefined and null
  return Boolean(
    merchandise && merchandise.inventory && merchandise.inventory.dailyCurrentStock != null
  );
};

export const isOverInventoryLimit = (
  merchandise: Merchandise | undefined | null,
  quantity: number
): boolean => {
  return Boolean(
    merchandise &&
    merchandise.inventory?.dailyCurrentStock != null &&
    merchandise.inventory?.dailyCurrentStock < quantity
  );
};

export const isMerchandiseAvailable = (merchandise: Merchandise): boolean => {
  const currentDailyStock = path(['inventory', 'dailyCurrentStock'], merchandise);
  if (isEmptyOrNil(currentDailyStock)) return true;

  return currentDailyStock > 0;
};

export const getCouponForMerchandise = (merchant: Merchant, merchandise: Merchandise): MerchantCoupon | undefined => {
  const excludePromoType = [PromotionCriteriaType.REFERRAL_TYPE, PromotionCriteriaType.COMBO_TYPE];
  const coupons = merchant?.promotions?.reduce((aggr: MerchantCoupon[], promo) => {
    if (promo.coupons && !excludePromoType.includes(promo.criteriaType)) {
      return [...aggr, ...promo.coupons];
    }
    return aggr;
  }, []);

  const remains = coupons?.filter((coupon) => isCouponValidOnMerchandise(merchandise, coupon, true));
  return remains ? head(remains) : undefined;
}