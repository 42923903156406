import React from 'react';
import { Avatar, Badge, Chip, Grid, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { AddAPhoto, Edit } from '@material-ui/icons';
import { Else, If, Then } from 'react-if';

// src
import { AvatarPaper } from '.';
import ImageUploadButton from './ImageUploadButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profilePhoto: {
      display: "flex",
      justifyContent: 'center',
      position: 'relative',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      "& .heroPhotoWrapper": {
        width: "100%",
        position: "relative",
        "& .heroPhoto": {
          width: "100%",
          minHeight: '200px',
          maxHeight: '250px',
          border: "1px solid",
          objectFit: "cover",
          borderColor: theme.palette.grey[400],
          borderRadius: theme.spacing(1),
        },
        "& .heroPhotoUpload": {
          position: 'absolute',
          bottom: theme.spacing(1.5),
          right: theme.spacing(1.5),
          '& .MuiButtonBase-root': {
            padding: theme.spacing(1),
            fontSize: '12px',
          }
        },
        "&.noHero": {
          minHeight: 250
        },
      },
      "& .logoSection": {
        position: 'absolute',
        bottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
        '& .logo': {
          width: theme.spacing(12),
          height: theme.spacing(12),
        },
      },
    },
    profilePhotoNoHero: {
      minHeight: 150
    },
    root: {
      '& .MuiFormControl-root': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      '& .MuiInputLabel-root': {
        backgroundColor: 'rgb(245, 250, 255)'
      },
      '& .MuiButtonBase-root': {
        '& a': {
          textDecoration: 'none',
        }
      },
    },
  })
);

type ImageUploadAvatarType = {
  logoSrc?: string,
  heroSrc?: string,
  showHeroCover?: boolean,
  showLogo?: boolean,
  onPhotoSelect?: (files: File[], photoType: string) => void
}

const ImageUploadAvatar = ({ logoSrc, heroSrc, onPhotoSelect, showHeroCover, showLogo=true }: ImageUploadAvatarType) => {
  const classes = useStyles();

  const onAddPhoto = (photoType: string, files?: File[]) => {
    if (!files) {
      return;
    }

    onPhotoSelect && onPhotoSelect(files, photoType);
  };

  const avatarContainerClasses = classNames([classes.profilePhoto], {
    [classes.profilePhotoNoHero]: !showHeroCover
  });

  return (
    <div className={avatarContainerClasses}>
      <If condition={showHeroCover}>
        <Grid item xs={12} sm={8} className="heroPhotoWrapper">
          <If condition={heroSrc}>
            <Then>
              <img
                className="heroPhoto"
                src={heroSrc || ''}
                alt="heroPhoto"
              />
            </Then>
            <Else>
              <div className="heroPhoto" />
            </Else>
          </If>
          <div className="heroPhotoUpload">
            <ImageUploadButton handleFiles={(files: File[]) => onAddPhoto("Hero", files)}>
              <Chip
                size="small"
                icon={<Edit fontSize="small" />}
                label="Cover Photo"
                clickable
                color='secondary'
                variant='filled'
              />
            </ImageUploadButton>
          </div>
        </Grid>
      </If>

      <If condition={showLogo}>
        <div className="logoSection">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={
              <ImageUploadButton handleFiles={(files: File[]) => onAddPhoto("Logo", files)} >
                <IconButton component="span">
                  <AddAPhoto />
                </IconButton>
              </ImageUploadButton>
            }>
            <AvatarPaper>
              <Avatar alt="business logo" className="logo" src={logoSrc || ''} />
            </AvatarPaper>
          </Badge>
        </div>
      </If>
    </div>
  )
}

export default ImageUploadAvatar;