import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab } from "@material-ui/core"
import OutlinedFab from './OutlinedFab'

type ConfirmationAlert = {
  isOpen: boolean;
  onConfirm?: () => void;
  confirmLabel?: string;
  onCancel?: () => void;
  cancelLabel?: string;
  children: any;
  title: string;
  isConfirmDisabled?: boolean;
}

const ConfirmationAlert = (props: ConfirmationAlert): React.ReactElement => {
  const { isOpen = false, onConfirm, onCancel, title, cancelLabel = 'Cancel', confirmLabel = 'Confirm', isConfirmDisabled=false } = props
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly', paddingBottom: 20, paddingTop: 20 }}>
      
      {onCancel && <OutlinedFab sx={{height:40}} onClick={onCancel} color='secondary' variant='extended'>
          {cancelLabel}
        </OutlinedFab>}
        {onConfirm && <Fab onClick={onConfirm} color="primary" size="medium" variant='extended' autoFocus disabled={isConfirmDisabled}>
          {confirmLabel}
        </Fab>}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationAlert;