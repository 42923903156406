import React, { useContext, useState } from 'react'
import {
  Avatar, CardActionArea, CardHeader, createStyles,
  makeStyles, Theme, Typography, useMediaQuery
} from '@material-ui/core'
import classnames from 'classnames'

// src
import { AppContext } from '../context/AppContext'
import { AvatarPaper } from '../bos_common/src/components/Papers'
import MerchantSelector from './MerchantSelector'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 'fit-content',

      '& .MuiCardHeader-root': {
        paddingTop: 0,
        paddingBottom: 0,

        '& .MuiTypography-root': {
          lineHeight: 1.2
        },
      }
    }
  })
)

const MerchantSelectorCTA = () => {
  const classes = useStyles();
  const [openDlg, setOpenDlg] = useState(false);
  const { merchant } = useContext(AppContext);
  const { officialName, username, logo } = merchant || {}

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMidSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) && !isMobile;

  return (
    <>
      <CardActionArea className={classnames([classes.root, 'merchant-selector-cta'])} onClick={() => setOpenDlg(true)}>
        <CardHeader elevation={3}
          title={
            <Typography variant="h6" component="div" className="store-name">
              {officialName}
            </Typography>
          }
          avatar={
            <AvatarPaper>
              <Avatar color="secondary" alt={officialName} src={logo} />
            </AvatarPaper>
          }
          subheader={!isMidSize && !isMobile && (
            <Typography variant="subtitle1" color="textSecondary" component="div" className="store-subtitle">
              @{username}
            </Typography>
          )}
        />
      </CardActionArea>

      {/* Merchant Selector Dialog */}
      <MerchantSelector open={openDlg} onClose={() => setOpenDlg(false)} />
    </>
  )
}

export default MerchantSelectorCTA