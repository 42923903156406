import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { AuthSliceStateEntities, AuthSliceState } from '../../../types/AuthSlice'
import { fetchAuth } from './authActions';

type AuthSliceCaseReducer = SliceCaseReducers<AuthSliceState>;

export const authSlice = createSlice<AuthSliceState, AuthSliceCaseReducer, string>({
  name: 'auth',
  initialState: {
    loading: false,
    entities: {
      user: null,
      token: '',
    }
  },
  reducers: {
    setLoadingAuth: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoadingAuth: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateAuth: (state, action: PayloadAction<AuthSliceStateEntities>) => {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        entities: {
          ...payload,
        }
      }
    },

    resetAuth: (state) => {
      return {
        ...state,
        loading: false,
        entities: {
          user: null,
          token: '',
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.fulfilled, (state, action) => {
        return {
          ...state,
          entities: {
            ...action.payload
          }
        }
      });
  }
});

// Action creators are generated for each case reducer function
export const { setLoadingAuth, stopLoadingAuth, updateAuth, resetAuth } = authSlice.actions;

export default authSlice.reducer;
