import React from "react";

import { WaitTime } from "../../bos_common/src/types/WaitTimeType";
import { Order } from "../../services/models";

export interface CallboardContextState {
  getMerchantWaitTime: () => void
  merchantWaitTime: WaitTime
  inProgressOrdersList: Array<Order>,
  completedOrdersList: Array<Order>,
  loading: boolean
}

export const CallboardContext = React.createContext<CallboardContextState>({
  getMerchantWaitTime: () => { },
  merchantWaitTime: {
    minMinutes: 0,
    maxMinutes: 0,
    priorOrdersInQueue: 0,
    priorItemsInQueue: 0
  },
  inProgressOrdersList: [],
  completedOrdersList: [],
  loading: false
})