import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useAuthStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",

      "& .signUpActionRow": {
        display: "flex",
        flexDirection: "row",
        width: ({ fullwidth }: any) => (fullwidth ? "100%" : "300px"),

        "& .phoneInput, .emailInput": {
          width: "100%",
          height: "36px",
        },

        "& .emailInput": {
          paddingBottom: theme.spacing(1),
        },

        "& .nextButton": {
          padding: "6px",
          marginLeft: "-37px",
        },
      },

      "& .MuiAlert-root": {
        backgroundColor: "transparent",
        padding: 0,
      },
    },
  })
);

export default useAuthStyles;
