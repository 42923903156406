import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Slide,
  Theme,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useLocation } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';

import TOSString from '../TOSString';
import axios from '../../services/backendAxios';
import { auth0, AuthLocalCachedMeta, getAuthMeta, isEmailAddressCached, storeAuthMeta } from '../../services/auth0';
import SimpleLoader from '../SimpleLoader';

declare let window: any;

const EMAIL_CODE_LENGTH = 6;

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);
Transition.displayName = 'SlideUpTransition';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialogTitle-root': {
        textAlign: 'center',
      },
      '& .dlgContent': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        '& input[type=number]': {
          paddingRight: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
      '& .dlgActions': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .MuiAlert-root': {
        backgroundColor: 'transparent',
      },
      '& .TOSText': {
        paddingTop: 0,
      },
    },
  }),
);

const EmailVerificationDialog = (props: {
  email: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  redirectTo?: string;
}): React.ReactElement => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [progress, setProgress] = useState(-1);
  const [hasConsent, setConsent] = useState(false);
  const [code, setCode] = useState('');
  const { email, open, setOpen, redirectTo } = props;
  const location = useLocation();

  const kickOffTimer = () => {
    if (progress < 0) {
      setProgress(60);
      const timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress < 0) {
            clearInterval(timer);
          }
          return prevProgress - 1;
        });
      }, 1000);
    }
  };

  const sendEmailCode = () => {
    const authMeta: AuthLocalCachedMeta = getAuthMeta();
    const lastTimeEmail = authMeta?.lastTimeEmail || 0;
    const emailHashArr = authMeta?.emailHashArray || [];

    if (isEmailAddressCached(emailHashArr, email)) {
      setConsent(true);
    } else {
      axios
        .get<boolean>('/users/exist', {
          params: { email },
        })
        .then(response => {
          if (response.status === 200 && response.data) {
            setConsent(true);
          }
          setConsent(false);
        });
    }

    // if one minute has passed
    if (Date.now() - lastTimeEmail > 60000) {
      setLoading(true);
      setCode('');
      setErrorMsg('');

      auth0.passwordlessStart(
        {
          connection: 'email',
          send: 'code',
          email,
        },
        (err, _result) => {
          if (err) {
            setLoading(false);
            // need to handle different error code
            setErrorMsg(err.error_description || err.error);
          } else {
            storeAuthMeta(Date.now());
            kickOffTimer();
            setLoading(false);
          }
        },
      );
    }
  }

  const handleEmailAuthentication = () => {
    if (open) {
      setLoading(true)
      if (window?.webkit && window?.webkit.messageHandlers.authMessageHandler) {
        window?.webkit.messageHandlers.authMessageHandler.postMessage(email);
      } else if (window?.authMessageHandler) {
        window.authMessageHandler.postMessage(email)
      } else {
        sendEmailCode();
      }
    }
  };

  const authorizeEmail = () => {
    if (code?.length === EMAIL_CODE_LENGTH) {
      // show drawer by default
      const state = redirectTo || location.pathname;
      auth0.passwordlessLogin(
        {
          connection: 'email',
          email,
          verificationCode: code,
          state,
          scope: 'openid email'
        },
        err => {
          if (err) {
            setLoading(false);
            setErrorMsg(err.error_description || err.error);
          } else {
            setLoading(false);
            setOpen(false);
          }
        },
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSignup = () => {
    setLoading(true);
    authorizeEmail();
  };

  const onCodeChange = (c: string) => {
    setCode(c);
  };

  useEffect(() => {
    handleEmailAuthentication();
  }, [open]);

  useEffect(() => {
    if (hasConsent && code.length === EMAIL_CODE_LENGTH && !loading) {
      handleSignup();
    }
  }, [code]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Email verification"
      TransitionComponent={Transition}
      keepMounted
      open={open}
      className={classes.root}
    >
      <DialogTitle>Enter your verification code</DialogTitle>
      <SimpleLoader loading={loading} />
      <DialogContent className="dlgContent">
        <ReactCodeInput
          type="number"
          fields={EMAIL_CODE_LENGTH}
          autoFocus
          name="email-verification-code"
          inputMode="numeric"
          value={code}
          onChange={onCodeChange}
        />
        <Typography gutterBottom variant="subtitle2" color="textSecondary" component="div">
          Code sent to {email}
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions className="dlgActions">
        <Button
          onClick={sendEmailCode}
          color="inherit"
          variant="contained"
          disabled={loading || progress > 0}
        >
          {progress > 0 ? `Resend (:${progress})` : `Resend`}
        </Button>
        <Button
          onClick={handleSignup}
          color="primary"
          variant="contained"
          disabled={loading || code.length !== EMAIL_CODE_LENGTH}
        >
          Continue
        </Button>
      </DialogActions>
      {!hasConsent && (
        <DialogContent className="TOSText">
          <TOSString />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default EmailVerificationDialog;
