import React, { useContext, useState } from "react";
import { Box, Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@material-ui/core";
import { pathOr } from "ramda";
import { If, Then } from "react-if";
import { NoteAdd, Person } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import CustomerScheduleEntry, { AttendeeStatus } from "bos_common/src/types/crm/CustomerScheduleEntryType";

import { isEmptyOrNil } from "../../utils";
import { CalendarContext } from "./context/CalendarContext";
import ManageAttendeeDialog from "./ManageAttendeeDialog";
import AddAttendeeNoteDialog from "./AddAttendeeNoteDialog";
import { isEventInTheFuture, isEventOlderThanOneWeek } from "./utils";

type AttendeesSectionProps = {
  isEditMode?: boolean;
}

const AttendeesSection = ({ isEditMode = false }: AttendeesSectionProps) => {
  const { updateCustomerAttendanceAndNote, selectedEvent } = useContext(CalendarContext);
  const [showAttendeeDialog, setShowAttendeeDialog] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<CustomerScheduleEntry>();
  const { watch, setValue } = useFormContext();

  const { t } = useTranslation()

  // dispatch action here to update a customers session attendee status
  const handleOnAttendeeChanged = async (attendee: CustomerScheduleEntry, attendance: AttendeeStatus) => {
    const updatedAttendeesList = await updateCustomerAttendanceAndNote(attendee, attendance)
    if (!isEmptyOrNil(updatedAttendeesList)) setValue('attendees', updatedAttendeesList)
  }

  const toggleAddNoteDrawer = (attendee: CustomerScheduleEntry) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedAttendee(attendee)
  }

  const attendeesList: CustomerScheduleEntry[] = isEditMode ? selectedEvent?.customerEntries : watch('attendees');
  const getAttendeeName = (attendee: CustomerScheduleEntry) => pathOr('', ['customer', 'displayName'], attendee)

  const disableMarkingAttendance = isEventOlderThanOneWeek(selectedEvent)

  return (
    <Box className='section-container'>
      <Typography variant="body2" fontWeight={600} gutterBottom>{t('Attendees')}</Typography>
      <If condition={!isEmptyOrNil(attendeesList)}>
        <Then>
          {attendeesList?.map((attendee) => (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ pt: 0, pb: 0, mt: 1, width: '100%' }}
              key={`e-${attendee.eventId}-c-${attendee.customerId}`}
            >
              <Box sx={{ flex: 1 }}>
                <Typography>
                  {getAttendeeName(attendee)}
                </Typography>
                <If condition={!isEmptyOrNil(attendee.note)}>
                  <Typography
                    sx={{ wordBreak: "break-word" }}
                    component="div"
                    variant="subtitle2"
                    lineHeight={1.2}
                    color="textSecondary">
                    {attendee.note}
                  </Typography>
                </If>
              </Box>

              {isEditMode && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton onClick={toggleAddNoteDrawer(attendee)}><NoteAdd fontSize="small" /></IconButton>
                  <ToggleButtonGroup
                    value={attendee.status}
                    exclusive
                    onChange={(e, value) => handleOnAttendeeChanged(attendee, value)}
                    size="small"
                  >
                    <ToggleButton disabled={disableMarkingAttendance} value={AttendeeStatus.PRESENT} color="primary">{t("MerchantService_Attended")}</ToggleButton>
                    <ToggleButton disabled={disableMarkingAttendance} value={AttendeeStatus.ABSENT} color="secondary">{t("MerchantService_Absent")}</ToggleButton>
                  </ToggleButtonGroup>

                </Stack>
              )}
            </Stack>
          ))}
        </Then>
      </If>

      <Button
        variant="text"
        color="primary"
        startIcon={<Person />}
        sx={{ mt: 2, mb: 2 }}
        onClick={() => setShowAttendeeDialog(true)}
        disabled={disableMarkingAttendance}
      >
        {t('Calendar_ManageAttendee')}
      </Button>

      <ManageAttendeeDialog
        isOpen={showAttendeeDialog}
        setOpen={setShowAttendeeDialog}
        isEditMode={isEditMode}
      />

      <AddAttendeeNoteDialog
        open={!isEmptyOrNil(selectedAttendee)}
        setOpen={() => setSelectedAttendee(undefined)}
        attendee={selectedAttendee}
      />
    </Box>
  )
}

export default AttendeesSection;