import { find, append, defaultTo } from "ramda";

export class LocalStorageUtils {
  localStorageKey: string = '';

  constructor(localStorageKey: string) {
    this.localStorageKey = localStorageKey
  }

  getItem(): string {
    // @ts-ignore
    return JSON.parse(localStorage.getItem(this.localStorageKey))
  }

  setItem(updatedItem: any, shouldStringify: boolean = false) {
    const _updatedItem = shouldStringify ? JSON.stringify(updatedItem) : updatedItem
    localStorage.setItem(this.localStorageKey, _updatedItem)
  }

  findItemInList(filterFn: (item: any) => boolean) {
    const list = defaultTo([], this.getItem())
    return find(filterFn, list)
  }

  appendToList(updatedItem: any) {
    const list = append(updatedItem, defaultTo([], this.getItem()))
    this.setItem(list, true);
  }

  removeItem() {
    localStorage.removeItem(this.localStorageKey)
  }
}