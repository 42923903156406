import React, { useContext } from 'react';
import { format } from 'date-fns';
import { AscDesc } from 'stream-chat';
import { If, Then, Else } from 'react-if';
import { useTranslation } from 'react-i18next';
import { ExitToApp, Chat as ChatIcon, Menu } from '@material-ui/icons';
import { head, omit, pathOr, pipe, propOr, values } from 'ramda';
import {
  Box,
  createStyles,
  makeStyles,
  Stack,
  Theme,
  Typography,
  Avatar,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import {
  Chat,
  Channel,
  MessageInput,
  Thread,
  Window,
  LoadingIndicator,
  ChannelList,
  MessageList,
  useChannelStateContext,
  useChatContext,
} from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';

import { isChatUserOnline, isEmptyOrNil } from 'bos_common/src';
import ChatSettings from 'bos_common/src/components/PlatformChat/ChatSettings';
import { PlatformChatContext } from 'bos_common/src/components/PlatformChat/context/PlatformChatContext';
import ChatErrorMessage from 'bos_common/src/components/PlatformChat/ChatErrorMessage';
import { getAdminUserName } from 'bos_common/src/components/PlatformChat/utils';
import FloatingActionsMenu from 'bos_common/src/components/FloatingActionsMenu';
import { ChatMessageInputStyle } from 'bos_common/src/components/PlatformChat/style';
import {
  MessageListWrapper,
  RenderChannelPreview,
} from 'bos_common/src/components/PlatformChat/templates';

import { AppContext } from '../context/AppContext';
import { Merchant } from '../services/models';
import { NotificationSeverity } from '../types/NotificationSlice';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .str-chat-channel, .str-chat': {
        height: 'calc(100vh - 70px)',
        overflow: 'auto',
      },

      '& .str-chat__message--me': {
        '& .str-chat__avatar': {
          '& .str-chat__avatar-fallback': {
            background: theme.palette.warning.main,
          },
        },
      },

      ...ChatMessageInputStyle(theme),

      '& .str-chat-channel-list': {
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(8),
        },
      },

      '& .str-chat__channel-list-messenger': {
        height: '100%',
        overflow: 'auto',
      },
    },
  })
);

const MessageMenuBtn = () => {
  const { client, setActiveChannel } = useChatContext();
  const { triggerNotification } = useContext(AppContext);
  const { channel } = useChannelStateContext();
  const { t } = useTranslation();

  const exitChat = async () => {
    if (isChatUserOnline(channel)) {
      triggerNotification(
        true,
        `Can't delete chat when customer is online`,
        NotificationSeverity.ERROR,
        true
      );

      return;
    }
    client.deleteChannels([channel.cid], { hard_delete: true }).then(() => {
      const nextChannel = pipe(
        propOr({}, 'activeChannels'),
        omit([channel.cid]),
        values,
        head
      )(client);
      setActiveChannel(nextChannel);
    });
  };

  const actionItems = [
    {
      icon: <ExitToApp />,
      text: t('ChannelDeleteChat'),
      handleClick: exitChat,
    },
  ];

  return <FloatingActionsMenu actionItems={actionItems} />;
};

const RenderChannelHeader = () => {
  const { channel } = useChannelStateContext();
  const { openMobileNav } = useChatContext();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const lastMessageTime = pathOr("", ['state', 'last_message_at'], channel);
  const createdAtDate = pathOr("", ['data', 'created_at'], channel);
  const channelUserName = pathOr('Unnamed Channel', ['data', 'created_by', 'name'], channel)
  const renderedTime = !isEmptyOrNil(lastMessageTime)
    ? `Last message: ${format(lastMessageTime, 'MM/dd/yyyy - p')}`
    : 'No messages yet';

  return (
    <Stack direction="row" gap={1} alignItems="center" p={2}>
      <If condition={isMobile}>
        <IconButton onClick={openMobileNav}>
          <Menu />
        </IconButton>
      </If>
      <Typography>
        <Avatar
          sx={{
            color: (theme) => theme.palette.background.paper,
            background: (theme) => theme.palette.warning.main,
          }}
        >
          {channelUserName[0]}
        </Avatar>
      </Typography>

      <Box>
        <Typography variant="h6">{channelUserName}</Typography>
        <Typography variant="body2" color="secondary">
          User entered lobby: {format(new Date(createdAtDate), 'MM/dd/yyyy - p')}
        </Typography>
        <Typography variant="body2" color="secondary">
          {renderedTime}
        </Typography>
      </Box>
    </Stack>
  );
};

const ChatBlankSlate = () => {
  const { channel } = useChatContext();

  if (!isEmptyOrNil(channel)) return null;

  return (
    <Box
      sx={{
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 150px)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <ChatIcon sx={{ fontSize: 150, opacity: 0.4 }} />
      <Typography>No chats available currently</Typography>
    </Box>
  );
};


const RenderUserLeftLobbyMessage = () => {
  const { channel } = useChannelStateContext();
  const lastMessageTime = pathOr('', ['state', 'last_message_at'], channel)

  return (
    <Box textAlign="center" p={2} pt={0} sx={{ fontSize: (theme) => theme.spacing(2.5) }}>
      User left the lobby {!isEmptyOrNil(lastMessageTime) ? `at ${format(lastMessageTime, 'MM/dd/yyyy - p')}` : ''}
    </Box>
  )

}
const RenderMessageInput = () => {
  const { channel } = useChannelStateContext();
  const isUserLeft = !isChatUserOnline(channel);

  return (
    <Stack
      alignItems="center"
      p={2}
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    >
      <If condition={isUserLeft}>
        <RenderUserLeftLobbyMessage />
      </If>

      <Stack direction="row" width="100%" alignItems="center">
        <MessageMenuBtn />
        <Box width="100%" sx={{ opacity: isUserLeft ? 0.6 : 1, pointerEvents: isUserLeft ? "none" : "all" }}>
          <MessageInput disabled={isUserLeft} />
        </Box>
      </Stack>
    </Stack>
  );
};

const AdminChatScreen = () => {
  const { chatClient } = useContext(PlatformChatContext);

  const { merchant } = useContext(AppContext);

  const classes = useStyle();

  if (!chatClient) return <LoadingIndicator />;

  const filters = {
    type: 'messaging',
    members: { $in: [getAdminUserName(merchant || ({} as Merchant))] },
  };
  const sort = { last_message_at: -1 as AscDesc };

  return (
    <Box className={classes.root}>
      <Chat client={chatClient} theme="messaging light">
        <ChatErrorMessage />
        <ChannelList
          setActiveChannelOnMount
          filters={filters}
          sort={sort}
          Preview={(props) => <RenderChannelPreview {...props} />}
        />
        <Channel EmptyPlaceholder={<ChatBlankSlate />}>
          <Window>
            <RenderChannelHeader />
            <MessageListWrapper>
              <MessageList onlySenderCanEdit messageActions={['edit', 'delete']} />
            </MessageListWrapper>

            <RenderMessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </Box>
  );
};

const AdminChat = () => {
  const { isChatStarted } = useContext(PlatformChatContext);

  return (
    <If condition={!isChatStarted}>
      <Then>
        <ChatSettings />
      </Then>
      <Else>
        <AdminChatScreen />
      </Else>
    </If>
  );
};

export default AdminChat;
