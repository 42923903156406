import { Box, createStyles, makeStyles } from '@material-ui/core';
import useAxios from 'axios-hooks';
import { find, propEq } from 'ramda';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Translation } from 'react-i18next';
import {
  MerchandiseApiResponseType,
  MerchandiseModifierCategory,
  MerchandiseModifierTemplate,
} from '../../bos_common/src/types/MerchandiseType';
import { AppContext } from '../../context/AppContext';
import AppToastMessage from '../common/AppToastMessage';

export const random = (num: number = 1000000) => Math.floor(Math.random() * num);
export type CateType = {
  name: string;
  id: string | number;
};
export type ListType = {
  id: string | number;
  title: string;
  description: string;
  categories: Array<CateType>;
  items: number;
  checked: boolean;
};

export enum StepNums {
  Start = 'Start',
  NewTemp = 'NewTemplate',
  NewCate = 'NewCategory',
  SelectCate = 'SelectCategory',
  SelectModifier = 'SelectModifier',
  ApplyItems = 'ApplyItems',
  ItemUse = 'ItemUse',
}

export type StepType = {
  num: StepNums;
  name: string;
  desc?: string | JSX.Element;
  back?: StepType;
  info?: string | Record<string, any>;
};

const makeStep = (
  num: StepNums = StepNums.Start,
  name: string,
  desc?: string | JSX.Element,
  info?: string | Record<string, any>,
  back?: {
    num: StepNums;
    name: string;
    desc?: string | JSX.Element;
    info?: string | Record<string, any>;
  }
) => {
  return {
    num,
    name,
    desc,
    info,
    back,
  };
};

export const StepStart = makeStep(
  StepNums.Start,
  'Start',
  <Translation>{(t) => t('ModifierTemplates')}</Translation>
);
export const StepNewTemp = makeStep(
  StepNums.NewTemp,
  'AddTemplate',
  <Translation>{(t) => t('NewTemplate')}</Translation>
);
export const StepNewCate = makeStep(
  StepNums.NewCate,
  'AddCategory',
  <Translation>{(t) => t('NewCategory')}</Translation>
);
export const StepSelectCategory = makeStep(
  StepNums.SelectCate,
  'SelectCategory',
  <Translation>{(t) => t('SelectCategory')}</Translation>
);
// 这个被ModifierModal 取代了
// export const StepSelectModifier = makeStep(StepNums.SelectModifier, 'selectModifier', 'Select Modifier')
export const StepApplyItems = makeStep(
  StepNums.ApplyItems,
  'ApplyItems',
  <Translation>{(t) => t('ApplyItems', { name: 'sth' })}</Translation>
);
export const StepItemUse = makeStep(
  StepNums.ItemUse,
  'ItemUse',
  <Translation>{(t) => `${t('ItemsUsingName')} sth`}</Translation>
);

export const API = {
  merchants: '/merchants/merchandises',
  templates: '/merchants/modifiers/templates',
  merchandises: '/merchants/merchandises',
  // applyItem: '/merchants/merchandises/bulkModifierTemplate',
  applyItem: '/merchants/merchandises/bulk',

  updateCategory: '/merchants/modifiers/categories',
  addModifier: '/merchants/modifiers',
  delModifiers: '/merchants/modifiers/bulk',
  delCategorys: '/merchants/modifiers/categories/bulk',
};

type ModifierCategoryNames = {
  id: string;
  name: string;
};
export interface MerchandiseTemplateInfo {
  id: number;
  merchantId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  modifierCategoryNames: ModifierCategoryNames[];
  version: number;
  applied: number | string;
}

export type TemplateType = Omit<MerchandiseModifierTemplate, 'modifierCategoryIds'> & {
  modifierCategoryNames: { id: number; name: string }[];
};

export const TemplateContext = createContext<{
  steps: StepType[];
  changeSteps: Dispatch<SetStateAction<StepType[]>>;
  changeToast: (error: boolean) => void;
  goBack: () => void;
  goNext: (step: StepType) => void;
  info: MerchandiseApiResponseType | undefined;
  templateLists: MerchandiseModifierTemplate[];
  updateInfo: () => Promise<any>;
  updateTemplate: () => Promise<any>;
  selectTemplate: MerchandiseModifierTemplate | undefined;
  changeTemplate: Dispatch<SetStateAction<MerchandiseModifierTemplate | undefined>>;
  selectCategory: MerchandiseModifierCategory | undefined;
  changeCategory: Dispatch<SetStateAction<MerchandiseModifierCategory | undefined>>;
  editCategoryOpen: boolean;
  setEditCategoryOpen: (bol: boolean) => void;
  openModifierModal: boolean;
  setOpenModifierModal: (bol: boolean) => void;
  openCategoryModal: boolean;
  setOpenCategoryModal: (bol: boolean) => void;
}>({
  steps: [],
  changeSteps: () => {},
  changeToast: (_bol: boolean) => {},
  goBack: () => {},
  goNext: () => {},
  info: undefined,
  templateLists: [],
  updateInfo: () => new Promise(() => {}),
  updateTemplate: () => new Promise(() => {}),
  selectTemplate: undefined,
  changeTemplate: () => {},
  selectCategory: undefined,
  changeCategory: () => {},
  editCategoryOpen: false,
  setEditCategoryOpen: (_bol: boolean) => {},
  openModifierModal: false,
  setOpenModifierModal: (_bol: boolean) => {},
  openCategoryModal: false,
  setOpenCategoryModal: (_bol: boolean) => {},
});

//  公共 provider
export const TemplateProvider = ({ children }: { children: React.ReactNode }) => {
  const { merchant } = useContext(AppContext);
  const [steps, setSteps] = useState<StepType[]>([StepStart]);
  const [selectTemplate, setSelectTemplate] = useState<MerchandiseModifierTemplate>();
  const [selectCategory, setSelectCategory] = useState<MerchandiseModifierCategory>();

  const [openModifierModal, setOpenModifierModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const [toast, setToast] = useState<{ showAlert: boolean; error: boolean }>({
    showAlert: true,
    error: false,
  });

  const [editCategoryOpen, setEditCategoryOpen] = useState<boolean>(false);

  const changeToast = (bol: boolean) => {
    setToast({ showAlert: true, error: bol });
  };

  const [{ data: allInfo, error: infoErr, loading: merchandiseLoading }, updateInfo] =
    useAxios<MerchandiseApiResponseType>({
      url: API.merchants,
      params: { merchantId: merchant?.id },
    });

  if (infoErr) {
    changeToast(true);
  }

  const [
    { data: currentTemplateLists = [], error: templateErr, loading: templateLoading },
    updateTemplate,
  ] = useAxios<TemplateType[]>({
    url: API.templates,
    params: { merchantId: merchant?.id },
  });

  if (templateErr) {
    changeToast(true);
  }
  const templateLists: MerchandiseModifierTemplate[] = useMemo(() => {
    const arr: MerchandiseModifierTemplate[] = currentTemplateLists.map((item) => ({
      ...item,
      modifierCategoryIds: item.modifierCategoryNames.map((jtem) => jtem.id),
    }));
    return arr;
  }, [currentTemplateLists]);

  useEffect(() => {
    if (toast.showAlert) {
      setTimeout(() => {
        setToast({ ...toast, showAlert: false });
      }, 3000);
    }
  }, [toast]);

  const goBack = useCallback(() => {
    if (steps.length >= 2) {
      steps.pop();
      setSteps([...steps]);
    }
  }, [steps]);
  const goNext = useCallback(
    (step: StepType) => {
      setSteps([...steps, step]);
    },
    [steps]
  );

  return (
    <TemplateContext.Provider
      value={{
        steps,
        changeSteps: setSteps,
        changeToast,
        goBack,
        goNext,
        info: allInfo,
        templateLists,
        updateInfo,
        updateTemplate,
        selectTemplate,
        changeTemplate: setSelectTemplate,
        selectCategory,
        changeCategory: setSelectCategory,
        editCategoryOpen,
        setEditCategoryOpen,
        openModifierModal,
        setOpenModifierModal,
        openCategoryModal,
        setOpenCategoryModal,
      }}
    >
      {children}
      <AppToastMessage {...toast} />
    </TemplateContext.Provider>
  );
};

export const useSelfStyles = makeStyles(() =>
  createStyles({
    ff: {
      fontFamily: 'monospace',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btn: {
      textTransform: 'capitalize',
      width: '100%',
    },
    oneMore: {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
    twoMore: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
      color: '#898989',
    },
  })
);

export const FabBox = (props: {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        width: '100%',
        gap: 10,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {props.children}
    </Box>
  );
};
