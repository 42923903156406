import React, { useContext, useEffect, useState } from "react"
import useAxios from "axios-hooks";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Fab,
  FormControl, FormControlLabel, LinearProgress, Paper, Radio, RadioGroup, Slide
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { UserContext } from "../bos_common/src/context/UserContext"
import { Merchant } from "../services/models";
import { getAuthHeaders } from "../utils";
import { AppContext } from "../context/AppContext";

const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) => {
  return <Slide direction="up" ref={ref} {...props} />;
});
Transition.displayName = 'SlideUpTransition';

export interface MerchantSelectorProps {
  open: boolean,
  onClose: () => void,
}

const MerchantSelector = (props: MerchantSelectorProps) => {
  const history = useHistory();
  const location = useLocation();
  const { open, onClose } = props;
  const { token } = useContext(UserContext);
  const { merchant } = useContext(AppContext);
  const [selectedMerchant, setSelected] = useState<Merchant | undefined>(merchant);
  const { t } = useTranslation();

  useEffect(() => {
    if (token && !initialLoading) {
      fetchAllMerchants();
    }
  }, [token])

  if (!token) {
    return null;
  }

  const [{ data: merchantArray, loading: initialLoading }, fetchAllMerchants] = useAxios<Merchant[]>({
    url: '/merchants/all',
    headers: getAuthHeaders(token),
  }, { manual: true });

  const [{ loading: merchantLoading }, fetchNewMerchant] = useAxios<Merchant>({
    url: '/merchants',
    headers: getAuthHeaders(token),
  }, { manual: true });

  const getNewURL = (newMerchant: Merchant) => (merchant?.username && merchant?.type === newMerchant.type)
    ? location.pathname.replace(merchant?.username, newMerchant.username)
    : `/${newMerchant.username}`

  const onDone = () => {
    if (selectedMerchant && selectedMerchant?.id !== merchant?.id) {
      onClose();
      history.replace({ pathname: getNewURL(selectedMerchant) });
    } else if (selectedMerchant && selectedMerchant?.id === merchant?.id) {
      onClose();
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const merchantId = (event.target as HTMLInputElement).value;
    const selected = merchantArray?.find((m) => m.id === merchantId);
    setSelected(selected);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle>{t("Orders_SelectStore")}</DialogTitle>
      {(initialLoading || merchantLoading) && <LinearProgress />}
      <DialogContent>
        <Paper elevation={3}>
          <CardContent>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="merchants"
                name="controlled-radio-buttons-group"
                value={selectedMerchant?.id}
                onChange={onChange}>
                {merchantArray && merchantArray.map((merchant: Merchant, index: number) => {
                  return (
                    <FormControlLabel
                      key={merchant.id}
                      sx={{marginBottom: 1}}
                      value={merchant.id}
                      control={<Radio />}
                      label={ <span> {merchant.officialName} - @{merchant.username} </span>}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </CardContent>
        </Paper>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly', paddingBottom: 20, paddingTop: 20 }}>
        <Fab
          variant='extended'
          color='secondary'
          size="medium"
          onClick={onClose}
        >
          {t("Cancel")}
        </Fab>
        <Fab
          variant='extended'
          color='primary'
          size="medium"
          onClick={onDone}
        >
          {t("Confirm")}
        </Fab>
      </DialogActions>
    </Dialog>
  )
}

export default MerchantSelector;