import React, { useContext, useMemo } from 'react';
import { Card, CardContent, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import { If } from 'react-if';
import { pathOr } from 'ramda';
import classNames from 'classnames';

// src
import {
  LineItem,
  MerchandiseApiResponseType,
  Merchant,
  Order,
  User,
} from '../../services/models';
import OrderSummary from '../../bos_common/src/components/OrderSummary';
import OrderItemCard, { OrderItemCardProps } from './OrderItemCard';
import OrderCardHeader from './OrderCardHeader';
import { AppContext } from '../../context/AppContext';
import { expandOrderLineItems, sortLineItemsByCategory } from '../../utils/orderUtils';
import { getOrderElapsedTimeColor } from './orderCardUtils';
import { UserContext } from '../../bos_common/src/context/UserContext';

type CustomStyleProps = {
  fullWidth?: boolean;
};
const useStyles = makeStyles<Theme, CustomStyleProps>((theme) =>
  createStyles({
    root: {
      margin: ({ fullWidth }) => fullWidth ? 0 : `0 ${theme.spacing(1)} ${theme.spacing(2)} 0`,
      maxWidth: ({ fullWidth }) => (fullWidth ? 'unset' : 300),
      width: '100%',
      border: `1px solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(1),

      "&.large": {
        maxWidth: 400,

        "& .item-label, .item-quantity": {
          fontSize: theme.spacing(3),
        },

        "& .order-title, .order-countdown, & .display-name, .phone-info, .collapsible-container .MuiTypography-overline, .totalPrice .MuiTypography-root, .priceRow > div ": {
          fontSize: theme.spacing(2.5),
        },

        "& .MuiChip-root, .MuiChip-outlined.order-header-chip": {
          fontSize: `14px !important`,
        },

        "& .order-remain-inventory .MuiTypography-root": {
          fontSize: theme.spacing(2),
        },

        "& .action-btns": {
          "& .MuiButtonBase-root": {
            fontSize: theme.spacing(2),
            height: theme.spacing(6),
            borderRadius: theme.spacing(5),
          },
        },
      },

      '& .order-card-content-container': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: 0,
        overflowY: 'auto',
      },

      '& .order-item': {
        marginBottom: theme.spacing(1),
      },

      '& .order-status': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  })
);

export type OrderItemProps = Omit<OrderItemCardProps, 'item' | 'order' | 'readOnly' | 'index'>;
type OrderCardProps = {
  order: Order;
  merchant?: Merchant;
  user?: User;
  readOnly?: boolean;
  fullWidth?: boolean;
  orderItemProps?: OrderItemProps;
  expandedLineItems?: boolean;
  showMerchantInfo?: boolean
  detailedMode?: boolean,
  children?: React.ReactElement | Array<React.ReactElement>;
};

export default function OrderCard(props: OrderCardProps) {
  const {
    order,
    user,
    merchant,
    readOnly = false,
    fullWidth = false,
    orderItemProps,
    expandedLineItems = false,
    showMerchantInfo = false,
    detailedMode = false,
    children,
  } = props;
  const classes = useStyles({ fullWidth });
  const appContext = useContext(AppContext);
  const { user: staff } = useContext(UserContext);
  const merchantData: MerchandiseApiResponseType = pathOr(
    {},
    ['merchant', 'merchantData'],
    appContext
  );

  // Add Flatten Line Item to order when Order Status is Paid
  const updatedOrder = useMemo(() => expandOrderLineItems(order, expandedLineItems), [order])
  const sortedLineItems: LineItem[] = useMemo(() => sortLineItemsByCategory(updatedOrder, merchantData), [updatedOrder]);

  const elapsedTimeColor = getOrderElapsedTimeColor(updatedOrder, staff)
  const customCardStyles = {
    ...(elapsedTimeColor && { border: `1px solid ${elapsedTimeColor.main}` })
  }

  const orderCardClasses = classNames(classes.root, {
    'large': detailedMode
  })
  return (
    <Card
      className={orderCardClasses}
      elevation={3}
      style={customCardStyles}
    >
      <OrderCardHeader
        order={order}
        user={user}
        merchant={merchant || appContext.merchant}
        showMerchantInfo={showMerchantInfo}
      />

      <CardContent className='order-card-content-container'>
        {sortedLineItems.map((item: LineItem, index) => (
          <div className="order-item" key={`${item.id}-${item.customizationSignature}-${index}`}>
            <OrderItemCard
              readOnly={readOnly}
              item={item}
              index={index}
              order={order}
              detailedMode={detailedMode}
              {...orderItemProps}
            />
          </div>
        ))}

        <If condition={detailedMode}>
          <OrderSummary order={order} anonymizeCoupon={true}/>
        </If>

        {children}
      </CardContent>
    </Card>
  );
}
