import React, { useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Redirect, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { If, Then, Else } from 'react-if';

import { UserContext } from '../../bos_common/src/context/UserContext';
import axios from '../../bos_common/src/services/backendAxios';
import { getAPIErrorMessage, getAuthHeaders } from '../../bos_common/src';

import { Merchant, Order, User } from '../../services/models';
import useFetchMerchant from '../../services/useFetchMerchant';
import { AppContext } from '../../context/AppContext';

import { OrderPageType } from '../OrderPageType';
import MerchantOrdersPageCompact from './commons/Compact';
import MerchantOrdersPageWide from './commons/Wide';
import { isEmptyOrNil, merchantsMap, orderMap, userMap } from '../../utils';
import MerchantOrdersContextProvider, { OrderDataMap } from '../../context/MerchantOrders/MerchantOrdersContextProvider';
import { getOrdersMinTimestamp } from '../../utils/orderUtils';
import { NotificationSeverity } from '../../types/NotificationSlice';
import { isParentMerchant } from '../../utils/merchantUtils';

interface IMerchantOrdersParams {
  type: OrderPageType;
  merchantUsername: string;
}

// We may render orders from child merchant onto parent merchant, thus,
// we need to return a list of merchants as part of the data.
interface OrderData {
  orders: Order[];
  users: User[];
  merchants: Merchant[];
}

class FetchingStatus {
  static isFetching: boolean = false;

  static lastFetched: string = getOrdersMinTimestamp();

  static setFetching(fetching: boolean) {
    FetchingStatus.isFetching = fetching;
    if (fetching === true) {
      this.lastFetched = new Date().toUTCString();
    }
  }
}

type NameToBranchType = {
  [key: string]: Merchant[]|undefined
};

const MerchantOrdersPage = () => {
  const { merchantUsername } = useParams<IMerchantOrdersParams>();
  const { merchant, updatedOrder, triggerNotification } = useContext(AppContext);
  const { token } = useContext(UserContext)
  const [ordersData, setOrdersData] = useState<OrderDataMap>({ orders: {}, users: {} });
  const [nameToBranches, setBranches] = useState<NameToBranchType>({});

  if (!merchantUsername && !merchant) {
    return <Redirect to={'/'} />
  }

  useFetchMerchant({ merchantUsername })

  const partialUpdateOrders = (newOrders?: Order[], newUsers?: User[]) => {
    if (newOrders && newOrders.length > 0) {
      setOrdersData(preState => {
        return {
          orders: { ...preState.orders, ...orderMap(newOrders) },
          users: { ...preState.users, ...userMap(newUsers) },
        }
      });
    }
  }

  const minTimestampOffset = getOrdersMinTimestamp()

  const fetchNewOrders = (newOrder?: Order | null) => {
    if (!newOrder) return;
    const orderId = newOrder.id;
    if (ordersData?.orders[orderId] && ordersData?.orders[orderId]?.updatedAt >= newOrder.updatedAt) {
      return;
    }

    if (!FetchingStatus.isFetching) {
      const lastFetched = FetchingStatus.lastFetched;
      FetchingStatus.setFetching(true);
      const orderDate = new Date(newOrder.toPickupTime);
      const orderMinTSOffset = new Date(orderDate.getTime() - 5 * 60000).toUTCString();
      const minTSOffset = lastFetched < orderMinTSOffset ? lastFetched : orderMinTSOffset;

      axios.get(
        '/merchants/orders/data',
        {
          headers: getAuthHeaders(token),
          params: {
            merchantId: newOrder.merchantId,
            minTimestampOffset: minTSOffset,
            limit: 400,
          },
        },
      ).then((response) => {
        if (response.status === 200) {
          FetchingStatus.setFetching(false);
          const _newOrderData = response.data as OrderData;
          if (_newOrderData) {
            partialUpdateOrders(_newOrderData.orders, _newOrderData.users);
          }
        }
      }).catch((err) => {
        const msg = getAPIErrorMessage(err);
        if (msg) { triggerNotification(true, msg, NotificationSeverity.ERROR); }
      }).finally(() => FetchingStatus.setFetching(false));
    }
  }

  // fetch orders
  const [{ data: _ordersData, loading: loadingOrders }, expensiveRefetch, cancelOrdersRequest] = useAxios<OrderData>({
    url: '/merchants/orders/data',
    headers: getAuthHeaders(token),
    params: {
      merchantUsername,
      minTimestampOffset,
      limit: 500,
    },
  }, { manual: true });

  // fetch store branches
  const [{ loading: loadingBranches }, fetchBranches] = useAxios<Merchant[]>(
    { url: `/merchants/${merchant?.id}/store-locations`,},
    { manual: true }
  );

  // replace state when do a refetch
  useEffect(() => {
    setOrdersData({
      orders: orderMap(_ordersData?.orders),
      users: userMap(_ordersData?.users),
      merchants: merchantsMap(_ordersData?.merchants),
    });
  }, [_ordersData]);

  // refresh MerchantOrdersPage when on a different day
  useEffect(() => {
    if (!FetchingStatus.isFetching) {
      FetchingStatus.setFetching(true);
      cancelOrdersRequest();

      const branches = nameToBranches[merchantUsername];
      const storeIds = branches && Array.from(new Set(branches.map(i => i.id)));
      expensiveRefetch({
        params: {
          merchantUsername,
          minTimestampOffset,
          limit: 500,
          storeIds,
        }
      })
        .catch((err) => {
          const msg = getAPIErrorMessage(err);
          if (msg) { triggerNotification(true, msg, NotificationSeverity.ERROR); }
        })
        .finally(() => FetchingStatus.setFetching(false));
    }
  }, [minTimestampOffset, merchantUsername, nameToBranches])

  useEffect(() => {
    if (updatedOrder) {
      fetchNewOrders(updatedOrder);;
    }
  }, [updatedOrder]);

  // fetch merchant branches in the begining
  useEffect(() => {
    if (isEmptyOrNil(token) || !merchant || !isParentMerchant(merchant)) return;
    fetchBranches({
      url: `/merchants/${merchant.id}/store-locations`,
      headers: getAuthHeaders(token),
    }).then((response) => {
      if (response.status === 200) {
        nameToBranches[merchantUsername] = response.data;
        setBranches({...nameToBranches});
        FetchingStatus.setFetching(false); // force refetching
      }
    })
  }, [merchant?.id, token]);

  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up('sm'));
  const branches = nameToBranches[merchantUsername];

  return (
    <MerchantOrdersContextProvider
      updateOrders={partialUpdateOrders}
      ordersData={ordersData}
      loadingOrders={loadingOrders || loadingBranches}
      branches={branches}
    >
      <If condition={isWide}>
        <Then>
          <MerchantOrdersPageWide
            merchant={merchant}
            loading={loadingOrders}
          />
        </Then>
        <Else>
          <MerchantOrdersPageCompact
            merchant={merchant}
            loading={loadingOrders} />
        </Else>
      </If>
    </MerchantOrdersContextProvider >
  )
};

export default MerchantOrdersPage;
