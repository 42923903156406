import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    textTransform:"initial",
    "&:before": {
      content: "'\u{201C}'"
    },
    "&:after": {
      content: "'\u{201D}'"
    }
  }
})
type MerchandiseNotesProps = {
  note: string
}
const MerchandiseNotes = (props: MerchandiseNotesProps) => {
  const classes = useStyles();

  return (
    <Typography variant="overline" color="text.secondary" className={classes.root}>
      Note: {props.note}
    </Typography>
  )
}

export default MerchandiseNotes;