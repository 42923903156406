import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  Drawer,
  Switch,
} from '@material-ui/core';
import { If } from 'react-if';

import { Merchandise, MerchandiseCategory } from '../../services/models';
import { displayTime, isEmptyOrNil, isMerchandiseAvailable } from '../../utils';
import { AppContext } from '../../context/AppContext';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inventoryTable: {
      padding: theme.spacing(2),
      '& .category-info': {
        marginBottom: theme.spacing(2)
      },
      '& .inventory-table': {
        '& thead > tr > th': {
          backgroundColor: 'transparent',
          color: theme.palette.grey[400],
          textTransform: 'uppercase',

          '&:first-child': {
            paddingLeft: 0
          }
        },

        '& tbody tr': {
          cursor: 'pointer'
        },
        '& tbody > tr > td': {
          lineHeight: 1.2,
          '&.row-operation': {
            '& p': {
              marginTop: `-${theme.spacing(1)}`,
              marginRight: theme.spacing(1),
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.6rem'
              }
            }
          },
          '&:first-child': {
            paddingLeft: 0
          }
        },
      }
    },
    merchandiseActionDrawer: {
      [theme.breakpoints.down('sm')]: {
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }
      }
    }
  }
));

const RenderSoldOutTime = ({ lastSoldOut } : { lastSoldOut: Date|null|undefined }) => {
  const { t } = useTranslation();
  const { merchant } = useContext(AppContext);
  if (!lastSoldOut || !merchant) {
    return null
  } else {
    const timeZone = merchant.timeZone || 'America/Los_Angeles';
    return (
      <Typography variant="caption" component="div" color="textSecondary" lineHeight={1.2}>
        {t("Sold_Out_At")}: {displayTime(lastSoldOut, false, timeZone)}
      </Typography>
    );
  }
}

type MerchandiseDrawerData = {
  merchandise?: Merchandise | null,
  isOpen?: boolean,
}
type MerchandiseActionDrawerProps = {
  merchandiseDrawerData: Partial<MerchandiseDrawerData>,
  setMerchandiseDrawerData: (merchandiseDrawerData: MerchandiseDrawerData) => void
  MerchandiseActionComponent: any
}
const MerchandiseActionDrawer = ({ merchandiseDrawerData, MerchandiseActionComponent, setMerchandiseDrawerData }: MerchandiseActionDrawerProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { merchandise, isOpen = false } = merchandiseDrawerData
  const anchor = isMobile ? 'bottom' : 'right'

  const onClose = () => setMerchandiseDrawerData({ isOpen: false })

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor={anchor}
      className={classes.merchandiseActionDrawer}
    >
      <MerchandiseActionComponent merchandise={merchandise} onClose={onClose} />
    </Drawer>
  )
}

type MerchandiseInventoryTableProps = {
  merchandises: Merchandise[],
  category?: MerchandiseCategory | null
  onItemUpdateCallback: (value: Pick<Merchandise, "id" | "inventory" >) => void,
  MerchandiseActionComponent?: any
}
const MerchandiseInventoryTable = ({ onItemUpdateCallback, merchandises, category, MerchandiseActionComponent }: MerchandiseInventoryTableProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [merchandiseDrawerData, setMerchandiseDrawerData] = useState<Partial<MerchandiseDrawerData>>({ isOpen: false, merchandise: null })
  const { t } = useTranslation();

  useEffect(() => {
    if (!merchandiseDrawerData.isOpen) {
      setTimeout(() => {
        setMerchandiseDrawerData({ merchandise: null })
      }, 100)
    }
  }, [merchandiseDrawerData.isOpen])

  const labels = {
    name: t("Inventory_List_Name"),
    dailyMax: isMobile ? t("Inventory_List_Max") : t("Inventory_List_DailyMax"),
    currentInventory: isMobile ? t("Inventory_List_Curr") : t("Inventory_List_Current"),
    availability: isMobile ? t("Inventory_List_Aval") : t("Inventory_Availability"),
    inStock: t('InStock'),
    outOfStock: t("OutOfStock"),
  }
  const isMerchanidseActionAvailable = Boolean(MerchandiseActionComponent)

  const toggleMerchandiseAvailability = (e: React.ChangeEvent<HTMLInputElement>, merchandise: Merchandise) => {
    const dailyMaxStock = merchandise.inventory?.dailyMaxStock ?? null
    const dailyCurrentStock = e.target.checked ? dailyMaxStock : 0

    onItemUpdateCallback({ id: merchandise.id, inventory: { ...merchandise.inventory, dailyMaxStock, dailyCurrentStock } })
  }

  return (
    <>
      <CardContent id={`category-${category?.id ?? '0'}`}>
        <Card className={classes.inventoryTable}>
          {category &&
            (
              <div className="category-info">
                <Typography variant="body1" component="div">{category.name}</Typography>
                {category.description && <Typography variant="subtitle2" component="p" color="textSecondary">{category.description}</Typography>}
              </div>
            )
          }

          <TableContainer className="inventory-table">
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{labels.name}</TableCell>
                  <If condition={!isMobile}>
                    <>
                      <TableCell>{labels.dailyMax}</TableCell>
                      <TableCell>{labels.currentInventory}</TableCell>
                    </>
                  </If>
                  <TableCell align="right">{labels.availability}</TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {merchandises?.map((merchandise: Merchandise) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={merchandise.id}
                    onClick={() => isMerchanidseActionAvailable && setMerchandiseDrawerData({ isOpen: true, merchandise })}>
                    <TableCell>
                      <div>{merchandise.name}</div>
                      {/* show daily inventory count under lable on mobile view */}
                      <If condition={isMobile && !isEmptyOrNil(merchandise.inventory?.dailyCurrentStock)}>
                        <>
                          <Typography variant="caption" component="div" color="textSecondary" lineHeight={1.2}>
                            {t("Inventory_Settings_CurrentStock")}: {merchandise.inventory?.dailyCurrentStock}
                          </Typography>
                          <RenderSoldOutTime lastSoldOut={merchandise.inventory?.lastSoldOut} />
                        </>
                      </If>
                    </TableCell>

                    {/* hide daily max and current stock for mobile */}
                    <If condition={!isMobile}>
                      <>
                        <TableCell>{merchandise.inventory?.dailyMaxStock ?? t("Unlimited")}</TableCell>
                        <TableCell>
                          <div>{merchandise.inventory?.dailyCurrentStock ?? t("Unlimited")}</div>
                          <RenderSoldOutTime lastSoldOut={merchandise.inventory?.lastSoldOut} />
                        </TableCell>
                      </>
                    </If>

                    <TableCell align="right" className="row-operation">
                      <Switch
                        checked={isMerchandiseAvailable(merchandise)}
                        onClick={(e: any) => e.stopPropagation()}
                        onChange={(e) => toggleMerchandiseAvailability(e, merchandise)}
                        color="primary"
                        name={`${merchandise.id}-availability-switch`}
                        inputProps={{ 'aria-label': 'in-stock' }}
                        id={`${merchandise.id}-availability-switch`}
                      />
                      <label
                        htmlFor={`${merchandise.id}-availability-switch`}
                        onClick={(e: any) => e.stopPropagation()}
                      >
                        <Typography variant="subtitle2" component="p" color="textSecondary">
                          {isMerchandiseAvailable(merchandise) ? labels.inStock : labels.outOfStock}
                        </Typography>
                      </label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </CardContent>

      <If condition={isMerchanidseActionAvailable}>
        <MerchandiseActionDrawer
          merchandiseDrawerData={merchandiseDrawerData}
          setMerchandiseDrawerData={setMerchandiseDrawerData}
          MerchandiseActionComponent={MerchandiseActionComponent}
        />
      </If>
    </>
  )
}

export default MerchandiseInventoryTable