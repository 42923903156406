import React from "react";

export type ImageUploadButtonProps = {
  handleFiles: (files: File[], extraArgs: any) => void;
  addCBParams?: any;
  multiple?: boolean;
  disabled?: boolean;
  children: React.ReactChild;
  accepts?: string
};

const ImageUploadButton = ({
  multiple = false,
  disabled = false,
  handleFiles,
  addCBParams,
  children,
  accepts = "image/*"
}: ImageUploadButtonProps) => (
  <div>
    <label>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => {
          const { files } = event.currentTarget;
          if (files) {
            handleFiles(Array.from(files), addCBParams);
          }
        }}
        accept={accepts}
        multiple={multiple}
        disabled={disabled}
      />
      {children}
    </label>
  </div>
);

export default ImageUploadButton;