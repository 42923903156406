import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Calendar, Views, dateFnsLocalizer, SlotInfo } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, addMinutes } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { filter, pathEq, propOr, values } from 'ramda';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { UserContext } from 'bos_common/src/context/UserContext';

import { isEmptyOrNil, userIsAdmin } from '../../utils';
import { BigCalendarEvent } from './types';
import { CalendarContext } from './context/CalendarContext';
import AddServiceDrawer from './AddServiceDrawer';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { colorsForStaff } from './utils';
import CalendarToolbar from './CalendarToolbar';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .rbc-toolbar": {
				[theme.breakpoints.down('sm')]: {
					display: "grid",
					gridGap: theme.spacing(1),
					marginBottom: theme.spacing(1),
				}
			},

			"& .rbc-current-time-indicator": {
				height: '2px',
				backgroundColor: theme.palette.error.main,
			},

			"& .rbc-time-content": {
				"& .rbc-day-slot": {
					"&:nth-child(2)": {
						"& .rbc-current-time-indicator": {
							"&::before": {
								content: '""',
								display: "inline-block",
								width: "15px",
								height: "15px",
								borderRadius: theme.spacing(1),
								backgroundColor: theme.palette.error.main,
								position: 'relative',
								top: "-8.5px"
							}
						}
					}
				}
			},
		},
	})
);

const DragAndDropCalendar = withDragAndDrop<any, any>(Calendar);

// setting up localizer for the Calendar
const locales = {
	'en-US': enUS
}
const localizer = dateFnsLocalizer({
	format, parse, startOfWeek, getDay, locales
})

const MerchantCalendar = () => {
	const classes = useStyles();
	const { user } = useContext(UserContext)

	const {
		fetchCalendarEvents,
		eventsData,
		setEventsData,
		selectedStaffList,
		loading,
		setSelectedEvent,
		calendarView
	} = useContext(CalendarContext);

	const [eventDrawer, toggleEventDrawer] = useState<{ isOpen: boolean, eventData: Partial<BigCalendarEvent> }>({ isOpen: false, eventData: {} });
	const [staffColors, setStaffColors] = useState<Map<number, string>>();
	useEffect(() => {
		fetchCalendarEvents();
	}, []);

	useEffect(() => {
		let colorCount = 0;
		let map = new Map();
		selectedStaffList.forEach((staff) => {
			map.set(staff.id, colorsForStaff[colorCount])
			colorCount = colorCount + 1 === colorsForStaff.length ? 0 : colorCount + 1;
		});
		setStaffColors(map);
	}, [selectedStaffList]);

	const getStaffColor = useCallback((staffId: number) => {
		return !isEmptyOrNil(staffColors) && staffId ? staffColors!.get(staffId) : '#fff'
	}, [staffColors]);

	const handleOnSelectEvent = (event: BigCalendarEvent) => {
		setSelectedEvent(event);
		toggleEventDrawer({ isOpen: true, eventData: event });
	}

	/* Calendar event handlers */
	const eventStyleGetter = useCallback((event, start, end, isSelected) => {
		const isPackageEvent = !isEmptyOrNil(event.merchantPackage)
		const showWhiteBackground = calendarView === Views.DAY || calendarView === Views.WEEK

		const style = {
			background: isPackageEvent ?
				'linear-gradient(0deg, rgba(3, 110, 184, 0.15), rgba(3, 110, 184, 0.15)), rgba(255, 255, 255, 0.75)' :
				showWhiteBackground ? "#fff" : getStaffColor(event.staff.id),
			color: isPackageEvent ?
				'#036EB8' :
				'#000'
		};
		return {
			style
		};
	}, [eventsData, selectedStaffList, staffColors, calendarView]);

	const onEventDrop = useCallback(
		({
			event,
			start,
			end,
			resourceId,
			isAllDay: droppedOnAllDaySlot = false,
		}) => {
			const { allDay } = event
			if (!allDay && droppedOnAllDaySlot) {
				event.allDay = true
			}

			const existing = propOr({}, event.id, eventsData)

			const updatedEventsData = {
				...eventsData,
				[event.id]: { ...existing, start, end, resourceId, allDay }
			}
			setEventsData(updatedEventsData);
		},
		[eventsData]
	)

	const onSelectSlot = (eventData: SlotInfo) => {
		setSelectedEvent();
		toggleEventDrawer({ isOpen: true, eventData: eventData as Partial<BigCalendarEvent> })
	}

	const closeAddEventDrawer = () => {
		setSelectedEvent();
		toggleEventDrawer({ isOpen: false, eventData: {} })
	}
	/* ----------------------- */

	const now = new Date()

	const viewableEventsList = useMemo(() => {
		if (isEmptyOrNil(eventsData) || !user) return []
		const eventsList = values(eventsData).map((data: any) => {
			const { date } = data
			const end = addMinutes(date, data.duration)

			return ({
				...data,
				date,
				end: new Date(end),
				calendarSchedule: {
					...data.calendarSchedule,
					endDate: data.end
				}
			})
		})

		if (userIsAdmin(user)) return filter((e: any) => selectedStaffList.find((s) => e.staff.userId === s.userId), eventsList)
		const thisUserEventsList = filter(pathEq(['staff', 'userId'], user.id), eventsList)

		return thisUserEventsList
	}, [eventsData, user, selectedStaffList])

	const viewableSelectedStaffList = useMemo(() => {
		if (!user) return []
		if (userIsAdmin(user)) return selectedStaffList

		return undefined;
	}, [user, selectedStaffList])

	const slotPropGetter = useCallback(
		(_, staffId) => {
			return {
				style: {
					background: getStaffColor(staffId)
				}
			}
		},
		[staffColors]
	)

	return (
		<div className={classes.root}>
			<SimpleLoader loading={loading} />
			<div>
				<DragAndDropCalendar
					components={{
						toolbar: CalendarToolbar
					}}
					draggableAccessor={event => false}
					onEventDrop={onEventDrop}
					onSelectSlot={onSelectSlot}
					onSelectEvent={(event) => handleOnSelectEvent(event)}
					eventPropGetter={(eventStyleGetter)}
					defaultView={Views.DAY}
					slotPropGetter={slotPropGetter}
					localizer={localizer}
					events={viewableEventsList}
					resourceIdAccessor="id"
					resources={viewableSelectedStaffList}
					resourceTitleAccessor="displayName"
					step={30}
					style={{ height: 600 }}
					selectable
					view={calendarView}
					defaultDate={now}
					scrollToTime={now}
				/>

				<AddServiceDrawer
					eventInfo={eventDrawer}
					closeDrawer={closeAddEventDrawer}
					onOpen={onSelectSlot}
				/>
			</div>
		</div>
	);
};

export default MerchantCalendar;