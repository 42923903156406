import React from 'react';
import { NavigateBefore } from '@material-ui/icons';
import { IconButton, Theme, useMediaQuery } from '@material-ui/core';

import PageHeader from '../bos_common/src/components/PageHeader'

import DocumentMetaTags from './common/DocumentMetaTags';
import MenuButtonSwitcher from './SideMenu/SideMenuSwitcher';

type MerchantPageHeaderProps = {
  title: string;
  onBack?: () => void;
  rightChild?: React.ReactNode;
  leftChild?: React.ReactNode;
  hideOnScroll?: boolean;
  elevation?: number;
}

const MerchantPageHeader = (props: MerchantPageHeaderProps) => {

  const {
    title,
    onBack,
    rightChild=(<MenuButtonSwitcher />),
    leftChild,
    hideOnScroll = true,
    elevation = 3,
  } = props

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  let leftSideElement = null
  if (leftChild) {
    leftSideElement = leftChild
  } else if (onBack) {
    leftSideElement = (
      <IconButton onClick={onBack}>
        <NavigateBefore fontSize="large" />
      </IconButton>
    )
  }

  return (
    <DocumentMetaTags>
      <PageHeader
        leftChild={leftSideElement}
        title={(!isMobile || (isMobile && !leftChild)) ? title : ''}
        rightChild={rightChild}
        hideOnScroll={hideOnScroll}
        elevation={elevation}
      />
    </DocumentMetaTags>
  );
};

export default MerchantPageHeader;
