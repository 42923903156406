import React from 'react';
import { OrderTabs } from '../../constants/orders';
import { Merchant, Order, OrderStatus } from '../../services/models';
import { MappedMerchants, MappedUsers } from '../../utils';
import { OrdersSearchStateType } from './MerchantOrdersContextProvider';

export type CancelOrderApiHandlerOptions = {
  restoreInventory?: boolean,
  staffToken?: string,
  customRefundAmount?: string,
  onSuccessCallback?: () => void,
}

export type OrdersListCategorized = {
  new: Order[],
  completed: Order[]
}

export enum ORDER_SEARCH_BAR_FILTERS {
  searchByName = 'search-by-name', //search order by name
  searchByAmount = 'search-by-amount', // search order by amount
  searchByPhone = 'search-by-phone', // search order by phone number
  searchByOrderNumber = 'search-by-order-number', // search by unique order number
}

export enum ORDER_FILTERS {
  all = 'all',
  orderAhead = "order-ahead", // future orders
  openCheck = "open-check", // open-check
  paid = "paid", // paid (online payment method, default method)
  confirmed = "confirmed", // confirmed by the One Market Operator
  unPaid = "unpaid", // unpaid (open check flow)
  refunded = "refunded", // partial or fully refunded
  dineIn = 'dine-in', // order.type === dinein
  pickUp = 'pick-up', // order.type === pickup
  voided = 'voided', // order.status === voided
  paidExternally = 'paid-externally', // order.status === paid-externally
}

export type SetOrderStatusType = {
  id: string,
  nextOrderStatus: OrderStatus,
  onSuccessCallback?: () => void, staffToken?: string,
  options?: { [key: string]: any }
}

export type MerchantBranchFilterType = {
  id: string,
  selected: boolean,
  date?: Date,
}

export interface MerchantOrdersContextState {
  setOrderStatus: (options: SetOrderStatusType) => Promise<boolean>,
  cancelOrder: (order: Order, options: CancelOrderApiHandlerOptions) => void,

  updateOrders: (_: Order[]) => void,

  setSelectedTab: (key: any) => void
  selectedTab: OrderTabs,

  merchantBranches?: Merchant[];
  selectedBranches?: MerchantBranchFilterType[];
  setSelectedBranches: (_: MerchantBranchFilterType[]) => void;

  ordersList: Order[],

  selectedFilter: { [key: string]: ORDER_FILTERS },
  setSelectedFilter: (value: ORDER_FILTERS) => void

  getFilteredOrdersList: (selectedTab: OrderTabs, branches?: MerchantBranchFilterType[]) => Order[],

  searchOrderItems: (searchText: string, searchType?: ORDER_SEARCH_BAR_FILTERS) => void
  searchBarState: OrdersSearchStateType,

  users: MappedUsers,
  merchants: MappedMerchants,

  isUserOneMOperator: boolean,
  loadingOrders: boolean
}

export const MerchantOrdersContext = React.createContext<MerchantOrdersContextState>({
  setOrderStatus: (options: SetOrderStatusType) => new Promise((r) => r(false)),
  cancelOrder: (_: Order, __: CancelOrderApiHandlerOptions) => { },

  updateOrders: (_: Order[]) => { },

  setSelectedTab: (_: any) => { },
  selectedTab: OrderTabs.new,

  setSelectedBranches: (_: MerchantBranchFilterType[]) => { },

  ordersList: [],

  setSelectedFilter: (_: any) => { },
  selectedFilter: {},
  getFilteredOrdersList: (_: OrderTabs, __?: MerchantBranchFilterType[]) => [],

  searchBarState: { value: undefined, searchType: ORDER_SEARCH_BAR_FILTERS.searchByName },
  searchOrderItems: (_: string, __?: ORDER_SEARCH_BAR_FILTERS) => { },

  users: {},
  merchants: {},

  isUserOneMOperator: false,
  loadingOrders: false,
});
