import useAxios from 'axios-hooks';
import SimpleLoader from 'bos_common/src/components/SimpleLoader';
import { propOr, uniq, without } from 'ramda';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../bos_common/src/context/UserContext';
import axios from '../../../../bos_common/src/services/backendAxios';
import { AppContext } from '../../../../context/AppContext';
import { localPrint } from '../../../../services/localPrinterService';
import { LineItem, Merchant, Order } from '../../../../services/models';
import { NotificationSeverity } from '../../../../types/NotificationSlice';
import { getAuthHeaders } from '../../../../utils';
import { buildKitchenTicket } from '../../../../utils/printUtils';
import AppFab from '../../../common/AppFab';
import ActionHeader from './ActionHeader';
import LineItemActions from './LineItemActions';
import useActionTemplateStyles from './styles';
import { OrderActionTemplateProps } from './types';

type KitchenPrintOrderActionProps = OrderActionTemplateProps & {
  forLabel: boolean;
};

const KitchenPrintOrderAction = (props: KitchenPrintOrderActionProps) => {
  const { order, closeDrawer, forLabel } = props;
  const classes = useActionTemplateStyles();
  const { t } = useTranslation();

  const { token } = useContext(UserContext);
  const { merchant, localPrinter, triggerNotification } = useContext(AppContext);
  const [selectedItemsForPrint, setItemsForPrint] = useState<Array<LineItem>>([]);
  const [selectedIndicesForPrint, setIndicesForPrint] = useState<Array<number>>([]);

  const [{ loading }, postLocalPrint] = useAxios(localPrint, { manual: true });

  // functions to handle action
  const handleConfirm = async () => {
    const toPrintOrder: Order = { ...order, lineItems: selectedItemsForPrint };
    const OnFailureMessage = `Failed to print labels for ${order.userDisplayName}'s order.`;

    let notification = [OnFailureMessage, NotificationSeverity.WARNING];
    try {
      if (localPrinter) {
        const kitchenTicketPrint = buildKitchenTicket({
          order: toPrintOrder,
          merchant: merchant as Merchant,
          reprint: true,
        })!;
        const res = await postLocalPrint({ data: kitchenTicketPrint });
        if (res) {
          notification = [
            `Ticket for ${order.userDisplayName}' order is sent to your local printer.`,
            NotificationSeverity.SUCCESS,
          ];
        }
      } else {
        const res = await axios.post(
          `merchants/orders/${order.id}/print`,
          { order: toPrintOrder, reprint: true, index: selectedIndicesForPrint },
          { headers: getAuthHeaders(token) }
        );
        if (res.status !== 200) {
          throw new Error('Failed to print');
        }
        notification = [
          forLabel
            ? `Labels for ${order.userDisplayName}' order are printed.`
            : `Ticket for ${order.userDisplayName}' order is printed.`,
          NotificationSeverity.SUCCESS,
        ];
      }
      closeDrawer();
    } catch (err) {
      notification = [propOr(OnFailureMessage, 'message', err), NotificationSeverity.ERROR];
    } finally {
      triggerNotification(true, ...notification);
    }
  };

  const onCheckboxChangeAction = (params: {
    checkboxValue: boolean;
    order: Order;
    item: LineItem;
    index: number;
  }) => {
    const { checkboxValue, item, index } = params;

    const updatedItemsList = checkboxValue
      ? uniq([...selectedItemsForPrint, item])
      : without([item], selectedItemsForPrint);
    const udpatedItemIndicesList = checkboxValue
      ? uniq([...selectedIndicesForPrint, index])
      : without([index], selectedIndicesForPrint);

    setItemsForPrint(updatedItemsList);
    setIndicesForPrint(udpatedItemIndicesList);
  };

  const title = forLabel ? t('KitchenLabels') : t('KitchenTickets');

  const subtitle = forLabel
    ? t('Order_Kitchen_LabelsSubtitle')
    : t('Order_Kitchen_TicketsSubtitle');

  const ctaButton = forLabel ? t('Order_Kitchen_LabelsButton') : t('Order_Kitchen_TicketsButton');

  return (
    <div className={classes.actionTemplateContainer}>
      <SimpleLoader loading={loading} />
      <ActionHeader title={title} subtitle={subtitle} />

      <div className="content-container">
        <LineItemActions
          order={order}
          orderItemProps={{
            onCheckboxChangeAction,
          }}
          expandedLineItems={forLabel}
        />
      </div>
      <div className="actions-container">
        <AppFab
          variant="extended"
          color="primary"
          disabled={selectedItemsForPrint.length === 0}
          onClick={handleConfirm}
        >
          {ctaButton}
        </AppFab>
      </div>
    </div>
  );
};

export default KitchenPrintOrderAction;
