import { Merchant } from "../types/MerchantType"
import { LineItem, Order, OrderStatus } from "../types/OrderTypes"
import { toFixed2 } from "../utils"

export const getOrder1Mpoints = (order: Order, merchant: Merchant): number => {
  // check if merchant support 1m points

  // compute 1m points
  const points_per_dollar = 10
  const pointsFromItems = +order.lineItems.reduce((a: number, b: LineItem) => a + b.quantity * b.price * points_per_dollar, 0)
  const pointsFromTip = +(order.tip * points_per_dollar)
  return Math.round(pointsFromItems + pointsFromTip)
}

export const getOrderSubtotalPrice = (items: LineItem[], ignoreRefundedRedeemedItem?: boolean): number => {
  return toFixed2(
    items.reduce((a: number, b: LineItem) => {
      const quantityRedeemed = b.quantityRedeemed || 0;
      const addition = ignoreRefundedRedeemedItem === true
        ? (b.refunded === true ? 0 : (b.quantity - quantityRedeemed) * b.price)
        : b.quantity * b.price;
      return a + addition;
    }, 0)
  );
};

export const getOrderTotalItems = (items: LineItem[]): number => {
  return items.reduce((a: number, b: LineItem) => a + b.quantity, 0)
}

export type CalculatedOrderTotal = {
  amount: number;
  tip: number;
  tax: number;
}

export const calculateOrderTotal = (order: Order, merchant: Merchant): CalculatedOrderTotal => {
  const subtotal = getOrderSubtotalPrice(order.lineItems);
  const discountAmount = order.couponRedemptionInfo?.amountRedeemed ?? 0;
  const redeemedAmount = order.pointsRedemptionInfo?.amountRedeemed ?? 0;

  const subtotalToPay = toFixed2(
    subtotal - Number(discountAmount) - Number(redeemedAmount)
  );

  const taxRate = merchant.orderingConfig?.taxRate || 0.075;
  const taxToPay = toFixed2(subtotalToPay * Number(taxRate));

  let tipToPay = 0;
  const tipData = merchant.orderingConfig?.tipOptions ? merchant.orderingConfig?.tipOptions[0] : null;
  if (tipData) {
    tipToPay = tipData.customTip ?? toFixed2(Number(tipData.tipRate || 0) * subtotalToPay);
  }

  return {
    amount: subtotalToPay + taxToPay + Number(tipToPay),
    tip: tipToPay,
    tax: taxToPay,
  };
}

export const getRefundedTax = (order: Order): number => {
  if (order.cancellationInfo) {
    return +order.cancellationInfo.reduce((acc, info) => acc + Number(info.taxRefundAmount), 0);
  }
  return 0;
}

export const getRefundedTip = (order: Order): number => {
  if (order.cancellationInfo) {
    return +order.cancellationInfo.reduce((acc, info) => acc + Number(info.tipRefundAmount), 0);
  }
  return 0;
}

export const getOrderPriceBreakdown = (
  order: Order,
  ignoreRefundedRedeemedItem?: boolean,
  anonymizeCoupon?: boolean
): Array<[string, number]> => {
  const breakdowns = new Array<[string, number]>();
  breakdowns.push(['Subtotal', getOrderSubtotalPrice(order.lineItems, ignoreRefundedRedeemedItem)]);

  if (order.status === OrderStatus.PAID || order.status === OrderStatus.FULFILLED) {
    if (order.couponRedemptionInfo && order.couponRedemptionInfo?.amountRedeemed > 0) {
      breakdowns.push([
        anonymizeCoupon ? '"coupon"' : `"${order.couponRedemptionInfo.couponCode}"`,
        -order.couponRedemptionInfo.amountRedeemed,
      ]);
    }
    if (order.pointsRedemptionInfo && order.pointsRedemptionInfo?.amountRedeemed > 0) {
      // subtracting the coupon amount from the 1m points redeemedAmount
      const amountRedeemed = order.couponRedemptionInfo && order.couponRedemptionInfo?.amountRedeemed > 0
        ? order.pointsRedemptionInfo?.amountRedeemed - order.couponRedemptionInfo?.amountRedeemed
        : order.pointsRedemptionInfo?.amountRedeemed

      breakdowns.push(['1M Points Redeemed', -amountRedeemed]);
    }
    const tax = Number(order.beforeRefundInfo ? order.beforeRefundInfo.tax : order.tax);
    breakdowns.push(['Taxes', toFixed2(tax)]);
    const tip = Number(order.beforeRefundInfo ? order.beforeRefundInfo.tip : order.tip);
    if (tip > 0) {
      breakdowns.push(['Tip', toFixed2(tip)]);
    }
    if (order.refundedAmount > 0) {
      breakdowns.push(['Refund', -order.refundedAmount]);
    }
  } else {
    const tax = Number(order.beforeRefundInfo ? order.beforeRefundInfo.tax : order.tax);
    breakdowns.push(['Taxes', toFixed2(tax)]);
    const tip = Number(order.beforeRefundInfo ? order.beforeRefundInfo.tip : order.tip);
    if (tip > 0) {
      breakdowns.push(['Tip', toFixed2(tip)]);
    }
  }
  return breakdowns;
};

export const calculateRefundAmount = (order: Order, refundedItems: LineItem[]): number => {
  const subtotal = getOrderSubtotalPrice(order.lineItems, true);
  const refundSubtotal = getOrderSubtotalPrice(refundedItems, true);

  // include discount in refund estimation
  const couponRedemption = order.couponRedemptionInfo?.amountRedeemed || 0;
  const subtotalAll = getOrderSubtotalPrice(order.lineItems);
  const discountRatio = 1 - couponRedemption / subtotalAll;

  const refundTotal = refundSubtotal * discountRatio + (refundSubtotal / subtotal) * (Number(order.tax) + Number(order.tip));
  return Math.round(refundTotal * 100) / 100;
}