import { Order, OrderStatus, User } from "../../services/models";
import { userIsOneMOperator } from "../../utils";

const ElapsedTimeColors = {
  late: {
    main: '#fe8e64',
    dark: '#e57c54'
  },
  warning: {
    main: '#f8c774',
    dark: '#deb166'
  },
  onTime: {
    main: '#71d798',
    dark: '#5ac683'
  },
  fulfilled: {
    main: '#b8b8b8',
    dark: '#9a9a9a'
  },
  default: {
    main: '#028a9e',
    dark: '#027181'
  },
}

type ElapsedTimeColor = { main: string, dark: string }
/**
 * returns a color based on the elapsed time. Depicts how close the order is to the toPickupTime
 * @param toPickupTime Date
 * @returns ElapsedTimeColor
 */
export const getOrderElapsedTimeColor = (order: Order, user?: User): ElapsedTimeColor | null => {
  const { toPickupTime, status } = order;
  if (status === OrderStatus.CONFIRMED && userIsOneMOperator(user)) return ElapsedTimeColors.fulfilled

  if ([OrderStatus.FULFILLED, OrderStatus.PAID_EXTERNALLY, OrderStatus.VOIDED].includes(status)) {
    return ElapsedTimeColors.fulfilled
  }

  const timestamp = new Date(toPickupTime).getTime();
  const now = new Date().getTime();
  const minutesDiff = Math.round((now - timestamp) / 1000 / 60)

  if (minutesDiff > 60) {
    // hours
    return ElapsedTimeColors.late
  } else if (minutesDiff > 5) {
    // 5 minutes
    return minutesDiff > 15 ? ElapsedTimeColors.late : ElapsedTimeColors.warning
  } else if (minutesDiff >= -15) {
    return ElapsedTimeColors.onTime
  }

  return ElapsedTimeColors.default
}