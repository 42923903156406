import React, { useCallback, useContext } from 'react'
import { filter, join, pipe, pluck } from 'ramda';
import { ToolbarProps, View, Navigate as navigate, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import {
  Button, Checkbox, createStyles, IconButton, ListItemText,
  makeStyles, MenuItem, Select, Stack, Theme, Typography, useMediaQuery
} from '@material-ui/core'
import { Today, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { If } from 'react-if';

import { UserContext } from 'bos_common/src/context/UserContext';
import { MerchantStaff } from 'bos_common/src/types/MerchantStaff';

import { isEmptyOrNil, userIsAdmin } from '../../utils';
import { CalendarContext } from './context/CalendarContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    margin: theme.spacing(1),

    '& .todaySelectBtn': {
      paddingLeft: 0,
      paddingRight: 0,
      width: 36,
      height: 35,
      minWidth: 30
    },

    '& .dateRange, .MuiSelect-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },

    '& .iconOnlyButton': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.light,
      width: 36,
      height: 36,
      '& .MuiIconButton-label': {
        width: 36,
        height: 36,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  }
}))

const CALENDAR_VIEWS = [
  {
    value: Views.DAY,
    label: 'Calendar_DayViewLabel',
  },
  {
    value: Views.WEEK,
    label: 'Calendar_WeekViewLabel',
  },
  {
    value: Views.MONTH,
    label: 'Calendar_MonthViewLabel',
  },
];

const CalendarToolbar = (props: ToolbarProps) => {
  const { onNavigate, label: dateLabel } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { t } = useTranslation();
  const { calendarView, setCalendarView, staffList, selectedStaffList, setSelectedStaffList } = useContext(CalendarContext)
  const { user } = useContext(UserContext)

  const handleViewChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCalendarView(event.target.value as View);
  };

  const handlePreviousClick = () => {
    if (onNavigate)
      onNavigate(navigate.PREVIOUS)
  }
  const handleNextClick = () => {
    if (onNavigate)
      onNavigate(navigate.NEXT)
  }

  const isStaffSelected = useCallback((id: number) => {
    return selectedStaffList.findIndex(s => s.id === id) > -1
  }, [selectedStaffList])

  const getRenderValue = (selected: number[]) => {
    if (selected.length === staffList.length) {
      return 'All Staff';
    }

    return pipe(
      filter((m: MerchantStaff) => selected.includes(m.id)),
      pluck('displayName'),
      join(', ')
    )(selectedStaffList)
  }

  const handleStaffSelectChange = (ev: any) => {
    const { target: { value } } = ev;

    if (value.includes(-1)) {
      setSelectedStaffList(staffList)
      return;
    }
    if (value.length === 0) return;
    setSelectedStaffList(staffList.filter(s => value.includes(s.id)));
  }

  const handleTodayBtnClick = () => {
    if (onNavigate)
      onNavigate(navigate.TODAY)
  }

  return (
    <div className={classes.root}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          color="primary"
          className="iconOnlyButton"
          onClick={handlePreviousClick}
        >
          <ChevronLeft />
        </IconButton>
        <Typography variant="body1" className="dateRange">
          {dateLabel}
        </Typography>
        <IconButton
          color="primary"
          className="iconOnlyButton"
          onClick={handleNextClick}
        >
          <ChevronRight />
        </IconButton>
        <Button aria-describedby="todaySelectBtn" variant="contained" className='todaySelectBtn' onClick={handleTodayBtnClick}>
          <Today fontSize='small' />
        </Button>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1} >
        <If condition={!isEmptyOrNil(staffList) && userIsAdmin(user)}>
          <Select
            id="staff-calendar-select"
            variant='outlined'
            value={pluck('id', selectedStaffList)}
            multiple
            size="small"
            renderValue={(selected) => getRenderValue(selected)}
            onChange={handleStaffSelectChange}
            sx={{
              maxWidth: 250
            }}
          >
            <MenuItem value={-1}>
              <Checkbox color='primary' checked={selectedStaffList.length === staffList.length} />
              <ListItemText primary={t("Calendar_AllStaffLabel")} />
            </MenuItem>
            {staffList?.map((staff) => (
              <MenuItem value={staff.id} key={staff.id}>
                <Checkbox color='primary' checked={isStaffSelected(staff.id)} />
                <ListItemText primary={staff.displayName} />
              </MenuItem>
            ))}
          </Select>
        </If>
        <If condition={!isMobile}>
          <Select
            id="calendar-view-select"
            variant='outlined'
            value={calendarView}
            size="small"
            onChange={handleViewChange}
            sx={{
              width: 135
            }}
          >
            {CALENDAR_VIEWS.map(({ value, label }) => <MenuItem value={value} key={value}>{t(label)}</MenuItem>)}
          </Select>
        </If>
      </Stack>
    </div>
  )
}

export default CalendarToolbar