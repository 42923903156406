import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '8px',
      border: '1px solid #bcc0c4',
      padding: '8px 0px',
      position: 'relative',
      marginTop: theme.spacing(2),

      '& .switch-label': {
        fontSize: '0.8rem',
        lineHeight: '1.4375em',
        fontFamily: '"Manrope","Helvetica","Arial",sans-serif',
        color: 'rgba(0, 0, 0, 0.6)',
        backgroundColor: '#f5faff',
        padding: '0 5px',

        position: 'absolute',
        top: '-8px',
        left: '10px',
      },
    },
  })
);

type FieldWrapperProps = {
  fieldLabel: string;
  children: React.ReactNode;
};

const FieldWrapper = ({ fieldLabel, children }: FieldWrapperProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className="switch-label">{fieldLabel}</span>

      {children}
    </div>
  );
};

export default FieldWrapper;
