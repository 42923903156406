import React, { useEffect } from 'react';
import { Divider, createStyles, makeStyles, Theme, Fab } from '@material-ui/core';
import { Else, If, Then } from 'react-if';
import { ChevronRight, DoNotDisturb } from '@material-ui/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';


import AppDrawer from 'bos_common/src/components/AppDrawer';
import OrderItems from 'bos_common/src/components/Order/OrderItems';

import MerchantOrdersContextProvider from '../../context/MerchantOrders/MerchantOrdersContextProvider';
import RefundOrderAction from './OrderActionsDrawer/ActionTemplates/RefundOrderAction';
import OrderInfo from './OrderInfo';
import { Order, OrderStatus } from '../../services/models';
import OrderAdminOperationInfo from './OrderRefundedByUserInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',

      '& .MuiDivider-root': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '& .order-action-fab': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '60px',
        width: '100%',
        margin: `${theme.spacing(4)} 0`,
        borderRadius: '5px',

        '& .label-container': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',

          "& .MuiSvgIcon-root": {
            marginRight: theme.spacing(1)
          },
        }
      },
      '& .actions-container': {
        background: 'transparent',
      },
    },
    drawerContainer: {
      width: '350px',
      maxWidth: 'unset'
    },
  })
)

type OnderInfoDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order | undefined;
  refreshHistory: (_: Order[]) => void
};

const OrderInfoDrawer = (props: OnderInfoDrawerProps): React.ReactElement | null => {
  const { order, refreshHistory, open, setOpen } = props;
  const [isRefundMode, setRefundMode] = React.useState(false)
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      setRefundMode(false)
    }
  }, [open])

  if (!order) return null;

  const isOpenCheckOrder = order.status === OrderStatus.OPEN_CHECK
  const isOrderVoidedOrMarkAsPaid = order.status === OrderStatus.VOIDED || order.status === OrderStatus.PAID_EXTERNALLY

  const updateOrders = (orders: Order[]) => {
    setOpen(false);
    refreshHistory(orders);
  }

  return (
    <AppDrawer
      anchor="right"
      {...props}
      title={isRefundMode ? '' : order.userDisplayName}
      drawerContainerClass={classes.drawerContainer}
    >
      <If condition={isRefundMode}>
        <Then>
          <MerchantOrdersContextProvider updateOrders={updateOrders}>
            <div className={classes.root}>
              <RefundOrderAction order={order} closeDrawer={() => setRefundMode(false)} />
            </div>
          </MerchantOrdersContextProvider>
        </Then>
        <Else>
          <div className={classNames(['content', classes.root])}>
            <OrderInfo order={order} />
            <Divider />
            <OrderItems order={order} />
            <OrderAdminOperationInfo order={order} />

            <Fab
              variant='extended'
              color="primary"
              className='order-action-fab'
              component='button'
              onClick={() => setRefundMode(true)}
            >
              <div className='label-container'>
                <DoNotDisturb /><span>{isOpenCheckOrder || isOrderVoidedOrMarkAsPaid ? t('Cancel') : t('Order_Refund')}</span>
              </div>

              <ChevronRight />
            </Fab>

          </div>
        </Else>
      </If>

    </AppDrawer >
  );
};

export default OrderInfoDrawer;