import React, { useContext } from 'react';
import { CardContent, makeStyles, Theme, Typography } from "@material-ui/core";
import { Else, If, Then } from 'react-if';
import { useTranslation } from 'react-i18next';

import { ColoredPaper } from '../../../bos_common/src';

import { Order, OrderStatus } from '../../../services/models';
import RenderMerchantOrders from './RenderMerchantOrders';
import { MerchantOrdersContext } from '../../../context/MerchantOrders/MerchantOrdersContext';
import { OrderTabs } from '../../../constants/orders';
import { StyledTabPanel } from '../../../components/StyledTab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },

  scrollableContainer: {
    height: `calc(100% - ${theme.spacing(2)})`,
    width: `calc(100% - ${theme.spacing(2)})`,
    overflow: 'hidden',
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'baseline'
  },
}));

type OrdersPanelProps = {
  orders: Order[],
  status?: OrderStatus
}

const OrdersPanel = (props: OrdersPanelProps) => {
  const { orders } = props
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ColoredPaper className={classes.scrollableContainer} >
      <If condition={orders.length === 0}>
        <Then>
          <CardContent>
            <Typography variant="subtitle1" component="div">{t("Order_NoOrderQueue")}</Typography>
          </CardContent>
        </Then>
        <Else>
          <RenderMerchantOrders />
        </Else>
      </If>
    </ColoredPaper>
  )
}

const MerchantOrderTabs = () => {
  const classes = useStyles();
  const { selectedTab, ordersList } = useContext(MerchantOrdersContext);

  return (
    <div className={classes.root}>
      <StyledTabPanel value={selectedTab} tabKey={OrderTabs.new}>
        <OrdersPanel
          status={OrderStatus.PAID}
          orders={ordersList || []}
        />
      </StyledTabPanel>
      <StyledTabPanel value={selectedTab} tabKey={OrderTabs.completed}>
        <OrdersPanel
          status={OrderStatus.FULFILLED}
          orders={ordersList || []}
        />
      </StyledTabPanel>
    </div>
  )
}

export default MerchantOrderTabs