import React, { useState } from "react"
import { Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { isEmptyOrNil } from "bos_common/src";
import { MultiFabContainer } from "bos_common/src/components/FabContainers";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { useAppSelector } from "../../../redux/hooks";
import { getMerchant } from "../../../redux/slice/merchant/merchantSelector";

import RenderMemberships from "./RenderMemberships";
import { ServiceFormType } from "../../../constants";
import MerchantPreviewButton from "../../MerchantPreviewButton";
import PackageMembershipFormDialog from "../../PackageMembershipFormDialog";

const getMembershipTitle = (isDataAvailable: boolean, isTrial=false) => {
  if(!isDataAvailable && !isTrial) {
    return "Services_AddMembership";
  }

  if(!isDataAvailable && isTrial) {
    return "Services_AddTrial";
  }

  if(isDataAvailable && !isTrial) {
    return "Services_EditMembership";
  }

  return "Services_EditTrial";
}

type ServicePackagesListProps = {
  memberships: MerchantMembership[];
  isTrialMembership?: boolean;
}

const ServiceMembershipsList = (props: ServicePackagesListProps) => {
  const { memberships, isTrialMembership } = props;

  const [dlgOpen, setDlgOpen] = useState<boolean>(false);
  const [editMembership, setEditMembership] = useState<MerchantMembership | MerchantMembership>();

  const merchant = useAppSelector(getMerchant);

  const { t } = useTranslation();

  const handleAddPackage = () => {
    setEditMembership(undefined);
    setDlgOpen(true);
  };

  const onEditPackage = (merchandise: MerchantMembership | MerchantMembership) => {
    setEditMembership(merchandise);
    setDlgOpen(true);
  };

  return (
    <div>
      <RenderMemberships
        memberships={memberships || {} as MerchantMembership[]}
        merchantId={merchant?.id}
        onEditMerchandise={onEditPackage}
        isTrial={isTrialMembership}
      />

      <MultiFabContainer buttonCount={2}>
        <MerchantPreviewButton merchant={merchant!} />

        <Fab variant="extended" color="primary" component="span" onClick={handleAddPackage}>
          <Add />
          {t(isTrialMembership ? 'Services_AddTrial' : 'Services_AddMembership')}
        </Fab>
      </MultiFabContainer>

      {merchant && (
        <PackageMembershipFormDialog
          title={t(getMembershipTitle(!isEmptyOrNil(editMembership), isTrialMembership))}
          merchantPackage={editMembership}
          open={dlgOpen}
          onClose={() => setDlgOpen(false)}
          type={ServiceFormType.membership}
          isTrialMembership={isTrialMembership}
        />
      )}
    </div>
  )
}

export default ServiceMembershipsList;
