import { useContext, useEffect } from 'react';
import { reverse, filter, pipe, prop, sortBy } from 'ramda';

// src
import { UserContext } from '../bos_common/src/context/UserContext';
import { LOCALSTORAGE_AUTH_KEYS } from '../bos_common/src/services/auth0';
import { MerchantAuth } from '../bos_common/src/types/MerchantAuthType';
import { IdentityRole } from '../bos_common/src/types/UserTypes';
import { AppContext } from '../context/AppContext';
import { Merchant } from './models';
import axios from '../bos_common/src/services/backendAxios';
import { isEmptyOrNil } from '../utils';

export interface MerchantDeviceAuthStorageType {
  merchantId: string;
  merchantUsername: string;
  merchantOfficialName: string;
  deviceAuthToken: string;
  lastLogin: Date | undefined
}

const isMerchantAuthed = (merchant: Merchant, authTokens?: MerchantDeviceAuthStorageType[]) => {
  for (const authToken of authTokens || []) {
    if (authToken.merchantId === merchant.id) {
      return !!authToken.deviceAuthToken;
    }
  }
  return false;
};

const storeDeviceAuthMeta = (merchant: Merchant, deviceAuthToken: string): void => {
  const tokens = getDeviceAuthMeta() || [];
  if (isMerchantAuthed(merchant, tokens)) {
    // TBD
  } else {
    tokens.push({
      merchantId: merchant.id,
      merchantUsername: merchant.username,
      merchantOfficialName: merchant.officialName,
      deviceAuthToken,
      lastLogin: new Date()
    });
    localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.MERCHANT_DEVICE_AUTH_TOKEN, JSON.stringify(tokens));
  }
};

export const getDeviceAuthMeta = (): MerchantDeviceAuthStorageType[] | undefined => {
  const deviceAuthTokenData =
    localStorage.getItem(LOCALSTORAGE_AUTH_KEYS.MERCHANT_DEVICE_AUTH_TOKEN) || '';
  if (deviceAuthTokenData) {
    const deviceAuthTokens = JSON.parse(deviceAuthTokenData) as
      | MerchantDeviceAuthStorageType[]
      | undefined;

    const authTokensWithLastLogin = pipe(
      filter((i: MerchantDeviceAuthStorageType) => !isEmptyOrNil(i.lastLogin)),
      sortBy(prop('lastLogin')),
      reverse
    )(deviceAuthTokens)

    const authTokensWithoutLastLogin = filter((i: MerchantDeviceAuthStorageType) => isEmptyOrNil(i.lastLogin), deviceAuthTokens)

    // return sorted auth tokens based on LastLogin
    return [...authTokensWithLastLogin, ...authTokensWithoutLastLogin]
  }
  return undefined;
};

const updateMerchantLastLogin = (merchant: Merchant) => {
  const authTokens = getDeviceAuthMeta();
  let changed = false;
  for (const authToken of authTokens || []) {
    if (authToken.merchantId === merchant.id) {
      authToken.merchantOfficialName = merchant.officialName;
      authToken.merchantUsername = merchant.username;
      authToken.lastLogin = new Date()
      changed = true;
    }
  }

  if (changed) {
    localStorage.setItem(
      LOCALSTORAGE_AUTH_KEYS.MERCHANT_DEVICE_AUTH_TOKEN,
      JSON.stringify(authTokens)
    );
  }
};

export const getDeviceAuthToken = (merchantUsername: string): string | undefined => {
  const authTokens = getDeviceAuthMeta();
  for (const authToken of authTokens || []) {
    if (authToken.merchantUsername === merchantUsername) {
      return authToken.deviceAuthToken;
    }
  }
  return undefined;
};

const useAuthDevice = () => {
  const { user, token } = useContext(UserContext);
  const { merchant } = useContext(AppContext);

  useEffect(() => {
    // only perform this operation for merchant admins
    if (user && merchant && token) {
      const tokens = getDeviceAuthMeta();
      // If this merchant has not been authed on this device
      if (!isMerchantAuthed(merchant, tokens)) {
        axios
          .get<MerchantAuth>('/merchants/allowedDeviceToken', {
            params: { merchantId: merchant.id },
            headers: { Authorization: `JWT ${token}` },
          })
          .then((response) => {
            if (response.status === 200) {
              const merchantAuth = response.data as MerchantAuth;
              storeDeviceAuthMeta(merchant, merchantAuth.allowedDeviceAuthToken);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        updateMerchantLastLogin(merchant);
      }
    }
  }, [user?.id, merchant?.id, token]);
};

export default useAuthDevice;
