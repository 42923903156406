import React, { useState } from "react"
import { rgba } from "polished";
import { Else, If, Then } from "react-if";
import { Clear, Menu as MenuIcon } from "@material-ui/icons";
import { Button, createStyles, IconButton, makeStyles, Menu, MenuItem, Popover, Stack, Theme } from "@material-ui/core";

const useStyle = makeStyles((theme: Theme) => createStyles({


  root: {
    "& .iconButton": {
      zIndex: 6,

    },

    "& .buttonsList": {
      zIndex: 6
    },

    '@global': {
      '@keyframes openingAnimation': {
        from: { opacity: 0 },
        to: { opacity: 1 }
      },
    },

    "& .actionButton": {
      background: theme.palette.background.paper,
      zIndex: 6,
      animation: "openingAnimation 1s infinite",
    }
  },
}))

type ActionItemsType = {
  icon: React.ReactNode,
  text: string,
  handleClick: () => void,
}

type FloatingActionsMenuProps = {
  actionItems: ActionItemsType[];
}

const FloatingActionsMenu = (props: FloatingActionsMenuProps) => {
  const { actionItems } = props;

  const [cardDropdownAnchor, setCardDropdownAnchor] = React.useState<null | HTMLElement>(null);

  const isCardDropdownOpened = Boolean(cardDropdownAnchor);

  const classes = useStyle();

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCardDropdownAnchor(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setCardDropdownAnchor(null);
  };

  return (
    <>
      <IconButton
        id="dropdownButton"
        aria-controls={isCardDropdownOpened ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isCardDropdownOpened ? 'true' : undefined}
        onClick={handleDropdownClick}
        className="iconButton"
      >
        {isCardDropdownOpened ? <Clear /> : <MenuIcon /> }
      </IconButton>
      <Popover
        anchorEl={cardDropdownAnchor}
        id="dropdown-menu"
        open={isCardDropdownOpened}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack gap={1} p={1}>
          {actionItems.map(({ icon, text, handleClick }, index) =>
            <Button
              key={`button-${index}`}
              className="actionButton"
              onClick={() => {
                handleClick();
                handleDropdownClose();
              }}
              startIcon={icon}
            >
              {text}
            </Button>
          )}
        </Stack>
      </Popover>
    </>
  )
}

export default FloatingActionsMenu;
