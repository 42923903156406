import React, { useContext, useMemo, useState } from "react";
import useAxios from "axios-hooks";
import { Else, If, Then } from "react-if";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { ascend, clone, map, pick, prop, sortWith } from "ramda";
import { Alert, createStyles, Grid, makeStyles, Stack, Theme } from "@material-ui/core";

import axios from "bos_common/src/services/backendAxios";
import { getAuthHeaders, isEmptyOrNil } from "bos_common/src";
import { UserContext } from "bos_common/src/context/UserContext";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { AppContext } from "../../../context/AppContext";
import MembershipPackageItemCard from "./MembershipPackageItemCard";
import { NotificationSeverity } from "../../../types/NotificationSlice";
import { useMerchantMenuContext } from "../../../context/MenuContext/MerchantMenuContext";
import { REMOVE_ONE_MEMBERSHIP, REMOVE_ONE_TRIAL, UPDATE_MEMBERSHIPS, UPDATE_TRIALS } from "../../../context/MenuContext/constants";
import FullScreenLoader from "../../common/FullScreenLoader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },

      '& .sortable-chosen': {
        color: '#fff',
        opacity: 0.7,
      },
    },
  })
);

type RenderMerchandiseProps = {
  setMerchandiseData?: any;
  memberships: MerchantMembership[];
  merchantId: string | undefined;
  onEditMerchandise: (_: MerchantMembership | MerchantMembership) => void;
  isTrial?: boolean;
};

const RenderMemberships = (props: RenderMerchandiseProps) => {
  const {
    memberships = [],
    merchantId,
    onEditMerchandise,
    isTrial
  } = props;

  const [merchandisesArr, setMerchandisesArr] = useState<MerchantMembership[]>([]);
  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext)

  const classes = useStyles();
  const { t } = useTranslation();

  const { state, dispatch } = useMerchantMenuContext();

  // request function
  const [{loading}, putMerchandises] = useAxios({
    url: `/merchants/${merchantId}/membership`,
    headers: getAuthHeaders(token),
    method: 'POST'
  }, { manual: true });

  // init sort
  useMemo(() => {
    const sortByProps = sortWith([ascend(prop('displayOrder')), ascend(prop('name'))]);
    setMerchandisesArr(sortByProps(memberships));
  }, [memberships]);

  // sortTable sort ent
  const onFinishSorting = (): void => {
    // clone memberships data
    const merchandisesCopy: MerchantMembership[] = clone(state.memberships);

    // reassign the sort field
    const merchandisesSort: MerchantMembership[] = merchandisesArr.map((item: MerchantMembership, index) => {
      // delete all fields of this sort
      merchandisesCopy.forEach((ele: MerchantMembership, idx: number) => {
        if (item.id === ele.id) {
          merchandisesCopy.splice(idx, 1);
        }
      });
      return { ...item, displayOrder: index + 1 };
    });

    const sortedData = map((item: MerchantMembership) => pick(['id', 'displayOrder'], item), merchandisesSort)

    // send modify request
    putMerchandises({
      method: 'PUT',
      data: sortedData,
    })
      .then(() => {
        if(isTrial) {
          dispatch({
            type: UPDATE_TRIALS,
            payload: merchandisesSort,
          })
        } else {
          dispatch({
            type: UPDATE_MEMBERSHIPS,
            payload: merchandisesSort,
          })
        }
      });
  };

  const handleDeleteItem = (merchandiseId: number) => {
    axios
      .delete(`merchants/${merchantId}/membership/${merchandiseId}`, { headers: getAuthHeaders(token) })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: isTrial ? REMOVE_ONE_TRIAL : REMOVE_ONE_MEMBERSHIP,
            payload: merchandiseId,
          })
          triggerNotification(true, t('SuccessMessage_ItemDeleted'), NotificationSeverity.SUCCESS, true);
        }
      })
      .catch(() => {
        triggerNotification(true, t('ErrorMessage_ItemDelete'), NotificationSeverity.ERROR, true);
      });
  }

  return (
    <If condition={!isEmptyOrNil(memberships)}>
      <Then>
        <FullScreenLoader loading={loading} />
        <ReactSortable
          className={classes.root}
          ghostClass="sortTableGhost"
          forceFallback
          list={merchandisesArr}
          handle=".dragHandle"
          animation={0}
          setList={setMerchandisesArr}
          onEnd={onFinishSorting}
        >
          {merchandisesArr.map((packageItem: MerchantMembership) => (
            <MembershipPackageItemCard
              merchandise={packageItem}
              //@ts-ignore
              onMerchandiseClick={onEditMerchandise}
              showExtraActions
              handleDeleteItem={handleDeleteItem}
              key={packageItem.id}
            />
          ))}
        </ReactSortable>
      </Then>

      <Else>
        <Stack height="100%" alignItems="center" justifyContent="center">
          <Alert severity="info">{t('Message_NoData')}</Alert>
        </Stack>
      </Else>
    </If>
  );
};

export default RenderMemberships;
