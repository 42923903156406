import React, { useContext, useState } from 'react';
import { createStyles, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import {
  MerchantOrdersContext,
  ORDER_SEARCH_BAR_FILTERS,
} from '../../context/MerchantOrders/MerchantOrdersContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      maxWidth: 400,
      width: "40%",
      margin: "auto",
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
        margin: theme.spacing(2),
      },
      display: "flex",

      '& .searchIcon': {
        position: "absolute",
        top: "50%",
        left: 10,
        transform: "translateY(-50%)"
      },

      '& .searchField': {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        borderRadius: 50,
        border: 0,
        background: grey[100],
        fontSize: 'inherit',
        outline: "none",
        width: "100%"
      }
    },

    searchType: {
      position: "absolute",
      top: "50%",
      right: 20,
      transform: "translateY(-50%)",
      color: 'rgba(0,0,0,0.6)',

      "&:hover": {
        "&:not(.Mui-disabled):before": {
          borderBottom: 0
        }
      },

      "&:before": {
        borderBottom: 0
      },

      "&:after": {
        borderBottom: 0
      },

      "& .MuiSelect-select:focus": {
        background: "transparent"
      }
    }
  })
);

const SearchOrder = () => {
  const { searchOrderItems, searchBarState } = useContext(MerchantOrdersContext);
  const [orderType, setOrderType] = useState(searchBarState.searchType);
  const { t } = useTranslation();

  const classes = useStyles();

  const handleSearchChange = (e: any) => {
    searchOrderItems(e.target.value, orderType);
  };

  return (
    <div className={classes.root}>
      <Search className="searchIcon" sx={{ color: '#626262' }} />
      <input className="searchField" type="text" value={searchBarState?.value ?? ""} onChange={handleSearchChange} />
      <Select value={orderType} onChange={(e) => setOrderType(e.target.value)} className={classes.searchType} id="grouped-select" label="Search Filter">
        <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByName}>{t("ByName")}</MenuItem>
        <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByAmount}>{t("ByAmount")}</MenuItem>
        <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByPhone}>{t("ByPhone")}#</MenuItem>
        <MenuItem value={ORDER_SEARCH_BAR_FILTERS.searchByOrderNumber}>{t("ByOrder")}#</MenuItem>
      </Select>
    </div>
  );
};

export default SearchOrder;
