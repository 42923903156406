import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, Theme, makeStyles, createStyles } from '@material-ui/core';
import { pathOr } from 'ramda';

import { useTranslation } from 'react-i18next';
import axios from '../bos_common/src/services/backendAxios'
import { UserContext } from '../bos_common/src/context/UserContext';
import { ApplicationPreferences, User } from '../services/models';
import { repeatAudio } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .sectionHeader': {
				marginTop: theme.spacing(1),
			},
			'& .inputRow': {
				display: 'flex',
				marginBottom: theme.spacing(2),
			},
			'& .sectionSpacer': {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
			},
		},
	})
);

const UserApplicationPreferences = () => {
	const classes = useStyles()
	const { user, token, setLoginState, setLoading } = useContext(UserContext)
	const { t } = useTranslation()

	const getApplicationPrefData = (localUser: User | undefined) => {
		return {
			notificationSound: pathOr(true, ['applicationPreferences', 'notificationSound'], localUser),
			orderDetailsCard: pathOr(true, ['applicationPreferences', 'orderDetailsCard'], localUser),
		}
	}

	const [preferences, setPreferences] = useState<ApplicationPreferences>(getApplicationPrefData(user))
	const [newOrderAudio, setAudio] = React.useState<HTMLAudioElement | null>(null);

	const toggleLabels = {
		notificationSound: 'Notification sounds',
		orderDetailsCard: 'Order detailed view'
	}

	useEffect(() => {
		setPreferences(getApplicationPrefData(user))
	}, [user])
  
	useEffect(() => {
	  const notifAudio = document.getElementById('app_notif_audio') as HTMLAudioElement;
	  setAudio(notifAudio);
	}, []);
  
	const handleToggle = (key: keyof ApplicationPreferences) => (event: any) => {
		event.preventDefault();
		const currentValue = preferences[key];
		const updatedPrefs = preferences;
		updatedPrefs[key] = !currentValue;

		setPreferences(updatedPrefs);
  
		if (newOrderAudio && !currentValue && key === 'notificationSound') {
		  repeatAudio(newOrderAudio, 3);
		}

		setLoading(true);
		axios.put<User>(
			'/users',
			{ id: user?.id, applicationPreferences: updatedPrefs },
			{ headers: { "Authorization": `JWT ${token}` } }
		).then((response) => {
			setLoading(false)
			if (response.status === 200) {
				setLoginState(response.data, token)
			}
		}).catch((err) => {
			setLoading(false)
		})
	}

	const RenderPreference = (prop: keyof ApplicationPreferences): React.ReactElement => {
		return (
			<ListItem button onClick={handleToggle(prop)} key={prop}>
				<ListItemText id={`${prop}_checkbox`} primary={toggleLabels[prop]} />
				<ListItemSecondaryAction>
					<Checkbox
						color="secondary"
						edge="end"
						onChange={handleToggle(prop)}
						checked={preferences[prop]}
						inputProps={{ 'aria-labelledby': `${prop}_checkbox` }}
					/>
				</ListItemSecondaryAction>
			</ListItem>
		)
	}

	return (
		<div className={classes.root}>
			<Typography gutterBottom variant="h6" component="div" className="sectionHeader">
				{t("Account_AppPreferences")}
			</Typography>
			<List>
				<Divider light variant="middle" />
				{RenderPreference('notificationSound')}
				{RenderPreference('orderDetailsCard')}
			</List>
		</div>
	)
}

export default UserApplicationPreferences