import { useContext, useEffect, useState } from 'react';
import assert from 'assert';
import { CancelToken } from 'axios';
// src
import { AppContext } from '../context/AppContext';
import { MerchandiseApiResponseType, Merchant } from './models';
import axios from '../bos_common/src/services/backendAxios';

export interface MerchantQueryParams {
  merchantUsername?: string;
  ownerId?: string;
  email?: string;
}

const useFetchMerchant = (query: MerchantQueryParams): boolean => {
  // make sure one is given
  const { merchantUsername, ownerId, email } = query;
  assert(merchantUsername || ownerId || email);

  const { merchant, setMerchant } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean | undefined>();

  // load if there is no merchant or the MerchantQueryParams don't match the current merchant
  const toLoad =
    !merchant ||
    !!(merchantUsername && merchant?.username !== merchantUsername) ||
    !!(ownerId && merchant?.ownerId !== ownerId) ||
    !!(email && merchant?.email !== email);

  useEffect(() => {
    const controller = new AbortController();
    if (toLoad && !loading) {
      setLoading(true);
      const loadData = async () => {
        try {
          // use owner id and email as signals if merchant username is not given
          const [merchantResponse, merchandisesDataResp] = await Promise.all([
            axios.get<Merchant>('/merchants', {
              params: {
                ...(merchantUsername && { username: merchantUsername }),
                ...(ownerId && !merchantUsername && { ownerId }),
                ...(email && !merchantUsername && { email })
              },
              signal: controller.signal
            }),
            merchantUsername
              ? axios.get<MerchandiseApiResponseType>('/merchants/merchandises', {
                params: { merchantUsername },
                signal: controller.signal,
              })
              : null,
          ]);

          const merchant = merchantResponse.data;
          let merchandisesDataRespSecondRound;
          if (!merchandisesDataResp && merchant?.id) {
            merchandisesDataRespSecondRound = await axios.get<MerchandiseApiResponseType>('/merchants/merchandises', {
              params: { merchantId: merchant?.id },
              signal: controller.signal,
            })
          }

          const merchandisesData = merchandisesDataResp?.data || merchandisesDataRespSecondRound?.data;
          if (merchant) {
            setMerchant({ ...merchant, merchantData: merchandisesData });
          }
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      };
      loadData();
    }

    return () => {
      controller.abort();
    };
  }, [merchantUsername, ownerId, email]);

  return loading === undefined ? toLoad : loading;
};

export default useFetchMerchant;
