import React from 'react'
import { SvgIcon } from "@material-ui/core";

function DeviceStatusIcon(props: any) {
  return (
    <SvgIcon color="inherit" sx={{ width: 24, height: 24 }} viewBox="0 0 24 24" {...props} >
      <path d="M20.5909 2.45459H3.40911C2.3543 2.45459 1.5 3.30894 1.5 4.3637V15.8183C1.5 16.873 2.3543 17.7274 3.40911 17.7274H10.0909V19.6365H8.18181V21.5455H15.8182V19.6364H13.9091V17.7273H20.5909C21.6457 17.7273 22.5 16.873 22.5 15.8182V4.3637C22.5 3.30894 21.6457 2.45459 20.5909 2.45459ZM20.5909 15.8183H3.40911V4.3637H20.5909V15.8183Z" fill="#1AAA5D"/>
    </SvgIcon>
  );
}

export default DeviceStatusIcon