import React, { useContext, useEffect, useState } from 'react'
import {
  createStyles, IconButton, makeStyles, Theme, Dialog, DialogTitle, Typography,
  Divider, DialogContent, DialogActions, Button, TextField
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { isEmptyOrNil } from 'bos_common/src';
import CustomerScheduleEntry from 'bos_common/src/types/crm/CustomerScheduleEntryType';
import { CalendarContext } from './context/CalendarContext';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),

      '& .MuiDialogTitle-root': {
        position: 'relative',
      },

      '& .close-button': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: theme.spacing(1),
      },

      '& .MuiPaper-root': {
        borderRadius: '20px',

        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1),
          width: "95%"
        }
      },

      '& .dialog-content': {
        padding: theme.spacing(2),
        textAlign: 'center'
      },

      '& .dialog-actions': {
        padding: theme.spacing(2),
        justifyContent: 'center',
        gridGap: theme.spacing(2),

        '& .action-btn': {
          borderRadius: theme.spacing(2),
          textTransform: 'capitalize',
          boxShadow: "0px 0px 6px rgb(0 0 0 / 15%)",
        }
      }
    }
  })
);

type OrderMarkAsPaidDialogProps = {
  open: boolean;
  setOpen: (_: boolean) => void,
  attendee?: CustomerScheduleEntry;
};

const AddAttendeeNoteDialog = (props: OrderMarkAsPaidDialogProps) => {
  const {
    open,
    setOpen,
    attendee
  } = props

  const classes = useStyles()
  const { t } = useTranslation();

  const [note, setNoteValue] = useState("")

  useEffect(() => {
    if (attendee) setNoteValue(attendee?.note ?? "")
  }, [attendee])

  const { updateCustomerAttendanceAndNote } = useContext(CalendarContext);
  const { setValue } = useFormContext();

  const closeDrawer = () => setOpen(false)

  // dispatch action here to update a customers note
  const handleSaveClick = async () => {
    if (attendee) {
      const updatedAttendeesList = await updateCustomerAttendanceAndNote(attendee, attendee.status, note);

      if (!isEmptyOrNil(updatedAttendeesList)) {
        setValue('attendees', updatedAttendeesList)
        closeDrawer()
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={closeDrawer}
      className={classes.root}
      maxWidth="sm"
      keepMounted={false}
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" component="span" className="dialog-title">
          {t('Attendees_AddNote')} <b>{attendee?.customer?.displayName}</b>
        </Typography>
        <IconButton className="close-button" onClick={closeDrawer}>
          <Close color="primary" />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent className='dialog-content'>
        <TextField
          fullWidth
          variant='outlined'
          value={note}
          color="primary"
          onChange={(e) => setNoteValue(e.target.value)}
          placeholder="Notes"
          multiline
          maxRows={3}
        />
      </DialogContent>

      <Divider />

      <DialogActions className='dialog-actions'>
        <Button variant="contained" color="primary" onClick={handleSaveClick}>{t("Save")}</Button>
      </DialogActions>

    </Dialog >
  );
};

export default AddAttendeeNoteDialog