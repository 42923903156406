import React, { useContext, useEffect, useMemo } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { If } from 'react-if';
import useAxios from 'axios-hooks';
import { createStyles, Theme, makeStyles, Grid, Typography, Box, Paper, Table, TableHead, styled, TableCell, TableRow, TableBody, TableContainer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useFetchMerchant from '../services/useFetchMerchant';
import { MerchantAggregatedSalesStats, MerchantStatsApiResponse } from '../services/models';
import { AppContext } from '../context/AppContext';
import oneMPointIcon from "../assets/icons/1m-points.svg"

import SimpleLoader from '../bos_common/src/components/SimpleLoader';
import { FullscreenPaper, getAuthHeaders } from '../bos_common/src';
import { UserContext } from '../bos_common/src/context/UserContext';
import renderPrice from '../bos_common/src/components/Price';

import MerchantPageHeader from '../components/MerchantPageHeader';
import { isEmptyOrNil } from '../utils';
import { formatMerchantSalesStats } from '../utils/merchantStatsUtils';
import { MerchantSalesStats, StatsType } from '../bos_common/src/types/MerchantSalesStatsType';
import MerchantAppLogo from '../components/common/MerchantAppLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,

      '& .tabContentWarpper': {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: theme.spacing(1)
      },

      '& table': {
        marginBottom: theme.spacing(5),
      },

      '& .info-container': {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        position: 'relative',

        '&::before': {
          position: 'absolute',
          content: '"-"',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: '0.1',
          borderRadius: 7,
          backgroundColor: theme.palette.text.secondary,
        },


        '&:first-child:before': {
          backgroundColor: theme.palette.primary.main
        }
      }
    }
  })
)

const StyledTableRow = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '& td, & th': {
    padding: theme.spacing(1.5),
    '&.subItem': {
      paddingLeft: theme.spacing(3),
    }
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const SalesBreakdownTable = (props: { aggregatedStats: MerchantAggregatedSalesStats }) => {
  const { aggregatedStats } = props;
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="stats table" size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography color="primary"><b>{t("BillingStatements_SalesBreakdown").toUpperCase()}</b></Typography></TableCell>
            <TableCell component="th" align="right"><Typography color="primary"><b>{t("BillingStatements_Total").toUpperCase()}</b></Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <TableCell component="th" scope="row"><strong>{t("BillingStatements_TotalSales")}</strong></TableCell>
            <TableCell align="right"><strong>{renderPrice(aggregatedStats.totalTotalGrossSales)}</strong></TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_Taxes")}</TableCell>
            <TableCell align="right">{renderPrice(aggregatedStats.orderTaxTotal)}</TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_Tips")}</TableCell>
            <TableCell align="right">{renderPrice(aggregatedStats.orderTipsTotal)}</TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row"><strong>{t("BillingStatements_GrossSales")}</strong></TableCell>
            <TableCell align="right"><strong>{renderPrice(aggregatedStats.totalGrossSales)}</strong></TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_Refunds")}</TableCell>
            <TableCell align="right">({renderPrice(aggregatedStats.orderRefundsTotal)})</TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_DiscountRedeemed")}</TableCell>
            <TableCell align="right">({renderPrice(aggregatedStats.totalDiscount)})</TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_RewardsRedeemed")}</TableCell>
            <TableCell align="right">({renderPrice(aggregatedStats.totalPointsRedeemed / 100)})</TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row"><strong>{t("BillingStatements_NetSales")}</strong></TableCell>
            <TableCell align="right"><strong>{renderPrice(aggregatedStats.totalNetSales)}</strong></TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_TransactionFees")} (2.9%+30{`\u{00A2}`})</TableCell>
            <TableCell align="right">({renderPrice(aggregatedStats.orderTransactionAmountTotal)})</TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row"><strong>{t("BillingStatements_NetTotal")}</strong></TableCell>
            <TableCell align="right"><strong>{renderPrice(aggregatedStats.totalNetTotal)}</strong></TableCell>
          </StyledTableRow>

        </TableBody>
      </Table>
    </TableContainer >
  )
}

const RewardsBalanceTable = (props: { aggregatedStats: MerchantAggregatedSalesStats }) => {
  const { aggregatedStats } = props;
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="stats table" size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography color="primary"><b>{t("BillingStatements_OneMRewards").toUpperCase()}</b></Typography></TableCell>
            <TableCell component="th" align="right"><Typography color="primary"><b>{t("BillingStatements_Total").toUpperCase()}</b></Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_RewardsGiven")}</TableCell>
            <TableCell align="right">
              <div className="tabContentWarpper">
                {aggregatedStats.totalPointsAwarded} <img src={oneMPointIcon} width="20" />
              </div>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" scope="row">{t("BillingStatements_RewardsRedeemed")}</TableCell>
            <TableCell align="right">
              <div className="tabContentWarpper">
                {aggregatedStats.totalPointsRedeemed} <img src={oneMPointIcon} width="20" />
              </div>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" className={"subItem"} scope="row"><s>{t("BillingStatements_PlatformFee")} x 20%</s></TableCell>
            <TableCell align="right">
              <div className="tabContentWarpper">
                <s>{aggregatedStats.totalPointsRedeemed * 0.2}</s> <img src={oneMPointIcon} width="20" />
              </div>
            </TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" className={"subItem"} scope="row"><s>{t("BillingStatements_PlatformFeeInDollarValue")} ($)</s></TableCell>
            <TableCell align="right"><s>{renderPrice(-aggregatedStats.totalPointsRedeemed / 100 * 0.2)}</s></TableCell>
          </StyledTableRow>

          <StyledTableRow>
            <TableCell component="th" className={"subItem"} scope="row">{t("BillingStatements_RunningBalance")}</TableCell>
            <TableCell align="right">
              <div className="tabContentWarpper">
                {aggregatedStats.totalPointsAwarded - aggregatedStats.totalPointsRedeemed} <img src={oneMPointIcon} width="20" />
              </div>
            </TableCell>
          </StyledTableRow>

        </TableBody>
      </Table>
    </TableContainer >
  )
}

const getFormattedDate = (date: string) => {
  const dateObj = new Date(parseInt(`${date}`))
  return `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`
}

interface IMerchantOfflinePresenceParams {
  merchantUsername: string;
}

const MonthlyBillingStatementPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { merchant } = useContext(AppContext)
  const { token } = useContext(UserContext);
  const { merchantUsername } = useParams<IMerchantOfflinePresenceParams>();
  const { t } = useTranslation();

  const onBack = () => {
    history.goBack();
  };

  const loading = useFetchMerchant({ merchantUsername })

  const startDate = new URLSearchParams(location.search).get("from")
  const endDate = new URLSearchParams(location.search).get("to")
  const [{ data: currentMonthSalesStats, loading: loadingStats }, refreshStats] = useAxios<MerchantStatsApiResponse>({
    url: '/merchants/stats',
    params: {
      merchantUsername,
      statsDateRange: { fromDate: parseInt(`${startDate}`), toDate: parseInt(`${endDate}`) },
      statsType: StatsType.DAILY,
    },
    headers: getAuthHeaders(token),
  },
    { manual: true }
  );

  const aggregatedStats: MerchantAggregatedSalesStats = useMemo(() => formatMerchantSalesStats(currentMonthSalesStats?.stats as Array<MerchantSalesStats>), [currentMonthSalesStats]);

  // refresh stats when date range selection changes
  useEffect(() => {
    if (!loading && !isEmptyOrNil(merchant)) refreshStats();
  }, [loading, merchant])

  if (!merchantUsername) {
    return <Redirect to='/create' />
  }

  if (!startDate || !endDate) {
    return <Redirect to={`/${merchantUsername}/billing-statements`} />
  }

  return (
    <FullscreenPaper>
      <MerchantPageHeader onBack={onBack} title={t("BillingStatements")} />
      <SimpleLoader loading={loading || loadingStats} />
      {!loading && !merchant && <Redirect to='/biz/create' />}
      {!loading && merchant &&
        <div className={classes.root}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sx={{ marginBottom: '5px' }}>
              <MerchantAppLogo />
            </Grid>

            <Grid item>
              <Box className="info-container">
                <Typography color="primary" variant="button"><b>{t("BillingStatements_Account")}</b></Typography>
                <If condition={!isEmptyOrNil(merchant.phoneNumber)}>
                  <Typography color="textSecondary" variant="body2">{merchant.phoneNumber}</Typography>
                </If>
                <If condition={!isEmptyOrNil(merchant.email)}>
                  <Typography color="textSecondary" variant="body2">{merchant.email}</Typography>
                </If>
              </Box>
              <Box className="info-container">
                <Typography color="primary" variant="button"><b>{t("BillingStatements_BillingPeriod")}</b></Typography>
                <Typography color="textSecondary" variant="body2">{getFormattedDate(startDate)} - {getFormattedDate(endDate)}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ marginBottom: 3, marginTop: 1 }} spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography color="primary" variant="button"><b>{t("BillingStatements_InvoiceFor")}</b></Typography>
              <Typography color="textSecondary" variant="body2">{merchant.officialName}</Typography>
              <Typography color="textSecondary" variant="body2">{merchant.address}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography color="primary" variant="button"><b>{t("BillingStatements_InvoiceFrom")}</b></Typography>
              <Typography color="textSecondary" variant="body2">One Market Inc.<br />3790 El Camino Real, #1109<br />Palo Alto, CA, 94306</Typography>
            </Grid>
          </Grid>

          {currentMonthSalesStats && (
            <SalesBreakdownTable aggregatedStats={aggregatedStats} />
          )}

          {currentMonthSalesStats && (
            <RewardsBalanceTable aggregatedStats={aggregatedStats} />
          )}
        </div>
      }
    </FullscreenPaper>
  );
};

export default MonthlyBillingStatementPage;
