import React from "react"
import { Button, createStyles, Divider, makeStyles, Stack, Theme, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { If } from "react-if";

import { isEmptyOrNil } from "bos_common/src";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .cardContent": {
        padding: theme.spacing(2),
      },

      "& .actionButtons": {
        display: "flex",
        gridGap: theme.spacing(2),
      },
      '& .normalButton': {
        textTransform: 'none',
      }
    },
  })
);

type SubscriptionsListWrapperProps = {
  children: React.ReactElement;
  handleAddItem?: () => void;
  handleShowPast?: () => void;
}

const SubscriptionsListWrapper = (props: SubscriptionsListWrapperProps) => {
  const { handleAddItem, handleShowPast, children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stack p={2} direction="row" justifyContent="flex-end" alignItems={"center"}>
        <div className="actionButtons">
          <If condition={!isEmptyOrNil(handleAddItem)}>
            <Button className="normalButton" onClick={handleAddItem} startIcon={<Add />} variant="contained" color="primary">Add</Button>
          </If>
          <If condition={!isEmptyOrNil(handleShowPast)}>
            <Button className="normalButton" onClick={handleShowPast} variant="contained" color="inherit">View Past</Button>
          </If>
        </div>
      </Stack>
      <div className="cardContent">
        {children}
      </div>
    </div>
  )
}

export default SubscriptionsListWrapper;