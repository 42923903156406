import { Fab, FabProps } from '@material-ui/core';
import { useState } from 'react';

type AppFabProps = Omit<FabProps, 'onClick'> & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any> | any;
};

const AppFab = ({ onClick, disabled, ...props }: AppFabProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Fab
      {...props}
      disabled={disabled || loading}
      onClick={async e => {
        setLoading(true);
        try {
          await onClick?.(e);
        // eslint-disable-next-line no-empty
        } catch {}
        setLoading(false);
      }}
    />
  );
};

export default AppFab;
