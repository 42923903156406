import useAxios from 'axios-hooks';
import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'bos_common/src/context/UserContext';
import { FeatureFlags } from 'bos_common/src/types/FeatureFlagTypes';
import { Merchant } from 'bos_common/src/types/MerchantType';
import { Order } from 'bos_common/src/types/OrderTypes';
import { useWindowStorage } from 'bos_common/src/hooks';

import { LOCAL_PRINTER } from '../constants/localStorageKeys';
import { useAppDispatch } from '../redux/hooks';
import { fetchAuth } from '../redux/slice/auth/authActions';
import { merchantFetchSucceed } from '../redux/slice/merchant/merchantActions';
import { addNotification } from '../redux/slice/notification/notificationActions';
import { Notification, NotificationSeverity } from '../types/NotificationSlice';
import { AppContext } from './AppContext';
import { SupportedLocales } from '../constants/locale';


const AppContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { user, token } = useContext(UserContext);
  const reduxDispatch = useAppDispatch();

  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();
  const [merchant, setMerchant] = useState<Merchant>();

  const [updatedOrder, setUpdatedOrder] = useState<Order>();

  const [localPrinter, setLocalPrinter] = useWindowStorage<string | null>(LOCAL_PRINTER, null);
  const [locale, setLocale] = useState<SupportedLocales>(() => {
    if (localStorage.getItem('bos_locale')) {
      return localStorage.getItem('bos_locale') as SupportedLocales;
    }
    return 'enUS';
  });

  const triggerNotification = (
    open: boolean,
    bodyText = '',
    severity: NotificationSeverity = NotificationSeverity.INFO,
    hideAction = false
  ) => {
    const notif = {
      ts: Date.now(),
      order: null,
      notificationBody: {
        open,
        bodyText,
        severity,
        hideAction,
      },
    } as Notification;
    reduxDispatch(addNotification(notif));
  };

  const [, fetchFeatureFlags] = useAxios<FeatureFlags>(
    { url: `/featureflags/merchant`, },
    { manual: true }
  );

  useEffect(() => {
    fetchFeatureFlags().then((res) => {
      if (res.status === 200) {
        setFeatureFlags(res.data);
      }
    });
  }, [])

  useEffect(() => {
    if (user && token) {
      // Sync auth between AppContextProvider and Redux.
      reduxDispatch(fetchAuth({ user, token }));
    }
  }, [user?.id, token]);

  useEffect(() => {
    if (merchant) {
      reduxDispatch(merchantFetchSucceed(merchant));
    }
  }, [merchant]);

  return (
    <AppContext.Provider
      value={{
        merchant,
        setMerchant,

        updatedOrder,
        setUpdatedOrder,

        triggerNotification,

        featureFlags,

        locale,
        setLocale,

        localPrinter,
        setLocalPrinter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
