import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { NotificationSliceState, Notification } from '../../../types/NotificationSlice';

type NotificationSliceCaseReducer = SliceCaseReducers<NotificationSliceState>;

export const notificationSlice = createSlice<NotificationSliceState, NotificationSliceCaseReducer, string>({
  name: 'notification',
  initialState: {
    entities: {
      notifications: [],
    },
    loading: false,
  },
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      const { payload } = action;
      const notifications = [...state.entities.notifications];
      notifications.push(payload);
      return {
        ...state,
        entities: {
          notifications,
        }
      }
    },

    removeNotification: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const notifications = [...state.entities.notifications];
      const indexOfItemInArray = state.entities.notifications.findIndex(
        (notif) => notif.ts === payload
      );
      if (indexOfItemInArray !== -1) {
        notifications.splice(indexOfItemInArray, 1);
      }
      return {
        ...state,
        entities: {
          notifications,
        }
      }
    },

    clearNotification: (state, _: PayloadAction<void>) => {
      const notifications: Notification[] = [];
      return {
        ...state,
        entities: {
          notifications,
        }
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { addNotification, removeNotification, clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
