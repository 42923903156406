import React from 'react';
import { Snackbar, Alert } from '@material-ui/core';
import { If, Then, Else } from 'react-if';

type AppToastMessageProps = {
  showAlert: boolean,
  error: boolean,
  onCloseAlert?: (showAlert: boolean) => void,
  successMessage?: string,
  errorMessage?: string
}
const AppToastMessage = (props: AppToastMessageProps) => {
  const {
    showAlert = false,
    error = false,
    successMessage='Business updated successfully',
    errorMessage='Business failed to update',
    onCloseAlert = () => { }
  } = props

  return (
    <Snackbar
      open={showAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={() => onCloseAlert(false)}>
      <div>
        <If condition={!error}>
          <Then>
            <Alert onClose={() => onCloseAlert(false)} severity="success">{successMessage}</Alert>
          </Then>
          <Else>
            <Alert onClose={() => onCloseAlert(false)} severity="error">{errorMessage}</Alert>
          </Else>
        </If>
      </div>
    </Snackbar>
  )
}

export default AppToastMessage