import React, { useContext, useMemo, useState } from "react";
import useAxios from "axios-hooks";
import { ascend, clone, map, pick, pluck, prop, sortWith } from "ramda";
import { Else, If, Then } from "react-if";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import { Alert, createStyles, Grid, makeStyles, Stack, Theme } from "@material-ui/core";

import axios from "bos_common/src/services/backendAxios";
import { getAuthHeaders, isEmptyOrNil } from "bos_common/src";
import { UserContext } from "bos_common/src/context/UserContext";
import MerchantPackage from "bos_common/src/types/crm/MerchantPackageType";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";

import { AppContext } from "../../../context/AppContext";
import MembershipPackageItemCard from "./MembershipPackageItemCard";
import { NotificationSeverity } from "../../../types/NotificationSlice";
import { useMerchantMenuContext } from "../../../context/MenuContext/MerchantMenuContext";
import { REMOVE_ONE_PACKAGE, UPDATE_PACKAGES } from "../../../context/MenuContext/constants";
import FullScreenLoader from "../../common/FullScreenLoader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },

      '& .sortable-chosen': {
        color: '#fff',
        opacity: 0.7,
      },

      '& .gridItem': {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
      },
    },
  })
);

type RenderMerchandiseProps = {
  setMerchandiseData?: any;
  packages: MerchantPackage[];
  merchantId: string | undefined;
  onEditMerchandise: (merchandise: MerchantPackage | MerchantMembership) => void;
};

const RenderPackages = (props: RenderMerchandiseProps) => {
  const {
    packages = [],
    merchantId,
    onEditMerchandise,
  } = props;

  const [merchandisesArr, setMerchandisesArr] = useState<MerchantPackage[]>([]);
  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext)

  const classes = useStyles();
  const { t } = useTranslation();

  const { state, dispatch } = useMerchantMenuContext();

  // request function
  const [{loading}, putMerchandises] = useAxios({
    url: `/merchants/${merchantId}/packages`,
    headers: getAuthHeaders(token),
    method: 'POST'
  }, { manual: true });

  // init sort
  useMemo(() => {
    const sortByProps = sortWith([ascend(prop('displayOrder')), ascend(prop('name'))]);
    setMerchandisesArr(sortByProps(packages));
  }, [packages]);

  // sortTable sort ent
  const onFinishSorting = (): void => {
    // clone packages data
    const merchandisesCopy: MerchantPackage[] = clone(state.packages);

    // reassign the sort field
    const merchandisesSort: MerchantPackage[] = merchandisesArr.map((item: MerchantPackage, index) => {
      // delete all fields of this sort
      merchandisesCopy.forEach((ele: MerchantPackage, idx: number) => {
        if (item.id === ele.id) {
          merchandisesCopy.splice(idx, 1);
        }
      });
      return { ...item, displayOrder: index + 1 };
    });

    const sortedData = map((item: MerchantPackage) => pick(['id', 'displayOrder'], item), merchandisesSort)

    // send modify request
    putMerchandises({
      method: 'PUT',
      data: sortedData,
    })
      .then(() => {
        dispatch({
          type: UPDATE_PACKAGES,
          payload: merchandisesSort,
        })
      });
  };

  const handleDeleteItem = (merchandiseId: number) => {
    axios
      .delete(`merchants/${merchantId}/packages/${merchandiseId}`, { headers: getAuthHeaders(token) })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: REMOVE_ONE_PACKAGE,
            payload: merchandiseId,
          })
          triggerNotification(true, t('SuccessMessage_ItemDeleted'), NotificationSeverity.SUCCESS, true);
        }
      })
      .catch(() => {
        triggerNotification(true, t('ErrorMessage_ItemDelete'), NotificationSeverity.ERROR, true);
      });
  }

  return (
    <If condition={!isEmptyOrNil(packages)}>
      <Then>
        <FullScreenLoader loading={loading} />
        <ReactSortable
          className={classes.root}
          ghostClass="sortTableGhost"
          forceFallback
          list={merchandisesArr}
          handle=".dragHandle"
          animation={0}
          setList={setMerchandisesArr}
          onEnd={onFinishSorting}
        >
          {merchandisesArr.map((packageItem: MerchantPackage) => (
            <MembershipPackageItemCard
              merchandise={packageItem}
              onMerchandiseClick={onEditMerchandise}
              showExtraActions
              handleDeleteItem={handleDeleteItem}
              key={packageItem.id}
            />
          ))}
        </ReactSortable>
      </Then>

      <Else>
        <Stack height="100%" alignItems="center" justifyContent="center">
          <Alert severity="info">{t('Message_NoData')}</Alert>
        </Stack>
      </Else>
    </If>
  );
};

export default RenderPackages;
