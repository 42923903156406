import { If } from "react-if";
import React, { useState, useContext, useEffect } from "react";
import { NavigateNext } from "@material-ui/icons";
import { Alert, IconButton, Button, FormHelperText } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router";

// src
import { LOCALSTORAGE_APP_KEYS } from "../../constants";
import PhoneVerificationDialog from "./PhoneVerificationDialog";

import useAuthStyles from "./styles";
import { AuthFieldProps } from "./types";
import { UserContext } from "../../context/UserContext";
import 'react-phone-input-2/lib/material.css'

const countryPhoneLength: { [key: string]: number } = {
  'us': 10,
  'cn': 11,
  'pk': 10,
  'id': 11,
  'in': 10
}

const PhoneSignup = (props: AuthFieldProps): React.ReactElement => {
  const {
    fullwidth,
    showActionBtn,
    redirectTo,
    isDisabled = undefined,
    actionBtnText = 'Sign Up/Login',
    useErrorAlert = true
  } = props;
  const classes = useAuthStyles({ fullwidth });
  const history = useHistory();
  const { user } = useContext(UserContext);

  const cachedConsumerPhoneNumber = sessionStorage.getItem(LOCALSTORAGE_APP_KEYS.USER_PHONE_NUMBER);
  const [phone, _setPhone] = useState(cachedConsumerPhoneNumber || "");
  const [countryData, setCountryData] = useState();
  const [phoneSignupOpen, setPhoneSignupOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const submitPhoneNumber = () => {
    if (user && user.phoneNumber === phone) {
      if (redirectTo) {
        const [authState, hash] = redirectTo.split('#');
        const [baseUrl, search] = authState.split('?');
        history.replace({ pathname: baseUrl, hash, search });
      }
    } else if (isPhoneNumberValid(phone, countryData)) {
      setPhoneSignupOpen(true)
    } else {
      setErrorMsg("Invalid phone number")
    }
  }

  const isPhoneNumberValid = (phone: string, country: any) => {
    const countryCodeLength = country?.dialCode.length || 0;
    const countryCode = country?.countryCode as string || 'us';
    const countryPLength = countryPhoneLength[countryCode];
    if (phone.length - countryCodeLength >= countryPLength) {
      return true;
    }
    return false;
  }

  const setPhone = (p: string, country: any) => {
    _setPhone(p);
    setCountryData(country);
    setErrorMsg("");
  }

  const disableActionBtn = isDisabled

  useEffect(() => {
    if (!isDisabled && isPhoneNumberValid(phone, countryData) && !cachedConsumerPhoneNumber) {
      submitPhoneNumber();
    }
  }, [phone, countryData, isDisabled])

  return (
    <div className={classes.root}>
      <div className="signUpActionRow">
        <PhoneInput
          inputClass="phoneInput"
          country={'us'}
          onlyCountries={['us', 'pk', 'cn', 'id', 'in']}
          value={phone}
          specialLabel=""
          onEnterKeyPress={submitPhoneNumber}
          onChange={(phone, country) => setPhone(phone, country)}
        />
        <IconButton
          color="primary"
          aria-label="next"
          className="nextButton"
          onClick={submitPhoneNumber}
          {...(disableActionBtn && { disabled: true })}
        >
          <NavigateNext />
        </IconButton>
      </div>

      <If condition={errorMsg && useErrorAlert}>
        <Alert severity="error">{errorMsg}</Alert>
      </If>
      <If condition={errorMsg && !useErrorAlert}>
        <FormHelperText sx={{ color: 'red' }}>{errorMsg}</FormHelperText>
      </If>

      {showActionBtn && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={submitPhoneNumber}
          fullWidth
          sx={{ marginTop: 1 }}
          {...(disableActionBtn && { disabled: true })}
        >
          {actionBtnText}
        </Button>
      )}
      <PhoneVerificationDialog
        phoneNumber={phone}
        open={phoneSignupOpen}
        setOpen={setPhoneSignupOpen}
        redirectTo={redirectTo}
      />
    </div>
  )
}

export default PhoneSignup