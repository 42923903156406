import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles, Theme, makeStyles, Grid, Card, CardContent, Typography, Box, Stack
} from '@material-ui/core'
import useAxios from 'axios-hooks'
import { Else, If, Then } from 'react-if'
import { rgba } from 'polished'
import { isFuture } from 'date-fns'

// src
import { MerchantCustomer } from 'bos_common/src/types/crm/MerchantCustomerType'
import CustomerScheduleEntry, { AttendeeStatus } from 'bos_common/src/types/crm/CustomerScheduleEntryType'
import SimpleLoader from 'bos_common/src/components/SimpleLoader'
import { displayDate } from 'bos_common/src'
import CustomizedChip from 'bos_common/src/components/CustomizedChip'

import BoxWithClick from '../../common/BoxWithClick'
import { isEmptyOrNil } from '../../../utils'
import { GetActivityLogResponse } from '../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      width: 'auto'
    },
    activityCard: {
      '& .MuiCardContent-root': {
        minHeight: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    activityList: {
      '& .logItem': {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: rgba(theme.palette.background.default, 0.5),

        '& .statusChip': {
          textTransform: 'capitalize'
        }
      }
    }
  })
)
type ActivityLogListProps = {
  list: CustomerScheduleEntry[]
}

const ActivityLogList = ({ list }: ActivityLogListProps) => {
  const classes = useStyles()

  const getStatusChipColor = (status: AttendeeStatus) => {
    switch (status) {
      case AttendeeStatus.PRESENT:
        return 'primary';
      case AttendeeStatus.ABSENT:
        return 'error';
      default:
        return 'default'
    }
  }

  const showStatusChip = (status: AttendeeStatus) => {
    return [AttendeeStatus.ABSENT, AttendeeStatus.PRESENT].includes(status)
  }

  return (
    <div className={classes.activityList}>
      <If condition={isEmptyOrNil(list)}>
        <Then>
          <Typography sx={{ my: 2 }} color="textSecondary">No Activity logs exist</Typography>
        </Then>
        <Else>
          {list.map(({ event, id, note, status }) => (
            <Box key={id} className="logItem">
              <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                <If condition={isEmptyOrNil(event?.merchandise)}>
                  <Then>
                    <Typography variant="subtitle1" fontWeight={'bold'}>{event?.merchantPackage?.name ?? ""}</Typography>
                  </Then>
                  <Else>
                    <Typography variant="subtitle1" fontWeight={'bold'}>{event?.merchandise?.name ?? ""}</Typography>
                  </Else>
                </If>
                <If condition={showStatusChip(status)}>
                  <CustomizedChip label={status} size="small" variant='filled' color={getStatusChipColor(status)} chipCustomClass='statusChip' />
                </If>
              </Stack>
              <If condition={!isEmptyOrNil(event?.staff?.user?.displayName)}>
                <Typography variant='subtitle1' color="textSecondary">by {event?.staff?.user?.displayName ?? ""}</Typography>
              </If>
              <If condition={!isEmptyOrNil(event?.date)}>
                <Typography variant='subtitle1'>{displayDate(event?.date!)}</Typography>
              </If>
              <If condition={!isEmptyOrNil(note)}>
                <Typography variant='body1' color="textSecondary" lineHeight={1.2}>{note}</Typography>
              </If>
            </Box>
          ))}
        </Else>
      </If>
    </div>
  )
}

type ActivityTabs = AttendeeStatus | 'upcoming'

type ActivityCardType = {
  label: string,
  value: number,
  type: ActivityTabs
}

const ActivityCard = (props: ActivityCardType) => {
  const classes = useStyles()
  const { label, value = 0, type } = props

  const numberStr = value < 10 && value !== 0 ? `0${value}` : value

  return (
    <Card className={classes.activityCard}>
      <CardContent>
        <Typography className="cardValue" variant='h4'sx={{ color: type === AttendeeStatus.ABSENT ? 'error.main' : 'inherit' }}>{numberStr}</Typography>
        <Typography className="cardLabel" variant='subtitle1' lineHeight={1.2}>{label}</Typography>
      </CardContent>
    </Card>
  )
}

interface CustomerActivityTabProps {
  customer: MerchantCustomer;
}

const CustomerActivityTab = ({ customer }: CustomerActivityTabProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedType, setSelectedType] = useState<ActivityTabs>()
  const [filteredList, setFilteredActivityList] = useState<CustomerScheduleEntry[]>([])

  const [{ data: activityLogs, loading }] = useAxios<GetActivityLogResponse>({
    url: `/merchant/${customer.merchantId}/customers/${customer.id}/activity-log/`,
    params: {
      customerType: customer.type
    },
    method: "GET"
  })


  useEffect(() => {
    if (isEmptyOrNil(activityLogs)) return;

    const { serviceMerchantsActivityLog } = activityLogs!;
    if (isEmptyOrNil(selectedType)) setFilteredActivityList(serviceMerchantsActivityLog)
    else {
      const filteredLogs = serviceMerchantsActivityLog.filter((item: CustomerScheduleEntry) => {
        return selectedType === 'upcoming'
          ? (item.event?.date && isFuture(new Date(item.event?.date)))
          : item.status === selectedType
      })
      setFilteredActivityList(filteredLogs)
    }
  }, [activityLogs, selectedType])

  const activityFilters: ActivityCardType[] = useMemo(() => {
    if (isEmptyOrNil(activityLogs)) return [];
    const { serviceMerchantsActivityLog } = activityLogs!;
      return [
        {
          label: t('Customers_UpcomingSessions'),
          value: serviceMerchantsActivityLog.filter((item: CustomerScheduleEntry) => item.event?.date && isFuture(new Date(item.event?.date))).length,
          type: 'upcoming'
        },
        {
          label: t('Customers_ClassesAttended'),
          value: serviceMerchantsActivityLog.filter((item: CustomerScheduleEntry) => item.status === AttendeeStatus.PRESENT).length,
          type: AttendeeStatus.PRESENT
        },
        {
          label: t('Customers_Absences'),
          value: serviceMerchantsActivityLog.filter((item: CustomerScheduleEntry) => item.status === AttendeeStatus.ABSENT).length,
          type: AttendeeStatus.ABSENT
        },
      ];
  }, [activityLogs]);

  return (
    <>
      <SimpleLoader loading={loading} />
      <div className={classes.root}>
        <Grid container spacing={2}>
          {activityFilters.map((item: ActivityCardType) => (
            <Grid item xs={4} key={item.label}>
              <BoxWithClick
                isSelected={selectedType === item.type}
                handleClick={() => setSelectedType(item.type === selectedType ? undefined : item.type)}
              >
                <ActivityCard {...item} />
              </BoxWithClick>
            </Grid>
          ))}
        </Grid>
        <ActivityLogList list={filteredList} />
      </div>
    </>
  )
}

function areEqual(prevProps: CustomerActivityTabProps, nextProps: CustomerActivityTabProps) {
  return prevProps.customer === nextProps.customer
}

export default React.memo(CustomerActivityTab, areEqual)