import {
  Box,
  Card,
  CardContent,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { If, Then } from 'react-if'

// src
import { isEmptyOrNil } from '../../utils';
import PercentageComparison, { PercentageComparisonProps } from './PercentageComparison';
import InfoTooltip from '../common/InfoTooltip';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .cardContent': {
        padding: 0,
      },
      '& .statsSection': {
        padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
      },
      '& .statsSectionHead': {
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',

        '& .infoButton': {
          position: 'absolute',
          right: 5,
          top: -9,
          padding: 0,
        },
      },
      '& .statsContent': {
        display: 'flex',
        justifyContent: 'space-evenly',
        textAlign: 'center',

        '& .statItem': {
          padding: `0 ${theme.spacing(1.5)}`,
          position: 'relative',

          '& .statValue': {
            fontWeight: 500,
            '&.red': {
              color: '#E45835',
            },
          },
          '& .MuiTypography-root': {
            fontSize: '0.8rem',
          },
          '&:not(:last-child)': {
            '&::after': {
              height: 20,
              position: 'absolute',
              right: 0,
              top: '18%',
              comparisonData: '""',
              display: 'inline-block',
              borderRight: '1px solid #999',
              width: 1,
            },
          },
        },
      },
      '& .contentText': {
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        fontSize: '0.8rem',
        fontWeight: 500,
        color: '#A0A5A6',
      },
      '& .footerText': {
        textAlign: 'center',
        color: '#999',
        fontWeight: 500,
      },
      '& .sectionDivider': {
        borderColor: '#CACACA',
      },
    },
  })
);

interface IMerchantStatsCard {
  headTitle: string | React.ReactNode;
  headValue: string | React.ReactNode;
  comparisonData?: {
    text: string,
    difference: PercentageComparisonProps
  }[];
  footer?: string;
  infoTooltip?: string;
}

const MerchantStatsCard = (props: IMerchantStatsCard) => {
  const { headTitle, headValue, comparisonData = [], footer, infoTooltip } = props;
  const classes = useStyles();

  return (
    <Card elevation={3} className={classes.root}>
      <CardContent className="cardContent">
        <Box className="statsSection">
          <Box className="statsSectionHead">
            <Typography sx={{ fontWeight: 'bold', lineHeight: 1, fontSize: 14 }} color="text.secondary" gutterBottom className='title-container'>
              {headTitle}
            </Typography>
            <Typography variant="h5" textAlign="center" className="value">{headValue}</Typography>
            <If condition={infoTooltip}>
              <InfoTooltip
                title={infoTooltip ?? ""}
                placement="top-start"
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={3000}>
                <IconButton className="infoButton">
                  <InfoIcon />
                </IconButton>
              </InfoTooltip>
            </If>
          </Box>
          <If condition={!isEmptyOrNil(comparisonData)}>
            <Then>
              <Box className="statsContent">
                {comparisonData.map((item, idx) => (
                  <Box className="statItem" key={`key-${idx}`}>
                    <PercentageComparison {...item.difference} />
                    <Typography>{item.text}</Typography>
                  </Box>
                ))}
              </Box>
            </Then>
          </If>
        </Box>
        <If condition={footer}>
          <>
            <Divider />
            <Box className="statsSection">
              <Typography className="footerText">{footer}</Typography>
            </Box>
          </>
        </If>
      </CardContent>
    </Card>
  );
};

export default MerchantStatsCard;
