import React, { ChangeEvent, useMemo } from 'react';
import debounce from 'debounce';
import { TextField, styled, InputAdornment } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  '& .leftAdornment': {
    height: '100%',
    paddingRight: theme.spacing(1),
    borderRight: `1px solid ${grey[200]}`,
  },
  "& .MuiOutlinedInput-input": {
    padding: 12,
  },
  "& .MuiOutlinedInput-root": {
    paddingLeft: 10,
  },
}))

interface InputSearchBoxProps {
  onChangeQuery: (q: string) => void;
  placeholder?: string,
  defaultValue?: string,
  rightChild?: React.ReactNode,
  leftChild?: React.ReactNode,
  forceSearch?: boolean,
  autoFocus?: boolean,
  minLength?: number,
}

// let timeoutId: NodeJS.Timeout | null = null;

export const InputSearchBox = (props: InputSearchBoxProps): JSX.Element => {
  const {
    onChangeQuery,
    placeholder = 'Search stores...',
    defaultValue = '',
    rightChild,
    leftChild,
    forceSearch,
    autoFocus,
    minLength = 3,
  } = props;

  React.useEffect(() => {
    return () => {
      debouncedHandler.clear();
    }
  }, []);

  const debouncedHandler = useMemo(() => debounce(onChangeQuery, 800), []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (forceSearch || (value.length >= minLength || value.length === 0)) {
      debouncedHandler(e.target.value);
    }
  }

  return (
    <StyledTextField
      fullWidth
      autoFocus={autoFocus}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      defaultValue={defaultValue}
      className="inputSearchField"
      InputProps={{
        ...(leftChild && {
          startAdornment: (
            <InputAdornment className="leftAdornment" position="start" >
              {leftChild}
            </InputAdornment>
          )
        }),
        ...(rightChild && {
          endAdornment: (
            <InputAdornment position="end" >
              {rightChild}
            </InputAdornment>
          )
        })
      }}
      onChange={handleInputChange}
    />
  );
}

export default InputSearchBox;
