import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Else, If, Then } from 'react-if';
import { LOCALSTORAGE_APP_KEYS } from '../../constants';
import { LOCALSTORAGE_AUTH_KEYS } from '../../services/auth0';

import EmailSignup from './EmailSignup';
import PhoneSignup from './PhoneSignup';
import { AuthFieldProps } from './types';

export interface AuthorizeUserProps extends AuthFieldProps {
  useEmail?: boolean
}

const AuthorizeUser = (props: AuthorizeUserProps): React.ReactElement => {
  const { useEmail, ...rest } = props;

  // ! for developers set 'use-email-login' in local storage from dev console
  const localStorageConfig = localStorage.getItem(LOCALSTORAGE_APP_KEYS.DEV_EMAIL_LOGIN) === 'true'
  const [useEmailLogin, toggleUseEmail] = useState<boolean>(Boolean(useEmail ?? localStorageConfig))

  const label = useEmailLogin ? 'Prefer phone number?' : 'Prefer email?'

  const onPreferEmailChange = () => {
    localStorage.setItem(LOCALSTORAGE_AUTH_KEYS.PREFER_EMAIL, `${!useEmailLogin}`)
    toggleUseEmail(!useEmailLogin)
  }
  return (
    <Box>
      <Typography gutterBottom onClick={onPreferEmailChange} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', textDecoration: 'underline' }}>
        {label}
      </Typography>
      <If condition={useEmailLogin}>
        <Then>
          <EmailSignup {...rest} />
        </Then>
        <Else>
          <PhoneSignup {...rest} />
        </Else>
      </If>
    </Box>
  )
}

export default AuthorizeUser