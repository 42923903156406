import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionTemplateContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      "& .header-container": {
        textAlign: 'center'
      },

      "& .content-container": {
        marginTop: theme.spacing(2),
        paddingBottom: '115px', // added extra padding to compensate for fix positioned actions footer

        "& .line-item-actions-container": {
          "& .line-item-action": {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '5px',
            padding: '4px 8px 4px 8px',
            marginBottom: theme.spacing(1)
          }
        },

        '& .order-summary': {
          marginTop: theme.spacing(2),

          '& .price-row': {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
          },

          '& .restore-inventory-switch-container': {
            '& label': {
              justifyContent: 'flex-end',
              marginRight: 0,

              '& .MuiTypography-subtitle1': {
                marginRight: theme.spacing(2)
              }
            }
          }
        }
      },

      '& .divider-container': {
        position: 'relative',

        '& .MuiDivider-root': {
          width: '100%',
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },

        '& .or-span': {
          position: 'absolute',
          left: '50%',
          top: '-9px',
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.6)',
          marginLeft: '-12px'
        }
      },

      '& .actions-container': {
        bottom: 0,
        position: 'fixed',
        width: 318, // 350 - 2(theme.spacing(2))
        right: 0,
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: theme.palette.common.white,

        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% - 30px)',
        },

        '& .MuiFab-root': {
          width: '100%'
        },
      },
    }
  })
)

export default useStyles;