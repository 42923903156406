import React from 'react'
import { Chip, createStyles, makeStyles, PropTypes, Theme } from '@material-ui/core'
import classNames from 'classnames';

const useStyles = makeStyles(() => createStyles({
  root: {
    borderRadius: '5px !important',
    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '6px',
    }
  }
}))

type ChipColor = Exclude<PropTypes.Color, 'inherit'>;

type Props = {
  label: React.ReactNode | string,
  variant?: 'outlined' | 'filled',
  size?: 'small' | 'medium',
  color?: ChipColor | "error",
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  chipCustomClass?: string;
}

const CustomizedChip = ({ label, color = 'default' as ChipColor, variant = 'outlined', icon, size = 'medium', chipCustomClass }: Props) => {
  const classes = useStyles()

  return (
    <Chip
      variant={variant}
      // @ts-ignore
      color={color}
      label={label}
      icon={icon}
      classes={{
        root: classNames([classes.root, chipCustomClass])
      }}
      size={size}
    />
  )
}

export default CustomizedChip
