import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router";

import TrialsMenu from "./components/TrialsMenu";
import ClassesMenu from "./components/ClassesMenu";
import PackagesMenu from "./components/PackagesMenu";
import MembershipsMenu from "./components/MembershipsMenu";
import MerchantPageHeader from "../../components/MerchantPageHeader";
import TwoColumnLayout from "../../components/common/TwoColumnLayout";
import MenuListCard, { CardMenuItem } from "../../components/common/MenuListCard";
import { MerchantMenuContextProvider } from "../../context/MenuContext/MerchantMenuContext";

interface IMerchantStatsPage {
  merchantUsername: string;
}

const ServiceMenuPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { merchantUsername } = useParams<IMerchantStatsPage>();

  const menuItems: CardMenuItem[] = [
    {
      path: `/${merchantUsername}/menu/classes`,
      label: t('Menu_Classes'),
      icon: false,
    },
    {
      path: `/${merchantUsername}/menu/packages`,
      label: t('Menu_Packages'),
      icon: false,
    },
    {
      path: `/${merchantUsername}/menu/memberships`,
      label: t('Menu_Memberships'),
      icon: false,
    },
    {
      path: `/${merchantUsername}/menu/trials`,
      label: t('Menu_Trials'),
      icon: false,
    },
  ];

  const onBack = () => {
    history.goBack();
  };

  return (
    <div className="container">
      <MerchantPageHeader onBack={onBack} title={t("Menu")} hideOnScroll={false} elevation={0} />
      <MerchantMenuContextProvider>
        <TwoColumnLayout leftSide={<MenuListCard title={t('Categories')} menuItems={menuItems} />}>
          <Switch>
            <Redirect
              exact
              from="/:merchantUsername/menu"
              to="/:merchantUsername/menu/classes"
            />
            <Route
              exact
              path="/:merchantUsername/menu/classes"
              component={ClassesMenu}
            />
            <Route
              exact
              path="/:merchantUsername/menu/packages"
              component={PackagesMenu}
            />
            <Route
              exact
              path="/:merchantUsername/menu/memberships"
              component={MembershipsMenu}
            />
            <Route
              exact
              path="/:merchantUsername/menu/trials"
              component={TrialsMenu}
            />
          </Switch>
        </TwoColumnLayout>
      </MerchantMenuContextProvider>
    </div>
  )

};

export default ServiceMenuPage;
