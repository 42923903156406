import React, { useContext } from 'react';
import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { logout } from '../bos_common/src/services/auth0';
import MerchantPageHeader from '../components/MerchantPageHeader';
import { ColoredPaper } from '../bos_common/src';
import { UserContext } from '../bos_common/src/context/UserContext';
import SimpleLoader from '../bos_common/src/components/SimpleLoader';

import UserApplicationPreferences from '../components/UserApplicationPreferences';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .avatarContainer': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& .avatar': {
          width: theme.spacing(16),
          height: theme.spacing(16),
        },
        '& .MuiCardHeader-content': {
          textAlign: 'center',
        },
      },
      '& .MuiDivider-root.sectionSpacer': {
        width: '100%',
        marginTop: `${theme.spacing(1)} !important`,
        marginBottom: `${theme.spacing(1)} !important`,
      },
      '& .buttonArea': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        marginTop: theme.spacing(1),
      }
    },
  })
);

const UserPage = () => {
  const classes = useStyles();
  const { user, loading, setAuthenticating, setLoginState } = useContext(UserContext);
  const { t } = useTranslation();

  const handleLogout = () => {
    setLoginState(undefined, undefined)
    setAuthenticating(true)
    logout(window.location.origin + '/login');
    // logout user by removing the token from memory and storage
  };

  return (
    <div className="container">
      <MerchantPageHeader title={t("Account")} />
      <SimpleLoader loading={loading} />
      <ColoredPaper className={classes.root}>
        <CardContent>
          <Grid container >
            <Grid container item xs={12} justifyContent='space-around'>
              <Grid item xs={12} sm={6}>
                <div className="avatarContainer">
                  <Avatar alt={user?.displayName || user?.phoneNumber} src={user?.picture} className="avatar" />
                  <CardHeader title={user?.displayName} subheader={user?.email || user?.phoneNumber} />
                </div>
              </Grid>
            </Grid>
            <Divider className="sectionSpacer" />
            <Grid container item xs={12} justifyContent='space-around'>
              <Grid item xs={12}>
                <UserApplicationPreferences />
              </Grid>
            </Grid>
            <Divider className="sectionSpacer" />
            <Grid container item xs={12} justifyContent='space-around'>
              <Grid item xs={12} sm={2}>
                <div className="buttonArea">
                  <Button variant="outlined" color="primary" onClick={handleLogout}>
                    {t("Logout")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </ColoredPaper>
    </div>
  );
};

export default UserPage;
