import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { Merchant } from "../../../bos_common/src/types/MerchantType";

import { MerchantSliceState } from '../../../types/MerchantSlice';

type MerchantSliceCaseReducer = SliceCaseReducers<MerchantSliceState>;

export const merchantSlice = createSlice<MerchantSliceState, MerchantSliceCaseReducer, string>({
  name: 'merchant',
  initialState: {
    loading: false,
    entities: {
      merchant: null,
    },
  },
  reducers: {
    setLoadingMerchant: (state) => {
      return {
        ...state,
        loading: true
      }
    },

    stopLoadingMerchant: (state) => {
      return {
        ...state,
        loading: false
      }
    },

    updateMerchant: (state, action: PayloadAction<Merchant>) => {
      const merchant: Merchant = action.payload;

      return {
        ...state, 
        entities: {
          merchant
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingMerchant, stopLoadingMerchant, updateMerchant } = merchantSlice.actions;

export default merchantSlice.reducer;
