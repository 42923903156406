/**
 * Load parameter from envvar if specified, or throw an exception otherwise.
 * @param paramName - The name of the parameter to source from envvars (e.g. "BACKEND_CONFIG_PARAM")
 */
const loadRequiredParam = (paramName: string): string => {
  const paramValue = process.env[paramName];
  if (paramValue === undefined) {
    throw new Error(`Required envvar ${paramName} not set.`);
  }
  return paramValue;
};

/**
 * Load parameter from envvar if specified, or fallback to specified default otherwise.
 * @param paramName - The name of the parameter to source from envvars (e.g. "BACKEND_CONFIG_PARAM")
 * @param defaultValue - The fallback value to be used if `paramName` var is not set
 */
const loadOptionalParam = (paramName: string, defaultValue: string): string => {
  return process.env[paramName] || defaultValue;
};

const loadConfig = (): Record<string, any> => ({
  appConfig: {
    buildEnv: loadOptionalParam('REACT_APP_BUILD_ENV', 'production'),
    streamChatId: loadOptionalParam("REACT_APP_STREAM_CHAT_ID", ""),
  },
  env: {
    isStaging: parseInt(loadOptionalParam('REACT_APP_IS_STAGING', '0'), 10)
  }
});

const config = loadConfig();
export default config;