import React, { useContext, useState } from 'react'
import { CardActions, createStyles, Fab, IconButton, makeStyles, Select, Theme, MenuItem } from '@material-ui/core';
import { CheckCircleOutline, MoreVert } from '@material-ui/icons';
import { If, Then, Else } from 'react-if';
import { useTranslation } from 'react-i18next';

// src
import { isOpenCheckPreAuthed } from 'bos_common/src/services/orderUtils';

import { Order, OrderStatus, OrderType } from '../../services/models';
import { MerchantOrdersContext } from '../../context/MerchantOrders/MerchantOrdersContext';
import { isEmptyOrNil } from '../../utils';
import RemindCustomerFab from './OrderActionsDrawer/ActionTemplates/RemindCustomerFab';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
			padding: 0,
			marginRight: `-${theme.spacing(1.5)}`,
			marginBottom: theme.spacing(1),

			'& .MuiFab-root': {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
			},

			'& .action-drawer-btn': {
				color: theme.palette.primary.main
			},

			"& .operator-actions-container": {
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",

				'& .wait-time-selector': {
					width: "110px"
				}
			}
		}
	})
);

type OrderCardActionsProps = {
	order: Order;
	onActionClick?: (id: string, status: OrderStatus, order?: Order, options?: { [key: string]: any }) => void;
	handleShowMoreClick?: (order: Order) => void;
};

const WAIT_TIMES_IN_MINUTES = [10, 20, 30, 40, 50, 60]
const RenderOperatorAction = (props: OrderCardActionsProps) => {
	const {
		order,
		onActionClick = () => { },
	} = props
	const [waitTimeInMinutes, setWaitTimeInMinutes] = useState<string>('');

	const handleOnChange = (event: any) => {
		const {
			target: { value },
		} = event;
		setWaitTimeInMinutes(value)
	};

	return (
		<div className='operator-actions-container'>
			<Select
				value={waitTimeInMinutes}
				displayEmpty
				onChange={handleOnChange}
				className='wait-time-selector'
			>
				<MenuItem value="">
					<em>Wait Time</em>
				</MenuItem>
				{WAIT_TIMES_IN_MINUTES.map((name) => (
					<MenuItem key={name} value={name}>
						{name} minutes
					</MenuItem>
				))}
			</Select>
			<IconButton
				color="primary"
				disabled={isEmptyOrNil(waitTimeInMinutes)}
				onClick={() => onActionClick(order.id, OrderStatus.PAID, order, { waitTimeInMinutes, isUserOneMOperator: true })}
			>
				<CheckCircleOutline style={{ fontSize: 30 }} />
			</IconButton>
		</div>
	)
}

const OrderCardActions = (props: OrderCardActionsProps) => {
	const {
		order,
		onActionClick = () => { },
		handleShowMoreClick = () => { }
	} = props
	const classes = useStyles()
	const { isUserOneMOperator } = useContext(MerchantOrdersContext);
	const { t } = useTranslation();

	const RenderStaffActions = () => {
		switch (order.status) {
			case OrderStatus.PAID:
			case OrderStatus.CONFIRMED:
				return (
					<Fab
						color="primary"
						variant="extended"
						size="medium"
						onClick={() => {
							onActionClick(order.id, OrderStatus.PAID, order)
						}}
					>
						{order.type === OrderType.PICKUP ? t("Ready_for_Pickup") : t("Complete")}
					</Fab>
				)
			case OrderStatus.FULFILLED:
				return (
					<RemindCustomerFab order={order} />
				);
			case OrderStatus.VOIDED:
			case OrderStatus.PAID_EXTERNALLY:
				return (
					<Fab
						variant="extended"
						color="inherit"
						size="medium"
						onClick={() => {
							onActionClick(
								order.id,
								order.status,
								order
							)
						}}
					>
						{t("MarkAsUnpaid")}
					</Fab>
				);
			case OrderStatus.OPEN_CHECK:
				return (
					<Fab
						variant="extended"
						color="primary"
						size="medium"
						onClick={() => {
							onActionClick(
								order.id,
								order.status,
								order
							)
						}}
					>
						{!isOpenCheckPreAuthed(order)
							? t("MarkAsPaid")
							: (order.type === OrderType.DINEIN)
								? t("MarkCheckAsClosed")
								: t("MarkAsPickedUp")
						}
					</Fab>
				);
			default:
				return null;
		}
	}

	const RenderOneMOperatorActions = () => {
		if (order.status === OrderStatus.PAID) {
			return (
				<RenderOperatorAction {...props} />
			)
		}

		return null;
	}

	const handleClick = () => {
		handleShowMoreClick(order)
	}

	return (
		<CardActions className={classes.root}>
			<div className="action-btns">
				<If condition={isUserOneMOperator}>
					<Then>
						<RenderOneMOperatorActions />
					</Then>
					<Else>
						<RenderStaffActions />
					</Else>
				</If>
			</div>

			<IconButton className='action-drawer-btn' aria-label="more options" onClick={handleClick}>
				<MoreVert />
			</IconButton>
		</CardActions>
	);
};

export default OrderCardActions