import React, { useContext, useState } from "react"
import { Box, ButtonBase, CardActionArea, createStyles, List, ListItem, makeStyles, Stack, Theme, Typography } from "@material-ui/core";
import { filter, pipe, pluck, propOr, without } from "ramda";
import { If, Then } from "react-if";
import useAxios, { RefetchOptions } from "axios-hooks";
import { AxiosPromise, AxiosRequestConfig } from "axios";

import TypographyEllipsis from "bos_common/src/components/TypographyEllipsis";
import { Merchant } from "bos_common/src/types/MerchantType";
import { getAuthHeaders, isEmptyOrNil } from "bos_common/src";
import axios from "bos_common/src/services/backendAxios";
import { MerchantCustomer } from "bos_common/src/types/crm/MerchantCustomerType";
import MerchantPackage from "bos_common/src/types/crm/MerchantPackageType";
import MerchantMembership from "bos_common/src/types/crm/MerchantMembershipType";
import CustomerSubscriptionBalance from "bos_common/src/types/crm/CustomerSubscriptionBalanceType";

import CustomDialog from "../../../common/CustomDialog";
import { AppContext } from "../../../../context/AppContext";
import { NotificationSeverity } from "../../../../types/NotificationSlice";
import { SubscriptionType } from "../../../Calendar/types";
import ConfirmSubscriptionAlert from "./ConfirmSubscriptionAlert";
import { UserContext } from "bos_common/src/context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .membershipOptionsList': {
        maxHeight: "400px",
        overflow: "auto",
      },

      "& .optionItem": {
        cursor: "pointer",
      },

      "& .heading": {
        marginBottom: theme.spacing(0.5),
        color: "#929292",
      }
    },
  })
);

type AddSubscriptionDialogProps = {
  open: boolean;
  setOpen: (_: boolean) => void;
  merchant?: Merchant;
  customer: MerchantCustomer;
  refetchSubscriptions: (config?: AxiosRequestConfig<any> | undefined, options?: RefetchOptions | undefined) => AxiosPromise<any>
  setLoading: (_: boolean) => void;
  subscriptionsList: Array<CustomerSubscriptionBalance>;
}

type PackageConfirmationInfoType = {
  showDialog: boolean,
  subscriptionId: number,
  subscriptionType: SubscriptionType
}

const getActiveSubscriptionsList = (key: string, subscriptionsList: CustomerSubscriptionBalance[]) => {
  return pipe(
    filter((s: CustomerSubscriptionBalance) => !s.isDeleted),
    pluck(key),
    without([null, undefined])
  )(subscriptionsList)
}

const AddSubscriptionDialog = (props: AddSubscriptionDialogProps) => {
  const { open, setOpen, merchant, customer, refetchSubscriptions, setLoading, subscriptionsList } = props;
  if (!open) return null;

  const classes = useStyles();
  const { token } = useContext(UserContext);
  const { triggerNotification } = useContext(AppContext);

  const [packageConfirmationInfo, setPackageConfirmationInfo] = useState<PackageConfirmationInfoType>({
    showDialog: false,
    subscriptionId: -1,
    subscriptionType: SubscriptionType.PACKAGE
  })
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [{ data: membershipsList, loading }] = useAxios({
    url: `/merchants/${merchant?.id}/membership/data`,
    method: "get",
    headers: getAuthHeaders(token),
  });

  const memberships = propOr([], 'memberships', membershipsList);
  const packages = propOr([], 'packages', membershipsList);

  const subscribedMemberships = getActiveSubscriptionsList('merchantMembershipId', subscriptionsList)
  const subscribedPackages = getActiveSubscriptionsList('merchantPackageId', subscriptionsList)

  const onSubscriptionClick = async (packageId: number, subscriptionType: SubscriptionType) => {
    let isError = false
    setDisableConfirm(true)
    if (subscribedMemberships.includes(packageId)) {
      triggerNotification(true, `Membership already subscribed`, NotificationSeverity.ERROR, true);
      isError = true
    }

    if (subscribedPackages.includes(packageId)) {
      triggerNotification(true, `Package already subscribed`, NotificationSeverity.ERROR, true);
      isError = true
    }

    if (!isError) {
      setLoading(true);
      const response = await axios.post(
        `/customers/${customer?.id}/subscriptions`,
        {
          ...(subscriptionType === SubscriptionType.PACKAGE ? { merchantPackageId: packageId } : { merchantMembershipId: packageId }),
          merchantId: merchant?.id
        },
        { headers: getAuthHeaders(token) }
      )

      if (response.status === 200) {
        await refetchSubscriptions()
        setOpen(false);
      }
    }

    setLoading(false);
    setDisableConfirm(false);
    setPackageConfirmationInfo({
      showDialog: false,
      subscriptionId: -1,
      subscriptionType: SubscriptionType.PACKAGE
    })
  }

  return (
    <CustomDialog
      maxWidth="sm"
      fullWidth
      title="Memberships/Packages"
      className={classes.root}
      setOpen={setOpen}
      keepMounted={false}
      open={open}
    >
      <Box p={2} minHeight="400px">
        <If condition={isEmptyOrNil(memberships) && isEmptyOrNil(packages) && !loading}>
          <Typography textAlign="center">No packages or memberships available</Typography>
        </If>

        <If condition={!isEmptyOrNil(memberships)}>
          <Then>
            <Typography variant="subtitle1" className="heading">Memberships</Typography>
            <Stack gap={1} className="membershipOptionsList">
              {
                memberships.map((data: MerchantMembership) => {
                  const { id, name } = data;

                  return (
                    <CardActionArea
                      key={id}
                      className="optionItem"
                      sx={{ p: 1 }}
                      onClick={() => setPackageConfirmationInfo({
                        showDialog: true,
                        subscriptionId: id,
                        subscriptionType: SubscriptionType.MEMBERSHIP
                      })}
                    >
                      <TypographyEllipsis>{name}</TypographyEllipsis>
                    </CardActionArea>
                  )
                })
              }
            </Stack>
          </Then>
        </If>

        <If condition={!isEmptyOrNil(packages)}>
          <Then>
            <Typography variant="subtitle1" className="heading">Packages</Typography>
            <Stack gap={1} className="membershipOptionsList">
              {
                packages.map((data: MerchantPackage) => {
                  const { id, name } = data;

                  return (
                    <CardActionArea
                      key={id}
                      className="optionItem"
                      sx={{ p: 1 }}
                      onClick={() => setPackageConfirmationInfo({
                        showDialog: true,
                        subscriptionId: id,
                        subscriptionType: SubscriptionType.PACKAGE
                      })}
                    >
                      <TypographyEllipsis>{name}</TypographyEllipsis>
                    </CardActionArea>
                  )
                })
              }
            </Stack>
          </Then>
        </If>

        <If condition={packageConfirmationInfo.showDialog}>
          <ConfirmSubscriptionAlert
            isOpen={packageConfirmationInfo.showDialog}
            handleCancel={() => setPackageConfirmationInfo({
              showDialog: false,
              subscriptionId: -1,
              subscriptionType: SubscriptionType.PACKAGE
            })}
            isConfirmDisabled={disableConfirm}
            handleConfirm={() => onSubscriptionClick(packageConfirmationInfo.subscriptionId, packageConfirmationInfo.subscriptionType)}
            subscriptionType={packageConfirmationInfo.subscriptionType}
          />

        </If>
      </Box>
    </CustomDialog >
  )
}

export default AddSubscriptionDialog;