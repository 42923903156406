import { Base } from "./Base";
import { MerchantFollower } from "./MerchantFollower";
import { MerchantPromotion } from "./MerchantPromotionType";
import { Merchant } from "./MerchantType";
import UserMerchantVote from "./UserMerchantVote";
import { UserPurchaseProfile } from "./UserPurchaseProfileType";
import { UserStripe } from "./UserStripeType";

export interface Auth0User {
  sub?: string;
  name?: string;
  email?: string;
  picture?: string;
  nickname?: string;
  phone_number?: string;
}

export interface Wallet {
  userId: string;
  points: number;
}

export interface FoodPreference {
  vegan: boolean;
  vegetarian: boolean;
  lactoseFree: boolean;
  glutenFree: boolean;
  kosher: boolean;
  islam: boolean;
  hindu: boolean;
}

export interface ApplicationPreferences {
  notificationSound: boolean;
  orderDetailsCard?: boolean;
}


export enum IdentityRole {
  User = "User",
  MerchantStaff = "MerchantStaff",
  MerchantAdmin = "MerchantAdmin",
  OneMOperator = 'OneMOperator',
  OneMOperatorAdmin = 'OneMOperatorAdmin',
}

export interface User extends Base {
  id: string;
  displayName?: string;
  picture?: string;
  email?: string;
  phoneNumber: string;
  address?: string;
  auth0Token?: string;
  points?:number,
  foodPreference?: FoodPreference;
  applicationPreferences?: ApplicationPreferences;
  role: IdentityRole;
  stripe: UserStripe;
  votedStores: UserMerchantVote[] | null;
  purchaseProfiles: UserPurchaseProfile[] | null;
  followedStores: MerchantFollower[];
  affiliateCode: string | null;
}

export interface UserPromotionEntry {
  merchant: Merchant;
  promotion: MerchantPromotion;
  startDate: Date;
  endDate: Date;
}