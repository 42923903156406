import { without } from 'ramda';
import { Merchandise, MerchandiseApiResponseType, MerchandiseCategory, MerchandiseModifier, MerchandiseModifierCategory, MerchandiseModifierTemplate } from "../../../bos_common/src/types/MerchandiseType";
import { MerchandiseRelations, MerchandiseSliceState } from "../../../types/MerchandiseSlice";
import { RootState } from "../../store";

const cleanArray = without([undefined, null, ''])

export const getMerchandises = (state: RootState): Merchandise[] | null => {
  const { entities } = state.merchandise as MerchandiseSliceState;
  if (!entities.ids)
    return null;
  return cleanArray(entities.ids.map((id: string) => entities.byId[id]));
}

export const getMerchandiseDictionary = (state: RootState): { [key: string]: Merchandise } => {
  return state.merchandise.entities.byId;
}

export const getMerchandiseCategories = (state: RootState): MerchandiseCategory[] => {
  const { merchandiseCategories } = state.merchandise as MerchandiseSliceState;
  return cleanArray(Object.values(merchandiseCategories).sort((a, b) => a.displayOrder-b.displayOrder));
}

export const getModifierCategories = (state: RootState): MerchandiseModifierCategory[] => {
  const { modifierCategories } = state.merchandise as MerchandiseSliceState;
  return cleanArray(Object.values(modifierCategories));
}

export const getModifierTemplates = (state: RootState): MerchandiseModifierTemplate[] => {
  const { modifierTemplates } = state.merchandise as MerchandiseSliceState;
  return cleanArray(Object.values(modifierTemplates));
}

export const getModifiers = (state: RootState): MerchandiseModifier[] => {
  const { modifiers } = state.merchandise as MerchandiseSliceState;
  return cleanArray(Object.values(modifiers));
}

export const getMerchandiseRaw = (state: RootState): MerchandiseApiResponseType|null => {
  const merchandises = getMerchandises(state);
  if (!merchandises) {
    return null;
  }

  return {
    merchandises,
    mercCategories: getMerchandiseCategories(state),
    modiCategories: getModifierCategories(state),
    modiTemplates: getModifierTemplates(state),
    modifiers: getModifiers(state),
  }
}

export const getMerchandiseLoadingState = (state: RootState): boolean => {
  return state.merchandise.loading as boolean;
}

export const getRelations = (state: RootState): MerchandiseRelations => {
  return state.merchandise.relations;
}
